import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import BlueBackground1 from "assets/images/help/about_us_blue_bk_1.svg";
import BlueBackground2 from "assets/images/help/about_us_blue_bk_2.svg";
import AboutUsFounderImgSmall from "assets/images/help/about_us_founder.png";
import AboutUsFounderImgMedium from "assets/images/help/about_us_founder@2x.png";
import AboutUsFounderImgLarge from "assets/images/help/about_us_founder@3x.png";
import AboutUsImgSmall from "assets/images/help/about_us_main.png";
import AboutUsImgMedium from "assets/images/help/about_us_main@2x.png";
import AboutUsImgLarge from "assets/images/help/about_us_main@3x.png";
import OrangeBackgroundImg from "assets/images/help/about_us_orange_bk.svg";
import ImgSponsor1 from "assets/images/help/about_us_sponsor_1.svg";
import ImgSponsor2 from "assets/images/help/about_us_sponsor_2.svg";
import ImgSponsor3 from "assets/images/help/about_us_sponsor_3.svg";
import ImgSponsor4 from "assets/images/help/about_us_sponsor_4.svg";
import ClubGrandHotelPalace from "assets/images/help/sponsorsAndSupporters/Club_Grand_Hotel_Palace.jpg";
import Textilmuseum from "assets/images/help/textilmuseum.png";
import Weltkulturerbe from "assets/images/help/Weltkulturerbe.png";

import useTranslation from "hooks/useTranslation";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import colors from "styles/colors";
import { Container, ImgWrapper, MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const SponsorsWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	${media.tablet`
    justify-content: center;
  `};
`;

const ValuesSectionWrapper = styled.section`
	margin-bottom: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${media.tablet`
    margin-bottom: 50px;
  `};
`;

const SponsorsPicture = styled.img<{ maxHeight?: string }>`
	margin: 2.5rem auto;
	width: 225px;
	max-height: ${props => props.maxHeight || "70px"};
`;

const TilesWrapper = styled.div`
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	${media.tablet`
    justify-content: center;
  `};
`;

const OrangeTile = styled.div`
	margin-right: 1.875rem;
	height: 8.938rem;
	width: 11.063rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${OrangeBackgroundImg});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const TileText = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	font-weight: bold;
	letter-spacing: 0.03px;
	text-align: center;
	color: ${colors.white};
	text-transform: capitalize;
	margin: 0;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const TitleOurValues = styled.h2`
	margin: 0 0 3.125rem 0;
	font-family: ${MontserratFontFamily};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.04px;
	color: ${colors.mainFontColor};
`;

const Background = styled.div`
	margin: 6.25rem 0 3.125rem 0;
	width: 100%;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    margin-bottom: 3.125rem;
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const DetailsWrapper = styled.div`
	padding-top: 6.25rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	${media.desktop`
    justify-content: center;
  `};
`;

const DetailsBox = styled.div<{ $backgroundUrl: string }>`
	margin-right: 1.875rem;
	padding: 4.063rem 3.125rem 0 3.125rem;
	width: 410px;
	height: 237px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-image: url(${props => props.$backgroundUrl});
	background-size: contain;
	background-repeat: no-repeat;

	${media.tablet`
    margin-right: 0;
  `};
`;

const DetailsTitle = styled.h2`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.03px;
	color: ${colors.mainFontColor};

	${media.tablet`
      font-size: ${dimensions.fontSize.large};
   `};
`;

const DetailsDescription = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	font-weight: 500;
	letter-spacing: 0.03px;
	text-align: center;
	color: ${colors.mainFontColor};
	margin: 0;

	${media.tablet`
      font-size: ${dimensions.fontSize.medium};
   `};
`;

const FounderDescWrapper = styled(Box)`
	margin-left: 80px;

	${media.tablet`
    margin-left: 0;
   `};
`;

const AboutUsPage = () => {
	const { t, withRaw } = useTranslation(["common", "seo"]);

	return (
		<AppWrapper>
			<Helmet>
				<meta name="description" content={t("SEO.ABOUT_US_PAGE.DESCRIPTION")} />
				<meta property="og:description" content={t("SEO.ABOUT_US_PAGE.DESCRIPTION")} />
			</Helmet>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.ABOUT_US.MAIN_TITLE")} />

				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.ABOUT_US.MAIN_TITLE")}
							description={withRaw("HELP.ABOUT_US.MAIN_DESCRIPTION", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="About us - picture"
								imgSmall={AboutUsImgSmall}
								imgMedium={AboutUsImgMedium}
								imgLarge={AboutUsImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>

				<section>
					<SmallerTitle>{t("HELP.ABOUT_US.SECOND_TITLE")}</SmallerTitle>

					<SponsorsWrapper>
						<SponsorsPicture
							src={ImgSponsor1}
							title="Canton St. Gallen sponsor image"
							alt="Canton St. Gallen sponsor image"
						/>

						<SponsorsPicture src={ImgSponsor2} title="Start feld sponsor image" alt="Start feld sponsor image" />

						<SponsorsPicture
							src={ImgSponsor3}
							title="St. Gallen bodensee sponsor image"
							alt="St. Gallen bodensee sponsor image"
						/>
					</SponsorsWrapper>

					<SponsorsWrapper>
						<SponsorsPicture src={ImgSponsor4} title="Spin off sponsor image" alt="Spin off sponsor image" />
						<SponsorsPicture src={Textilmuseum} title="Textilmuseum sponsor image" alt="Textilmuseum sponsor image" />

						<SponsorsPicture
							src={Weltkulturerbe}
							title="Weltkulturerbe sponsor image"
							alt="Weltkulturerbe sponsor image"
						/>
					</SponsorsWrapper>

					<SponsorsWrapper>
						<SponsorsPicture
							src={ClubGrandHotelPalace}
							title="Club Grand Hotel Palace sponsor image"
							alt="Club Grand Hotel Palace sponsor image"
							maxHeight="224px"
						/>
					</SponsorsWrapper>
				</section>
			</Container>

			<Background>
				<Container>
					<DetailsWrapper>
						<DetailsBox $backgroundUrl={BlueBackground1}>
							<DetailsTitle>{t("HELP.ABOUT_US.OUR_PURPOSE_TITLE")}</DetailsTitle>
							<DetailsDescription>{t("HELP.ABOUT_US.OUR_PURPOSE_DESC")}</DetailsDescription>
						</DetailsBox>

						<DetailsBox $backgroundUrl={BlueBackground2}>
							<DetailsTitle>{t("HELP.ABOUT_US.OUR_MISSION_TITLE")}</DetailsTitle>
							<DetailsDescription>{t("HELP.ABOUT_US.OUR_MISSION_DESC")}</DetailsDescription>
						</DetailsBox>

						<DetailsBox $backgroundUrl={BlueBackground1}>
							<DetailsTitle>{t("HELP.ABOUT_US.OUR_VISION_TITLE")}</DetailsTitle>
							<DetailsDescription>{t("HELP.ABOUT_US.OUR_VISION_DESC")}</DetailsDescription>
						</DetailsBox>
					</DetailsWrapper>
				</Container>
			</Background>

			<Container>
				<ValuesSectionWrapper>
					<TitleOurValues>{t("HELP.ABOUT_US.OUR_VALUES")}</TitleOurValues>
					<TilesWrapper>
						<OrangeTile>
							<TileText>{t("HELP.ABOUT_US.PEOPLE_VALUE")}</TileText>
						</OrangeTile>

						<OrangeTile>
							<TileText>{t("HELP.ABOUT_US.AUTHENTICITY_VALUE")}</TileText>
						</OrangeTile>

						<OrangeTile>
							<TileText>{t("HELP.ABOUT_US.PASSION_VALUE")}</TileText>
						</OrangeTile>

						<OrangeTile>
							<TileText>{t("HELP.ABOUT_US.SIMPLICITY_VALUE")}</TileText>
						</OrangeTile>

						<OrangeTile>
							<TileText>{t("HELP.ABOUT_US.QUALITY_VALUE")}</TileText>
						</OrangeTile>
					</TilesWrapper>
				</ValuesSectionWrapper>

				<InnerWrapper>
					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Mateusz Mierzwinski founder of localbini - picture"
								imgSmall={AboutUsFounderImgSmall}
								imgMedium={AboutUsFounderImgMedium}
								imgLarge={AboutUsFounderImgLarge}
							/>
						</ImgWrapper>
					</Col>

					<Col>
						<FounderDescWrapper>
							<CommonParagraph
								withMarginTop
								isSecondHeader
								mainTitle="Mateusz Mierzwinski"
								subTitle={t("HELP.ABOUT_US.OUR_FOUNDER")}
								description={withRaw("HELP.ABOUT_US.FOUNDER_DESCRIPTION", null)}
							/>
						</FounderDescWrapper>
					</Col>
				</InnerWrapper>
			</Container>
		</AppWrapper>
	);
};

export default AboutUsPage;
