import { array } from "yup";

import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

const useValidationSchema = (step: number) => {
	const { t } = useTranslation();

	const {
		yup: {
			validations,
			requiredStringMinMaxLength,
			requiredString,
			maxArrayItems,
			minMaxArrayItems,
			requiredNumber,
			requiredCheckbox,
			requiredMinMaxMinutes,
		},
		translations: { required, tooFew },
	} = useValidations();

	return [
		// STEP 1 start
		validations({
			title: requiredStringMinMaxLength(1, 100),
			country: requiredString,
			city: requiredString,
			languages: array().nullable().min(1, tooFew(1)).required(required),
			relevant_attractions: maxArrayItems(10),
			categories: minMaxArrayItems(1, 10),
			description: requiredStringMinMaxLength(150, 300),
			description_uniqueness: requiredStringMinMaxLength(500, 1000),
		}),
		// STEP 1 end

		// STEP 2 start
		validations({
			requirements: maxArrayItems(50),
			included: maxArrayItems(50),
			excluded: maxArrayItems(50),
			accessibility: maxArrayItems(50),
			location: validations({
				start: validations({
					coordinates: validations({
						lon: requiredNumber,
						lat: requiredNumber,
					}),
					formatted_address: requiredString,
				}),
				end: validations({
					coordinates: validations({
						lon: requiredNumber,
						lat: requiredNumber,
					}),
					formatted_address: requiredString,
				}),
			}),
		}),
		// STEP 2 end

		// STEP 3 start
		validations({
			duration_minutes: requiredMinMaxMinutes(60, 3600),
		}),
		// STEP 3 end

		// STEP 4 start
		validations({
			media_rights_confirmed_HELPER: requiredCheckbox(t("EXPERIENCE.FORM.ACCEPT_CONDITION_ERROR")),
		}),
		// STEP 4 end

		// STEP 5 start
		validations({
			pricing: validations(),
		}),
		// STEP 5 end
	][step];
};

export default useValidationSchema;
