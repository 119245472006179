import "i18n";
import "lazysizes";
import { FC, StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import RouteWrapper from "routing/components/RouteWrapper";

import store, { persistor } from "store/index";

const DevEnvWrapper: FC = ({ children }) =>
	process.env.NODE_ENV === "development" ? <StrictMode>{children}</StrictMode> : <>{children}</>;

const App: FC = () => (
	<DevEnvWrapper>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<HelmetProvider>
					<RouteWrapper />
				</HelmetProvider>
			</PersistGate>
		</Provider>
	</DevEnvWrapper>
);

export default App;
