import { Box, FormHelperText } from "@material-ui/core";
import { Field } from "formik";
import styled from "styled-components";

import ChipAction from "ui/Chip";

import dimensions from "styles/dimensions";

export interface OptionsListProps {
	name: string;
	options: any;
	chipWidth: number;
}

const Wrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;

const ChipWrapper = styled.div<{ width: number }>`
	margin: 5px 5px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${props => props.width}px;
`;

const Error = styled(FormHelperText)`
	margin-left: -${dimensions.sizes["30"]};
`;

interface IChipsField {
	clickable?: boolean;
	chipWidth?: number;
	singleSelect?: boolean;
	name: string;
	options: string[];
	disabled?: boolean;
}

const ChipsField = ({ clickable, chipWidth = 70, singleSelect = false, name, options, disabled }: IChipsField) => (
	<Field name={name}>
		{({ field, form: { setFieldTouched, setFieldValue }, meta }) => {
			const hasError = meta.touched && !!meta.error;
			const set = new Set(field.value);

			return (
				<Box>
					<Wrapper>
						{options.map(option => (
							<ChipWrapper key={option} width={chipWidth}>
								<ChipAction
									disabled={disabled}
									clickable={clickable}
									isClicked={set.has(option)}
									label={option}
									onClick={() => {
										if (set.has(option)) {
											set.delete(option);
										} else {
											if (singleSelect) {
												set.clear();
												set.add(option);
											} else {
												set.add(option);
											}
										}

										setFieldTouched(name, true);
										setFieldValue(name, Array.from(set));
									}}
								/>
							</ChipWrapper>
						))}
					</Wrapper>

					<Box>{hasError && <Error error>{meta.error}</Error>}</Box>
				</Box>
			);
		}}
	</Field>
);

export default ChipsField;
