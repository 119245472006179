import { PersonOutline } from "@material-ui/icons";
import styled from "styled-components";

import { Tooltip } from "ui/tooltips/Tooltip";

import colors from "styles/colors";

export const AvatarContainer = styled.div`
	margin: 0 auto 32px;
	text-align: center;
`;

export const DropzoneWrapper = styled.div`
	margin-bottom: 16px;
	display: inline-block;
`;

export const Desc = styled.p<{ error?: boolean }>`
	font-size: 14px;
	line-height: 24px;
	color: ${props => (props.error ? colors.red : "#666")};
`;

export const FieldContainer = styled.div`
	margin-bottom: 30px;

	& > div {
		width: 100%;
	}
`;

export const DropzoneContainer = styled.div<{ error?: boolean }>`
	background-color: #e5e5e5;
	border: ${props => (props.error ? `1px solid ${colors.red}` : "none")};
	height: 140px;
	width: 140px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DropzoneInside = styled.div`
	border: 2px dashed ${colors.white};
	border-radius: 50%;
	font-size: 16px;
	height: 115px;
	width: 115px;
	display: inherit;
	align-items: inherit;
	justify-content: inherit;
	cursor: pointer;
`;

export const AvatarImage = styled.img`
	height: 140px;
	width: 140px;
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
`;

export const StyledIcon = styled(PersonOutline)`
	color: ${colors.white};
`;

export const FieldWithTooltipContainer = styled.div`
	position: relative;

	& > .MuiTextField-root {
		width: 100%;

		.MuiInputBase-input {
			margin-right: 25px;
		}
	}
`;

export const StyledTooltip = styled(Tooltip)`
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
`;
