import useErrors from "hooks/useErrors";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

const useHandleSubdomain = () => {
	const { handleAndNotify } = useErrors();

	const handle = () => {
		try {
			const location = window.location;

			const baseDomain = process.env.REACT_APP_SITE_URL;

			if (!location || !baseDomain) {
				return;
			}

			const url = new URL(baseDomain);

			const subdomain = location.hostname.replace(url.hostname, "").replace(/^\.+|\.+$/g, "");

			if (subdomain.length > 0) {
				location.href =
					url.origin +
					fillRoute(paths.PARTNER_REDIRECT, {
						subdomain,
					});
			}
		} catch (e) {
			handleAndNotify(e);
		}
	};

	return { handle };
};

export default useHandleSubdomain;
