import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const StartAdornment = styled.div`
	min-height: 32px;
	display: flex;
	align-items: center;
`;

export const StyledAutocompleteTextField = styled(TextField)`
	.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
		padding-right: 50px;
	}
`;
