import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

interface ISaveButtonsProps {
	title: string;
	isSubmitting: boolean;
	isEditable: boolean;
	saveLabel: string;
	editLabel: string;
	saveAsDraft?: () => void;
	cancelForm?: () => void;
	onChangeEditable?: (v: boolean) => void;
	saveForReview?: () => void;
}

const DarkBlueBtn = styled(Button)`
	margin-right: 0.625rem;
	background-color: #1976d2;
	color: ${colors.white};
	font-size: 14px;

	&:hover {
		background-color: #166aba;
	}

	${media.phone`
    font-size: 12px;

    .MuiButton-root {
       padding: 6px 10px;
    }
  `};
`;

const OutlinedBlueBtn = styled(Button)`
	background-color: ${colors.white};
	color: #1976d2;
	font-weight: 500;
	border: solid 1px #1976d2;
	font-size: 14px;

	&:hover {
		background-color: ${colors.lightGray};
	}

	${media.phone`
    font-size: 12px;
  `};
`;

const InfoIcon = styled(ReportProblemOutlined)`
	margin-right: 0.875rem;
	width: 1.5rem;
	height: 1.5rem;
`;

const SaveBtnsContent = styled.div`
	margin-top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0.063rem 0 1.25rem;
	border-radius: 0.25rem;
	background-color: #bbdefb;

	${media.desktop`
    margin-top: 0.938rem;
    padding: 0.5rem;
    justify-content: center;
  `};
`;

const SaveDesc = styled.label`
	margin-right: 70px;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: #0d3c61;

	${media.phone`
    margin-right: 0;
  `};
`;

const SaveButtons = ({
	title,
	isSubmitting,
	isEditable,
	saveLabel,
	editLabel,
	saveAsDraft,
	cancelForm,
	onChangeEditable,
	saveForReview,
}: ISaveButtonsProps) => {
	const { t } = useTranslation("ui");
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const saveAsDraftAction = () => {
		if (saveAsDraft) {
			saveAsDraft();
		}
	};

	const cancelFormAction = () => {
		if (cancelForm) {
			cancelForm();
		}
	};

	const onChangeEditableAction = () => {
		if (onChangeEditable) {
			onChangeEditable(!isEditable);
		}
	};

	const saveForReviewAction = () => {
		if (saveForReview) {
			saveForReview();
		}
	};

	return (
		<Box
			display="flex"
			alignItems={isSmallScreen ? "flex-end" : "center"}
			justifyContent={isEditable ? "space-between" : "flex-end"}
			width="100%"
			height="100%"
			paddingBottom={5}
			flexDirection={isSmallScreen ? "column-reverse" : "row"}
		>
			{isEditable && (
				<SaveBtnsContent>
					<Box display="flex" marginBottom={isSmallScreen ? 3 : undefined}>
						<InfoIcon />
						<SaveDesc>{title}</SaveDesc>
					</Box>

					<Box display="flex" alignItems="center" justifyContent="space-between">
						<DarkBlueBtn onClick={saveAsDraftAction} variant="contained" disabled={isSubmitting}>
							{saveLabel}
						</DarkBlueBtn>

						<DarkBlueBtn onClick={saveForReviewAction} variant="contained" disabled={isSubmitting}>
							{t("SAVE_BUTTONS.SEND_TO_REVIEW_BTN")}
						</DarkBlueBtn>

						<OutlinedBlueBtn variant="outlined" onClick={cancelFormAction} disabled={isSubmitting}>
							{t("COMMON.CANCEL")}
						</OutlinedBlueBtn>
					</Box>
				</SaveBtnsContent>
			)}

			<Button color="primary" disabled={isEditable} variant="contained" onClick={onChangeEditableAction}>
				{editLabel}
			</Button>
		</Box>
	);
};

export default SaveButtons;
