import { IBookingIId } from "data/backoffice/bookings/types";

export type TRateAction = (currentStep: ESteps, data: {}, nextStep?: ESteps) => Promise<unknown>;

export interface IRateAction {
	action: TRateAction;
	bookingData?: IBookingIId;
}

// Important! The order matters.
export enum ESteps {
	NPS = "nps",
	EXPERIENCE = "experience",
	LOCALHOST = "localhost",

	FINISH = "finish",
}
