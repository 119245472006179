import {
	createStyles,
	FormControl,
	FormControlProps,
	FormHelperText,
	InputLabel,
	makeStyles,
	MenuItem,
	Select as MaterialSelect,
	SelectProps,
	Theme,
} from "@material-ui/core";
import { ReactNode } from "react";

export type Option = {
	value: string | number;
	label: ReactNode;
};

export interface ISelect extends Omit<SelectProps, "onChange" | "error"> {
	options: Option[];
	name: string;
	error?: string;
	formControlProps?: FormControlProps;
	onChange: (value: Option["value"]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: "100%",
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		select: {
			boxShadow: theme.shadows[1],
			"& ul": {
				backgroundColor: "#fdfdfd",
			},
		},
	}),
);

const Select = ({
	options,
	name,
	label,
	required,
	variant,
	value,
	onChange,
	error,
	formControlProps,
	...rest
}: ISelect) => {
	const classes = useStyles();

	return (
		<FormControl variant={variant} className={classes.formControl} {...formControlProps}>
			{!!label && (
				<InputLabel id={`select-label-${name}`} required={required} shrink>
					{label}
				</InputLabel>
			)}

			<MaterialSelect
				labelId={`select-label-${name}`}
				id={`select-id-${name}`}
				MenuProps={{
					classes: { paper: classes.select },
				}}
				value={value}
				variant={variant}
				onChange={event => onChange(event.target.value as Option["value"])}
				error={!!error}
				{...rest}
			>
				{options.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</MaterialSelect>

			{error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};

export default Select;
