import { Checkbox as MaterialCheckbox, CheckboxProps } from "@material-ui/core";

export interface ICheckbox extends Omit<CheckboxProps, "onChange"> {
	onChange?: (checked: boolean) => void;
}

const Checkbox = ({ color, onChange, ...rest }: ICheckbox) => (
	<MaterialCheckbox {...rest} color={color || "primary"} onChange={(_, checked) => !!onChange && onChange(checked)} />
);

export default Checkbox;
