import qs from "query-string";
import { Redirect, useLocation } from "react-router-dom";

import { paths } from "../paths";
import { ProtectedProps } from "../types";
import LayoutComponent from "./LayoutComponent";

const ProtectedRoute = ({ Component, isAuthenticated, expected, routerProps }: ProtectedProps) => {
	const location = useLocation();

	if (isAuthenticated !== expected) {
		if (!expected) {
			return <Redirect to={paths.HOMEPAGE} />;
		}

		if (expected) {
			return (
				<Redirect
					to={{
						pathname: paths.LOGIN,
						search: qs.stringify({ redirect: location.pathname + location.search + location.hash }),
					}}
				/>
			);
		}
	}

	return <LayoutComponent Component={Component} routerProps={routerProps} />;
};

export default ProtectedRoute;
