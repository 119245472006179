const getParams = <Shape extends Record<string, string>>(urlSearchParams: URLSearchParams): Shape => {
	const params = {};

	urlSearchParams.forEach((val, key) => {
		if (params[key] !== undefined) {
			if (!Array.isArray(params[key])) {
				params[key] = [params[key]];
			}

			params[key].push(val);
		} else {
			params[key] = val;
		}
	});

	return params as Shape;
};

export { getParams };
