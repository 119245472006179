import { Box, CircularProgress, CircularProgressProps, Typography } from "@material-ui/core";

const CircularProgressWithLabel = (props: CircularProgressProps & { label: number | string }) => (
	<Box position="relative" display="inline-flex">
		<CircularProgress variant="determinate" {...props} />

		<Box
			top={0}
			left={0}
			bottom={0}
			right={0}
			position="absolute"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Typography color="textPrimary">{props.label}</Typography>
		</Box>
	</Box>
);

export default CircularProgressWithLabel;
