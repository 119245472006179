import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const Wrapper = styled.div`
	width: 100%;
	padding: 14px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background: #ffeed6;
	border-radius: 6px;
`;

const FontStyles = css`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.1px;
	color: ${colors.mainFontColor};
`;

const Text = styled.span`
	${FontStyles};
`;

const TextOrange = styled(Text)`
	color: ${colors.sunShade};
	font-weight: bold;
`;

const CovidUpdateBtn = styled(Button)`
	${FontStyles};
	color: ${colors.sunShade};
	text-decoration: underline;
`;

const CovidWarningBox = ({ refGrid }: any) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { t } = useTranslation();

	const scrollToInfoAboutCovid = () => {
		if (refGrid.current) {
			window.scrollTo({
				behavior: "smooth",
				top: refGrid.current.offsetTop,
			});
		}
	};

	return (
		<Wrapper>
			<Box display="flex" flexWrap="wrap" width={isSmallScreen ? "100%" : "80%"}>
				<Box mr={5}>
					<ErrorOutline color="primary" fontSize="small" />
				</Box>

				<Box mr={2}>
					<TextOrange>{t("EXPERIENCE_DETAILS_TRAVELLER.COVID_UPDATE_TITLE")}:</TextOrange>
				</Box>
				<Text>{t("EXPERIENCE_DETAILS_TRAVELLER.COVID_UPDATE_DESC")}</Text>
			</Box>

			<Box display="flex" justifyContent="flex-end" width={isSmallScreen ? "100%" : "20%"}>
				<CovidUpdateBtn color="inherit" size="small" onClick={scrollToInfoAboutCovid}>
					{t("EXPERIENCE_DETAILS_TRAVELLER.COVID_UPDATE_BTN")}
				</CovidUpdateBtn>
			</Box>
		</Wrapper>
	);
};

export default CovidWarningBox;
