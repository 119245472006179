import { IClassification } from "../backoffice/classifications/types";
import { TCategory } from "../categories/types";
import { ECurrency } from "../financials/types";
import { IDaysOfWeek, IId, IInternalComment, TDateField } from "../types";
import { ILbReview, ILocalhostDetails } from "../users/types";

enum EPhysicalDifficultyLevel {
	EASY = "EASY",
	MODERATE = "MODERATE",
	CHALLENGING = "CHALLENGING",
}

enum EKindOfPricing {
	FIXED_PRICE = "FIXED_PRICE",
	TIERED_PRICE = "TIERED_PRICE",
}

export enum EExperienceStatus {
	AWAITING_HOSTS = "AWAITING_HOSTS",
	PUBLISHED = "PUBLISHED",
	RETIRED = "RETIRED",
	DRAFT = "DRAFT",
	AWAITING_REVIEW = "AWAITING_REVIEW",
	UNDER_REVIEW = "UNDER_REVIEW",
	NEEDS_CHANGES = "NEEDS_CHANGES",
	REJECTED = "REJECTED",
	PUBLISHED__DRAFT = "PUBLISHED__DRAFT",
	PUBLISHED__AWAITING_REVIEW = "PUBLISHED__AWAITING_REVIEW",
	PUBLISHED__UNDER_REVIEW = "PUBLISHED__UNDER_REVIEW",
	PUBLISHED__NEEDS_CHANGES = "PUBLISHED__NEEDS_CHANGES",
}

// Should be always these types of labels in private config!!!
export enum EExperienceFunctionalLabels {
	FAKE = "FAKE",
	NO_LABEL = "NO_LABEL",
}

export enum EActivityType {
	TOUR = "TOUR",
	ACTIVITY = "ACTIVITY",
}

export enum EExpType {
	COHOSTED = "COHOSTED",
	UNIQUE = "UNIQUE",
}

export enum EPromotingSections {
	DISCOVER = "DISCOVER",
	EXPLORE = "EXPLORE",
	CITY = "CITY",
	CATEGORY = "CATEGORY",
	COUNTRY = "COUNTRY",
	"UNIQUE-EXP-LP" = "UNIQUE-EXP-LP",
	"HIGHLIGHT-OF-THE-WEEK" = "HIGHLIGHT-OF-THE-WEEK",
	"PRICE-UPON-REQUEST" = "PRICE-UPON-REQUEST",
}

export enum ETypeRadioGroup {
	fixed = "fixed",
	tiered = "tiered",
}

export type TCurrencyType = keyof typeof ECurrency;
export type TActivityType = keyof typeof EActivityType;
export type TPhysicalDifficultyLevel = keyof typeof EPhysicalDifficultyLevel;
export type TExpType = keyof typeof EExpType;

export type TExperienceLabel = EExperienceFunctionalLabels | string;

export interface IRangeMinMax {
	min: number;
	max: number;
}

export interface Coordinates {
	lon: number;
	lat: number;
}

export interface Location {
	coordinates: Coordinates;
	formatted_address: string;
	tips?: string;
	name?: string;
	place_id?: string;
	timezone?: string;
}

export interface LocationParams {
	start: Location;
	end: Location;
}

export interface Schedule {
	available_days_of_week: IDaysOfWeek;
	availability_periods: TDateField[];
	excluded_dates?: string[];
}

export interface TourSpot extends Location {
	order: number;
}

export interface Pricing {
	currency?: ECurrency;
	kids_free_under: number | null;
	price_per_person?: number | null;
	private_price?: number | null;
	tiered_pricing_details?: Record<string, number>;
	kids_pricing_details?: Record<string, number>;
	filter_price?: number;
}

export interface IGalleryItem {
	id: string;
	title?: string;
	description?: string;
	// For experiences/details/Traveller
	media_url: string;
	created_on?: string;
}

export interface IExperienceInternal {
	classification_id?: string;
	exposures?: EPromotingSections[];
	label?: TExperienceLabel;
	liability_insurance?: boolean;
	suggested_route?: string;
	verification?: {
		is_verified?: boolean;
	};
}

export interface IExperienceDTO {
	// Special start
	id: string; // @todo:verify - only from response?
	internal?: IExperienceInternal;
	version?: number;
	lb_review?: ILbReview;
	// Special end
	//
	// Common start
	status?: EExperienceStatus;
	exp_type: EExpType;
	is_verified: boolean;
	// Common end
	//
	// STEP 1 start
	title: string;
	country: string;
	city: string;
	activity_type: TActivityType;
	relevant_attractions?: string[];
	categories: TCategory[]; // @todo:fix - change to optional
	description: string;
	description_uniqueness: string;
	// STEP 1 end
	//
	// STEP 2 start
	requirements?: string[];
	included?: string[];
	excluded?: string[];
	number_of_travelers: IRangeMinMax;
	accessibility?: string[];
	physical_difficulty_level: TPhysicalDifficultyLevel;
	health_restrictions?: string[];
	custom_health_restrictions?: string[];
	location: LocationParams;
	// STEP 2 end
	//
	// STEP 3 start
	duration_minutes: number;
	schedule: Schedule; // @todo:fix - change to optional
	cutoff_time_hours: number;
	cancellation_policy_hours: number;
	weather_sensitivities?: string[];
	disaster_policies?: string[];
	custom_disaster_policies?: string[];
	// STEP 3 end
	//
	// STEP 4 start
	media_gallery: IGalleryItem[];
	photo_main?: string;
	// STEP 4 END
	//
	// STEP 5 start
	pricing: Pricing;
	// STEP 5 end
	//

	alternative_titles?: Record<string, number>;
	languages: string[];
	tour_spots?: TourSpot[];
	private?: boolean;
	translations?: string[];
	localhost?: ILocalhostDetails;
	designed_by?: string | null;
	designer_details?: ILocalhostDetails | null;
	number_of_locals?: number;
	ratings: IRatings;
	internal_comments?: IInternalComment[];
}

export type IExperience = IExperienceDTO &
	IId & {
		pricingType: EKindOfPricing;
	};

export type IExperienceTemp = {
	priceType?: ETypeRadioGroup;
	changeImages?: boolean;
	kidsFreeUnder?: boolean;
	redirectStepButton?: number;
	mainPhoto?: IGalleryItem;
	imagesList?: IGalleryItem[];
	priceChart?: (emptyChartValues: Record<number, number>, pricing?: Pricing) => Record<string, number>;
	classification?: IClassification;
};

export type IExperienceWithTemp = IExperience & {
	temp?: IExperienceTemp;
};

export interface IRatings {
	average: number;
	count: number;
	values?: {
		1: number;
		2: number;
		3: number;
		4: number;
		5: number;
	};
	weighted_result?: number;
}

export type TUpdateExperienceFormValues = Partial<IExperience> & {
	photo_main_HELPER?: {
		media_url: string;
		id: string;
	};
	media_gallery_HELPER?: {
		media_url: string;
		id: string;
	}[];
	media_rights_confirmed_HELPER?: boolean;
	sendToReview_HELPER?: boolean;
};

export interface IUpcomingBooking {
	available_spots: number;
	city: string;
	country: string;
	date_time: string;
	eid: string;
	exp_currency: TCurrencyType;
	exp_type: TExpType;
	exp_duration_minutes: number | null;
	exp_filter_price: number | null;
	exp_photo_main: string | null;
	exp_ratings: IRatings | null;
	exp_title: string;
	id: string;
	language: string;
	localhost_details: {
		first_name: string;
		photo_url: string | null;
	} | null;
	localhost_id: string;
	utc_date_time: string;
	utc_offset: number;
}
