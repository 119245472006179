import { uniq } from "lodash-es";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { TConfigsCityIId } from "data/configs/types";

import useCity from "store/hooks/useCity";

import { ISharedOnboardingProps } from "../../types";
import CitiesSelectorLayout from "./components/CitiesSelectorLayout";

const CitiesSelector = ({ moveToNextStep, profileData, updateProfileData, isBusy }: ISharedOnboardingProps) => {
	const { citiesSorted } = useCity();

	const [selectedCities, setSelectedCities] = useState<string[]>(profileData?.cities || []);

	const isNextButtonDisabled = !citiesSorted.length || !selectedCities.length || !!isBusy;

	useEffect(() => {
		setSelectedCities(profileData?.cities || []);
	}, [profileData]);

	const handleCityAutocompleteChange = (event: ChangeEvent, value: TConfigsCityIId[]) => {
		const newSelectedCities = value.map(elem => elem.name);

		setSelectedCities(newSelectedCities.length ? uniq(newSelectedCities) : []);
	};

	const handleNextButtonClick = async (event: MouseEvent) => {
		event.preventDefault();

		const apiCall = await updateProfileData({ cities: selectedCities });

		if (apiCall === true) {
			moveToNextStep();
		}
	};

	const citiesSelectorLayoutProps = {
		selectedCities,
		cities: citiesSorted,
		handleNextButtonClick,
		handleCityAutocompleteChange,
		isNextButtonDisabled,
	};

	return <CitiesSelectorLayout {...citiesSelectorLayoutProps} />;
};

export default CitiesSelector;
