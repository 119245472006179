import { Box, Chip } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip/Chip";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

interface IChipStringArray {
	valuesArray: string[];
	color?: ChipProps["color"];
	translationKey?: string;
}

const Wrapper = styled(Box)<{
	$backgroundBox: string;
	$borderRadiusBox: string;
	$paddingLeft: string;
	$widthBox: "initial" | "max-content";
}>`
	width: ${props => props.$widthBox};
	display: flex;
	flex-wrap: wrap;
	padding: 0.375rem 0.625rem 0.625rem ${props => props.$paddingLeft};
	background-color: ${props => props.$backgroundBox};
	border-radius: ${props => props.$borderRadiusBox};
`;

const ChipStringArray = ({ valuesArray, color = "primary", translationKey }: IChipStringArray) => {
	const { withTaggedKey } = useTranslation();

	return (
		<Wrapper
			className="ChipStringArray__wrapper"
			$backgroundBox={color === "default" ? colors.white : "none"}
			$borderRadiusBox={color === "default" ? "0.25rem" : "none"}
			$paddingLeft={color === "default" ? "0.375rem" : "0"}
			$widthBox={color === "default" ? "max-content" : "initial"}
		>
			{valuesArray &&
				valuesArray.map((v, i) => (
					<Box pt={2} pr={5} key={`${v}-${i}`}>
						<Chip color={color} label={!!translationKey ? withTaggedKey(translationKey, { category_name: v }) : v} />
					</Box>
				))}
		</Wrapper>
	);
};

export default ChipStringArray;
