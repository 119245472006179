import { Box, Button } from "@material-ui/core";
import styled from "styled-components";

import { EPromotingSections, IExperienceDTO, IUpcomingBooking } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { MIN_PEOPLE_PER_GROUP } from "utils/constants";

import colors from "styles/colors";
import { Container, RobotoFontFamily } from "styles/common";

import { LastMinuteBox } from "../LastMinute";

const Wrapper = styled.div<{ withLastBookings?: boolean }>`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: ${props => (props.withLastBookings ? 216 : 162)}px;
	padding-top: ${props => (props.withLastBookings ? 0 : 10)}px;
	background: ${colors.white};
	border-radius: 4px 4px 0 0;
	box-shadow: 0 0 40px 0 #cecece;
	z-index: 10;
`;

const Title = styled.strong`
	display: block;
	margin: 0 0 8px;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 500;
	color: ${colors.mainFontColor};
`;

const StyledButton = styled(Button)`
	min-width: 100px;
	height: 48px;
`;

const PriceLabel = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 26px;
	line-height: 1;
	color: ${colors.mainFontColor};
	font-weight: bold;
`;

const FromLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.2;
	color: ${colors.mainFontColor};
`;

const PerPersonLabel = styled.span`
	margin-left: 4px;
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	line-height: 1;
	color: ${colors.mainFontColor};
`;

interface IBookingPanelForMobileProps extends Pick<IExperienceDTO, "internal"> {
	showPopupCallback: () => void;
	titleStr: string;
	pricePerPerson?: number;
	currency: string;
	upcomingBookings?: IUpcomingBooking[];
	initialModalOpened: boolean;
	groupLimit: number;
}

const BookingPanelForMobile = ({
	showPopupCallback,
	titleStr,
	pricePerPerson,
	currency,
	upcomingBookings,
	initialModalOpened,
	internal,
	groupLimit,
}: IBookingPanelForMobileProps) => {
	const { t, withValuesAsString } = useTranslation();

	const priceUponRequest = internal?.exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);

	return (
		<Wrapper withLastBookings={!!upcomingBookings?.length}>
			{!!upcomingBookings?.length && (
				<LastMinuteBox
					upcomingBookings={upcomingBookings}
					initialModalOpened={initialModalOpened}
					bookCallback={showPopupCallback}
					onlyButton
				/>
			)}

			<Container>
				<Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom="20px">
					<Box paddingRight="10px">
						<Title>{titleStr}</Title>
						{!priceUponRequest && pricePerPerson ? (
							<>
								<Box display="flex" alignItems="flex-end">
									<PriceLabel>
										{currency}
										{pricePerPerson}
									</PriceLabel>
									<PerPersonLabel>
										{withValuesAsString("EXPERIENCE_DETAILS_TRAVELLER.AMOUNT_PER_PERSON", {
											groupLimit: groupLimit > MIN_PEOPLE_PER_GROUP ? groupLimit : MIN_PEOPLE_PER_GROUP,
										})}
									</PerPersonLabel>
								</Box>
							</>
						) : (
							<FromLabel>{t("EXPERIENCE_DETAILS_TRAVELLER.PRICE_UPON_REQUEST")}</FromLabel>
						)}
					</Box>

					<Box display="flex" justifyContent="flex-end" height="80px">
						<StyledButton color="primary" variant="contained" onClick={() => showPopupCallback()}>
							{t(
								!priceUponRequest && pricePerPerson
									? "EXPERIENCE_DETAILS_TRAVELLER.BOOK_BTN"
									: "EXPERIENCE_DETAILS_TRAVELLER.SEND_REQUEST_FOR_PRICE",
							)}
						</StyledButton>
					</Box>
				</Box>
			</Container>
		</Wrapper>
	);
};

export default BookingPanelForMobile;
