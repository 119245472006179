import qs from "query-string";
import { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { IBookingIId } from "data/backoffice/bookings/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import ratingsService from "services/api/ratings";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectGuest, selectUser } from "store/selectors/user";
import { fetchGuestAuthCredentials, userActions } from "store/slices/users";

import fillRoute from "utils/routes";

import Dialog from "ui/Dialog";

import HomePage from "../home";
import RatingsFactory from "./components/RatingFactory";
import { validCurrentState } from "./shared";
import { ESteps, TRateAction } from "./types";

const RatingPage = ({ location, history, match }: RouteComponentProps) => {
	const { addSuccess } = useNotification();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const loggedUser = useAppSelector(selectUser);
	const { data: guestCredentials, status: guestCredentialsStatus } = useAppSelector(selectGuest);

	const { step, booking_id } = match.params as { step?: ESteps; booking_id: string };

	const { token } = qs.parse(location.search);

	const [confirmedStep, setConfirmedStep] = useState<ESteps>();
	const [showDialog, setShowDialog] = useState<boolean>(true);
	const [booking, setBooking] = useState<IBookingIId>();

	const closeDialog = () => {
		setShowDialog(false);

		history.push(paths.HOMEPAGE);
	};

	const ratingsRoute = (nextStep: ESteps) =>
		fillRoute(paths.RATINGS, { booking_id, step: nextStep, ...(token && { search: { token } }) });

	const fetchRatingAndBookingDetails = async (withGuestCredentials?: boolean) => {
		try {
			if (!booking) {
				try {
					const loadedBooking = await bookingsService.getBooking(booking_id, withGuestCredentials);

					setBooking(loadedBooking.data);
				} catch (e) {
					handleAndNotify(e);
				}
			}

			const ratingStateLocal = await ratingsService.getRatingByBooking(booking_id, withGuestCredentials);

			const validationStep = validCurrentState(ratingStateLocal);

			if (validationStep !== step) {
				history.replace(ratingsRoute(validationStep));
			} else {
				setConfirmedStep(step);
			}
		} catch (e) {
			handleAndNotify(e);

			closeDialog();
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(fetchGuestAuthCredentials({ token: token as string, trigger: paths.RATINGS }));
		} else if (loggedUser) {
			fetchRatingAndBookingDetails();
		}

		// eslint-disable-next-line
	}, [booking_id, step, token, loggedUser]);

	useEffect(() => {
		if (guestCredentialsStatus === "success" && guestCredentials?.trigger === paths.RATINGS) {
			dispatch(userActions.resetGuestStatus());

			fetchRatingAndBookingDetails(true);
		}

		// eslint-disable-next-line
	}, [guestCredentialsStatus]);

	const action: TRateAction = async (currentStep, data, nextStep) => {
		try {
			if (!nextStep) {
				closeDialog();

				return;
			}

			const requestData = {
				...data,
				booking_id,
			};

			await ratingsService.postRatings(requestData, currentStep, token ? true : undefined);

			addSuccess("RATING.STAGE.SAVED", true);

			// @ts-ignore
			if (currentStep === ESteps.NPS && requestData?.answer === 0) {
				history.replace(ratingsRoute(ESteps.FINISH));
			} else {
				history.replace(ratingsRoute(nextStep));
			}
		} catch (e) {
			handleAndNotify(e);
		}
	};

	if (!token && !loggedUser) {
		return <Redirect to={fillRoute(paths.LOGIN, { search: { redirect: location.pathname } })} />;
	}

	return (
		<>
			<Dialog showDialog={showDialog} handleClose={closeDialog} decorator="withoutLogo" minHeight="auto">
				<RatingsFactory step={confirmedStep} action={action} bookingData={booking} />
			</Dialog>

			<HomePage />
		</>
	);
};

export default RatingPage;
