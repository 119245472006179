import { memo } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { Description, SmallerTitle } from "ui/CommonParagraph";

import { Container } from "styles/common";
import media from "styles/media";

import { WrapperSection } from "../../index";

const Background = styled.div`
	width: 100%;
	padding: 5rem 0 1rem;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    padding: 5.5rem 0 1rem;
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const About = memo(() => {
	const { t, withRaw } = useTranslation();

	return (
		<WrapperSection>
			<Background>
				<Container>
					<SmallerTitle textAlignCenter>{t("UNIQUE_EXPERIENCES.ABOUT_TITLE")}</SmallerTitle>

					<Description textAlignCenter>{withRaw("UNIQUE_EXPERIENCES.ABOUT_DESCRIPTION", null)}</Description>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default About;
