import { ReactComponent as IconTerribleLarge } from "assets/icons/ratings/large/rate_icon_1color.svg";
import { ReactComponent as IconNotBadLarge } from "assets/icons/ratings/large/rate_icon_3color.svg";
import { ReactComponent as IconVeryGoodLarge } from "assets/icons/ratings/large/rate_icon_5color.svg";
import { ReactComponent as IconTerrible } from "assets/icons/ratings/rate_icon_1color.svg";
import { ReactComponent as IconBad } from "assets/icons/ratings/rate_icon_2color.svg";
import { ReactComponent as IconNotBad } from "assets/icons/ratings/rate_icon_3color.svg";
import { ReactComponent as IconGood } from "assets/icons/ratings/rate_icon_4color.svg";
import { ReactComponent as IconVeryGood } from "assets/icons/ratings/rate_icon_5color.svg";

import { IRateIcons } from "components/Ratings/types";

export const icons: IRateIcons = {
	1: {
		icon: IconTerrible,
		label: "RATING.USER.CHOICE.TERRIBLE",
		color: "#E53935",
		answer: 1,
	},
	2: {
		icon: IconBad,
		label: "RATING.USER.CHOICE.BAD",
		color: "#FF8441",
		answer: 2,
	},
	3: {
		icon: IconNotBad,
		label: "RATING.USER.CHOICE.NOT_BAD",
		color: "#FFCA28",
		answer: 3,
	},
	4: {
		icon: IconGood,
		label: "RATING.USER.CHOICE.GOOD",
		color: "#4CAF50",
		answer: 4,
	},
	5: {
		icon: IconVeryGood,
		label: "RATING.USER.CHOICE.VERY_GOOD",
		color: "#00C853",
		answer: 5,
	},
};

export const iconsShort: IRateIcons = {
	1: {
		icon: IconTerribleLarge,
		label: "RATING.NPS.NO",
		color: "#E53935",
		answer: 0,
	},
	2: {
		icon: IconNotBadLarge,
		label: "RATING.NPS.MAYBE",
		color: "#FFCA28",
		answer: 1,
	},
	3: {
		icon: IconVeryGoodLarge,
		label: "RATING.NPS.YES",
		color: "#00C853",
		answer: 2,
	},
};
