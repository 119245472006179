import { Divider } from "@material-ui/core";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export const Title = styled.h1`
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: bold;
	line-height: 1.06;
	letter-spacing: 0.56px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
`;

export const Col = styled.div`
	flex: 1;
`;

export const StyledDivider = styled(Divider)`
	margin: 25px 0;
`;

export const Text = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.1;
	color: ${colors.mainFontColor};
`;
