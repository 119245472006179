import { Box } from "@material-ui/core";
import { memo, ReactNode } from "react";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

interface IWhyLocalbiniProps {
	description: string;
	icon: ReactNode;
	title: string;
}

const MainTitle = styled.h2`
	margin: 14px 0 0;
	font-family: ${RobotoFontFamily};
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.14px;
	text-align: center;
	text-transform: uppercase;

	${media.tablet`
    font-size: 14px;
    letter-spacing: 0.08px;
  `};
`;

const Description = styled.h3`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.48;
	letter-spacing: 0.08px;
	text-align: center;
	color: ${colors.secondaryGray};

	${media.tablet`
    font-size: 12px;
    letter-spacing: 0.06px;
  `};
`;

const WhyLocalbini = memo(({ description, icon, title }: IWhyLocalbiniProps) => (
	<Box
		display="flex"
		alignItems="center"
		justifyContent="flex-start"
		flexDirection="column"
		width="300px"
		pt={16}
		mr={10}
	>
		{icon}
		<MainTitle>{title}</MainTitle>
		<Description>{description}</Description>
	</Box>
));

export default WhyLocalbini;
