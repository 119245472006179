import { Box, Grid } from "@material-ui/core";
import { DateRange } from "newer-pickers";
import { useState } from "react";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS } from "utils/dates";

import FilterSearch from "ui/FilterSearch";
import RangeInput, { IRangeInputValues } from "ui/forms/date-time/RangeInput";
import RangePicker from "ui/forms/date-time/RangePicker";

export interface ISelectedFilters {
	dateTime: DateRange<Date> | null[];
	price: IRangeInputValues;
}

interface IFilterSearchPartnerBookingsProps {
	applySelectedFiltersAction: (v: ISelectedFilters) => void;
}

const FilterSearchPartnerBookings = ({ applySelectedFiltersAction }: IFilterSearchPartnerBookingsProps) => {
	const { t } = useTranslation();

	const initialDates = [null, null];

	const defaultRangeInput = {
		from: "",
		to: "",
	};

	const [selectedDates, setSelectedDates] = useState<DateRange<Date> | null[]>(initialDates);
	const [selectedPrice, setSelectedPrice] = useState<IRangeInputValues>(defaultRangeInput);

	const setDefaultValues = () => {
		setSelectedDates(initialDates);
		setSelectedPrice(defaultRangeInput);
	};

	const onChangeDates = (value: DateRange<Date>) => {
		setSelectedDates(value);
	};

	const onChangePriceInput = (value: IRangeInputValues) => setSelectedPrice(value);

	const setFilters = (value: boolean) => {
		if (value) {
			applySelectedFiltersAction({
				dateTime: selectedDates,
				price: selectedPrice,
			});
		}
	};

	return (
		<FilterSearch onClickApply={setFilters} onClickClear={setDefaultValues}>
			<Grid container spacing={10}>
				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<RangePicker
						labelStart={t("PARTNER_BOOKINGS.FILTERS.LABEL.DATE_FROM")}
						labelEnd={t("PARTNER_BOOKINGS.FILTERS.LABEL.DATE_TO")}
						// @ts-ignore
						date={selectedDates}
						setDate={onChangeDates}
						inputDateFormat={DATE_FORMATS.DATE_FORMAT2}
						mask="__.__.____"
						colorOfInputBorder="rgba(0, 0, 0, 0.42)"
					/>
				</Grid>

				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<Box position="relative" top="2px">
						<RangeInput
							initialValue={selectedPrice}
							labelFirstInput={t("PARTNER_BOOKINGS.FILTERS.LABEL.MIN_PRICE")}
							labelSecondInput={t("PARTNER_BOOKINGS.FILTERS.LABEL.MAX_PRICE")}
							handleChange={onChangePriceInput}
							name="price-input-range"
							typeInputs="number"
							withoutDecimalsRemove
						/>
					</Box>
				</Grid>
			</Grid>
		</FilterSearch>
	);
};

export default FilterSearchPartnerBookings;
