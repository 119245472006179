import { useMemo } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { MY_SETTINGS_TABS, paths } from "routing/paths";

import fillRoute from "utils/routes";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import Tabs from "ui/Tabs";
import { ITabsListItem } from "ui/Tabs/types";

import colors from "styles/colors";
import { Container, MontserratFontFamily, RobotoFontFamily } from "styles/common";

import AccountSettings from "./tabs/AccountSettings";
import SecuritySettings from "./tabs/SecuritySettings";

const Header = styled.h1`
	font-size: 24px;
	font-weight: 600;
	font-family: ${MontserratFontFamily};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	color: ${colors.mainFontColor};
	margin: 0 0 35px;
`;

const Wrapper = styled.div`
	margin: 2.5rem 0;
	font-family: ${RobotoFontFamily};
`;

const AccountMySettingsPage = () => {
	const { t, i18n } = useTranslation();

	const tabsList: ITabsListItem[] = useMemo(
		() => [
			{
				title: t("SETTINGS.ACCOUNT_SETTINGS"),
				route: fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.ACCOUNT }),
				content: <AccountSettings />,
			},
			{
				title: t("SETTINGS.SECURITY_SETTINGS"),
				route: fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.SECURITY }),
				content: <SecuritySettings />,
			},
		],

		// eslint-disable-next-line
		[i18n.language],
	);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.SETTINGS_LABEL")} />

				<Wrapper>
					<Header>{t("TOPBAR.SETTINGS_LABEL")}</Header>
					<Tabs tabs={tabsList} scrollBreakpoint="sm" />
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default AccountMySettingsPage;
