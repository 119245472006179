import { Button, ButtonGroup } from "@material-ui/core";
import styled from "styled-components";

import { styleConfig, VariantStyle } from "./config";

interface ISwitcherValues {
	value: string;
	label: string;
}

interface ISwitcherButton {
	variant?: VariantStyle;
	switcherValue: string;
	handleClickSwitcher: (v: string) => void;
	mainConfig: {
		first: ISwitcherValues;
		second: ISwitcherValues;
	};
	isDisabled?: boolean;
	customHeight?: number;
}

const StyledButton = styled(Button)<{
	$backgroundColor: string;
	$fontColor: string;
	$border: string;
	$customHeight?: number;
}>`
	height: ${props => props.$customHeight || 25}px;
	background: ${props => props.$backgroundColor};
	color: ${props => props.$fontColor};
	border: ${props => props.$border};
	border-radius: 30px;
	padding: 0 30px;
`;

const SwitcherButton = ({
	variant = VariantStyle.orange,
	mainConfig,
	switcherValue,
	handleClickSwitcher,
	isDisabled = false,
	customHeight,
}: ISwitcherButton) => {
	const handleSwitcher = (value: string) => {
		handleClickSwitcher(value);
	};

	return (
		<ButtonGroup disabled={isDisabled}>
			<StyledButton
				$customHeight={customHeight}
				$backgroundColor={
					switcherValue === mainConfig.first.value
						? styleConfig[variant].checked.backgroundColor
						: styleConfig[variant].unchecked.backgroundColor
				}
				$fontColor={
					switcherValue === mainConfig.first.value
						? styleConfig[variant].checked.fontColor
						: styleConfig[variant].unchecked.fontColor
				}
				$border={
					switcherValue === mainConfig.first.value
						? styleConfig[variant].checked.border
						: styleConfig[variant].unchecked.border
				}
				onClick={() => handleSwitcher(mainConfig.first.value)}
				variant="contained"
			>
				{mainConfig.first.label}
			</StyledButton>

			<StyledButton
				$customHeight={customHeight}
				$backgroundColor={
					switcherValue === mainConfig.second.value
						? styleConfig[variant].checked.backgroundColor
						: styleConfig[variant].unchecked.backgroundColor
				}
				$fontColor={
					switcherValue === mainConfig.second.value
						? styleConfig[variant].checked.fontColor
						: styleConfig[variant].unchecked.fontColor
				}
				$border={
					switcherValue === mainConfig.second.value
						? styleConfig[variant].checked.border
						: styleConfig[variant].unchecked.border
				}
				onClick={() => handleSwitcher(mainConfig.second.value)}
				variant="contained"
			>
				{mainConfig.second.label}
			</StyledButton>
		</ButtonGroup>
	);
};

export default SwitcherButton;
