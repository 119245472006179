import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useCallback } from "react";

import { IUpcomingBooking } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { minutesToHM } from "utils/dates";

import RecordUpcomingBooking from "components/experiences/RecordUpcomingBooking";
import { carouselSwiperConfig } from "components/experiences/RecordUpcomingBooking/config";

import CarouselSwiper from "ui/CarouselSwiper";

import { BoxWithGrayBackgroundAbsolute, Subtitle, Title } from "./styled";

interface IUpcomingBookings {
	upcomingBookings: IUpcomingBooking[];
}

const UpcomingBookings = ({ upcomingBookings }: IUpcomingBookings) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const { t } = useTranslation();

	const createListOfSliders = useCallback(
		() =>
			upcomingBookings.map(v => (
				<RecordUpcomingBooking
					key={`${v.eid}-${v.exp_title}`}
					eid={v.eid}
					avatarUrl={v.localhost_details?.photo_url || undefined}
					city={v.city}
					hours={minutesToHM(v.exp_duration_minutes)}
					imgUrl={v.exp_photo_main}
					name={v.localhost_details?.first_name || t("SEARCH.EXPERIENCES.LOCALHOST")}
					pricePerPerson={v.exp_filter_price}
					priceCurrency={v.exp_currency}
					ratings={v.exp_ratings}
					title={v.exp_title}
					spots={v.available_spots}
					dateTime={v.utc_date_time}
					utcOffset={v.utc_offset}
					localhostId={v.localhost_id}
				/>
			)),

		// eslint-disable-next-line
		[upcomingBookings],
	);

	return (
		<>
			<BoxWithGrayBackgroundAbsolute
				marginTop={isMobile ? "55px" : "70px"}
				paddingTop="40px"
				paddingBottom="40px"
				$calcWidth={
					isMobile
						? // @ts-ignore
						  document.body.clientWidth - document.querySelector(".MuiBreadcrumbs-root")?.clientWidth
						: document.body.clientWidth
				}
			>
				<Box position="relative" paddingBottom="40px">
					<Title>Last minute</Title>

					<Subtitle>
						Hurry up! Especially for you, we've selected Experiences that are starting soon in your area. Join them and
						have fun.
					</Subtitle>
				</Box>

				<CarouselSwiper
					heightSlide="inherit"
					widthSlide="inherit"
					paddingBox={5}
					settings={carouselSwiperConfig}
					slides={createListOfSliders()}
					hidePrevNextBtn={upcomingBookings.length < 2}
				/>
			</BoxWithGrayBackgroundAbsolute>
		</>
	);
};

export default UpcomingBookings;
