import { SnackbarOrigin } from "@material-ui/core/Snackbar/Snackbar";

interface IConfig {
	autoHideDuration: number | null;
	positionVertical: SnackbarOrigin["vertical"];
	positionHorizontal: SnackbarOrigin["horizontal"];
}

const config: IConfig = {
	autoHideDuration: 25000,
	positionVertical: "bottom",
	positionHorizontal: "center",
};

export default config;
