import jwtDecode, { JwtPayload } from "jwt-decode";
import { has } from "lodash-es";

export const checkPermission = (permissionKey: string, token?: string): null | boolean => {
	if (!token) {
		return null;
	}

	const tokenDecoded = jwtDecode<JwtPayload>(token);

	return has(tokenDecoded, permissionKey);
};
