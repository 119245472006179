import registerMainImg from "assets/images/RegisterMainImg.jpg";

import RegisterView from "components/auth/RegisterView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

const RegisterPage = () => (
	<AppWithImageWrapper image={registerMainImg}>
		<RegisterView />
	</AppWithImageWrapper>
);

export default RegisterPage;
