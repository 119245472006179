import { blueGrey, green, grey, orange, red } from "@material-ui/core/colors";
import { createTheme, jssPreset, responsiveFontSizes } from "@material-ui/core/styles";
import { create } from "jss";
import jssPluginTemplate from "jss-plugin-template";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";

export const jss = create({
	plugins: [...jssPreset().plugins, jssPluginTemplate()],
	insertionPoint: "jss-insertion-point",
});

// if body.fontSize === 16px
const sizing = {
	xxs: "0.25rem", // 4px
	xs: "0.5rem", // 8px
	s: "0.75rem", // 12px
	m: "1rem", // 16px
	l: "1.25rem", // 20px
	xl: "1.5rem", // 24px
	xxl: "1.75rem", // 28px
	xxxl: "2rem", // 32px
};

export const theme = responsiveFontSizes(
	createTheme({
		// @ts-ignore
		sizing,
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1366,
				xl: 1920,
			},
		},
		spacing: 2,
		palette: {
			type: "light",
			primary: {
				main: orange[400],
			},
			secondary: {
				main: orange[600],
			},
			text: {
				primary: blueGrey[900],
				secondary: grey[600],
				homePageFont: colors.mainFontColor,
			},
			success: {
				main: green[500],
			},
			error: {
				main: red[700],
			},
			warning: {
				main: red[600],
			},
			background: {
				default: "#fff",
				secondary: colors.lightGrayWhite,
				greyBackground: "#f3f5f9",
			},
			grey: {
				50: colors.lightGray,
			},
		},
		shadows: [
			"none",
			"0 0 10px 0 #cecece",
			"0 0 40px 0 #cecece",
			"0 0 8px 0 rgba(55, 71, 79, 0.17)",
			"0 0 5px 0 #cecece",
			"0 0 30px 2px rgba(206, 206, 206, 0.52)",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
		],
		typography: {
			fontFamily: RobotoFontFamily,
			subtitle2: {
				color: blueGrey[900],
				fontSize: "12px",
			},
			h1: {
				fontFamily: MontserratFontFamily,
			},
			h2: {
				fontFamily: MontserratFontFamily,
			},
			h3: {
				fontFamily: MontserratFontFamily,
			},
			h4: {
				fontFamily: MontserratFontFamily,
			},
			h5: {
				fontFamily: MontserratFontFamily,
			},
			h6: {
				fontFamily: MontserratFontFamily,
			},
			button: {
				fontFamily: RobotoFontFamily,
				fontWeight: 500,
				textTransform: "capitalize",
			},
		},
		overrides: {
			MuiAppBar: {
				colorPrimary: {
					backgroundColor: "#fff",
				},
			},
			MuiTab: {
				root: {
					"&$selected": {
						color: orange[400],
					},
				},
			},
			MuiLink: {
				underlineHover: {
					"&:hover": {
						textDecoration: "none",
					},
				},
			},
			MuiSelect: {
				icon: {
					color: orange[400],
				},
			},
			MuiButton: {
				outlined: {
					fontWeight: "normal",
				},
				containedPrimary: {
					color: "#fff",
					textTransform: "capitalize",
					boxShadow: "0 0 8px 0 rgba(55, 71, 79, 0.17)",
					fontWeight: 500,
					borderRadius: "4px",
					"&:hover": {
						background: "#546E7A",
					},
					"&:active": {
						background: "#546E7A",
					},
				},
				sizeLarge: {
					paddingTop: 11,
					paddingBottom: 11,
				},
			},
			MuiInput: {
				root: {
					padding: "6px 8px",
				},
				underline: {
					padding: "0 !important",
				},
			},
			MuiAccordion: {
				root: {
					border: "none",
					boxShadow: "none !important",
					"&:before": {
						backgroundColor: "none",
					},
					"&$expanded": {
						margin: 0,
					},
				},
			},
			MuiAccordionSummary: {
				root: {
					overflow: "hidden",
				},
				content: {
					marginTop: 12,
					marginBottom: 12,
				},
			},
			MuiTypography: {
				colorPrimary: {
					color: "#000",
				},
			},
			MuiInputLabel: {
				outlined: {
					color: grey[500],
				},
			},
			MuiFormControlLabel: {
				root: {
					padding: 20,
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: "30px",
				},
			},
			MuiChip: {
				colorPrimary: {
					backgroundColor: orange[100],
				},
				deleteIconColorPrimary: {
					color: grey[500],
				},
			},
			MuiSwitch: {
				switchBase: {
					color: grey[200],
				},
				track: {
					backgroundColor: grey[500],
				},
				thumb: {
					boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)",
				},
			},
			MuiFormControl: {
				root: {
					"&.full": {
						width: "100%",
					},
					"&.break": {
						marginBottom: dimensions.spaces.xlarge,
					},
					"&.nomargin": {
						marginBottom: 0,
					},
				},
			},
		},
	}),
);
