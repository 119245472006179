import { isArray } from "lodash-es";
import React from "react";

import { StandardLink } from "styles/common";

import { baseGoogleMapsUrl, linkCommonProps } from "./config";

interface ILinkGoogleMaps {
	address?: string | string[] | null;
}

const LinkGoogleMaps = ({ address }: ILinkGoogleMaps) => {
	if (!address) {
		return null;
	}

	return isArray(address) ? (
		<>
			{address.map((item, key) => (
				<React.Fragment key={key}>
					{key > 0 && ", "}
					<StandardLink href={`${baseGoogleMapsUrl}/${item}`} {...linkCommonProps}>
						{item}
					</StandardLink>
				</React.Fragment>
			))}
		</>
	) : (
		<StandardLink href={`${baseGoogleMapsUrl}/${address}`} {...linkCommonProps}>
			{address}
		</StandardLink>
	);
};

export default LinkGoogleMaps;
