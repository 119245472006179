import { Button, createStyles, Dialog, DialogContent, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { TimePicker } from "@material-ui/pickers";
import { TimePickerViewsProps } from "@material-ui/pickers/TimePicker/TimePicker";
import { format, isValid, parse } from "date-fns";
import { isUndefined } from "lodash-es";
import { memo, useState } from "react";

import useTranslation from "hooks/useTranslation";

import MuiDateProvider from "providers/MuiDateProvider";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

export interface ITimePicker {
	callBackTime: (selectedTime) => void;
	label: string;
	isOpen?: boolean;
	typeButton?: "CHIP" | "TIME_BTN";
}

const useStyles = makeStyles(() =>
	createStyles({
		addMoreBtn: {
			borderRadius: "16px",
			padding: "3px 15px",
			textTransform: "lowercase",
		},
		dateContent: {
			backgroundColor: colors.lightGrayWhite,
		},
		toolbar: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
		},
		input: {
			maxWidth: 300,
			width: "100%",
			"& .MuiInputBase-input": {
				fontSize: 35,
				color: "white",
				fontWeight: 400,
				fontFamily: MontserratFontFamily,
			},
			"& svg": {
				color: "white",
			},
		},
		inputField: {
			width: "100%",
		},
		picker: {
			background: colors.sunShade,
			padding: 15,
			display: "flex",
			flexDirection: "column",
		},
		dialogContent: {
			padding: "0 !important",
		},
		active: {
			fontWeight: 700,
			color: "white",
		},
		button: {
			background: colors.secondaryGray,
			color: "white",
			marginTop: 5,
		},
	}),
);

const now = new Date();
const timeFormat = "HH:mm";
const initOpenTo = "hours";

const setTime = (date: Date, hours: number, minutes: number) => {
	date.setHours(hours);
	date.setMinutes(minutes);

	return date;
};

const isValidHour = (hour?: string) => {
	if (isUndefined(hour)) {
		return false;
	}

	const intHours = parseInt(hour, 10);
	return intHours >= 0 && intHours <= 24;
};

const isValidMinutes = (minutes?: string) => {
	if (isUndefined(minutes)) {
		return false;
	}

	const intMinutes = parseInt(minutes, 10);
	return intMinutes >= 0 && intMinutes <= 59;
};

const formatTime = (date: Date) => format(date, timeFormat);
const parseTime = (date?: string) => parse(date || "", timeFormat, now);
const isValidTime = (date: string) => isValid(parseTime(date));
const is24h = (date: Date) => date.getHours() > 12 || date.getHours() === 0;

export const TimePickerComponent = memo(({ callBackTime, isOpen, label }: ITimePicker) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [open, setOpen] = useState<boolean>(isOpen || false);
	const [selectedTime, setSelectedTime] = useState<Date>(now);
	const [tempTime, setTempTime] = useState<string>(formatTime(now));
	const [AMPM, setAMPM] = useState<"AM" | "PM">(is24h(now) ? "PM" : "AM");
	const [openTo, setOpenTo] = useState<TimePickerViewsProps["openTo"]>(initOpenTo);
	const [refresh, setRefresh] = useState<number>(0);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const changePickerDate = (date: Date) => {
		setSelectedTime(date);
		setTempTime(formatTime(date));
		setIsDisabled(false);
	};

	const changeInputDate = (time: string) => {
		setTempTime(time);
		if (isValidTime(time)) {
			setSelectedTime(parseTime(time));
			setIsDisabled(false);
		} else {
			try {
				const newTime = trySetTime(new Date(), time, true);
				setIsDisabled(false);
				setSelectedTime(newTime);
			} catch {
				setIsDisabled(true);
			}
		}
	};

	const trySetTime = (date: Date, time: string, changePicker = false) => {
		const exploded = time.split(":");
		if (!exploded.length) {
			throw new Error();
		}

		if (exploded.length === 1) {
			if (isValidHour(exploded[0])) {
				if (changePicker) {
					setRefresh(new Date().getTime());
					setOpenTo("hours");
				}

				return setTime(date, parseInt(exploded[0], 10), 0);
			}

			throw new Error();
		}

		if (exploded.length === 2) {
			if (changePicker) {
				setRefresh(new Date().getTime());
				setOpenTo("minutes");
			}

			if (!isValidHour(exploded[0])) {
				throw new Error();
			}

			date.setHours(parseInt(exploded[0], 10));

			if (isValidMinutes(exploded[1])) {
				date.setMinutes(parseInt(exploded[1], 10));
			} else {
				throw new Error();
			}
		}

		if (exploded.length > 2) {
			throw new Error();
		}

		return date;
	};

	const canShowAMPM = (timeSelected: Date, timeTemp: string) => {
		let checkTime;

		try {
			checkTime = trySetTime(new Date(), timeTemp);
		} catch {
			checkTime = timeSelected;
		}

		return !is24h(checkTime);
	};

	const confirm = () => {
		const response = selectedTime;
		if (isUndefined(response)) {
			return;
		}

		let hours = response.getHours();
		const minutes = response.getMinutes();

		if (hours !== 0) {
			if (AMPM === "PM" && hours < 12) hours = hours + 12;
			if (AMPM === "AM" && hours === 12) hours = hours - 12;
		}

		const time =
			(hours < 10 ? "0" + hours.toString() : hours) + ":" + (minutes < 10 ? "0" + minutes.toString() : minutes);

		callBackTime(time);
		setOpen(false);
	};

	return (
		<div>
			<Button className={classes.addMoreBtn} variant="outlined" onClick={handleOpen}>
				{label}
			</Button>

			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
				<DialogContent className={classes.dialogContent}>
					<MuiDateProvider>
						<TimePicker
							key={`picker-${refresh}`}
							variant="static"
							minutesStep={5}
							openTo={openTo}
							value={selectedTime}
							onChange={(event: Date) => changePickerDate(new Date(event))}
							disableToolbar
							ampm
						/>

						<div className={classes.picker}>
							<TextField
								variant="standard"
								value={tempTime || ""}
								className={classes.inputField}
								onChange={event => changeInputDate(event.target.value)}
								InputProps={{
									className: classes.input,
									startAdornment: (
										<InputAdornment position="start">
											<InsertInvitationIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="end">
											{canShowAMPM(selectedTime, tempTime) && (
												<>
													<IconButton
														size="small"
														className={AMPM === "AM" ? classes.active : ""}
														onClick={() => setAMPM("AM")}
													>
														AM
													</IconButton>

													<IconButton
														size="small"
														className={AMPM === "PM" ? classes.active : ""}
														onClick={() => setAMPM("PM")}
													>
														PM
													</IconButton>
												</>
											)}
										</InputAdornment>
									),
								}}
							/>

							<Button disabled={isDisabled} onClick={confirm} variant="text" className={classes.button} size="small">
								{t("COMMON.CONFIRM")}
							</Button>
						</div>
					</MuiDateProvider>
				</DialogContent>
			</Dialog>
		</div>
	);
});
