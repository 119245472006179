import MaskInput from "ui/forms/MaskInput";
import { MASKS } from "ui/forms/MaskInput/utils";

const DateInput = ({ onChange, value, ...props }: any) => (
	<MaskInput
		lazy={false}
		autofix={true}
		value={value}
		onAccept={(val: string) => onChange(val)}
		{...MASKS.dayMonthMask}
		{...props}
	/>
);

export default DateInput;
