import { Box, Grid } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";

import { FormSection } from "../shared.styled";

const ChangePassword = () => {
	const { t } = useTranslation();
	const { addSuccess } = useNotification();
	const [isAction, setIsAction] = useState<boolean>(false);
	const { handleAndNotify } = useErrors();

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			old_password: Yup.string().required(t("ERRORS.REQUIRED")),
			new_password: Yup.string().required(t("ERRORS.REQUIRED")),
			new_password_repeat: Yup.string().oneOf([Yup.ref("new_password"), null], t("SETTINGS.PASSWORD.NOT_MATCH")),
		}),
		initialValues: {
			old_password: "",
			new_password: "",
			new_password_repeat: "",
		},
		onSubmit: async values => {
			setIsAction(true);
			try {
				const userData = await Auth.currentAuthenticatedUser();

				await Auth.changePassword(userData, values.old_password, values.new_password);

				formikProps.resetForm();

				addSuccess(t("SETTINGS.PASSWORD.CHANGED"));
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsAction(false);
			}
		},
	});

	return (
		<FormSection>
			<FormikProvider value={formikProps}>
				<Form onSubmit={formikProps.handleSubmit}>
					<Grid container spacing={10}>
						<Grid item sm={6} md={4} lg={3} xs={12}>
							<Input
								type="password"
								required
								name="old_password"
								label={t("SETTINGS.PASSWORD.OLD_PASSWORD")}
								inputClassName="full"
								autoComplete="current-password"
							/>
						</Grid>
						<Grid item sm={6} md={4} lg={3} xs={12}>
							<Input
								type="password"
								required
								name="new_password"
								label={t("SETTINGS.PASSWORD.NEW_PASSWORD")}
								inputClassName="full"
								autoComplete="new-password"
							/>
						</Grid>
						<Grid item md={4} lg={3} xs={12}>
							<Input
								type="password"
								required
								name="new_password_repeat"
								label={t("SETTINGS.PASSWORD.NEW_PASSWORD_REPEAT")}
								inputClassName="full"
								autoComplete="new-password"
							/>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Box mt={3}>
								<ActionButton
									size="large"
									translationDefault={"SETTINGS.PASSWORD.CHANGE_PASSWORD"}
									isAction={isAction}
								/>
							</Box>
						</Grid>
					</Grid>
				</Form>
			</FormikProvider>
		</FormSection>
	);
};

export default ChangePassword;
