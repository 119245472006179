import colors from "styles/colors";

// @todo:fix - to types.
export enum VariantStyle {
	orange = "orange",
	green = "green",
}

export const styleConfig = {
	[VariantStyle.orange]: {
		checked: {
			fontColor: colors.white,
			border: `1px solid ${colors.sunShade}`,
			backgroundColor: colors.sunShade,
		},
		unchecked: {
			fontColor: colors.sunShade,
			border: `1px solid ${colors.sunShade}`,
			backgroundColor: "#ffdca9",
		},
	},
	[VariantStyle.green]: {
		checked: {
			fontColor: colors.white,
			border: `1px solid ${colors.green}`,
			backgroundColor: colors.green,
		},
		unchecked: {
			fontColor: colors.green,
			border: `1px solid ${colors.green}`,
			backgroundColor: "rgba(76, 175, 80, 0.15)",
		},
	},
};
