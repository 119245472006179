export const carouselSwiperConfig = {
	lazy: true,
	pagination: { clickable: true },
	breakpoints: {
		300: {
			spaceBetween: 20,
			width: 304,
			freeMode: true,
			pagination: { clickable: false },
		},
		600: {
			spaceBetween: 25,
			width: 304,
			freeMode: true,
			pagination: { clickable: false },
		},
		960: {
			spaceBetween: 25,
			width: 434,
		},
	},
};
