import { Box } from "@material-ui/core";
import styled from "styled-components";

import AppleIcon from "assets/icons/apple.svg";
import FacebookIcon from "assets/icons/facebook.svg";
import GoogleIcon from "assets/icons/google.svg";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const SocialButton = styled.div`
	width: 250px;
	height: 40px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: solid 1px #bdbdbd;
	background-color: ${colors.white};
`;

const LabelButton = styled.span`
	width: 190px;
	opacity: 0.87;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	letter-spacing: 0.3px;
	color: rgba(0, 0, 0, 0.87);
	text-transform: uppercase;
`;

const IconWrapper = styled.span`
	margin-right: 10px;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SocialMediaLoginComponent = () => {
	const { t } = useTranslation();

	return (
		<Box display="flex" flexDirection="column" alignItems="center" width="100%">
			<SocialButton>
				<IconWrapper>
					<img alt="Facebook" src={FacebookIcon} height="20px" width="20px" />
				</IconWrapper>

				<LabelButton>{t(`AUTH.SOCIAL_MEDIA.FACEBOOK`)}</LabelButton>
			</SocialButton>

			<SocialButton>
				<IconWrapper>
					<img alt="Google" src={GoogleIcon} height="18px" width="18px" />
				</IconWrapper>

				<LabelButton>{t(`AUTH.SOCIAL_MEDIA.GOOGLE`)}</LabelButton>
			</SocialButton>

			<SocialButton>
				<IconWrapper>
					<img alt="Apple" src={AppleIcon} height="20px" width="20px" />
				</IconWrapper>

				<LabelButton>{t(`AUTH.SOCIAL_MEDIA.APPLE`)}</LabelButton>
			</SocialButton>
		</Box>
	);
};
