import { Box, BoxProps } from "@material-ui/core";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import colors from "styles/colors";
import { RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";

const fontCss = css`
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.medium};
	color: ${colors.mainFontColor};
	margin-right: 5px;
`;

const TextMedium = styled.span`
	${fontCss};
`;

const Link = styled(StyledLink)`
	${fontCss};
`;

type IPrivacyUrlsFooter = {
	firstMessage?: string;
	align?: BoxProps["textAlign"];
};

const PrivacyUrlsFooter = ({ firstMessage, align }: IPrivacyUrlsFooter) => {
	const { t } = useTranslation();

	return (
		<Box textAlign={align || "center"} marginTop="28px" marginBottom="28px">
			{!!firstMessage && <TextMedium>{firstMessage}</TextMedium>}

			<Link to={paths.HELP_LEGAL_TERMS}>
				<strong>{t("BOOKING_FORM.TERMS_CONDITIONS")}</strong>,
			</Link>

			<Link to={paths.HELP_CANCELLATION_POLICY}>
				<strong>{t("BOOKING_FORM.CANCELLATION_POLICY")}</strong>,
			</Link>

			<Link to={paths.HELP_PRIVACY_POLICY}>
				<strong>{t("BOOKING_FORM.PRIVACY_POLICY")}</strong>.
			</Link>
		</Box>
	);
};

export default PrivacyUrlsFooter;
