import {
	Box,
	Button,
	FormHelperText,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import { Check, Close, DeleteOutline, EditOutlined } from "@material-ui/icons";
import { sortBy } from "lodash-es";
import { useCallback, useEffect, useState } from "react";

import { TCurrency } from "data/backoffice/financials/types";
import { getDictionary } from "data/dictionaries";
import { ECurrencySymbol } from "data/financials/types";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { getEarnings, prices } from "utils/prices";

import { StyledHelperText, StyledTableCell } from "../shared.styled";
import { insertInArray, parseParticipants, transformArray } from "./utils";

export interface ITableTieredPriceProps {
	field?: "JSON_PRICES_FOR_CHILDREN" | "JSON_PRICES_PER_TRAVELLER";
	value?: { [v: string]: number };
	onChange: (v) => void;
	minValue?: number;
	maxValue?: number;
	currency?: TCurrency;
	editMode?: boolean;
}

export interface IDataProps {
	participants: string;
	pricePerPerson: number;
	earnings: number;
}

const TableTieredPrice = ({
	field = "JSON_PRICES_PER_TRAVELLER",
	onChange,
	value,
	minValue,
	maxValue,
	currency,
	editMode = true,
}: ITableTieredPriceProps) => {
	const { t } = useTranslation();

	const headers = getDictionary(field);

	const notifications = useNotification();

	const [data, setData] = useState<IDataProps[]>([]);
	const [editRowIndex, setEditRowIndex] = useState<number | null>(null); // if null we are not in edit mode
	const [showAddRow, setShowAddRow] = useState<boolean>(false);

	const [from, setFrom] = useState<number>(minValue || 1);
	const [to, setTo] = useState<number>(minValue || 1);
	const [price, setPrice] = useState<number>(1);

	useEffect(() => {
		if (minValue !== undefined) {
			setFrom(prevState => {
				if (minValue > prevState) {
					return minValue;
				} else {
					return prevState;
				}
			});
		}
	}, [minValue]);

	useEffect(() => {
		setTo(prevState => {
			if (prevState <= from) {
				if (maxValue && from >= maxValue) {
					return maxValue;
				}
				return from;
			} else {
				return prevState;
			}
		});
	}, [from, maxValue]);

	useEffect(() => {
		const initialDataArray: IDataProps[] = [];

		if (value === undefined) return;

		const tempUniqueValues = Object.values(value).filter((v, i, a) => a.indexOf(v) === i);

		tempUniqueValues.forEach(v => {
			const tempListOfKeys = Object.keys(value).filter(key => value[key] === v);
			initialDataArray.push({
				participants: `${tempListOfKeys[0]}-${tempListOfKeys.slice(-1)}`,
				pricePerPerson: v,
				earnings: getEarnings(v),
			});
		});

		setData(sortBy(initialDataArray, elem => parseInt(elem?.participants, 10)));

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		onChange(transformArray(data));

		// eslint-disable-next-line
	}, [data]);

	const addRow = () => {
		setShowAddRow(!showAddRow);
		if (value && Object.keys(value).length > 0) {
			const keysArrayInt = Object.keys(value).map(k => parseInt(k, 10));
			setFrom(prevState => {
				const next = Math.max(...keysArrayInt) + 1;

				if (prevState > next) {
					return prevState;
				} else {
					if (maxValue && next >= maxValue) {
						return maxValue;
					}

					return next;
				}
			});
		} else {
			setFrom(prevState => {
				if (prevState > 1) {
					return prevState;
				} else {
					return 1;
				}
			});
		}
	};

	const handleDelete = useCallback(
		(rowIndex: number) => {
			const others = data.filter((_, index) => index !== rowIndex);
			setData(others);
		},
		[data],
	);

	const handleClearRow = useCallback(() => {
		setFrom(minValue || 1);
		setTo(minValue || 1);
		setPrice(1);
		setShowAddRow(false);
	}, [minValue]);

	const handleAdd = useCallback(() => {
		if (minValue && from < minValue) {
			notifications.clear();
			notifications.addError(
				`Wrong value provided - "from" field value is too low. Please check your values and try again.`,
			);
			return;
		}

		const keysArray = Object.keys(value || []);
		if (
			from > to ||
			!price ||
			!from ||
			!to ||
			(editRowIndex === null && keysArray.length && keysArray.indexOf(`${from}`) !== -1) ||
			(editRowIndex === null && keysArray.length && keysArray.indexOf(`${to}`) !== -1)
		) {
			notifications.clear();
			notifications.addError("Wrong value provided. Please check your values and try again.");
			return;
		}

		const row = {
			participants: `${from}-${to}`,
			pricePerPerson: price,
			earnings: getEarnings(price),
		};
		if (editRowIndex !== null) {
			const withoutCurrent = data.filter((_, index: number) => index !== editRowIndex);

			// add the new row in the same index as before
			const modifiedData = insertInArray(withoutCurrent, editRowIndex, row);

			setData(sortBy(modifiedData, elem => parseInt(elem?.participants, 10)));
			setEditRowIndex(null);
		} else {
			// add new element
			setData(sortBy([...data, row], elem => parseInt(elem?.participants, 10)));
		}

		handleClearRow();

		// hide the row
		setShowAddRow(false);

		// eslint-disable-next-line
	}, [from, to, price, data, onChange, editRowIndex, handleClearRow]);

	const handleEdit = useCallback(
		(rowIndex: number) => {
			setEditRowIndex(rowIndex);
			setShowAddRow(true);
			const row: IDataProps | undefined = data.find((_, index) => index === rowIndex);
			if (row === undefined) return;
			const [fromVal, toVal] = parseParticipants(row.participants);

			// fill the form input values
			setFrom(fromVal);
			setTo(toVal);
			setPrice(row.pricePerPerson);
		},
		[data],
	);

	return (
		<>
			<TableContainer>
				<Table aria-label="tiered price table">
					<TableHead>
						<TableRow>
							{headers.map(header => (
								<StyledTableCell key={header} align="left">
									{t(`EXPERIENCE.FORM.${field}.HEADRES_OF_COLUMN.${header}`)}
									{header === "PRICE_PER_PERSON" && (
										<StyledHelperText>
											{t(
												"EXPERIENCE.FORM.JSON_PRICES_FOR_CHILDREN.HEADRES_OF_COLUMN.PRICE_PER_PERSON.VAT_NOT_INCLUDED",
											)}
										</StyledHelperText>
									)}
								</StyledTableCell>
							))}
							<StyledTableCell align="left" />
						</TableRow>
					</TableHead>

					<TableBody>
						{data.length > 0 &&
							data.map(({ participants, pricePerPerson, earnings }, index) => (
								<TableRow key={participants}>
									<StyledTableCell align="left">
										{participants} {t(`EXPERIENCE.FORM.${field}.SUFFIX_PARTICIPANTS_AGE`)}
									</StyledTableCell>

									<StyledTableCell align="left">{prices(pricePerPerson, currency)}</StyledTableCell>

									<StyledTableCell align="left">{prices(earnings, currency)}</StyledTableCell>

									{editMode && (
										<StyledTableCell align="right">
											<Box display="flex" justifyContent="flex-end" flexDirection="row">
												<Box>
													<IconButton edge="end" aria-label="delete" color="inherit" onClick={() => handleEdit(index)}>
														<EditOutlined />
													</IconButton>
												</Box>

												<Box>
													<IconButton
														edge="end"
														aria-label="delete"
														color="inherit"
														onClick={() => handleDelete(index)}
													>
														<DeleteOutline />
													</IconButton>
												</Box>
											</Box>
										</StyledTableCell>
									)}
								</TableRow>
							))}

						{showAddRow && (
							<TableRow>
								<StyledTableCell align="left">
									<Box display="flex">
										<Box width="80px">
											<TextField
												id="participants-from"
												label={t(
													field === "JSON_PRICES_FOR_CHILDREN"
														? "EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.YEARS_OLD_LABEL"
														: "EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.PARTICIPANTS_FROM",
												)}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{ inputProps: { min: minValue || 1, max: maxValue } }}
												value={from}
												variant="outlined"
												type="number"
												onChange={evt =>
													maxValue
														? Number(evt.target.value) <= maxValue && setFrom(Number(evt.target.value))
														: setFrom(Number(evt.target.value))
												}
											/>
										</Box>

										<Box ml={5} mr={5} mt={10}>
											<Typography>to</Typography>
										</Box>

										<Box width="80px">
											<TextField
												id="participants-to"
												label={t(
													field === "JSON_PRICES_FOR_CHILDREN"
														? "EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.YEARS_OLD_LABEL"
														: "EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.PARTICIPANTS_TO",
												)}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													inputProps: { min: maxValue && from + 1 > maxValue ? maxValue : from + 1, max: maxValue },
												}}
												value={to}
												variant="outlined"
												type="number"
												onChange={evt =>
													maxValue
														? Number(evt.target.value) <= maxValue && setTo(Number(evt.target.value))
														: setTo(Number(evt.target.value))
												}
											/>
										</Box>
									</Box>
								</StyledTableCell>

								<StyledTableCell align="left">
									<Box minWidth="60px">
										<TextField
											id="participants-price"
											InputLabelProps={{
												shrink: true,
											}}
											defaultValue={price}
											type="number"
											onChange={evt => setPrice(Number(evt.target.value))}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														{!!currency ? ECurrencySymbol[currency] : ""}
													</InputAdornment>
												),
												inputProps: { min: 1 },
											}}
										/>
									</Box>
								</StyledTableCell>

								<StyledTableCell>
									<Box display="flex" flexDirection="row">
										<Box pr={4}>
											<IconButton edge="end" aria-label="accept" color="inherit" onClick={handleAdd}>
												<Check />
											</IconButton>
										</Box>

										<Box>
											<IconButton edge="end" aria-label="close" color="inherit" onClick={handleClearRow}>
												<Close />
											</IconButton>
										</Box>
									</Box>
								</StyledTableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{editMode &&
				maxValue &&
				!!data.length &&
				parseInt(data[data.length - 1]?.participants.split("-")[1], 10) < maxValue && (
					<FormHelperText error>
						You must provide tiered price for max. number of the travellers that you choose in step 2 ({maxValue} max.
						travellers)
					</FormHelperText>
				)}

			{editMode && (
				<Box display="flex" justifyContent="flex-end" pt={10}>
					<Button disabled={showAddRow} variant="contained" onClick={addRow}>
						<Typography variant="button">{t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.ADD_POSITTION")}</Typography>
					</Button>
				</Box>
			)}
		</>
	);
};

export default TableTieredPrice;
