import { IBookingIId } from "data/backoffice/bookings/types";
import { EExpType, Pricing } from "data/experiences/types";

import { dateParseAndFormat, DATE_FORMATS } from "./dates";

type TValues = Record<string, number>;

export const getKidsAges = (values: TValues, prefix: string) =>
	Object.keys(values)
		.filter(key => key.startsWith(prefix))
		.map(key => values[key]);

export const kidsCounter = (values: TValues, prefix: string) => {
	const kidsAges = getKidsAges(values, prefix);

	const kidsData: TValues = {};

	if (kidsAges.length) {
		kidsAges.forEach(elem => {
			if (isNaN(kidsData[elem])) {
				kidsData[elem] = 1;
			} else {
				kidsData[elem] += 1;
			}
		});
	}

	const kidsDetails = Object.entries(kidsData).map(([age, quantity]) => ({ age: Number(age), quantity }));

	return kidsDetails;
};

export const getKidsPricingDetails = (pricing: Pricing, values: TValues, prefix: string) => {
	let totalPrice = 0;

	const pricingDetails: { age: number; quantity: number; price: number }[] = [];

	const kidsData: TValues = {};

	const kidsAges = getKidsAges(values, prefix);

	if (kidsAges.length) {
		kidsAges.forEach(elem => {
			if (isNaN(kidsData[elem])) {
				kidsData[elem] = 1;
			} else {
				kidsData[elem] += 1;
			}
		});
	}

	Object.entries(kidsData).forEach(([age, quantity]) => {
		let price = 0;

		const ageAsNumber = Number(age);

		if (typeof pricing.kids_free_under === "number" && ageAsNumber <= pricing.kids_free_under) {
			price = 0;
		} else {
			if (typeof pricing?.kids_pricing_details?.[age] === "number") {
				price = pricing.kids_pricing_details[age] * quantity;
			} else {
				if (typeof pricing?.price_per_person === "number") {
					price = pricing.price_per_person * quantity;
				} else if (pricing?.tiered_pricing_details) {
					price = (pricing.tiered_pricing_details?.["1"] || 0) * quantity;
				}
			}
		}

		totalPrice += price;

		pricingDetails.push({ age: ageAsNumber, quantity, price });
	});

	return { totalPrice, pricingDetails };
};

export const prepareDataStringForLB021 = (booking: IBookingIId) => {
	const bookingRef = booking.partner_int_bid || booking.id;
	const parentId = booking.superbooking_id;
	const expId = booking.eid;
	const expName = booking.exp_title;
	const country = booking.country;
	const city = booking.city;
	const duration = booking.exp_duration_minutes;
	const numberOfGuests = booking.total_travelers;
	const guestName = booking.traveler_details?.first_name || "";
	const guestPhone = `'${booking.traveler_details?.phone || ""}`;
	const currency = booking.currency;
	const price = booking.price;
	const partnerResellPrice = typeof booking.partner_resell_price === "number" ? booking.partner_resell_price : "";

	const language = new Intl.DisplayNames(["en"], { type: "language" }).of(booking.language);

	const localSwissCreatedDate = new Date(booking.created_on + "-0000").toLocaleString("en-GB", {
		timeZone: DATE_FORMATS.SWISS_TIMEZONE,
	});

	const expMonth = dateParseAndFormat(
		booking.date_time,
		DATE_FORMATS.MONTH_SHORT_AND_LONG_FORMAT,
		DATE_FORMATS.API_FORMAT_DATETIME,
	);
	const expDate = dateParseAndFormat(booking.date_time, DATE_FORMATS.DATE_FORMAT2, DATE_FORMATS.API_FORMAT_DATETIME);
	const expWeekDay = `D${((new Date(booking.date_time).getDay() + 6) % 7) + 1} ${dateParseAndFormat(
		booking.date_time,
		DATE_FORMATS.DAY_LONG_FORMAT,
		DATE_FORMATS.API_FORMAT_DATETIME,
	)}`;
	const createdDate = dateParseAndFormat(
		localSwissCreatedDate,
		DATE_FORMATS.DATE_FORMAT2,
		DATE_FORMATS.DATETIME_FORMAT5,
	);
	const createdHour = dateParseAndFormat(
		localSwissCreatedDate,
		DATE_FORMATS.TIME_FORMAT,
		DATE_FORMATS.DATETIME_FORMAT5,
	);
	const expHour = dateParseAndFormat(booking.date_time, DATE_FORMATS.TIME_FORMAT, DATE_FORMATS.API_FORMAT_DATETIME);
	const quarter = dateParseAndFormat(booking.date_time, DATE_FORMATS.QUARTER_FORMAT, DATE_FORMATS.API_FORMAT_DATETIME);
	const year = new Date(booking.date_time).getFullYear();

	const poolUnique = booking.exp_type === EExpType.UNIQUE ? "Unique" : "Pool";
	const publicPrivate = booking.private ? "Private" : "Public";
	const channel = booking.partner_int_bid ? "B2B2C" : "B2C";

	// Orders of tabs matter!
	const arrayOfData = [
		bookingRef,
		"\t",
		parentId,
		"\t",
		"\t",
		"\t",
		"\t",
		poolUnique,
		"\t",
		publicPrivate,
		"\t",
		"\t",
		channel,
		"\t",
		"\t",
		expId,
		"\t",
		expName,
		"\t",
		country,
		"\t",
		city,
		"\t",
		"\t",
		"\t",
		expMonth,
		"\t",
		expDate,
		"\t",
		expWeekDay,
		"\t",
		createdDate,
		"\t",
		createdHour,
		"\t",
		expHour,
		"\t",
		quarter,
		"\t",
		year,
		"\t",
		"\t",
		"\t",
		"\t",
		duration,
		"\t",
		numberOfGuests,
		"\t",
		guestName,
		"\t",
		language,
		"\t",
		"\t",
		guestPhone,
		"\t",
		"\t",
		"\t",
		currency,
		"\t",
		price,
		"\t",
		"\t",
		"\t",
		"\t",
		"\t",
		partnerResellPrice,
	];

	return arrayOfData.join("");
};
