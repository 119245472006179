import {
	Box,
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	// FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { Fragment, memo, useLayoutEffect, useState } from "react";

import useTranslation from "hooks/useTranslation";

import { firstDayOfCurrentYear, lastDayOfCurrentYear } from "utils/dates";

import MuiDateProvider from "providers/MuiDateProvider";

import colors from "styles/colors";

import DateInput from "./components/DateInput";

interface IDatePicker {
	callBackDate: (start, stop) => void;
	label: string;
	dateFormat?: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		addMoreBtn: {
			borderRadius: "16px",
			padding: "3px 15px",
			textTransform: "lowercase",
		},
		dateContent: {
			backgroundColor: colors.lightGrayWhite,
		},
	}),
);

const DatePickerCurrentYear = memo(({ callBackDate, label, dateFormat = "yyyy-MM-dd" }: IDatePicker) => {
	const { t } = useTranslation();

	const classes = useStyles();

	const [open, setOpen] = useState<boolean>(false);
	const [selectedDateStart, setSelectedDateStart] = useState<Date | null>(new Date());
	const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(new Date());
	// const [helperText, setHelperText] = useState<string | null>(null);

	const removeYearFromCalendarHeaders = () => {
		setTimeout(() => {
			const calendarHeaders = document.querySelectorAll(".MuiPickersCalendarHeader-transitionContainer p");

			if (calendarHeaders.length) {
				const calendarHeader1 = calendarHeaders[0];
				const calendarHeader2 = calendarHeaders[1];

				if (calendarHeader1) {
					calendarHeader1.innerHTML = calendarHeader1.innerHTML.split(" ")[0];
				}

				if (calendarHeader2) {
					calendarHeader2.innerHTML = calendarHeader2.innerHTML.split(" ")[0];
				}
			}
		}, 0);
	};

	useLayoutEffect(() => {
		removeYearFromCalendarHeaders();
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = () => {
		// if (selectedDateStart && selectedDateEnd && selectedDateStart > selectedDateEnd) {
		//   setHelperText(t("EXPERIENCE.FORM.COMMON_FOR_DATE_TIME_PICKERS.HELPER_TEXT_ERROR"));
		//   return;
		// } else setHelperText(null);

		if (selectedDateStart && selectedDateEnd) {
			callBackDate(format(selectedDateStart, dateFormat), format(selectedDateEnd, dateFormat));
		}

		setOpen(false);
	};

	const handleChangeDateStart = (dateString: any) => {
		if (dateString.includes("_")) return;

		setSelectedDateStart(new Date(`${new Date().getFullYear()}-${dateString}`));
	};

	const handleChangeDateEnd = (dateString: any) => {
		if (dateString.includes("_")) return;

		setSelectedDateEnd(new Date(`${new Date().getFullYear()}-${dateString}`));
	};

	return (
		<div>
			<Button className={classes.addMoreBtn} variant="outlined" onClick={handleClickOpen}>
				{label}
			</Button>

			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
				<DialogContent>
					<Fragment>
						<Box display="flex">
							<Box mr={5}>
								<DateInput
									id="start-date"
									value={selectedDateStart && format(selectedDateStart, dateFormat)}
									onChange={handleChangeDateStart}
									label={t("EXPERIENCE.FORM.COMMON_FOR_DATE_TIME_PICKERS.START_DATE")}
									variant="outlined"
								/>
							</Box>

							<Box>
								<DateInput
									id="end-date"
									value={selectedDateEnd && format(selectedDateEnd, dateFormat)}
									onChange={handleChangeDateEnd}
									label={t("EXPERIENCE.FORM.COMMON_FOR_DATE_TIME_PICKERS.END_DATE")}
									variant="outlined"
								/>
							</Box>
						</Box>

						{/*{helperText && (*/}
						{/*  <Box>*/}
						{/*    <FormHelperText error>{helperText}</FormHelperText>*/}
						{/*  </Box>*/}
						{/*)}*/}

						<MuiDateProvider>
							<Box display="flex" justifyContent="space-between" mt={5}>
								<Box mr={15}>
									<DatePicker
										minDate={firstDayOfCurrentYear}
										maxDate={lastDayOfCurrentYear}
										onMonthChange={removeYearFromCalendarHeaders}
										disableToolbar
										variant="static"
										value={selectedDateStart}
										onChange={(event: Date) => setSelectedDateStart(new Date(event))}
										className={classes.dateContent}
									/>
								</Box>

								<Box>
									<DatePicker
										minDate={firstDayOfCurrentYear}
										maxDate={lastDayOfCurrentYear}
										onMonthChange={removeYearFromCalendarHeaders}
										disableToolbar
										variant="static"
										value={selectedDateEnd}
										onChange={(event: Date) => setSelectedDateEnd(new Date(event))}
										className={classes.dateContent}
									/>
								</Box>
							</Box>
						</MuiDateProvider>
					</Fragment>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleSubmit} color="primary">
						{t("COMMON.SUBMIT")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
});

export default DatePickerCurrentYear;
