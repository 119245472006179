import {
	Box,
	Chip,
	FormHelperText,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
} from "@material-ui/core";
import { Add, Cancel, Check, Close } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";

import { getDictionary, TDictionaryName } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import TextFieldBox from "components/forms/TextFieldBox";

interface IStringTagsProps {
	onChange: (newValue) => void;
	value: string[];
	field?: TDictionaryName;
	helperText?: string;
	add?: boolean;
	hideOptions?: boolean;
	translateDic?: boolean;
	options?: string[];
	translationsForLabels?: {
		[key: string]: string;
	};
}

const StyledListItem = styled(ListItem)`
	padding-bottom: 25px;
`;

const StringTags = ({
	onChange,
	helperText,
	value = [],
	field,
	add,
	hideOptions,
	translateDic = true,
	options,
	translationsForLabels,
}: IStringTagsProps) => {
	const { t } = useTranslation();

	const [isAddMode, setIsAddMode] = useState<boolean>(false);
	const [optionsVisible, setOptionsVisible] = useState<boolean>(!hideOptions);
	const [currentlyAdded, setCurrentlyAdded] = useState<string>("");

	const dictionary = field && getDictionary(field);

	const handleDelete = index => onChange(value.filter((_, i) => i !== index));

	const handleAdd = category => onChange([...value, category]);

	const handleNew = () => {
		onChange([...value, currentlyAdded]);
		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	const handleCancel = () => {
		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	return (
		<Grid container spacing={8} direction="column">
			<Grid item xs container direction="row">
				{value.map((v, i) => (
					<Box display="flex" pr={5} pb={5} key={v}>
						<Chip
							color="primary"
							label={
								translationsForLabels ? translationsForLabels[v] || v : translateDic ? t(`EXPERIENCE.${field}.${v}`) : v
							}
							onDelete={() => handleDelete(i)}
							deleteIcon={<Cancel />}
						/>
					</Box>
				))}

				{hideOptions && (
					<Box display="flex" pr={5} pb={5}>
						<Chip
							variant="outlined"
							label={optionsVisible ? "Hide more" : "Add more"}
							onClick={() => setOptionsVisible(prevState => !prevState)}
						/>
					</Box>
				)}
			</Grid>

			{optionsVisible && (
				<Grid item xs container direction="row" spacing={2}>
					{(dictionary || options || []).map(
						d =>
							value.indexOf(d) === -1 && (
								<Box display="flex" pr={5} pb={5} key={d}>
									<Chip
										label={
											translationsForLabels
												? translationsForLabels[d] || d
												: translateDic
												? t(`EXPERIENCE.${field}.${d}`)
												: d
										}
										onDelete={() => handleAdd(d)}
										onClick={() => handleAdd(d)}
										deleteIcon={<Add />}
										size="small"
									/>
								</Box>
							),
					)}

					{add && (
						<Box display="flex" pr={5} pb={5}>
							<Chip
								variant="outlined"
								label={t(`EXPERIENCE.FORM.STRING_TAGS.ADD_POSITION.DEFAULT`)}
								onClick={() => setIsAddMode(true)}
								size="small"
							/>
						</Box>
					)}
				</Grid>
			)}

			{isAddMode && (
				<List key="list">
					<StyledListItem divider key="add_more" button>
						<TextFieldBox
							placeholder={t("EXPERIENCE.FORM.STRING_TAGS.PLACEHOLDER.DEFAULT")}
							variant="outlined"
							onChange={e => setCurrentlyAdded(e.target.value)}
							value={currentlyAdded}
							size="small"
							style={{ width: 250 }}
							customBottom="-20px"
							maxLength={50}
						/>

						<ListItemSecondaryAction>
							<Box display="flex" flexDirection="row">
								<Box pr={4}>
									<IconButton type="button" edge="end" aria-label="accept" color="inherit" onClick={handleNew}>
										<Check />
									</IconButton>
								</Box>

								<Box>
									<IconButton type="button" edge="end" aria-label="close" color="inherit" onClick={handleCancel}>
										<Close />
									</IconButton>
								</Box>
							</Box>
						</ListItemSecondaryAction>
					</StyledListItem>
				</List>
			)}

			{helperText && <FormHelperText error>{helperText}</FormHelperText>}
		</Grid>
	);
};

export default StringTags;
