import { Box, Button, Grid, GridProps } from "@material-ui/core";
import { useState } from "react";

import useTranslation from "hooks/useTranslation";

import Review from "../Review";

interface IReviewsProps {
	items: {
		created_on: string;
		score: number;
		name?: string | null;
		comment?: string | null;
	}[];
	limit?: number;
	perPage?: number;
	gridItemProps?: GridProps;
}

const Reviews = ({ items = [], limit = 6, perPage = 3, gridItemProps = { xs: 12, sm: 6 } }: IReviewsProps) => {
	const { t } = useTranslation();

	const [page, setPage] = useState<number>(0);

	const total = items?.length;

	const isMore = limit + page * perPage < total;

	const handleButtonClick = () => {
		if (!isMore) {
			setPage(0);

			return;
		}

		setPage(prevPage => prevPage + 1);
	};

	return (
		<>
			<Grid container spacing={10}>
				{items.slice(0, limit + page * perPage).map((v, i) => (
					<Grid item key={i} {...gridItemProps}>
						<Review
							// TODO: add it when backend will return photo url
							avatarImg={undefined}
							dateValue={v.created_on}
							descriptionValue={v.comment}
							nameValue={v.name}
							value={v.score}
						/>
					</Grid>
				))}
			</Grid>

			{total > limit && (
				<Box display="flex" alignItems="center" justifyContent="center">
					<Button variant="contained" color="primary" onClick={handleButtonClick}>
						{t(isMore ? "EXPERIENCE_DETAILS_LOCALS.SHOW_MORE_BTN" : "EXPERIENCE_DETAILS_LOCALS.SHOW_LESS_BTN")}
					</Button>
				</Box>
			)}
		</>
	);
};

export default Reviews;
