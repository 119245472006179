import { Box, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import slugify from "slugify";
import styled, { css } from "styled-components";

import LogoGreyish from "assets/icons/logo/localbini-greyish.svg";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTraveller } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import fillRoute from "utils/routes";
import { getParams } from "utils/urls";

import DownloadTicketButton from "components/bookings/DownloadTicketButton";
import AppWrapper from "components/layout/AppWrapper";
import SharePopup from "components/Share/components/SharePopup";

import colors from "styles/colors";
import { Container, MontserratFontFamily, RobotoFontFamily, StyledLink } from "styles/common";

import BreadCrumbsBooking from "./BreadCrumbsBooking";

const MainTitle = styled.h1`
	margin: 10px 0 30px;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	color: ${colors.lightDark};
	text-align: center;
`;

const SubTitle = styled.div`
	font-family: ${RobotoFontFamily};
	color: ${colors.middleGray};
	font-size: 16px;
	text-align: center;
	line-height: 1.31;
`;

const Paragraph = styled.p<{ small?: boolean; bolded?: boolean }>`
	margin-bottom: 30px;

	${props =>
		props.bolded &&
		css`
			font-weight: bold;
		`}
	${props =>
		props.small &&
		css`
			font-size: 14px;
		`}
`;

const BookingConfirmation = () => {
	const { t, withRaw } = useTranslation();

	const dispatch = useAppDispatch();

	const userData = useAppSelector(selectUser);
	const experienceData = useAppSelector(selectExperienceTraveller);

	const location = useLocation();
	const initialQueryParams: { bok?: string } = getParams(new URLSearchParams(location.search));

	const [showSharePopup, setShowSharePopup] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			dispatch(experienceTravellerActions.resetBooking());
		};

		// eslint-disable-next-line
	}, []);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const experience = experienceData?.data;

	const link =
		experience?.id && experience?.title
			? fillRoute(paths.SEARCH_EXPERIENCES, { title: slugify(experience?.title.toLowerCase()), id: experience?.id })
			: undefined;

	return (
		<AppWrapper>
			<Container>
				{showSharePopup && experience?.description && experience?.title && link && (
					<SharePopup
						showDialog={showSharePopup}
						setShowDialog={() => setShowSharePopup(false)}
						title={experience?.title}
						description={experience?.description}
						shareUrl={link}
						addHostName={true}
					/>
				)}

				<BreadCrumbsBooking lastBreadcrumb={t("BOOKING_CONFIRMATION.TITLE")} />

				<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="600px">
					<Box>
						<Box display="flex" justifyContent="center" alignItems="center">
							<img src={LogoGreyish} alt="LocalBini logo" />
						</Box>

						<MainTitle>{withRaw("BOOKING_CONFIRMATION.TITLE")}</MainTitle>

						<SubTitle>
							<Paragraph bolded>
								{withRaw(partnerType ? "BOOKING_CONFIRMATION.SUB_TITLE_1_PARTNER" : "BOOKING_CONFIRMATION.SUB_TITLE_1")}
							</Paragraph>

							<Paragraph>
								{withRaw(partnerType ? "BOOKING_CONFIRMATION.SUB_TITLE_2_PARTNER" : "BOOKING_CONFIRMATION.SUB_TITLE_2")}
							</Paragraph>

							{!partnerType && (
								<>
									<Paragraph>{withRaw("BOOKING_CONFIRMATION.SUB_TITLE_3")}</Paragraph>

									<Paragraph small>{withRaw("BOOKING_CONFIRMATION.SUB_TITLE_DISCLAIMER")}</Paragraph>
								</>
							)}
						</SubTitle>
					</Box>

					{partnerType && (
						<>
							<Box marginBottom="30px" marginTop="25px">
								<DownloadTicketButton bookingId={initialQueryParams.bok} />
							</Box>

							<Box fontWeight="500" fontSize="12px">
								<StyledLink
									$decoration="underline"
									to={fillRoute(paths.GUEST_BOOKING_PAGE, { booking_id: initialQueryParams.bok, token: "" })}
								>
									{t("BOOKING_CONFIRMATION.BOOKING_DETAILS_LINK")}
								</StyledLink>
							</Box>
						</>
					)}

					{!partnerType && experience?.description && experience?.title && link && (
						<Button color="primary" variant="contained" onClick={() => setShowSharePopup(true)}>
							{t("BOOKING_CONFIRMATION.SHARE_BTN")}
						</Button>
					)}
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default BookingConfirmation;
