import { Box, CircularProgress } from "@material-ui/core";
import { getCurrentLocale } from "i18n";
import { isEmpty } from "lodash-es";
import qs from "query-string";
import { useCallback, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import store from "store";

import LanguageIcon from "assets/icons/footer/language.svg";

import { DeepPartial } from "data/types";
import {
	EOnboardingSteps,
	EOnboardingVersions,
	EUserOnboardingStatus,
	EUserOnboardingType,
	IUser,
} from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import usersService from "services/api/users";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectUser, selectUserStatus } from "store/selectors/user";
import { deleteUserProfileMedia, fetchAuthenticatedUser, getUserProfileMediaUrl } from "store/slices/users";

import { dateObjectToString, DATE_FORMATS } from "utils/dates";
import { omit } from "utils/objects";
import fillRoute from "utils/routes";

import LanguageSelectorModal from "components/translations/LanguageSelectorModal";

import AlertMessage, { AlertMessageProps } from "ui/AlertMessage";

import Bio from "./steps/Bio";
import { CalendlyStep } from "./steps/Calendly";
import CitiesSelector from "./steps/CitiesSelector";
import Experiences from "./steps/Experiences";
import PhoneVerification from "./steps/PhoneVerification";
import ShortBioWithCity from "./steps/ShortBioWithCity";
import { BackgroundColumn, BackgroundDescription, Column, Highlighted, LanguageContainerWithCursor } from "./styled";
import { TUpdateProfileData } from "./types";

export const useAlertMessage = () => {
	const [openAlert, setOpenAlert] = useState<boolean>(false);

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	const openAlertMessage = () => {
		setOpenAlert(true);
	};

	return { openAlert, openAlertMessage, handleCloseAlert, severity: "error" } as AlertMessageProps & {
		openAlertMessage(): void;
	};
};

// @todo:refactor - move it to components.
export const TeaserOnboarding = () => {
	const { t } = useTranslation();

	return (
		<BackgroundDescription>
			{`${t("ONBOARDING.H1.PART_1")} `}
			<Highlighted>{t("ONBOARDING.H1.HIGHLIGHTED")}</Highlighted>
			{` ${t("ONBOARDING.H1.PART_2")}`}
		</BackgroundDescription>
	);
};

export const LanguageSelectorOnboarding = () => {
	const { findNameByKey } = useLanguages();

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const currentLanguage = getCurrentLocale()?.code?.split("-")[0] || "en";

	const languageLabel = findNameByKey(currentLanguage);

	return (
		<>
			<LanguageSelectorModal
				anchorElement={anchorEl}
				setAnchorElement={setAnchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: -10, // change it when new languages will come!
					horizontal: 40,
				}}
			/>

			<LanguageContainerWithCursor onClick={e => setAnchorEl(e.currentTarget)}>
				<img
					title="Change language version"
					alt="Change language version icon"
					src={LanguageIcon}
					height={26}
					width={26}
				/>

				<Box marginLeft="8px">{languageLabel}</Box>
			</LanguageContainerWithCursor>
		</>
	);
};

const OnboardingPage = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const history = useHistory();
	const location = useLocation();
	const { step } = useParams<{ step?: string }>();

	const { type } = qs.parse(location.search);

	const { openAlertMessage, handleCloseAlert, openAlert } = useAlertMessage();

	const userStatusFromStore = useAppSelector(selectUserStatus);
	const userDataFromStore = useAppSelector(selectUser);

	const [isBusy, setIsBusy] = useState<boolean>(false);

	const profileData = userDataFromStore?.profile_draft || null;
	const uid = userDataFromStore?.id || "";

	const currentStep = step ? Number(step) : 0;
	const binipool = type === "binipool";

	useEffect(() => {
		const fetchProfileData = async () => {
			await store.dispatch<any>(fetchAuthenticatedUser({ dontCatchUserApiError: true }));
		};

		fetchProfileData();
	}, [step]);

	useEffect(() => {
		if (userStatusFromStore === "error") {
			openAlertMessage();
		}

		// eslint-disable-next-line
	}, [userStatusFromStore]);

	useEffect(() => {
		if (userDataFromStore?.userType === "TRAVELER") {
			history.push(paths.ONBOARDING_LOGIN);
		}

		// eslint-disable-next-line
	}, [userDataFromStore]);

	const updateProfileData = useCallback(
		async (data: TUpdateProfileData, dontChangeOnboardingCurrentStep?: boolean, withoutOnboarding?: boolean) => {
			setIsBusy(true);

			try {
				if (data.avatar_blob) {
					if (profileData?.media_gallery?.length) {
						const arrayOfPromises = profileData.media_gallery.map(elem =>
							store.dispatch<any>(deleteUserProfileMedia({ mediaId: elem.id })),
						);

						await Promise.all(arrayOfPromises);
					}

					await store.dispatch<any>(
						getUserProfileMediaUrl({
							file: data.avatar_blob,
							queryParams: { usage: "PROFILE" },
						}),
					);
				}

				const userData: DeepPartial<IUser> = {
					...(!isEmpty(data) && {
						profile_draft: {
							...omit(data, ["avatar_blob", "meeting"]),
						},
					}),
					...(!withoutOnboarding && {
						onboarding: {
							version: EOnboardingVersions.LAST,
							status: EUserOnboardingStatus.IN_PROGRESS,
							type: binipool ? EUserOnboardingType.BINIPOOL : EUserOnboardingType.UNIQUE,
							...(!dontChangeOnboardingCurrentStep && {
								current_step: (currentStep !== EOnboardingSteps.Calendly ? currentStep + 1 : currentStep).toString(),
								steps: {
									...userDataFromStore?.onboarding?.steps,
									[currentStep]: dateObjectToString(new Date(), DATE_FORMATS.API_FORMAT_DATETIME_MS),
								},
							}),
							...(data.meeting && { ...data.meeting }),
						},
					}),
				};

				await usersService.patchPartialUpdate(userDataFromStore?.id || "", userData);

				return true;
			} catch (error) {
				handleAndNotify(error);

				return false;
			} finally {
				setIsBusy(false);
			}
		},

		// eslint-disable-next-line
		[userDataFromStore, profileData, currentStep, binipool],
	);

	const moveToNextStep = useCallback(() => {
		setIsBusy(false);

		handleCloseAlert();

		history.push(fillRoute(paths.ONBOARDING, { step: currentStep + 1, search: type && { type } }));

		// eslint-disable-next-line
	}, [currentStep]);

	const getClassForBg = useCallback((): string => {
		switch (currentStep) {
			case EOnboardingSteps.PhoneVerify:
				return "confirmPhoneBackground";
			case EOnboardingSteps.SelectCities:
			case EOnboardingSteps.ShortBio:
				return "confirmCityBackground";
			case EOnboardingSteps.LongBio:
			case EOnboardingSteps.Binipools:
				return "confirmProfileBackground";
			default:
				return "defaultBackground";
		}
	}, [currentStep]);

	const renderComponentForStep = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<Box height="80vh" display="flex" justifyContent="center" alignItems="center">
						<CircularProgress color="secondary" />
					</Box>
				);
			case EOnboardingSteps.PhoneVerify:
				return <PhoneVerification moveToNextStep={moveToNextStep} updateProfileData={updateProfileData} uid={uid} />;
			case EOnboardingSteps.SelectCities:
			case EOnboardingSteps.ShortBio:
				if (binipool) {
					return (
						<ShortBioWithCity
							moveToNextStep={moveToNextStep}
							updateProfileData={updateProfileData}
							profileData={profileData}
							isBusy={isBusy}
						/>
					);
				} else {
					return (
						<CitiesSelector
							moveToNextStep={moveToNextStep}
							updateProfileData={updateProfileData}
							profileData={profileData}
							isBusy={isBusy}
						/>
					);
				}
			case EOnboardingSteps.LongBio:
			case EOnboardingSteps.Binipools:
				if (binipool) {
					return (
						<Experiences
							moveToNextStep={moveToNextStep}
							updateProfileData={updateProfileData}
							profileData={profileData}
							isBusy={isBusy}
						/>
					);
				} else {
					return (
						<Bio
							moveToNextStep={moveToNextStep}
							updateProfileData={updateProfileData}
							profileData={profileData}
							isBusy={isBusy}
							typeBinipool={binipool}
						/>
					);
				}
			case EOnboardingSteps.Calendly:
				return (
					<CalendlyStep
						moveToNextStep={moveToNextStep}
						updateProfileData={updateProfileData}
						profileData={profileData}
						isBusy={isBusy}
						typeBinipool={binipool}
						email={userDataFromStore?.personal?.email}
						phone={userDataFromStore?.personal?.phone}
					/>
				);
			default:
				return <Redirect to={paths.NOT_FOUND} />;
		}
	}, [
		currentStep,
		moveToNextStep,
		binipool,
		updateProfileData,
		profileData,
		uid,
		isBusy,
		userDataFromStore?.personal?.email,
		userDataFromStore?.personal?.phone,
	]);

	if (currentStep === 0) {
		const onboardingData = userDataFromStore?.onboarding;

		if (onboardingData) {
			if (onboardingData.status === EUserOnboardingStatus.COMPLETED) {
				return <Redirect to={paths.DASHBOARD} />;
			} else {
				return (
					<Redirect
						to={fillRoute(paths.ONBOARDING, {
							step: onboardingData.current_step || EOnboardingSteps.PhoneVerify,
							...(onboardingData.type !== EUserOnboardingType.UNIQUE && { search: { type: "binipool" } }),
						})}
					/>
				);
			}
		} else {
			return (
				<Redirect
					to={fillRoute(paths.ONBOARDING, {
						step: EOnboardingSteps.PhoneVerify,
						search: { type: "binipool" },
					})}
				/>
			);
		}
	}

	return (
		<>
			<Column>
				<LanguageSelectorOnboarding />

				{renderComponentForStep()}
			</Column>

			<BackgroundColumn className={getClassForBg()}>
				<TeaserOnboarding />
			</BackgroundColumn>

			<AlertMessage handleCloseAlert={handleCloseAlert} openAlert={openAlert} severity="error">
				{t("ERRORS.SOMETHING_WENT_WRONG")}
			</AlertMessage>
		</>
	);
};

export default OnboardingPage;
