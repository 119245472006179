import { Box, FormHelperText } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import qs from "query-string";
import { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import EmailIcon from "assets/icons/email.svg";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { newPasswordRequiredQueryParam } from "utils/constants";

import ActionButton from "ui/buttons/ActionButton";

import CodeInput from "../../formik/CodeInput";
import PasswordInput from "../../formik/Input/variant/PasswordInput";
import { HeaderLogo, StyledLinkAuth, SubTitle, Title } from "../shared";

interface IResetPasswordForm {
	code: string;
	password: string;
	passwordConfirm: string;
}

const ResetPasswordView = () => {
	const history = useHistory();
	const location = useLocation();

	const { t, withRaw } = useTranslation();

	const { email, username, migration, token, onboarding, type, ...restQueryParams } = qs.parse(location.search);

	const notification = useNotification();

	const { handleAndNotify } = useErrors();

	const [migrationSuccess, setMigrationSuccess] = useState<boolean>(false);

	const newPasswordParam = restQueryParams[newPasswordRequiredQueryParam];

	const emailParam = typeof email === "string" ? email.toLowerCase() : "";

	const handleResend = async (_, silent?: boolean) => {
		try {
			await Auth.forgotPassword((emailParam || username) as string);

			if (!silent) {
				notification.success();
			}
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const formikProps = useFormik<IResetPasswordForm>({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			password: Yup.string().min(8).required(),
			passwordConfirm: Yup.string()
				.required()
				.oneOf([Yup.ref("password")], t("AUTH.RESET_PASSWORD.RE_PASSWORD_INCORRECT_MESSAGE")),
			code: !newPasswordParam ? Yup.number().required() : Yup.number(),
		}),
		initialValues: {
			password: "",
			passwordConfirm: "",
			code: (token as string) || "",
		},
		onSubmit: async values => {
			try {
				if (newPasswordParam) {
					const userData = await Auth.signIn(emailParam, restQueryParams.password as string);

					await Auth.completeNewPassword(userData, values.password);
				} else {
					await Auth.forgotPasswordSubmit(emailParam, values.code, values.password);
				}

				if (!migration) {
					history.push({
						pathname: onboarding ? paths.ONBOARDING_LOGIN : paths.LOGIN,
						search: qs.stringify({ email, ...(!!type && { type }) }),
					});
				} else {
					setMigrationSuccess(true);
				}
			} catch (e) {
				if (migration) {
					await handleResend(undefined, true);

					notification.add(t("AUTH.RESET_PASSWORD.EXPIRED_CODE_MESSAGE"), "warning");
				} else {
					handleAndNotify(e);
				}
			}
		},
	});

	if (!email && !username && !newPasswordParam) {
		return <Redirect to={paths.LOGIN} />;
	}

	if (migrationSuccess) {
		return (
			<>
				<HeaderLogo />

				<Box display="flex" alignItems="center" justifyContent="center" mb={12}>
					<Title>{t("AUTH.RESET_PASSWORD.HEADER_MIGRATION_SUCCESS")}</Title>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" textAlign="center" mb={20}>
					<SubTitle>{t("AUTH.RESET_PASSWORD.SUB-HEADER_MIGRATION_SUCCESS")}</SubTitle>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<ActionButton
						onClick={() => {
							history.push({
								pathname: paths.LOGIN,
								search: qs.stringify({ email, redirect: paths.PROFILE }),
							});
						}}
						translationDefault="AUTH.RESET_PASSWORD.SUBMIT_BUTTON_MIGRATION_SUCCESS"
					/>
				</Box>
			</>
		);
	}

	return (
		<>
			<HeaderLogo />

			{!migration ? (
				<>
					{!newPasswordParam && (
						<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
							<img alt="email icon" src={EmailIcon} height={"48px"} width={"52px"} />
						</Box>
					)}

					<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
						<Title>
							{t(!newPasswordParam ? "AUTH.RESET_PASSWORD.HEADER" : "AUTH.RESET_PASSWORD.HEADER_CREATE_NEW_PASSWORD")}
						</Title>
					</Box>

					{!newPasswordParam && (
						<Box textAlign="center" mb={10}>
							<SubTitle>{t("AUTH.RESET_PASSWORD.SUB-HEADER")}</SubTitle>
						</Box>
					)}

					<Box textAlign="center" mb={10}>
						<SubTitle as="div">{email}</SubTitle>
					</Box>
				</>
			) : (
				<>
					<Box display="flex" alignItems="center" justifyContent="center" mb={12}>
						<Title>{t("AUTH.RESET_PASSWORD.HEADER_MIGRATION")}</Title>
					</Box>

					<Box display="flex" alignItems="center" justifyContent="center" textAlign="center" mb={20}>
						<SubTitle>{withRaw("AUTH.RESET_PASSWORD.SUB-HEADER_MIGRATION")}</SubTitle>
					</Box>
				</>
			)}

			<FormikProvider value={formikProps}>
				<Form onSubmit={formikProps.handleSubmit}>
					{!migration && !newPasswordParam && (
						<Box textAlign="center" mb={10}>
							<CodeInput fields={6} name="code" pattern="[0-9]+" inputMode="numeric" autoFocus />
						</Box>
					)}

					<PasswordInput
						required
						name="password"
						label={t("AUTH.RESET_PASSWORD.PASSWORD_NEW")}
						inputClassName="full break"
						autoComplete="new-password"
					/>

					<PasswordInput
						required
						name="passwordConfirm"
						label={t("AUTH.RESET_PASSWORD.PASSWORD_CONFIRM")}
						inputClassName="full break"
						autoComplete="new-password"
					/>

					<Box mt={-12} mb={20}>
						<FormHelperText>
							<ErrorOutline style={{ color: "rgba 0 0 0, 0.8", fontSize: "12px", marginRight: "5px" }} />
							{t("AUTH.REGISTER.PASSWORD_HELPER_TEXT")}
						</FormHelperText>
					</Box>

					<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
						<ActionButton
							isAction={formikProps.isSubmitting}
							disabled={formikProps.isSubmitting || !formikProps.isValid}
							onClick={() => formikProps.handleSubmit()}
							translationDefault={
								!migration ? "AUTH.RESET_PASSWORD.SUBMIT_BUTTON" : "AUTH.RESET_PASSWORD.SUBMIT_BUTTON_MIGRATION"
							}
						/>
					</Box>

					{!migration && !newPasswordParam && (
						<>
							<Box textAlign="center" mb={10}>
								<SubTitle as="div">{t("AUTH.RESET_PASSWORD.CHECK_SPAM_DESC")}</SubTitle>
							</Box>

							<Box display="flex" alignItems="center" justifyContent="center" mb={5}>
								<StyledLinkAuth as="div" onClick={handleResend}>
									{t("AUTH.RESET_PASSWORD.RESEND_ANCHOR")}
								</StyledLinkAuth>
							</Box>
						</>
					)}
				</Form>
			</FormikProvider>
		</>
	);
};

export default ResetPasswordView;
