import { Box, Divider } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";

import { prices } from "utils/prices";

import colors from "styles/colors";

import { SubTitle, Text, TextBold } from "../index";
import Promocode from "./Promocode";

const Row = styled(Box)`
	margin-bottom: 10px;
	display: flex;
`;

const StyledDivider = styled(Divider)<{ $withCustomMarginTop?: string }>`
	margin: 3px 0 12px 0;

	${props => props.$withCustomMarginTop && `margin-top: ${props.$withCustomMarginTop};`}
`;

interface IBookingDetails {
	children: number[]; // ages for all the children
	language: string | undefined;
}

const itemWidth = "50px";

const BookingDetails = ({ language, children }: IBookingDetails) => {
	const { t } = useTranslation();

	const booking = useAppSelector(selectExperienceTravellerBooking);

	const hasChildren = children.length > 0;

	return (
		<>
			<Box marginTop="30px" marginBottom="18px">
				<SubTitle>{t("BOOKING_SUMMARY.SUBTITLE_REQUEST_DETAILS")}</SubTitle>
			</Box>

			<Row>
				<Box flex={0.7}>
					<Text>{t("BOOKING_SUMMARY.ROW_NUMBER_OF_PEOPLE")}</Text>
				</Box>

				<Box flex={1}>
					<TextBold>{booking.adults}</TextBold> <Text>{t("BOOKING.SUMMARY.ADULTS")}</Text>,{" "}
					<TextBold>{booking.kids}</TextBold> <Text>{t("BOOKING.SUMMARY.KIDS")}</Text>
					{hasChildren && (
						<Text lowercase> ({children.map(child => `${child} ${t("BOOKING.SUMMARY.YEARS_OLD")}`).join(", ")})</Text>
					)}
				</Box>
			</Row>

			<Row>
				<Box flex={0.7}>
					<Text>{t("BOOKING_SUMMARY.ROW_LANGUAGE")}</Text>
				</Box>

				<Box flex={1}>
					<TextBold>{language}</TextBold>
				</Box>
			</Row>

			<StyledDivider />

			{!booking.isPrivate && (
				<>
					<Row>
						<Box flex={0.7}>
							<TextBold>{t("BOOKING_SUMMARY.ROW_ADULTS")}</TextBold>
						</Box>

						<Box display="flex" justifyContent="space-between" flex={0.5}>
							<Box width={itemWidth}>
								<TextBold>{booking.pricing.tickets?.adults.quantity}</TextBold>
							</Box>

							<Text lowercase>x</Text>

							<Box minWidth={itemWidth}>
								<TextBold>
									{prices(booking.pricing.tickets?.adults.price, booking.pricing.currency, undefined, undefined, true)}
								</TextBold>
							</Box>
						</Box>

						<Box display="flex" justifyContent="flex-end" flex={0.5}>
							<TextBold>
								{prices(
									(booking.pricing.tickets?.adults.quantity || 0) * (booking.pricing.tickets?.adults.price || 0),
									booking.pricing.currency,
									undefined,
									undefined,
									true,
								)}
							</TextBold>
						</Box>
					</Row>

					{booking.pricing.tickets?.kids && !!Object.entries(booking.pricing.tickets.kids).length && (
						<>
							<StyledDivider />

							<Row>
								<Box flex={0.5}>
									<TextBold>{t("BOOKING_SUMMARY.ROW_KIDS")}</TextBold>
								</Box>

								<Box display="flex" justifyContent="flex-end" flex={0.5}>
									<TextBold>
										{prices(
											(booking.pricing.total_gross_price || 0) -
												booking.pricing.tickets.adults.quantity * booking.pricing.tickets.adults.price,
											booking.pricing.currency,
											undefined,
											undefined,
											true,
										)}
									</TextBold>
								</Box>
							</Row>

							{Object.entries(booking.pricing.tickets.kids).map(([age, details], index) => (
								<Row key={index}>
									<Box flex={0.7}>
										<Text lowercase>
											<Text>{t("BOOKING.SUMMARY.KIDS")}</Text> <b>{age}</b> {t("BOOKING.SUMMARY.YEARS_OLD")}
										</Text>
									</Box>

									<Box display="flex" justifyContent="space-between" flex={0.5}>
										<Box width={itemWidth}>
											<TextBold>{details.quantity}</TextBold>
										</Box>

										<Text lowercase>x</Text>

										<Box minWidth={itemWidth}>
											<TextBold>{prices(details.price, booking.pricing.currency, undefined, undefined, true)}</TextBold>
										</Box>
									</Box>

									<Box flex={0.5} />
								</Row>
							))}
						</>
					)}

					<StyledDivider />
				</>
			)}

			<Promocode />

			<StyledDivider $withCustomMarginTop="20px" />

			{typeof booking.pricing.discount?.affiliate_code?.value !== "undefined" && (
				<Row>
					<Box>
						<TextBold color={colors.sunShade}>
							{booking.pricing.discount.affiliate_code?.message || t("BOOKING_SUMMARY.ROW_AFFILIATE")}
						</TextBold>
					</Box>

					<Box display="flex" justifyContent="flex-end" flex={1} minWidth="60px">
						<TextBold color={colors.sunShade}>
							-
							{prices(
								booking.pricing.discount.affiliate_code.value,
								booking.pricing.currency,
								undefined,
								undefined,
								true,
							)}
						</TextBold>
					</Box>
				</Row>
			)}

			<Row>
				<Box flex={1}>
					<TextBold>{t("BOOKING_SUMMARY.ROW_TOTAL_PRICE")}</TextBold>
				</Box>

				<Box display="flex" justifyContent="flex-end" flex={1}>
					<TextBold>
						{prices(booking.pricing.final_gross_price, booking.pricing.currency, undefined, undefined, true)}
					</TextBold>
				</Box>
			</Row>
		</>
	);
};

export default BookingDetails;
