interface IResponsiveImgProps {
	altDesc: string;
	imgSmall: string;
	imgMedium: string;
	imgLarge: string;
}

const ResponsiveImage = ({ altDesc, imgSmall, imgMedium, imgLarge }: IResponsiveImgProps) => (
	<picture>
		<source media="(max-width: 768px)" srcSet={imgSmall} />
		<source media="(max-width: 1024px)" srcSet={imgMedium} />
		<source media="(max-width: 1340px)" srcSet={imgLarge} />

		<img src={imgLarge} title={altDesc} alt={altDesc} width="100%" />
	</picture>
);

export default ResponsiveImage;
