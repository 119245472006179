import { Box, Button, Checkbox, useMediaQuery, useTheme } from "@material-ui/core";
import { omit, pick } from "lodash-es";
import { useEffect, useReducer, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import useCity from "store/hooks/useCity";
import useCountry from "store/hooks/useCountry";
import useLanguages from "store/hooks/useLanguages";

import fillRoute from "utils/routes";
import { getParams } from "utils/urls";

import AutocompleteUI, { Option } from "ui/forms/Autocomplete";
import OptionsList from "ui/OptionsList";

import { StyledLink } from "styles/common";

import { ISearchParams } from "../../index";
import {
	AdvertisementContent,
	AdvertisementText,
	ArrowContent,
	ClearButton,
	ClearButtonContent,
	EndAdornment,
	ExpandLessIcon,
	InputLabel,
	Item,
	LetsStartedButton,
	MoreFiltersWrapper,
	PriceWrapper,
	ShowMoreButton,
	StartAdornment,
	StyledFormControlLabel,
	StyledTextField,
	Title,
	TitleWrapper,
} from "./styled";

const defaultStateCategories = {
	HISTORY: false,
	ART_CULTURE: false,
	PHOTOGRAPHY: false,
	ARCHITECTURE: false,
	SPORT: false,
	FOOD_DRINKS: false,
	ADVENTURE: false,
	NIGHT_LIFE: false,
	NATURE: false,
	FASHION: false,
	LIFESTYLE: false,
	DISCOVERY: false,
	EXCLUSIVE: false,
};

const reducerCategories = (state, action) => {
	switch (action.type) {
		case "clear-all-filters":
			return defaultStateCategories;
		default:
			return { ...state, [action.type]: !state[action.type] };
	}
};

// Hardcoded, major languages from business perspective!
const defaultStateLanguages = {
	de: false,
	fr: false,
	en: false,
	it: false,
	es: false,
};

const reducerLanguages = (state, action: { type: string; payload?: Record<string, boolean> }) => {
	switch (action.type) {
		case "clear-all-filters":
			return defaultStateLanguages;
		case "update-all-filters":
			return action.payload;
		default:
			return { ...state, [action.type]: !state[action.type] };
	}
};

const mainLanguagesKeys = Object.keys(defaultStateLanguages);

const MoreFilters = () => {
	const { t } = useTranslation();

	const { search } = useLocation();
	const history = useHistory();

	const { country, city, category } = useParams<{ country?: string; city?: string; category?: string }>();

	const { citiesSelectForCountryOrAll } = useCity();
	const { countries, countriesSelectSorted } = useCountry();
	const { languagesSelectSorted, isLoading } = useLanguages();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const categoriesDict = getDictionary("CATEGORIES");

	const queryParams: ISearchParams = getParams(new URLSearchParams(search));

	const [minPrice, setMinPrice] = useState<string>(queryParams.min_price || "");
	const [maxPrice, setMaxPrice] = useState<string>(queryParams.max_price || "");
	const [duration, setDuration] = useState<number>(parseInt(queryParams.min_duration as string, 10) / 60 || 0);
	const [selectedCountry, setSelectedCountry] = useState<string>(country || "");
	const [selectedCity, setSelectedCity] = useState<string>(city || "");
	const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);
	const [showLanguages, setShowLanguages] = useState<boolean>(false);
	const [showCategories, setShowCategories] = useState<boolean>(false);
	const [showCountries, setShowCountries] = useState<boolean>(false);
	const [showCities, setShowCities] = useState<boolean>(false);

	const mainLanguages = languagesSelectSorted.filter(elem => mainLanguagesKeys.includes(elem.value));

	const checkMultiParam = (value: string, param: string): boolean => {
		if (!queryParams[param]) {
			return false;
		}

		if (Array.isArray(queryParams[param])) {
			return !!queryParams[param].find(elem => elem === value);
		}

		return queryParams[param] === value;
	};

	const initialStateCategories = () => {
		const tempDictCategories = {};

		if (category) {
			const categoryElem = categoriesDict.find(elem => elem.label === category);

			if (categoryElem) {
				tempDictCategories[categoryElem.value] = true;

				return tempDictCategories;
			}
		}

		categoriesDict.forEach(v => (tempDictCategories[v.value] = checkMultiParam(v.value, "categories")));

		return tempDictCategories;
	};

	const [stateCategories, dispatchCategories] = useReducer(reducerCategories, initialStateCategories());

	const [stateLanguages, dispatchLanguages] = useReducer(reducerLanguages, defaultStateLanguages);

	useEffect(() => {
		const initialStateLanguages = languagesSelectSorted.reduce(
			(finalObject, currentValue) => {
				const result = checkMultiParam(currentValue.value, "languages");

				if (result) {
					finalObject[currentValue.value] = true;
				}

				return finalObject;
			},
			{ ...defaultStateLanguages },
		);

		dispatchLanguages({ type: "update-all-filters", payload: initialStateLanguages });

		// eslint-disable-next-line
	}, [isLoading]);

	const clearAllFilters = () => {
		dispatchCategories({ type: "clear-all-filters" });
		dispatchLanguages({ type: "clear-all-filters" });

		setMinPrice("");
		setMaxPrice("");
		setDuration(0);
		setSelectedCountry("");
		setSelectedCity("");
	};

	const applyFilters = () => {
		const setUpFilters: { [v: string]: string | number } = { offset: 0 };

		if (minPrice) {
			setUpFilters.min_price = minPrice;
		}

		if (maxPrice) {
			setUpFilters.max_price = maxPrice;
		}

		if (duration && duration !== 0) {
			setUpFilters.min_duration = duration * 60;
		}

		const categoriesArray = Object.entries(stateCategories)
			.filter(([name, value]) => value)
			.map(([name, value]) => name);

		const languagesArray = Object.entries(stateLanguages)
			.filter(([name, value]) => value)
			.map(([name, value]) => name);

		// @ts-ignore
		const queryURL = new URLSearchParams({
			...pick(queryParams, ["date_from", "date_to", "text_search", "number_of_travelers"]),
			...setUpFilters,
		});

		if (categoriesArray.length) {
			queryURL.delete("categories");

			categoriesArray.forEach(elem => {
				queryURL.append("categories", elem);
			});
		}

		if (languagesArray.length) {
			queryURL.delete("languages");

			languagesArray.forEach(elem => {
				queryURL.append("languages", elem);
			});
		}

		let pathname: string = paths.SEARCH;

		// Order is important!!!
		if (selectedCountry) {
			pathname = fillRoute(paths.SEARCH_COUNTRY, { country: selectedCountry });
		}

		if (selectedCity) {
			pathname = fillRoute(paths.SEARCH_CITY, { city: selectedCity });
		}

		if (categoriesArray.length === 1) {
			queryURL.delete("categories");

			pathname = fillRoute(paths.SEARCH_CATEGORY, {
				category: categoriesDict.find(elem => elem.value === categoriesArray[0]).label,
			});
		}

		if (selectedCountry && selectedCity) {
			pathname = fillRoute(paths.SEARCH_COUNTRY_CITY, {
				country: selectedCountry,
				city: selectedCity,
			});
		}

		if (selectedCountry && categoriesArray.length === 1) {
			queryURL.delete("categories");

			pathname = fillRoute(paths.SEARCH_COUNTRY_CATEGORY, {
				country: selectedCountry,
				category: categoriesDict.find(elem => elem.value === categoriesArray[0]).label,
			});
		}

		if (selectedCity && categoriesArray.length === 1) {
			queryURL.delete("categories");

			pathname = fillRoute(paths.SEARCH_CITY_CATEGORY, {
				city: selectedCity,
				category: categoriesDict.find(elem => elem.value === categoriesArray[0]).label,
			});
		}

		if (selectedCountry && selectedCity && categoriesArray.length === 1) {
			queryURL.delete("categories");

			pathname = fillRoute(paths.SEARCH_COUNTRY_CITY_CATEGORY, {
				country: selectedCountry,
				city: selectedCity,
				category: categoriesDict.find(elem => elem.value === categoriesArray[0]).label,
			});
		}

		history.push({ pathname, search: queryURL.toString() });

		setShowMoreFilters(false);
	};

	const citiesList = citiesSelectForCountryOrAll(selectedCountry);

	return (
		<Box display="flex" position="relative">
			<ShowMoreButton isHide={showMoreFilters} onClick={() => setShowMoreFilters(prevState => !prevState)}>
				{showMoreFilters
					? t("SEARCH.EXPERIENCES.MORE_FILTERS.HIDE_FILTERS")
					: t("SEARCH.EXPERIENCES.MAIN_FILTERS.MORE_FILTERS")}
			</ShowMoreButton>

			{showMoreFilters && (
				<MoreFiltersWrapper>
					<Item>
						<Box>
							<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.PRICING")}</Title>

							<PriceWrapper>
								<InputLabel>{t("SEARCH.EXPERIENCES.ITEM.FROM")}</InputLabel>

								<StyledTextField
									$customWidth="80px"
									$customMargin=" 0 10px"
									variant="outlined"
									label={t("SEARCH.EXPERIENCES.ITEM.PER_PERSON")}
									type="number"
									value={minPrice}
									onChange={e => setMinPrice(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ inputProps: { min: 0 } }}
								/>

								<InputLabel>{t("SEARCH.EXPERIENCES.ITEM.TO")}</InputLabel>

								<StyledTextField
									$customWidth="80px"
									$customMargin="0 0 0 10px"
									variant="outlined"
									label={t("SEARCH.EXPERIENCES.ITEM.PER_PERSON")}
									type="number"
									value={maxPrice}
									onChange={e => setMaxPrice(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</PriceWrapper>
						</Box>

						<Box>
							<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.DURATION")}</Title>

							<StyledTextField
								$fontBold
								$customMargin="10px 0 30px"
								variant="outlined"
								type="number"
								value={duration}
								onChange={e => setDuration(parseInt(e.target.value, 10))}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputProps: { min: 0 },
									startAdornment: <StartAdornment>{t("SEARCH.EXPERIENCES.MORE_FILTERS.MORE_THAN")}</StartAdornment>,
									endAdornment: <EndAdornment>{t("COMMON.HOUR_SHORT")}</EndAdornment>,
								}}
							/>
						</Box>

						<Box>
							<TitleWrapper>
								<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.LANGUAGES")}</Title>

								{isSmallScreen && (
									<ArrowContent onClick={() => setShowLanguages(prevState => !prevState)}>
										<ExpandLessIcon $isShowLess={!showLanguages} />
									</ArrowContent>
								)}
							</TitleWrapper>

							{((showLanguages && isSmallScreen) || !isSmallScreen) && (
								<Box display="flex" flexDirection="column">
									{mainLanguages.map(language => (
										<StyledFormControlLabel
											key={language.value}
											control={
												<Checkbox
													checked={stateLanguages[language.value]}
													onChange={() => dispatchLanguages({ type: language.value })}
													name={`id-language-${language.value}-checkbox`}
												/>
											}
											label={language.label}
										/>
									))}

									<Box marginTop="10px" marginBottom="25px">
										<AutocompleteUI
											name="more-languages"
											label={t("SEARCH.EXPERIENCES.MORE_FILTERS.MORE_LANGUAGES")}
											options={languagesSelectSorted.filter(elem => !mainLanguagesKeys.includes(elem.value))}
											textFieldProps={{
												variant: "outlined",
												size: "small",
											}}
											onChange={(v: Option) => {
												const mainLanguagesValues = pick(stateLanguages, mainLanguagesKeys);

												if (!!v?.value) {
													dispatchLanguages({
														type: "update-all-filters",
														payload: { ...mainLanguagesValues, [v.value]: true },
													});
												} else {
													dispatchLanguages({ type: "update-all-filters", payload: { ...mainLanguagesValues } });
												}
											}}
											value={Object.entries(omit(stateLanguages, mainLanguagesKeys)).find(([key, value]) => value)?.[0]}
											filterSelectedOptions
										/>
									</Box>
								</Box>
							)}
						</Box>
					</Item>

					<Item $isPaddingLeft>
						<TitleWrapper>
							<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.CATEGORIES")}</Title>

							{isSmallScreen && (
								<ArrowContent onClick={() => setShowCategories(prevState => !prevState)}>
									<ExpandLessIcon $isShowLess={!showCategories} />
								</ArrowContent>
							)}
						</TitleWrapper>

						{((showCategories && isSmallScreen) || !isSmallScreen) && (
							<Box display="flex" flexDirection="column">
								{getDictionary("CATEGORIES").map(elem => (
									<StyledFormControlLabel
										key={elem.value}
										control={
											<Checkbox
												checked={stateCategories[elem.value]}
												onChange={() => dispatchCategories({ type: elem.value })}
												name={`id-category-${elem.value}-checkbox`}
											/>
										}
										label={t("EXPERIENCE.CATEGORY." + elem.value)}
									/>
								))}
							</Box>
						)}
					</Item>

					<Item>
						<TitleWrapper>
							<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.COUNTRY")}</Title>

							{isSmallScreen && (
								<ArrowContent onClick={() => setShowCountries(prevState => !prevState)}>
									<ExpandLessIcon $isShowLess={!showCountries} />
								</ArrowContent>
							)}
						</TitleWrapper>

						{((showCountries && isSmallScreen) || !isSmallScreen) && (
							<Box marginTop="10px">
								<OptionsList
									width="100%"
									height={countries && countries.length > 20 ? "400px" : "auto"}
									maxHeight="400px"
									options={countriesSelectSorted}
									selectedValue={selectedCountry}
									onItemClick={(v: string) =>
										setSelectedCountry(prevState => {
											if (prevState === v) {
												setSelectedCity("");

												return "";
											}

											return v;
										})
									}
									error=""
								/>
							</Box>
						)}
					</Item>

					<Item $isPaddingLeft>
						<TitleWrapper>
							<Title>{t("SEARCH.EXPERIENCES.MORE_FILTERS.CITY")}</Title>

							{isSmallScreen && (
								<ArrowContent onClick={() => setShowCities(prevState => !prevState)}>
									<ExpandLessIcon $isShowLess={!showCities} />
								</ArrowContent>
							)}
						</TitleWrapper>

						{((showCities && isSmallScreen) || !isSmallScreen) && (
							<Box marginTop="10px">
								<OptionsList
									width="100%"
									height={citiesList && citiesList.length > 20 ? "400px" : "auto"}
									maxHeight="400px"
									options={citiesList}
									selectedValue={selectedCity}
									onItemClick={(v: string) => setSelectedCity(prevState => (prevState === v ? "" : v))}
									error=""
								/>
							</Box>
						)}
					</Item>

					<ClearButtonContent>
						<ClearButton onClick={clearAllFilters}>{t("SEARCH.EXPERIENCES.MORE_FILTERS.CLEAR")}</ClearButton>
					</ClearButtonContent>

					<Box display="flex" justifyContent="flex-end" position="absolute" bottom="25px" right="15px">
						<Button color="primary" variant="contained" onClick={applyFilters}>
							{t("SEARCH.EXPERIENCES.MORE_FILTERS.APPLY_FILTERS")}
						</Button>
					</Box>
				</MoreFiltersWrapper>
			)}

			{!isSmallScreen && showMoreFilters && (
				<AdvertisementContent>
					<Box
						display="flex"
						justifyContent="flex-end"
						alignItems="flex-end"
						flexDirection="column"
						width="90%"
						paddingBottom="20%"
					>
						<AdvertisementText>{t("HP.ADVERTISEMENT.TITLE")}</AdvertisementText>

						<StyledLink $withoutHoverUnderline to={paths.BINIPOOL_CONCEPT}>
							<LetsStartedButton color="primary" variant="contained">
								{t("HP.ADVERTISEMENT.START_BTN")}
							</LetsStartedButton>
						</StyledLink>
					</Box>
				</AdvertisementContent>
			)}
		</Box>
	);
};

export default MoreFilters;
