import { isArray } from "lodash-es";

import { Option } from "./index";

const mapValueToOptionsValue = (
	value: unknown | unknown[],
	options: Option[],
	config: { multiple?: boolean; freeSolo?: boolean },
) => {
	let localValue: unknown | unknown[] = "";

	if (config?.multiple) {
		localValue = isArray(value) ? value : [value];
	} else {
		localValue = isArray(value) ? [...value].join(", ") : value;
	}

	if (isArray(localValue)) {
		return localValue.filter(v => !!v).map(v => options.find(o => o.value === v) || { value: v, label: v });
	}

	return (
		options?.find(o => o.value === localValue) || (config?.freeSolo ? { value: localValue, label: localValue } : null)
	);
};

export { mapValueToOptionsValue };
