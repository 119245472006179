import { Box, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import styled from "styled-components";

import VerifiedUserIcon from "assets/icons/verified-user.svg";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		veryfiedStatus: {
			border: `1px solid ${theme.palette.primary.main}`,
			borderRadius: "4px",
		},
	}),
);

const Label = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: 0.08px;
	color: ${colors.mainFontColor};
`;

interface IVerifiedStatusProps {
	currentStatus: string;
	withoutMarginLeft?: boolean;
}

const VerifiedStatusBox = ({ currentStatus, withoutMarginLeft }: IVerifiedStatusProps) => {
	const classes = useStyles();
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			marginLeft={withoutMarginLeft ? undefined : 10}
			paddingRight={5}
			paddingLeft={5}
			pt={2}
			pb={2}
			className={classes.veryfiedStatus}
		>
			<img
				alt=""
				src={VerifiedUserIcon}
				height={isSmallScreen ? "18px" : "22px"}
				width={isSmallScreen ? "18px" : "22px"}
			/>

			{!isMediumScreen && (
				<Box ml={5}>
					<Label>{currentStatus}</Label>
				</Box>
			)}
		</Box>
	);
};

export default VerifiedStatusBox;
