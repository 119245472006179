import { EUserOnboardingMeetingStatus, IProfileDraft } from "data/users/types";

export type TUpdateProfileData = Partial<IProfileDraft> & {
	avatar_blob?: Blob | null;
	meeting?: {
		meeting_id: string;
		meeting_status: EUserOnboardingMeetingStatus.SCHEDULED;
	};
};

export interface ISharedOnboardingProps {
	moveToNextStep(): void;
	updateProfileData(
		data: TUpdateProfileData,
		dontChangeOnboardingCurrentStep?: boolean,
		withoutOnboarding?: boolean,
	): Promise<boolean>;
	profileData?: IProfileDraft | null;
	isBusy?: boolean;
	typeBinipool?: boolean;
}

export enum EPhoneConfirmationMethods {
	SMS = "SMS",
	WhatsApp = "WhatsApp",
}
