import { useMediaQuery, useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import DistributionPartnerImgSmall from "assets/images/help/distributionPartners/become_distribution_partner.png";
import DistributionPartnerImgMedium from "assets/images/help/distributionPartners/become_distribution_partner@2x.png";
import DistributionPartnerImgLarge from "assets/images/help/distributionPartners/become_distribution_partner@3x.png";
import LiveCitiesImgMedium from "assets/images/help/distributionPartners/live_cities@2x.png";
import LiveExperiencesImgMedium from "assets/images/help/distributionPartners/live_experiences@2x.png";
import LocalsImgSmall from "assets/images/help/distributionPartners/locals.png";
import LocalsImgMedium from "assets/images/help/distributionPartners/locals@2x.png";
import LocalsImgLarge from "assets/images/help/distributionPartners/locals@3x.png";
import LocalsStatisticsImgMedium from "assets/images/help/distributionPartners/locals_statistics@2x.png";
import SwissApproachSmall from "assets/images/help/distributionPartners/swiss_approach.png";
import SwissApproachMedium from "assets/images/help/distributionPartners/swiss_approach@2x.png";
import SwissApproachLarge from "assets/images/help/distributionPartners/swiss_approach@3x.png";
import TechnologyImgSmall from "assets/images/help/distributionPartners/technology.png";
import TechnologyImgMedium from "assets/images/help/distributionPartners/technology@2x.png";
import TechnologyImgLarge from "assets/images/help/distributionPartners/technology@3x.png";
import WhoCanBecomeImgSmall from "assets/images/help/distributionPartners/who_can_become.png";
import WhoCanBecomeImgMedium from "assets/images/help/distributionPartners/who_can_become@2x.png";
import WhoCanBecomeImgLarge from "assets/images/help/distributionPartners/who_can_become@3x.png";

import useTranslation from "hooks/useTranslation";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import colors from "styles/colors";
import { Container, ImgWrapper, MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import CommercialTermsForm from "./components/CommercialTermsForm";

const WrapperSection = styled.section<{ flexDirectionValue: string }>`
	margin-bottom: 80px;
	display: flex;
	flex-direction: ${props => props.flexDirectionValue};

	${media.desktop`
    margin-bottom: 40px;
  `};
`;

const Col = styled.div<{ isPaddingLeft?: boolean }>`
	flex: 1;
	${props => props.isPaddingLeft && "padding-left: 40px;"}

	${media.desktop`
    padding-left: 0;
  `};
`;

const Background = styled.div`
	padding-bottom: 1rem;
	margin-bottom: 6.25rem;
	width: 100%;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
   clip-path: ellipse(150% 50% at 50% 50%);
   padding-bottom: 2rem;
  `};
`;

const StatisticsContent = styled.section`
	padding-top: 3.875rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const ImgsContent = styled.div`
	width: 100%;
	margin: 2.125rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	${media.desktop`
    justify-content: center;
  `};
`;

const TileContent = styled.div`
	width: 23.875rem;
	height: 17.438rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const LiveCitiesTileContent = styled(TileContent)`
	background-image: url(${LiveCitiesImgMedium});
`;

const LocalsStatisticsTileContent = styled(TileContent)`
	background-image: url(${LocalsStatisticsImgMedium});
`;

const LiveExperiencesTileContent = styled(TileContent)`
	background-image: url(${LiveExperiencesImgMedium});
`;

const TileTitle = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xxlarge};
	font-weight: bold;
	line-height: 1.15;
	letter-spacing: 0.05px;
	text-align: center;
	color: ${colors.white};
`;

const TileTitleNumber = styled.span`
	font-size: 50px;
	letter-spacing: 0.1px;
`;

const statisticsMockData = {
	live_cities: 130,
	locals: 5000,
	live_experiences: 2000,
};

const DistributionPartnersPage = () => {
	const { t, withRaw } = useTranslation();
	const theme = useTheme();
	const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
	const { live_cities, locals, live_experiences } = statisticsMockData;

	return (
		<AppWrapper>
			<Helmet>
				<title>{t("HELP.DISTRIBUTION_PARTNER.TITLE_1")} | LocalBini</title>
			</Helmet>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.DISTRIBUTION_PARTNER.TITLE_1")} />

				<WrapperSection flexDirectionValue={isMdScreen ? "column" : "row"}>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.DISTRIBUTION_PARTNER.TITLE_1")}
							description={withRaw("HELP.DISTRIBUTION_PARTNER.DESCRIPTION_1", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Become a distribution partner - main picture"
								imgSmall={DistributionPartnerImgSmall}
								imgMedium={DistributionPartnerImgMedium}
								imgLarge={DistributionPartnerImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</WrapperSection>

				<WrapperSection flexDirectionValue={isMdScreen ? "column-reverse" : "row"}>
					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Who can become a distribution partner of LocalBini platform? - picture"
								imgSmall={WhoCanBecomeImgSmall}
								imgMedium={WhoCanBecomeImgMedium}
								imgLarge={WhoCanBecomeImgLarge}
							/>
						</ImgWrapper>
					</Col>

					<Col isPaddingLeft>
						<CommonParagraph
							isLightTitle
							isSecondHeader
							mainTitle={t("HELP.DISTRIBUTION_PARTNER.TITLE_2")}
							description={withRaw("HELP.DISTRIBUTION_PARTNER.DESCRIPTION_2", null)}
						/>
					</Col>
				</WrapperSection>

				<WrapperSection flexDirectionValue={isMdScreen ? "column" : "row"}>
					<Col>
						<CommonParagraph
							isLightTitle
							isSecondHeader
							mainTitle={t("HELP.DISTRIBUTION_PARTNER.TITLE_3")}
							description={withRaw("HELP.DISTRIBUTION_PARTNER.DESCRIPTION_3", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="A Swiss approach towards quality - picture"
								imgSmall={SwissApproachSmall}
								imgMedium={SwissApproachMedium}
								imgLarge={SwissApproachLarge}
							/>
						</ImgWrapper>
					</Col>
				</WrapperSection>
			</Container>

			<Background>
				<Container>
					<StatisticsContent>
						<SmallerTitle>{t("HELP.DISTRIBUTION_PARTNER.TITLE_4")}</SmallerTitle>

						<ImgsContent>
							<LiveCitiesTileContent>
								<TileTitle>
									<TileTitleNumber>{live_cities}+</TileTitleNumber>
									<br />
									{t("HELP.DISTRIBUTION_PARTNER.LIVE_CITIES_LABEL")}
								</TileTitle>
							</LiveCitiesTileContent>

							<LocalsStatisticsTileContent>
								<TileTitle>
									<TileTitleNumber>{locals}+</TileTitleNumber>
									<br />
									{t("HELP.DISTRIBUTION_PARTNER.LOCALS_LABEL")}
								</TileTitle>
							</LocalsStatisticsTileContent>

							<LiveExperiencesTileContent>
								<TileTitle>
									<TileTitleNumber>{live_experiences}+</TileTitleNumber>
									<br />
									{t("HELP.DISTRIBUTION_PARTNER.LIVE_EXPERIENCES_LABEL")}
								</TileTitle>
							</LiveExperiencesTileContent>
						</ImgsContent>
					</StatisticsContent>
				</Container>
			</Background>

			<Container>
				<WrapperSection flexDirectionValue={isMdScreen ? "column-reverse" : "row"}>
					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="The Locals - picture"
								imgSmall={LocalsImgSmall}
								imgMedium={LocalsImgMedium}
								imgLarge={LocalsImgLarge}
							/>
						</ImgWrapper>
					</Col>

					<Col isPaddingLeft>
						<CommonParagraph
							isLightTitle
							isSecondHeader
							mainTitle={t("HELP.DISTRIBUTION_PARTNER.TITLE_5")}
							description={withRaw("HELP.DISTRIBUTION_PARTNER.DESCRIPTION_5", null)}
						/>
					</Col>
				</WrapperSection>

				<WrapperSection flexDirectionValue={isMdScreen ? "column" : "row"}>
					<Col>
						<CommonParagraph
							isLightTitle
							isSecondHeader
							mainTitle={t("HELP.DISTRIBUTION_PARTNER.TITLE_6")}
							description={withRaw("HELP.DISTRIBUTION_PARTNER.DESCRIPTION_6", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Technology - picture"
								imgSmall={TechnologyImgSmall}
								imgMedium={TechnologyImgMedium}
								imgLarge={TechnologyImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</WrapperSection>
			</Container>

			<Background>
				<Container>
					<CommercialTermsForm />
				</Container>
			</Background>
		</AppWrapper>
	);
};

export default DistributionPartnersPage;
