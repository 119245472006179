import { IBookingIId } from "data/backoffice/bookings/types";

export const columns = [
	"Booking ID",
	"Status",
	"Experience title",
	"City & Country",
	"Date & time",
	"Price",
	"Traveller info",
	"",
];

export const fields: (keyof IBookingIId | "city_country_HELPER" | "action_HELPER")[] = [
	"id",
	"status",
	"exp_title",
	"city_country_HELPER",
	"date_time",
	"price",
	"traveler_details",
	"action_HELPER",
];

export const sortedColumns = ["Status", "Experience title", "Date & time", "Price"];

export const idForContainer = "partner-bookings-main-view";
export const listItemsOffset = 20;
