import { array } from "yup";

import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

const useValidationSchema = () => {
	const { t } = useTranslation();
	const {
		yup: {
			validations,
			requiredStringMinMaxLength,
			requiredString,
			maxArrayItems,
			minMaxArrayItems,
			requiredNumber,
			requiredCheckbox,
			requiredMinMinutes,
		},
		translations: { required, tooFew },
	} = useValidations();

	return validations({
		title: requiredStringMinMaxLength(1, 100),
		country: requiredString,
		city: requiredString,
		languages: array().nullable().min(1, tooFew(1)).required(required),
		relevant_attractions: maxArrayItems(10),
		categories: minMaxArrayItems(1, 10),
		description: requiredStringMinMaxLength(150, 300),
		description_uniqueness: requiredStringMinMaxLength(500, 1000),

		requirements: maxArrayItems(50),
		included: maxArrayItems(50),
		excluded: maxArrayItems(50),
		accessibility: maxArrayItems(50),
		location: validations({
			start: validations({
				coordinates: validations({
					lon: requiredNumber,
					lat: requiredNumber,
				}),
				formatted_address: requiredString,
			}),
			end: validations({
				coordinates: validations({
					lon: requiredNumber,
					lat: requiredNumber,
				}),
				formatted_address: requiredString,
			}),
		}),

		duration_minutes: requiredMinMinutes(60),

		media_rights_confirmed_HELPER: requiredCheckbox(t("EXPERIENCE.FORM.ACCEPT_CONDITION_ERROR")),

		pricing: validations(),
	});
};

export default useValidationSchema;
