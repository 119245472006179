import { memo, ReactNode } from "react";
import styled from "styled-components";

import JeromeImgSmall from "assets/images/binipoolConcept/Jerome_Gander.png";
import JeromeImgMedium from "assets/images/binipoolConcept/Jerome_Gander@2x.png";
import MaurizioImgSmall from "assets/images/binipoolConcept/Maurizio_Mariscoli.png";
import MaurizioImgMedium from "assets/images/binipoolConcept/Maurizio_Mariscoli@2x.png";

import useTranslation from "hooks/useTranslation";

import { Container } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

interface IAmbasadorResponsiveImgProps {
	altDesc: string;
	imgSmall: string;
	imgMedium: string;
}

interface ITileProps {
	picture: ReactNode;
	title: string;
	description: string;
	isRowReverse?: boolean;
	localAmbassadorDesc: string;
	motto: string;
}

const Background = styled.div`
	width: 100%;
	background: rgba(255, 229, 210, 0.63);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const MainContent = styled.div`
	width: 80%;
	padding: 8.75rem 0;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	${media.desktop`
      width: 100%;
      flex-direction: column;
      padding: 4rem 0;
  `};
`;

const TileWrapper = styled.div<{ isRowReverse?: boolean }>`
	width: 100%;
	margin-bottom: ${props => !props.isRowReverse && "65px"};
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: ${props => (props.isRowReverse ? "row-reverse" : "row")};

	${media.desktop`
      flex-direction: column;
  `};
`;

const TileImgContent = styled.div`
	height: auto;
	min-width: 425px;

	${media.tablet`
     min-width: 100%;
  `};
`;

const TileTextContent = styled.div<{ isRowReverse?: boolean }>`
	height: auto;
	margin-right: ${props => (props.isRowReverse ? "5rem" : "0")};
	margin-left: ${props => (props.isRowReverse ? "0" : "5rem")};
	text-align: ${props => (props.isRowReverse ? "right" : "left")};

	${media.desktop`
      margin: 3.125rem 0 0 0;
      text-align: center;
  `};
`;

const TileTitle = styled.h2`
	margin: 0 0 0.313rem 0;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.44;
	letter-spacing: 0.04px;
`;

const TileDesc = styled.div`
	margin: 0 0 1.25rem 0;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.93;
	letter-spacing: 0.03px;
`;

const TileLocalAmbassadorDesc = styled(TileDesc)`
	margin: 0 0 1.25rem 0;
	font-size: ${dimensions.fontSize.medium};
`;

const TileLocalAmbassadorMotto = styled(TileDesc)`
	margin: 0;
	font-weight: 600;
	font-style: italic;
`;

const AmbasadorResponsiveImg = ({ altDesc, imgSmall, imgMedium }: IAmbasadorResponsiveImgProps) => (
	<img srcSet={`${imgSmall} 1x, ${imgMedium} 1.5x`} src={imgMedium} title={altDesc} alt={altDesc} width="100%" />
);

const Tile = memo(({ picture, title, description, isRowReverse, localAmbassadorDesc, motto }: ITileProps) => (
	<TileWrapper isRowReverse={isRowReverse}>
		<TileImgContent>{picture}</TileImgContent>

		<TileTextContent isRowReverse={isRowReverse}>
			<TileTitle>{title}</TileTitle>
			<TileLocalAmbassadorDesc>{localAmbassadorDesc}</TileLocalAmbassadorDesc>
			<TileDesc>{description}</TileDesc>
			<TileLocalAmbassadorMotto>{motto}</TileLocalAmbassadorMotto>
		</TileTextContent>
	</TileWrapper>
));

const Ambassadors = memo(() => {
	const { t } = useTranslation();

	return (
		<WrapperSection>
			<Background>
				<Container>
					<MainContent>
						<Tile
							picture={
								<AmbasadorResponsiveImg
									altDesc="ambassador - Maurizio Mariscoli"
									imgSmall={MaurizioImgSmall}
									imgMedium={MaurizioImgMedium}
								/>
							}
							title="Maurizio Mariscoli"
							description={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_PARIS_DESC")}
							localAmbassadorDesc={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_PARIS")}
							motto={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_PARIS_MOTTO")}
						/>

						<Tile
							isRowReverse
							picture={
								<AmbasadorResponsiveImg
									altDesc="ambassador - Jerome Gander"
									imgSmall={JeromeImgSmall}
									imgMedium={JeromeImgMedium}
								/>
							}
							title="Jerome Gander"
							description={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_ROTTERDAM_DESC")}
							localAmbassadorDesc={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_ROTTERDAM")}
							motto={t("BINIPOOL_CONCEPT.LOCAL_AMBASSADOR_ROTTERDAM_MOTTO")}
						/>
					</MainContent>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default Ambassadors;
