import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom";

import CurrentPartner from "classes/partner/session/CurrentPartner";
import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { IPartner } from "data/backoffice/partners/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import { paths } from "routing/paths";

import partnersService from "services/api/partners";

import ApiError from "utils/errors/ApiError";

import CircularLoader from "ui/loaders/CircularLoader";

const PartnersRedirectPage = () => {
	const history = useHistory();
	const routeMatch = useRouteMatch();
	const { subdomain } = useParams<{ subdomain: string }>();

	const { handleAndNotify } = useErrors();
	const { addError } = useNotification();

	const [partnerItem, setPartnerItem] = useState<IPartner>();
	const [foundPartner, setFoundPartner] = useState<CurrentPartner>();

	useEffect(() => {
		partnerStorage.clear();

		if (partnerItem) {
			setFoundPartner(
				partnerStorage.store({
					id: partnerItem.id,
					name: partnerItem.name,
					logo: partnerItem.logo,
				}),
			);
		}
	}, [partnerItem]);

	useEffect(() => {
		(async () => {
			try {
				setPartnerItem(await partnersService.getBySubdomain(subdomain));
			} catch (error) {
				if (error instanceof ApiError && error.isNotFound()) {
					addError("PARTNERS.PARTNER_NOT_FOUND", true);
				} else {
					handleAndNotify(error);
				}

				history.push(paths.HOMEPAGE);
			}
		})();
		// eslint-disable-next-line
	}, []);

	const redirectTo =
		`${history.location.pathname?.replace(routeMatch.url, "") || ""}${history.location.search || ""}` || paths.HOMEPAGE;

	if (foundPartner) {
		return <Redirect to={redirectTo} />;
	}

	return <CircularLoader />;
};

export default PartnersRedirectPage;
