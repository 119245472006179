import { Avatar, Box, Divider } from "@material-ui/core";
import { CalendarToday, LanguageOutlined, PermIdentity, QueryBuilder } from "@material-ui/icons";
import { addMinutes, format, isValid, parse } from "date-fns";
import styled, { css } from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { IGeneratePriceSummaryResultData } from "data/pricing/types";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectExperienceTraveller } from "store/selectors/experienceTraveller";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const Wrapper = styled.div`
	width: 397px;
	background: ${colors.white};
	box-shadow: 0 0 40px 0 #cecece;
	border-radius: 4px;

	${media.tablet`
    width: 100%
  `};
`;

const MainPhoto = styled.div<{ path: string }>`
	background-image: url(${props => props.path});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 200px;
	width: 100%;
	border-radius: 4px;
`;

const AvatarContent = styled(Avatar)`
	margin: -40px 0 0 20px;
	border: 3px solid ${colors.white};
	height: 84px;
	width: 84px;
`;

const DetailsContent = styled.div`
	width: 100%;
	padding: 20px;
`;

const MainTitle = styled.h1`
	margin: 14px 0;
	width: 80%;
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};
`;

const DividerOrange = styled(Divider)`
	border: 0.7px solid ${colors.sunShade};
`;

const Text = styled.span`
	font-family: ${RobotoFontFamily};
	margin-left: 16px;
	font-size: 14px;
	color: ${colors.mainFontColor};
`;

const TextBold = styled(Text)`
	margin-left: 0;
	font-weight: bold;
`;

const TotalPriceLabel = styled.span`
	margin-top: 20px;
	font-size: ${dimensions.fontSize.xlarge};
	font-weight: 500;
	line-height: 2;
	color: ${colors.mainFontColor};
`;

const PriceLabel = styled.span`
	font-size: ${dimensions.fontSize.xxhudge};
	font-weight: bold;
	line-height: 1.33;
	color: ${colors.mainFontColor};
`;

const MyLocalhost = styled.div`
	font-size: ${dimensions.fontSize.medium};
	font-weight: bold;
	line-height: 1.5;
	color: ${colors.secondaryGray};
	text-transform: uppercase;
`;

const MyLocalhostName = styled(MyLocalhost)`
	font-size: ${dimensions.fontSize.large};
`;

const IconStyle = css`
	width: 20px;
	height: 20px;
	color: ${colors.hokiColor};
`;

const StyledPermIdentity = styled(PermIdentity)`
	${IconStyle}
`;

const StyledCalendarToday = styled(CalendarToday)`
	${IconStyle};
	width: 18px;
`;

const StyledQueryBuilder = styled(QueryBuilder)`
	${IconStyle}
`;

const StyledLanguageOutlined = styled(LanguageOutlined)`
	${IconStyle}
`;

interface IBookingDetailsBoxProps {
	date: string[];
	hour: string[];
	language: string;
	adults: number;
	kids: number;
	pricing: Partial<IGeneratePriceSummaryResultData>;
	onlyBookingDetails?: boolean;
	showVat?: boolean;
	vatRate?: number;
}

const BookingInfo = ({
	date,
	hour,
	language,
	adults,
	kids,
	pricing,
	onlyBookingDetails,
	showVat,
	vatRate,
}: IBookingDetailsBoxProps) => {
	const { t } = useTranslation();

	const { findNameByKey } = useLanguages();

	const experience = useAppSelector(selectExperienceTraveller);

	if (!experience) {
		return null;
	}

	const hourFirstElem = hour[0];

	const tempEndTime = addMinutes(
		parse(hourFirstElem, DATE_FORMATS.TIME_FORMAT, new Date()),
		experience.data?.duration_minutes,
	);

	let endTime = "";

	if (isValid(tempEndTime)) {
		endTime = format(tempEndTime, DATE_FORMATS.TIME_FORMAT);
	}

	const BookingDetails = (
		<>
			<Box display="flex" alignItems="center" marginBottom="20px">
				<StyledPermIdentity />

				<Text>
					<TextBold>{adults}</TextBold>{" "}
					{adults === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULT") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")},{" "}
					<TextBold>{kids}</TextBold>{" "}
					{kids === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.KID") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}
				</Text>
			</Box>

			<Box display="flex" alignItems="center" marginBottom="20px">
				<StyledCalendarToday />

				<Text>{parseISOWrapper(date[0], DATE_FORMATS.DAY_MONTH_SHORT_YEAR_SHORT_FORMAT)}</Text>
			</Box>

			<Box display="flex" alignItems="center" marginBottom="20px">
				<StyledQueryBuilder />

				<Text>
					{hourFirstElem} - {endTime}
				</Text>
			</Box>

			<Box display="flex" alignItems="center" marginBottom="20px">
				<StyledLanguageOutlined />

				<Text>{findNameByKey(language)}</Text>
			</Box>
		</>
	);

	if (onlyBookingDetails) {
		return BookingDetails;
	}

	return (
		<Wrapper>
			<MainPhoto path={experience.data?.photo_main || CoverImg} />

			<Box display="flex">
				<AvatarContent src={experience.data?.localhost?.photo_url || ""} alt="Avatar image" />

				<Box marginLeft="13px" marginTop="10px">
					<MyLocalhost>{t("BOOKING_INFO.WITH_YOUR_LOCALHOST")}</MyLocalhost>

					<MyLocalhostName>{experience.data?.localhost?.first_name || ""}</MyLocalhostName>
				</Box>
			</Box>

			<DetailsContent>
				<MainTitle>{experience.data?.title}</MainTitle>

				<DividerOrange />

				<Box marginTop="20px" marginBottom="20px">
					{BookingDetails}

					<DividerOrange />

					<Box display="flex" flexDirection="column">
						<TotalPriceLabel>{t("BOOKING_INFO.TOTAL_PRICE")}</TotalPriceLabel>

						<Box display="flex" alignItems="center" gridGap="10px">
							<PriceLabel>{prices(pricing.final_gross_price, pricing.currency, undefined, undefined, true)}</PriceLabel>

							{showVat && typeof pricing?.tax?.vat === "number" && (
								<Box color={colors.bermudaGray}>
									incl. {vatRate}% VAT ({prices(pricing.tax.vat, pricing.currency)})
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</DetailsContent>
		</Wrapper>
	);
};

export default BookingInfo;
