import { Box, Button, createStyles, Popover, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ReactNode, useState } from "react";

import { CloseIconBtn } from "styles/common";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContent: {
			backgroundColor: theme.palette.grey[100],
		},
		orangeLeftBorder: {
			height: "inherit",
			minWidth: "8px",
			backgroundColor: theme.palette.primary.main,
			borderTopLeftRadius: "inherit",
			borderBottomLeftRadius: "inherit",
		},
		openBtn: {
			minWidth: "24px !important",
			"&:hover": {
				background: "none",
			},
		},
	}),
);

interface ITooltipProps {
	children: ReactNode;
	className?: string;
}

export const Tooltip = ({ children, className }: ITooltipProps) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box ml={5} display="flex" className={className}>
			<Button className={classes.openBtn} onClick={handleOpen}>
				<HelpOutlineIcon color="secondary" />
			</Button>

			<Popover
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Box display="flex" className={classes.mainContent}>
					<div className={classes.orangeLeftBorder} />
					<CloseIconBtn onClick={handleClose}>
						<CloseIcon />
					</CloseIconBtn>

					<Box paddingTop={25} paddingRight={15} paddingBottom={15} paddingLeft={5}>
						{children}
					</Box>
				</Box>
			</Popover>
		</Box>
	);
};
