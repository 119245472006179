import {
	EPrivateConfigEntity,
	EPrivateConfigField,
	EPrivateConfigValueStatus,
	IConfigsAll,
	IConfigsCityStore,
	IConfigsCityUpdate,
	IConfigsCountry,
	IPrivateConfig,
	TConfigsCityIId,
	TConfigsCountryIId,
} from "data/configs/types";

import API, { APISingleData } from "./index";
import { generateEntityEndpoint } from "./utils/createCRUDAPI";

const internalNamespace = "internal";
const apiName = "/configs";

const configsServices = {
	getAllConfig: () => APISingleData.get<IConfigsAll>(internalNamespace, apiName),

	getCountries: (): Promise<TConfigsCountryIId[]> =>
		API.get<TConfigsCountryIId[]>(internalNamespace, generateEntityEndpoint(apiName, "countries")),
	patchPartialUpdateCountry: (id: string, body: Partial<IConfigsCountry>) =>
		API.patch(internalNamespace, generateEntityEndpoint(apiName, "countries", id), { body }),
	postCreateCountry: (body: Omit<IConfigsCountry, "iso_code">) =>
		API.post(internalNamespace, generateEntityEndpoint(apiName, "countries"), { body }),

	getCities: (): Promise<TConfigsCityIId[]> =>
		API.get<TConfigsCityIId[]>(internalNamespace, generateEntityEndpoint(apiName, "cities")),
	patchPartialUpdateCity: (id: string, body: IConfigsCityUpdate) =>
		API.patch(internalNamespace, generateEntityEndpoint(apiName, "cities", id), { body }),
	postCreateCity: (body: IConfigsCityStore) =>
		API.post(internalNamespace, generateEntityEndpoint(apiName, "cities"), { body }),

	getPrivateConfig: () => API.get<IPrivateConfig[]>(internalNamespace, `${apiName}/private`),
	patchUpdatePrivateConfig: (
		entity: EPrivateConfigEntity,
		field: EPrivateConfigField,
		body: { value: string; status: EPrivateConfigValueStatus; score?: number; color?: string },
	) => API.patch<IPrivateConfig>(internalNamespace, `${apiName}/private/${entity}/${field}`, { body }),
};

export default configsServices;
