import { Box, CircularProgress, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";

import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import useLanguages from "store/hooks/useLanguages";

import ImageEditor from "ui/ImageEditDialog";
import StepBar from "ui/StepBar";

import { stepsLength } from "../../config";
import {
	InputsContainer,
	MainContainer,
	MainContent,
	StepContainer,
	StyledAutocomplete,
	StyledButton,
	TitleHeader,
} from "../../shared.styled";
import {
	AvatarContainer,
	AvatarImage,
	Desc,
	DropzoneContainer,
	DropzoneInside,
	DropzoneWrapper,
	FieldContainer,
	StyledIcon,
} from "../Bio/shared.styled";
import { BioProps } from "./index";

const Layout = ({
	inputs,
	userName,
	cities,
	handleOnFilesAdded,
	handleLanguagesChange,
	handleCitiesChange,
	handleSendButtonClick,
	isSendButtonDisabled,
	validateField,
}: BioProps) => {
	const { t, withValues } = useTranslation();

	const { languagesSelectSorted, findNameByKey } = useLanguages();

	const [openAvatarDialog, setOpenAvatarDialog] = useState<boolean>(false);

	const avatarError = !inputs.avatar_url ? !!inputs.errors.avatar_url : false;

	return (
		<MainContainer>
			{openAvatarDialog && (
				<ImageEditor
					initialImage={inputs.avatar_url}
					showDialog
					handleClose={() => {
						validateField("avatar_url");

						setOpenAvatarDialog(prevState => !prevState);
					}}
					saveImage={handleOnFilesAdded}
				/>
			)}

			<MainContent>
				<TitleHeader>{withValues("ONBOARDING.HEADER_WITH_NAME", { name: userName })}</TitleHeader>

				<StepContainer>
					<StepBar activeStep={EOnboardingSteps.ShortBio} stepsAmount={stepsLength} />
				</StepContainer>

				<AvatarContainer>
					<DropzoneWrapper>
						{inputs.avatar_url ? (
							<AvatarImage src={inputs.avatar_url} onClick={() => setOpenAvatarDialog(prevState => !prevState)} />
						) : (
							<DropzoneContainer error={avatarError}>
								<DropzoneInside onClick={() => setOpenAvatarDialog(prevState => !prevState)}>
									<StyledIcon fontSize="large" />
								</DropzoneInside>
							</DropzoneContainer>
						)}
					</DropzoneWrapper>

					<Desc error={avatarError}>
						{t("ONBOARDING.BIO.UPLOAD_IMAGE")}
						<br />
						<small>{t("ONBOARDING.BIO.UPLOAD_IMAGE_SMALL")}</small>
					</Desc>
				</AvatarContainer>

				<InputsContainer>
					<FieldContainer>
						<StyledAutocomplete
							id="bio_autocomplete_languages_list"
							multiple
							autoHighlight
							value={inputs.languages.map(elem => ({ value: elem, label: findNameByKey(elem) }))}
							options={languagesSelectSorted}
							getOptionLabel={(option: { label: string }) => option.label}
							getOptionSelected={(
								option: { label: string; value: string },
								selected: { label: string; value: string },
							) => option.value === selected.value}
							filterSelectedOptions
							onChange={handleLanguagesChange}
							renderInput={params => (
								<TextField
									{...params}
									name="language_skills"
									label={t("ONBOARDING.BIO.INPUT.LANGUAGE.LABEL")}
									placeholder={t("ONBOARDING.BIO.INPUT.LANGUAGE.PLACEHOLDER")}
									variant="outlined"
									onBlur={() => validateField("languages")}
									error={inputs.errors.languages}
									helperText={inputs.errors.languages && t("ERRORS.REQUIRED")}
								/>
							)}
						/>
					</FieldContainer>

					<FieldContainer>
						<Box textAlign="center" marginTop="-14px">
							<Desc>{t("ONBOARDING.CITIES.HEADER")}</Desc>
						</Box>

						{!!cities?.length ? (
							<StyledAutocomplete
								id="bio_autocomplete_cities_list"
								multiple
								autoHighlight
								value={inputs.cities.map(elem => ({ value: elem, label: elem }))}
								options={cities}
								getOptionLabel={(option: { label: string }) => option.label}
								getOptionSelected={(
									option: { label: string; value: string },
									selected: { label: string; value: string },
								) => option.value === selected.value}
								filterSelectedOptions
								onChange={handleCitiesChange}
								renderInput={params => (
									<TextField
										{...params}
										id="cities_list_input"
										name="cities"
										label={t("ONBOARDING.CITIES.INPUT_LABEL")}
										placeholder={t("ONBOARDING.CITIES.INPUT_PLACEHOLDER")}
										variant="outlined"
										onBlur={() => validateField("cities")}
										error={inputs.errors.cities}
										helperText={inputs.errors.cities && t("ERRORS.REQUIRED")}
									/>
								)}
								popupIcon={<SearchIcon />}
							/>
						) : (
							<Box height={50} display="flex" justifyContent="center" alignItems="center">
								<CircularProgress color="secondary" />
							</Box>
						)}
					</FieldContainer>
				</InputsContainer>

				<StyledButton
					id="bio_button_send_for_review"
					onClick={handleSendButtonClick}
					disabled={isSendButtonDisabled()}
					variant="contained"
					color="primary"
				>
					{t("ONBOARDING.NEXT_BUTTON")}
				</StyledButton>
			</MainContent>
		</MainContainer>
	);
};

export default Layout;
