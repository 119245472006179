import { Link, TextField } from "@material-ui/core";
import styled from "styled-components";

import { mobileWidth, spaceDefault, StepContainer } from "pages/onboarding/shared.styled";

export const InputsContainer = styled(StepContainer)`
	display: flex;
	flex-direction: column;
	padding: 0 ${spaceDefault + spaceDefault / 2}px ${spaceDefault + spaceDefault / 2}px;

	@media screen and (max-width: ${mobileWidth}) {
		padding: 0 10px 13px;
	}
`;

export const MarginCenter = styled.div`
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	gap: 20px;
`;

export const InputField = styled(TextField)`
	max-width: 60px;

	input {
		height: 30px;
		text-align: center;
	}
`;

export const StyledLink = styled(Link)`
	cursor: pointer;
`;
