import { Box } from "@material-ui/core";
import { ReactElement } from "react";
import styled from "styled-components";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { SubTitle, Text, TextBold } from "../index";

export const Row = styled(Box)`
	margin-bottom: 10px;
	display: flex;
`;

interface Props {
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	phoneInputComponent?: ReactElement;
}

const PersonalDetails = ({ firstname, lastname, email, phone, phoneInputComponent }: Props) => {
	const { t } = useTranslation();

	const userData = useAppSelector(selectUser);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	return (
		<>
			<Box marginTop="40px" marginBottom="18px">
				<SubTitle>
					{t(partnerType ? "BOOKING_SUMMARY.BOOKING_AS_PARTNER_SUBTITLE" : "BOOKING.SUMMARY.PERSONAL_DETAILS.SUBTITLE")}
				</SubTitle>
			</Box>

			<Row>
				<Box flex={0.7}>
					<Text>{t("BOOKING_SUMMARY.ROW_NAME")}</Text>
				</Box>

				<Box flex={1}>
					<TextBold>{firstname}</TextBold>
				</Box>
			</Row>

			<Row>
				<Box flex={0.7}>
					<Text>{t("BOOKING_SUMMARY.ROW_LAST_NAME")}</Text>
				</Box>

				<Box flex={1}>
					<TextBold>{lastname}</TextBold>
				</Box>
			</Row>

			<Row>
				<Box flex={0.7}>
					<Text>{t("BOOKING_SUMMARY.ROW_EMAIL")}</Text>
				</Box>

				<Box flex={1}>
					<TextBold lowercase>{email}</TextBold>
				</Box>
			</Row>

			<Row>
				<Box flex={0.7}>
					<Text>
						{t("BOOKING_SUMMARY.ROW_PHONE")}
						{!phone && " *"}
					</Text>
				</Box>

				<Box flex={1}>{!!phone ? <TextBold>{phone}</TextBold> : phoneInputComponent}</Box>
			</Row>
		</>
	);
};

export default PersonalDetails;
