import { Box, Button, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";

import BackgroundImg from "assets/images/background/home_page_image.jpg";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { Container, MontserratFontFamily, StyledLink } from "styles/common";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			[theme.breakpoints.down("sm")]: {
				fontSize: 18,
			},
			fontFamily: MontserratFontFamily,
			color: theme.palette.common.white,
			fontWeight: "bold",
			fontSize: 50,
			lineHeight: 1,
			letterSpacing: "0.1px",
			flexShrink: 0,
		},
		startBtn: {
			[theme.breakpoints.down("sm")]: {
				minWidth: 140,
				height: 40,
			},
			minWidth: 207,
			height: 48,
		},
	}),
);

const Advertisement = memo(() => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Container>
			<Box
				display="flex"
				alignItems="flex-start"
				justifyContent="center"
				flexDirection="column"
				mb={25}
				style={{
					backgroundImage: `linear-gradient(to right, rgba(1,1,1, 0.5), rgba(1,1,1, 0.2)), url(${BackgroundImg})`,
					position: "relative",
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					width: "100%",
					height: isSmallScreen ? "240px" : "670px",
					borderRadius: "4px",
				}}
			>
				<Box ml={isSmallScreen ? 15 : 55} width={isSmallScreen ? "70%" : "40%"}>
					<Typography className={classes.title} variant="h2">
						{t("HP.ADVERTISEMENT.TITLE")}
					</Typography>

					<Box mt={17}>
						<StyledLink $withoutHoverUnderline to={paths.BINIPOOL_CONCEPT}>
							<Button className={classes.startBtn} color="primary" variant="contained">
								{t("HP.ADVERTISEMENT.START_BTN")}
							</Button>
						</StyledLink>
					</Box>
				</Box>
			</Box>
		</Container>
	);
});

export default Advertisement;
