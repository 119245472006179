import * as Yup from "yup";

import { EPartnerConnectionType, EPartnerType } from "data/notifications/types";

import useTranslation from "hooks/useTranslation";

const useValidationSchema = () => {
	const { t, withValuesAsString } = useTranslation();

	const required = t("ERRORS.REQUIRED");
	const tooShort2 = withValuesAsString("ERRORS.TOO_SHORT_WITH_VALUE", { min: 2 });
	const tooShort5 = withValuesAsString("ERRORS.TOO_SHORT_WITH_VALUE", { min: 5 });
	const tooLong15 = withValuesAsString("ERRORS.TOO_LONG_SEQUENCE", { max: 15 });
	const invalidEmail = t("ERRORS.INVALID_EMAIL");

	return Yup.object().shape({
		company: Yup.string().min(2, tooShort2).required(required),
		description: Yup.string().min(2, tooShort2).required(required),
		first_name: Yup.string().min(2, tooShort2).required(required),
		last_name: Yup.string().min(2, tooShort2).required(required),
		from: Yup.string().email(invalidEmail).required(required),
		partner_connection_type: Yup.string().oneOf(Object.keys(EPartnerConnectionType)).required(required),
		partner_type: Yup.string().oneOf(Object.keys(EPartnerType)).required(required),
		phone: Yup.string().min(5, tooShort5).max(15, tooLong15).required(required),
		website: Yup.string().min(2, tooShort2).required(required),
	});
};

export default useValidationSchema;
