import { Avatar, Box, Button } from "@material-ui/core";
import { ReactElement } from "react";
import slugify from "slugify";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";
import fillRoute from "utils/routes";

import colors from "styles/colors";
import { RobotoFontFamily, StyledLink } from "styles/common";
import media from "styles/media";

import { Text } from "../shared.styled";

const Wrapper = styled.div`
	width: 100%;
	height: auto;
	padding: 15px 0;
	display: flex;
	align-items: center;
	border-bottom: solid 1px ${colors.borderGray};

	${media.tablet`
    flex-wrap: wrap;
    height: 100%;
  `};
`;

const StatusLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.67;
`;

const Title = styled.h1`
	margin: 0;
	min-width: 300px;
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	font-weight: bold;
	line-height: 1.2;
	letter-spacing: 0.56px;
	color: ${colors.mainFontColor};

	${media.tablet`
    min-width: initial;
  `};
`;

const StyledAvatar = styled(Avatar)`
	margin: 0 auto;
	width: 49px;
	height: 49px;
`;

const StyledText = styled(Text)<{ isMarginRight?: boolean; isMarginLeft?: boolean; bold?: boolean }>`
	${props => props.isMarginRight && "margin-right: 3px;"}
	${props => props.isMarginLeft && "margin-left: 3px;"}
  ${props => props.bold && "font-weight: bold;"}

  color: ${colors.secondaryGray};
`;

const BtnsContent = styled(Box)`
	flex: 1;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	flex-direction: column;
`;

const SeeDetailsButton = styled(Button)`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 300;
	line-height: 1.2;
	color: ${colors.secondaryGray};
`;

const AvatarWrapper = styled.div`
	margin-right: 10px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledFlexLink = styled(StyledLink)`
	display: inline-flex;
`;

interface IDashboardItemProps {
	title: string;
	expId: string;
	localhostName: string;
	avatarUrl?: string;
	date: string;
	leaveOnClick?: (v: boolean) => void;
	seeDetailsOnClick: (v: boolean) => void;
	status: ReactElement;
	localhostId?: string | null;
}

const DashboardItemList = ({
	title,
	expId,
	localhostName,
	avatarUrl,
	date,
	leaveOnClick,
	seeDetailsOnClick,
	status,
	localhostId,
}: IDashboardItemProps) => {
	const { t } = useTranslation("ui");

	const leaveBinipoolAction = () => {
		if (leaveOnClick) {
			leaveOnClick(true);
		}
	};

	const seeDetailsAction = () => {
		seeDetailsOnClick(true);
	};

	const LocalHostLinkHoc = ({ children }) => {
		if (localhostId) {
			return (
				<StyledFlexLink
					to={fillRoute(paths.PUBLIC_PROFILE, {
						userName: encodeURI(localhostName.toLowerCase()),
						uid: localhostId,
					})}
				>
					{children}
				</StyledFlexLink>
			);
		} else {
			return children;
		}
	};

	return (
		<Wrapper>
			<AvatarWrapper>
				<LocalHostLinkHoc>
					<StyledAvatar alt={`Avatar localhost - ${localhostName}`} src={avatarUrl} />
				</LocalHostLinkHoc>
			</AvatarWrapper>

			<Box flex={3}>
				<StatusLabel>
					Status: <strong>{status}</strong>
				</StatusLabel>

				<Title>
					<StyledLink
						to={fillRoute(paths.SEARCH_EXPERIENCES, {
							title: slugify(title.toLowerCase() || ""),
							id: expId,
						})}
					>
						{title}
					</StyledLink>
				</Title>

				<Box display="flex" marginTop="5px">
					<LocalHostLinkHoc>
						<StyledText>{localhostName}</StyledText>
					</LocalHostLinkHoc>

					<StyledText isMarginLeft isMarginRight>
						|
					</StyledText>

					<StyledText bold>{parseISOWrapper(date, DATE_FORMATS.DATE_FORMAT6)}</StyledText>

					<StyledText isMarginLeft isMarginRight>
						|
					</StyledText>

					<StyledText bold>{parseISOWrapper(date, DATE_FORMATS.TIME_FORMAT)}</StyledText>
				</Box>
			</Box>

			<BtnsContent>
				<SeeDetailsButton onClick={seeDetailsAction}>{t("DASHBOARD_ITEM_SEE_DETAILS_BTN")}</SeeDetailsButton>

				{leaveOnClick && (
					<Button color="primary" variant="contained" onClick={leaveBinipoolAction}>
						{t("DASHBOARD_ITEM_LEAVEBINIPOOL_BTN")}
					</Button>
				)}
			</BtnsContent>
		</Wrapper>
	);
};

export default DashboardItemList;
