import { Box, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

export interface IStringSwitchesProps {
	field: string;
	helperText?: string;
	onChange: (newValue) => void;
	value: string[];
}

const StringSwitches = ({ field, onChange, value }: IStringSwitchesProps) => {
	const { t } = useTranslation();
	const dictionary = getDictionary(field as any);

	const handleChangeSwitch = (element: string) => {
		if (value.indexOf(element) === -1) {
			onChange([...value, element]);
		} else {
			onChange(value.filter(v => v !== element));
		}
	};

	return (
		<Box>
			<List key="list">
				{dictionary.map((d, i) => (
					<ListItem key={i} divider style={{ padding: "10px 0" }}>
						<ListItemText primary={t(`EXPERIENCE.${field}.${d}`)} />

						<ListItemSecondaryAction>
							<Switch
								checked={value.indexOf(d) !== -1}
								color="primary"
								name={d}
								onChange={() => handleChangeSwitch(d)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default StringSwitches;
