import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiPickersUtilsProviderProps } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { getCurrentLocale } from "i18n";

const MuiDateProvider = ({ children, ...rest }: Omit<MuiPickersUtilsProviderProps, "utils" | "locale">) => {
	const locale = getCurrentLocale();

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale} {...rest}>
			<>{children}</>
		</MuiPickersUtilsProvider>
	);
};

export default MuiDateProvider;
