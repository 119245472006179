import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TNotificationsResponse } from "data/notifications/types";

import notificationsService from "services/api/notifications";

import { handleRejectValues } from "store/utils/handleRejectValues";

import { allowedRecipientTypes } from "pages/backoffice/notifications";

export interface IBackofficeNotificationsState {
	unreadBackofficeNotifications: TNotificationsResponse | null;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IBackofficeNotificationsState = {
	unreadBackofficeNotifications: null,
	status: "idle",
	error: null,
};

export const fetchUnreadBackofficeNotifications = handleRejectValues(
	"notificationsBackoffice/fetchUnreadNotifications",
	() =>
		notificationsService.getSearchNotifications({
			recipient_type: allowedRecipientTypes,
			marked_as_read: false,
		}),
);

const { actions, reducer } = createSlice({
	name: "notificationsBackoffice",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUnreadBackofficeNotifications.fulfilled, (state, action: PayloadAction<TNotificationsResponse>) => {
				state.unreadBackofficeNotifications = action.payload;
				state.status = "succeeded";
				state.error = null;
			})
			.addCase(fetchUnreadBackofficeNotifications.pending, state => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(fetchUnreadBackofficeNotifications.rejected, state => {
				state.unreadBackofficeNotifications = null;
				state.status = "failed";
				state.error = true;
			});
	},
});

export const notificationsBackofficeActions = actions;

export default reducer;
