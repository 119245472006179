import { Box } from "@material-ui/core";
import Popover, { PopoverOrigin } from "@material-ui/core/Popover";
import i18n, { appLanguages, getCurrentLocale } from "i18n";
import { Dispatch, SetStateAction, useEffect } from "react";
import styled from "styled-components";

import useErrors from "hooks/useErrors";

import useLanguages from "store/hooks/useLanguages";

import { localStorageKeys } from "utils/constants";

// TODO: create UI component from it
import { FlagAdornmentSpan } from "pages/onboarding/steps/PhoneVerification/styled";

const StyledPopover = styled(Popover)`
	.MuiPopover-paper {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	}
`;

const BoxWithCursor = styled(Box)`
	cursor: pointer;

	&:hover {
		background-color: #eceff1;
	}
`;

interface ILanguageSelectorModal {
	anchorElement: HTMLElement | null;
	setAnchorElement: Dispatch<SetStateAction<HTMLElement | null>>;
	anchorOrigin?: PopoverOrigin;
	transformOrigin?: PopoverOrigin;
}

const LanguageSelectorModal = ({
	anchorElement,
	setAnchorElement,
	anchorOrigin,
	transformOrigin,
}: ILanguageSelectorModal) => {
	const { handleAndNotify } = useErrors();

	const { findNameByKey } = useLanguages();

	useEffect(() => {
		const handleClosePopover = () => setAnchorElement(null);

		if (anchorElement) {
			document.addEventListener("scroll", handleClosePopover);
		}

		return () => document.removeEventListener("scroll", handleClosePopover);

		// eslint-disable-next-line
	}, [anchorElement]);

	const changeLanguage = async (code: string) => {
		try {
			await i18n.changeLanguage(code);

			localStorage.setItem(localStorageKeys.languages.selectedLanguage.key, code);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setAnchorElement(null);
		}
	};

	const currentLanguage = getCurrentLocale()?.code?.split("-")[0] || "en";

	return (
		<StyledPopover
			open={!!anchorElement}
			anchorEl={anchorElement}
			onClose={() => setAnchorElement(null)}
			disableScrollLock
			anchorOrigin={
				anchorOrigin || {
					vertical: "top",
					horizontal: "center",
				}
			}
			transformOrigin={
				transformOrigin || {
					vertical: 40, // change it when new languages will come!
					horizontal: "center",
				}
			}
		>
			<Box width="120px">
				{Object.keys(appLanguages)
					.filter(elem => appLanguages[elem].available && elem !== currentLanguage)
					.map((elem, index, array) => (
						<BoxWithCursor
							key={elem}
							paddingY="5px"
							paddingX="10px"
							borderBottom={index < array.length - 1 ? "1px solid #ECEFF1" : undefined}
							onClick={() => changeLanguage(elem)}
							display="flex"
							gridGap="8px"
							alignItems="center"
						>
							<FlagAdornmentSpan className={elem === "en" ? "gb" : elem} />

							<span>{findNameByKey(elem)}</span>
						</BoxWithCursor>
					))}
			</Box>
		</StyledPopover>
	);
};

export default LanguageSelectorModal;
