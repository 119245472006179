import { useEffect } from "react";

import { Notifications } from "classes/Notifications";

import useNotification from "hooks/useNotification";

export const NotificationsClassProvider = () => {
	const useNotificationHook = useNotification();

	useEffect(() => {
		Notifications.useNotification = useNotificationHook;

		// eslint-disable-next-line
	}, []);

	return null;
};
