import { Button, TextField } from "@material-ui/core";
import { Language, LocationOnOutlined, PublicOutlined } from "@material-ui/icons";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

import FormTitle from "./FormTitle";

export const StyledInput = styled(TextField)<{ fullWidth?: boolean }>`
	margin: ${props => (props.fullWidth ? "initial" : "0 20px")};
	width: ${props => (props.fullWidth ? "100%" : "500px")};

	input {
		padding: 7px;
	}
`;

export const BorderedButton = styled(Button)`
	background: none;
	color: ${colors.mainFontColor};
	border: 1px solid ${colors.mainFontColor};
	box-shadow: none;
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 1;

	&:hover {
		background-color: ${colors.lightGray};
	}

	.MuiButton-label {
		margin-bottom: -2px;
	}
`;

export const GreenButton = styled(BorderedButton)`
	background: ${colors.green};
	border: 1px solid ${colors.green};
	color: ${colors.white};

	&:hover {
		background-color: ${colors.lightGreen};
	}
`;

export const OrangeButton = styled(GreenButton)`
	background: ${colors.sunShade};
	border: 1px solid ${colors.sunShade};

	&:hover {
		border: 1px solid ${colors.lightSunShade};
		background-color: ${colors.lightSunShade};
	}
`;

export const RedButton = styled(OrangeButton)`
	background: ${colors.red};
	border: 1px solid ${colors.red};

	&:hover {
		border: 1px solid ${colors.red};
		background-color: ${colors.red};
	}
`;

export const WhiteButton = styled(GreenButton)`
	background: white;
	border: 1px solid white;
	color: black;

	&:hover {
		border: 1px solid ${colors.lightGray};
		background-color: ${colors.lightGray};
	}
`;

const outlinedIconCss = css`
	width: 18px;
	height: 22px;
	color: ${colors.hokiColor};
`;

export const LocationOnOutlinedIcon = styled(LocationOnOutlined)`
	${outlinedIconCss}
`;

export const GlobOutlinedIcon = styled(PublicOutlined)`
	${outlinedIconCss}
`;

export const LanguageIcon = styled(Language)`
	${outlinedIconCss}
`;

export const MainTitle = styled.h1<{ break?: boolean; border?: boolean }>`
	color: ${colors.mainFontColor};
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	font-family: ${RobotoFontFamily};
	margin: 0;
	display: inline-flex;
	line-height: 1;
	align-items: center;
	letter-spacing: 0.83px;
	width: 100%;
	padding: 0 0 5px;
	${props => props.break && "margin-bottom: 25px;"}
	${props => props.border && "border-bottom: 1px solid #e6e6e6;"}
`;

export const FormDescription = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: 0.4px;
	margin: 0 0 10px;
`;

export const PreviewValue = styled(FormTitle)`
	margin: 0;
	font-size: 14px;
`;

export const AvatarSection = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	margin: 0 10px;
`;

export const Avatar = styled.div<{ image: string }>`
	min-width: 40px;
	height: 40px;
	border-radius: 50%;
	background: ${colors.lightGray} url(${props => props.image}) no-repeat center center;
	background-size: cover;
`;

export const StyledModalHeader = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	text-align: center;
	color: ${colors.lightDark};
	margin-bottom: 20px;
	margin-top: 20px;
`;
