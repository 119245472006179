import Loader from "components/loaders/Loader";

import { ESteps, IRateAction } from "../types";
import RateExperience from "./steps/RateExperience";
import RateFinish from "./steps/RateFinish";
import RateLocalhost from "./steps/RateLocalhost";
import RateNPS from "./steps/RateNPS";

interface IRatingFactory extends IRateAction {
	step?: ESteps;
}

const RatingsFactory = ({ step, action, bookingData }: IRatingFactory) => {
	switch (step) {
		case ESteps.NPS:
			return <RateNPS action={action} bookingData={bookingData} />;

		case ESteps.EXPERIENCE:
			return <RateExperience action={action} bookingData={bookingData} />;

		case ESteps.LOCALHOST:
			return <RateLocalhost action={action} bookingData={bookingData} />;

		case ESteps.FINISH:
			return <RateFinish />;

		default:
			return <Loader withoutFlying />;
	}
};

export default RatingsFactory;
