import { Box, CircularProgress, Divider, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import BrainTreeDropIn from "braintree-web-drop-in";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import slugify from "slugify";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import financialsServices from "services/api/financials";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectBrainTreeToken, selectExperienceTraveller } from "store/selectors/experienceTraveller";
import { getBrainTreeToken } from "store/slices/experienceTraveller";

import AppWrapper from "components/layout/AppWrapper";

import BookingInfo from "ui/BookingInfo";
import ActionButton from "ui/buttons/ActionButton";

import { Container } from "styles/common";

import BreadCrumbsBooking from "../../booking/components/BreadCrumbsBooking";
import DetailsBox from "../../booking/components/DetailsBox";
import { MainTitle, StyledAlert } from "../../booking/shared.styled";

export interface IPaymentStep {
	bookingId: string;
	amount: number;
	bookingDetails: {
		date: string[];
		hour: string[];
		language: string;
		adults: number;
		kids: number;
		pricing: {
			final_gross_price: number;
		};
	};
	travellerDetails: {
		name: string | null | undefined;
		last_name: string | null | undefined;
		email: string | null | undefined;
	};
}

let brainTreeInstance:
	| undefined
	| { teardown(callback: () => void): void; requestPaymentMethod(params: any, callback: any): void };

const PaymentStep = ({ bookingId, amount, bookingDetails, travellerDetails }: IPaymentStep) => {
	const dispatch = useAppDispatch();

	const brainTreeToken = useAppSelector(selectBrainTreeToken);
	const experience = useAppSelector(selectExperienceTraveller);

	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

	const history = useHistory();
	const location = useLocation();
	const { handleAndNotify } = useErrors();

	const { t, withRaw } = useTranslation();

	const [loader, setLoader] = useState<boolean>(true);
	const [error, setError] = useState<boolean | string>(false);
	const [instanceCounter, setInstanceCounter] = useState<number>(new Date().getTime());

	const brainTreeInstanceInitializing = useRef<boolean>(false);

	const currency = experience?.data?.pricing?.currency;

	const brainTreeContainer = `brainTree-container-${instanceCounter}`;

	const extendedError = typeof error === "string";

	useEffect(() => {
		return () => {
			if (brainTreeInstance) {
				brainTreeInstance.teardown(() => {
					brainTreeInstance = undefined;
				});
			}
		};
	}, []);

	useEffect(() => {
		dispatch(getBrainTreeToken(currency));

		// eslint-disable-next-line
	}, [currency]);

	useEffect(() => {
		const initializeBraintree = () => {
			if (brainTreeToken) {
				if (brainTreeInstanceInitializing.current) {
					return;
				}

				brainTreeInstanceInitializing.current = true;

				setLoader(false);

				BrainTreeDropIn.create(
					{
						authorization: brainTreeToken,
						container: `#${brainTreeContainer}`,
						threeDSecure: true,
						// paypal: {
						// 	flow: "vault",
						// },
					},
					(_, instance) => {
						if (instance) {
							brainTreeInstance = instance;
						} else {
							brainTreeInstanceInitializing.current = false;

							setInstanceCounter(new Date().getTime());
						}
					},
				);
			} else if (brainTreeToken === undefined) {
				setLoader(false);
			}
		};

		if (!brainTreeInstanceInitializing.current) {
			if (brainTreeInstance) {
				brainTreeInstance.teardown(() => {
					brainTreeInstance = undefined;

					initializeBraintree();
				});
			} else {
				initializeBraintree();
			}
		}

		// eslint-disable-next-line
	}, [brainTreeToken, instanceCounter]);

	const handleErrors = (errorParam: any, simpleError: boolean) => {
		let errorMessage;

		try {
			// @ts-ignore
			errorMessage = errorParam?.getError()?.message;
		} catch {
			errorMessage = "";
		}

		setError(simpleError ? true : errorMessage || true);

		setLoader(false);

		brainTreeInstanceInitializing.current = false;

		setInstanceCounter(new Date().getTime());

		handleAndNotify(errorParam);
	};

	const payAction = useCallback(() => {
		setError(false);

		brainTreeInstance?.requestPaymentMethod(
			{
				threeDSecure: {
					amount,
					...(travellerDetails.email && { email: travellerDetails.email }),
					...((travellerDetails.name || travellerDetails.last_name) && {
						billingAddress: {
							...(travellerDetails.name && { givenName: slugify(travellerDetails.name) }),
							...(travellerDetails.last_name && { surname: slugify(travellerDetails.last_name) }),
						},
					}),
					collectDeviceData: true,
				},
			},
			(err, payload) => {
				(async () => {
					if (!!payload?.nonce) {
						setLoader(true);

						try {
							if (payload?.threeDSecureInfo?.status === "lookup_enrolled") {
								throw new Error(t("BOOKING.CHECKOUT.VERIFICATION_CANCELLED"));
							}
						} catch (e) {
							return handleErrors(e, true);
						}

						try {
							const financialsConfirmation = await financialsServices.postBrainTreeCheckout({
								booking_id: bookingId,
								payment_method_nonce: payload.nonce,
								device_data: navigator.userAgent,
							});

							if (financialsConfirmation) {
								const confirmed = location.search ? `${location.search}&confirmed=true` : "?confirmed=true";
								history.push(`${location.pathname}${confirmed}`);
							}
						} catch (e) {
							return handleErrors(e, false);
						}
					}
				})();
			},
		);

		// eslint-disable-next-line
	}, [amount, bookingId]);

	return (
		<AppWrapper>
			<Container>
				<BreadCrumbsBooking lastBreadcrumb={t("BOOKING_SUMMARY.PAYMENT_METHOD")} />

				<MainTitle>{t("BOOKING_SUMMARY.PAYMENT_METHOD")}</MainTitle>

				<Box display="flex" alignItems="flex-start" justifyContent="space-between" marginTop="30px" marginBottom="60px">
					<Box flex={2} paddingRight={!isMediumScreen ? "50px" : undefined}>
						{loader && (
							<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
								<CircularProgress color="primary" size={40} value={100} />
							</Box>
						)}

						{brainTreeToken === undefined && (
							<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
								<Alert severity="error">{t("BOOKING.CHECKOUT.PAYMENT_APPLET_ERROR")}</Alert>
							</Box>
						)}

						{error && (
							<Box marginBottom="25px">
								<StyledAlert severity="warning">
									{withRaw("BOOKING.CHECKOUT.PAYMENT_ERROR", null)}

									{extendedError && (
										<div>
											{t("BOOKING.CHECKOUT.ERROR_DETAILS")}&nbsp;<strong>{error}</strong>
										</div>
									)}
								</StyledAlert>
							</Box>
						)}

						{!loader && <Box key={brainTreeContainer} id={brainTreeContainer} minHeight="200px" marginBottom="40px" />}

						<Divider />

						<Box display="flex" alignItems="center" justifyContent="flex-end" flex={1} marginTop="10px">
							<ActionButton
								translationDefault={"BOOKING.SUMMARY.NEXT_STEP"}
								isDisabled={!brainTreeToken || loader}
								onClick={payAction}
							/>
						</Box>
					</Box>

					<Hidden mdDown>
						<BookingInfo {...bookingDetails} pricing={{ ...bookingDetails.pricing, currency }} />
					</Hidden>

					<Hidden lgUp>
						<DetailsBox hideButton {...bookingDetails} pricing={{ ...bookingDetails.pricing, currency }} />
					</Hidden>
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default PaymentStep;
