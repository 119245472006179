import { ReactNode } from "react";

import { Option } from "ui/forms/Select";

import { omit } from "./objects";

const existsInEnum = <T>(value: T, enumObject: { [key: string]: T }) => Object.values(enumObject).includes(value);

const createSelectFromEnum = (
	list: Record<string, string>,
	translationCallback?: (key: string | number) => ReactNode,
): Option[] =>
	Object.keys(list).reduce(
		(acc, enumKey) => [
			...acc,
			{
				label: translationCallback ? translationCallback(list[enumKey]) : list[enumKey],
				value: enumKey,
			},
		],
		[],
	);

const omitInEnum = <T extends Record<string, string>, K extends Extract<keyof T, string>>(
	list: T,
	omitted: K[],
): Omit<T, K> => omit(list, omitted);

export { existsInEnum, createSelectFromEnum, omitInEnum };
