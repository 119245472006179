import { Dialog as MaterialDialog, useMediaQuery, useTheme } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import styled from "styled-components";

import Decorator from "./components/Decorator";
import { TDialogDecorators } from "./types";

interface IDialogProps extends Omit<DialogProps, "open"> {
	showDialog: boolean;
	handleClose: (...props: any) => void;
	minHeight?: string;
	maxHeight?: string;
	decorator?: TDialogDecorators;
}

const Wrapper = styled.div<{ minHeight?: string; maxHeight?: string }>`
	min-width: 350px;
	min-height: ${props => (props.minHeight ? props.minHeight : "300px")};
	${props => props.maxHeight && `max-height: ${props.maxHeight};`}
`;

const Dialog = ({ children, handleClose, showDialog, minHeight, maxHeight, decorator, ...rest }: IDialogProps) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<MaterialDialog
			fullScreen={isSmallScreen}
			open={showDialog}
			onClose={handleClose}
			aria-labelledby="responsive-dialog"
			{...rest}
		>
			<Decorator decorator={decorator} handleClose={handleClose}>
				<Wrapper minHeight={minHeight} maxHeight={maxHeight}>
					{children}
				</Wrapper>
			</Decorator>
		</MaterialDialog>
	);
};

export default Dialog;
