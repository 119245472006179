import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";
import styled, { css } from "styled-components";

import ContactUsImgSmall from "assets/images/uniqueExperiences/contact-us.png";
import ContactUsImgMedium from "assets/images/uniqueExperiences/contact-us@2x.png";
import ContactUsImgLarge from "assets/images/uniqueExperiences/contact-us@3x.png";

import useTranslation from "hooks/useTranslation";

import ContactButton from "ui/buttons/ContactButton";
import CommonParagraph from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import colors from "styles/colors";
import { Container, MontserratFontFamily } from "styles/common";
import media from "styles/media";

export const Wrapper = styled.section`
	margin: 0 auto;
	width: 100%;
	font-family: ${MontserratFontFamily};
	color: ${colors.mainFontColor};

	${media.desktop`
     margin-bottom: 50px;
  `};
`;

const Background = styled.div`
	margin: 200px auto 100px auto;
	background-color: #d2edfe;
	width: 100%;
	height: 351px;
	display: flex;
	align-items: center;
	justify-content: center;

	${media.tablet`
     height: 200px;
     margin: 30px auto;
  `};
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	${media.desktop`
     width: 100%;
     flex-direction: column;
     justify-content: center;
     padding: 40px 0;
  `};
`;

const Col = styled.div<{ isPaddingLeft?: boolean }>`
	flex: 1;
	${props =>
		props.isPaddingLeft &&
		css`
			padding-left: 20px;
			${media.tablet`
      padding-left: 0;
   `}
		`};
`;

const ImgWrapper = styled.div`
	height: auto;
	margin-top: -75px;
	width: 100%;
`;

const ContactUs = memo(() => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Wrapper>
			<Background>
				<Container>
					<MainContent>
						{!isMobileScreen && (
							<Col>
								<ImgWrapper>
									<ResponsiveImage
										altDesc="Unique experiences - contact us - picture"
										imgSmall={ContactUsImgSmall}
										imgMedium={ContactUsImgMedium}
										imgLarge={ContactUsImgLarge}
									/>
								</ImgWrapper>
							</Col>
						)}

						<Col isPaddingLeft>
							<CommonParagraph
								isSecondHeader
								mainTitle={t("UNIQUE_EXPERIENCES.CONTACT_US_TITLE")}
								subTitle={t("UNIQUE_EXPERIENCES.CONTACT_US_SUBTITLE")}
							/>
							<ContactButton translationKey="UNIQUE_EXPERIENCES.CONTACT_US_BTN" />
						</Col>
					</MainContent>
				</Container>
			</Background>
		</Wrapper>
	);
});

export default ContactUs;
