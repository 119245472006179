import { Box } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ReactNode } from "react";
import styled from "styled-components";

import { ReactComponent as IconTerrible } from "assets/icons/ratings/rate_icon_1color.svg";
import { ReactComponent as IconBad } from "assets/icons/ratings/rate_icon_2color.svg";
import { ReactComponent as IconNotBad } from "assets/icons/ratings/rate_icon_3color.svg";
import { ReactComponent as IconGood } from "assets/icons/ratings/rate_icon_4color.svg";
import { ReactComponent as IconVeryGood } from "assets/icons/ratings/rate_icon_5color.svg";
import { ReactComponent as IconNoValue } from "assets/icons/ratings/rate_icon_6.svg";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

import HoverTooltip from "../tooltips/HoverTooltip";

const IconContainer = styled.div<{ backgroundColor: string; iconScale?: number }>`
	svg {
		transform: scale(${props => props.iconScale || 1.6});

		circle {
			fill: ${props => props.backgroundColor};
		}
	}
`;

const StyledIcon = styled(HelpOutlineIcon)`
	color: ${colors.lightSunShade};
	font-size: 18px;
`;

export enum EValueLevel {
	terrible = "terrible",
	bad = "bad",
	notBad = "notBad",
	good = "good",
	veryGood = "veryGood",
	noValue = "noValue",
}

export interface ISingleRange {
	label: string;
	minValue: number;
	maxValue: number;
	icon: EValueLevel;
}

export interface IStatisticBox {
	label: string;
	ranges: ISingleRange[];
	value: number | null | undefined;
	valueLabel?: ReactNode;
	withTooltip?: boolean;
	tooltipInfo?: ReactNode;
	iconScale?: number;
}

export const createIcon = (valueLevelParam: EValueLevel, scaleParam: number | undefined) => {
	let Icon;

	switch (valueLevelParam) {
		case EValueLevel.terrible:
			Icon = (
				<IconContainer backgroundColor="#E53935" iconScale={scaleParam}>
					<IconTerrible />
				</IconContainer>
			);
			break;
		case EValueLevel.bad:
			Icon = (
				<IconContainer backgroundColor="#FF8441" iconScale={scaleParam}>
					<IconBad />
				</IconContainer>
			);
			break;
		case EValueLevel.notBad:
			Icon = (
				<IconContainer backgroundColor="#FFCA28" iconScale={scaleParam}>
					<IconNotBad />
				</IconContainer>
			);
			break;
		case EValueLevel.good:
			Icon = (
				<IconContainer backgroundColor="#4CAF50" iconScale={scaleParam}>
					<IconGood />
				</IconContainer>
			);
			break;
		case EValueLevel.veryGood:
			Icon = (
				<IconContainer backgroundColor="#00C853" iconScale={scaleParam}>
					<IconVeryGood />
				</IconContainer>
			);
			break;
		default:
			Icon = (
				<IconContainer backgroundColor="rgb(216, 216, 216)" iconScale={scaleParam}>
					<IconNoValue />
				</IconContainer>
			);
	}

	return Icon;
};

const StatisticBox = ({ label, ranges, value, valueLabel, withTooltip, tooltipInfo, iconScale }: IStatisticBox) => {
	const { t } = useTranslation("ui");

	const valueLevel =
		typeof value === "number"
			? ranges.find(elem => value >= elem.minValue && value <= elem.maxValue)?.icon || EValueLevel.noValue
			: EValueLevel.noValue;

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			gridGap="20px"
			flexWrap="wrap"
		>
			<div>
				<Box fontSize="14px" marginBottom="4px" display="flex" gridGap="10px" alignItems="center">
					{label}

					{withTooltip && (
						<HoverTooltip
							icon={<StyledIcon />}
							backgroundColor={colors.white}
							content={
								<Box padding="10px">
									{tooltipInfo && (
										<Box fontSize="14px" fontWeight="500" marginBottom="20px">
											{tooltipInfo}
										</Box>
									)}

									{ranges.map((elem, index) => (
										<Box
											key={elem.label}
											marginBottom={ranges.length - 1 === index ? undefined : "5px"}
											display="flex"
											justifyContent="space-between"
											alignItems="center"
											width="100%"
											gridGap="20px"
										>
											<Box fontSize="14px" fontWeight="500">
												{elem.label}
											</Box>

											{createIcon(elem.icon, 1)}
										</Box>
									))}
								</Box>
							}
						/>
					)}
				</Box>

				<Box fontSize="28px" fontWeight="600" fontFamily={MontserratFontFamily}>
					{valueLabel || (typeof value === "number" ? value : t("STATISTIC_BOX.NO_DATA"))}
				</Box>
			</div>

			{createIcon(valueLevel, iconScale)}
		</Box>
	);
};

export default StatisticBox;
