import { createContext, ReactNode, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Notification from "../index";
import {
	ENotificationType,
	IBackofficeErrorProvider,
	IBackOfficeErrorsContext,
	INotificationItem,
	INotificationSnackbar,
} from "../types";

export const NotificationProviderContext = createContext<IBackOfficeErrorsContext>({
	notifications: [],
	total: 0,

	// tslint:disable-next-line:no-empty
	add(message: ReactNode, type?: ENotificationType, snackbarProps?: INotificationSnackbar): void {},
	// tslint:disable-next-line:no-empty
	remove(id: string): void {},
	// tslint:disable-next-line:no-empty
	clear(): void {},
});

const NotificationProvider = ({ children }: IBackofficeErrorProvider) => {
	const [notifications, setNotifications] = useState<INotificationItem[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [notificationProps, setNotificationProps] = useState<INotificationSnackbar>();

	const remove = id => setNotifications(prevNotifications => prevNotifications.filter(item => item.id !== id));

	const clear = () => {
		setNotifications([]);

		setNotificationProps(undefined);
	};

	const addNotification = (
		message: ReactNode,
		type: ENotificationType = "info",
		snackbarProps?: INotificationSnackbar,
	) => {
		setNotificationProps(snackbarProps);
		setNotifications(prevNotifications => [...prevNotifications, { message, type, id: uuidv4() }]);
		setTotal(prevTotal => prevTotal + 1);
	};

	const addCallback = useCallback((message, type, snackbarProps) => addNotification(message, type, snackbarProps), []);
	const removeCallback = useCallback(id => remove(id), []);
	const clearCallback = useCallback(clear, []);

	const contextErrorValue = {
		notifications,
		total,

		add: addCallback,
		remove: removeCallback,
		clear: clearCallback,
	};

	return (
		<NotificationProviderContext.Provider value={contextErrorValue}>
			<Notification snackbarProps={notificationProps} />

			{children}
		</NotificationProviderContext.Provider>
	);
};

export default NotificationProvider;
