import {
	Avatar,
	Box,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Divider,
	IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlined from "@material-ui/icons/EditOutlined";
import styled, { css } from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { currencySymbolsDictionary } from "data/dictionaries";
import { EExperienceStatus, IExperienceDTO } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { minutesToHM } from "utils/dates";
import { getEarnings } from "utils/prices";
import fillRoute from "utils/routes";

import RatingStars from "ui/RatingStars";
import ExperienceStatusLabel from "ui/statuses/ExperienceStatusLabel";

import colors from "styles/colors";
import { RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import ConfirmDialog from "../../dialogs/ConfirmDialog";

const CardWrapper = styled(Card)`
	width: 100%;
	font-family: ${RobotoFontFamily};
	color: ${colors.mainFontColor};
`;

const CardMainContent = styled(CardActionArea)`
	border-radius: 4px;
	box-shadow: 0 0 2.5rem 0 #cecece;
`;

const CardStatusContent = styled(CardActions)`
	box-sizing: border-box;
	padding: 10px 20px;
	background: #f2f2f2;
	width: 100%;
	min-height: 68px;

	${media.phone`
    padding: 10px;
  `};
`;

const CardDetailsContent = styled(CardContent)`
	height: 130px;
	padding: 1.25rem 1.25rem 1.563rem;

	${media.tablet`
    padding: 10px 5px 5px 10px;
    height: 120px;
  `};
`;

const CardTitle = styled.h1`
	margin: 0 0 5px;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.06;
	letter-spacing: 0.56px;

	${media.phone`
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.44px;
  `};
`;

const CardLabel = styled.span`
	margin-right: 5px;
	font-size: ${dimensions.fontSize.medium};
	line-height: 1.55;
	letter-spacing: 0.07px;
`;

const CardCityHoursLabel = styled(CardLabel)`
	color: ${colors.secondaryGray};
`;

const CardAvatarLabel = styled(CardLabel)`
	margin-right: 0;
	position: absolute;
	top: 0;
	font-size: 10px;

	${media.tablet`
     top: 8px;
  `};
`;

const AvatarContent = styled(Avatar)`
	position: relative;
	bottom: 55px;
	width: 54px;
	height: 54px;
	border: 4px solid white;

	${media.phone`
      width: 39px;
      height: 39px;
  `};
`;

const CardMediaContent = styled(CardMedia)`
	height: 400px;

	${media.desktop`
      height: 350px;
  `};
`;

const ButtonEdit = styled(Box)<{ disabled?: boolean }>`
	${props =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.4;
		`};
`;

const Record = ({
	title,
	photo_main,
	city,
	duration_minutes,
	status,
	id,
	ratings,
	pricing,
	localhost,
	archiveExp,
}: IExperienceDTO & { archiveExp(): void }) => {
	const { t } = useTranslation();

	const currency = pricing?.currency;

	return (
		<CardWrapper>
			<StyledLink
				to={fillRoute(paths.UPDATE_EXPERIENCE_PAGE, { id, search: { preview: true } })}
				$withoutHoverUnderline
			>
				<CardMainContent>
					<CardMediaContent image={photo_main ? photo_main : CoverImg} title={title} />

					<CardDetailsContent>
						<Box display="flex">
							<Box flexGrow={1}>
								<CardTitle>{title.length > 50 ? title.slice(0, 50) + "..." : title}</CardTitle>

								<Box display="flex">
									<CardCityHoursLabel>{city} </CardCityHoursLabel>

									<CardCityHoursLabel>
										{duration_minutes ? `| ${minutesToHM(duration_minutes)}` : ""}
									</CardCityHoursLabel>
								</Box>
							</Box>

							<Box display="flex" flexDirection="column" alignItems="center" position="relative">
								<AvatarContent alt={localhost?.first_name || "Local Host"} src={localhost?.photo_url || ""} />

								<CardAvatarLabel>{localhost?.first_name || "Local Host"}</CardAvatarLabel>
							</Box>
						</Box>

						<Divider />

						<Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={4}>
							<Box display="flex">
								{typeof pricing?.filter_price === "number" && (
									<CardLabel>
										Approx earnings: from{" "}
										<strong>
											{!!currency ? currencySymbolsDictionary[currency] : ""} {getEarnings(pricing.filter_price)}
										</strong>
									</CardLabel>
								)}
							</Box>

							{ratings.count > 0 && (
								<RatingStars
									value={ratings.average}
									count={ratings.count}
									detailsRating={ratings.values}
									size="small"
									withoutModal={!ratings.values}
								/>
							)}
						</Box>
					</CardDetailsContent>
				</CardMainContent>
			</StyledLink>

			<CardStatusContent>
				<Box display="flex" flexDirection="column" width="100%">
					<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
						<ExperienceStatusLabel type={status} />

						{status !== EExperienceStatus.RETIRED && (
							<Box display="flex">
								<ButtonEdit
									disabled={
										status === EExperienceStatus.UNDER_REVIEW || status === EExperienceStatus.PUBLISHED__UNDER_REVIEW
									}
								>
									<StyledLink
										to={
											status === EExperienceStatus.DRAFT
												? fillRoute(paths.EXPERIENCE_CREATE_STEP, { id, step: "step-1" })
												: fillRoute(paths.UPDATE_EXPERIENCE_PAGE, { id })
										}
									>
										<IconButton>
											<EditOutlined />
										</IconButton>
									</StyledLink>
								</ButtonEdit>

								<ConfirmDialog
									onConfirm={() => archiveExp()}
									title={t("EXPERIENCE.RECORD.ARCHIVE_CONFIRMATION_TITLE")}
									message={t("EXPERIENCE.RECORD.ARCHIVE_CONFIRMATION")}
								>
									<IconButton>
										<DeleteOutlineIcon />
									</IconButton>
								</ConfirmDialog>
							</Box>
						)}
					</Box>
				</Box>
			</CardStatusContent>
		</CardWrapper>
	);
};

export default Record;
