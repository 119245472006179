import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";
import styled from "styled-components";

import BecomeLocalhostImgSmall from "assets/images/uniqueExperiences/become_localhost.png";
import BecomeLocalhostImgMedium from "assets/images/uniqueExperiences/become_localhost@2x.png";
import BecomeLocalhostImgLarge from "assets/images/uniqueExperiences/become_localhost@3x.png";
import BlueBackgroundLeft from "assets/images/uniqueExperiences/blue_background_left.svg";
import BlueBackgroundRight from "assets/images/uniqueExperiences/blue_background_right.svg";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, ImgWrapper, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

const Background = styled.div`
	width: 100%;
	padding: 1rem 0 3rem;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const DescriptionText = styled.p`
	margin: 0 auto;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.large};
	font-weight: 600;
	line-height: 1.71;
	letter-spacing: 0.03px;
	text-align: center;

	${media.tablet`
    font-size: ${dimensions.fontSize.medium};
  `};
`;

const RegisterBtnContent = styled.div`
	margin: 1.25rem auto 70px auto;
	text-align: center;
`;

const DetailsWrapper = styled.div`
	margin-bottom: 5rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	${media.desktop`
    justify-content: center;
  `};
`;

const DetailsBox = styled.div<{ $backgroundUrl: string }>`
	margin-right: 1.875rem;
	padding: 0 2.5rem;
	width: 25.625rem;
	height: 14.813rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${props => props.$backgroundUrl});
	background-size: 100% 100%;
	background-repeat: no-repeat;

	${media.largeDesktop`
    margin-bottom: 1.25rem;
  `};

	${media.tablet`
    margin-right: 0;
  `};
`;

const DetailsDescription = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.04px;
	text-align: center;
	margin: 0;
`;

const BecomeLocalhost = memo(() => {
	const { t, withRaw } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const userData = useAppSelector(selectUser);

	return (
		<WrapperSection>
			<Background>
				<Container>
					<Box paddingTop={!isSmallScreen && "80px"}>
						<SmallerTitle textAlignCenter>{withRaw("UNIQUE_EXPERIENCES.BECOME_LOCALHOST_TITLE")}</SmallerTitle>
					</Box>

					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.BECOME_LOCALHOST_DESC", null)}</DescriptionText>

					{!(
						[EUserType.TRAVELER, EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]
					).includes(userData?.userType) && (
						<RegisterBtnContent>
							<StyledLink
								to={
									!userData
										? paths.ONBOARDING_REGISTER
										: fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: null, step: null })
								}
								$withoutHoverUnderline
							>
								<Button color="primary" variant="contained" size="large">
									{t(!userData ? "UNIQUE_EXPERIENCES.HEADER_REGISTER_NOW" : "ONBOARDING.FINAL_MODAL.CTA")}
								</Button>
							</StyledLink>
						</RegisterBtnContent>
					)}

					<DetailsWrapper>
						<DetailsBox $backgroundUrl={BlueBackgroundRight}>
							<DetailsDescription>{t("UNIQUE_EXPERIENCES.BECOME_LOCALHOST_1_DESC")}</DetailsDescription>
						</DetailsBox>

						<DetailsBox $backgroundUrl={BlueBackgroundLeft}>
							<DetailsDescription>{t("UNIQUE_EXPERIENCES.BECOME_LOCALHOST_2_DESC")}</DetailsDescription>
						</DetailsBox>

						<DetailsBox $backgroundUrl={BlueBackgroundRight}>
							<DetailsDescription>{t("UNIQUE_EXPERIENCES.BECOME_LOCALHOST_3_DESC")}</DetailsDescription>
						</DetailsBox>
					</DetailsWrapper>

					<ImgWrapper>
						<ResponsiveImage
							altDesc="Unique experiences - become a localhost - picture"
							imgSmall={BecomeLocalhostImgSmall}
							imgMedium={BecomeLocalhostImgMedium}
							imgLarge={BecomeLocalhostImgLarge}
						/>
					</ImgWrapper>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default BecomeLocalhost;
