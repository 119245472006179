import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";

import { Container } from "styles/common";

const HealthPage = () => (
	<AppWrapper>
		<Helmet>
			<link rel="canonical" />

			<meta name="robots" content="noindex, nofollow" />
		</Helmet>

		<Container>
			<Breadcrumbs />

			<Box id="health-check-info" marginTop="100px" textAlign="center" minHeight="200px">
				<h2>
					<Box display="inline" fontWeight="normal">
						App version:{" "}
					</Box>

					{process.env.REACT_APP_CURRENT_RELEASE_VERSION || "No proper ENV variable!"}
				</h2>
			</Box>
		</Container>
	</AppWrapper>
);

export default HealthPage;
