import { TextField } from "@material-ui/core";
import { BaseTextFieldProps } from "@material-ui/core/TextField/TextField";
import { IMaskMixin } from "react-imask";

interface IMaskInput extends BaseTextFieldProps {
	inputRef: any;
}

const MaskInput = IMaskMixin(({ inputRef, ...props }: IMaskInput) => <TextField {...props} inputRef={inputRef} />);

export default MaskInput;
