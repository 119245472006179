import { Helmet } from "react-helmet-async";

import useTranslation from "hooks/useTranslation";

import { MainLayout } from "components/layout/MainLayout";

import { LayoutComponentProps } from "../types";

const LayoutComponent = ({ Component, routerProps }: LayoutComponentProps) => {
	const { t } = useTranslation("seo");

	return (
		<MainLayout>
			<Helmet>
				<title>{t("SEO.TITLE")}</title>
				<meta name="description" content={t("SEO.META.DESCRIPTION")} />
				<meta name="keywords" content={t("SEO.META.KEYWORDS")} />

				<meta property="og:type" content="website" />
				<meta property="og:title" content={t("SEO.TITLE")} />
				<meta property="og:description" content={t("SEO.META.DESCRIPTION")} />
				{/* @todo:fix - environment file and mainDomain */}
				<meta property="og:url" content={process.env.REACT_APP_SITE_URL || "https://localbini.com"} />
				<meta
					property="og:image"
					// @todo:fix - environment file and mainDomain
					content={`${process.env.REACT_APP_SITE_URL || "https://localbini.com"}/localbiniSmall.svg`}
				/>
				<meta property="og:site_name" content="LocalBini" />
				<meta property="og:locale" content="en_GB" />
			</Helmet>

			<Component {...routerProps} />
		</MainLayout>
	);
};

export default LayoutComponent;
