import { Divider } from "@material-ui/core";
import { CalendarToday, LanguageOutlined, QueryBuilder } from "@material-ui/icons";
import styled, { css } from "styled-components";

import SelectField from "components/formik/SelectField";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import media from "styles/media";

export const Wrapper = styled.div`
	padding: 22px 20px 10px;
	background: ${colors.white};
	border-radius: 4px;
	box-shadow: 0 0 40px 0 #cecece;
	border: 2px solid ${colors.white};

	${media.largeDesktop`
    box-shadow: none;
    border: none;
  `};
`;

export const TitlePage = styled.strong`
	font-family: ${MontserratFontFamily};
	font-size: 2.25rem;
	font-weight: bold;
	line-height: 1.08;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 1rem;
    line-height: 1.25;
  `};
`;

export const MainTitle = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	font-weight: bold;
	color: ${colors.darkGreen};
`;

export const TitleSection = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	color: ${colors.bermudaGray};
`;

export const PriceLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 24px;
	font-weight: bold;
	line-height: 1.33;
	text-align: right;
	color: ${colors.mainFontColor};
`;

export const TotalPriceLabel = styled(PriceLabel)`
	font-size: 16px;
	font-weight: 500;
	line-height: 2;
	letter-spacing: normal;
`;

export const StyledDivider = styled(Divider)`
	background: ${colors.sunShade};
`;

export const LinksWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	color: ${colors.mainFontColor};
`;

export const Text = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	letter-spacing: 0.44px;
	color: ${colors.mainFontColor};
`;

export const CalendarPopup = styled.div`
	position: absolute;
	left: 15px;
	top: 24px;
	width: auto;
	height: auto;
	box-shadow: 0 0 40px 0 #cecece;
	z-index: 100;
`;

const sharedIconsStyles = css`
	height: 20px;
	color: ${colors.hokiColor};
`;

export const StyledCalendarToday = styled(CalendarToday)<{ disabled?: boolean }>`
	${sharedIconsStyles};
	width: 18px;
	cursor: pointer;

	${props => props.disabled && "cursor: initial; pointer-events: none;"}
`;

export const StyledQueryBuilder = styled(QueryBuilder)`
	${sharedIconsStyles};
`;

export const StyledLanguageOutlined = styled(LanguageOutlined)`
	margin-right: 10px;
	${sharedIconsStyles};
`;

export const NotResultsLabel = styled.div`
	margin-top: 3px;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.5;
	color: ${colors.red};
`;

export const StyledSelectField = styled(SelectField)`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	padding: 0 0 0 3px;
`;
