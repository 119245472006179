import { FormControlLabel, FormControlLabelProps, Switch as SwitchUI, SwitchProps } from "@material-ui/core";
import { Field } from "formik";
import { ReactNode } from "react";
import styled from "styled-components";

interface ISwitchField extends Omit<FormControlLabelProps, "label" | "control"> {
	name: string;
	label?: ReactNode;
	switchProps?: Omit<SwitchProps, "checked" | "onChange" | "name">;
}

const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;
`;

const SwitchField = ({ name, label, switchProps, ...otherProps }: ISwitchField) => (
	<Field name={name}>
		{({ field, form: { setFieldValue } }) => (
			<StyledFormControlLabel
				control={
					<SwitchUI
						checked={field.value || false}
						onChange={(_, checked) => setFieldValue(field.name, checked)}
						name={field.name}
						{...switchProps}
					/>
				}
				label={label}
				{...otherProps}
			/>
		)}
	</Field>
);

export default SwitchField;
