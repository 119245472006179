import EventNoteIcon from "@material-ui/icons/EventNote";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { StandardLink } from "styles/common";

const StyledCalendarIcon = styled(EventNoteIcon)`
	width: 16px;
	height: 16px;
	opacity: 0.6;
	cursor: pointer;
`;

interface ILinkGoogleCalendar {
	title: string;
	startDate: string;
	endDate: string;
	timeZone: string;
	description?: string;
	location?: string;
}

const LinkGoogleCalendar = ({ title, startDate, endDate, timeZone, description, location }: ILinkGoogleCalendar) => {
	const { t } = useTranslation("ui");

	return (
		<StandardLink
			href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${startDate}%2F${endDate}&ctz=${timeZone}`}
			target="_blank"
			title={t("LINK_GOOGLE_CALENDAR.LABEL")}
		>
			<StyledCalendarIcon />
		</StandardLink>
	);
};

export default LinkGoogleCalendar;
