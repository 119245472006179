import styled from "styled-components";

import logo from "assets/icons/logo/localbini-greyish.svg";
import cityPhoto from "assets/images/background/city_photo.jpg";
import lifestylePhoto from "assets/images/background/lifestyle_photo.jpg";
import roofstopPhoto from "assets/images/background/roofstop_photo.jpg";
import tripPhoto from "assets/images/background/trip_photo.jpg";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

import { tabletWidth } from "./shared.styled";

export const Column = styled.div`
	background-color: ${colors.white};
	width: 50%;
	height: 100%;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: relative;
		top: 16px;
		left: 16px;
		width: 64px;
		height: 64px;
		background: url(${logo});
	}

	@media screen and (max-width: ${tabletWidth}) {
		width: 100%;
	}
`;

export const BackgroundColumn = styled.div`
	position: fixed;
	width: 50%;
	height: 100%;
	right: 0;
	top: 0;
	background-repeat: no-repeat;
	background-size: cover;

	@media screen and (max-width: ${tabletWidth}) {
		display: none;
	}

	&.defaultBackground {
		background: url(${tripPhoto});
	}

	&.confirmPhoneBackground {
		background: url(${lifestylePhoto});
	}

	&.confirmCityBackground {
		background: url(${cityPhoto});
	}

	&.confirmProfileBackground {
		background: url(${roofstopPhoto});
	}
`;

export const BackgroundDescription = styled.h1`
	font-family: ${MontserratFontFamily};
	font-size: 40px;
	font-weight: 600;
	color: ${colors.white};
	line-height: 55px;
	max-width: 392px;
	padding-top: 36px;
	position: relative;
	margin: 124px;
	text-align: left;

	&:before {
		content: "";
		background-color: ${colors.white};
		position: absolute;
		width: 62px;
		height: 4px;
		top: 0;
	}
`;

export const Highlighted = styled.span`
	color: ${colors.sunShade};
`;

export const LanguageContainerWithCursor = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 34px;
	right: 16px;
	cursor: pointer;
`;
