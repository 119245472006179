import { isUndefined, sortBy } from "lodash-es";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";

import { selectConfigsAllError, selectConfigsLanguages } from "../selectors/configs";
import { fetchConfigsAll } from "../slices/configs";

interface ILanguageListElem {
	value: string;
	label: string;
}

const useLanguages = () => {
	const configsAllError = useAppSelector(selectConfigsAllError);

	const languages = useAppSelector(selectConfigsLanguages);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (configsAllError === true && !languages) {
			dispatch(fetchConfigsAll());
		}

		// eslint-disable-next-line
	}, [configsAllError, languages]);

	const isLoading = isUndefined(languages);

	const languagesSelect: ILanguageListElem[] = !!languages
		? Object.entries(languages).map(([key, value]) => ({ value: key, label: value?.name || key }))
		: [];

	const languagesNativeSelect: ILanguageListElem[] = !!languages
		? Object.entries(languages).map(([key, value]) => ({ value: key, label: value?.native || value?.name || key }))
		: [];

	const languagesSelectSorted: ILanguageListElem[] = sortBy(languagesSelect, elem => elem.label);

	const languagesNativeSelectSorted: ILanguageListElem[] = sortBy(languagesNativeSelect, elem => elem.label);

	const findNameByKey = (key?: string) => (!!key ? languages?.[key.toLowerCase()]?.name || key : undefined);

	const filterArrayWithKeys = (keys?: string[]) =>
		!!keys
			? keys.map(
					elem =>
						languagesSelect.find(lang => lang.value.toLowerCase() === elem.toLowerCase()) || {
							value: elem,
							label: elem,
						},
			  )
			: [];

	const filterArrayWithNames = (names?: string[]) =>
		!!names
			? names.map(
					elem =>
						languagesSelect.find(lang => lang.label.toLowerCase() === elem.toLowerCase()) || {
							value: elem,
							label: elem,
						},
			  )
			: [];

	const filterArrayWithKeysOnlyLabels = (keys?: string[]) => filterArrayWithKeys(keys).map(elem => elem.label);

	const filterArrayWithNamesOnlyKeys = (names?: string[]) => filterArrayWithNames(names).map(elem => elem.value);

	return {
		languages,
		languagesSelect,
		languagesSelectSorted,
		languagesNativeSelect,
		languagesNativeSelectSorted,
		findNameByKey,
		filterArrayWithKeys,
		filterArrayWithNames,
		filterArrayWithKeysOnlyLabels,
		filterArrayWithNamesOnlyKeys,
		isLoading,
	};
};

export default useLanguages;
