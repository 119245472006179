import * as Yup from "yup";

import { IContactUsDataFields, TContactUsFields } from "data/notifications/types";

const requiredFieldLabel = "Required!";
const tooShortMin2 = "Too short, min. 2 characters!";
const bookingIdValidation = "Booking ID must contains 36 characters!";

const createValidationSchema = (options: TContactUsFields) => {
	const initialValues: { [K in keyof IContactUsDataFields]: string } & { subject_HELPER: string } = {
		name: "",
		from: "",
		description: "",
		subject_HELPER: "",
	};

	const shape: { [K in keyof IContactUsDataFields]: any } & { subject_HELPER: any } = {
		name: Yup.string().min(2, tooShortMin2).required(requiredFieldLabel),
		from: Yup.string().email("Must be a valid email!").required(requiredFieldLabel),
		description: Yup.string().min(2, tooShortMin2).required(requiredFieldLabel),
		subject_HELPER: Yup.string().required(requiredFieldLabel),
	};

	if (options.includes("booking_id")) {
		initialValues.booking_id = "";
		shape.booking_id = Yup.string()
			.min(36, bookingIdValidation)
			.max(36, bookingIdValidation)
			.required(requiredFieldLabel);
	}

	if (options.includes("subject")) {
		initialValues.subject = "";
		shape.subject = Yup.string().min(2, tooShortMin2).required(requiredFieldLabel);
	}

	if (options.includes("participants_amount")) {
		initialValues.participants_amount = "";
		shape.participants_amount = Yup.number().required(requiredFieldLabel);
	}

	if (options.includes("city")) {
		initialValues.city = "";
		shape.city = Yup.string().min(2, tooShortMin2).required(requiredFieldLabel);
	}

	if (options.includes("datetime")) {
		initialValues.datetime = "";
		shape.datetime = Yup.string().required(requiredFieldLabel);
	}

	if (options.includes("phone")) {
		initialValues.phone = "";
		shape.phone = Yup.string()
			.min(5, "Too short, min. 5 characters!")
			.max(16, "Too long, max. 16 characters!")
			.required(requiredFieldLabel);
	}

	return { validationSchema: Yup.object().shape(shape), initialValues };
};

export default createValidationSchema;
