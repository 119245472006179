import { Button } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

interface IBookingSimpleBoxProps {
	description: ReactNode | string;
	handleClick: () => void;
	labelBtn: string;
}

const Wrapper = styled.div`
	width: 100%;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0 0 40px 0 #cecece;
	border: solid 1px ${colors.sunShade};
	margin-bottom: 30px;
`;

const DescriptionContent = styled.div`
	margin-bottom: 26px;
	width: 70%;
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	font-weight: 500;
	line-height: 1.68;
	color: ${colors.mainFontColor};
`;

const StyledButton = styled(Button)`
	width: 100%;
	height: 48px;
`;

const BookingSimpleBox = ({ description, handleClick, labelBtn }: IBookingSimpleBoxProps) => (
	<Wrapper>
		<DescriptionContent>{description}</DescriptionContent>

		<StyledButton variant="contained" color="primary" onClick={handleClick}>
			{labelBtn}
		</StyledButton>
	</Wrapper>
);

export default BookingSimpleBox;
