import { Tooltip, TooltipProps, withStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Namespace } from "i18next";
import { ReactElement, useMemo } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

interface IHoverTooltip extends Omit<TooltipProps, "title" | "children"> {
	translation?: string;
	content?: ReactElement;
	ns?: Namespace;
	icon?: ReactElement;
	iconColor?: string;
	backgroundColor?: string;
}

const StyledIcon = styled(InfoOutlinedIcon)<{ $color?: string }>`
	width: 18px;
	margin-top: -6px;
	margin-bottom: -6px;

	fill: ${props => props.$color || "rgba(0, 0, 0, 0.47)"};
`;

const HoverTooltip = ({ translation, content, icon, iconColor, ns, backgroundColor, ...rest }: IHoverTooltip) => {
	const { withRaw } = useTranslation(ns);

	const DarkBlueTooltip = useMemo(
		() =>
			withStyles({
				tooltip: {
					color: colors.mainFontColor,
					backgroundColor: backgroundColor || colors.oceanDrive,
					boxShadow: "0 0 30px 0 rgba(170, 170, 170, 0.5)",
					borderRadius: 4,
					padding: "12px 10px 10px 10px",
					fontSize: 12,
					fontWeight: 400,
				},
				arrow: {
					color: backgroundColor || colors.oceanDrive,
				},
			})(Tooltip),
		[backgroundColor],
	);

	return (
		<DarkBlueTooltip
			title={content ? content : withRaw(translation || "", null)}
			placement="top-start"
			arrow
			interactive
			{...rest}
		>
			{icon || <StyledIcon $color={iconColor} />}
		</DarkBlueTooltip>
	);
};

export default HoverTooltip;
