import { EBookingStatus, EStatusSuperBooking } from "data/backoffice/bookings/types";

import colors from "styles/colors";

export const parseStatus = (status: EBookingStatus | EStatusSuperBooking): { label: string; color: string } =>
	({
		[EStatusSuperBooking.AWAITING_HOST]: { label: "Active", color: colors.green },
		[EStatusSuperBooking.NOTIFIED]: { label: "Active", color: colors.green },
		[EStatusSuperBooking.HOST_CONFIRMED]: { label: "Active", color: colors.green },
		[EStatusSuperBooking.ABANDONED]: { label: "Abandoned", color: colors.hokiColor },
		[EStatusSuperBooking.EXPIRED]: { label: "Expired", color: colors.hokiColor },

		[EBookingStatus.NEW]: { label: "Waiting for payment", color: colors.sunShade },
		[EBookingStatus.PAID]: { label: "Active", color: colors.green },
		[EBookingStatus.PAYMENT_FAILED]: { label: "Payment failed", color: colors.red },
		[EBookingStatus.CANCELED]: { label: "Canceled", color: colors.red },
		[EBookingStatus.REFUNDED]: { label: "Refunded", color: colors.sunShade },
		[EBookingStatus.PERFORMED]: { label: "Done", color: colors.green },
		[EBookingStatus.PERFORMED__REFUNDED]: { label: "Refunded", color: colors.sunShade },
	}[status]);
