import { Divider, Menu, MenuItem } from "@material-ui/core";
import { Cache } from "aws-amplify";
import { ReactElement, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import store from "store";
import styled from "styled-components";

import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import authorizationServices from "services/api/authorization";
import { expirationTimeForCache } from "services/aws/auth";

import { fetchAuthenticatedUser } from "store/slices/users";

import { accountTypeParam } from "utils/constants";
import fillRoute from "utils/routes";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import {
	menuConfigAdditional,
	menuConfigMainLocalhost,
	menuConfigMainPartnerAgent,
	menuConfigMainPartnerManager,
	menuConfigMainTraveler,
} from "../config";

const GreyLine = styled(Divider)`
	margin: ${dimensions.spaces.xsmall} 0;
`;

const LinkStyle = styled(Link)`
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const Wrapper = styled(Menu)`
	z-index: 1500;
	position: absolute;

	& li {
		font-family: ${MontserratFontFamily};
		font-weight: 500;
		font-size: ${dimensions.fontSize.medium};
		color: ${colors.mainFontColor};
	}

	& li:hover {
		text-decoration: underline;
	}

	& .MuiMenu-paper {
		margin-top: 1rem;
		padding: 0.75rem;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	}

	${media.tablet`
    & .MuiMenu-paper {
    left: 5%;
    right: 5%;
    width: 100vw;
  }
  `};
`;

interface MenuPopupProps {
	userType?: EUserType;
	originalUserType?: EUserType;
	handleClickOpenDialog: (val: string) => void;
	handleSingOut: () => void;
	handleClose: () => void;
	open: boolean;
	anchorEl: any;
}

const menuVariant = {
	[EUserType.TRAVELER]: menuConfigMainTraveler,
	[EUserType.PARTNER_AGENT]: menuConfigMainPartnerAgent,
	[EUserType.PARTNER_MANAGER]: menuConfigMainPartnerManager,
};

const MenuPopup = ({
	userType,
	originalUserType,
	handleClickOpenDialog,
	handleSingOut,
	open,
	handleClose,
	anchorEl,
}: MenuPopupProps) => {
	const { t } = useTranslation();

	const history = useHistory();
	const location = useLocation();

	const { handleAndNotify } = useErrors();

	const isNotAuthenticated = !userType;

	const possibleToSwitchAccountType = (
		[EUserType.LOCALHOST, EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT] as (
			| EUserType
			| undefined
		)[]
	).includes(originalUserType);

	const switchAccountTypeAction = (type: EUserType | undefined) => async () => {
		if (!type) {
			return;
		}

		try {
			const authData = await authorizationServices.getSwitchAccountType(type);

			if (authData?.authorization) {
				await Cache.setItem("Authorization", authData.authorization, { expires: expirationTimeForCache });
			}

			await store.dispatch<any>(fetchAuthenticatedUser({ accountType: type, reFetchConfig: true }));

			history.push({ pathname: location.pathname, search: `${accountTypeParam}=${type}` });
		} catch (e) {
			handleAndNotify(e);
		}
	};

	useEffect(() => {
		if (open) {
			document.addEventListener("scroll", handleClose);
		}

		return () => document.removeEventListener("scroll", handleClose);

		// eslint-disable-next-line
	}, [open]);

	const menuItems: ReactElement[] = [];

	if (!isNotAuthenticated) {
		const menuConfigMain = menuVariant[userType] || menuConfigMainLocalhost;

		menuConfigMain.forEach(v => {
			menuItems.push(
				<LinkStyle key={v.key} to={v.link}>
					<MenuItem disabled={v.disabled} key={v.key}>
						{t(`TOPBAR.${v.translationKey}`)}
					</MenuItem>
				</LinkStyle>,
			);
		});

		if (possibleToSwitchAccountType) {
			menuItems.push(<GreyLine key="line_1" />);

			if (userType === EUserType.TRAVELER) {
				menuItems.push(
					<MenuItem key="switch_to_localhost_account" onClick={switchAccountTypeAction(originalUserType)}>
						{t("TOPBAR.SWITCH_TO_LOCALHOST_LABEL")}
					</MenuItem>,
				);
			}

			if (
				[EUserType.LOCALHOST, EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT].includes(
					userType,
				)
			) {
				menuItems.push(
					<MenuItem key="switch_to_traveler_account" onClick={switchAccountTypeAction(EUserType.TRAVELER)}>
						{t("TOPBAR.SWITCH_TO_TRAVELER_LABEL")}
					</MenuItem>,
				);
			}
		}

		if ([EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT].includes(userType)) {
			menuItems.push(
				<LinkStyle key="backoffice" to={paths.BACKOFFICE}>
					<MenuItem key="backoffice">{t("TOPBAR.BACKOFFICE_LABEL")}</MenuItem>
				</LinkStyle>,
			);
		}

		menuItems.push(<GreyLine key="line_2" />);

		menuConfigAdditional.forEach(v => {
			menuItems.push(
				<LinkStyle key={v.key} to={v.link}>
					<MenuItem disabled={v.disabled}>{t(`TOPBAR.${v.translationKey}`)}</MenuItem>
				</LinkStyle>,
			);
		});

		menuItems.push(<GreyLine key="line_3" />);

		menuItems.push(
			<MenuItem key="Logout" onClick={handleSingOut}>
				{t("TOPBAR.LOGOUT_LABEL")}
			</MenuItem>,
		);
	}

	if (isNotAuthenticated) {
		/* menuItems.push(
      <MenuItem key="Register" onClick={() => handleClickOpenDialog("REGISTER")}>
        {t("TOPBAR.REGISTER_LABEL")}
      </MenuItem>,
    );*/

		menuItems.push(
			<MenuItem key="Login" onClick={() => handleClickOpenDialog("LOGIN")}>
				{t("TOPBAR.LOGIN_LABEL")}
			</MenuItem>,
		);

		menuItems.push(
			<LinkStyle key="onboarding/register" to={fillRoute(paths.ONBOARDING_REGISTER, { search: { type: "binipool" } })}>
				<MenuItem key="Become a Local host">{t("TOPBAR.BECOME_LOCALHOST_LABEL")}</MenuItem>
			</LinkStyle>,
		);

		menuItems.push(
			<MenuItem disabled key="FAQ">
				{t("TOPBAR.FAQ_LABEL")}
			</MenuItem>,
		);
	}

	return (
		<Wrapper
			id="menu-appbar"
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={open}
			onClose={handleClose}
			disableScrollLock
		>
			{menuItems}
		</Wrapper>
	);
};

export default MenuPopup;
