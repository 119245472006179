import { sortBy } from "lodash-es";
import { useEffect } from "react";

import { IPartner } from "data/backoffice/partners/types";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectPartners } from "store/selectors/backoffice/partners";
import { fetchPartners } from "store/slices/backoffice/partners";

import { Option } from "ui/forms/Select";

const usePartner = () => {
	const dispatch = useAppDispatch();

	const partners = useAppSelector(selectPartners);

	useEffect(() => {
		if (!partners.data) {
			dispatch(fetchPartners());
		}

		// eslint-disable-next-line
	}, []);

	const findById = (partnerId?: string | null): IPartner | null | undefined => {
		if (!partners.data) {
			return undefined;
		}

		if (!partnerId) {
			return null;
		}

		return partners.data?.find(p => p?.id === partnerId) || null;
	};

	const partnersSelect: Option[] = partners.data?.map(v => ({ label: v.name, value: v.id })) || [];

	const partnersSelectSorted: Option[] = sortBy(partnersSelect, elem => elem.label);

	return {
		partners: partners.data || [],
		partnersSelect,
		partnersSelectSorted,
		findById,
	};
};

export default usePartner;
