import styled from "styled-components";

import AnimationFlyingElement from "components/loaders/AnimationFlyingElement";

const Wrapper = styled.div`
	position: relative;
`;

const Animation = () => (
	<Wrapper>
		<AnimationFlyingElement topMargin="-20px" leftMargin="-10%" versionAnimation="first" toTheLeft />

		<AnimationFlyingElement
			animationDelay="1s"
			topMargin="10px"
			leftMargin="80%"
			height="30px"
			width="30px"
			versionAnimation="second"
		/>

		<AnimationFlyingElement
			animationDelay="1s"
			topMargin="40%"
			leftMargin="100%"
			height="15px"
			width="15px"
			versionAnimation="third"
			toTheLeft
		/>

		<AnimationFlyingElement topMargin="80%" leftMargin="90%" height="30%" width="30%" />
	</Wrapper>
);

export default Animation;
