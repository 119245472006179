import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export interface AlertMessageProps {
	severity: "success" | "error";
	openAlert: boolean;
	handleCloseAlert(): void;
	verticalPosition?: "top" | "bottom";
	autoHideDuration?: number;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
	openAlert,
	children,
	handleCloseAlert,
	severity,
	verticalPosition = "bottom",
	autoHideDuration = 6000,
}) => (
	<Snackbar
		id="alert_message"
		anchorOrigin={{ horizontal: "center", vertical: verticalPosition }}
		open={openAlert}
		autoHideDuration={autoHideDuration}
		onClose={handleCloseAlert}
	>
		<Alert onClose={handleCloseAlert} severity={severity} variant="filled">
			{children}
		</Alert>
	</Snackbar>
);

export default AlertMessage;
