import Picture1 from "assets/images/tooltips/media1.jpg";
import Picture2 from "assets/images/tooltips/media2.jpg";
import Picture3 from "assets/images/tooltips/media3.jpg";

import useTranslation from "hooks/useTranslation";

import TooltipIcon from "ui/tooltips/TooltipIcon";

import colors from "styles/colors";

import { DescriptionContent, IconContent, Text, Title, TooltipContent } from "../shared.styled";

const Gallery = () => {
	const { t } = useTranslation();

	return (
		<>
			<img src={Picture1} alt="media 1" width={276} height={180} />
			<TooltipContent>
				<IconContent>
					<TooltipIcon iconType="success" />
				</IconContent>

				<DescriptionContent>
					<Title isMarginBottom color={colors.green}>
						{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.TITLE1")}
					</Title>
					<Text>{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.DESC1")}</Text>
				</DescriptionContent>
			</TooltipContent>

			<img src={Picture2} alt="media 2" width={276} height={180} />

			<TooltipContent>
				<IconContent>
					<TooltipIcon iconType="warning" />
				</IconContent>

				<DescriptionContent>
					<Title isMarginBottom color={colors.sunShade}>
						{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.TITLE2")}
					</Title>

					<Text>{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.DESC2")}</Text>
				</DescriptionContent>
			</TooltipContent>

			<img src={Picture3} alt="media 3" width={276} height={180} />

			<TooltipContent>
				<IconContent>
					<TooltipIcon iconType="error" />
				</IconContent>

				<DescriptionContent>
					<Title isMarginBottom color="#f44336">
						{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.TITLE3")}
					</Title>

					<Text>{t("EXPERIENCE.TOOLTIP.MEDIA.EXP1.DESC3")}</Text>
				</DescriptionContent>
			</TooltipContent>
		</>
	);
};

export default Gallery;
