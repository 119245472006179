import { Box, Button, createStyles, Divider, FormHelperText, makeStyles, Theme, Typography } from "@material-ui/core";
import { CalendarToday, Check, LanguageOutlined, PermIdentity, QueryBuilder } from "@material-ui/icons";
import { addMinutes, format, isValid, parse } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import pricingService from "services/api/pricing";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectExperienceTraveller, selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import { DATE_FORMATS } from "utils/dates";
import { prices } from "utils/prices";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

const Title = styled.h1`
	width: 80%;
	margin: 50px 0 30px;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	text-align: center;
	color: ${colors.lightDark};
`;

const Text = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.1px;
	color: ${colors.mainFontColor};
`;

const CheckIcon = styled(Check)`
	margin-right: 14px;
	font-size: 26px;
	color: ${colors.sunShade};
`;

const DialogText = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	color: ${colors.mainFontColor};
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	flex: 1;
`;

const sharedIconsStyles = css`
	margin-right: 14px;
	height: 25px;
	width: 25px;
	color: ${colors.hokiColor};
`;

const PermIdentityIcon = styled(PermIdentity)`
	${sharedIconsStyles};
`;

const CalendarTodayIcon = styled(CalendarToday)`
	${sharedIconsStyles};
`;

const QueryBuilderIcon = styled(QueryBuilder)`
	${sharedIconsStyles};
`;

const LanguageOutlinedIcon = styled(LanguageOutlined)`
	${sharedIconsStyles};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContent: {
			boxShadow: theme.shadows[2],
			border: `solid 1px ${colors.sunShade}`,
			borderRadius: 4,
			background: theme.palette.common.white,
			color: colors.darkGreen,
		},
		title: {
			fontFamily: MontserratFontFamily,
			fontSize: 20,
			fontWeight: "bold",
			color: theme.palette.primary.main,
			lineHeight: 1.35,
		},
	}),
);

const BookingPrivateBox = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();
	const { addError } = useNotification();

	const classes = useStyles();

	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useAppDispatch();

	const { findNameByKey } = useLanguages();

	const booking = useAppSelector(selectExperienceTravellerBooking);
	const experience = useAppSelector(selectExperienceTraveller);

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [loadingPrices, setLoadingPrices] = useState<boolean>(false);

	const affiliateErrorFirstTime = useRef<string>();

	const language = findNameByKey(booking.language);

	const tempEndTime = addMinutes(
		parse(booking.hour?.[0], DATE_FORMATS.TIME_FORMAT, new Date()),

		experience?.data?.duration_minutes,
	);

	let endTime = "";

	if (isValid(tempEndTime)) {
		endTime = format(tempEndTime, DATE_FORMATS.TIME_FORMAT);
	}

	useEffect(() => {
		if (affiliateErrorFirstTime.current) {
			addError(affiliateErrorFirstTime.current);
		}

		// eslint-disable-next-line
	}, [affiliateErrorFirstTime.current]);

	const getPrices = useCallback(
		async () => {
			try {
				setLoadingPrices(true);

				const pricingData = await pricingService.postGeneratePriceSummary({
					...booking.postGeneratePriceSummaryPayload,
					private: true,
				});

				let wrongAffiliate;

				if (pricingData.discount.affiliate_code?.error_message) {
					wrongAffiliate = true;

					if (!affiliateErrorFirstTime.current) {
						affiliateErrorFirstTime.current = pricingData.discount.affiliate_code.error_message;
					}
				}

				dispatch(
					experienceTravellerActions.updateBooking({
						pricing: pricingData,
						...(wrongAffiliate && { affiliateCode: null }),
					}),
				);

				return true;
			} catch (e) {
				handleAndNotify(e);

				return false;
			} finally {
				setLoadingPrices(false);
			}
		},

		// eslint-disable-next-line
		[booking],
	);

	const showCloseDialog = useCallback(async () => {
		if (booking.adults > 0 && !!booking.date.length && !!booking.hour.length) {
			const fetchedPrices = !showDialog ? await getPrices() : true;

			if (fetchedPrices) {
				setShowDialog(prevState => !prevState);

				setError(null);
			}
		} else {
			setError("Fill a proper data to the Booking summary form (adults, kids, date, time)");
		}
	}, [booking, showDialog, getPrices]);

	const addPrivateBooking = useCallback(() => {
		dispatch(
			experienceTravellerActions.updateBooking({
				isPrivate: true,
			}),
		);

		history.push(`${match.url}?step=1`);
	}, [dispatch, history, match]);

	return (
		<>
			<Dialog showDialog={showDialog} handleClose={showCloseDialog}>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Title as="strong">{t("BOOKING_PRIVATE.MAIN_TITLE_DIALOG")}</Title>
				</Box>

				<Box marginBottom="5px">
					<DialogText>
						<strong>{t("BOOKING_PRIVATE.BOOKING_SUMMARY")}</strong>
					</DialogText>
				</Box>

				<Divider />

				<Box display="flex" marginTop="20px" width="100%">
					<Item>
						<PermIdentityIcon />

						<DialogText>
							{booking.adults} {booking.adults === 1 ? t("BOOKING.SUMMARY.ADULT") : t("BOOKING.SUMMARY.ADULTS")},{" "}
							{booking.kids} {booking.kids === 1 ? t("BOOKING.SUMMARY.KID") : t("BOOKING.SUMMARY.KIDS")}
						</DialogText>
					</Item>

					<Item>
						<QueryBuilderIcon />

						<DialogText>
							{booking.hour?.[0]} - {endTime}
						</DialogText>
					</Item>
				</Box>

				<Box display="flex" marginTop="20px" marginBottom="20px" width="100%">
					<Item>
						<CalendarTodayIcon />

						<DialogText>{booking.date?.[0]}</DialogText>
					</Item>

					<Item>
						<LanguageOutlinedIcon />

						{!!language && <DialogText>{language}</DialogText>}
					</Item>
				</Box>

				<Divider />

				<Box display="flex" justifyContent="flex-end" marginTop="20px" marginBottom="20px">
					<DialogText>
						{t("BOOKING_FORM.TOTAL_PRICE")}{" "}
						<Box display="inline-flex" alignItems="center" gridGap="10px">
							<b>{prices(booking.pricing.final_gross_price, booking.pricing.currency, undefined, undefined, true)}</b>
							{booking.showVat && typeof booking.pricing?.tax?.vat === "number" && (
								<Box color={colors.bermudaGray}>
									incl. {booking.vatRate}% VAT ({prices(booking.pricing.tax.vat, booking.pricing.currency)})
								</Box>
							)}
						</Box>
					</DialogText>
				</Box>

				<Box display="flex" justifyContent="space-between" marginBottom="20px">
					<Button variant="outlined" onClick={() => setShowDialog(false)}>
						{t("BOOKING_PRIVATE.CANCEL_BTN")}
					</Button>

					<Button variant="contained" color="primary" onClick={addPrivateBooking}>
						{t("BOOKING_PRIVATE.MAKE_PRIVATE_BOOKING_BTN")}
					</Button>
				</Box>
			</Dialog>

			<Box pt={13} pl={10} pr={10} pb={13} className={classes.mainContent}>
				<Box width="70%">
					<Typography variant="body1" className={classes.title}>
						{t("BOOKING_PRIVATE.MAIN_TITLE_BOX")}
					</Typography>
				</Box>

				<Box mt={8} mb={8}>
					<Box display="flex" mb={5}>
						<CheckIcon />
						<Text>{t("BOOKING_PRIVATE.POINT_1")}</Text>
					</Box>

					<Box display="flex" mb={5}>
						<CheckIcon />
						<Text>{t("BOOKING_PRIVATE.POINT_2")}</Text>
					</Box>

					<Box display="flex" mb={5}>
						<CheckIcon />
						<Text>{t("BOOKING_PRIVATE.POINT_3")}</Text>
					</Box>
				</Box>

				<ActionButton
					translationDefault="BOOKING_PRIVATE.MAKE_PRIVATE_BOOKING_BTN"
					onClick={showCloseDialog}
					isAction={loadingPrices}
					color="primary"
					variant="contained"
					fullWidth
				/>

				{error && (
					<Box marginTop="10px">
						<FormHelperText error>{error}</FormHelperText>
					</Box>
				)}
			</Box>
		</>
	);
};

export default BookingPrivateBox;
