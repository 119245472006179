import { Box, RadioGroup } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import { boolean, string } from "yup";

import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import { IPostInviteNewUserBody } from "services/api/types/users";
import usersService from "services/api/users";

import usePartner from "store/hooks/usePartner";

import CheckboxField from "components/formik/CheckboxField";
import Input from "components/formik/Input";
import RadioField from "components/formik/RadioField";
import SelectField from "components/formik/SelectField";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

const StyledModalHeader = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	color: ${colors.lightDark};
`;

const StyledRadioGroup = styled(RadioGroup)`
	flex-direction: row;
	justify-content: space-evenly;
`;

interface IInviteNewUserModalProps {
	handleClose: () => void;
	reloadMainTable?: () => void;
	allUserTypes?: boolean;
}

const partnerTypes = [EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER];

const InviteNewUserModal = ({ handleClose, reloadMainTable, allUserTypes }: IInviteNewUserModalProps) => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();
	const { success } = useNotification();

	const { partnersSelect } = usePartner();

	const {
		yup: { validations, requiredStringMinLength, requiredEmail, requiredString },
	} = useValidations();

	const formikProps = useFormik<IPostInviteNewUserBody>({
		enableReinitialize: true,
		validationSchema: validations({
			first_name: requiredStringMinLength(2),
			last_name: requiredStringMinLength(2),
			email: requiredEmail,
			user_type: requiredString,
			...(allUserTypes && {
				partner_id: string().when("user_type", {
					is: userType => partnerTypes.includes(userType),
					then: requiredString,
				}),
				binipool_onboarding: boolean(),
			}),
		}),
		initialValues: {
			first_name: "",
			last_name: "",
			email: "",
			user_type: EUserType.PARTNER_AGENT,
			...(allUserTypes && { partner_id: "", binipool_onboarding: true }),
		},
		onSubmit: async values => {
			const data = { ...values };

			if (partnerTypes.includes(values.user_type)) {
				data.partner_id = allUserTypes ? values.partner_id : partnerStorage.getData()?.id;
			} else {
				delete data.partner_id;
			}

			if (values.user_type !== EUserType.LOCALHOST) {
				delete data.binipool_onboarding;
			}

			try {
				await usersService.postInvite({
					...data,
				});

				success();

				if (!!reloadMainTable) {
					reloadMainTable();
				}

				handleClose();
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	return (
		<Dialog showDialog handleClose={handleClose}>
			<StyledModalHeader>{t("INVITE_NEW_USER_MODAL.TITLE")}</StyledModalHeader>

			<FormikProvider value={formikProps}>
				<Box marginTop="30px" marginBottom="20px">
					<Input
						name="first_name"
						inputClassName="full"
						label={t("INVITE_NEW_USER_MODAL.LABEL.FIRST_NAME")}
						required
						disabled={formikProps.isSubmitting}
					/>
				</Box>

				<Box marginBottom="20px">
					<Input
						name="last_name"
						inputClassName="full"
						label={t("INVITE_NEW_USER_MODAL.LABEL.LAST_NAME")}
						required
						disabled={formikProps.isSubmitting}
					/>
				</Box>

				<Box marginBottom="25px">
					<Input
						name="email"
						inputClassName="full"
						label={t("INVITE_NEW_USER_MODAL.LABEL.EMAIL")}
						required
						disabled={formikProps.isSubmitting}
					/>
				</Box>

				{allUserTypes && (
					<StyledRadioGroup>
						<Box fontSize="14px" display="flex" alignItems="center">
							<RadioField name="user_type" value={EUserType.TRAVELER} />

							<span>{t(`COMMON.USER_TYPE.${EUserType.TRAVELER}`)}</span>
						</Box>

						<Box fontSize="14px" display="flex" alignItems="center">
							<RadioField name="user_type" value={EUserType.LOCALHOST} />

							<span>{t(`COMMON.USER_TYPE.${EUserType.LOCALHOST}`)}</span>
						</Box>
					</StyledRadioGroup>
				)}

				<StyledRadioGroup>
					<Box fontSize="14px" display="flex" alignItems="center">
						<RadioField name="user_type" value={EUserType.PARTNER_AGENT} />

						<span>{t(`COMMON.USER_TYPE.${EUserType.PARTNER_AGENT}`)}</span>
					</Box>

					<Box fontSize="14px" display="flex" alignItems="center">
						<RadioField name="user_type" value={EUserType.PARTNER_MANAGER} />

						<span>{t(`COMMON.USER_TYPE.${EUserType.PARTNER_MANAGER}`)}</span>
					</Box>
				</StyledRadioGroup>

				{allUserTypes && partnerTypes.includes(formikProps.values.user_type) && (
					<Box marginY="25px">
						<SelectField
							name="partner_id"
							label={t("INVITE_NEW_USER_MODAL.LABEL.PARTNER_ID")}
							required
							disabled={formikProps.isSubmitting}
							options={partnersSelect}
							variant="outlined"
						/>
					</Box>
				)}

				{allUserTypes && formikProps.values.user_type === EUserType.LOCALHOST && (
					<Box marginY="25px">
						<CheckboxField name="binipool_onboarding" label={t("INVITE_NEW_USER_MODAL.LABEL.BINIPOOL_TYPE")} />
					</Box>
				)}

				<Box marginTop="30px" marginBottom="30px" display="flex" justifyContent="center">
					<ActionButton
						isAction={formikProps.isSubmitting}
						disabled={formikProps.isSubmitting || !formikProps.isValid}
						translationDefault="INVITE_NEW_USER_MODAL.BUTTON"
						onClick={() => formikProps.handleSubmit()}
					/>
				</Box>
			</FormikProvider>
		</Dialog>
	);
};

export default InviteNewUserModal;
