import { TCurrencyType } from "../experiences/types";
import { IId, IInternalComment } from "../types";

export enum EUserStatus {
	DRAFT = "DRAFT",
	UNDER_REVIEW = "UNDER_REVIEW",
	PUBLISHED__UNDER_REVIEW = "PUBLISHED__UNDER_REVIEW",
	AWAITING_REVIEW = "AWAITING_REVIEW",
	PUBLISHED__AWAITING_REVIEW = "PUBLISHED__AWAITING_REVIEW",
	PUBLISHED = "PUBLISHED",
	PUBLISHED__DRAFT = "PUBLISHED__DRAFT",
	NEEDS_CHANGES = "NEEDS_CHANGES",
	PUBLISHED__NEEDS_CHANGES = "PUBLISHED__NEEDS_CHANGES",
	REJECTED = "REJECTED",
}

export enum EUserGeneralStatus {
	ACTIVE = "ACTIVE",
	DELETED = "DELETED",
}

export enum EUserType {
	TRAVELER = "TRAVELER",
	LOCALHOST = "LOCALHOST",
	JUNIOR_STAFF = "JUNIOR_STAFF",
	STAFF = "STAFF",
	ADMIN = "ADMIN",
	ROOT = "ROOT",
	PARTNER_AGENT = "PARTNER_AGENT",
	PARTNER_MANAGER = "PARTNER_MANAGER",
	PARTNER_TRAVELER = "PARTNER_TRAVELER",
	UNREGISTERED_TRAVELER = "UNREGISTERED_TRAVELER",
}

// Should be always these types of labels in private config!!!
export enum EUserFunctionalLabels {
	FAKE = "FAKE",
	NO_LABEL = "NO_LABEL",
}

export enum EReviewOutcomeLabel {
	ACCEPTED = "ACCEPTED",
	NEEDS_CHANGES = "NEEDS_CHANGES",
	REJECTED = "REJECTED",
}

export enum EUserProfilePictureKind {
	profile = "profile",
	cover = "cover",
}

export enum EUserContactStatus {
	NO_CONTACT = "NO_CONTACT",
	EVERYTHING_OK = "EVERYTHING_OK",
	NOT_PICKED_UP = "NOT_PICKED_UP",
	PLATFORM_ISSUES = "PLATFORM_ISSUES",
	REMOVAL_REQUEST = "REMOVAL_REQUEST",
	WRONG_NUMBER = "WRONG_NUMBER",
}

export enum EUserOnboardingMeetingStatus {
	CANCELED = "CANCELED",
	COMPLETED = "COMPLETED",
	NOT_SCHEDULED = "NOT_SCHEDULED",
	SCHEDULED = "SCHEDULED",
}

export enum EUserOnboardingType {
	BINIPOOL = "BINIPOOL",
	UNIQUE = "UNIQUE",
}

export enum EUserOnboardingStatus {
	REGISTERED = "REGISTERED",
	IN_PROGRESS = "IN_PROGRESS",
	COMPLETED = "COMPLETED",
}

export enum EUserOnlineStatus {
	ACTIVE = "ACTIVE", // 24h or less
	MID_ACTIVE = "MID_ACTIVE", // 1-7 days
	LESS_ACTIVE = "LESS_ACTIVE", // 8-30 days
	NO_ACTIVE = "NO_ACTIVE", // 30+ days
	UNDEFINED = "UNDEFINED", // undefined amount of days
}

export type TUserStatus = keyof typeof EUserStatus;
export type TReviewOutcomeLabel = keyof typeof EReviewOutcomeLabel;

export type TUserLabel = EUserFunctionalLabels | string;

export interface ILocalhostProfile {
	first_name?: string;
	description?: string;
	motto?: string;
	skills_and_interests?: string[];
	languages?: string[];
	cities?: string[];
	media_gallery?: {
		created_at: string;
		id: string;
		media_url: string;
		sha256: string;
	}[];
	occupation?: string;
	pictures?: {
		cover: string | null;
		profile: string | null;
	};
	onboarding_selected_experiences?: string[];
	status: EUserStatus;
}

export interface IUserInternal {
	label?: TUserLabel;
	is_vip?: boolean;
	claims_adult?: boolean;
	verification?: {
		is_verified: boolean;
		modified_by: string | null;
		modified_on: string | null;
	};
	mfa?: {
		enabled: boolean;
		preferred_setting: null | string;
	};
	communication?: {
		contact_status: EUserContactStatus;
		contacted_by: string | null;
		contacted_on: string | null;
	};
	note?: string | null;
	expectations?: {
		bookings?: {
			per_week?: number | null;
		};
		earnings?: {
			currency?: TCurrencyType | null;
			per_hour?: number | null;
			per_month?: number | null;
		};
	};
	expert_in?: string[];
	miecho?: {
		birthplace?: string | null;
		persona?: string[];
		native_languages?: string[];
		fit_for?: string[];
		in_city_since?: string | null;
		interview_questions?: {
			[key: string]: {
				question: string;
				answer: string;
			} | null;
		};
	};
	source?: {
		channel?: string | null;
		details?: string | null;
		ticket_id?: string | null;
	};
	subtype?: string | null;
}

export interface ILbReview {
	comments: string | null;
	fields_to_improve: string[];
	review_outcome: EReviewOutcomeLabel;
	reviewed_by: string | null;
	reviewed_on: string | null;
}

interface IPersonal {
	email?: string;
	phone?: string;
	devices?: {
		[key: string]: {
			name: string;
			platform: string;
		};
	};
}

export interface IProfileDraft extends ILocalhostProfile {
	birthdate?: string;
	last_name?: string;
	lb_review?: ILbReview;
}

export interface IPublicProfile extends ILocalhostProfile {
	is_verified: boolean;
}

interface IPrivateProfile {
	last_name?: string;
}

export interface IUser {
	// @todo:chore - #AD1
	id: string;
	created_on: string;

	first_name?: string;
	photo_url: string | null;
	last_name?: string;
	personal?: IPersonal;
	internal_comments?: IInternalComment[];
	internal: IUserInternal;
	last_seen_online_on: string;
	profile_draft?: IProfileDraft;
	public_profile?: IPublicProfile;
	private_profile?: IPrivateProfile;
	user_type: EUserType;
	status: EUserGeneralStatus;
	ratings?: {
		average: number;
		count: number;
		values: {
			1: number;
			2: number;
			3: number;
			4: number;
			5: number;
		};
		weighted_result: number;
	};
	statistics?: {
		hosting_notification?: {
			count: {
				total: number;
				by_status: {
					ACCEPTED: number;
					CANCELED: number;
					REJECTED: number;
					SENT: number;
					TIMED_OUT: number;
				};
			};
			mean_reaction_time_seconds: number | null;
			mean_response_time_seconds: number | null;
			reaction_rate_percent: number | null;
		};
	};
	onboarding?: {
		current_step: string;
		steps: Record<number, string>;
		status: EUserOnboardingStatus;
		type?: EUserOnboardingType;
		meeting_id?: string;
		meeting_status: EUserOnboardingMeetingStatus;
		version?: string;
	};
}

export interface IAuthenticatedUser extends IUser {
	userType: EUserType;
	originalUserType: EUserType;
}

export interface ILocalhostDetails extends Pick<ILocalhostProfile, "pictures"> {
	first_name: string | null;
	last_name?: string | null;
	email?: string | null;
	phone?: string | null;
	photo_url?: string | null;
	id?: string;
	cities?: string[];
	languages?: string[];
	label?: TUserLabel;
	note?: string | null;
	subtype?: string | null;
}

// @todo:chore - refactor user interfaces (IUser without IId's fields (#AD1), TUserIId)
export type TUserIId = IUser & IId;

// Last edit: 22-03-2023
// After change you must update EOnboardingVersion below!
// Must start from 1 (0 is reserved for redirect on onboarding)!
export enum EOnboardingSteps {
	PhoneVerify = 1,
	// START for ?type=binipool onboarding
	ShortBio = 2,
	Binipools = 3,
	// END
	// START for unique onboarding
	SelectCities = 2,
	LongBio = 3,
	// END
	Calendly = 4,
}

// Last edit: 22-03-2023
export enum EOnboardingVersions {
	LAST = "3.0.0",
}
