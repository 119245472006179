import { Box, List, ListItem, ListItemText, Radio, RadioGroup } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem/ListItem";
import { useState } from "react";

import { getDictionary, TDictionaryName } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

export interface IStringRadioGroup<T> {
	onChange: (newValue) => void;
	value: T;
	field?: TDictionaryName;
	listItemProps?: Pick<ListItemProps, "disableGutters">;
}

const StringRadioGroup = <T extends string>({
	onChange,
	value,
	field = "PHYSICAL_DIFFICULTY_LEVEL",
	listItemProps,
}: IStringRadioGroup<T>) => {
	const [currentValue, setCurrentValue] = useState<T>(value);
	const { t } = useTranslation();
	const dictionary: T[] = getDictionary(field);

	const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(event.target.value as T);
		onChange(event.target.value);
	};

	const handleChange = (changedValue: T) => {
		setCurrentValue(changedValue);
		onChange(changedValue);
	};

	return (
		<Box>
			<RadioGroup
				aria-label="choose-difficulty-level"
				name="choose-difficulty-level"
				value={currentValue}
				onChange={handleChangeRadio}
			>
				<List key="list" dense>
					{dictionary.map((d, i) => (
						<ListItem key={i} divider {...listItemProps}>
							<Radio value={d} />

							<ListItemText
								onClick={() => handleChange(d)}
								primary={t(`EXPERIENCE.FORM.STRING_RADIO.${d}.TITLE`)}
								secondary={t(`EXPERIENCE.FORM.STRING_RADIO.${d}.DESCRIPTION`)}
							/>
						</ListItem>
					))}
				</List>
			</RadioGroup>
		</Box>
	);
};

export default StringRadioGroup;
