import { IId } from "../types";

export enum ECurrency {
	EUR = "EUR",
	GBP = "GBP",
	CHF = "CHF",
}

export enum ECurrencySymbol {
	EUR = "€",
	GBP = "£",
	CHF = "CHF",
}

export enum EBankAccountStatus {
	EMPTY = "EMPTY",
	INVALID = "INVALID",
	VALID = "VALID",
	VALID_NOT_SEPA = "VALID_NOT_SEPA",
}

export interface IFinancialsAddress {
	city: string;
	country: string;
	street: string;
	zip_code: string;
}

export interface IFinancialsBankingDetails {
	account_number?: string;
	bank_account_status?: EBankAccountStatus;
	bank_address?: IFinancialsAddress;
	first_name?: string;
	last_name?: string;
	swift?: string;
	uid?: string;
	user_address?: IFinancialsAddress;
}

export type TFinancialsBankingDetailsIId = IFinancialsBankingDetails & IId;
