import { Box, Button, Divider, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { format } from "date-fns";
import { getCurrentLocale } from "i18n";
import { useMemo, useState } from "react";
import styled from "styled-components";

import BlogIcon from "assets/icons/footer/blog.svg";
import FBIcon from "assets/icons/footer/fb.svg";
import InstagramIcon from "assets/icons/footer/instagram.svg";
import LanguageIcon from "assets/icons/footer/language.svg";
// import TikTokIcon from "assets/icons/footer/tiktok.svg";
import LinkedinIcon from "assets/icons/footer/linkedin.svg";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import useLanguages from "store/hooks/useLanguages";

import Dialog from "ui/Dialog";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";

import colors from "styles/colors";
import { Container, RobotoFontFamily, StyledLink as CommonStyledLink } from "styles/common";
import dimensions from "styles/dimensions";

import LanguageSelectorModal from "../../translations/LanguageSelectorModal";
import { configFooter } from "./config";

const Title = styled.span`
	display: block;
	margin: 0;
	font-size: ${dimensions.fontSize.medium};
	font-weight: bold;
	line-height: 2.64;
	letter-spacing: 1px;
	text-transform: uppercase;
`;

const StyledLink = styled(CommonStyledLink)`
	font-size: ${dimensions.fontSize.medium};
	color: ${colors.mainFontColor};
	text-decoration: none;
	line-height: 2.64;
	letter-spacing: 1px;

	&:hover {
		text-decoration: underline;
	}
`;

const StyledDisclaimerImpressumLink = styled(CommonStyledLink)`
	font-size: ${dimensions.fontSize.medium};
	color: ${colors.mainFontColor};
	line-height: 2.64;
	letter-spacing: 1px;
`;

const StyledImg = styled.img`
	width: 26px;
	height: 26px;
`;

const StyledLinkForIcons = styled.a`
	display: flex;
`;

const FooterContent = styled.footer`
	margin-top: 60px;
	background: ${colors.white};
	width: 100%;
	font-family: ${RobotoFontFamily};
`;

const BoxWithCursor = styled(Box)`
	cursor: pointer;
`;

const Footer = () => {
	const { t, withComponents } = useTranslation();

	const { findNameByKey } = useLanguages();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const dictionary = getDictionary("FOOTER_TITLES");

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [showModal, setShowModal] = useState(false);

	const currentLanguage = getCurrentLocale()?.code?.split("-")[0] || "en";

	const languageLabel = findNameByKey(currentLanguage);

	const temporaryBinipoolConceptModal = useMemo(
		() => (
			<Dialog showDialog handleClose={() => setShowModal(false)}>
				<DialogContentWrapper title={t("FOOTER.TEMPORARY_BINIPOOL_MODAL.TITLE")}>
					<Box lineHeight="18px">
						{withComponents("FOOTER.TEMPORARY_BINIPOOL_MODAL.CONTENT", {
							Link: <CommonStyledLink to={paths.BINIPOOL_CONCEPT} $decoration="underline" />,
						})}

						<Box display="flex" width="100%" justifyContent="space-evenly" marginTop="25px">
							<Button variant="contained" onClick={() => setShowModal(false)}>
								{t("FOOTER.TEMPORARY_BINIPOOL_MODAL.BUTTON.CLOSE")}
							</Button>

							<Button color="primary" variant="contained">
								<CommonStyledLink to={paths.BINIPOOL_CONCEPT}>
									{t("FOOTER.TEMPORARY_BINIPOOL_MODAL.BUTTON.OKAY")}
								</CommonStyledLink>
							</Button>
						</Box>
					</Box>
				</DialogContentWrapper>
			</Dialog>
		),

		// eslint-disable-next-line
		[],
	);

	return (
		<FooterContent>
			{showModal && temporaryBinipoolConceptModal}

			<LanguageSelectorModal anchorElement={anchorEl} setAnchorElement={setAnchorEl} />

			<Divider />

			<Box pt={15} pb={20} pl={isSmallScreen ? 5 : 0} pr={isSmallScreen ? 5 : 0}>
				<Container>
					<Grid container spacing={10}>
						{dictionary.map(value => (
							<Grid item xs={12} sm={6} md={3} key={value}>
								<Title>{t(`FOOTER.${value}`)}</Title>

								{configFooter[value].map(element => (
									<Box key={element.id}>
										{element.direct ? (
											<StyledLink as="a" href={element.link}>
												{t(`FOOTER.LINKS.${element.id}.label`)}
											</StyledLink>
										) : element.showModal ? (
											<StyledLink
												as="a"
												href={element.link}
												onClick={e => {
													e.preventDefault();

													setShowModal(true);
												}}
											>
												{t(`FOOTER.LINKS.${element.id}.label`)}
											</StyledLink>
										) : (
											<StyledLink to={element.link}>{t(`FOOTER.LINKS.${element.id}.label`)}</StyledLink>
										)}
									</Box>
								))}
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>

			<Box
				pt={10}
				pb={10}
				pl={isSmallScreen ? 5 : 0}
				pr={isSmallScreen ? 5 : 0}
				style={{ background: theme.palette.background.greyBackground }}
			>
				<Container>
					<Box
						display="flex"
						justifyContent={isSmallScreen ? undefined : "space-between"}
						alignItems="center"
						flexDirection={isSmallScreen ? "column-reverse" : "row"}
					>
						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="flex-start"
							flexDirection="column"
							width={isSmallScreen ? "100%" : "initial"}
						>
							<Typography variant="body1">
								<StyledLink to={paths.HOMEPAGE}>© {format(new Date(), "Y")} LocalBini AG</StyledLink>
								&nbsp;|&nbsp;
								<StyledDisclaimerImpressumLink to={paths.HELP_DISCLAIMER_IMPRESSUM}>
									{t("FOOTER.LINKS.DISCLAIMER.label")}
								</StyledDisclaimerImpressumLink>
								&nbsp;|&nbsp;
								<StyledDisclaimerImpressumLink to={paths.HELP_DISCLAIMER_IMPRESSUM}>
									{t("FOOTER.LINKS.IMPRESSUM.label")}
								</StyledDisclaimerImpressumLink>
							</Typography>

							{isSmallScreen && (
								<BoxWithCursor
									display="flex"
									justifyContent="center"
									alignItems="center"
									mr={10}
									mt={14}
									onClick={e => setAnchorEl(e.currentTarget)}
								>
									<img
										title="Change language version"
										alt="Change language version icon"
										src={LanguageIcon}
										height={20}
										width={20}
									/>

									<Box marginLeft="8px">{languageLabel}</Box>
								</BoxWithCursor>
							)}
						</Box>

						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
							width={isSmallScreen ? "100%" : "initial"}
							mt={isSmallScreen ? 14 : 0}
							mb={isSmallScreen ? 5 : 0}
						>
							{!isSmallScreen && (
								<BoxWithCursor
									display="flex"
									justifyContent="center"
									alignItems="center"
									mr={10}
									onClick={e => setAnchorEl(e.currentTarget)}
								>
									<StyledImg title="Change language version" alt="Change language version icon" src={LanguageIcon} />

									<Box marginLeft="8px">{languageLabel}</Box>
								</BoxWithCursor>
							)}

							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								{/* @todo:fix - environment file -> getSubdomain */}
								<StyledLinkForIcons href="https://biniblog.localbini.com" target="_blank" rel="noopener noreferrer">
									<StyledImg title="LocalBini - Blog link" alt="LocalBini - Blog link" src={BlogIcon} />
								</StyledLinkForIcons>
							</Box>

							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								<StyledLinkForIcons
									href="https://www.instagram.com/localbini"
									target="_blank"
									rel="noopener noreferrer nofollow"
								>
									<StyledImg title="LocalBini - Instagram link" alt="LocalBini - Instagram link" src={InstagramIcon} />
								</StyledLinkForIcons>
							</Box>

							{/*<Box display="flex" justifyContent="center" alignItems="center" mr={5} pb={2.5}>*/}
							{/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
							{/*  <StyledLinkForIcons href="#" target="_blank" rel="nofollow">*/}
							{/*    <StyledImg alt="localbini - tiktok" src={TikTokIcon} />*/}
							{/*  </StyledLinkForIcons>*/}
							{/*</Box>*/}

							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								<StyledLinkForIcons
									href="https://www.facebook.com/localbini"
									target="_blank"
									rel="noopener noreferrer nofollow"
								>
									<StyledImg title="LocalBini - Facebook link" alt="LocalBini - Facebook link" src={FBIcon} />
								</StyledLinkForIcons>
							</Box>

							<Box display="flex" justifyContent="center" alignItems="center">
								<StyledLinkForIcons
									href="https://www.linkedin.com/company/localbini"
									target="_blank"
									rel="noopener noreferrer nofollow"
								>
									<StyledImg title="LocalBini - Linkedin link" alt="LocalBini - Linkedin link" src={LinkedinIcon} />
								</StyledLinkForIcons>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</FooterContent>
	);
};

export default Footer;
