import styled from "styled-components";

import colors from "styles/colors";
import dimensions from "styles/dimensions";
import media from "styles/media";

export const TooltipContent = styled.div`
	display: flex;
	max-width: 400px;
`;

export const IconContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 2.5rem;
`;

export const Title = styled.h1<{ isMarginBottom?: boolean; color?: string }>`
	margin: 0 0 ${props => (props.isMarginBottom ? "0.938rem" : "0")} 0;
	font-size: ${dimensions.fontSize.large};
	font-weight: 500;
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: ${props => (props.color ? props.color : colors.mainFontColor)};

	${media.phone`
    font-size: ${dimensions.fontSize.medium};
  `};
`;

export const Text = styled.div<{ isMarginBottom?: boolean }>`
	margin-bottom: ${props => (props.isMarginBottom ? "0.938rem" : "0")};
	font-size: ${dimensions.fontSize.large};
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: ${colors.mainFontColor};

	${media.phone`
    font-size: ${dimensions.fontSize.medium};
  `};
`;

export const DescriptionContent = styled.div`
	margin-left: 0.5rem;
`;
