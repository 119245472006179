import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from "@material-ui/core";
import { StarBorder } from "@material-ui/icons";
import { FormikProps } from "formik";
import { ChangeEvent } from "react";
import styled from "styled-components";

import { IExperience as FormValues } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCity from "store/hooks/useCity";
import useCountry from "store/hooks/useCountry";
import useLanguages from "store/hooks/useLanguages";
import { selectUser } from "store/selectors/user";

import StringArray from "components/creation-process/StringArray";
import StringTags from "components/creation-process/StringTags";
import AboutExp from "components/creation-process/tooltips/basic/AboutExp";
import ActivityOrTour from "components/creation-process/tooltips/basic/ActivityOrTour";
import ListOfAttractions from "components/creation-process/tooltips/basic/ListOfAttractions";
import NameYourExp from "components/creation-process/tooltips/basic/NameYourExp";
import PlaceYourExp from "components/creation-process/tooltips/basic/PlaceYourExp";
import UniqueExp from "components/creation-process/tooltips/basic/UniqueExp";
import Input from "components/formik/Input";
import SelectField from "components/formik/SelectField";

import { Tooltip } from "ui/tooltips/Tooltip";

import colors from "styles/colors";

import {
	ItemDescription,
	ItemTitle,
	ItemWrapper,
	MainTitleSection,
	StyledAutocomplete,
	TitleTooltipWrapper,
	Wrapper,
} from "../shared.styled";

const StyledStarBorderIcon = styled(StarBorder)`
	font-size: 24px;
	color: ${colors.black};
`;

const Step1 = ({ isSubmitting, values, errors, setFieldValue }: FormikProps<FormValues>) => {
	const { t } = useTranslation();

	const { countriesSelectSorted } = useCountry();
	const { citiesSelectForCountryOrAll } = useCity();
	const { languagesSelectSorted, findNameByKey } = useLanguages();

	const userData = useAppSelector(selectUser);

	return (
		<Wrapper>
			<MainTitleSection>{t("EXPERIENCE.BASIC_INFORMATION")}</MainTitleSection>
			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.title.TITLE")}</ItemTitle>

					<Tooltip>
						<NameYourExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.title.DESCRIPTION")}</ItemDescription>

				<Input
					inputAndWrapperStyles={{ width: "100%" }}
					name="title"
					label={t("EXPERIENCE.FIELDS.title.INPUT_TITLE")}
					type="text"
					readOnly={isSubmitting}
					value={values.title}
					onChange={(v: ChangeEvent<HTMLInputElement>) => setFieldValue("title", v.target.value)}
					required
					maxLength={100}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.city.TITLE")}</ItemTitle>

					<Tooltip>
						<PlaceYourExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.location.DESCRIPTION")}</ItemDescription>

				<Grid container spacing={10}>
					<Grid item md={6} xs={12}>
						<SelectField
							name="country"
							label={t("SEARCH.EXPERIENCES.MORE_FILTERS.COUNTRY")}
							options={countriesSelectSorted}
							required
						/>
					</Grid>

					<Grid item md={6} xs={12}>
						<SelectField
							name="city"
							label={t("SEARCH.EXPERIENCES.MORE_FILTERS.CITY")}
							options={citiesSelectForCountryOrAll(values?.country)}
							required
						/>
					</Grid>
				</Grid>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.languages.TITLE")}</ItemTitle>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.languages.DESCRIPTION")}</ItemDescription>

				<StyledAutocomplete
					name="languages"
					id="creation_process_autocomplete_languages_list"
					multiple
					autoHighlight
					options={
						userData?.profile_draft?.languages?.length
							? userData.profile_draft.languages.map(elem => ({ value: elem, label: findNameByKey(elem) }))
							: languagesSelectSorted
					}
					getOptionLabel={option => findNameByKey(option.value as string) || ""}
					getOptionSelected={(option, selected) => option.value === selected.value}
					filterSelectedOptions
					variant="standard"
					label={t("EXPERIENCE.FIELDS.languages.INPUT_TITLE")}
					inputProps={{
						onKeyUp: undefined,
					}}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.activity_type.TITLE")}</ItemTitle>

					<Tooltip>
						<ActivityOrTour />
					</Tooltip>
				</TitleTooltipWrapper>

				<RadioGroup
					row
					defaultValue={values.activity_type}
					onChange={e => setFieldValue("activity_type", e && e.target.value)}
				>
					<FormControlLabel value="TOUR" control={<Radio />} label={t("EXPERIENCE.FIELDS.activity_type.TOUR")} />

					<FormControlLabel
						value="ACTIVITY"
						control={<Radio />}
						label={t("EXPERIENCE.FIELDS.activity_type.ACTIVITY")}
					/>
				</RadioGroup>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t(`EXPERIENCE.FIELDS.relevant_attractions.${values.activity_type}.TITLE`)}</ItemTitle>

					<Tooltip>
						<ListOfAttractions />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>
					{t(`EXPERIENCE.FIELDS.relevant_attractions.${values.activity_type}.DESCRIPTION`)}
				</ItemDescription>

				<StringArray
					icon={<StyledStarBorderIcon />}
					onChange={v => setFieldValue("relevant_attractions", [...v])}
					value={values.relevant_attractions || []}
				/>

				{errors.relevant_attractions && <FormHelperText error>{errors?.relevant_attractions}</FormHelperText>}
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.categories.TITLE")}</ItemTitle>
				<ItemDescription>{t("EXPERIENCE.FIELDS.categories.DESCRIPTION")}</ItemDescription>

				<StringTags
					onChange={v => setFieldValue("categories", [...v])}
					value={values.categories}
					field="CATEGORY"
					translateDic
				/>

				{errors.categories && <FormHelperText error>{errors.categories}</FormHelperText>}
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.description.TITLE")}</ItemTitle>

					<Tooltip>
						<AboutExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.description.DESCRIPTION")}</ItemDescription>

				<Input
					inputAndWrapperStyles={{ width: "100%" }}
					name="description"
					label={t("EXPERIENCE.FIELDS.description.TITLE")}
					type="text"
					readOnly={isSubmitting}
					value={values.description}
					multiline
					onChange={(v: ChangeEvent<HTMLInputElement>) => setFieldValue("description", v.target.value)}
					required
					maxLength={300}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.description_uniqueness.TITLE")}</ItemTitle>

					<Tooltip>
						<UniqueExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.description_uniqueness.DESCRIPTION")}</ItemDescription>

				<Input
					inputAndWrapperStyles={{ width: "100%" }}
					name="description_uniqueness"
					label={t("EXPERIENCE.FIELDS.description_uniqueness.TITLE")}
					type="text"
					readOnly={isSubmitting}
					value={values.description_uniqueness}
					multiline
					onChange={(v: ChangeEvent<HTMLInputElement>) => setFieldValue("description_uniqueness", v.target.value)}
					required
					maxLength={1000}
				/>
			</ItemWrapper>
		</Wrapper>
	);
};

export default Step1;
