import { Box } from "@material-ui/core";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

const CircleAvatarContent = styled(Box)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Circle = styled.div<{ withoutBorder?: boolean }>`
	margin: 5px 0;
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.sunShade};
	color: ${colors.white};
	border-radius: 50%;

	${props =>
		!props.withoutBorder &&
		css`
			border: 4px solid ${colors.white};
		`};
`;

const Label = styled.span`
	font-size: 12px;
	color: ${colors.mainFontColor};
`;

const CircleIcon = styled(PeopleAltOutlinedIcon)`
	box-sizing: border-box;
	width: 100%;
	height: auto;
	padding: 9px;
`;

interface IBinipoolAvatarProps {
	withLabelCohosted?: boolean;
	withoutBorder?: boolean;
}

const BinipoolAvatar = ({ withLabelCohosted = true, withoutBorder }: IBinipoolAvatarProps) => {
	const { t } = useTranslation();

	return (
		<CircleAvatarContent>
			<Circle withoutBorder={withoutBorder}>
				<CircleIcon />
			</Circle>

			{withLabelCohosted && <Label>{t("EXPERIENCE.BINIPOOL_RECORD.BINIPOOL_CO_HOSTED_LABEL")}</Label>}
		</CircleAvatarContent>
	);
};

export default BinipoolAvatar;
