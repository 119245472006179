import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button/Button";
import { ReactText, RefObject } from "react";
import styled, { css, keyframes } from "styled-components";

import useTranslation from "hooks/useTranslation";

const StyledButton = styled(Button)<{ padding: number }>`
	padding-left: ${props => props.padding}px;
	padding-right: ${props => props.padding}px;
	position: relative;
`;

const ellipsis = keyframes`
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
`;

const Dots = styled.span<{ disableDots?: boolean }>`
	display: inline-block;

	&::after {
		display: inline-block;
		content: "";
		width: 1em;
		color: inherit;
		text-align: left;
		position: absolute;

		${props =>
			!props.disableDots &&
			css`
				animation: ${ellipsis} 1.25s infinite;
			`}
	}
`;

interface IActionButton extends Omit<ButtonProps, "onClick"> {
	translationDefault: string;
	translationAction?: string;
	isAction?: boolean;
	isDisabled?: boolean;
	translationValues?: { [key: string]: ReactText };
	padding?: number;
	onClick?: (...props: any) => unknown;
	ns?: string | string[];
	actionRef?: RefObject<HTMLButtonElement>;
}

const ActionButton = ({
	translationDefault,
	translationAction = "COMMON.IN_PROGRESS",
	isAction = false,
	isDisabled = false,
	translationValues = {},
	padding = 65,
	ns = ["common", "bo"],
	actionRef,
	...rest
}: IActionButton) => {
	const { withValues } = useTranslation(ns);

	return (
		<StyledButton
			padding={padding}
			type="submit"
			variant="contained"
			color="primary"
			disabled={isAction || isDisabled}
			ref={actionRef}
			{...rest}
		>
			<Dots disableDots={!isAction}>
				{withValues(isAction ? translationAction : translationDefault, translationValues, null)}
			</Dots>
		</StyledButton>
	);
};

export default ActionButton;
