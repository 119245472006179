import qs from "query-string";
import { MouseEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getDictionary } from "data/dictionaries";
import { EExpType, TCurrencyType } from "data/experiences/types";
import { EUserStatus } from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import { paths } from "routing/paths";

import experiencesService from "services/api/experiences";

import { ISharedOnboardingProps } from "../../types";
import ExperiencesLayout from "./components/ExperiencesLayout";

export interface IExperienceElem {
	id: string;
	title: string;
	city: string;
	description: string;
	description_uniqueness: string;
	categories: string[];
	duration_time: number;
	price_per_person: number;
	price_private_booking: number;
	currency_symbol?: TCurrencyType;
	photo?: string;
	isSelected?: boolean;
}

const Experiences = ({ moveToNextStep, profileData, updateProfileData, isBusy }: ISharedOnboardingProps) => {
	const { handleAndNotify } = useErrors();

	const { success } = useNotification();

	const history = useHistory();
	const location = useLocation();

	const { selectOnlyBinipools } = qs.parse(location.search);

	const [experiences, setExperiences] = useState<IExperienceElem[] | null | undefined>(null);
	const [experienceDetails, setExperienceDetails] = useState<any | null>(null);
	const [isCancellationPolicyAccepted, setIsCancellationPolicyAccepted] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		setExperiences(null);

		if (profileData?.cities) {
			const fetchExperiences = async () => {
				try {
					const experiencesList = await experiencesService.getListOfExperiences({
						exp_type: EExpType.COHOSTED,
						limit: 100,
						city: profileData.cities,
					});

					const mappedExperiences = experiencesList.data?.map(elem => ({
						id: elem.id,
						title: elem.title,
						city: elem.city,
						photo: elem.photo_main,
						description: elem.description,
						description_uniqueness: elem.description_uniqueness,
						categories: elem.categories
							? getDictionary("CATEGORIES")
									.filter(category => {
										let condition = false;

										elem.categories.forEach(innerElem => {
											if (category.value === innerElem) {
												condition = true;
											}
										});

										return condition;
									})
									.map(category => category.label)
							: [],
						duration_time: elem.duration_minutes / 60,
						price_per_person: elem.pricing.price_per_person || 0,
						price_private_booking: elem.pricing.private_price || 0,
						currency_symbol: elem.pricing.currency,
						...(profileData.onboarding_selected_experiences?.includes(elem.id) && { isSelected: true }),
					}));

					setExperiences(mappedExperiences);
				} catch (e) {
					handleAndNotify(e);

					setExperiences(null);
				}
			};

			fetchExperiences();
		} else {
			setExperiences(undefined);
		}

		// eslint-disable-next-line
	}, [profileData?.cities?.toString(), profileData?.onboarding_selected_experiences?.toString()]);

	const handleSeeDetailsClick = (event, experienceId: string) => {
		event.stopPropagation();

		setExperienceDetails(experiences?.find(elem => elem.id === experienceId));

		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const isNextButtonDisabled = (): boolean => {
		const selectedExperiences = experiences?.filter((experience: IExperienceElem) => experience.isSelected);

		return !!isBusy || selectedExperiences?.length === 0 || !isCancellationPolicyAccepted;
	};

	const handleExperienceSelectClick = (tourIndex: number) => {
		if (!experiences) {
			return;
		}

		experiences[tourIndex].isSelected = !experiences[tourIndex].isSelected;

		setExperiences([...experiences]);
	};

	const handleNextButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		const selectedExperiences = experiences
			?.filter((experience: IExperienceElem) => experience.isSelected)
			.map((experience: IExperienceElem) => experience.id);

		const apiCall = await updateProfileData(
			{
				onboarding_selected_experiences: selectedExperiences,
				...(selectOnlyBinipools && { status: EUserStatus.AWAITING_REVIEW }),
			},
			undefined,
			!!selectOnlyBinipools,
		);

		if (apiCall === true) {
			if (selectOnlyBinipools) {
				success();

				history.push(paths.EXPERIENCES);

				return;
			}

			moveToNextStep();
		}
	};

	const experiencesLayoutProps = {
		experiences,
		experienceDetails,
		handleCloseModal,
		handleExperienceSelectClick,
		handleNextButtonClick,
		handleSeeDetailsClick,
		isCancellationPolicyAccepted,
		isNextButtonDisabled,
		openModal,
		setIsCancellationPolicyAccepted,
		selectOnlyBinipools: !!selectOnlyBinipools,
	};

	return <ExperiencesLayout {...experiencesLayoutProps} />;
};

export default Experiences;
