import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { memo, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import AuthenticIcon from "assets/icons/homePage/unique-vauthentic.svg";
import QualityIcon from "assets/icons/homePage/unique-vetted.svg";
import SatisfactionIcon from "assets/icons/homePage/unique-vsatisfaction.svg";
import HomePageImg from "assets/images/background/homepage_main.jpg";
import HomePageImgSmall from "assets/images/background/homepage_main_mobile@2x.jpg";

import { CustomOptionType } from "data/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import colors from "styles/colors";
import { Container, MontserratFontFamily, RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import PlacesAutocomplete from "../../forms/PlacesAutocomplete";
import WhyLocalbini from "./components/WhyLocalbini";

const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 44px;
	font-weight: bold;
	line-height: 1.05;
	letter-spacing: 1.02px;
	color: ${colors.white};
	text-transform: uppercase;

	${media.tablet`
    font-size: 20px;
    line-height: 1.25;
    letter-spacing: 0.46px;
  `};
`;

const SubTitle = styled.span`
	display: block;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xxlarge};
	font-weight: 500;
	letter-spacing: 0.6px;
	color: ${colors.white};
	text-transform: none;
	line-height: 1.4;

	${media.tablet`
    font-size: ${dimensions.fontSize.xlarge};
    letter-spacing: 0.37px;
  `};
`;

const SearchAutocomplete = styled(PlacesAutocomplete)`
	width: 75%;
	margin-right: 40px;

	div > label {
		font-size: 16px;
		color: ${colors.hokiColor};
		width: max-content;
	}

	svg {
		fill: ${colors.hokiColor};
	}

	${media.tablet`
    margin-right: 10px;
  `};
`;

const SearchWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 5px;
	position: absolute;
	z-index: 999;
	width: 100%;
	height: 120px;
	top: 0;
	box-shadow: 0 0 8px 0 rgb(55 71 79 / 17%);

	${media.tablet`
    padding: 10px;
    border-radius: 0;
  `};
`;

const SearchBoxWrapper = styled.div`
	margin: 0 auto;
	width: 1340px;

	${media.largeDesktop`
    width: 90%;
  `};

	${media.tablet`
    width: 100%;
  `};
`;

const SearchBox = styled.div`
	position: relative;
	width: 100%;
`;

const SearchBtn = styled(Button)`
	text-align: center;
	width: 150px;
	height: 60px;

	${media.desktop`
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
  `};
`;

const SearchBtnLabel = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 15px;
	font-weight: 500;
	line-height: 1.73;
	letter-spacing: 0.3px;

	${media.desktop`
    font-size: 0;
  `};
`;

const StyledSearchIcon = styled(Search)`
	margin-right: 10px;
	color: ${colors.white};
	width: 24px;
	height: 24px;

	${media.desktop`
    margin-right: -5px;
  `};
`;

const WhyLocalbiniContent = styled(Box)`
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	padding-top: 75px;
	width: 100%;

	${media.tablet`
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `};
`;

const Header = memo(() => {
	const { t } = useTranslation();

	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const history = useHistory();

	const [searchValue, setSearchValue] = useState<string>("");

	const keyPressAction = useCallback(
		v => {
			if (v.key === "Enter") {
				v.preventDefault();
				if (searchValue) {
					// @ts-ignore
					const queryURL: any = new URLSearchParams({ text_search: searchValue, limit: 20, offset: 0 });
					history.push({ pathname: paths.SEARCH, search: queryURL.toString() });
				} else {
					history.push({ pathname: paths.SEARCH });
				}
			}
		},
		[searchValue, history],
	);

	return (
		<header>
			<Box
				style={{
					backgroundImage: `linear-gradient(to bottom, #000000 0%, rgba(34, 34, 34, 0.87) 0%, rgba(255, 255, 255, 0) 20%), url(${
						isSmallScreen ? HomePageImgSmall : HomePageImg
					})`,
					marginTop: -96,
					position: "relative",
					top: 0,
					left: 0,
					right: 0,
					zIndex: 100,
					height: "85vh",
					width: "100%",
					backgroundSize: "cover",
					backgroundPosition: "center right",
					backgroundRepeat: "no-repeat",
					transition: "background-image 0.5s linear",
				}}
			>
				<Container>
					<Box width={isSmallScreen ? "90%" : "50%"} pt="20vh">
						<MainTitle>
							{t("HP.HEADER.MAIN_TITLE")}
							<SubTitle>{t("HP.HEADER.SUB_TITLE")}</SubTitle>
						</MainTitle>
					</Box>
				</Container>
			</Box>

			<Box style={{ background: "#f3f5f9" }} pb={25}>
				<SearchBoxWrapper>
					<SearchBox>
						<SearchWrapper
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							mt={isSmallScreen ? -22 : -30}
							mb={10}
						>
							<SearchAutocomplete
								onKeyPressAction={keyPressAction}
								heightInput="35px"
								selectedValue={searchValue}
								onChange={val => setSearchValue(val)}
								customOptions={[
									{
										type: CustomOptionType.CURRENT_LOCATION,
										label: "Nearby",
										value: "Nearby",
									},
								]}
								label={t("HP.HEADER.SEARCH_LABEL")}
							/>

							<StyledLink
								to={fillRoute(paths.SEARCH, { search: searchValue && { text_search: searchValue } })}
								$withoutHoverUnderline
							>
								<SearchBtn color="primary" variant="contained">
									<StyledSearchIcon />
									<SearchBtnLabel>{t("HP.HEADER.SEARCH_BTN")}</SearchBtnLabel>
								</SearchBtn>
							</StyledLink>
						</SearchWrapper>
					</SearchBox>
				</SearchBoxWrapper>

				<Container>
					<WhyLocalbiniContent>
						<WhyLocalbini
							description={t("HP.HEADER.QUALITY_SUBTITLE")}
							icon={<img title="Quality" alt="Quality icon" src={QualityIcon} height="46px" width="46px" />}
							title={t("HP.HEADER.QUALITY_TITLE")}
						/>

						<WhyLocalbini
							description={t("HP.HEADER.SATISFACTION_SUBTITLE")}
							icon={
								<img title="Satisfaction" alt="Satisfaction icon" src={SatisfactionIcon} height="46px" width="38px" />
							}
							title={t("HP.HEADER.SATISFACTION_TITLE")}
						/>

						<WhyLocalbini
							description={t("HP.HEADER.AUTHENTICITY_SUBTITLE")}
							icon={<img title="Authenticity" alt="Authenticity icon" src={AuthenticIcon} height="46px" width="38px" />}
							title={t("HP.HEADER.AUTHENTICITY_TITLE")}
						/>
					</WhyLocalbiniContent>
				</Container>
			</Box>
		</header>
	);
});

export default Header;
