import { memo } from "react";
import styled from "styled-components";

import ContactUsImg from "assets/images/binipoolConcept/contact-us.svg";

import useTranslation from "hooks/useTranslation";

import ContactButton from "ui/buttons/ContactButton";
import CommonParagraph from "ui/CommonParagraph";

import colors from "styles/colors";
import { Container, MontserratFontFamily } from "styles/common";
import media from "styles/media";

export const Wrapper = styled.section`
	margin: 0 auto;
	width: 100%;
	font-family: ${MontserratFontFamily};
	color: ${colors.mainFontColor};

	${media.desktop`
     margin-bottom: 50px;
  `};
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	${media.desktop`
     width: 100%;
     flex-direction: column;
     justify-content: center;
  `};
`;

const Col = styled.div<{ isPaddingLeft?: boolean }>`
	flex: 1;
	${props => props.isPaddingLeft && `padding-left: 20px`};

	${media.tablet`
     padding-left: 0;
  `};
`;

const StyledImg = styled.img`
	margin-bottom: -8px;
	width: 592px;
	height: 302px;

	${media.largeDesktop`
      margin-bottom: 3.125rem;
      width: 100%;
      height: 100%;
  `};
`;

const ContactUs = memo(() => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Container>
				<MainContent>
					<Col>
						<StyledImg src={ContactUsImg} title="Contact us" alt="Contact us" />
					</Col>

					<Col isPaddingLeft>
						<CommonParagraph
							isSecondHeader
							mainTitle={t("BINIPOOL_CONCEPT.CONTACT_US_TITLE")}
							subTitle={t("BINIPOOL_CONCEPT.CONTACT_US_DESC")}
						/>

						<ContactButton translationKey="BINIPOOL_CONCEPT.CONTACT_US_BTN" />
					</Col>
				</MainContent>
			</Container>
		</Wrapper>
	);
});

export default ContactUs;
