import { Avatar } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

const UserAvatar = styled(Avatar)`
	width: 93px;
	height: 93px;
`;

const AvatarSection = styled.div`
	display: flex;
	gap: 25px;
	align-items: center;
	border-bottom: solid 1px #dadada;
	margin-bottom: 15px;
	padding-bottom: 25px;
`;

const AvatarDescription = styled.div`
	font-family: ${RobotoFontFamily};
	line-height: 1.71;
	font-size: 14px;
	letter-spacing: 0.3px;
	color: ${colors.darkGreen};
	word-break: break-word;
`;

const ContactDetails = styled.div`
	font-family: ${MontserratFontFamily};
	color: ${colors.bermudaGray};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.3;
`;

const UserName = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	color: ${colors.mainFontColor};
	margin: 0;
`;

const UserDetails = () => {
	const { t } = useTranslation();

	const loggedUser = useAppSelector(selectUser);

	return !!loggedUser ? (
		<AvatarSection>
			<UserAvatar
				alt="Avatar profile"
				src={loggedUser?.public_profile?.pictures?.profile || loggedUser?.profile_draft?.pictures?.profile || undefined}
			/>

			<AvatarDescription>
				<UserName>
					{loggedUser?.public_profile?.first_name} {loggedUser?.private_profile?.last_name}
				</UserName>

				<ContactDetails>
					{t("USER.SETTINGS.MAIL")}: {loggedUser?.personal?.email}
				</ContactDetails>

				<ContactDetails>
					{t("USER.SETTINGS.MOBILE")}: {loggedUser?.personal?.phone || "-"}
				</ContactDetails>
			</AvatarDescription>
		</AvatarSection>
	) : null;
};

export default UserDetails;
