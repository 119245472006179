import { Box, FormControlLabel, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import { mediaMobileFirst } from "styles/media";

export const MenuElem = styled.div<{ active?: boolean; noClick?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.71;
	color: ${colors.mainFontColor};
	cursor: pointer;
	padding: 0 10px 10px;
	flex-grow: 1;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.noClick && "cursor: unset;"}

	${mediaMobileFirst.tabletMaterial`
      font-size: 16px;
      line-height: 1.5;
      width: 100%;
      justify-content: flex-start;
      padding: 12px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        path {
          fill: ${colors.mainFontColor};
        }
      }
  `}

  ${props =>
		props.active &&
		css`
			color: ${colors.sunShade};

			${mediaMobileFirst.tabletMaterial`
         border-radius: 6px;
         background-color: #b0bec51a;

         svg path {
          fill: ${colors.sunShade};
         }
      `}

			&::after {
				content: "";
				position: absolute;
				height: 1px;
				width: 100%;
				background-color: ${colors.sunShade};
				bottom: -1px;
				left: 0;

				${mediaMobileFirst.tabletMaterial`
           display: none;
        `}
			}
		`}
`;

export const SwitchContainer = styled(Grid)`
	justify-content: flex-end;
	height: fit-content;
	width: fit-content;
	position: relative;
	top: -2px;
	font-family: ${RobotoFontFamily};

	${mediaMobileFirst.tabletMaterial`
      top: 2px;
   `}
`;

export const CategoriesFilters = styled.div`
	background-color: #b0bec51a;
	padding: 10px 20px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	${mediaMobileFirst.tabletMaterial`
     background: none;
   `}
`;

export const StyledArrow = styled(KeyboardArrowDownIcon)<{ $isOpened: boolean }>`
	cursor: pointer;
	color: ${colors.mainFontColor};
	margin-left: 4px;

	${props =>
		props.$isOpened &&
		css`
			transform: rotate(180deg);
		`}
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;

	.MuiIconButton-label,
	.MuiFormControlLabel-label {
		color: #546e7a;
	}

	.MuiFormControlLabel-label {
		font-size: 12px;
	}
`;

export const FilterButtons = styled(Box)<{ $active?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	line-height: 43px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 8px;
	border: solid 1px #979797;

	${props =>
		props.$active &&
		css`
			color: ${colors.white};
			background-color: ${colors.sunShade};
			border: none;
		`}
`;
