import { Form } from "formik";
import { rgba } from "polished";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import Input from "../formik/Input";

export const RateTextarea = styled(Input)`
	width: 100%;
	margin-bottom: 20px;

	.MuiTextField-root,
	.MuiInputBase-input {
		width: 100%;
		min-height: 50px;
	}
`;

export const FormikForm = styled(Form)`
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
`;

export const NodeLabel = styled.div`
	font-size: 14px;
	line-height: 1.67;
	font-family: ${RobotoFontFamily};
	letter-spacing: normal;
	margin-bottom: 10px;
	font-weight: 500;
	color: ${rgba(colors.mainFontColor, 0.87)};
	white-space: pre-line;

	strong {
		font-weight: bold;
	}

	small {
		font-size: inherit;
		display: block;
	}
`;
