import { DeepPartial } from "data/types";
import { EUserContactStatus, EUserType, IUser, IUserInternal, TUserIId } from "data/users/types";

import API, { APISingleData } from "./index";
import { IUserGetMediaUrlQueryParams } from "./types";
import { IFetchByIdQueryParams, IPostCreateInternalCommentBody, IPostInviteNewUserBody } from "./types/users";
import { generateEntityEndpoint } from "./utils/createCRUDAPI";

const namespace = "internal";
const api = "/users";

// @todo:chore - change IUser to TUserIId (if applicable to a user with IId)
const usersService = {
	fetchById: (userId: string, queryParams?: IFetchByIdQueryParams) =>
		API.get<TUserIId>(namespace, `${api}/${userId}`, {
			queryStringParameters: queryParams,
		}),
	updateById: (userId: string, data: DeepPartial<IUser>) =>
		API.patch<TUserIId>(namespace, generateEntityEndpoint(api, userId), {
			body: data,
		}),
	patchPartialUpdate: (userId: string, data: DeepPartial<IUser>) =>
		API.patch<TUserIId>(namespace, generateEntityEndpoint(api, userId), {
			body: data,
		}),

	getAllUsers: queryParams =>
		APISingleData.get<{ data: TUserIId[]; count: number }>(namespace, api, {
			queryStringParameters: queryParams,
		}),

	getUserMediaUrl: (userId: string, queryParams: IUserGetMediaUrlQueryParams): Promise<any> =>
		API.get(namespace, `${generateEntityEndpoint(api, userId)}/media/upload-url`, {
			queryStringParameters: queryParams,
		}),
	deleteUserMedia: (userId: string, mediaId: string) =>
		API.del(namespace, `${generateEntityEndpoint(api, userId)}/media/${mediaId}`),

	syncUserPhone: (userId: string): Promise<any> => API.post(namespace, `${api}/${userId}/sync-phone`, {}),
	syncUserMFA: (userId: string): Promise<any> => API.post(namespace, `${api}/${userId}/sync-mfa`, {}),
	deleteAccount: (userId: string, token?: string) =>
		API.del(namespace, generateEntityEndpoint(api, userId), {
			queryStringParameters: token ? { token } : undefined,
		}),

	putRecordContact: (userId: string, status: Omit<EUserContactStatus, EUserContactStatus.NO_CONTACT>): Promise<any> =>
		API.put<IUserInternal["communication"]>(namespace, `${api}/${userId}/communication`, {
			body: {
				contact_status: status,
			},
		}),
	postCreateInternalComment: (userId: string, body: IPostCreateInternalCommentBody) =>
		API.post<TUserIId>(namespace, `${api}/${userId}/internal-comments`, { body }),
	deleteInternalComment: (userId: string, commentId: string) =>
		API.del(namespace, `${api}/${userId}/internal-comments/${commentId}`),

	changeUserType: (userId: string, userType: EUserType): Promise<any> =>
		API.patch(namespace, `${api}/${userId}/user-type`, {
			body: { user_type: userType },
		}),
	postInvite: (body: IPostInviteNewUserBody) =>
		API.post<TUserIId>(namespace, api, {
			body,
		}),
	postResetPassword: (userId: string) =>
		APISingleData.post<{ password: string }>(namespace, `${api}/${userId}/reset-password`),
	postDeactivateMFA: (userId: string) =>
		APISingleData.post<{ message: string }>(namespace, `${api}/${userId}/deactivate-mfa`),
};

export default usersService;
