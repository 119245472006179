import useTranslation from "hooks/useTranslation";

import TooltipIcon from "ui/tooltips/TooltipIcon";

import { DescriptionContent, IconContent, Title, TooltipContent } from "../shared.styled";

const LanguageDefaultExp = () => {
	const { t } = useTranslation();

	return (
		<TooltipContent>
			<IconContent>
				<TooltipIcon iconType="success" />
			</IconContent>

			<DescriptionContent>
				<Title isMarginBottom>{t("EXPERIENCE.TOOLTIP.BASIC_INFO.EXP2.LOCATION")}</Title>
			</DescriptionContent>
		</TooltipContent>
	);
};

export default LanguageDefaultExp;
