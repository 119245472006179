import { memo, ReactElement } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { SmallerTitle } from "ui/CommonParagraph";

import colors from "styles/colors";
import { Container, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

interface IQAndAItemProps {
	question: string | ReactElement;
	answer: string | ReactElement;
}

const Background = styled.div`
	width: 100%;
	padding: 5rem 0 1rem 0;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    padding: 6rem 0 1rem 0;
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const QAMainContent = styled.div`
	width: 100%;
	margin: 5rem auto;
	display: flex;
	flex-direction: row;

	${media.desktop`
    flex-direction: column;
    margin: 2rem auto;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const QAndAItemWrapper = styled.div`
	max-width: 33.75rem;
	margin-bottom: 1.875rem;

	${media.desktop`
     max-width: 100%;
  `};
`;

const QAndAItemTitle = styled.h3`
	font-size: 18px;
	font-weight: bold;
	line-height: 1.44;
	letter-spacing: 0.04px;
`;

const QAndAItemDesc = styled.div`
	opacity: 0.9;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.86;
	letter-spacing: 0.03px;
`;

const QAndAItem = memo(({ answer, question }: IQAndAItemProps) => (
	<QAndAItemWrapper>
		<QAndAItemTitle>{question}</QAndAItemTitle>

		<QAndAItemDesc>{answer}</QAndAItemDesc>
	</QAndAItemWrapper>
));

const QuestionsAndAnswers = memo(() => {
	const { t, withComponents } = useTranslation();

	return (
		<WrapperSection>
			<Background>
				<Container>
					<SmallerTitle textAlignCenter>{t("BINIPOOL_CONCEPT.TITLE_Q_A")}</SmallerTitle>

					<QAMainContent>
						<Col>
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_1")} answer={t("BINIPOOL_CONCEPT.ANSWER_1")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_2")} answer={t("BINIPOOL_CONCEPT.ANSWER_2")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_3")} answer={t("BINIPOOL_CONCEPT.ANSWER_3")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_5")} answer={t("BINIPOOL_CONCEPT.ANSWER_5")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_6")} answer={t("BINIPOOL_CONCEPT.ANSWER_6")} />
						</Col>

						<Col>
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_7")} answer={t("BINIPOOL_CONCEPT.ANSWER_7")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_8")} answer={t("BINIPOOL_CONCEPT.ANSWER_8")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_9")} answer={t("BINIPOOL_CONCEPT.ANSWER_9")} />
							<QAndAItem question={t("BINIPOOL_CONCEPT.QUESTION_10")} answer={t("BINIPOOL_CONCEPT.ANSWER_10")} />
							<QAndAItem
								question={t("BINIPOOL_CONCEPT.QUESTION_11")}
								answer={withComponents(
									"BINIPOOL_CONCEPT.ANSWER_11",
									{ Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} /> },
									null,
								)}
							/>
						</Col>
					</QAMainContent>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default QuestionsAndAnswers;
