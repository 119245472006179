import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import localBiniExperienceCancellationExampleImg from "assets/images/help/cancellationPolicy/localbini-experience-cancellation-info.png";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CarouselSwiper from "ui/CarouselSwiper";
import CommonParagraph from "ui/CommonParagraph";
import HelpSection from "ui/HelpSection";

import colors from "styles/colors";
import { Container, StyledExternalLink, StyledLink } from "styles/common";

const swiperSettings = {
	lazy: true,
	slidesPerView: 1,
	navigation: false,
	pagination: false,
	allowSlidePrev: false,
	allowSlideNext: false,
};

const CancellationPolicyPage = () => {
	const { t, withComponents, withRaw } = useTranslation();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const links = {
		Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
		NormalLink: <StyledExternalLink color={colors.sunShade} href="tel:+41715087013" />,
	};

	const box = <Box marginTop="40px" />;

	const image = (
		<CarouselSwiper
			images={[localBiniExperienceCancellationExampleImg]}
			heightSlide={isMobile ? "233px" : "697px"}
			slides={[
				<Box border="1px solid black" borderRadius="4px" height="100%" key="localBiniExperienceCancellationExampleImg">
					<div
						style={{
							backgroundImage: `url(${localBiniExperienceCancellationExampleImg})`,
							width: "100%",
							height: "100%",
							backgroundSize: "contain",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							cursor: "pointer",
						}}
					/>
					<Box fontStyle="italic" textAlign="center">
						{t("HELP.SECTION_1.IMAGE_DESCRIPTION")}
					</Box>
				</Box>,
			]}
			settings={swiperSettings}
			hidePrevNextBtn
		/>
	);

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />

				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.CANCELLATION.TITLE")} />

				<CommonParagraph
					withMarginTop
					mainTitle={t("HELP.CANCELLATION.TITLE")}
					subTitle={withRaw("HELP.CANCELLATION.UPDATED_AT", null)}
				/>

				<HelpSection
					title={t("HELP.SECTION_1.TITLE")}
					descriptionLeft={withComponents("HELP.SECTION_1.DESCRIPTION", { ...links, Box: box, Image: image }, null)}
				/>

				<HelpSection
					id="localhost-cancellation-policy"
					title={t("HELP.SECTION_2.TITLE")}
					descriptionLeft={withComponents("HELP.SECTION_2.DESCRIPTION", links, null)}
				/>

				<HelpSection
					title={t("HELP.SECTION_3.TITLE")}
					descriptionLeft={withComponents("HELP.SECTION_3.DESCRIPTION", links, null)}
				/>
			</Container>
		</AppWrapper>
	);
};

export default CancellationPolicyPage;
