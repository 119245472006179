import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";
import styled from "styled-components";

import JennyImgSmall from "assets/images/uniqueExperiences/jenny.png";
import JennyImgMedium from "assets/images/uniqueExperiences/jenny@2x.png";
import MatteoImgSmall from "assets/images/uniqueExperiences/matteo.png";
import MatteoImgMedium from "assets/images/uniqueExperiences/matteo@2x.png";
import BackgroundDesktop from "assets/images/uniqueExperiences/path_desktop.svg";
import BackgroundMobile from "assets/images/uniqueExperiences/path_mobile.svg";

import useTranslation from "hooks/useTranslation";

import { SmallerTitle } from "ui/CommonParagraph";

import { Container, MontserratFontFamily } from "styles/common";
import media from "styles/media";

interface ILocalResponsiveImgProps {
	altDesc: string;
	imgSmall: string;
	imgMedium: string;
	widthValue: string;
	heightValue: string;
}

const Wrapper = styled.section`
	margin: 50px auto -50px;
	width: 100%;
	font-family: ${MontserratFontFamily};

	${media.largeDesktop`
    margin: 0 auto 30px;
  `};
`;

const DescriptionText = styled.p`
	margin: 0 auto;
	font-family: ${MontserratFontFamily};
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.03px;
	text-align: center;

	${media.desktop`
    font-size: 12px;
  `};
`;

const InnerWrapperMobile = styled.div`
	margin: 0 auto;
	position: relative;
	width: 330px;
`;

const InnerWrapperTablet = styled.div`
	margin: 150px auto 150px;
	position: relative;
	width: 700px;
`;

const InnerWrapperDesktop = styled.div`
	position: relative;
	width: 100%;
`;

const PathContentMobile = styled.div`
	height: 2000px;
	width: 100%;
	padding-top: 600px;
	background-image: url(${BackgroundMobile});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
`;

const PathContentTablet = styled(Box)`
	margin: 0 auto;
	height: 2100px;
	width: 100%;
	padding-top: 425px;
	padding-bottom: 100%;
	background-image: url(${BackgroundMobile});
	background-repeat: no-repeat;
	background-position: top;
	background-size: contain;
`;

const PathContentDesktop = styled(Box)`
	height: 1035px;
	padding: 110px 0 150px 0;
	position: relative;
	background-image: url(${BackgroundDesktop});
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
`;

const MatteoImgWrapper = styled.div`
	position: absolute;
	top: -130px;
	left: 15px;
	z-index: -10;

	${media.largeDesktop`
    top: -120px;
    left: 130px;
  `};

	${media.desktop`
    top: 60px;
    left: 0;
    right: 0;
    padding: 10px;
  `};
`;

const JennyImgWrapper = styled.div`
	position: absolute;
	bottom: 85px;
	right: 0;
	z-index: -10;

	${media.largeDesktop`
    bottom: -130px;
    left: 130px;
    z-index: 10;
  `};

	${media.desktop`
    left: 12px;
    bottom: 65px;
  `};
`;

const LocalResponsiveImg = ({ altDesc, imgSmall, imgMedium, widthValue, heightValue }: ILocalResponsiveImgProps) => (
	<img
		srcSet={`${imgSmall} 1x, ${imgMedium} 1.5x`}
		src={imgMedium}
		alt={altDesc}
		title={altDesc}
		width={widthValue}
		height={heightValue}
	/>
);

const HowItWorks = memo(() => {
	const { withRaw } = useTranslation();
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

	const renderMobile = () => (
		<InnerWrapperMobile>
			<MatteoImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Matteo - architect - Rome - picture"
					imgSmall={MatteoImgSmall}
					imgMedium={MatteoImgMedium}
					widthValue="90%"
					heightValue="auto"
				/>
			</MatteoImgWrapper>

			<PathContentMobile>
				<Box height="120px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_1", null)}</DescriptionText>
				</Box>

				<Box height="125px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_2", null)}</DescriptionText>
				</Box>

				<Box height="140px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_3", null)}</DescriptionText>
				</Box>

				<Box height="120px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_4", null)}</DescriptionText>
				</Box>

				<Box height="120px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_5", null)}</DescriptionText>
				</Box>

				<Box height="130px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_6", null)}</DescriptionText>
				</Box>

				<Box height="115px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_7", null)}</DescriptionText>
				</Box>

				<Box height="auto">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_8", null)}</DescriptionText>
				</Box>
			</PathContentMobile>

			<JennyImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Jenny - traveler - picture"
					imgSmall={JennyImgSmall}
					imgMedium={JennyImgMedium}
					widthValue="90%"
					heightValue="auto"
				/>
			</JennyImgWrapper>
		</InnerWrapperMobile>
	);

	const renderTablet = () => (
		<InnerWrapperTablet>
			<MatteoImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Matteo - architect - Rome - picture"
					imgSmall={MatteoImgSmall}
					imgMedium={MatteoImgMedium}
					widthValue="309px"
					heightValue="439px"
				/>
			</MatteoImgWrapper>

			<PathContentTablet>
				<Box height="185px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_1", null)}</DescriptionText>
				</Box>

				<Box height="205px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_2", null)}</DescriptionText>
				</Box>

				<Box height="220px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_3", null)}</DescriptionText>
				</Box>

				<Box height="185px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_4", null)}</DescriptionText>
				</Box>

				<Box height="180px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_5", null)}</DescriptionText>
				</Box>

				<Box height="205px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_6", null)}</DescriptionText>
				</Box>

				<Box height="165px">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_7", null)}</DescriptionText>
				</Box>

				<Box height="auto">
					<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_8", null)}</DescriptionText>
				</Box>
			</PathContentTablet>

			<JennyImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Jenny - traveler - picture"
					imgSmall={JennyImgSmall}
					imgMedium={JennyImgMedium}
					widthValue="339px"
					heightValue="404px"
				/>
			</JennyImgWrapper>
		</InnerWrapperTablet>
	);

	const renderDesktop = () => (
		<InnerWrapperDesktop>
			<MatteoImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Matteo - architect - Rome - picture"
					imgSmall={MatteoImgSmall}
					imgMedium={MatteoImgMedium}
					widthValue="434px"
					heightValue="586px"
				/>
			</MatteoImgWrapper>

			<PathContentDesktop>
				<Box position="relative" width="100%" height="190px">
					<Box position="absolute" top="35px" left="588px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_1", null)}</DescriptionText>
					</Box>

					<Box position="absolute" top="58px" right="100px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_2", null)}</DescriptionText>
					</Box>
				</Box>

				<Box position="relative" width="100%" height="190px">
					<Box position="absolute" top="106px" left="540px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_4", null)}</DescriptionText>
					</Box>

					<Box position="absolute" top="56px" right="30px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_3", null)}</DescriptionText>
					</Box>
				</Box>

				<Box position="relative" width="100%" height="150px">
					<Box position="absolute" top="106px" left="340px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_6", null)}</DescriptionText>
					</Box>

					<Box position="absolute" top="90px" right="390px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_5", null)}</DescriptionText>
					</Box>
				</Box>

				<Box position="relative" width="100%" height="245px">
					<Box position="absolute" top="105px" left="50px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_7", null)}</DescriptionText>
					</Box>

					<Box position="absolute" top="185px" right="500px">
						<DescriptionText>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_STEP_8", null)}</DescriptionText>
					</Box>
				</Box>
			</PathContentDesktop>

			<JennyImgWrapper>
				<LocalResponsiveImg
					altDesc="Unique experiences - Jenny - traveler - picture"
					imgSmall={JennyImgSmall}
					imgMedium={JennyImgMedium}
					widthValue="404px"
					heightValue="486px"
				/>
			</JennyImgWrapper>
		</InnerWrapperDesktop>
	);

	const renderMainContent = () => {
		if (isMobileScreen) {
			return renderMobile();
		} else if (isTabletScreen) {
			return renderTablet();
		} else {
			return renderDesktop();
		}
	};

	return (
		<Wrapper>
			<Container>
				<SmallerTitle textAlignCenter>{withRaw("UNIQUE_EXPERIENCES.HOW_IT_WORKS_TITLE")}</SmallerTitle>

				{renderMainContent()}
			</Container>
		</Wrapper>
	);
});

export default HowItWorks;
