import { Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { IFinancialsAddress, IFinancialsBankingDetails } from "data/financials/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import financialsService, { errorCodes } from "services/api/financials";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCity from "store/hooks/useCity";
import useCountry from "store/hooks/useCountry";
import { selectUser } from "store/selectors/user";

import { GlobOutlinedIcon, LocationOnOutlinedIcon } from "components/backoffice/shared.styled";
import Autocomplete from "components/formik/Autocomplete";
import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";

import { FormSection } from "../shared.styled";

const FooterContent = styled.div`
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #dadada;
	margin-top: 40px;
	padding-top: 10px;
`;

const ChangeFinancials = () => {
	const { t } = useTranslation();

	const { success } = useNotification();
	const { handleAndNotify } = useErrors();

	const { citiesSelectSorted } = useCity();
	const { countriesSelectSorted } = useCountry();

	const loggedUser = useAppSelector(selectUser);

	const [userFinancials, setUserFinancials] = useState<IFinancialsBankingDetails>();
	const [update, setUpdate] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			try {
				if (!!loggedUser?.id) {
					const apiData = await financialsService.getBankingDetails(loggedUser?.id);

					setUserFinancials(apiData);

					setUpdate(true);
				}
			} catch (e) {
				// @ts-ignore
				if (e?.getError()?.error_code !== errorCodes.getBankingDetails.missingBankingDetails) {
					handleAndNotify(e);
				}

				setUpdate(false);
			}
		})();

		// eslint-disable-next-line
	}, [loggedUser?.id]);

	const formikProps = useFormik<IFinancialsAddress>({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			street: Yup.string().required(t("ERRORS.REQUIRED")),
			zip_code: Yup.string().required(t("ERRORS.REQUIRED")),
			city: Yup.string().required(t("ERRORS.REQUIRED")),
			country: Yup.string().required(t("ERRORS.REQUIRED")),
		}),
		initialValues: {
			street: userFinancials?.user_address?.street || "",
			zip_code: userFinancials?.user_address?.zip_code || "",
			city: userFinancials?.user_address?.city || "",
			country: userFinancials?.user_address?.country || "",
		},
		onSubmit: async values => {
			const data = {
				user_address: values,
			};

			try {
				if (update) {
					const result = await financialsService.patchBankingDetails(loggedUser?.id || "", data);

					setUserFinancials(result);
				} else {
					const result = await financialsService.postBankingDetails({ ...data, uid: loggedUser?.id });

					setUserFinancials(result);
				}

				success();

				setUpdate(true);

				formikProps.resetForm();
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	return (
		<FormSection>
			<FormikProvider value={formikProps}>
				<Form>
					<Grid container spacing={10}>
						<Grid item md={3} xs={12}>
							<Autocomplete
								name="country"
								label={t("USER.SETTINGS.COUNTRY")}
								options={countriesSelectSorted}
								textFieldProps={{
									InputProps: { startAdornment: <GlobOutlinedIcon /> },
									required: true,
								}}
								inputProps={{ autoComplete: "none" }}
								filterSelectedOptions
							/>
						</Grid>

						<Grid item md={5} xs={12}>
							<Input
								required
								name="street"
								label={t("USER.SETTINGS.STREET_ADDRESS")}
								inputClassName="full"
								maxLength={100}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Input
								required
								name="zip_code"
								label={t("USER.SETTINGS.POST_CODE")}
								inputClassName="full"
								maxLength={20}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Autocomplete
								name="city"
								label={t("USER.SETTINGS.CITY")}
								options={citiesSelectSorted}
								textFieldProps={{
									InputProps: { startAdornment: <LocationOnOutlinedIcon /> },
									required: true,
								}}
								inputProps={{ autoComplete: "none" }}
								freeSolo
								filterSelectedOptions
							/>
						</Grid>
					</Grid>

					<FooterContent>
						<ActionButton
							translationDefault={"SETTINGS.SAVE_CHANGES"}
							isAction={formikProps.isSubmitting}
							onClick={formikProps.handleSubmit}
							size="large"
						/>
					</FooterContent>
				</Form>
			</FormikProvider>
		</FormSection>
	);
};

export default ChangeFinancials;
