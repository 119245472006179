import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPartner } from "data/backoffice/partners/types";

import partnersService from "services/api/backoffice/partners";

import { handleRejectValues } from "store/utils/handleRejectValues";

export interface IPartnersState {
	data: IPartner[] | null;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IPartnersState = {
	data: null,
	status: "idle",
	error: null,
};

export const fetchPartners = handleRejectValues("partners/fetch", partnersService.getListOfPartners);

const { actions, reducer } = createSlice({
	name: "partners",
	initialState,
	reducers: {
		reset() {
			return initialState;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchPartners.fulfilled, (state, action: PayloadAction<IPartner[]>) => {
				state.data = action.payload;
				state.status = "succeeded";
				state.error = null;
			})
			.addCase(fetchPartners.pending, state => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(fetchPartners.rejected, state => {
				state.status = "failed";
				state.error = true;
			});
	},
});

export const partnersActions = actions;

export default reducer;
