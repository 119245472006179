import { Box, Button, FormControlLabel, TextField } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import styled, { css } from "styled-components";

import AdvertisementImg from "assets/images/background/search_page_image.jpg";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const LabelCommonStyles = css`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	letter-spacing: 0.38px;
	color: ${colors.mainFontColor};
`;

export const MoreFiltersWrapper = styled.div`
	height: auto;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	border-radius: 0 0 0 ${dimensions.radius.small};
	background: ${colors.white};
	box-shadow: rgb(55 71 79 / 11%) 0 10px 11px 0;
	padding: ${dimensions.spaces.xlarge} ${dimensions.spaces.large};
	box-sizing: border-box;
	position: relative;

	${media.desktop`
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 120px;
  `};

	${media.tablet`
    border-radius: 0 0 0 ${dimensions.radius.small};
  `};
`;

export const Item = styled(Box)<{ $isPaddingLeft?: boolean }>`
	flex: 1;

	${props => props.$isPaddingLeft && "padding-left: 20px;"}

	${media.desktop`
    width: 100%;
    padding-left: 0;
  `};
`;

export const TitleWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.desktop`
    margin-top: 10px;
  `};
`;

export const Title = styled.h1`
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.44px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;

	${media.desktop`
    margin-bottom: 10px;
  `};
`;

export const InputLabel = styled.span`
	${LabelCommonStyles};
`;

export const PriceWrapper = styled.div`
	margin: 10px 0 30px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const StyledTextField = styled(TextField)<{
	$customWidth?: string;
	$customMargin?: string;
	$fontBold?: boolean;
}>`
	height: 30px;

	margin: ${props => (props.$customMargin ? props.$customMargin : "auto")};
	width: ${props => (props.$customWidth ? props.$customWidth : "100%")};

	input {
		padding: 10px;

		${LabelCommonStyles};

		${props =>
			props.$fontBold &&
			css`
				font-weight: bold;
				padding: 10px 0;
				text-decoration: underline;
			`};
	}
`;

export const EndAdornment = styled.span`
	position: absolute;
	left: 115px;
	${LabelCommonStyles};
`;

export const StartAdornment = styled.span`
	width: 130px;
	${LabelCommonStyles};
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;

	.MuiTypography-body1 {
		${LabelCommonStyles};
	}
`;

export const ClearButton = styled(Button)`
	margin-top: 20px;
	color: #90a4ae;
	font-size: 12px;
	font-weight: 500;
	text-decoration: underline;
`;

export const ClearButtonContent = styled(Box)`
	position: absolute;
	bottom: 15px;
	left: 15px;
`;

export const ShowMoreButton = styled.div<{ isHide: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	top: ${props => (props.isHide ? "initial" : "-5px")};
	left: calc(50%);
	transform: translateX(-50%);
	min-width: 170px;
	padding-left: 10px;
	padding-right: 10px;
	height: 40px;
	border-radius: ${dimensions.radius.small};
	box-shadow: 0 0 8px 0 rgb(55 71 79 / 17%);
	border: none;
	background: #fff;
	font-size: 13px;
	font-weight: 500;
	color: ${colors.mainFontColor};
	z-index: 100;
	${props => props.isHide && "bottom: -20px;"}

	${media.tablet`
    top: -60px;
    left: 10px;
    transform: none;
   `}

  ${props =>
		props.isHide &&
		css`
			${media.tablet`
      top: initial;
      left: calc(50% - 85px);
      bottom: -35px;
   `}
		`};
`;

export const AdvertisementContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background-image: url(${AdvertisementImg});
	position: relative;
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	width: 450px;
	border-radius: 0 0 ${dimensions.radius.small} 0;
`;

export const AdvertisementText = styled.div`
	margin-bottom: 20px;
	font-family: ${MontserratFontFamily};
	font-size: 26px;
	font-weight: bold;
	line-height: 1.19;
	letter-spacing: 0.05px;
	text-align: right;
	color: ${colors.white};
`;

export const LetsStartedButton = styled(Button)`
	width: 160px;
	height: 42px;
`;

export const ArrowContent = styled.div`
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const ExpandLessIcon = styled(ExpandLess)<{ $isShowLess: boolean }>`
	width: 40px;
	height: 30px;

	${props => props.$isShowLess && "transform: rotate(180deg);"}
`;
