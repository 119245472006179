import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TFinancialIId } from "data/backoffice/financials/types";

import financialsServices from "services/api/financials";

export interface IFinancialsBackofficeState {
	data: {
		list: TFinancialIId[] | null;
		count: number | null;
	};
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IFinancialsBackofficeState = {
	data: {
		list: null,
		count: null,
	},
	status: "idle",
	error: null,
};

export const fetchFinancialsBackoffice = createAsyncThunk<any, any>(
	"financialsBackoffice/fetch",
	async (queryParams = {}, thunkAPI) => {
		try {
			const financialsList = await financialsServices.getSearchFinancials(queryParams);

			return financialsList;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const { actions, reducer } = createSlice({
	name: "financialsBackoffice",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: {
		[fetchFinancialsBackoffice.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.list = action.payload.data;
			state.data.count = action.payload.count;
			state.status = "succeeded";
			state.error = null;
		},
		[fetchFinancialsBackoffice.pending.toString()]: (state, action: PayloadAction<any>) => {
			state.status = "loading";
			state.error = null;
		},
		[fetchFinancialsBackoffice.rejected.toString()]: (state, action: PayloadAction<any>) => {
			state.status = "failed";
			state.error = true;
		},
	},
});

export const financialsBackofficeActions = actions;

export default reducer;
