import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import {
	DirectionsOutlined,
	Language,
	LocationOnOutlined,
	QueryBuilderOutlined,
	StarBorderOutlined,
} from "@material-ui/icons";
import styled, { css } from "styled-components";

import { EExperienceStatus, IRatings } from "data/experiences/types";

import ExperienceStatusLabel from "ui/statuses/ExperienceStatusLabel";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

import ChipStringArray from "../../../ChipStringArray";

export const DetailsBannerWrapper = styled(Box)<{ $withoutVerticalPadding?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	padding: ${props => (props.$withoutVerticalPadding ? 0 : "14px")} 20px;
	border-radius: 4px;
	box-shadow: 0 0 30px 0 rgba(206, 206, 206, 0.81);

	${media.largeDesktop`
      padding: 14px 20px;
  `};

	${media.tablet`
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  `};
`;

const Item = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ItemLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.1px;
	color: rgba(0, 0, 0, 0.87);
`;

const IconsCommonStyles = css`
	margin-right: 10px;
	font-size: 26px;
	color: ${colors.sunShade};
`;

const StyledLocationOnOutlined = styled(LocationOnOutlined)`
	${IconsCommonStyles}
`;

const StyledDirectionsOutlined = styled(DirectionsOutlined)`
	${IconsCommonStyles}
`;

const StyledQueryBuilderOutlined = styled(QueryBuilderOutlined)`
	${IconsCommonStyles}
`;

const StyledStarBorderOutlined = styled(StarBorderOutlined)`
	${IconsCommonStyles}
`;

const StyledLanguage = styled(Language)`
	${IconsCommonStyles}
`;

interface IDetailsBannerProps {
	includingArray: string[];
	city?: string;
	languages?: string;
	activityType?: string;
	durationMin?: string;
	rating?: IRatings;
	status?: EExperienceStatus;
	withoutVerticalPadding?: boolean;
}

const DetailsBanner = ({
	city,
	languages,
	includingArray,
	activityType,
	durationMin,
	rating,
	status,
	withoutVerticalPadding,
}: IDetailsBannerProps) => {
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

	const marginLeftElement = 18;

	return (
		<DetailsBannerWrapper $withoutVerticalPadding={withoutVerticalPadding}>
			<Box display="flex" flexDirection={isMediumScreen ? "column" : "row"}>
				<Box display="flex" flexWrap="wrap">
					{city && (
						<Item mr={marginLeftElement}>
							<StyledLocationOnOutlined />

							<ItemLabel>{city}</ItemLabel>
						</Item>
					)}

					{languages && (
						<Item mr={marginLeftElement}>
							<StyledLanguage />

							<ItemLabel>{languages}</ItemLabel>
						</Item>
					)}

					{activityType && (
						<Item mr={marginLeftElement}>
							<StyledDirectionsOutlined />

							<ItemLabel>{activityType}</ItemLabel>
						</Item>
					)}

					{durationMin && (
						<Item mr={marginLeftElement}>
							<StyledQueryBuilderOutlined />

							<ItemLabel>{durationMin}</ItemLabel>
						</Item>
					)}

					{rating && rating.count > 0 && (
						<Item mr={marginLeftElement}>
							<StyledStarBorderOutlined />

							<ItemLabel>
								{rating?.average} ({rating.count})
							</ItemLabel>
						</Item>
					)}
				</Box>

				<Box display="flex" mr={marginLeftElement}>
					<ChipStringArray valuesArray={includingArray} />
				</Box>
			</Box>

			{status && <ExperienceStatusLabel type={status} />}
		</DetailsBannerWrapper>
	);
};

export default DetailsBanner;
