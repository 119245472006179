import {
	Avatar,
	Badge,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	Popover,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import {
	AccountCircleOutlined,
	ArrowDropDownCircleOutlined,
	Close as CloseIcon,
	Menu as Hamburgermenu,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import styled from "styled-components";

import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { MY_SETTINGS_TABS, paths } from "routing/paths";

import { signOut } from "services/aws/auth";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectNotifications } from "store/selectors/notifications";
import { selectUser } from "store/selectors/user";
import { fetchUnreadNotifications } from "store/slices/notifications";

import { oneMinuteInSeconds } from "utils/dates";
import fillRoute from "utils/routes";

import Logo from "ui/Logo";

import colors from "styles/colors";
import { CloseIconBtn, Container, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import ForgotPasswordView from "../../auth/ForgotPasswordView";
import LoginView from "../../auth/LoginView";
import RegisterView from "../../auth/RegisterView";
import MenuPopup from "./components/MenuPopup";
import NotificationsSimple from "./components/NotificationsSimple";

type TDialog = "LOGIN" | "REGISTER" | "FORGOT_PSWD";

interface ITopBar {
	isHomePage?: boolean;
}

const NavBar = styled.nav<{ lightMode?: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 6rem;
	width: 100%;
	background: ${props => (props.lightMode ? "none" : colors.white)};
	color: ${props => (props.lightMode ? colors.white : colors.mainFontColor)};
	box-shadow: ${props =>
		props.lightMode ? "none" : "2px 0 15px 0 rgba(0, 0, 0, 0.29), -1px 0 0 0 rgba(0, 0, 0, 0.52)"};
	z-index: 1000;

	${media.phone`
    height: 5rem;
  `};
`;

const NavContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
`;

const LinkStyle = styled(Link)`
	color: inherit;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const LinkLabelStyle = styled.span`
	font-size: ${dimensions.fontSize.large};
	font-weight: 500;
	line-height: 1.57;
	letter-spacing: 0.32px;
	text-transform: uppercase;
	cursor: pointer;
`;

const LogoContent = styled.div`
	flex: 2;
	gap: 15px;
	display: inline-flex;

	${media.largeDesktop`
    flex: 1;
  `};

	${media.tablet`
    flex: 2;
  `};
`;

const LogoLink = styled(Link)`
	display: inline-flex;
	align-items: center;
	gap: 20px;
	text-decoration: none;
	position: relative;
`;

const LinksContent = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
`;

const NameAvatarContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const AvatarImg = styled(Avatar)`
	height: 2rem;
	width: 2rem;
	border: 0.125rem solid ${colors.white};

	${media.tablet`
    margin-left: 0;
  `};
`;

const PartnerLogoImage = styled.img`
	height: 34px;
`;

// @todo:backend - add favorite button
// const FavoriteIcon = styled(FavoriteBorder)<{ lightMode?: boolean }>`
//   color: ${props => (props.lightMode ? colors.white : colors.mainFontColor)};
//   font-size: 1.25rem;
// `;

const NavItem = styled.div<{ isMenuIcon?: boolean; pointer?: boolean }>`
	margin-left: ${props => (props.isMenuIcon ? "8px" : "20px")};

	${props => props.pointer && "cursor: pointer;"}
`;

const MenuIcon = styled(Hamburgermenu)<{ $lightMode?: boolean }>`
	color: ${props => (props.$lightMode ? colors.white : colors.mainFontColor)};
`;

const UpButton = styled.div`
	width: 130px;
	height: 36px;
	display: flex;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	background: ${colors.white};
	border-radius: 4px;
	border: 1px solid ${colors.oceanDrive};
`;

const ScrollToTopWrapper = styled.div`
	div {
		z-index: 1300;
		right: 15px !important;
		bottom: 60px !important;
	}
`;

const StyledArrowUpward = styled(ArrowDropDownCircleOutlined)`
	margin-right: 10px;
	width: 20px;
	height: 20px;
	fill: ${colors.mainFontColor};
	transform: rotate(180deg);
`;

const StyledDialog = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		min-width: 400px;

		${media.tablet`
       min-width: auto;
     `};
	}
`;

const StyledPopover = styled(Popover)`
	.MuiPopover-paper {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	}
`;

const DialogForNotifications = styled(Dialog)`
	.MuiDialog-paperScrollPaper {
		display: block;
	}
`;

const TopBar = ({ isHomePage = false }: ITopBar) => {
	const { t } = useTranslation();

	const history = useHistory();
	const location = useLocation();

	const dispatch = useAppDispatch();

	const userData = useAppSelector(selectUser);
	const notificationsGlobal = useAppSelector(selectNotifications);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorForPopover, setAnchorForPopover] = useState<null | HTMLElement>(null);
	const [typeOfDialog, setTypeOfDialog] = useState<TDialog>("LOGIN");
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	useEffect(() => {
		const handleClosePopover = () => setAnchorForPopover(null);

		if (anchorForPopover) {
			document.addEventListener("scroll", handleClosePopover);
		}

		return () => document.removeEventListener("scroll", handleClosePopover);

		// eslint-disable-next-line
	}, [anchorForPopover]);

	useEffect(() => {
		let fetchNewNotifications;

		if (userData?.userType) {
			dispatch(fetchUnreadNotifications(!partnerType ? { recipient_type: userData?.userType } : undefined));

			fetchNewNotifications = setInterval(
				() => dispatch(fetchUnreadNotifications(!partnerType ? { recipient_type: userData?.userType } : undefined)),
				1000 * oneMinuteInSeconds,
			);
		}

		return () => {
			if (fetchNewNotifications) {
				clearInterval(fetchNewNotifications);
			}
		};

		// eslint-disable-next-line
	}, [location.pathname, userData?.userType]);

	const isPartnerLogo = partnerStorage?.getPartner()?.canDisplayLogo();
	const partnerName = partnerStorage.getData()?.name;
	const partnerLogo = partnerStorage?.getPartner()?.logo;

	const open = Boolean(anchorEl);
	const isLightMode = isHomePage && !isPartnerLogo;

	const handleMenu = event => setAnchorEl(event.currentTarget);

	const handleClickOpenDialog = (typeDialog: TDialog) => {
		setOpenDialog(true);

		handleClose();

		setTypeOfDialog(typeDialog);
	};

	const handleClose = () => setAnchorEl(null);

	const handleSingOut = async () => {
		await signOut();

		history.push(paths.HOMEPAGE);

		handleClose();
	};

	const handleCloseDialog = () => setOpenDialog(false);

	const handleShowForgotPswdOnPopup = () => setTypeOfDialog("FORGOT_PSWD");

	return (
		<>
			{isSmallScreen && (
				<DialogForNotifications
					fullScreen
					open={!!anchorForPopover}
					onClose={() => setAnchorForPopover(null)}
					aria-labelledby="notifications-modal"
				>
					<DialogContent>
						<CloseIconBtn onClick={() => setAnchorForPopover(null)}>
							<CloseIcon />
						</CloseIconBtn>
					</DialogContent>

					<NotificationsSimple />
				</DialogForNotifications>
			)}

			<StyledDialog
				fullScreen={isSmallScreen}
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="responsive-dialog-login-register"
			>
				<DialogContent>
					<CloseIconBtn onClick={handleCloseDialog}>
						<CloseIcon />
					</CloseIconBtn>

					{typeOfDialog === "LOGIN" && <LoginView isPopup showForgotPasswordOnPopup={handleShowForgotPswdOnPopup} />}
					{typeOfDialog === "REGISTER" && <RegisterView isPopup isMainView />}
					{typeOfDialog === "FORGOT_PSWD" && <ForgotPasswordView isPopup />}
				</DialogContent>
			</StyledDialog>

			<NavBar lightMode={isLightMode}>
				<Container>
					<NavContent>
						<LogoContent>
							<LogoLink to={paths.HOMEPAGE}>
								{isPartnerLogo ? (
									<>
										<Logo version="localbini" height={34} />
										<Divider orientation="vertical" flexItem />
									</>
								) : isHomePage ? (
									<Logo version="localbiniWhite" height={34} />
								) : (
									<Logo version="localbiniBig" height={34} />
								)}

								{isPartnerLogo && <PartnerLogoImage src={partnerLogo} alt={partnerName} />}
							</LogoLink>
						</LogoContent>

						<LinksContent>
							{/* @todo:backend - add favorite button */}
							{/*<IconButton aria-label="my-favorite" color="inherit">*/}
							{/*  <FavoriteIcon lightMode={isHomePage} />*/}
							{/*</IconButton>*/}
							{!isSmallScreen && userData?.userType !== EUserType.TRAVELER && !partnerType && (
								<LinkStyle
									to={
										userData
											? fillRoute(paths.EXPERIENCE_CREATE_STEP, { step: null, id: null })
											: paths.BINIPOOL_CONCEPT
										// Temporary switch from paths.UNIQUE_EXPERIENCES
									}
								>
									<LinkLabelStyle>{t(userData ? "TOPBAR.HOST_YOUR_EXP" : "TOPBAR.HOST_EXP")}</LinkLabelStyle>
								</LinkStyle>
							)}

							{userData && (
								<NameAvatarContent>
									{!isSmallScreen && (
										<NavItem>
											<LinkStyle
												to={
													partnerType ? fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.ACCOUNT }) : paths.PROFILE
												}
											>
												<LinkLabelStyle>
													{userData.profile_draft?.first_name} {userData.profile_draft?.last_name}
												</LinkLabelStyle>
											</LinkStyle>
										</NavItem>
									)}

									<NavItem pointer>
										<Badge
											color="error"
											overlap="circular"
											variant="dot"
											invisible={!notificationsGlobal.unreadNotifications?.statistics.total}
										>
											<AvatarImg
												alt="User avatar"
												src={userData.profile_draft?.pictures?.profile || undefined}
												onClick={event => setAnchorForPopover(event.currentTarget)}
											/>

											{!isSmallScreen && (
												<StyledPopover
													id={!!anchorForPopover ? "notifications-modal" : undefined}
													open={!!anchorForPopover}
													anchorEl={anchorForPopover}
													onClose={() => setAnchorForPopover(null)}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													transformOrigin={{
														vertical: -10,
														horizontal: "right",
													}}
													disableScrollLock
												>
													<NotificationsSimple />
												</StyledPopover>
											)}
										</Badge>
									</NavItem>
								</NameAvatarContent>
							)}

							<NavItem isMenuIcon>
								<IconButton
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									color="inherit"
									onClick={handleMenu}
								>
									{userData ? (
										<MenuIcon $lightMode={isLightMode} />
									) : isMobileScreen ? (
										<AccountCircleOutlined />
									) : (
										<MenuIcon $lightMode={isLightMode} />
									)}
								</IconButton>
							</NavItem>
						</LinksContent>
					</NavContent>
				</Container>
			</NavBar>

			<MenuPopup
				userType={userData?.userType}
				originalUserType={userData?.originalUserType}
				handleClickOpenDialog={handleClickOpenDialog}
				handleSingOut={handleSingOut}
				open={open}
				handleClose={handleClose}
				anchorEl={anchorEl}
			/>

			{isSmallScreen && (
				<ScrollToTopWrapper>
					<ScrollToTop showUnder={160}>
						<UpButton>
							<StyledArrowUpward />
							{t("TOPBAR.BACK_TO_TOP")}
						</UpButton>
					</ScrollToTop>
				</ScrollToTopWrapper>
			)}
		</>
	);
};

export default TopBar;
