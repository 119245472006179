import axios from "axios";

import { EPromoCodeType } from "data/promo-codes/types";

import { postValidatePath } from "services/api/promoCodes";
import { apiAddress } from "services/aws/config";

import { Errors } from "./Errors";
import { Notifications } from "./Notifications";

class AffiliateSession {
	public static readonly affiliateUrlParam = "afi";

	private readonly affiliateSessionStorageKey = "afiParam";

	private affiliateParam: string | undefined;

	constructor() {
		this.load();
	}

	load() {
		try {
			const keyValue = window.sessionStorage.getItem(this.affiliateSessionStorageKey);

			if (null !== keyValue) {
				this.affiliateParam = keyValue;

				return;
			}
		} catch {
			return;
		}
	}

	getAffiliateParam() {
		return this.affiliateParam;
	}

	async getAndValidateAffiliateParam(headers?: Record<string, string>) {
		if (this.affiliateParam) {
			const errorMessage = "Affiliate code is invalid/inactive and will not be used.";

			try {
				// Raw axios due to problem with normal API request from AWS Amplify
				const validateCode = await axios.post<{ code: string; message: string | null; type: EPromoCodeType }>(
					`https://internal.api${apiAddress}.localbini.com${postValidatePath}`,
					{
						code: this.affiliateParam,
					},
					{
						headers,
					},
				);

				if (validateCode.data.type !== EPromoCodeType.AFFILIATE_CODE) {
					Notifications.useNotification?.addError(errorMessage);

					this.clear();
				}
			} catch (e) {
				// @ts-ignore
				if (e?.response?.status !== 403) {
					Errors.useErrors?.handleAndNotify(e);
				} else {
					Notifications.useNotification?.addError(errorMessage);
				}

				this.clear();
			}
		}

		return this.affiliateParam;
	}

	getAffiliateUrlParam() {
		return AffiliateSession.affiliateUrlParam;
	}

	clear() {
		window.sessionStorage.removeItem(this.affiliateSessionStorageKey);

		this.affiliateParam = undefined;
	}

	store(affiliateParam?: string) {
		const urlParamValue = new URLSearchParams(window.location.search).get(AffiliateSession.affiliateUrlParam);

		if (!!affiliateParam || !!urlParamValue) {
			window.sessionStorage.setItem(
				this.affiliateSessionStorageKey,
				(affiliateParam as string) || (urlParamValue as string),
			);

			this.load();
		}

		return this.affiliateParam;
	}
}

const affiliateSessionStorage = new AffiliateSession();

export { affiliateSessionStorage };

export default AffiliateSession;
