import { Box, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import LocalBiniLogo from "assets/icons/logo/localbini-greyish.svg";

import { CloseIconBtn } from "styles/common";

import { IDialogDecorator } from "../../types";

const LogoDecorator = ({ children, handleClose }: IDialogDecorator) => (
	<DialogContent>
		<CloseIconBtn aria-label="close-button" onClick={handleClose}>
			<CloseIcon />
		</CloseIconBtn>

		<Box marginBottom="12px">
			<img src={LocalBiniLogo} alt="logo localbini" height="64px" width="64px" />
		</Box>

		{children}
	</DialogContent>
);

export default LogoDecorator;
