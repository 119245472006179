import useTranslation from "hooks/useTranslation";

import TooltipIcon from "ui/tooltips/TooltipIcon";

import { DescriptionContent, IconContent, Text, TooltipContent } from "../shared.styled";

const ActivityOrTour = () => {
	const { t } = useTranslation();

	return (
		<TooltipContent>
			<IconContent>
				<TooltipIcon iconType="success" />
			</IconContent>

			<DescriptionContent>
				{/*<Title isMarginBottom>*/}
				{/*  {t("EXPERIENCE.TOOLTIP.EXP3.TITLE")}*/}
				{/*</Title>*/}
				<Text isMarginBottom>{t("EXPERIENCE.TOOLTIP.BASIC_INFO.EXP3.DESC1")}</Text>
				<Text>{t("EXPERIENCE.TOOLTIP.BASIC_INFO.EXP3.DESC2")}</Text>
			</DescriptionContent>
		</TooltipContent>
	);
};

export default ActivityOrTour;
