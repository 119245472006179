import { Avatar, Box, Button, TextField } from "@material-ui/core";
import { ReactElement, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { EBookingStatus, IPendingRequest } from "data/backoffice/bookings/types";
import { currencySymbolsDictionary } from "data/dictionaries";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsServices from "services/api/bookings";

import { DATE_FORMATS, minutesToHM, oneHourInMinutes, parseISOWrapper } from "utils/dates";
import fillRoute from "utils/routes";

import SwitcherButton from "ui/buttons/SwitcherButton";
import Dialog from "ui/Dialog";
import LinkDownloadFileICS from "ui/LinkDownloadFileICS";
import LinkGoogleCalendar from "ui/LinkGoogleCalendar";
import LinkGoogleMaps from "ui/LinkGoogleMaps";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily, StyledExternalLink, StyledLink } from "styles/common";
import media from "styles/media";

import ElemClipboard from "../../backoffice/ElemClipboard";
import { Col, StyledDivider, Text, Title } from "../shared.styled";

const Wrapper = styled.div`
	display: flex;
	width: 100%;

	${media.tablet`
    flex-direction: column;
  `};
`;

const StyledTitle = styled(Title)`
	font-size: 14px;
`;

const Item = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 10px;
`;

const NoSystemData = styled.span`
	font-style: italic;
	line-height: 1;
	font-size: 11px;
	color: ${colors.middleGray};
	display: inline-block;
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const DescWrapper = styled.div`
	width: 100%;
	padding: 44px 22px;
`;

const AvatarWrapper = styled.div`
	width: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledAvatar = styled(Avatar)`
	margin-bottom: 5px;
	width: 54px;
	height: 54px;
`;

const ImgContent = styled.div<{ mainPhotoUrl: string }>`
	width: 100%;
	height: 100%;
	background-image: url(${props => props.mainPhotoUrl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const StyledDialog = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		margin: 0 auto;
		min-width: 850px;

		.MuiDialogContent-root {
			padding: 0;
		}
		.MuiDialogContent-root:first-child {
			padding-top: 0;
		}
		${media.tablet`
      min-width: 100%;
    `};
	}
`;

const StyledExpLink = styled.a`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: bold;
	line-height: 1.67;
	color: ${colors.sunShade};

	&:hover {
		text-decoration: underline;
	}
`;

const DialogWrapper = styled.div`
	margin: 60px 0 30px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const AddBtn = styled(Button)`
	width: 250px;
	height: 40px;
`;

const TitleCancelBooking = styled.h1`
	margin: 0 0 14px;
	opacity: 0.76;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	text-align: center;
	color: ${colors.mainFontColor};
`;

const StyledTextField = styled(TextField)`
	input {
		text-align: center;
	}
`;

enum EHeldCodeType {
	LOCALBINI = "LOCALBINI",
	PARTNER = "PARTNER",
}

interface IDashboardSeeDetailsLocalhostProps {
	id: string;
	title: string;
	startDate?: string;
	startLocation: string;
	language: string;
	cost?: string;
	link?: string;
	showDialog: boolean;
	handleClose: () => void;
	localhostLink?: string | null;
	firstNameLocalhost?: string | null;
	avatarUrlLocalhost?: string;
	mainPhoto?: string;
	experienceTime?: number;
	experienceTimeZone?: string;
	guestsDetails: {
		name?: string;
		phone?: string | null;
		status?: EBookingStatus;
		comment?: string | null;
		bookingId?: string;
		partnerIntBid?: string | null;
	}[];
	earningDetails: IPendingRequest;
	totalTravelers?: number;
	statusLabel?: ReactElement;
	bookingId?: string;
}

const DashboardSeeDetailsLocalhost = ({
	id,
	title,
	startDate,
	startLocation,
	language,
	cost,
	link,
	handleClose,
	showDialog,
	localhostLink,
	firstNameLocalhost,
	avatarUrlLocalhost,
	mainPhoto,
	experienceTime,
	experienceTimeZone,
	guestsDetails,
	earningDetails,
	totalTravelers,
	statusLabel,
	bookingId,
}: IDashboardSeeDetailsLocalhostProps) => {
	const { t, withRaw } = useTranslation(["common", "ui"]);

	const { handleAndNotify } = useErrors();
	const { addSuccess } = useNotification();

	const [heldCode, setHeldCode] = useState<string>("");
	const [selectedBookingId, setSelectedBookingId] = useState<string>("");
	const [showAddCodeDialog, setShowAddCodeDialog] = useState<boolean>(false);
	const [heldCodeType, setHeldCodeType] = useState<EHeldCodeType>(EHeldCodeType.LOCALBINI);

	const partnerIntBid = guestsDetails.find(elem => elem.bookingId === selectedBookingId)?.partnerIntBid?.split("*")[0];

	const addHeldCode = async () => {
		try {
			await bookingsServices.postHeldCode(
				selectedBookingId,
				heldCodeType === EHeldCodeType.PARTNER ? partnerIntBid + heldCode : heldCode,
			);

			addSuccess(t("MY_BOOKINGS.BOOKING_CODE_ADDED"));

			handleClose();

			setShowAddCodeDialog(false);
			setHeldCode("");
			setHeldCodeType(EHeldCodeType.LOCALBINI);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const showAddCodeDialogAction = (bId?: string) => {
		if (bId) {
			setShowAddCodeDialog(true);
			setSelectedBookingId(bId);
		}
	};

	return (
		<>
			<Dialog
				minHeight="auto"
				decorator="withoutLogo"
				showDialog={showAddCodeDialog}
				handleClose={() => {
					setShowAddCodeDialog(false);
					setHeldCode("");
					setHeldCodeType(EHeldCodeType.LOCALBINI);
				}}
			>
				<DialogWrapper>
					<TitleCancelBooking>{t("MY_BOOKINGS.ADD_CODE_TITLE_BOOKING")}</TitleCancelBooking>

					<Box textAlign="center" mb="12px">
						{withRaw(
							`MY_BOOKINGS.ADD_CODE_SUBTITLE_BOOKING.${
								!!partnerIntBid ? EHeldCodeType.PARTNER : EHeldCodeType.LOCALBINI
							}`,
							null,
						)}
					</Box>

					{!!partnerIntBid && (
						<SwitcherButton
							customHeight={36}
							switcherValue={heldCodeType}
							handleClickSwitcher={v => {
								setHeldCode("");

								setHeldCodeType(v as EHeldCodeType);
							}}
							mainConfig={{
								first: {
									label: t(`MY_BOOKINGS.ADD_CODE.SWITCHER.${EHeldCodeType.LOCALBINI}`),
									value: EHeldCodeType.LOCALBINI,
								},
								second: {
									label: t(`MY_BOOKINGS.ADD_CODE.SWITCHER.${EHeldCodeType.PARTNER}`),
									value: EHeldCodeType.PARTNER,
								},
							}}
						/>
					)}

					<Box
						mt={partnerIntBid ? "20px" : undefined}
						mb="30px"
						display="flex"
						gridGap="10px"
						alignItems="center"
						justifyContent="center"
						flexWrap="wrap"
					>
						{heldCodeType === EHeldCodeType.PARTNER && (
							<Box fontSize="20px" fontWeight="500" lineHeight="1.6">
								{partnerIntBid}
							</Box>
						)}

						<StyledTextField
							variant="outlined"
							value={heldCode}
							onChange={e => setHeldCode(e.target.value)}
							placeholder={t(`MY_BOOKINGS.ADD_CODE.INPUT.${heldCodeType}`)}
						/>
					</Box>

					<AddBtn
						disabled={heldCode.length < (heldCodeType === EHeldCodeType.PARTNER ? 2 : 5)}
						variant="contained"
						color="primary"
						onClick={addHeldCode}
					>
						{t("COMMON.CONFIRM")}
					</AddBtn>
				</DialogWrapper>
			</Dialog>

			<StyledDialog decorator="withoutLogo" maxHeight="500px" showDialog={showDialog} handleClose={handleClose}>
				<Wrapper>
					<Col>
						<ImgContent mainPhotoUrl={mainPhoto || CoverImg} />
					</Col>

					<Col>
						<DescWrapper>
							<Box display="flex">
								{firstNameLocalhost && localhostLink && (
									<StyledLink to={localhostLink} color={colors.mainFontColor} target="_blank">
										<AvatarWrapper>
											<StyledAvatar alt={`Avatar Local Host - ${firstNameLocalhost}`} src={avatarUrlLocalhost} />

											<Text>{firstNameLocalhost}</Text>
										</AvatarWrapper>
									</StyledLink>
								)}

								<StyledLink
									to={
										id
											? fillRoute(paths.SEARCH_EXPERIENCES, {
													title: slugify(title.toLowerCase() || ""),
													id,
											  })
											: ""
									}
									$color={colors.mainFontColor}
									target="_blank"
								>
									<StyledTitle>{title}</StyledTitle>
								</StyledLink>
							</Box>

							<StyledDivider />

							{statusLabel && (
								<Item>
									<Col>
										<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STATUS")}</Text>
									</Col>

									<Col>
										<TextBold>{statusLabel}</TextBold>
									</Col>
								</Item>
							)}

							<Item>
								<Col>
									<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_DATE")}</Text>
								</Col>

								<Col>
									<TextBold>{parseISOWrapper(startDate, DATE_FORMATS.DATETIME_FORMAT3, true)}</TextBold>

									{startDate && experienceTime && (
										<Box display="inline-flex" position="relative" top="4px" gridGap="10px" marginLeft="10px">
											<LinkGoogleCalendar
												title={title.replace(/\s/g, "+")}
												location={startLocation}
												startDate={parseISOWrapper(
													startDate,
													DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
												)}
												endDate={parseISOWrapper(
													new Date(
														new Date(startDate).getTime() + experienceTime * oneHourInMinutes * 1000,
													).toISOString(),
													DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
												)}
												timeZone={experienceTimeZone || ""}
												description={t("DASHBOARD_BOOKING_DETAILS_PREVIEW.CALENDAR_EVENT.DESCRIPTION_LINK")}
											/>

											<LinkDownloadFileICS
												title={title}
												location={startLocation}
												startDate={parseISOWrapper(
													startDate,
													DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
												)}
												endDate={parseISOWrapper(
													new Date(
														new Date(startDate).getTime() + experienceTime * oneHourInMinutes * 1000,
													).toISOString(),
													DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE,
												)}
												timeZone={experienceTimeZone || ""}
												bookingId={bookingId || ""}
												description={t("DASHBOARD_BOOKING_DETAILS_PREVIEW.CALENDAR_EVENT.DESCRIPTION")}
											/>
										</Box>
									)}
								</Col>
							</Item>

							<Item>
								<Col>
									<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.EXPERIENCE_TIME")}</Text>
								</Col>

								<Col>
									<TextBold>{experienceTime ? minutesToHM(experienceTime) : 0}</TextBold>
								</Col>
							</Item>

							<Item>
								<Col>
									<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_LOCATION")}</Text>
								</Col>

								<Col>
									<TextBold>
										<LinkGoogleMaps address={startLocation} />
									</TextBold>
								</Col>
							</Item>

							<Item>
								<Col>
									<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.NB_OF_TRAVELERS")}</Text>
								</Col>

								<Col>
									<TextBold>{totalTravelers}</TextBold>
								</Col>
							</Item>

							<Item>
								<Col>
									<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LANGUAGE")}</Text>
								</Col>

								<Col>
									<TextBold>{language}</TextBold>
								</Col>
							</Item>

							{earningDetails && (
								<Item>
									<Col>
										<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.YOUR_EARNINGS")}</Text>
									</Col>

									<Col>
										<TextBold>
											{currencySymbolsDictionary[earningDetails.localhost_earnings?.currency || ""]}{" "}
											{earningDetails.localhost_earnings?.value}
										</TextBold>
									</Col>
								</Item>
							)}

							{!!cost && (
								<Item>
									<Col>
										<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.COST")}</Text>
									</Col>

									<Col>
										<TextBold>{cost}</TextBold>
									</Col>
								</Item>
							)}

							{link && (
								<Item>
									<Col>
										<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.SUGGESTED_ITINERARY")}</Text>
									</Col>

									<Col>
										<StyledExpLink href={link} target="_blank">
											{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LINK_LABEL")}
										</StyledExpLink>
									</Col>
								</Item>
							)}

							{bookingId && (
								<Item>
									<Col>
										<Text>{t("MY_BOOKINGS.BOOKING_ID")}</Text>
									</Col>

									<Col>
										<TextBold>
											<ElemClipboard splitPart={15} elem={bookingId} />
										</TextBold>
									</Col>
								</Item>
							)}

							{guestsDetails.map((v, i) => (
								<Box key={`${v.name || ""}-${i}`}>
									<StyledDivider />

									<Item>
										<Col>
											<Text>
												{t("DASHBOARD_PENDING_REQUEST_PREVIEW.GUEST")} {i + 1}:
											</Text>
										</Col>

										<Col>
											{!v.name && !v.phone ? (
												<NoSystemData>{t("DASHBOARD_BOOKING_DETAILS_PREVIEW.NO_TRAVELER_DATA")}</NoSystemData>
											) : (
												<TextBold>
													{v.name}{" "}
													{!!v.phone && (
														<StyledExternalLink color="inherit" href={`tel:${v.phone}`}>
															({v.phone})
														</StyledExternalLink>
													)}
												</TextBold>
											)}
										</Col>
									</Item>

									{v.comment && (
										<Item>
											<Col>
												<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.GUEST_COMMENT")}</Text>
											</Col>

											<Col>
												<Text>{v.comment}</Text>
											</Col>
										</Item>
									)}

									{!!v.bookingId && v?.status === EBookingStatus.PAID && (
										<Item>
											<Button variant="outlined" onClick={() => showAddCodeDialogAction(v.bookingId)}>
												{t("MY_BOOKINGS.ADD_CODE_BOOKING")}
											</Button>
										</Item>
									)}
								</Box>
							))}
						</DescWrapper>
					</Col>
				</Wrapper>
			</StyledDialog>
		</>
	);
};

export default DashboardSeeDetailsLocalhost;
