import { Button } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { StyledLink } from "styles/common";

const StyledButton = styled(Button)`
	margin-top: 1.25rem;
	height: 3rem;
	min-width: 7.5rem;
`;

interface IContactButton {
	translationKey: string;
}

const ContactButton = ({ translationKey }: IContactButton) => {
	const { t } = useTranslation();

	return (
		<StyledLink to={paths.CONTACT} $withoutHoverUnderline>
			<StyledButton color="primary" variant="contained" size="large">
				{t(translationKey)}
			</StyledButton>
		</StyledLink>
	);
};

export default ContactButton;
