import { Box, createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";

import { MontserratFontFamily } from "styles/common";

interface ITitleSectionProps {
	title: string;
	subTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainTitle: {
			[theme.breakpoints.down("sm")]: {
				fontSize: 22,
			},
			color: theme.palette.text.homePageFont,
			fontWeight: "bold",
			fontSize: 36,
			fontFamily: MontserratFontFamily,
			lineHeight: "39px",
			letterSpacing: 0.07,
		},
		subTitle: {
			[theme.breakpoints.down("sm")]: {
				fontSize: 14,
			},
			textAlign: "center",
			color: theme.palette.text.homePageFont,
			fontSize: 26,
			fontFamily: MontserratFontFamily,
			letterSpacing: 0.05,
			fontWeight: 300,
		},
	}),
);

const TitleSection = memo(({ subTitle, title }: ITitleSectionProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mb={isSmallScreen ? 15 : 20}>
			<Typography variant="h2" className={classes.mainTitle}>
				{title}
			</Typography>

			<Typography variant="h3" className={classes.subTitle}>
				{subTitle}
			</Typography>
		</Box>
	);
});

export default TitleSection;
