import { Box, createStyles, Divider, Switch, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { differenceBy } from "lodash-es";
import { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { EExperienceStatus, EExpType, IExperience } from "data/experiences/types";
import { EOnboardingSteps } from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import draftsService from "services/api/drafts";
import experiencesService from "services/api/experiences";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { minutesToHM } from "utils/dates";
import fillRoute from "utils/routes";

import Header from "components/creation-process/Header";
import BinipoolExperiencesList from "components/experiences/BinipoolExperiencesList";
import Record from "components/experiences/Record";
import RecordBinipool from "components/experiences/RecordBinipool";
import { carouselSwiperConfig } from "components/experiences/RecordBinipool/config";
import AppWrapper from "components/layout/AppWrapper";

import CarouselSwiper from "ui/CarouselSwiper";
import EmptyList from "ui/EmptyList";

import colors from "styles/colors";
import { Container } from "styles/common";
import dimensions from "styles/dimensions";

import { Loader } from "./common";

const UniqueExpTitle = styled.h1`
	margin: 0;
	font-size: ${dimensions.fontSize.medium};
	font-weight: 500;
	line-height: 2.58;
	letter-spacing: 0.83px;
	color: ${colors.mainFontColor};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		experiencesContainer: {
			[theme.breakpoints.up("md")]: {
				columnGap: "calc((100% - 3 * (33% - 20px)) / 2)",
			},
			[theme.breakpoints.down("md")]: {
				columnGap: "calc(100% - 2 * (50% - 20px))",
			},
			[theme.breakpoints.down("sm")]: {
				columnGap: 0,
			},
		},
		experienceRecord: {
			[theme.breakpoints.up("md")]: {
				width: "calc(33% - 20px)",
			},
			[theme.breakpoints.down("md")]: {
				width: "calc(50% - 20px)",
			},
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	}),
);

const AccountMyExperiencesPage = () => {
	const { t } = useTranslation();

	const classes = useStyles();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { handleAndNotify } = useErrors();
	const { success } = useNotification();

	const [showOnlyArchived, setShowOnlyArchived] = useState<boolean>(false);
	const [drafts, setDrafts] = useState<IExperience[] | null>(null);
	const [joinedBinipools, setJoinedBinipools] = useState<IExperience[] | null>(null);
	const [binipoolsInCity, setBinipoolsInCity] = useState<IExperience[] | null>(null);
	const [designedBinipools, setDesignedBinipools] = useState<IExperience[] | null>(null);

	const loggedUser = useAppSelector(selectUser);

	const timeout = 2000;

	useEffect(() => {
		const callsToApi = [
			fetchJoinedBinipools(),
			experiencesService
				.getListOfExperiences({
					exp_type: EExpType.COHOSTED,
					limit: 100,
					city: loggedUser?.profile_draft?.cities || [],
				})
				.then(binipools => setBinipoolsInCity(binipools.data))
				.catch(e => handleAndNotify(e)),
			experiencesService
				.getListOfExperiences({
					exp_type: EExpType.COHOSTED,
					designed_by: loggedUser?.id,
					limit: 100,
				})
				.then(binipoolsDesigned => setDesignedBinipools(binipoolsDesigned.data))
				.catch(e => handleAndNotify(e)),
		];

		Promise.all(callsToApi);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchDrafts();

		// eslint-disable-next-line
	}, [showOnlyArchived]);

	const fetchDrafts = async () => {
		setDrafts(null);

		try {
			const draftsData = await draftsService.fetchAll({
				limit: 100,
				sort: "modified_on",
				order: "desc",
				exp_type: EExpType.UNIQUE,
				created_by: loggedUser?.id,
				status: showOnlyArchived
					? EExperienceStatus.RETIRED
					: [
							EExperienceStatus.DRAFT,
							EExperienceStatus.AWAITING_REVIEW,
							EExperienceStatus.UNDER_REVIEW,
							EExperienceStatus.NEEDS_CHANGES,
							EExperienceStatus.REJECTED,
							EExperienceStatus.AWAITING_HOSTS,
							EExperienceStatus.PUBLISHED,
							EExperienceStatus.PUBLISHED__AWAITING_REVIEW,
							EExperienceStatus.PUBLISHED__UNDER_REVIEW,
							EExperienceStatus.PUBLISHED__NEEDS_CHANGES,
							EExperienceStatus.PUBLISHED__DRAFT,
					  ],
			});

			setDrafts(draftsData.data);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const fetchJoinedBinipools = () =>
		experiencesService
			.getListOfExperiences({
				exp_type: EExpType.COHOSTED,
				cohosted_by_user: true,
				limit: 100,
			})
			.then(usersBinipools => setJoinedBinipools(usersBinipools.data))
			.catch(e => handleAndNotify(e));

	const leaveBinipool = async (experienceId: string) => {
		try {
			setJoinedBinipools(null);

			await experiencesService.deleteLeaveBinipool(experienceId, loggedUser?.id || "");

			success();

			// Needed timeout for fresh data!
			setTimeout(() => fetchJoinedBinipools(), timeout);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const archiveExperience = async (experienceId: string) => {
		try {
			await draftsService.deleteRetire(experienceId);

			// Needed timeout for fresh data!
			setTimeout(() => fetchDrafts(), timeout);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const createListOfSliders = () => {
		const listOfSliders: ReactNode[] = [];

		drafts?.forEach(draft => listOfSliders.push(<Record {...draft} archiveExp={() => archiveExperience(draft.id)} />));

		return listOfSliders;
	};

	const createJoinedBinipoolsSliders = useCallback(
		() =>
			joinedBinipools?.map(v => (
				<RecordBinipool
					key={`${v.id}-${v.title}`}
					id={v.id}
					city={v.city}
					hours={!!v.duration_minutes ? v.duration_minutes / 60 : 0}
					duration={minutesToHM(v.duration_minutes)}
					imgUrl={v.photo_main || ""}
					rating={{ totalRating: v.ratings.average, nbReviews: v.ratings.count }}
					title={v.title}
					currency={v.pricing?.currency}
					withoutButton
					withEarnings
					withOptions
					onLeaveBinipool={() => leaveBinipool(v.id)}
					itinerary={v.internal?.suggested_route}
				/>
			)) || [],

		// eslint-disable-next-line
		[joinedBinipools],
	);

	const createDesignedBinipoolsSliders = useCallback(
		() =>
			designedBinipools?.map(v => (
				<RecordBinipool
					key={`${v.id}-${v.title}`}
					id={v.id}
					city={v.city}
					hours={!!v.duration_minutes ? v.duration_minutes / 60 : 0}
					duration={minutesToHM(v.duration_minutes)}
					imgUrl={v.photo_main || ""}
					rating={{ totalRating: v.ratings.average, nbReviews: v.ratings.count }}
					title={v.title}
					currency={v.pricing?.currency}
					withoutButton
					withEarnings
					itinerary={v.internal?.suggested_route}
				/>
			)) || [],

		// eslint-disable-next-line
		[designedBinipools],
	);

	return (
		<AppWrapper>
			<Container>
				<Header
					link="/"
					backToLabel={t("EXPERIENCE.BACK_TO_MAIN_BTN")}
					lastBreadcrumbLabel={t("TOPBAR.MY_EXPERIENCES_LABEL")}
				/>

				<section>
					<Box marginLeft={isSmallScreen ? 7 : 0} mt={5} mb={5} display="flex" justifyContent="space-between">
						<UniqueExpTitle>
							{t(showOnlyArchived ? "EXPERIENCE.LIST.TITLE_ARCHIVED" : "EXPERIENCE.LIST.TITLE")}
						</UniqueExpTitle>

						<Box display="flex" alignItems="center" gridGap="5px">
							<Box fontSize="12px" lineHeight="12px">
								{t("EXPERIENCE.LIST.SHOW_ONLY_ARCHIVED")}
							</Box>

							<Switch
								checked={showOnlyArchived}
								size="small"
								onChange={() => setShowOnlyArchived(prevState => !prevState)}
							/>
						</Box>
					</Box>

					{drafts !== null ? (
						<>
							{!drafts.length ? (
								<EmptyList
									description={t("EXPERIENCE.EMPTY_LIST.NEW_DESCRIPTION")}
									link={fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: null, step: null })}
									nameBtn={t("EXPERIENCE.EMPTY_LIST.NEW_BTN")}
									title={t("EXPERIENCE.EMPTY_LIST.NEW_TITLE")}
								/>
							) : isSmallScreen ? (
								<Box ml={10} mr={10} mb={10}>
									<CarouselSwiper slides={createListOfSliders()} />
								</Box>
							) : (
								<Box className={classes.experiencesContainer} display="flex" flexWrap="wrap" marginBottom={40}>
									{drafts.map(draft => (
										<Box pb={10} key={draft.id} className={classes.experienceRecord}>
											<Record {...draft} archiveExp={() => archiveExperience(draft.id)} />
										</Box>
									))}
								</Box>
							)}
						</>
					) : (
						<Loader />
					)}
				</section>

				<Divider />

				<section>
					<Box marginLeft={isSmallScreen ? 7 : 0} mt={5} mb={5}>
						<UniqueExpTitle>{t("EXPERIENCE.LIST.TITLE_BINIPOOL")}</UniqueExpTitle>

						{joinedBinipools === null ? (
							<Loader />
						) : !!joinedBinipools.length ? (
							<Box mt={5} mb={15}>
								<CarouselSwiper
									heightSlide="inherit"
									widthSlide="100%"
									paddingBox={5}
									settings={carouselSwiperConfig}
									slides={createJoinedBinipoolsSliders()}
									hidePrevNextBtn={joinedBinipools.length <= 1}
								/>
							</Box>
						) : (
							<EmptyList
								description={t("EXPERIENCE.EMPTY_LIST.BINIPOOL_DESCRIPTION")}
								link={fillRoute(paths.ONBOARDING, {
									step: EOnboardingSteps.Binipools,
									search: { type: "binipool", selectOnlyBinipools: true },
								})}
								nameBtn={t("EXPERIENCE.EMPTY_LIST.BINIPOOL_BTN")}
								title={t("EXPERIENCE.EMPTY_LIST.BINIPOOL_TITLE")}
							/>
						)}
					</Box>
				</section>

				<Divider />

				{designedBinipools === null ? (
					<section>
						<Box marginLeft={isSmallScreen ? 7 : 0} mt={5} mb={5}>
							<Loader />
						</Box>
					</section>
				) : (
					!!designedBinipools.length && (
						<>
							<section>
								<Box marginLeft={isSmallScreen ? 7 : 0} mt={5} mb={5}>
									<UniqueExpTitle>{t("EXPERIENCE.LIST.TITLE_DESIGNED_BINIPOOL")}</UniqueExpTitle>

									<Box mt={5} mb={15}>
										<CarouselSwiper
											heightSlide="inherit"
											widthSlide="100%"
											paddingBox={5}
											settings={carouselSwiperConfig}
											slides={createDesignedBinipoolsSliders()}
											hidePrevNextBtn={designedBinipools.length <= 1}
										/>
									</Box>
								</Box>
							</section>

							<Divider />
						</>
					)
				)}
			</Container>

			<Box mt="40px">
				{binipoolsInCity === null ? (
					<Loader />
				) : !!binipoolsInCity.length ? (
					<BinipoolExperiencesList
						binipoolExpList={
							!!joinedBinipools?.length ? differenceBy(binipoolsInCity, joinedBinipools, "id") : binipoolsInCity
						}
					/>
				) : null}
			</Box>
		</AppWrapper>
	);
};

export default AccountMyExperiencesPage;
