import { Avatar, Box, Button, Divider } from "@material-ui/core";
import styled from "styled-components";

import ExpBackground from "assets/images/common/mock_background.svg";

import useTranslation from "hooks/useTranslation";

import { minutesToHM } from "utils/dates";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

import { Title } from "../shared.styled";

const Wrapper = styled.div`
	margin: 0 10px 30px;
	width: 424px;
	background: ${colors.white};
	box-shadow: 0 0 5px 0 #cecece;
	border-radius: 4px;

	${media.largeDesktop`
    margin-right: 15px;
  `};

	${media.phone`
    width: 100%;
    margin-right: 0;
  `};
`;

const Text = styled.p`
	margin: 0 5px 0 0;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.67;
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const DetailsWrapper = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
	height: 36px;
`;

const Picture = styled.div<{ pictureUrl?: string }>`
	width: 100%;
	height: 290px;
	background-image: url(${props => props.pictureUrl || ExpBackground});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 4px 4px 0;
`;

const AvatarContent = styled.div`
	position: absolute;
	top: -40px;
	right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const StyledAvatar = styled(Avatar)`
	width: 54px;
	height: 54px;
	border: 4px solid white;
`;

const NameLabel = styled.span`
	margin-top: 13px;
	font-size: 10px;
`;

interface IDashboardPendingRequestCardProps {
	title: string;
	yourPotentialEarning: string;
	date: string;
	time: number;
	meetingSpot: string;
	avatarUrl?: string;
	localhostName?: string;
	pictureUrl?: string;
	onClickShowDetails: () => void;
}

const DashboardPendingRequestCard = ({
	title,
	yourPotentialEarning,
	date,
	time,
	meetingSpot,
	avatarUrl,
	localhostName,
	pictureUrl,
	onClickShowDetails,
}: IDashboardPendingRequestCardProps) => {
	const { t } = useTranslation("ui");

	return (
		<Wrapper>
			<Picture pictureUrl={pictureUrl} />

			<Box p="20px">
				<Box display="flex" height="45px">
					<Box flex={4}>
						<Title>{title?.length > 50 ? title.slice(0, 50) + "..." : title}</Title>
					</Box>

					{avatarUrl && localhostName && (
						<Box flex={1} position="relative">
							<AvatarContent>
								<StyledAvatar alt={`Avatar localhost - ${localhostName}`} src={avatarUrl} />

								<NameLabel>{localhostName}</NameLabel>
							</AvatarContent>
						</Box>
					)}
				</Box>

				<Divider />

				<Box marginTop="20px">
					<DetailsWrapper>
						<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.YOUR_EARNINGS")}</Text>

						<TextBold>{yourPotentialEarning}</TextBold>
					</DetailsWrapper>

					<DetailsWrapper>
						<Text>{t("DASHBOARD_CARD_DATE_LABEL")}</Text>

						<TextBold>{date}</TextBold>
					</DetailsWrapper>

					<DetailsWrapper>
						<Text>{t("DASHBOARD_CARD_TIME_LABEL")}</Text>

						<TextBold>{minutesToHM(time)}</TextBold>
					</DetailsWrapper>

					<DetailsWrapper>
						<Text>{t("DASHBOARD_CARD_SPOT_LABEL")}</Text>

						<TextBold>{meetingSpot}</TextBold>
					</DetailsWrapper>

					<Box display="flex" justifyContent="flex-end">
						<StyledButton color="primary" variant="contained" size="large" onClick={onClickShowDetails}>
							{t("DASHBOARD_CARD_DETAILS_BTN")}
						</StyledButton>
					</Box>
				</Box>
			</Box>
		</Wrapper>
	);
};

export default DashboardPendingRequestCard;
