import { Box } from "@material-ui/core";

import CircularLoader from "ui/loaders/CircularLoader";

import colors from "styles/colors";

const InnerTableLoader = () => (
	<Box position="relative" textAlign="center" color={colors.sunShade} height="115px">
		<p>Loading more records...</p>

		<CircularLoader />
	</Box>
);

export default InnerTableLoader;
