import {
	Box,
	createStyles,
	Divider,
	makeStyles,
	Switch,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import endMarker from "assets/icons/avatar-location-end-station.svg";
import startMarker from "assets/icons/avatar-location-start-station.svg";

import { LocationParams } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import Map, { MarkerStyle } from "ui/maps/Map";

interface IMapProps {
	locationData?: LocationParams;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxLocation: {
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
			background: theme.palette.common.white,
			width: 380,
		},
		contentMap: {
			height: "100%",
			borderRadius: "4px",
			border: `5px solid ${theme.palette.common.white}`,
		},
		startEndLabel: {
			fontSize: 12,
			lineHeight: 1.67,
			letterSpacing: 0.09,
			marginBottom: 4,
		},
		addressText: {
			fontSize: 16,
			lineHeight: 1.25,
			letterSpacing: 0.11,
		},
		descText: {
			fontSize: 14,
			lineHeight: 1.43,
			letterSpacing: 0.1,
			color: theme.palette.common.black,
			opacity: 0.5,
		},
		dividerBlackStyle: {
			background: theme.palette.common.black,
			opacity: 0.4,
		},
		switchContainer: {
			pointerEvents: "none",
		},
	}),
);

const MapMode = ({ locationData }: IMapProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { t } = useTranslation();

	const theSameLocation = () => {
		return (
			locationData?.start.coordinates.lat === locationData?.end.coordinates.lat &&
			locationData?.start.coordinates.lon === locationData?.end.coordinates.lon
		);
	};

	return (
		<Box pb={5}>
			<Box display="flex" justifyContent="flex-start" pt={10} mb={15} flexWrap="wrap">
				<Box
					mr={isSmallScreen ? 0 : 6}
					height={isSmallScreen ? "100%" : 378}
					width={isSmallScreen ? "100%" : "initial"}
				>
					<Box className={classes.boxLocation} mb={5} pt={10} pb={10} pl={10} pr={17}>
						<Box display="flex" mb={14}>
							<Box mr={5}>
								<img alt="" src={startMarker} height="37px" width="26px" />
							</Box>

							<Box>
								<Typography className={classes.startEndLabel}>
									{t("EXPERIENCE_DETAILS_LOCALS.STARTING_LABEL")}
								</Typography>

								<Typography className={classes.addressText}>{locationData?.start.formatted_address}</Typography>
							</Box>
						</Box>

						<Divider />

						<Box mt={6}>
							<Typography className={classes.startEndLabel}>
								{t("EXPERIENCE_DETAILS_LOCALS.STARTING_POINT_DESC")}
							</Typography>
						</Box>

						<Box mt={6} mb={6}>
							<Typography className={classes.descText}>{locationData?.start.tips}</Typography>
						</Box>

						<Divider className={classes.dividerBlackStyle} />
					</Box>

					<Box className={classes.boxLocation} mb={15} pt={10} pb={10} pl={10} pr={17}>
						<Box display="flex" justifyContent="flex-end" alignItems="center">
							<Typography className={classes.startEndLabel}>
								{t("EXPERIENCE_DETAILS_LOCALS.THE_SAME_POINT_LABEL")}
							</Typography>

							<Switch
								className={classes.switchContainer}
								color="primary"
								checked={theSameLocation()}
								name="switch-the-same-location"
							/>
						</Box>

						<Box display="flex" mb={14}>
							<Box mr={5}>
								<img alt="" src={endMarker} height="37px" width="37px" />
							</Box>

							<Box>
								<Typography className={classes.startEndLabel}>{t("EXPERIENCE_DETAILS_LOCALS.ENDING_LABEL")}</Typography>
								<Typography className={classes.addressText}>{locationData?.end.formatted_address}</Typography>
							</Box>
						</Box>

						<Divider />
					</Box>
				</Box>

				<Box className={classes.contentMap} width={isSmallScreen ? "100%" : "calc(100% - 400px)"}>
					<Map
						fixedHeight={390}
						markers={[
							{
								lat: locationData?.start.coordinates.lat || 0,
								lng: locationData?.start.coordinates.lon || 0,
								markerStyle: "start" as MarkerStyle,
							},
							{
								lat: locationData?.end.coordinates.lat || 0,
								lng: locationData?.end.coordinates.lon || 0,
								markerStyle: "end" as MarkerStyle,
							},
						]}
						center={{ lat: locationData?.start.coordinates.lat || 0, lng: locationData?.start.coordinates.lon || 0 }}
						zoom={10}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default MapMode;
