import Pica from "pica";

const resizeCanvasImage = async (
	canvas: HTMLCanvasElement,
	outputImageFormat = "image/jpeg",
	imageQuality = 0.5,
	maxSize = 4096,
): Promise<Blob> => {
	const pica = new Pica({ features: ["all"] });

	const originalWidth = canvas.width;
	const originalHeight = canvas.height;

	let scaleFactor = maxSize / Math.max(originalWidth, originalHeight);

	if (scaleFactor > 1) {
		scaleFactor = 1;
	}

	const newCanvas = document.createElement("canvas");

	newCanvas.width = Math.max(Math.round(originalWidth * scaleFactor), 1);
	newCanvas.height = Math.max(Math.round(originalHeight * scaleFactor), 1);

	const resizedCanvas = await pica.resize(canvas, newCanvas);
	const blob = await pica.toBlob(resizedCanvas, outputImageFormat, imageQuality);

	return blob;
};

export default resizeCanvasImage;
