import { Box, Button } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

interface IFilterSearch {
	onClickApply: (v: boolean) => void;
	onClickClear: (v: boolean) => void;
	children?: ReactNode;
}

export const Wrapper = styled.div`
	padding: 18px;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0 0 30px 0 rgba(206, 206, 206, 0.52);
	background: ${colors.white};
`;

const StyledButton = styled(Button)<{ $darkFontColor?: boolean }>`
	height: 30px;
	font-size: 12px;
	font-weight: 500;
	${props => props.$darkFontColor && "color: #90a4ae;"}
	${props => props.$darkFontColor && "text-decoration: underline;"}
  letter-spacing: 0.26px;
`;

const FilterSearch = ({ onClickApply, onClickClear, children }: IFilterSearch) => {
	const { t } = useTranslation("ui");

	return (
		<Wrapper>
			<Box display="flex" alignItems="center" justifyContent="flex-end">
				<StyledButton $darkFontColor onClick={() => onClickClear(true)}>
					{t("FILTER_SEARCH_CLEAR_BTN")}
				</StyledButton>
			</Box>

			<Box>{children && <Box>{children}</Box>}</Box>

			<Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="30px">
				<StyledButton color="primary" variant="contained" onClick={() => onClickApply(true)}>
					{t("FILTER_SEARCH_APPLY_BTN")}
				</StyledButton>
			</Box>
		</Wrapper>
	);
};

export default FilterSearch;
