import { Box } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { Description } from "ui/CommonParagraph";

import colors from "styles/colors";
import { Container, StyledLink } from "styles/common";

const LegalTermsPage = () => {
	const { t } = useTranslation();

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("FOOTER.LINKS.LEGAL_TERMS.label")} />
				<CommonParagraph withMarginTop mainTitle={t("FOOTER.LINKS.LEGAL_TERMS.label")} />

				<Box marginTop="30px">
					<Description>
						<StyledLink color={colors.mainFontColor} to={paths.HELP_PRIVACY_POLICY}>
							<b>1. {t("HELP.PRIVACY_POLICY_LOCALBINI.TITLE")}</b>
						</StyledLink>
					</Description>

					<Description>
						<StyledLink color={colors.mainFontColor} to={paths.HELP_COOKIES}>
							<b>2. {t("HELP.COOKIES.TITLE")}</b>
						</StyledLink>
					</Description>

					<Description>
						<StyledLink color={colors.mainFontColor} to={paths.HELP_TERMS_SERVICE}>
							<b>3. {t("HELP.LEGAL_TERMS.TITLE")}</b>
						</StyledLink>
					</Description>

					<Description>
						<StyledLink color={colors.mainFontColor} to={paths.HELP_CANCELLATION_POLICY}>
							<b>4. {t("HELP.CANCELLATION.TITLE")}</b>
						</StyledLink>
					</Description>
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default LegalTermsPage;
