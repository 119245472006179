import axios from "axios";
import { FormikProps, withFormik } from "formik";
import { pick } from "lodash-es";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { EReviewOP } from "data/drafts/types";
import { EExpType, IExperience } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import { paths } from "routing/paths";

import draftsService from "services/api/drafts";

import fillRoute from "utils/routes";

import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import { Container } from "styles/common";

import CreationProcessStepper from "./components/CreationProcessStepper";
import { daysEmptyValues, initialValuesPerStep } from "./config";
import validationSchema from "./validate";

const ExperienceCreatePage = () => {
	const history = useHistory();
	const { id, step } = useParams<{ id?: string; step?: string }>();
	const location = useLocation();

	const notifications = useNotification();
	const { handleAndNotify } = useErrors();

	const addSuccess = () => notifications.addSuccess("EXPERIENCE.FOOTER.SAVED", true);

	const currentStep = Number(step ? step.slice(-1) : 1);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [draftData, setDraftData] = useState<Partial<IExperience>>(initialValuesPerStep[currentStep]);

	const getSavedExperience = async () => {
		if (!id) {
			return;
		}

		try {
			setIsLoading(true);

			const savedData = await draftsService.getDetails(id);

			setDraftData(pick(savedData, Object.keys(initialValuesPerStep[currentStep])));
		} catch (error) {
			handleAndNotify(error);

			history.push(paths.NOT_FOUND);
		} finally {
			setIsLoading(false);
		}
	};

	const saveMediaFile = async (blob: Blob) => {
		if (!id) {
			return;
		}

		try {
			setIsLoading(true);
			window.scrollTo(0, 0);

			const mediaUrlObject = await draftsService.getSignedMediaUrl(id);

			const formData = new FormData();

			// @ts-ignore
			Object.keys(mediaUrlObject.fields).forEach(key => formData.append(key, mediaUrlObject.fields[key]));
			formData.append("file", blob);

			// @ts-ignore
			await axios.post(mediaUrlObject.url, formData);

			setTimeout(() => {
				getSavedExperience();
			}, 5000); // Time for get updated, with new media files draft data
		} catch (error) {
			setIsLoading(false);
			handleAndNotify(error);
		}
	};

	const deleteMediaFile = async (mediaId: string) => {
		if (!id) {
			return;
		}

		try {
			setIsLoading(true);
			window.scrollTo(0, 0);

			await draftsService.deleteRemoveFromGallery(id, mediaId);

			setTimeout(() => {
				getSavedExperience();
			}, 5000); // Time for get updated, with new media files draft data
		} catch (error) {
			setIsLoading(false);
			handleAndNotify(error);
		}
	};

	useEffect(() => {
		notifications.clear();

		if (id) {
			getSavedExperience();
		} else {
			setDraftData(initialValuesPerStep[currentStep]);
		}
		// eslint-disable-next-line
	}, [id, currentStep]);

	useLayoutEffect(() => {
		notifications.clear();
		// eslint-disable-next-line
	}, [location.pathname]);

	const handleSubmit = async values => {
		notifications.clear();

		const data = { ...values };

		if (!data.photo_main && data.media_gallery?.length) {
			data.photo_main = data.media_gallery[0].media_url;
		}

		if (data.schedule?.availability_periods?.length === 0) {
			data.schedule.availability_periods = [
				{
					from: "01-01",
					to: "12-31",
				},
			];
		}

		if (data.pricing?.currency) {
			delete data.pricing.currency;
		}
		if (data.pricing?.filter_price) {
			delete data.pricing.filter_price;
		}

		delete data.nextIndicator_HELPER;
		delete data.saveIndicator_HELPER;

		delete data.photo_main_HELPER;
		delete data.media_gallery;
		delete data.media_gallery_HELPER;
		delete data.media_rights_confirmed_HELPER;

		try {
			let responseData;

			if (!id) {
				responseData = await draftsService.postCreate({
					exp_type: EExpType.UNIQUE,
					city: data.city,
					country: data.country,
					title: data.title,
				});

				// For deep validation on backend
				await draftsService.patchPartialUpdate(responseData.id, data, { validate: true });
			} else {
				// @todo:fix - temp validate only for specific steps
				responseData = await draftsService.patchPartialUpdate(id, data, {
					validate: currentStep !== 5,
				});
			}

			addSuccess();

			const sharedUrlPart = fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: responseData.id, step: null });

			if (!id && values.saveIndicator_HELPER) {
				return history.push(sharedUrlPart);
			}

			if (
				values.saveIndicator_HELPER ||
				(currentStep === 4 && !values.saveIndicator_HELPER && !values.nextIndicator_HELPER)
			) {
				return getSavedExperience();
			}

			if (values.nextIndicator_HELPER) {
				if (currentStep === 1) {
					history.push(`${sharedUrlPart}/step-${currentStep}`);
				}

				if (currentStep === 5) {
					await draftsService.postReview(responseData.id, EReviewOP.SUBMIT);

					return history.push(fillRoute(paths.CONGRATULATIONS, { id: responseData.id }));
				}

				return history.push(`${sharedUrlPart}/step-${currentStep + 1}`);
			}
		} catch (error) {
			handleAndNotify(error);
		}
	};

	const validations = validationSchema(currentStep - 1);

	const CreationProcessWithFormik = useMemo(
		() =>
			withFormik({
				enableReinitialize: true,
				validationSchema: validations,
				mapPropsToValues: () => {
					let mediaGalleryHelper;
					let mainPhotoHelper;

					if (currentStep === 4) {
						if (draftData.media_gallery?.length) {
							mediaGalleryHelper = draftData.media_gallery.map(elem => ({
								media_url: elem.media_url,
								id: elem.id,
							}));
						}

						if (draftData.photo_main) {
							mainPhotoHelper = {
								media_url: draftData.photo_main,
								id: mediaGalleryHelper?.find(elem => elem.media_url === draftData.photo_main).id || "",
							};

							mediaGalleryHelper = mediaGalleryHelper?.filter(elem => elem.media_url !== mainPhotoHelper.media_url);
						} else {
							mainPhotoHelper = mediaGalleryHelper?.length ? mediaGalleryHelper[0] : undefined;

							if (mainPhotoHelper) {
								mediaGalleryHelper = mediaGalleryHelper.filter(elem => elem.id !== mainPhotoHelper.id);
							}
						}
					}

					if (draftData.schedule?.availability_periods && draftData.schedule.availability_periods.length === 1) {
						if (
							draftData.schedule.availability_periods[0].from === "01-01" &&
							draftData.schedule.availability_periods[0].to === "12-31"
						) {
							draftData.schedule.availability_periods = [];
						}
					}

					if (draftData?.schedule?.available_days_of_week) {
						draftData.schedule.available_days_of_week = {
							...daysEmptyValues,
							...draftData.schedule.available_days_of_week,
						};
					}

					return {
						...initialValuesPerStep[currentStep],
						...draftData,
						...(mediaGalleryHelper && { media_gallery_HELPER: mediaGalleryHelper }),
						...(mainPhotoHelper && { photo_main_HELPER: mainPhotoHelper }),
					};
				},
				handleSubmit,
			})(({ ...formikProps }: FormikProps<IExperience>) => (
				<CreationProcessStepper
					{...formikProps}
					currentStep={currentStep}
					saveMediaCallback={saveMediaFile}
					deleteMediaCallback={deleteMediaFile}
				/>
			)),
		// eslint-disable-next-line
		[currentStep, draftData],
	);

	return (
		<AppWrapper>
			<Container>{isLoading ? <Loader /> : <CreationProcessWithFormik />}</Container>
		</AppWrapper>
	);
};

export default ExperienceCreatePage;
