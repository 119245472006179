import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { cloneElement, ReactElement, useState } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import Logo from "ui/Logo";

const StyledDialogTitle = styled(DialogTitle)`
	padding-left: 0;
	padding-right: 0;
`;

interface IConfirmDialog {
	onConfirm: (...props: any) => unknown;
	onReject?: (...props: any) => unknown;
	children?: ReactElement;
	title: string | ReactElement;
	message?: string | ReactElement;
	disabled?: boolean;
}

const ConfirmDialog = ({ children, onConfirm, onReject, disabled, title, message }: IConfirmDialog) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState<boolean>(!children && !disabled);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);

		if (!!onReject) {
			onReject();
		}
	};

	const handleConfirm = () => {
		handleClose();

		onConfirm();
	};

	if (disabled && !!children) {
		return children;
	}

	return (
		<>
			{!!children && cloneElement(children, { onClick: handleClickOpen })}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<Logo version="localbiniBig" />
				</DialogContent>

				<DialogContent>
					<StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>

					{!!message && <DialogContentText id="alert-dialog-description">{message}</DialogContentText>}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{t("POPUP.DISAGREE")}
					</Button>

					<Button onClick={handleConfirm} variant="contained" color="primary" autoFocus>
						{t("POPUP.AGREE")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ConfirmDialog;
