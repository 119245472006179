import { Box } from "@material-ui/core";
import { CreateOutlined, CropOriginal, StarBorder } from "@material-ui/icons";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import AutocompleteV2Field from "../../formik/AutocompleteV2Field";

export const TitlePage = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 34px;
	font-weight: 400;
	line-height: 1.15;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
  `};
`;

export const TitleSection = styled.h2`
	font-family: ${MontserratFontFamily};
	font-size: 22px;
	font-weight: 400;
	line-height: 1.33;
	letter-spacing: normal;
	color: ${colors.mainFontColor};
	margin-bottom: 0;

	${media.tablet`
    font-size: 16px;
    line-height: 1.25;
  `};
`;

export const TitleSectionWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MainPhotoBox = styled.div`
	box-sizing: border-box;
	width: 136px;
	height: 30px;
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colors.mainFontColor};
	background: ${colors.white};
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1;
	border-radius: 4px;
`;

export const EditBtn = styled.div<{ marginRight?: boolean }>`
	margin-right: ${props => (props.marginRight ? "1.25rem" : 0)};
	width: 36px;
	min-width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;
	box-shadow: 0 0 1px -2px rgba(255, 255, 255, 0.1);
	background-color: #fafafa;
	cursor: pointer;

	${media.largeDesktop`
    margin-right: 0;
  `};

	&:hover {
		background-color: #ededed;
	}
`;

export const BoxWithGrayBackground = styled(Box)<{ $calcWidth: number }>`
	position: relative;

	&::before {
		content: "";
		background-color: ${colors.lightGray};
		position: absolute;
		width: calc(${props => props.$calcWidth}px + ((${props => props.$calcWidth}px - 1340px) / 2));
		height: 100%;
		z-index: 0;
		top: 0;
		left: calc(-${props => props.$calcWidth}px + 1340px);

		${media.largeDesktop // @ts-ignore prettier-ignore
		`width: calc(${props => props.$calcWidth}px + 100%);
      left: -${props => props.$calcWidth / 2}px;
  `};
	}
`;

export const StyledStarBorderIcon = styled(StarBorder)`
	font-size: 24px;
	color: ${colors.black};
`;

export const StyledCropOriginalIcon = styled(CropOriginal)`
	margin-right: 5px;
	font-size: 18px;
	color: ${colors.sunShade};
`;

export const CreateOutlinedIcon = styled(CreateOutlined)`
	font-size: ${dimensions.fontSize.xxxlarge};
	opacity: 0.7;
`;

export const StyledAutocomplete = styled(AutocompleteV2Field)`
	.MuiChip-root {
		background-color: ${colors.lightOrange};
	}

	.MuiAutocomplete-popupIndicator {
		color: ${colors.sunShade};
	}
`;
