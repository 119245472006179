import { MY_FINANCES_TABS, MY_SETTINGS_TABS, paths } from "routing/paths";

import fillRoute from "utils/routes";

interface IMenuConfig {
	key: string;
	link: string;
	translationKey: string;
	disabled?: boolean;
}

export const menuConfigMainLocalhost: IMenuConfig[] = [
	{
		key: "dashboard",
		link: paths.DASHBOARD,
		translationKey: "DASHBOARD_LABEL",
	},
	{
		key: "my_bookings",
		link: paths.MY_BOOKINGS,
		translationKey: "MY_BOOKINGS_LABEL",
	},
	{
		key: "my_experiences",
		link: paths.EXPERIENCES,
		translationKey: "MY_EXPERIENCES_LABEL",
	},
	{
		key: "my_finances",
		link: fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.PAST_EARNINGS }),
		translationKey: "MY_FINANCES_LABEL",
	},
	{
		key: "my_profile",
		link: paths.PROFILE,
		translationKey: "MY_PROFILE_LABEL",
	},
	{
		key: "my_referrals",
		link: paths.MY_REFERRALS,
		translationKey: "MY_REFERRALS_LABEL",
	},
	{
		key: "notifications",
		link: paths.NOTIFICATIONS,
		translationKey: "NOTIFICATIONS_LABEL",
	},
];

export const menuConfigMainTraveler: IMenuConfig[] = [
	{
		key: "my_bookings",
		link: paths.MY_BOOKINGS,
		translationKey: "MY_BOOKINGS_LABEL",
	},
	{
		key: "my-profile",
		link: paths.PROFILE,
		translationKey: "MY_PROFILE_LABEL",
	},
	{
		key: "notifications",
		link: paths.NOTIFICATIONS,
		translationKey: "NOTIFICATIONS_LABEL",
	},
];

export const menuConfigMainPartnerAgent: IMenuConfig[] = [
	{
		key: "bookings",
		link: paths.PARTNER_BOOKINGS,
		translationKey: "PARTNER_BOOKINGS",
	},
	{
		key: "notifications",
		link: paths.NOTIFICATIONS,
		translationKey: "NOTIFICATIONS_LABEL",
	},
];

export const menuConfigMainPartnerManager: IMenuConfig[] = [
	{
		key: "bookings",
		link: paths.PARTNER_BOOKINGS,
		translationKey: "PARTNER_BOOKINGS",
	},
	{
		key: "users",
		link: paths.PARTNER_USERS,
		translationKey: "PARTNER_USERS",
	},
	{
		key: "notifications",
		link: paths.NOTIFICATIONS,
		translationKey: "NOTIFICATIONS_LABEL",
	},
];

export const menuConfigAdditional: IMenuConfig[] = [
	{
		key: "settings",
		link: fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.ACCOUNT }),
		translationKey: "SETTINGS_LABEL",
	},
	{
		key: "faq",
		link: "",
		translationKey: "FAQ_LABEL",
		disabled: true,
	},
];
