import styled from "styled-components";

import AssuranceImg from "assets/images/help/assurance.svg";
import QAOEImg from "assets/images/help/icon-qaoe.svg";
import P2PImg from "assets/images/help/p2p.svg";
import QualityImg from "assets/images/help/quality.svg";
import QualityControlImgSmall from "assets/images/help/quality_control_main.png";
import QualityControlImgMedium from "assets/images/help/quality_control_main@2x.png";
import QualityControlImgLarge from "assets/images/help/quality_control_main@3x.png";
import VerificationImg from "assets/images/help/verification.svg";

import useTranslation from "hooks/useTranslation";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, ImgWrapper, MontserratFontFamily } from "styles/common";
import media from "styles/media";

const DescriptionText = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 14px;
	max-width: 80%;
	margin-top: 0;
	line-height: 1.86;
	${media.tablet`
    max-width: 100%;
  `};
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const ProcedureSection = styled.section`
	display: flex;
	flex-direction: row;

	${media.tablet`
      flex-direction: column;
  `};
`;

const Subtitle = styled.h3`
	margin-bottom: 0;
	font-family: ${MontserratFontFamily};
	font-size: 18px;
	line-height: 1.86;
	letter-spacing: 0.03px;
`;

const ProcedureWrapper = styled.div<{ withMargin?: boolean }>`
	${props => props.withMargin && `margin-top: 50px`};
`;

const Procedure = ({ withMargin = false, image, subtitle, description }) => (
	<ProcedureWrapper withMargin={withMargin}>
		<img src={image} title={`Procedure - ${subtitle}`} alt={`Procedure - ${subtitle}`} />
		<Subtitle>{subtitle}</Subtitle>
		<DescriptionText>{description}</DescriptionText>
	</ProcedureWrapper>
);

const QualityControlPage = () => {
	const { t, withRaw } = useTranslation();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.QUALITY_CONTROL.MAIN_TITLE")} />

				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.QUALITY_CONTROL.MAIN_TITLE")}
							description={withRaw("HELP.QUALITY_CONTROL.MAIN_DESCRIPTION", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Quality control - picture"
								imgSmall={QualityControlImgSmall}
								imgMedium={QualityControlImgMedium}
								imgLarge={QualityControlImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>

				<SmallerTitle>{t("HELP.QUALITY_CONTROL.SECOND_TITLE")}</SmallerTitle>

				<ProcedureSection>
					<Col>
						<Procedure
							description={t("HELP.QUALITY_CONTROL.PROCEDURE_DESC_1")}
							subtitle={t("HELP.QUALITY_CONTROL.PROCEDURE_TITLE_1")}
							image={QualityImg}
						/>

						<Procedure
							withMargin
							description={t("HELP.QUALITY_CONTROL.PROCEDURE_DESC_3")}
							subtitle={t("HELP.QUALITY_CONTROL.PROCEDURE_TITLE_3")}
							image={VerificationImg}
						/>

						<Procedure
							withMargin
							description={t("HELP.QUALITY_CONTROL.PROCEDURE_DESC_4")}
							subtitle={t("HELP.QUALITY_CONTROL.PROCEDURE_TITLE_4")}
							image={QAOEImg}
						/>
					</Col>

					<Col>
						<Procedure
							description={t("HELP.QUALITY_CONTROL.PROCEDURE_DESC_2")}
							subtitle={t("HELP.QUALITY_CONTROL.PROCEDURE_TITLE_2")}
							image={AssuranceImg}
						/>

						<Procedure
							withMargin
							description={t("HELP.QUALITY_CONTROL.PROCEDURE_DESC_5")}
							subtitle={t("HELP.QUALITY_CONTROL.PROCEDURE_TITLE_5")}
							image={P2PImg}
						/>
					</Col>
				</ProcedureSection>
			</Container>
		</AppWrapper>
	);
};

export default QualityControlPage;
