import { FormControl, FormControlLabel, FormHelperText, FormLabel } from "@material-ui/core";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel/FormControlLabel";
import { Field } from "formik";
import { ReactNode } from "react";
import styled from "styled-components";

import Checkbox, { ICheckbox } from "ui/forms/Checkbox";

interface IGroupProps {
	isGroup: true;
	value: string | number;
}

interface ISingleProps {
	isGroup?: false;
	value?: never;
}

interface ICheckboxField extends Omit<ICheckbox, "value"> {
	name: string;
	label?: ReactNode;
	formControlProps?: Omit<FormControlLabelProps, "label" | "control">;
	// @todo:chore - check the validation of all checkboxes using this field
	// if there are no duplicate errors then remove this field.
	withError?: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;

	.MuiFormLabel-root {
		font-size: 14px;
	}
`;

const CheckboxField = ({
	name,
	label,
	formControlProps,
	isGroup,
	withError,
	value,
	...rest
}: ICheckboxField & (IGroupProps | ISingleProps)) => (
	<Field name={name}>
		{({ field, form: { setFieldValue, setFieldTouched }, meta }) => {
			const fieldValue = field.value;

			const onChangeGroup = () => {
				const set = new Set(fieldValue || []);

				if (set.has(value)) {
					set.delete(value);
				} else {
					set.add(value);
				}

				setFieldTouched(name, true);

				setFieldValue(name, Array.from(set));
			};

			const onChangeSingle = () => {
				setFieldTouched(name, true);

				setFieldValue(name, !fieldValue);
			};

			return (
				<FormControl component="fieldset" required={rest.required}>
					<StyledFormControlLabel
						control={
							<Checkbox
								onChange={isGroup ? onChangeGroup : onChangeSingle}
								name={name}
								checked={isGroup ? field.value?.includes(value) : !!field.value}
								{...rest}
							/>
						}
						label={label && <FormLabel component="legend">{label}</FormLabel>}
						{...formControlProps}
					/>

					{meta.touched && !!meta.error && withError && <FormHelperText error>{meta.error}</FormHelperText>}
				</FormControl>
			);
		}}
	</Field>
);

export default CheckboxField;
