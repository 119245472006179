import {
	Avatar,
	Box,
	createStyles,
	IconButton,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import { memo, MouseEvent, ReactElement, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { currencySymbolsDictionary } from "data/dictionaries";
import { EPromotingSections, IExperienceInternal, IRatings, TExpType } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import BinipoolAvatar from "ui/BinipoolAvatar";
import RatingStars from "ui/RatingStars";

import colors from "styles/colors";
import { CurrencyWrapper, RobotoFontFamily, StyledDivider, StyledLink } from "styles/common";
import media from "styles/media";

import SharePopup from "../../Share/components/SharePopup";

const RecordLink = styled(StyledLink)<{ $noClick?: boolean }>`
	width: 100%;

	${props => props.$noClick && "pointer-events: none;"}
`;

const ImgContent = styled(Box)`
	position: relative;
	overflow: hidden;
	border-radius: 4px 4px 0 0;
`;

const Photo = styled(Box)<{ background: string; height: number }>`
	position: relative;
	background-image: url(${props => props.background});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px 4px 0 0;
	width: 100%;
	height: ${props => props.height}px;
	transition: all 0.5s linear;

	&:hover {
		transform: scale(1.1);
	}
`;

const NameLabel = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	color: ${colors.mainFontColor};
`;

const ImageOptions = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
	box-sizing: border-box;
`;

const ActionButton = styled(IconButton)`
	background-color: ${colors.sunShade};
	width: 100%;
	height: 100%;

	&:hover {
		background-color: ${colors.mainFontColor};
	}
`;

const ShareIcon = styled(ReplyIcon)`
	transform: scaleX(-1);
	color: ${colors.white};
`;

const MainTitle = styled.div`
	padding-right: 15px;
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: bold;
	line-height: 1.06;
	letter-spacing: 0.56px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;

	${media.desktop`
    font-size: 16px;
  `};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recordContent: {
			boxShadow: "0 0 8px 0 rgb(55 71 79 / 17%)",
			borderRadius: 4,
			background: theme.palette.common.white,
			color: theme.palette.text.homePageFont,
		},
		cityHoursText: {
			fontFamily: RobotoFontFamily,
			fontSize: 12,
			color: colors.secondaryGray,
			lineHeight: "20px",
		},
		priceRatingText: {
			fontFamily: RobotoFontFamily,
			fontSize: 12,
			lineHeight: "20px",
		},
		avatar: {
			[theme.breakpoints.down("sm")]: {
				width: theme.spacing(22),
				height: theme.spacing(22),
			},
			width: theme.spacing(33),
			height: theme.spacing(33),
			border: "4px solid white",
		},
		pictureContent: {
			[theme.breakpoints.down("sm")]: {
				height: "400px",
			},
			width: "100%",
			height: "480px",
		},
	}),
);

const config = {
	standard: {
		content: {
			mobile: {
				width: "100%",
				height: "auto",
			},
			desktop: {
				width: 434,
				height: 650,
			},
		},
		img: {
			mobile: {
				height: 400,
			},
			desktop: {
				height: 480,
			},
		},
	},
	small: {
		content: {
			mobile: {
				width: "100%",
				height: 450,
			},
			desktop: {
				width: 434,
				height: 550,
			},
		},
		img: {
			mobile: {
				height: 300,
			},
			desktop: {
				height: 380,
			},
		},
	},
};

export interface IRecordHPProps {
	id?: string;
	avatarUrl?: string;
	city: string;
	hours: string | null;
	isSmallerImgExplore?: boolean;
	imgUrl?: string;
	name?: string;
	pricePerPerson: number;
	ratings: IRatings;
	title: string;
	// @todo:fix - remove optional
	priceCurrency?: string;
	exp_type: TExpType;
	description?: string;
	fullWidth?: boolean;
	exposures?: IExperienceInternal["exposures"];
	customLink?: string;
	optionsElem?: ReactElement;
}

const RecordHP = memo(
	({
		id,
		avatarUrl,
		city,
		hours,
		isSmallerImgExplore = false,
		imgUrl,
		name,
		pricePerPerson,
		ratings,
		title,
		priceCurrency = "EUR",
		exp_type,
		description,
		fullWidth,
		exposures,
		customLink,
		optionsElem,
	}: IRecordHPProps) => {
		const { t } = useTranslation();

		const classes = useStyles();
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

		const [showSharePopup, setShowSharePopup] = useState<boolean>(false);

		const boxSize = isSmallerImgExplore ? config.small : config.standard;
		const currency = currencySymbolsDictionary[priceCurrency || ""];
		const priceUponRequest = exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);

		const shareClickHandle = (ev: MouseEvent<HTMLButtonElement>) => {
			ev.preventDefault();

			setShowSharePopup(true);
		};
		const link = id
			? customLink || fillRoute(paths.SEARCH_EXPERIENCES, { title: slugify(title.toLowerCase()), id })
			: undefined;

		return (
			<>
				{showSharePopup && description && title && link && (
					<SharePopup
						showDialog={showSharePopup}
						setShowDialog={() => setShowSharePopup(false)}
						title={title}
						description={description}
						shareUrl={link}
						addHostName={true}
					/>
				)}

				<Box
					className={classes.recordContent}
					style={{
						width: !!fullWidth ? "100%" : isSmallScreen ? boxSize.content.mobile.width : boxSize.content.desktop.width,
						height: !!optionsElem
							? "auto"
							: isSmallScreen
							? boxSize.content.mobile.height
							: boxSize.content.desktop.height,
					}}
				>
					<RecordLink to={link || ""} $noClick={!link} $withoutHoverUnderline>
						<Box position="relative">
							<ImgContent>
								<Photo
									background={imgUrl || CoverImg}
									height={isSmallScreen ? boxSize.img.mobile.height : boxSize.img.desktop.height}
								/>

								{description && title && (
									<ImageOptions>
										<ActionButton aria-label="share" onClick={shareClickHandle}>
											<ShareIcon />
										</ActionButton>
									</ImageOptions>
								)}
							</ImgContent>

							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								flexDirection="column"
								style={{ position: "absolute", bottom: "-40px", right: exp_type === "UNIQUE" ? "20px" : "30px" }}
							>
								{exp_type === "UNIQUE" ? (
									<>
										<Avatar alt={`Avatar Local host - ${name}`} src={avatarUrl} className={classes.avatar} />

										<NameLabel>{name}</NameLabel>
									</>
								) : (
									<BinipoolAvatar />
								)}
							</Box>
						</Box>

						<Box p={10}>
							<Box height={isSmallScreen ? "80px" : "100px"}>
								<Box display="flex" alignItems="center" justifyContent="flex-start">
									<Box width="80%">
										<MainTitle>{title?.length > 40 ? title.slice(0, 40) + "..." : title}</MainTitle>
									</Box>
								</Box>

								<Box display="flex">
									<Typography className={classes.cityHoursText}>{city}</Typography>&nbsp;&nbsp;|&nbsp;&nbsp;
									<Typography className={classes.cityHoursText}>{hours}</Typography>
								</Box>
							</Box>

							<StyledDivider />

							<Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
								{!priceUponRequest ? (
									<Typography className={classes.priceRatingText}>
										{t("HP.DISCOVER.SEE_FROM_LABEL")}{" "}
										<strong>
											<CurrencyWrapper>{currency}</CurrencyWrapper>
											{pricePerPerson}{" "}
										</strong>{" "}
										{t("HP.DISCOVER.SEE_PER_PERSON_LABEL")}
									</Typography>
								) : (
									<Typography className={classes.priceRatingText}>
										{t("HP.DISCOVER.PRICE_UPON_REQUEST_LABEL")}
									</Typography>
								)}

								{ratings.count > 0 && (
									<RatingStars
										value={ratings.average}
										count={ratings.count}
										detailsRating={ratings.values}
										size="small"
										withoutModal={!ratings.values}
									/>
								)}
							</Box>
						</Box>
					</RecordLink>

					{!!optionsElem && (
						<Box bgcolor="#f2f2f2">
							<Box p={10} width="100%" display="flex" justifyContent="flex-end">
								{optionsElem}
							</Box>
						</Box>
					)}
				</Box>
			</>
		);
	},
);

export default RecordHP;
