import { Box, CircularProgress, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import BrainTreeDropIn from "braintree-web-drop-in";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import slugify from "slugify";

import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { EBookingSource, EBookingStatus } from "data/backoffice/bookings/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import bookingsServices from "services/api/bookings";
import financialsServices from "services/api/financials";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import {
	selectBrainTreeToken,
	selectExperienceTraveller,
	selectExperienceTravellerBooking,
} from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { getBrainTreeToken } from "store/slices/experienceTraveller";

import AppWrapper from "components/layout/AppWrapper";

import BookingInfo from "ui/BookingInfo";

import { Container } from "styles/common";

import { MainTitle, StyledAlert } from "../../shared.styled";
import BreadCrumbsBooking from "../BreadCrumbsBooking";
import DetailsBox from "../DetailsBox";
import Stepper from "../Stepper";

let brainTreeInstance:
	| undefined
	| { teardown(callback: () => void): void; requestPaymentMethod(params: any, callback: any): void };

const BookingCheckout = () => {
	const dispatch = useAppDispatch();

	const brainTreeToken = useAppSelector(selectBrainTreeToken);
	const booking = useAppSelector(selectExperienceTravellerBooking);
	const experience = useAppSelector(selectExperienceTraveller);
	const user = useAppSelector(selectUser);

	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

	const history = useHistory();
	const match = useRouteMatch();
	const { handleAndNotify } = useErrors();

	const { t, withRaw } = useTranslation();

	const [loader, setLoader] = useState<boolean>(true);
	const [error, setError] = useState<boolean | string>(false);
	const [instanceCounter, setInstanceCounter] = useState<number>(new Date().getTime());
	const [localBookingId, setLocalBookingId] = useState<string>();

	const brainTreeInstanceInitializing = useRef<boolean>(false);

	const partner = partnerStorage.getPartner();

	const partnerConciergeType = (
		[EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]
	).includes(user?.userType);

	const brainTreeContainer = `brainTree-container-${instanceCounter}`;

	const extendedError = typeof error === "string";

	const freeBooking = booking.pricing.final_gross_price === 0;

	useEffect(() => {
		if (!booking.eid) {
			history.push(`${match.url}?step=0`);
		}

		return () => {
			if (brainTreeInstance) {
				brainTreeInstance.teardown(() => {
					brainTreeInstance = undefined;
				});
			}
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!freeBooking && booking.eid) {
			dispatch(getBrainTreeToken(experience?.data?.pricing?.currency));
		}

		// eslint-disable-next-line
	}, [experience]);

	useEffect(() => {
		if (freeBooking) {
			bookingAction();
		}

		// eslint-disable-next-line
	}, [freeBooking]);

	useEffect(() => {
		const initializeBraintree = () => {
			if (brainTreeToken) {
				if (brainTreeInstanceInitializing.current) {
					return;
				}

				brainTreeInstanceInitializing.current = true;

				setLoader(false);

				BrainTreeDropIn.create(
					{
						authorization: brainTreeToken,
						container: `#${brainTreeContainer}`,
						threeDSecure: true,
						// paypal: {
						// 	flow: "vault",
						// },
					},
					(_, instance) => {
						if (instance) {
							brainTreeInstance = instance;
						} else {
							brainTreeInstanceInitializing.current = false;

							setInstanceCounter(new Date().getTime());
						}
					},
				);
			} else if (brainTreeToken === undefined) {
				setLoader(false);
			}
		};

		if (!freeBooking && !brainTreeInstanceInitializing.current) {
			if (brainTreeInstance) {
				brainTreeInstance.teardown(() => {
					brainTreeInstance = undefined;

					initializeBraintree();
				});
			} else {
				initializeBraintree();
			}
		}

		// eslint-disable-next-line
	}, [brainTreeToken, instanceCounter]);

	const createBooking = () => {
		return bookingsServices.postBooking({
			status: EBookingStatus.NEW,
			source: partnerConciergeType
				? EBookingSource.CONCIERGE
				: partner?.isActive()
				? EBookingSource.SUBDOMAIN
				: EBookingSource.WEB_APP,
			...(booking.comment && { comments: booking.comment }),
			...(booking.superbooking_id && { superbooking_id: booking.superbooking_id }),
			...(!booking.superbooking_id && {
				date_time: `${booking.date[0]}T${booking.hour[0]}:00`,
				eid: booking.eid,
				language: booking.language,
				private: booking.isPrivate,
			}),
			tickets: {
				adults: booking.postGeneratePriceSummaryPayload.tickets.adults,
				...(booking.postGeneratePriceSummaryPayload.tickets.kids?.length && {
					kids: booking.postGeneratePriceSummaryPayload.tickets.kids.map(elem => ({
						age: elem.age,
						quantity: elem.quantity,
					})),
				}),
			},
			traveler_details: { ...booking.travelerDetails, email: booking.travelerDetails.email || null },
			...(user?.id && !partnerConciergeType
				? { traveler_id: user.id }
				: {
						traveler_id: booking.createdUserId || null,
				  }),
			...(!!booking.promoCode && { promo_code: booking.promoCode }),
			...(!!booking.giftCard && { gift_card: booking.giftCard }),
			...((!!booking.affiliateCode || booking.affiliateCode === null) && { affiliate_code: booking.affiliateCode }),
		});
	};

	const bookingAction = async () => {
		setError(false);
		setLoader(true);

		try {
			const bookingData = await createBooking();

			if (bookingData.data?.id) {
				history.push(`${match.url}?confirmed=true&bok=${bookingData.data?.id}`);
			}
		} catch (e) {
			return handleErrors(e, true);
		}
	};

	const payAction = useCallback(() => {
		setError(false);

		brainTreeInstance?.requestPaymentMethod(
			{
				threeDSecure: {
					amount: booking.pricing.final_gross_price,
					...(booking.travelerDetails.email && { email: booking.travelerDetails.email }),
					...((booking.travelerDetails.first_name || booking.travelerDetails.last_name) && {
						billingAddress: {
							...(booking.travelerDetails.first_name && { givenName: slugify(booking.travelerDetails.first_name) }),
							...(booking.travelerDetails.last_name && { surname: slugify(booking.travelerDetails.last_name) }),
						},
					}),
					collectDeviceData: true,
				},
			},
			(err, payload) => {
				(async () => {
					if (!!payload?.nonce) {
						setLoader(true);

						try {
							if (payload?.threeDSecureInfo?.status === "lookup_enrolled") {
								throw new Error(t("BOOKING.CHECKOUT.VERIFICATION_CANCELLED"));
							}
						} catch (e) {
							return handleErrors(e, true);
						}

						let bookingId;

						if (!localBookingId) {
							try {
								const bookingData = await createBooking();

								bookingId = bookingData.data.id;

								setLocalBookingId(bookingId);
							} catch (e) {
								return handleErrors(e, true);
							}
						} else {
							bookingId = localBookingId;
						}

						try {
							if (bookingId) {
								const financialsConfirmation = await financialsServices.postBrainTreeCheckout({
									booking_id: bookingId,
									payment_method_nonce: payload.nonce,
									device_data: navigator.userAgent,
								});

								if (financialsConfirmation) {
									history.push(`${match.url}?confirmed=true&bok=${bookingId}`);
								}
							}
						} catch (e) {
							return handleErrors(e, false);
						}
					}
				})();
			},
		);

		// eslint-disable-next-line
	}, [booking, localBookingId, user, partnerConciergeType, match.url]);

	const handleErrors = (errorParam: any, simpleError: boolean) => {
		let errorMessage;

		try {
			// @ts-ignore
			errorMessage = errorParam?.getError()?.message;
		} catch {
			errorMessage = "";
		}

		setError(simpleError ? true : errorMessage || true);

		setLoader(false);

		brainTreeInstanceInitializing.current = false;

		setInstanceCounter(new Date().getTime());

		handleAndNotify(errorParam);
	};

	return (
		<AppWrapper>
			<Container>
				<BreadCrumbsBooking
					lastBreadcrumb={t(freeBooking ? "BOOKING_SUMMARY.PAYMENT_METHOD_FREE" : "BOOKING_SUMMARY.PAYMENT_METHOD")}
				/>

				<MainTitle>
					{t(freeBooking ? "BOOKING_SUMMARY.PAYMENT_METHOD_FREE" : "BOOKING_SUMMARY.PAYMENT_METHOD")}
				</MainTitle>

				<Box display="flex" alignItems="flex-start" justifyContent="space-between" marginTop="30px" marginBottom="60px">
					<Box flex={2} paddingRight={!isMediumScreen && "50px"}>
						{loader && (
							<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
								<CircularProgress color="primary" size={40} value={100} />
							</Box>
						)}

						{brainTreeToken === undefined && (
							<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
								<Alert severity="error">{t("BOOKING.CHECKOUT.PAYMENT_APPLET_ERROR")}</Alert>
							</Box>
						)}

						{error && (
							<Box marginBottom="25px">
								<StyledAlert severity="warning">
									{withRaw(
										extendedError
											? "BOOKING.CHECKOUT.PAYMENT_ERROR"
											: freeBooking
											? "BOOKING.CHECKOUT.BOOKING_ERROR_WITHOUT_PAYMENT"
											: "BOOKING.CHECKOUT.BOOKING_ERROR",
										null,
									)}

									{extendedError && (
										<div>
											{t("BOOKING.CHECKOUT.ERROR_DETAILS")}&nbsp;<strong>{error}</strong>
										</div>
									)}
								</StyledAlert>
							</Box>
						)}

						{!loader && <Box key={brainTreeContainer} id={brainTreeContainer} minHeight="200px" marginBottom="40px" />}

						<Stepper
							customNext={freeBooking ? bookingAction : payAction}
							backStep={1}
							isNextDisabled={freeBooking ? loader : !brainTreeToken || loader}
							backText={t("BOOKING.STEPPER.BACK")}
							nextText={t(freeBooking ? "BOOKING.SUMMARY.NEXT_STEP_WITHOUT_PAYMENT" : "BOOKING.CHECKOUT.NEXT_STEP")}
						/>
					</Box>

					<Hidden mdDown>
						<BookingInfo {...booking} />
					</Hidden>

					<Hidden lgUp>
						<DetailsBox hideButton {...booking} />
					</Hidden>
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default BookingCheckout;
