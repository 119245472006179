import * as Yup from "yup";

import useValidations from "hooks/useValidations";

const useValidate = () => {
	const {
		yup: {
			validations,
			requiredClearNameMinMaxLength,
			requiredEmail,
			requiredStrongPassword,
			requiredCheckbox,
			requiredStringMinMaxLength,
		},
	} = useValidations();

	return validations({
		name: requiredClearNameMinMaxLength(2, 50),
		family_name: requiredClearNameMinMaxLength(2, 50),
		email: requiredEmail,
		password: requiredStrongPassword,
		affiliate_code: Yup.string().when("affiliate_code_switch", {
			is: codeSwitch => codeSwitch,
			then: requiredStringMinMaxLength(2, 30),
		}),
		claims_adult: requiredCheckbox(),
	});
};

export default useValidate;
