import { format } from "date-fns";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import { difference, isEmpty } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { EPromotingSections, IExperienceDTO } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import experiencesService from "services/api/experiences";
import pricingService from "services/api/pricing";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCountry from "store/hooks/useCountry";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import { kidsCounter } from "utils/booking";
import { DATE_FORMATS, daysOfWeekDateIndex } from "utils/dates";
import { availableDaysOfWeek, generateDatesInRange } from "utils/experienceDates";

import { idForContainer } from "ui/InfoInput";

import BookingFormView from "./components/BookingFormView";
import SendRequestForPriceModal from "./components/SendRequestForPriceModal";
import { BookingFormValues } from "./types";
import createValidationSchema from "./validate";

const BookingForm = ({
	title,
	schedule,
	pricing,
	languages,
	is_verified,
	number_of_travelers,
	cancellation_policy_hours,
	cutoff_time_hours,
	internal,
	country,
}: Pick<
	IExperienceDTO,
	| "title"
	| "schedule"
	| "pricing"
	| "languages"
	| "is_verified"
	| "number_of_travelers"
	| "cancellation_policy_hours"
	| "cutoff_time_hours"
	| "internal"
	| "country"
>) => {
	const history = useHistory();
	const match = useRouteMatch();
	const { id: experienceId } = useParams<{ id: string }>();

	const { handleAndNotify } = useErrors();
	const { addError } = useNotification();

	const dispatch = useAppDispatch();

	const bookingData = useAppSelector(selectExperienceTravellerBooking);

	const { findByName } = useCountry();

	const [datesList, setDatesList] = useState<string[]>([]);
	const [timesList, setTimesList] = useState<string[]>([]);
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [isLoadingTimes, setIsLoadingTimes] = useState<boolean>(false);
	const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
	const [reloadData, setReloadData] = useState<boolean>(true);
	const [sendRequestModal, setSendRequestModal] = useState<boolean>(false);

	const affiliateErrorFirstTime = useRef<string>();

	const priceUponRequest = internal?.exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);

	const countryDetails = findByName(country || "");
	const showVat = !!countryDetails?.booking_vat_enabled;
	const vatRate = countryDetails?.vat;

	const actionSubmit = useCallback(
		(v: BookingFormValues, formikHelpers) => {
			if (
				bookingData.superbooking_id &&
				typeof bookingData.maxPeople === "number" &&
				v.adults + v.kids > bookingData.maxPeople
			) {
				addError(
					`For this last minute booking you can select max. ${bookingData.maxPeople} people. Please change form values.`,
				);
				formikHelpers.setFieldError(
					"adults",
					`For this last minute booking you can select max. ${bookingData.maxPeople} people.`,
				);

				return;
			}

			dispatch(
				experienceTravellerActions.updateBooking({
					language: v.language,
					date: v.date,
					hour: v.hour,
					adults: v.adults,
					kids: v.kids,
					pricing: v.pricing,
					selectedChildren: v.selectedChildren,
					isPrivate: false,
				}),
			);

			if (priceUponRequest || !pricing.filter_price) {
				setSendRequestModal(true);

				return;
			}

			history.push(`${match.url}?step=1`);
		},

		// eslint-disable-next-line
		[bookingData.superbooking_id],
	);

	const formikProps = useFormik({
		validationSchema: createValidationSchema(
			bookingData.superbooking_id ? undefined : number_of_travelers?.min,
			number_of_travelers?.max,
		),
		initialValues: {
			...bookingData,
			...(number_of_travelers?.min &&
				number_of_travelers.min > bookingData.adults && { adults: number_of_travelers.min }),
		},
		onSubmit: (values, formikHelpers) => actionSubmit(values, formikHelpers),
	});

	const {
		handleSubmit,
		setFieldValue,
		setTouched,
		setFieldTouched,
		validateForm,
		touched,
		errors,
		values: { date, hour, language, adults, kids, selectedChildren, pricing: formikPricing },
	} = formikProps;

	const touchedForm = !isEmpty(touched);

	const kidsData = useMemo(() => kidsCounter(selectedChildren, "kid-nr-"), [selectedChildren]);

	const getPrices = useCallback(async () => {
		try {
			setIsLoadingPrices(true);

			const data = {
				experience_id: experienceId,
				tickets: {
					adults,
					...(kidsData.length && { kids: kidsData }),
				},
				...(date.length && hour.length && { date_time: `${date[0]}T${hour[0]}:00` }),
			};

			const pricingData = await pricingService.postGeneratePriceSummary(data);

			let wrongAffiliate;

			if (pricingData.discount.affiliate_code?.error_message) {
				wrongAffiliate = true;

				if (!affiliateErrorFirstTime.current) {
					affiliateErrorFirstTime.current = pricingData.discount.affiliate_code.error_message;
				}
			}

			setFieldValue("pricing", pricingData);

			dispatch(
				experienceTravellerActions.updateBooking({
					postGeneratePriceSummaryPayload: data,
					...(wrongAffiliate && { affiliateCode: null }),
				}),
			);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setIsLoadingPrices(false);
		}

		// eslint-disable-next-line
	}, [adults, kidsData, date, hour, experienceId]);

	// For private booking box
	useEffect(() => {
		dispatch(
			experienceTravellerActions.updateBooking({
				language,
				date,
				hour,
				adults,
				kids,
				pricing: formikPricing,
				selectedChildren,
				showVat,
				vatRate,
			}),
		);

		// eslint-disable-next-line
	}, [language, date, hour, adults, kids, formikPricing, selectedChildren, showVat, vatRate]);

	useEffect(() => {
		if (bookingData.superbooking_id) {
			setDatesList(bookingData.date);
			setTimesList(bookingData.hour);

			setFieldValue("adults", 1); // Reset number_of_travelers.min
			setFieldValue("date", bookingData.date);
			setFieldValue("hour", bookingData.hour);
			setFieldValue("language", bookingData.language);

			const peopleSelector = document.getElementById(idForContainer);

			if (!!peopleSelector) {
				peopleSelector.click();
			}
		}

		// eslint-disable-next-line
	}, [bookingData.language, bookingData.date, bookingData.hour, bookingData.superbooking_id]);

	useEffect(() => {
		if (!bookingData.superbooking_id) {
			formikProps.resetForm();

			setReloadData(true);
		}

		// eslint-disable-next-line
	}, [bookingData.superbooking_id]);

	useEffect(() => {
		if (schedule && !bookingData.superbooking_id) {
			const availableDaysOfWeekValidation = availableDaysOfWeek(schedule.available_days_of_week);

			const availabilityPeriodsTemp: { from: string; to: string }[] = [];
			const currentYear = new Date().getFullYear();

			// Next 10 years
			const next10years = 10;
			schedule?.availability_periods?.forEach(e => {
				availabilityPeriodsTemp.push({ from: `${e.from}-${currentYear}`, to: `${e.to}-${currentYear}` });
				[...Array(next10years)].forEach((_, index) => {
					availabilityPeriodsTemp.push({
						from: `${e.from}-${currentYear + index + 1}`,
						to: `${e.to}-${currentYear + index + 1}`,
					});
				});
			});

			const tempDates: string[] = [];

			availabilityPeriodsTemp.forEach(el => {
				const getDatesAvailabilityPeriods = generateDatesInRange(el.from, el.to);

				getDatesAvailabilityPeriods.forEach(dateEl => {
					const newDate = new Date();

					const formattedDate = new Date(`${format(dateEl, DATE_FORMATS.DATE_FORMAT)}${DATE_FORMATS.TIME_START_DAY}`);
					const formattedToday = new Date(`${format(newDate, DATE_FORMATS.DATE_FORMAT)}${DATE_FORMATS.TIME_START_DAY}`);

					newDate.setHours(newDate.getHours() + (cutoff_time_hours || 0));
					const todayWithCutoff = new Date(
						`${format(newDate, DATE_FORMATS.DATE_FORMAT)}${DATE_FORMATS.TIME_START_DAY}`,
					);

					if (
						formattedDate >= formattedToday &&
						formattedDate >= todayWithCutoff &&
						availableDaysOfWeekValidation[daysOfWeekDateIndex[formattedDate.getDay()]]
					) {
						if (bookingData && bookingData?.selectedDates?.dateFrom && bookingData?.selectedDates?.dateTo) {
							if (
								formattedDate >= new Date(`${bookingData?.selectedDates.dateFrom}${DATE_FORMATS.TIME_START_DAY}`) &&
								formattedDate <= new Date(`${bookingData?.selectedDates.dateTo}${DATE_FORMATS.TIME_END_DAY}`)
							) {
								tempDates.push(format(dateEl, DATE_FORMATS.DATE_FORMAT));
							}
						} else {
							tempDates.push(format(dateEl, DATE_FORMATS.DATE_FORMAT));
						}
					}
				});
			});

			const datesFiltered = schedule.excluded_dates?.length
				? difference(tempDates, schedule.excluded_dates)
				: tempDates;

			setDatesList(datesFiltered);
		}

		// eslint-disable-next-line
	}, [schedule, bookingData.superbooking_id]);

	useEffect(() => {
		if (touchedForm) {
			if (!kids) {
				setFieldValue("selectedChildren", {});
			} else {
				const kidsAges = {};

				for (let i = 0; i < kids; i++) {
					kidsAges[`kid-nr-${i}`] = selectedChildren[`kid-nr-${i}`] || null;
				}

				setFieldValue("selectedChildren", kidsAges);
			}
		}

		// eslint-disable-next-line
	}, [kids]);

	useEffect(() => {
		if (!reloadData && date.length > 0 && !!date[0] && !bookingData.superbooking_id) {
			setIsLoadingTimes(true);

			const getTimesList = async () => {
				try {
					const availableHours = await experiencesService.getExperienceAvailabilityHours(experienceId, {
						date_from: date[0],
						date_to: date[0],
						number_of_travelers: adults,
					});

					if (!isEmpty(availableHours.data)) {
						setTimesList(availableHours.data[language][date[0]]);
					} else {
						setTimesList([]);

						setFieldValue("hour", []);
					}
				} catch (e) {
					setTimesList([]);

					setFieldValue("hour", []);
				} finally {
					setIsLoadingTimes(false);

					setTouched({ adults: true });
				}
			};

			getTimesList();
		}

		// eslint-disable-next-line
	}, [date, language, reloadData]);

	useEffect(() => {
		if (date.length && hour.length) {
			getPrices();
		}

		// eslint-disable-next-line
	}, [date, hour]);

	useEffect(() => {
		if (reloadData) {
			getPrices();

			setReloadData(false);
		}

		// eslint-disable-next-line
	}, [reloadData]);

	useEffect(() => {
		if (affiliateErrorFirstTime.current) {
			addError(affiliateErrorFirstTime.current);
		}

		// eslint-disable-next-line
	}, [affiliateErrorFirstTime.current]);

	const getDateFromCalendar = useCallback(
		v => {
			setFieldValue("date", [v]);

			setShowCalendar(false);

			const currentIndex = datesList.indexOf(v);

			if (currentIndex > 5) {
				const tempDatesTemp = new Set([v, ...datesList]);
				// @ts-ignore
				setDatesList([...tempDatesTemp]);
			}
		},

		// eslint-disable-next-line
		[datesList],
	);

	const externalHandleClosePeople = useCallback(async () => {
		const validationErrors = await validateForm();

		if (validationErrors.adults || validationErrors.kids || validationErrors.selectedChildren) {
			if (validationErrors.selectedChildren) {
				Object.keys(validationErrors.selectedChildren).forEach(key => {
					if (!touched.selectedChildren?.[key]) {
						setFieldTouched(`selectedChildren.${key}`, true);
					}
				});
			}

			return false;
		}

		setReloadData(true);

		return true;

		// eslint-disable-next-line
	}, []);

	return (
		<>
			{sendRequestModal && <SendRequestForPriceModal showModalHandler={setSendRequestModal} experienceTitle={title} />}

			<FormikProvider value={formikProps}>
				<Formik>
					<BookingFormView
						title={title}
						cancellation_policy_hours={cancellation_policy_hours}
						is_verified={is_verified}
						handleSubmit={handleSubmit}
						datesList={datesList}
						timesList={timesList}
						showCalendar={showCalendar}
						isLoadingTimes={isLoadingTimes}
						isLoadingPrices={isLoadingPrices}
						languages={languages}
						touchedForm={touchedForm}
						setShowCalendar={setShowCalendar}
						formikPricing={formikPricing}
						kids={kids}
						adults={adults}
						getDateFromCalendar={getDateFromCalendar}
						errors={errors}
						values={formikProps.values}
						externalHandleClosePeople={externalHandleClosePeople}
						internal={internal}
						filterPrice={pricing.filter_price}
					/>
				</Formik>
			</FormikProvider>
		</>
	);
};

export default BookingForm;
