import { Box, Typography } from "@material-ui/core";
import { BoxProps } from "@material-ui/core/Box/Box";
import { ChipProps } from "@material-ui/core/Chip/Chip";
import { TypographyProps } from "@material-ui/core/Typography/Typography";

import { IDaysOfWeek } from "data/types";

import useTranslation from "hooks/useTranslation";

import ChipStringArray from "../../../ChipStringArray";
import { TitleSection } from "../../shared.styled";

interface IAvailableDaysProps {
	daysArray?: IDaysOfWeek;
	chipsColor?: ChipProps["color"];
	titleStr?: string;
	labelProps?: TypographyProps;
	boxItemProps?: BoxProps;
	compactSize?: boolean;
	className?: string;
}

const AvailableDays = ({
	daysArray,
	chipsColor = "primary",
	titleStr,
	boxItemProps,
	labelProps,
	compactSize,
	className,
}: IAvailableDaysProps) => {
	const { withTaggedKey } = useTranslation();

	return (
		<Box className={className}>
			{!!titleStr && (
				<Box mb={11}>
					<TitleSection>{titleStr}</TitleSection>
				</Box>
			)}

			{daysArray &&
				Object.entries(daysArray).map(
					([k, v], i) =>
						v.length > 0 && (
							<Box
								key={`${k}-${v.join("-")}-${i}`}
								display="flex"
								alignItems="center"
								mb={compactSize ? 0 : 4}
								pl={12}
								pr={10}
								bgcolor="#fff"
								borderRadius={4}
								width="max-content"
								{...boxItemProps}
							>
								<Box mr={14} width="100px">
									<Typography {...labelProps}>
										{withTaggedKey("EXPERIENCE_DETAILS_LOCALS.DAYS_OF_WEEK.{k}", { k })}
									</Typography>
								</Box>

								<Box>
									<ChipStringArray valuesArray={v} color={chipsColor} />
								</Box>
							</Box>
						),
				)}
		</Box>
	);
};

export default AvailableDays;
