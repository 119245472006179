import SaveAltOutlined from "@material-ui/icons/SaveAltOutlined";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";

const StyledSaveIcon = styled(SaveAltOutlined)`
	width: 16px;
	height: 16px;
	opacity: 0.6;
	cursor: pointer;
`;

interface ILinkDownloadFileICS {
	title: string;
	startDate: string;
	endDate: string;
	timeZone: string;
	bookingId: string;
	description?: string;
	location?: string;
}

const LinkDownloadFileICS = ({
	title,
	startDate,
	endDate,
	timeZone,
	bookingId,
	description,
	location,
}: ILinkDownloadFileICS) => {
	const { t } = useTranslation("ui");

	// See https://stackoverflow.com/questions/64249208/how-to-get-ics-file-to-adjust-to-local-time-instead-of-changed-time
	// TZID is probably hack, can not work in some calendars
	const icsData = `BEGIN:VCALENDAR
PRODID:${bookingId}
VERSION:2.0
BEGIN:VEVENT
UID:${bookingId}
DTSTAMP:${parseISOWrapper(new Date().toISOString(), DATE_FORMATS.DATETIME_GOOGLE_CALENDAR_FORMAT)}
DTSTART;TZID="${timeZone}":${startDate}
DTEND;TZID="${timeZone}":${endDate}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location?.replace(/,/g, "\\,")}
END:VEVENT
END:VCALENDAR`;

	const handleDownload = () => {
		const blob = new Blob([icsData], { type: "text/calendar;charset=utf-8" });

		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.setAttribute("download", `${title} ${parseISOWrapper(startDate, DATE_FORMATS.DATETIME_FORMAT)}.ics`);

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div title={t("LINK_DOWNLOAD_FILE_ICS.LABEL")}>
			<StyledSaveIcon onClick={handleDownload} />
		</div>
	);
};

export default LinkDownloadFileICS;
