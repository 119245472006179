import tripImage from "assets/images/background/trip_photo.jpg";

import LoginView from "components/auth/LoginView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

import { LanguageSelectorOnboarding, TeaserOnboarding } from "../index";
import { LanguageSelectorContainer } from "../shared.styled";

const AuthOnboardingLoginPage = () => (
	<AppWithImageWrapper
		image={tripImage}
		teaserElem={<TeaserOnboarding />}
		headerElem={
			<LanguageSelectorContainer>
				<LanguageSelectorOnboarding />
			</LanguageSelectorContainer>
		}
	>
		<LoginView onboarding />
	</AppWithImageWrapper>
);

export default AuthOnboardingLoginPage;
