import { Box, Divider } from "@material-ui/core";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import media from "styles/media";

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	${media.largeDesktop`
    justify-content: center;
    align-items: center;
  `};
`;

export const StyledDividerSection = styled(Divider)`
	margin: 30px 0;
	border: solid 1px #e6e6e6;
`;

export const TitleSection = styled.h1`
	margin: 0;
	font-size: 18px;
	font-family: ${RobotoFontFamily};
	font-weight: 300;
	line-height: 1.33;
	letter-spacing: 0.1px;
	color: ${colors.darkGreen};
`;

export const CircularProgressContent = styled(Box)`
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const FiltersWrapper = styled.div`
	display: flex;
	column-gap: 10px;
	flex-wrap: wrap;

	${media.tablet`
    margin-top: 15px;
    gap: 10px;
  `};
`;

export const ModalTitle = styled.h1`
	margin-top: 5px;
	font-size: 24px;
	font-family: ${MontserratFontFamily};
	line-height: 31px;
	font-weight: 600;
`;

export const ModalContainer = styled(Box)`
	text-align: center;
	padding-top: 45px;

	svg {
		width: 42px;
		height: 50px;
	}
`;
