import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import { IRateIcons, IRatingsSettings } from "../types";
import RatingsList from "./RatingsList";

const RateSection = styled.div`
	margin-bottom: 33px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const RateCategoryName = styled.strong`
	font-size: 12px;
	font-weight: bold;
	color: ${colors.mainFontColor};
	font-family: ${RobotoFontFamily};
`;

interface IRatesSection {
	icons: IRateIcons;
	categoryName?: string | null;
	categoryKey: string;
	settings: IRatingsSettings;
}

const RatingsSection = ({ icons, categoryName, categoryKey, settings }: IRatesSection) => {
	const { t } = useTranslation();

	return (
		<RateSection>
			{!!categoryName && <RateCategoryName>{t(categoryName)}</RateCategoryName>}

			<RatingsList icons={icons} categoryKey={categoryKey} settings={settings} />
		</RateSection>
	);
};

export default RatingsSection;
