import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TUserIId } from "data/users/types";

import usersService from "services/api/users";

interface TUsersBackofficeStateData {
	users: TUserIId[] | null;
	count: number | null;
}

export interface IUsersBackofficeState {
	data: TUsersBackofficeStateData;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IUsersBackofficeState = {
	data: {
		users: null,
		count: null,
	},
	status: "idle",
	error: null,
};

export const fetchUsersBackoffice = createAsyncThunk<any, any>(
	"usersBackoffice/fetch",
	async (queryParams = {}, thunkAPI) => {
		try {
			const usersList = await usersService.getAllUsers(queryParams);

			return usersList;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const { actions, reducer } = createSlice({
	name: "usersBackoffice",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: {
		[fetchUsersBackoffice.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.users = action.payload.data;
			state.data.count = action.payload.count;
			state.status = "succeeded";
			state.error = null;
		},
		[fetchUsersBackoffice.pending.toString()]: state => {
			state.status = "loading";
			state.error = null;
		},
		[fetchUsersBackoffice.rejected.toString()]: state => {
			state.status = "failed";
			state.error = true;
		},
	},
});

export const usersBackofficeActions = actions;

export default reducer;
