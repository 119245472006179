import { Box, Button, Table as BaseTable, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import { Sort } from "@material-ui/icons";
import { get, isNumber, isUndefined } from "lodash-es";
import { rgba } from "polished";
import React, { ReactElement, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const RowWrapper = styled(TableRow)<{ color?: string }>`
	min-height: 60px;
	${props => props.color && `background-color: ${props.color};`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledTableHead = styled(TableHead)<{ $sticky?: boolean | number }>`
	background: ${rgba("#cfd8dc", 0.6)};
	color: ${colors.mainFontColor};
	max-height: 60px;
	border: 1px solid ${colors.borderGray};

	${props =>
		props.$sticky &&
		css`
			position: sticky;
			top: ${typeof props.$sticky === "number" ? props.$sticky : 0}px;
			z-index: 100;
			background-color: #cfd8dc;
		`}
`;

const StyledTableCell = styled(TableCell)<{ $isOpened?: boolean; $isCollapse?: boolean; $isPrev?: boolean }>`
	border: 1px solid ${props => (props.$isOpened ? `${colors.oceanDrive} !important` : colors.borderGray)};
	${props => props.$isPrev && `border-bottom-color: ${colors.oceanDrive};`}
	${props => props.$isCollapse && `padding: 0; border: none;`}
`;

const StyledFilterListIcon = styled(Sort)<{ rotate: Order }>`
	${props => props.rotate === "asc" && "transform: rotate(180deg);"}
`;

const StyledButton = styled(Button)`
	margin-left: 10px;
	width: 24px;
	height: 24px;
	padding-left: 0;
	padding-right: 0;
	min-width: auto;
`;

const StyledBox = styled(Box)<{ $minWidth: string }>`
	display: flex;
	align-items: center;
	min-width: ${props => props.$minWidth};
`;

const defaultMinWidth = "100px";

const sortData = (arrayToSort: TTableData, nameField: string, sortOrder: Order) =>
	[...arrayToSort].sort((a, b) => {
		try {
			const getA = get(a, nameField);
			const getB = get(b, nameField);

			let valueA = isUndefined(getA?.sortValue) ? getA : getA.sortValue;
			let valueB = isUndefined(getB?.sortValue) ? getB : getB.sortValue;

			if (isNumber(valueA) && isNumber(valueB)) {
				return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
			}

			valueA = valueA.toString();
			valueB = valueB.toString();

			if (!valueA || !valueB) {
				return false;
			}

			if (sortOrder === "asc") {
				return valueA.localeCompare(valueB);
			}

			return valueB.localeCompare(valueA);
		} catch {
			return false;
		}
	});

export type Order = "asc" | "desc";

type TTableData = { [key: string]: any; TABLE__ALERT?: boolean }[];

interface TableProps {
	id?: string;
	data: TTableData;
	columns: string[];
	fields: string[];
	sortedColumns?: string[];
	externalSort?: (nameField: string, order: Order, orderBy: number) => void;
	initialOrder?: Order;
	initialOrderBy?: number | null;
	rowOpenedIndex?: number;
	rowOpenedExternalKey?: string;
	rowOpenedExternalCurrent?: string | number;
	rowOpenedContent?: ReactElement | false;
	closedOpenedRow?: () => void;
	tableCellProps?: TableCellProps;
	tableAlertColor?: string;
	tableBackgroundEven?: string;
	tableBackgroundOdd?: string;
	totalRecords?: number;
	stickyHeader?: boolean | number;
	defaultMinWidthColumns?: string[];
}

const Table = ({
	id,
	data,
	columns,
	fields,
	sortedColumns,
	externalSort,
	initialOrder,
	initialOrderBy,
	rowOpenedIndex,
	rowOpenedExternalKey,
	rowOpenedExternalCurrent,
	rowOpenedContent,
	closedOpenedRow,
	tableCellProps,
	tableAlertColor = "#ffe1e1",
	tableBackgroundEven = "rgba(255, 255, 255, 0.15)",
	tableBackgroundOdd = "#f2f2f2",
	totalRecords,
	stickyHeader,
	defaultMinWidthColumns,
}: TableProps) => {
	const [order, setOrder] = useState<Order>(initialOrder || "asc");
	const [orderBy, setOrderBy] = useState<number | null>(initialOrderBy || null);
	const [sortedData, setSortedData] = useState<TTableData>(data);

	useEffect(() => {
		if (!!externalSort || !isNumber(orderBy)) {
			setSortedData(data);

			return;
		}

		setSortedData(sortData(data, fields[orderBy], order));

		// eslint-disable-next-line
	}, [orderBy, order, data]);

	useEffect(() => {
		if (closedOpenedRow) {
			closedOpenedRow();
		}

		setOrderBy(prevOrderBy => (initialOrderBy === 0 || initialOrderBy ? initialOrderBy : prevOrderBy));
		setOrder(prevOrder => initialOrder || prevOrder);

		// eslint-disable-next-line
	}, [initialOrder, initialOrderBy]);

	const updateData = (columnNumber: number) => {
		if (closedOpenedRow) {
			closedOpenedRow();
		}

		const fieldName = fields[columnNumber];
		const newOrder = order === "asc" ? "desc" : "asc";

		if (externalSort) {
			externalSort(fieldName, newOrder, columnNumber);

			return;
		}

		setOrderBy(columnNumber);
		setOrder(newOrder);
	};

	return (
		<Wrapper id={id}>
			{totalRecords !== undefined && (
				<Box textAlign="right" fontFamily={RobotoFontFamily}>
					Table records: visible <strong>{sortedData.length}</strong> / <strong>{totalRecords}</strong> total
				</Box>
			)}

			<BaseTable>
				<StyledTableHead $sticky={sortedData.length ? stickyHeader : undefined}>
					<TableRow>
						{columns.map((v, index) => (
							<StyledTableCell key={`${v}-${index}`} {...tableCellProps}>
								<StyledBox $minWidth={defaultMinWidthColumns?.includes(v) ? defaultMinWidth : "unset"}>
									{v}
									{sortedColumns ? (
										sortedColumns.some(key => key === v) && (
											<StyledButton onClick={() => updateData(index)}>
												<StyledFilterListIcon rotate={!!order && orderBy === index ? order : "asc"} />
											</StyledButton>
										)
									) : (
										<StyledButton onClick={() => updateData(index)}>
											<StyledFilterListIcon rotate={!!order && orderBy === index ? order : "asc"} />
										</StyledButton>
									)}
								</StyledBox>
							</StyledTableCell>
						))}
					</TableRow>
				</StyledTableHead>

				<TableBody>
					{sortedData.map((row, index, array) => {
						const rowOpenedExternal = rowOpenedExternalKey && rowOpenedExternalCurrent;

						const isOpened = rowOpenedExternal
							? rowOpenedExternalCurrent === get(row, rowOpenedExternalKey) && !!rowOpenedContent
							: index === rowOpenedIndex && !!rowOpenedContent;

						const isPrev = rowOpenedExternal
							? index === array.findIndex(elem => rowOpenedExternalCurrent === get(elem, rowOpenedExternalKey)) - 1 &&
							  !!rowOpenedContent
							: !!rowOpenedIndex &&
							  (index === rowOpenedIndex - 1 || (index === rowOpenedIndex && index === 0)) &&
							  !!rowOpenedContent;

						return (
							<React.Fragment key={typeof get(row, "id") === "string" ? get(row, "id") : index}>
								<RowWrapper
									color={
										get(row, "TABLE__ALERT") ? tableAlertColor : index % 2 ? tableBackgroundEven : tableBackgroundOdd
									}
								>
									{fields.map((field, i) => (
										<StyledTableCell
											key={`row-${field}-${i}`}
											$isOpened={isOpened}
											$isPrev={isPrev}
											{...tableCellProps}
										>
											{get(row, field)?.value || get(row, field)}
										</StyledTableCell>
									))}
								</RowWrapper>

								{isOpened && (
									<RowWrapper>
										<StyledTableCell $isCollapse colSpan={columns.length}>
											{rowOpenedContent}
										</StyledTableCell>
									</RowWrapper>
								)}
							</React.Fragment>
						);
					})}
				</TableBody>
			</BaseTable>
		</Wrapper>
	);
};

export default Table;
