import { Box, Divider, Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import styled from "styled-components";

import ReferralImage from "assets/icons/referrals/referral-image.svg";

import AffiliateSession from "classes/AffiliateSession";

import { ECurrency } from "data/financials/types";
import { EPromoCodesUsageType, IPromoCodeUsageNotGenerated, TPromoCodeIId } from "data/promo-codes/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import promoCodesService from "services/api/promoCodes";

import { dateObjectToString, DATE_FORMATS, getMonthName } from "utils/dates";
import { prices } from "utils/prices";

import ActionButton from "ui/buttons/ActionButton";
import CircularLoader from "ui/loaders/CircularLoader";

import colors from "styles/colors";
import { StyledLink } from "styles/common";

const StyledActionButton = styled(ActionButton)`
	color: ${colors.sunShade} !important;
	background-color: ${colors.white} !important;
	box-shadow: none !important;
`;

interface IGenerateReferralBox {
	variant?: "simple" | "withStatistics";
}

const GenerateReferralBox = ({ variant = "simple" }: IGenerateReferralBox) => {
	const { t, withRaw, withValues } = useTranslation();

	const { handleAndNotify } = useErrors();
	const { addSuccess } = useNotification();

	const [loader, setLoader] = useState<boolean>(false);
	const [codeGenerated, setCodeGenerated] = useState<boolean | null>(null);
	const [userCode, setUserCode] = useState<string>("");
	const [benefits, setBenefits] = useState<{
		booking: number | null;
		localhost: number | null;
		new_localhost: number | null;
		currency: ECurrency;
		usage?: number | null;
		bookingsAmount?: number | null;
		recommendationsAmount?: number | null;
	}>({
		booking: null,
		localhost: null,
		new_localhost: null,
		currency: ECurrency.EUR,
	});

	const withStatistics = variant === "withStatistics";

	useEffect(() => {
		const fetchCode = async () => {
			try {
				const data = (await promoCodesService.getAffiliateCode()) as TPromoCodeIId;

				if (data) {
					let promoCodesUsage;

					if (withStatistics) {
						const now = new Date();

						promoCodesUsage = await promoCodesService.getUsage({
							type: [
								EPromoCodesUsageType.BOOKING_PROFIT,
								EPromoCodesUsageType.NEW_LOCALHOST_PROFIT,
								EPromoCodesUsageType.NEW_LOCALHOST_BONUS,
							],
							min_created_on:
								dateObjectToString(new Date(now.getFullYear(), now.getMonth(), 1), DATE_FORMATS.DATE_FORMAT) +
								DATE_FORMATS.TIME_START_DAY,
							max_created_on:
								dateObjectToString(new Date(now.getFullYear(), now.getMonth() + 1, 0), DATE_FORMATS.DATE_FORMAT) +
								DATE_FORMATS.TIME_END_DAY,
						});
					}

					setBenefits({
						localhost: data.non_traveler_benefits.localhost_recruitment_bonus || null,
						new_localhost: data.non_traveler_benefits.new_localhost_signup_bonus || null,
						booking: data.non_traveler_benefits.flat_gain,
						currency: data.currency || ECurrency.EUR,
						usage: data.usage_count,
						...(promoCodesUsage && {
							bookingsAmount: promoCodesUsage.statistics?.by_type?.BOOKING_PROFIT,
							recommendationsAmount: promoCodesUsage.statistics?.by_type?.NEW_LOCALHOST_PROFIT,
						}),
					});

					setUserCode(data.code || "");

					setCodeGenerated(true);
				} else {
					setCodeGenerated(false);
				}

				setLoader(false);
			} catch (e) {
				// @ts-ignore
				if (e?.getStatus && e.getStatus() === 404) {
					// @ts-ignore
					const errorData = e.getError() as IPromoCodeUsageNotGenerated;

					if (errorData) {
						setBenefits({
							localhost: errorData.non_traveler_benefits.localhost_recruitment_bonus,
							new_localhost: errorData.non_traveler_benefits.new_localhost_signup_bonus,
							booking: errorData.non_traveler_benefits.flat_gain,
							currency: ECurrency.EUR,
						});
					}
				} else {
					handleAndNotify(e);
				}

				setCodeGenerated(false);
			}
		};

		if ((codeGenerated === null || codeGenerated === true) && !userCode) {
			fetchCode();
		}

		// eslint-disable-next-line
	}, [codeGenerated]);

	const generateCode = async () => {
		try {
			setLoader(true);

			await promoCodesService.postCreateAffiliateCode();

			setCodeGenerated(true);
		} catch (e) {
			setLoader(false);

			handleAndNotify(e);
		} finally {
			setCodeGenerated(false);
		}
	};

	const nowDate = new Date();

	return (
		<Grid container style={{ width: "100%", height: "100%" }} spacing={withStatistics ? 10 : undefined}>
			<Grid item md={withStatistics ? 8 : 12} xs={12}>
				<Box
					width="100%"
					height="100%"
					borderRadius="4px"
					bgcolor={codeGenerated !== null ? (userCode ? colors.sunShade : "#fb8c00") : undefined}
					color={colors.white}
					position="relative"
					boxShadow={codeGenerated !== null ? "0 0 40px 0 rgba(30, 30, 30, 0.12)" : undefined}
				>
					{codeGenerated !== null ? (
						<Grid container style={{ width: "100%", height: "100%" }}>
							<Grid item md={8} xs={12}>
								<Box padding="20px" display="flex" height="100%" flexWrap="wrap" alignItems="center">
									<Box marginRight="32px" marginBottom="20px" width="22%">
										<img src={ReferralImage} alt="Referral code" />
									</Box>

									{!userCode ? (
										<div>
											<Box fontSize="22px" marginBottom="8px">
												{t("GENERATE_REFERRAL_BOX.GENERATE.TITLE")}
											</Box>

											<Box fontSize="14px" lineHeight="1.43" marginBottom="16px">
												{withRaw("GENERATE_REFERRAL_BOX.GENERATE.SUBTITLE", null)}
											</Box>

											<StyledActionButton
												onClick={generateCode}
												translationDefault="GENERATE_REFERRAL_BOX.GENERATE.CTA"
												padding={20}
												color="default"
												isAction={loader}
											/>
										</div>
									) : (
										<div>
											<Box fontSize="22px" marginBottom="20px">
												{t("GENERATE_REFERRAL_BOX.TITLE")}
											</Box>

											<Box
												bgcolor="#fb8c00"
												borderRadius="4px"
												fontSize="16px"
												fontWeight="bold"
												textAlign="center"
												paddingY="8px"
												paddingX="50px"
												border={`2px dashed ${colors.white}`}
											>
												{userCode}
											</Box>

											<Box display="flex" gridGap="12px" marginTop="12px">
												<StyledActionButton
													onClick={() => {
														copy(userCode);

														addSuccess("GENERATE_REFERRAL_BOX.COPY_CODE_MESSAGE", true);
													}}
													translationDefault="GENERATE_REFERRAL_BOX.COPY_CODE"
													padding={32}
													color="default"
												/>

												<StyledActionButton
													onClick={() => {
														copy(
															`${process.env.REACT_APP_SITE_URL}/?${AffiliateSession.affiliateUrlParam}=${userCode}`,
														);

														addSuccess("GENERATE_REFERRAL_BOX.COPY_LINK_MESSAGE", true);
													}}
													translationDefault="GENERATE_REFERRAL_BOX.COPY_LINK"
													padding={32}
													color="default"
												/>
											</Box>
										</div>
									)}
								</Box>
							</Grid>

							<Grid item md={4} xs={12}>
								<Box
									display="flex"
									flexDirection="column"
									height="100%"
									padding="20px"
									fontSize="14px"
									bgcolor={userCode ? "#fb8c00" : colors.sunShade}
									borderRadius="4px"
								>
									<Box marginBottom="20px" display="flex" gridGap="7px">
										<CheckIcon />{" "}
										{withValues(
											benefits.new_localhost === benefits.localhost
												? "GENERATE_REFERRAL_BOX.RULES.SAME_AMOUNT"
												: "GENERATE_REFERRAL_BOX.RULES.DIFFERENT_AMOUNT",
											{
												new_localhost: prices(benefits.new_localhost, benefits.currency),
												localhost: prices(benefits.localhost, benefits.currency),
											},
											null,
										)}
									</Box>

									<Box marginBottom="20px" display="flex" gridGap="7px">
										<CheckIcon />{" "}
										{withValues(
											"GENERATE_REFERRAL_BOX.RULES.BOOKING",
											{ booking: prices(benefits.booking, benefits.currency) },
											null,
										)}
									</Box>

									<Box fontSize="12px" fontWeight="500" marginLeft="31px">
										<StyledLink to={paths.HELP_TERMS_SERVICE} $decoration="underline">
											{t("GENERATE_REFERRAL_BOX.RULES.LINK")}
										</StyledLink>
									</Box>
								</Box>
							</Grid>
						</Grid>
					) : (
						<CircularLoader />
					)}
				</Box>
			</Grid>

			{withStatistics && userCode && (
				<Grid item md={4} xs={12}>
					<Box
						width="100%"
						height="100%"
						borderRadius="4px"
						padding="20px"
						bgcolor={colors.white}
						boxShadow="0 0 40px 0 rgba(30, 30, 30, 0.12)"
					>
						{!!benefits.usage ? (
							<Box display="flex" flexDirection="column">
								<Box fontSize="16px" color="#67758d" fontWeight="500" marginBottom="12px">
									{t("GENERATE_REFERRAL_BOX.STATISTICS.TITLE")}

									<strong>
										{" "}
										{getMonthName(nowDate.getMonth() + 1)} {nowDate.getFullYear()}
									</strong>
								</Box>

								<Box color="#485465" fontSize="13px" fontWeight="500" marginBottom="20px">
									<Box display="flex" justifyContent="space-between" marginBottom="5px">
										<span>{t("GENERATE_REFERRAL_BOX.STATISTICS.BOOKINGS")}</span>

										<strong>{benefits.bookingsAmount || 0}</strong>
									</Box>

									<Divider />

									<Box display="flex" justifyContent="space-between" marginTop="5px">
										<span>{t("GENERATE_REFERRAL_BOX.STATISTICS.NEW_LOCALHOSTS")}</span>

										<strong>{benefits.recommendationsAmount || 0}</strong>
									</Box>
								</Box>

								<Box display="flex" justifyContent="flex-end">
									<StyledLink to={paths.MY_REFERRALS} $withoutHoverUnderline>
										<ActionButton translationDefault="GENERATE_REFERRAL_BOX.STATISTICS.LINK" padding={14} />
									</StyledLink>
								</Box>
							</Box>
						) : (
							<Box display="flex" flexDirection="column">
								<Box fontSize="16px" color={colors.black} fontWeight="500" marginBottom="14px">
									{t("GENERATE_REFERRAL_BOX.CHANGE_CODE_BOX.TITLE")}
								</Box>

								<Box fontSize="14px" color="rgba(0, 0, 0, 0.54)" marginBottom="22px">
									{t("GENERATE_REFERRAL_BOX.CHANGE_CODE_BOX.SUB_TITLE")}
								</Box>

								<Box display="flex" alignItems="center" justifyContent="space-between" gridGap="25px">
									<Box fontSize="12px" color={colors.red}>
										{t("GENERATE_REFERRAL_BOX.CHANGE_CODE_BOX.ALERT")}
									</Box>

									<Box minWidth="164px">
										<StyledLink to={paths.CONTACT} $withoutHoverUnderline>
											<ActionButton translationDefault="GENERATE_REFERRAL_BOX.CHANGE_CODE_BOX.CTA" padding={14} />
										</StyledLink>
									</Box>
								</Box>
							</Box>
						)}
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default GenerateReferralBox;
