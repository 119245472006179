import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import colors from "styles/colors";

interface ITooltipIconProps {
	iconType: "success" | "warning" | "error";
}

const configIcon = {
	success: colors.green,
	warning: colors.sunShade,
	error: "#f44336",
};

const TooltipIcon = ({ iconType }: ITooltipIconProps) => (
	<ErrorOutlineIcon style={{ color: configIcon[iconType] || "black" }} />
);

export default TooltipIcon;
