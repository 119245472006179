import { Button } from "@material-ui/core";
import { RefObject } from "react";
import styled from "styled-components";

import { MontserratFontFamily, StyledLink } from "styles/common";
import media from "styles/media";

interface IEmptyListProps {
	description: string;
	link?: string;
	nameBtn: string;
	title: string;
	currentRef?: RefObject<HTMLDivElement>;
}

const Wrapper = styled.section`
	margin: 100px 0;

	${media.tablet`
    margin: 60px 0 0;
  `};
`;

const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 36px;
	font-weight: bold;
	line-height: 1.08;
	letter-spacing: 0.07px;

	${media.tablet`
    font-size: 26px;
  `};
`;

const Description = styled.p`
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	letter-spacing: 0.04px;
	line-height: 1.08;

	${media.tablet`
    font-size: 14px;
  `};
`;

const StyledBtn = styled(Button)`
	height: 48px;
	min-width: 240px;
`;

const EmptyList = ({ description, link = "", nameBtn, title, currentRef }: IEmptyListProps) => {
	const scrollToInfo = () => {
		if (currentRef?.current) {
			window.scrollTo({
				behavior: "smooth",
				top: currentRef.current.offsetTop,
			});
		}
	};
	return (
		<Wrapper>
			<MainTitle>{title}</MainTitle>
			<Description>{description}</Description>
			{currentRef ? (
				<StyledBtn onClick={scrollToInfo} color="primary" variant="contained">
					{nameBtn}
				</StyledBtn>
			) : (
				<StyledLink to={link} $withoutHoverUnderline>
					<StyledBtn color="primary" variant="contained">
						{nameBtn}
					</StyledBtn>
				</StyledLink>
			)}
		</Wrapper>
	);
};

export default EmptyList;
