import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import slugify from "slugify";

import { EStatusHostingRequest, IBookingIId, IPendingRequest, ISuperbookingIId } from "data/backoffice/bookings/types";
import { IExperience } from "data/experiences/types";
import { IRatingByBooking } from "data/ratings/types";
import { TUserIId } from "data/users/types";

import useErrors from "hooks/useErrors";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import experiencesService from "services/api/experiences";
import ratingsService from "services/api/ratings";
import usersService from "services/api/users";

import useLanguages from "store/hooks/useLanguages";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import fillRoute from "utils/routes";
import { isValidIdToPreview } from "utils/strings";

import DashboardDetailsBox from "components/dashboard/DashboardDetailsBox";
import DashboardItemList from "components/dashboard/DashboardItemList";
import DashboardSeeAllList from "components/dashboard/DashboardSeeAllList";
import DashboardSeeDetails from "components/dashboard/DashboardSeeDetailsLocalhost";
import DashboardSeeDetailsTraveler from "components/dashboard/DashboardSeeDetailsTraveler";

import Calendar from "ui/forms/date-time/Calendar";

import colors from "styles/colors";

import { initialResults, listItemsLimit } from "../../config";
import { TKindOfList } from "../../types";
import { parseStatus } from "../../utils";
import Loader from "../Loader";
import PendingRequests from "../PendingRequests";
import { RatingOnBookingDetails } from "../RatingOnBookingDetails";
import {
	ItemWrapper,
	Row,
	StyledBrightness1,
	StyledDividerMain,
	StyledDividerSection,
	Text,
	TitleSection,
} from "./styled";

interface IResults {
	data: ISuperbookingIId[];
	count: number;
}

const MyBookingsLocal = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { findNameByKey } = useLanguages();

	const { bid } = useParams<{ bid?: string }>();
	const query = useQuery();
	const sbk = query.get("sbk");

	const [resultsToday, setResultsToday] = useState<IResults>(initialResults);
	const [resultsPast, setResultsPast] = useState<IResults>(initialResults);
	const [resultsFuture, setResultsFuture] = useState<IResults>(initialResults);
	const [resultsSelectedDay, setResultsSelectedDay] = useState<IResults>(initialResults);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showSuperBookingDetails, setShowSuperBookingDetails] = useState<boolean>(false);
	const [showAllList, setShowAllList] = useState<boolean>(false);
	const [kindOfList, setKindOfList] = useState<TKindOfList>("TODAY");
	const [selectedSuperBooking, setSelectedSuperBooking] = useState<ISuperbookingIId | null>(null);
	const [selectedExperience, setSelectedExperience] = useState<IExperience | null>(null);
	const [selectedSubBookings, setSelectedSubBookings] = useState<{ data: IBookingIId[]; count: number }>(
		initialResults,
	);
	const [datesList, setDatesList] = useState<string[]>([]);
	const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth() + 1);
	const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
	const [selectedDayCalendar, setSelectedDayCalendar] = useState<string | boolean>(false);
	const [pendingRequestsList, setPendingRequestsList] = useState<IPendingRequest[]>([]);
	// START for rating of specific booking
	const [ratedBooking, setRatedBooking] = useState<IBookingIId>();
	const [ratingForRatedBooking, setRatingForRatedBooking] = useState<IRatingByBooking["experience"]>();
	const [experienceForRatedBooking, setExperienceForRatedBooking] = useState<IExperience>();
	const [travellerForRatedBooking, setTravellerForRatedBooking] = useState<TUserIId>();
	// END for rating of specific booking

	useEffect(() => {
		if (sbk) {
			const fetchSuperBookingFromUrlParam = async () => {
				try {
					const superBooking = await bookingsService.getSuperbookingById(sbk);

					if (superBooking) {
						showBookingDetails(superBooking);
					}
				} catch (e) {
					handleAndNotify(t("MY_BOOKINGS.BOOKINGS_WRONG_URL_PARAM"));
				}
			};

			fetchSuperBookingFromUrlParam();
		}

		// eslint-disable-next-line
	}, [sbk]);

	useEffect(() => {
		if (bid) {
			(async () => {
				const [bookingData, ratingData] = await Promise.all([
					bookingsService
						.getBooking(bid)
						.then(booking => {
							setRatedBooking(booking?.data);
							return booking?.data;
						})
						.catch(() => handleAndNotify(t("MY_BOOKINGS.BOOKINGS_WRONG_URL_PARAM"))),
					ratingsService
						.getRatingByBooking(bid)
						.then(rating => {
							setRatingForRatedBooking(rating?.experience);
							return rating?.experience;
						})
						.catch(() => handleAndNotify(t("MY_BOOKINGS.RATING_WRONG_BOOKING"))),
				]);

				if (bookingData?.eid) {
					getExperienceDetails(bookingData.eid).then(
						expDetails => expDetails && setExperienceForRatedBooking(expDetails),
					);
				}

				if (ratingData?.traveler_id && isValidIdToPreview(ratingData.traveler_id)) {
					usersService
						.fetchById(ratingData.traveler_id)
						.then(travellerData => setTravellerForRatedBooking(travellerData))
						.catch(e => handleAndNotify(e));
				}
			})();
		}

		// eslint-disable-next-line
	}, [bid]);

	useEffect(() => {
		const fetchHostingNotifications = async () => {
			try {
				// const todayFrom = getDateWithDefaultTime(new Date(), "FROM");
				const hostingNotifications = await bookingsService.getHostingNotifications({
					status: EStatusHostingRequest.ACCEPTED,
					sort: "date_time",
					order: "asc",
					offset: 0,
					limit: 100,
					// date_time_from: todayFrom,
				});

				if (hostingNotifications.data) {
					setPendingRequestsList(hostingNotifications.data);
				}
			} catch (e) {
				handleAndNotify(e);
			}
		};

		fetchHostingNotifications();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const getSuperbookingsSelectedDay = async () => {
			try {
				if (selectedDayCalendar) {
					const selectedDayFrom = getDateWithDefaultTime(
						selectedDayCalendar ? new Date((selectedDayCalendar as string) || 0) : new Date(),
						"FROM",
					);
					const selectedDayTo = getDateWithDefaultTime(
						selectedDayCalendar ? new Date((selectedDayCalendar as string) || 0) : new Date(),
						"TO",
					);

					const bookings = await bookingsService.fetchSuperBookingsBackoffice({
						limit: listItemsLimit,
						date_time_from: selectedDayFrom,
						date_time_to: selectedDayTo,
						order: "asc",
						sort: "utc_date_time",
						offset: 0,
					});

					if (bookings) {
						setResultsSelectedDay(bookings);
					}
				}
			} catch (e) {
				handleAndNotify(e);
			}
		};

		getSuperbookingsSelectedDay();
		// eslint-disable-next-line
	}, [selectedDayCalendar]);

	useEffect(() => {
		const createDatesList = async () => {
			try {
				let firstDay = `${currentYear}-${currentMonth}-01`;
				if (currentMonth < 10) {
					firstDay = `${currentYear}-0${currentMonth}-01`;
				}
				const firstDayFrom = getDateWithDefaultTime(new Date(firstDay), "FROM");

				const superBookingsList = await bookingsService.fetchSuperBookingsBackoffice({
					limit: listItemsLimit,
					date_time_from: firstDayFrom,
					order: "asc",
					sort: "utc_date_time",
					offset: 0,
				});

				if (superBookingsList.data) {
					const tempDatesList = superBookingsList.data.map(v => parseISOWrapper(v.date_time, DATE_FORMATS.DATE_FORMAT));

					const tempDatesSet = new Set(tempDatesList);
					// @ts-ignore
					setDatesList([...tempDatesSet]);
				}
			} catch (e) {
				handleAndNotify(e);
			}
		};

		createDatesList();
		// eslint-disable-next-line
	}, [currentMonth]);

	useEffect(() => {
		const loadSuperBookings = async () => {
			try {
				const yesterday = new Date();
				yesterday.setDate(yesterday.getDate() - 1);

				const tomorrow = new Date();
				tomorrow.setDate(tomorrow.getDate() + 1);

				const todayFrom = getDateWithDefaultTime(new Date(), "FROM");
				const todayTo = getDateWithDefaultTime(new Date(), "TO");

				const superBookingsListToday = await bookingsService.fetchSuperBookingsBackoffice({
					limit: listItemsLimit,
					date_time_from: todayFrom,
					date_time_to: todayTo,
					order: "asc",
					sort: "utc_date_time",
					offset: 0,
				});

				if (superBookingsListToday) {
					setResultsToday(superBookingsListToday);
				}

				const pastTo = getDateWithDefaultTime(yesterday, "TO");

				const superBookingsPast = await bookingsService.fetchSuperBookingsBackoffice({
					limit: listItemsLimit,
					date_time_to: pastTo,
					order: "desc",
					sort: "utc_date_time",
					offset: 0,
				});

				if (superBookingsPast) {
					setResultsPast(superBookingsPast);
				}

				const futureFrom = getDateWithDefaultTime(tomorrow, "FROM");

				const superBookingsFuture = await bookingsService.fetchSuperBookingsBackoffice({
					limit: listItemsLimit,
					date_time_from: futureFrom,
					order: "asc",
					sort: "utc_date_time",
					offset: 0,
				});

				if (superBookingsFuture) {
					setResultsFuture(superBookingsFuture);
				}
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsLoading(false);
			}
		};

		loadSuperBookings();
		// eslint-disable-next-line
	}, []);

	const getExperienceDetails = async id => {
		try {
			const expDetails = await experiencesService.getExperienceById(id);

			return expDetails;
		} catch (e) {
			handleAndNotify(e);
			return;
		}
	};

	const getSubBookings = async id => {
		try {
			const subBookings = await bookingsService.fetchBookings({ superbooking_id: id });

			return subBookings;
		} catch (e) {
			handleAndNotify(e);
			return null;
		}
	};

	const showBookingDetails = superBooking => {
		if (superBooking) {
			setSelectedSuperBooking(superBooking);

			setShowSuperBookingDetails(true);

			const expDetails = getExperienceDetails(superBooking.eid);

			expDetails.then(v => {
				if (v) {
					// @ts-ignore
					setSelectedExperience(v);
				}
			});

			const subBookings = getSubBookings(superBooking.id);

			subBookings.then(s => {
				if (s) {
					// @ts-ignore
					setSelectedSubBookings(s);
				}
			});
		}
	};

	const handleCloseDetailsDialog = () => {
		setShowSuperBookingDetails(false);

		// To prevent quick moment with empty data on modal
		setTimeout(() => {
			setSelectedExperience(null);
			setSelectedSuperBooking(null);
			setSelectedSubBookings(initialResults);
		}, 0);
	};

	const cleanupRatedBookingData = () => {
		setRatedBooking(undefined);
		setRatingForRatedBooking(undefined);
		setExperienceForRatedBooking(undefined);
		setTravellerForRatedBooking(undefined);
	};

	const renderListOfResults = useCallback(
		(title: string, list: ISuperbookingIId[]) => (
			<DashboardSeeAllList title={title} showDialog={showAllList} handleClose={() => setShowAllList(false)}>
				{list.map(v => (
					<DashboardItemList
						key={v.id}
						title={v?.exp_title || ""}
						expId={v.eid}
						localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
						localhostId={v.localhost_id}
						avatarUrl={v.localhost_details?.photo_url || undefined}
						date={v.date_time}
						status={
							<Box display="inline" color={parseStatus(v.status).color}>
								{parseStatus(v.status).label}
							</Box>
						}
						seeDetailsOnClick={() => showBookingDetails(v)}
					/>
				))}
			</DashboardSeeAllList>
		),
		// eslint-disable-next-line
		[showAllList],
	);

	const rerenderDialogSeeAll = useCallback(() => {
		let list;
		switch (kindOfList) {
			case "PAST":
				list = renderListOfResults(`${t("MY_BOOKINGS.PAST_BOOKINGS_TITLE")} (${resultsPast.count})`, resultsPast.data);
				break;
			case "FUTURE":
				list = renderListOfResults(
					`${t("MY_BOOKINGS.FUTURE_BOOKINGS_TITLE")} (${resultsFuture.count})`,
					resultsFuture.data,
				);
				break;
			case "TODAY":
				list = renderListOfResults(
					`${t("MY_BOOKINGS.TODAYS_BOOKINGS_TITLE")} (${resultsToday.count})`,
					resultsToday.data,
				);
				break;
			case "SELECTED_DAY":
				list = renderListOfResults(
					// @ts-ignore
					`${t("MY_BOOKINGS.SELECTED_DAY_BOOKINGS_TITLE")} ${
						selectedDayCalendar && format(new Date(selectedDayCalendar as string), "dd MMMM yyyy")
					}`,
					resultsSelectedDay.data,
				);
				break;
			default:
				list = null;
		}
		return list;
		// eslint-disable-next-line
	}, [kindOfList, resultsFuture, resultsToday, resultsPast, t, showAllList, resultsSelectedDay, selectedDayCalendar]);

	const showAllListToday = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("TODAY");
		}
	};

	const showAllListPast = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("PAST");
		}
	};

	const showAllListFuture = (v: boolean) => {
		if (v) {
			setShowAllList(true);
			setKindOfList("FUTURE");
		}
	};

	const showAllListSelectedDay = (v: string | boolean) => {
		if (v) {
			setSelectedDayCalendar(v);
			setShowAllList(true);
			setKindOfList("SELECTED_DAY");
		}
	};

	return (
		<>
			{ratedBooking && ratingForRatedBooking && (
				<DashboardSeeDetailsTraveler
					id={ratedBooking?.id}
					avatarUrlLocalhost={ratedBooking?.localhost_details?.photo_url || undefined}
					title={ratedBooking?.exp_title || ""}
					startDate={ratedBooking?.date_time}
					startLocation={experienceForRatedBooking?.location.start.formatted_address || "-"}
					tickets={ratedBooking?.tickets}
					language={findNameByKey(ratedBooking?.language) || "-"}
					link={fillRoute(paths.SEARCH_EXPERIENCES, {
						title: slugify(ratedBooking?.exp_title?.toLowerCase() || ""),
						id: ratedBooking?.eid,
					})}
					showDialog
					handleClose={cleanupRatedBookingData}
					localhostLink={
						ratedBooking?.localhost_id &&
						ratedBooking?.localhost_details?.first_name &&
						fillRoute(paths.PUBLIC_PROFILE, {
							userName: slugify(ratedBooking.localhost_details.first_name.toLowerCase()),
							uid: ratedBooking.localhost_id,
						})
					}
					firstNameLocalhost={ratedBooking?.localhost_details?.first_name || null}
					phoneNumberLocalhost={ratedBooking?.localhost_details?.phone || null}
					mainPhoto={experienceForRatedBooking?.photo_main}
					experienceTime={ratedBooking?.exp_duration_minutes}
					experienceTimeZone={experienceForRatedBooking?.location?.start?.timezone}
					comment={ratedBooking?.comments}
					status={ratedBooking?.status}
					statusLabel={
						ratedBooking?.status && (
							<Box display="inline" color={parseStatus(ratedBooking.status).color}>
								{parseStatus(ratedBooking.status).label}
							</Box>
						)
					}
					footerComponent={
						<RatingOnBookingDetails
							score={ratingForRatedBooking?.score}
							comment={ratingForRatedBooking?.comment_public}
							travellerName={ratingForRatedBooking?.traveler_name}
							travellerID={travellerForRatedBooking?.id}
							travellerPhoto={travellerForRatedBooking?.public_profile?.pictures?.profile}
						/>
					}
				/>
			)}

			<DashboardSeeDetails
				id={selectedExperience?.id || ""}
				avatarUrlLocalhost={selectedSuperBooking?.localhost_details?.photo_url || undefined}
				title={selectedSuperBooking?.exp_title || ""}
				startDate={selectedSuperBooking?.date_time}
				startLocation={selectedExperience?.location.start.formatted_address || "-"}
				language={findNameByKey(selectedSuperBooking?.language) || "-"}
				link={selectedExperience?.internal?.suggested_route}
				showDialog={showSuperBookingDetails && !!selectedSuperBooking && !!selectedExperience}
				handleClose={handleCloseDetailsDialog}
				localhostLink={
					selectedSuperBooking?.localhost_id &&
					selectedSuperBooking?.localhost_details?.first_name &&
					fillRoute(paths.PUBLIC_PROFILE, {
						userName: encodeURI(selectedSuperBooking.localhost_details.first_name.toLowerCase()),
						uid: selectedSuperBooking.localhost_id,
					})
				}
				firstNameLocalhost={selectedSuperBooking?.localhost_details?.first_name}
				mainPhoto={selectedExperience?.photo_main}
				experienceTime={selectedExperience?.duration_minutes}
				experienceTimeZone={selectedExperience?.location?.start?.timezone}
				guestsDetails={selectedSubBookings.data.map(v => ({
					name: v.traveler_details?.first_name,
					phone: v.traveler_details?.phone,
					status: v.status,
					comment: v?.comments,
					bookingId: v?.id,
					partnerIntBid: v?.partner_int_bid,
				}))}
				earningDetails={pendingRequestsList.filter(v => v.superbooking_id === selectedSuperBooking?.id)[0]}
				totalTravelers={selectedSuperBooking?.total_travelers}
				statusLabel={
					selectedSuperBooking?.status && (
						<Box display="inline" color={parseStatus(selectedSuperBooking.status).color}>
							{parseStatus(selectedSuperBooking.status).label}
						</Box>
					)
				}
				bookingId={selectedSuperBooking?.id}
			/>
			{rerenderDialogSeeAll()}
			<Row>
				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.TODAYS_BOOKINGS_TITLE")}
						titleAdditionalText={resultsToday.count > 0 ? resultsToday.count : undefined}
						emptyListMessage={
							resultsToday.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.TODAYS_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListToday}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsToday.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={`today-${v.id}`}
									title={v?.exp_title || ""}
									expId={v.eid}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>

				<ItemWrapper>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<TitleSection>{t("MY_BOOKINGS.CALENDAR_TITLE")}</TitleSection>
						<Box display="flex" alignItems="center">
							<StyledBrightness1 />
							<Text>{t("MY_BOOKINGS.YOUR_PLANNED_BOOKINGS_LABEL")}</Text>
						</Box>
					</Box>

					<StyledDividerSection />
					<Box display="flex" alignItems="center" justifyContent="center" height="400px">
						<Calendar
							initialValue={new Date()}
							label=""
							onClickDate={showAllListSelectedDay}
							chosenDatesList={datesList}
							customDateFormat={DATE_FORMATS.DATE_FORMAT}
							customBackgroundColorToday={colors.black}
							customFontColorToday={colors.white}
							onChangeMonth={v => setCurrentMonth(v)}
							onChangeYear={v => setCurrentYear(v)}
							width={isSmallScreen ? "auto" : "450px"}
							height={isSmallScreen ? "auto" : "350px"}
						/>
					</Box>
				</ItemWrapper>

				{/* @todo:backend - add a notifications*/}
				{/*<ItemWrapper width="33%">*/}
				{/*  <DashboardDetailsBox*/}
				{/*    title={t("MY_BOOKINGS.NOTIFICATIONS_TITLE")}*/}
				{/*    emptyListMessage={t("MY_BOOKINGS.NOTIFICATIONS_EMPTY_LIST")}*/}
				{/*    seeAllOnClick={v => console.log("v:", v)}*/}
				{/*  />*/}
				{/*</ItemWrapper>*/}
			</Row>
			<Row>
				<PendingRequests />
			</Row>
			<StyledDividerMain />
			<Row>
				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.PAST_BOOKINGS_TITLE")}
						titleAdditionalText={resultsPast.count > 0 ? resultsPast.count : undefined}
						emptyListMessage={
							resultsPast.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.PAST_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListPast}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsPast.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={`past-${v.id}`}
									title={v?.exp_title || ""}
									expId={v.eid}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>

				<ItemWrapper>
					<DashboardDetailsBox
						title={t("MY_BOOKINGS.FUTURE_BOOKINGS_TITLE")}
						titleAdditionalText={resultsFuture.count > 0 ? resultsFuture.count : undefined}
						emptyListMessage={
							resultsFuture.data.length > 0 || isLoading ? undefined : t("MY_BOOKINGS.FUTURE_BOOKINGS_EMPTY_LIST")
						}
						seeAllOnClick={showAllListFuture}
					>
						{isLoading ? (
							<Loader />
						) : (
							resultsFuture.data?.slice(0, 5)?.map(v => (
								<DashboardItemList
									key={`future-${v.id}`}
									title={v?.exp_title || ""}
									expId={v.eid}
									localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
									localhostId={v.localhost_id}
									avatarUrl={v.localhost_details?.photo_url || undefined}
									date={v.date_time}
									status={
										<Box display="inline" color={parseStatus(v.status).color}>
											{parseStatus(v.status).label}
										</Box>
									}
									seeDetailsOnClick={() => showBookingDetails(v)}
								/>
							))
						)}
					</DashboardDetailsBox>
				</ItemWrapper>
			</Row>
		</>
	);
};

export default MyBookingsLocal;
