import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import UniqueImgSmall from "assets/images/uniqueExperiences/unique_experiences.png";
import UniqueImgMedium from "assets/images/uniqueExperiences/unique_experiences@2x.png";
import UniqueImgLarge from "assets/images/uniqueExperiences/unique_experiences@3x.png";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import CommonParagraph, { Description } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const Col = styled.div`
	flex: 1;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

const RegisterBtnContent = styled.div`
	margin-top: 1.25rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${media.desktop`
       display: flex;
  `};
`;

const RegisterBtn = styled(Button)`
	height: 3rem;
`;

const ItsFreeLabel = styled.label`
	margin-top: 0.625rem;
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.large};
	font-weight: 500;
	line-height: 1.57;
	letter-spacing: 0.32px;
`;

const ImgWrapper = styled.div`
	height: auto;
	margin-top: 2.5rem;
	width: 100%;

	${media.desktop`
     margin-top: 0;
  `};
`;

const Header = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { t, withRaw } = useTranslation();

	const userData = useAppSelector(selectUser);

	return (
		<Container>
			<Helmet>
				<title>{t("UNIQUE_EXPERIENCES.SUB_TITLE")} | LocalBini</title>
				<meta property="og:title" content={t("UNIQUE_EXPERIENCES.SUB_TITLE") + "| LocalBini"} />
			</Helmet>

			{!isSmallScreen ? (
				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("UNIQUE_EXPERIENCES.MAIN_TITLE")}
							subTitle={t("UNIQUE_EXPERIENCES.SUB_TITLE")}
							description={withRaw("UNIQUE_EXPERIENCES.MAIN_DESCRIPTION", null)}
						/>

						{!(
							[EUserType.TRAVELER, EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]
						).includes(userData?.userType) && (
							<RegisterBtnContent>
								<StyledLink
									to={
										!userData
											? paths.ONBOARDING_REGISTER
											: fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: null, step: null })
									}
									$withoutHoverUnderline
								>
									<RegisterBtn color="primary" variant="contained" size="large">
										{t(!userData ? "UNIQUE_EXPERIENCES.HEADER_REGISTER_NOW" : "ONBOARDING.FINAL_MODAL.CTA")}
									</RegisterBtn>
								</StyledLink>

								<ItsFreeLabel>{t("UNIQUE_EXPERIENCES.HEADER_ITS_FREE")}</ItsFreeLabel>
							</RegisterBtnContent>
						)}
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Unique experiences - picture"
								imgSmall={UniqueImgSmall}
								imgMedium={UniqueImgMedium}
								imgLarge={UniqueImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>
			) : (
				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("UNIQUE_EXPERIENCES.MAIN_TITLE")}
							subTitle={t("UNIQUE_EXPERIENCES.SUB_TITLE")}
						/>

						<ImgWrapper>
							<ResponsiveImage
								altDesc="Unique experiences - picture"
								imgSmall={UniqueImgSmall}
								imgMedium={UniqueImgMedium}
								imgLarge={UniqueImgLarge}
							/>
						</ImgWrapper>
					</Col>

					<Col>
						<Description>{withRaw("UNIQUE_EXPERIENCES.MAIN_DESCRIPTION", null)}</Description>

						<RegisterBtnContent>
							<StyledLink
								to={
									!userData
										? paths.ONBOARDING_REGISTER
										: fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: null, step: null })
								}
								$withoutHoverUnderline
							>
								<RegisterBtn color="primary" variant="contained">
									{t(!userData ? "UNIQUE_EXPERIENCES.HEADER_REGISTER_NOW" : "ONBOARDING.FINAL_MODAL.CTA")}
								</RegisterBtn>
							</StyledLink>

							<ItsFreeLabel>{t("UNIQUE_EXPERIENCES.HEADER_ITS_FREE")}</ItsFreeLabel>
						</RegisterBtnContent>
					</Col>
				</InnerWrapper>
			)}
		</Container>
	);
};

export default Header;
