export const carouselSwiperConfig = {
	lazy: true,
	slidesPerView: 1,
	pagination: { clickable: true },
	breakpoints: {
		300: {
			spaceBetween: 20,
			height: 480,
			width: 304,
		},
		600: {
			spaceBetween: 50,
		},
		960: {
			spaceBetween: 70,
			height: 660,
			width: 434,
		},
	},
};
