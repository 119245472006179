import { Box, createStyles, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { TCurrency } from "data/backoffice/financials/types";
import { currencySymbolsDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

interface IBarChartVerticalProps {
	height?: string;
	width?: string;
	value?: {
		[key: string]: number;
	};
	minEarning?: number;
	currency?: TCurrency;
}

const StyledDots = styled.div`
	color: #979797;
	letter-spacing: 0.3px;
`;

const PriceBorder = styled.div`
	width: 43px;
	height: 23px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	border: solid 1px ${colors.sunShade};
	font-size: 12px;
`;

const MinEarningsLabel = styled.span`
	font-size: 12px;
	line-height: 1.55;
	letter-spacing: 0.07px;
	text-align: center;
	color: ${colors.mainFontColor};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		labelAbow: {
			fontSize: "10px",
			fontWeight: 500,
		},
		labelBelow: {
			fontSize: "12px",
			fontWeight: 500,
		},
		elementOfChart: {
			backgroundColor: theme.palette.primary.main,
			borderRadius: "16px 16px 0 0",
		},
	}),
);

const BarChartVertical = ({
	height = "245px",
	width = "50px",
	value,
	minEarning,
	currency,
}: IBarChartVerticalProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	if (!value) {
		return <Box display="flex" />;
	}

	const valueArray = Object.values(value);
	const valueArrayMaxValue = Math.max(...valueArray);

	const getValueForChart = price =>
		valueArray.reduce((firstNb, secondNb) => {
			const firstNbDiff = Math.abs(firstNb - price);
			const secondNbDiff = Math.abs(secondNb - price);

			if (firstNbDiff === secondNbDiff) {
				return firstNb > secondNb ? firstNb : secondNb;
			} else {
				return secondNbDiff < firstNbDiff ? secondNb : firstNb;
			}
		});

	const currencySymbol = !!currency ? currencySymbolsDictionary[currency] : "";

	return (
		<Box display="flex">
			{Object.entries(value).map(([k, v], i) => (
				<Box key={`${k}-${i}`} display="flex" flexDirection="column" alignItems="center" width={width} height={height}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="flex-end"
						width="100%"
						height="100%"
					>
						<Box mb={5}>
							<Typography className={classes.labelAbow}>
								{currencySymbol} {v}
							</Typography>
						</Box>

						{minEarning && getValueForChart(minEarning) === v && (
							<Box position="relative" width="350px" left="0">
								<Box display="flex" position="absolute" top="-10px">
									<Box width="45px">
										<PriceBorder>{minEarning}</PriceBorder>
										<MinEarningsLabel>
											{t("EXPERIENCE.FIELDS.pricing.private_price.MIN_EARNINGS_LABEL")}
										</MinEarningsLabel>
									</Box>
									<StyledDots>--------------------------------------------</StyledDots>
								</Box>
							</Box>
						)}
						<Box className={classes.elementOfChart} width="30%" height={`${(100 * v) / valueArrayMaxValue}%`} />
					</Box>

					<Box mt={5}>
						<Typography className={classes.labelBelow}>{k} per.</Typography>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default BarChartVertical;
