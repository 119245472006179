import { ReactNode } from "react";
import styled from "styled-components";

import Dialog from "ui/Dialog";

import { StyledDivider, Title } from "../shared.styled";

interface IDashboardSeeAllListProps {
	title: string;
	showDialog: boolean;
	handleClose: () => void;
	children: ReactNode;
	className?: string;
}

const Wrapper = styled.section`
	padding: 20px 0;
`;

const DashboardSeeAllList = ({ className, title, handleClose, showDialog, children }: IDashboardSeeAllListProps) => (
	<Dialog className={className} decorator="withoutLogo" showDialog={showDialog} handleClose={handleClose}>
		<Wrapper>
			<Title>{title}</Title>

			<StyledDivider />

			{children}
		</Wrapper>
	</Dialog>
);

export default DashboardSeeAllList;
