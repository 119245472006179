import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { rgba } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { paths } from "routing/paths";

import Logo from "ui/Logo";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import media from "styles/media";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			fontSize: "24px",
			fontWeight: 600,
			fontFamily: MontserratFontFamily,
			color: rgba("#212121", 0.87),
		},
	}),
);

interface IHeader {
	header_text: string;
}

// @todo:refactor - extract this components, they are not used only by auth components
export const LargeHeader = ({ header_text }: IHeader) => {
	const classes = useStyles();

	return (
		<Box display="flex" alignItems="center" justifyContent="center" mb={5}>
			<Typography className={classes.title} variant="h1">
				{header_text}
			</Typography>
		</Box>
	);
};

export const SubHeader = ({ header_text }: IHeader) => (
	<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
		<Typography variant="subtitle1">{header_text}</Typography>
	</Box>
);

interface IHeaderLogo {
	isPopup?: boolean;
	defaultMarginBottom?: number;
}

export const HeaderLogo = ({ isPopup = false, defaultMarginBottom = 45 }: IHeaderLogo) => (
	<Box
		display="flex"
		alignItems="center"
		justifyContent={isPopup ? "flex-start" : "center"}
		mb={isPopup ? 10 : defaultMarginBottom}
	>
		<Link to={paths.HOMEPAGE}>
			<Logo version="localbiniBig" width={isPopup ? undefined : 230} height={isPopup ? undefined : 48} />
		</Link>
	</Box>
);

export const Title = styled.h1<{ center?: boolean }>`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 20px;
  `};

	${props =>
		props.center &&
		css`
			text-align: center;
		`}
`;

export const SubTitle = styled.h2<{ center?: boolean }>`
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.5;
	opacity: 0.87;
	font-weight: 300;
	color: ${colors.middleGray};

	${media.tablet`
    font-size: 16px;
  `};

	${props =>
		props.center &&
		css`
			text-align: center;
		`}
`;

const CommonText = css`
	opacity: 0.7;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.2;
	letter-spacing: 0.06px;
	color: rgba(0, 0, 0, 0.87);
`;

export const Label = styled.span`
	margin-right: 5px;
	${CommonText};
`;

export const CheckboxWrapper = styled.div`
	.MuiTypography-body1 {
		${CommonText}
	}
`;

export const StyledLinkAuth = styled(Link)`
  font-family: ${RobotoFontFamily};
  font-size: 12px;
  line-height: 2;
  font-weight: 500;
  color: #323232;
  text-decoration: underline;
  cursor: pointer;
}
`;
