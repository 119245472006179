import { InputAdornment } from "@material-ui/core";
import countryData from "country-data";
import { ChangeEvent, FormEvent } from "react";

import useTranslation from "hooks/useTranslation";

import AlertMessage, { AlertMessageProps } from "ui/AlertMessage";
import StepBar from "ui/StepBar";

import { stepsLength } from "../../../config";
import { Alignment, Desc, MainContainer, MainContent, StepContainer, TitleHeader } from "../../../shared.styled";
import {
	CountryCodeContainer,
	CountryTextField,
	FlagAdornmentSpan,
	InputsContainer,
	PhoneNumberInput,
	StyledActionButton,
	StyledAutocomplete,
} from "../styled";

interface Country {
	code: string;
	phone: string;
}

interface PhoneVerificationProps extends AlertMessageProps {
	phoneNumber: string;
	prefixNumber: string;
	countrySymbol: string;
	isSpinnerOpen: boolean;
	isPhoneButtonDisabled: boolean;
	sendTheCode(event: FormEvent): void;
	handlePhoneNumberInputChange(event: ChangeEvent<HTMLInputElement>): void;
	handlePrefixNumberInputChange(event: ChangeEvent, value: { code: string; phone: string }): void;
}

const PhoneVerificationLayout = ({
	sendTheCode,
	handlePhoneNumberInputChange,
	phoneNumber,
	prefixNumber,
	countrySymbol,
	handlePrefixNumberInputChange,
	isSpinnerOpen,
	isPhoneButtonDisabled,
	openAlert,
	handleCloseAlert,
}: PhoneVerificationProps) => {
	const { t, withRaw } = useTranslation();

	return (
		<>
			<MainContainer>
				<Alignment>
					<MainContent>
						<TitleHeader mb={32}>{t("ONBOARDING.PHONE_VERIFY.STEP_1.HEADER")}</TitleHeader>

						<StepContainer>
							<StepBar stepsAmount={stepsLength} />
						</StepContainer>

						<Desc textAlign="left" as="div">
							{withRaw("ONBOARDING.PHONE_VERIFY.STEP_1.DESC", null)}
						</Desc>

						<InputsContainer>
							<StyledAutocomplete // @todo:refactor - make it as a component in UI
								id="phone_verification_option"
								options={countryData.countries.all
									.map(elem => ({
										phone: (elem.countryCallingCodes?.length && elem.countryCallingCodes[0]) || "",
										code: elem.alpha2,
									}))
									.filter(elem => elem.phone.length)}
								defaultValue={
									prefixNumber
										? {
												phone: prefixNumber,
										  }
										: null
								}
								autoHighlight
								autoSelect
								disableClearable
								onChange={handlePrefixNumberInputChange}
								getOptionLabel={(option: Country) => option.phone}
								filterOptions={(options, state) => {
									const prefix = state.inputValue.replace("+", "").replace("00", "");

									return options.filter((option: Country) => option.phone?.includes(prefix));
								}}
								renderOption={(option: Country) => (
									<CountryCodeContainer>
										<FlagAdornmentSpan className={option.code.toLowerCase()} />
										{option.phone}
									</CountryCodeContainer>
								)}
								renderInput={params => (
									<CountryTextField
										{...params}
										id="phone_verification_select_input"
										variant="outlined"
										InputProps={{
											...params.InputProps,
											startAdornment: countrySymbol && (
												<InputAdornment position="start">
													<FlagAdornmentSpan className={countrySymbol.toLowerCase()} />
												</InputAdornment>
											),
										}}
										inputProps={{
											...params.inputProps,
											autoComplete: "disabled",
										}}
									/>
								)}
							/>
							<PhoneNumberInput
								id="phone_verification_phone_input"
								variant="outlined"
								placeholder={t("ONBOARDING.PHONE_VERIFY.STEP_1.INPUT_PLACEHOLDER")}
								onChange={handlePhoneNumberInputChange}
								value={phoneNumber}
							/>
						</InputsContainer>

						<Desc>{t("ONBOARDING.PHONE_VERIFY.STEP_1.HINT")}</Desc>

						<StyledActionButton
							id="phone_verification_send_code_button"
							disabled={isPhoneButtonDisabled || isSpinnerOpen}
							variant="contained"
							onClick={sendTheCode}
							color="primary"
							translationDefault="ONBOARDING.PHONE_VERIFY.STEP_1.BUTTON"
							isAction={isSpinnerOpen}
						/>
					</MainContent>
				</Alignment>
			</MainContainer>

			<AlertMessage openAlert={openAlert} handleCloseAlert={handleCloseAlert} severity="error">
				{t("ONBOARDING.PHONE_VERIFY.STEP_1.ALERT")}
			</AlertMessage>
		</>
	);
};

export default PhoneVerificationLayout;
