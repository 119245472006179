import { Box, Button, createStyles, FormHelperText, makeStyles, Theme } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import { rgba } from "polished";
import qs from "query-string";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { affiliateSessionStorage } from "classes/AffiliateSession";

import { EPromoCodeType } from "data/promo-codes/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import promoCodesService from "services/api/promoCodes";

import PrivacyUrlsFooter from "ui/PrivacyUrlsFooter";

import colors from "styles/colors";
import { StyledLink } from "styles/common";

import CheckboxField from "../../formik/CheckboxField";
import Input from "../../formik/Input";
import PasswordInput from "../../formik/Input/variant/PasswordInput";
import SwitchField from "../../formik/SwitchField";
import { CheckboxWrapper, HeaderLogo, Label, StyledLinkAuth, SubTitle, Title } from "../shared";
import { SocialMediaLoginComponent } from "../SocialMediaLoginComponent";
import PasswordHint from "./components/PasswordHint";
import useValidate from "./validate";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		hrStyle: {
			borderTop: "none",
			borderLeft: "none",
			borderRight: "none",
			borderBottom: `1px solid ${theme.palette.grey}`,
			marginBottom: "8px",
			width: "100%",
		},
		orangeInput: {
			"& .MuiTextField-root": {
				marginBottom: 0,
			},
		},
	}),
);

const ErrorLink = styled(Link)`
	color: white;
	font-style: italic;
`;

const CustomPrivacyUrlsFooter = styled(PrivacyUrlsFooter)`
	& > a {
		opacity: 0.7 !important;
		color: rgba(0, 0, 0, 0.87) !important;
	}
`;

const AffiliateCodeContainer = styled.div<{ disabled: boolean }>`
	display: flex;
	align-items: center;
	padding: 12px;
	border-radius: 4px;
	margin-bottom: 15px;
	border: 1px solid transparent;

	${props =>
		props.disabled
			? css`
					background-color: ${rgba("#eceff1", 0.2)};
			  `
			: css`
					border-color: ${colors.sunShade};
			  `}
`;

const SwitchLabel = styled.span`
	font-size: 12px;
	color: ${colors.textSecondary};
`;

interface IDefaultValues {
	firstName: string;
	surname: string;
	email: string;
	password: string;
	affiliateCode: string;
}

interface IRegisterForm {
	name: string;
	family_name: string;
	email: string;
	password: string;
	claims_adult: boolean;
	marketing_agreed: boolean;
	affiliate_code_switch: boolean;
	affiliate_code: string;
}

interface IRegisterView {
	isMainView?: boolean;
	onboarding?: boolean;
	isPopup?: boolean;
	defaultValues?: IDefaultValues;
}

const registerDefaultValues: IDefaultValues = {
	firstName: "",
	surname: "",
	email: "",
	password: "",
	affiliateCode: "",
};

const RegisterView = ({
	isPopup,
	isMainView = true,
	onboarding = false,
	defaultValues = registerDefaultValues,
}: IRegisterView) => {
	const { t, withComponents, withRaw } = useTranslation();

	const { handleAndNotify } = useErrors();
	const { add, clear } = useNotification();

	const history = useHistory();
	const location = useLocation();

	const classes = useStyles();

	const [registerMain, setRegisterMain] = useState(isMainView);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { type } = qs.parse(location.search);

	const binipool = type === "binipool";

	const afiParam = affiliateSessionStorage.getAffiliateParam();

	const formikProps = useFormik<IRegisterForm>({
		enableReinitialize: true,
		validationSchema: useValidate(),
		initialValues: {
			name: defaultValues.firstName,
			family_name: defaultValues.surname,
			email: defaultValues.email,
			password: defaultValues.password,
			claims_adult: false,
			marketing_agreed: false,
			affiliate_code_switch: !!afiParam || !!defaultValues.affiliateCode,
			affiliate_code: afiParam || defaultValues.affiliateCode,
		},
		onSubmit: async (values, formikHelpers) => {
			setIsLoading(true);

			clear();

			const userName = `usr-${uuidv4().replace(/-/g, "")}`;

			const affiliateCode = values.affiliate_code;

			if (!!affiliateCode) {
				try {
					await promoCodesService.postValidate({
						code: affiliateCode,
						type: EPromoCodeType.AFFILIATE_CODE,
					});
				} catch {
					const errorMessage = t("AUTH.REGISTER.AFFILIATE_CODE_ERROR");

					add(errorMessage, "error");

					formikHelpers.setFieldError("affiliate_code", errorMessage);

					setIsLoading(false);

					return;
				}
			}

			try {
				const params = {
					username: userName,
					password: values.password,
					attributes: {
						email: values.email.toLowerCase(),
						name: values.name,
						family_name: values.family_name,

						"custom:marketing_agreed": values.marketing_agreed.toString(),
						"custom:claims_adult": values.claims_adult.toString(),
						"custom:unverified_type": onboarding ? "LOCALHOST" : "TRAVELER",
						...(onboarding ? { "custom:binipool_onboarding": binipool.toString() } : {}),
						...(!!affiliateCode ? { "custom:affiliate_code": affiliateCode } : {}),
					},
				};

				await Auth.signUp(params);

				history.push({
					pathname: onboarding ? paths.ONBOARDING_CONFIRM_EMAIL : paths.CONFIRM_EMAIL,
					search: qs.stringify({ email: values.email, username: userName, ...(binipool ? { type: "binipool" } : {}) }),
				});
			} catch (e) {
				// @ts-ignore
				if (e?.name === "UserLambdaValidationException") {
					add(
						withComponents(
							"AUTH.REGISTER.ERROR_FROM_API",
							{
								Link: <ErrorLink to={paths.FORGOT_PASSWORD} />,
							},
							null,
						),
						"error",
						{
							open: true,
							disableClose: true,
						},
					);

					return;
				}

				handleAndNotify(e);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const { handleSubmit, errors, touched, values: formikValues, setFieldValue, setFieldTouched } = formikProps;

	useEffect(() => {
		if (!formikValues.affiliate_code_switch) {
			setFieldValue("affiliate_code", "");
			setFieldTouched("affiliate_code", false);
		}

		// eslint-disable-next-line
	}, [formikValues.affiliate_code_switch]);

	return (
		<>
			{registerMain ? (
				<>
					<HeaderLogo isPopup={isPopup} defaultMarginBottom={5} />

					<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
						<Title center>
							{withRaw(
								onboarding
									? binipool
										? "AUTH.REGISTER.HEADER_ONBOARDING_BINIPOOL"
										: "AUTH.REGISTER.HEADER_ONBOARDING"
									: "AUTH.REGISTER.HEADER",
							)}
						</Title>
					</Box>

					{onboarding && (
						<Box display="flex" alignItems="center" justifyContent="center" mt={-18} mb={12}>
							<SubTitle center>
								{withComponents(
									binipool
										? "AUTH.REGISTER.SUB-HEADER_ONBOARDING_BINIPOOL"
										: "AUTH.REGISTER.SUB-HEADER_ONBOARDING_UNIQUE",
									{
										Link: (
											<StyledLink
												to={binipool ? paths.BINIPOOL_CONCEPT : paths.UNIQUE_EXPERIENCES}
												$decoration="underline"
												target="_blank"
											/>
										),
									},
									null,
								)}
							</SubTitle>
						</Box>
					)}

					<FormikProvider value={formikProps}>
						<Formik onSubmit={handleSubmit} noValidate>
							<Input required name="name" label={t("AUTH.REGISTER.FIRST_NAME")} inputClassName="full break" />

							<Input required name="family_name" label={t("AUTH.REGISTER.LAST_NAME")} inputClassName="full break" />

							<Input required name="email" type="email" label={t("AUTH.REGISTER.EMAIL")} inputClassName="full break" />

							<PasswordInput
								required
								name="password"
								label={t("AUTH.REGISTER.PASSWORD")}
								inputClassName="full break"
								autoComplete="new-password"
							/>

							<Box display="flex" alignItems="center" mt={-6} mb={12}>
								<PasswordHint align="flex-start" />
							</Box>

							<AffiliateCodeContainer disabled={!formikValues.affiliate_code_switch}>
								<SwitchField
									name="affiliate_code_switch"
									label={
										<SwitchLabel>
											{t(
												!!afiParam
													? "AUTH.REGISTER.AFFILIATE_SWITCH_LABEL_FROM_URL"
													: "AUTH.REGISTER.AFFILIATE_SWITCH_LABEL",
											)}
										</SwitchLabel>
									}
									disabled={!!afiParam}
								/>

								<Input
									disabled={!!afiParam || !formikValues.affiliate_code_switch}
									className={classes.orangeInput}
									name="affiliate_code"
									label={t(!!afiParam ? "AUTH.REGISTER.AFFILIATE_FROM_URL" : "AUTH.REGISTER.AFFILIATE")}
									inputClassName="full"
								/>
							</AffiliateCodeContainer>

							<CheckboxWrapper>
								<CheckboxField name="claims_adult" label={`${t("AUTH.REGISTER.ABOVE_18")} *`} />

								{errors?.claims_adult && touched?.claims_adult && (
									<Box marginTop="-10px">
										<FormHelperText error>{errors?.claims_adult}</FormHelperText>
									</Box>
								)}
							</CheckboxWrapper>

							<CheckboxWrapper>
								<CheckboxField name="marketing_agreed" label={t("AUTH.REGISTER.MARKETING_AGREEMENT")} />
							</CheckboxWrapper>

							<Box marginTop="-13px">
								<CustomPrivacyUrlsFooter firstMessage={t("AUTH.REGISTER.AGREEMENTS_FIRST_PART")} />
							</Box>

							<Box display="flex" justifyContent="center" alignItems="center">
								<Button
									type="submit"
									variant="contained"
									color="primary"
									disabled={isLoading}
									style={{ width: "260px" }}
								>
									{t("AUTH.REGISTER.SUBMIT_BUTTON")}
								</Button>
							</Box>
						</Formik>
					</FormikProvider>

					{/*<Box display="flex" justifyContent="center" alignItems="center" mt={15} mb={5}>*/}
					{/*  <StyledLinkAuth*/}
					{/*    to={paths.REGISTER}*/}
					{/*    onClick={e => {*/}
					{/*      e.preventDefault();*/}
					{/*      setRegisterMain(false);*/}
					{/*    }}*/}
					{/*  >*/}
					{/*    {t("AUTH.REGISTER.MORE_OPTIONS")}*/}
					{/*  </StyledLinkAuth>*/}
					{/*</Box>*/}

					{!onboarding && (
						<Box display="flex" justifyContent="center" alignItems="center" mb={5} mt={5}>
							<Label>{t("AUTH.REGISTER.REDIRECT_LABEL")}</Label>

							<StyledLinkAuth to={paths.LOGIN}>{t("AUTH.REGISTER.REDIRECT_LOGIN")}</StyledLinkAuth>
						</Box>
					)}
				</>
			) : (
				<Box paddingBottom="50px">
					<HeaderLogo isPopup={isPopup} />

					<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
						<Title>{t("AUTH.REGISTER.HEADER")}</Title>
					</Box>

					<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
						<SubTitle>{t("AUTH.REGISTER.SUB-HEADER")}</SubTitle>
					</Box>

					<SocialMediaLoginComponent />

					<Box display="flex" width="100%" mt={5} mb={15}>
						<hr className={classes.hrStyle} />
						<Box ml={5} mr={5}>
							<Label>{t("AUTH.COMMON_ELEMENTS.OR")}</Label>
						</Box>
						<hr className={classes.hrStyle} />
					</Box>

					<Box display="flex" justifyContent="center" alignItems="center" marginBottom="30px">
						<Button variant="contained" color="primary" disabled={isLoading} onClick={() => setRegisterMain(true)}>
							{t("AUTH.REGISTER.WITH_EMAIL")}
						</Button>
					</Box>

					<Box display="flex" justifyContent="center" alignItems="center">
						<StyledLinkAuth
							to={paths.REGISTER}
							onClick={e => {
								e.preventDefault();

								setRegisterMain(true);
							}}
						>
							{t("COMMON.CANCEL")}
						</StyledLinkAuth>
					</Box>
				</Box>
			)}
		</>
	);
};

export default RegisterView;
