import { Box, Grid, TextField } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import * as Yup from "yup";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import usersService from "services/api/users";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { LargeHeader, SubHeader } from "components/auth/shared";
import PhoneInputField from "components/formik/PhoneInputField";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { codeInputStyle } from "styles/common";

import { FormSection } from "../shared.styled";

const phoneCognitoAttrName = "phone_number";

const ChangePhoneNumber = () => {
	const { t } = useTranslation();

	const { addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);

	const [isAction, setIsAction] = useState<boolean>(false);
	const [isActionModal, setIsActionModal] = useState<boolean>(false);
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const [code, setCode] = useState<string>("");
	const [phoneVerified, setPhoneVerified] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			try {
				const user = await Auth.currentUserInfo();

				setPhoneVerified(user?.attributes?.phone_number_verified || false);
			} catch (e) {
				handleAndNotify(e);
			}
		})();
		// eslint-disable-next-line
	}, []);

	const verifyCode = async () => {
		try {
			setIsActionModal(true);

			await Auth.verifyCurrentUserAttributeSubmit(phoneCognitoAttrName, code);

			await usersService.syncUserPhone(userData?.id || "");

			setConfirmModal(false);
			setPhoneVerified(true);

			addSuccess(t("SETTINGS.PHONE.CHANGED"));
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setIsActionModal(false);
		}
	};

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			new_phone: Yup.string().required(t("ERRORS.REQUIRED")),
		}),
		initialValues: {
			new_phone:
				userData?.personal?.phone && !phoneVerified && userData.personal.phone.includes("+")
					? userData.personal.phone
					: "",
		},
		onSubmit: async values => {
			setIsAction(true);

			setCode("");

			try {
				const user = await Auth.currentAuthenticatedUser();

				await Auth.updateUserAttributes(user, {
					[phoneCognitoAttrName]: `${values.new_phone.includes("+") ? "" : "+"}${values.new_phone.replace(/\s/g, "")}`,
				});

				await Auth.verifyCurrentUserAttribute(phoneCognitoAttrName);

				setConfirmModal(true);
			} catch (e) {
				setConfirmModal(false);

				handleAndNotify(e);
			} finally {
				setIsAction(false);
			}
		},
	});

	return (
		<FormSection>
			<Dialog showDialog={confirmModal} handleClose={() => setConfirmModal(false)}>
				<Box textAlign="center">
					<LargeHeader header_text={t("ONBOARDING.PHONE_VERIFY.STEP_2.HEADER")} />

					<SubHeader header_text={t("ONBOARDING.PHONE_VERIFY.STEP_2.DESC")} />

					<Box marginBottom={15}>
						<ReactCodeInput
							fields={6}
							inputMode="numeric"
							inputStyle={codeInputStyle}
							type="text"
							name="code"
							pattern={`[0-9]+`}
							onChange={(value: string) => setCode(value)}
							value={code}
						/>
					</Box>

					<ActionButton
						disabled={code.length !== 6 || isActionModal}
						type="button"
						onClick={verifyCode}
						size="large"
						translationDefault={"SETTINGS.PHONE.VERIFY"}
						isAction={isActionModal}
					/>
				</Box>
			</Dialog>

			<FormikProvider value={formikProps}>
				<Form>
					<Grid container spacing={10}>
						<Grid item sm={6} lg={3} xs={12} style={!userData?.personal?.phone ? { display: "none" } : {}}>
							<TextField
								defaultValue={userData?.personal?.phone || ""}
								variant="outlined"
								label={t("SETTINGS.PHONE.CURRENT_PHONE_LABEL")}
								helperText={
									!phoneVerified ? <span style={{ color: colors.red }}>{t("SETTINGS.PHONE.UNVERIFIED")}</span> : ""
								}
								fullWidth
								disabled
							/>
						</Grid>

						<Grid item sm={6} lg={3} xs={12}>
							<PhoneInputField
								name="new_phone"
								label={t(
									!!userData?.personal?.phone && userData?.personal?.phone === formikProps.values.new_phone
										? "SETTINGS.PHONE.NEW_PHONE_LABEL_VERIFY"
										: "SETTINGS.PHONE.NEW_PHONE_LABEL",
								)}
								required
								disableCountryGuess={false}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Box mt={3}>
								<ActionButton
									type="button"
									onClick={formikProps.handleSubmit}
									size="large"
									translationDefault={
										!userData?.personal?.phone
											? "SETTINGS.PHONE.ADD_PHONE_BUTTON"
											: userData?.personal?.phone === formikProps.values.new_phone
											? "SETTINGS.PHONE.VERIFY"
											: "SETTINGS.PHONE.CHANGE_PHONE_BUTTON"
									}
									isAction={isAction}
									disabled={isAction}
								/>
							</Box>
						</Grid>
					</Grid>
				</Form>
			</FormikProvider>
		</FormSection>
	);
};

export default ChangePhoneNumber;
