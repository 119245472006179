import {
	EPromoCodeType,
	IPromoCodesUsageStatistics,
	IPromoCodeUsageNotGenerated,
	TPromoCodeIId,
	TPromoCodePartialStore,
	TPromoCodesUsageIId,
} from "data/promo-codes/types";

import API, { APISingleData } from "./index";

const namespace = "internal";
const api = "/promo-codes";

export const postValidatePath = `${api}/validate`;

const promoCodesService = {
	getAll: (queryParams?: Record<string, any>) =>
		APISingleData.get<{ count: number; data: TPromoCodeIId[] }>(namespace, api, {
			queryStringParameters: queryParams,
		}),
	postCreate: (body: TPromoCodePartialStore) => APISingleData.post<TPromoCodeIId>(namespace, api, { body }),
	patchPartialUpdate: (code: string, body: TPromoCodePartialStore) =>
		APISingleData.patch<TPromoCodeIId>(namespace, `${api}/${code}`, { body }),
	getByCode: (code: string) => APISingleData.get<TPromoCodeIId>(namespace, `${api}/${code}`),
	getUsage: (queryParams?: Record<string, any>) =>
		APISingleData.get<{ data: TPromoCodesUsageIId[]; count: number; statistics: IPromoCodesUsageStatistics }>(
			namespace,
			`${api}/usage`,
			{
				queryStringParameters: queryParams,
			},
		),
	postValidate: (body: { code: string; type?: EPromoCodeType }) =>
		APISingleData.post<{ code: string; message: string | null; type: EPromoCodeType }>(namespace, postValidatePath, {
			body,
		}),
	//
	//
	//
	getAffiliateCode: () =>
		APISingleData.get<TPromoCodeIId | IPromoCodeUsageNotGenerated>(namespace, `${api}/affiliate-code`),
	postCreateAffiliateCode: () => API.post<TPromoCodeIId>(namespace, `${api}/affiliate-code`),
};

export default promoCodesService;
