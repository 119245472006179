import { IDaysOfWeek } from "data/types";

import { FormValuesWithHelpers } from "./types";

export const daysEmptyValues: IDaysOfWeek = {
	"0": [],
	"1": [],
	"2": [],
	"3": [],
	"4": [],
	"5": [],
	"6": [],
};

export const initialValuesPerStep: {
	[key: number]: FormValuesWithHelpers;
} = {
	1: {
		title: "",
		country: "",
		city: "",
		languages: [],
		activity_type: "TOUR",
		relevant_attractions: [],
		categories: [],
		description: "",
		description_uniqueness: "",
	},
	2: {
		requirements: [],
		included: [],
		excluded: [],
		number_of_travelers: {
			min: 1,
			max: 15,
		},
		accessibility: [],
		physical_difficulty_level: "EASY",
		health_restrictions: [],
		custom_health_restrictions: [],
		location: {
			start: {
				coordinates: {
					lon: 0,
					lat: 0,
				},
				formatted_address: "",
			},
			end: {
				coordinates: {
					lon: 0,
					lat: 0,
				},
				formatted_address: "",
			},
		},
	},
	3: {
		duration_minutes: 60,
		schedule: {
			available_days_of_week: daysEmptyValues,
			availability_periods: [],
			excluded_dates: [],
		},
		cutoff_time_hours: 1,
		cancellation_policy_hours: 1,
		weather_sensitivities: [],
		disaster_policies: [],
		custom_disaster_policies: [],
	},
	4: {
		photo_main: "",
		media_gallery: [],
		photo_main_HELPER: {
			media_url: "",
			id: "",
		},
		media_gallery_HELPER: [],
		media_rights_confirmed_HELPER: false,
	},
	5: {
		number_of_travelers: {
			min: 1,
			max: 15,
		},
		pricing: {
			kids_free_under: 1,
		},
		// For preview START
		city: "",
		country: "",
		photo_main: "",
		duration_minutes: 0,
		title: "",
	},
};
