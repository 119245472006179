import { createStyles, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import { useState } from "react";

import useTranslation from "hooks/useTranslation";

import Input, { IInput } from "../index";

const useStyles = makeStyles(() =>
	createStyles({
		showHideLabel: {
			fontSize: "12px",
			textDecoration: "underline",
		},
		showHideButton: {
			height: 50,
			borderRadius: 3,
			marginRight: -11,
			"& .MuiTouchRipple-rippleVisible": {
				borderRadius: 3,
			},
		},
	}),
);

const PasswordInput = ({ ...props }: Omit<IInput, "type">) => {
	const { t } = useTranslation();

	const classes = useStyles();

	const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

	const handleClickShowPassword = () => setIsShowPassword(prevIsShow => !prevIsShow);

	const handleMouseDownPassword = event => event.preventDefault();

	return (
		<Input
			id={`id-password-${props.name}`}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						className={classes.showHideButton}
						aria-label="toggle password visibility"
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
					>
						{isShowPassword ? (
							<span className={classes.showHideLabel}>{t(`AUTH.COMMON_ELEMENTS.HIDE`)}</span>
						) : (
							<span className={classes.showHideLabel}>{t(`AUTH.COMMON_ELEMENTS.SHOW`)}</span>
						)}
					</IconButton>
				</InputAdornment>
			}
			{...props}
			type={isShowPassword ? "text" : "password"}
		/>
	);
};

export default PasswordInput;
