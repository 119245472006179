import { IRatingByBooking } from "data/ratings/types";

import { IRateCategories, TRateResult } from "components/Ratings/types";

import { ESteps } from "./types";

export const isValid = <T>(data?: TRateResult<T>, categories?: IRateCategories) => {
	if (!data || !categories) {
		return false;
	}

	return Object.values(data).filter(d => null !== d)?.length === Object.values(categories)?.length;
};

export const validCurrentState = (ratingsByBooking: IRatingByBooking): ESteps => {
	// For negative NPS rating
	if (ratingsByBooking?.nps?.answer === 0) {
		return ESteps.FINISH;
	}

	let firstIncomplete;

	for (const step of Object.keys(ESteps)) {
		const stepValue = ESteps[step];

		if (!firstIncomplete && !ratingsByBooking[stepValue]) {
			firstIncomplete = stepValue;
		}
	}

	return !!firstIncomplete ? firstIncomplete : ESteps.FINISH;
};
