import { Box, Grid, useTheme } from "@material-ui/core";
import { DirectionsOutlined, LocationOnOutlined, PlaylistAdd, QueryBuilderOutlined } from "@material-ui/icons";
import styled from "styled-components";

import { TActivityType } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { minutesToHM } from "utils/dates";

import { RobotoFontFamily } from "styles/common";

import ChipStringArray from "../../../ChipStringArray";

interface IBasicInfoProps {
	activityType: TActivityType;
	city: string;
	durationMin: number;
	includingArray: string[];
}

const Text = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	line-height: 1.25;
	letter-spacing: 0.11px;
	color: rgba(0, 0, 0, 0.87);
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const BasicInfo = ({ activityType, city, durationMin, includingArray }: IBasicInfoProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Box mt={22} mb={22}>
			<Grid container>
				<Grid item sm={4} xs={12}>
					<Box display="flex" alignItems="center" pb={12} gridGap={5}>
						<LocationOnOutlined style={{ fontSize: 26, color: theme.palette.primary.main }} />
						<Text>{city}</Text>
					</Box>
				</Grid>

				<Grid item sm={4} xs={12}>
					<Box display="flex" alignItems="center" pb={12} gridGap={5}>
						<QueryBuilderOutlined style={{ fontSize: 24, color: theme.palette.primary.main }} />

						<Text>{minutesToHM(durationMin)}</Text>
					</Box>
				</Grid>

				<Grid item sm={4} xs={12}>
					<Box display="flex" alignItems="center" pb={12} gridGap={5}>
						<DirectionsOutlined style={{ fontSize: 24, color: theme.palette.primary.main }} />
						<Text>{t(`EXPERIENCE.FIELDS.activity_type.${activityType}`)}</Text>
					</Box>
				</Grid>

				{!!includingArray?.length && (
					<Grid item xs={12} sm={12}>
						<Box display="flex" alignItems="center" flexWrap="wrap" gridGap={5}>
							<PlaylistAdd style={{ fontSize: 26, color: theme.palette.primary.main }} />
							<TextBold>{t("EXPERIENCE.INCLUDING")}:</TextBold>
							<ChipStringArray valuesArray={includingArray} />
						</Box>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default BasicInfo;
