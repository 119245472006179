import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { FormikProps } from "formik";
import { useCallback, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import StickyBox from "react-sticky-box";
import styled from "styled-components";

import { IExperience as FormValues } from "data/experiences/types";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import Header from "components/creation-process/Header";
import StepAccordionMenu from "components/creation-process/StepAccordationMenu";

import ActionButton from "ui/buttons/ActionButton";

import Step1 from "../steps/Step1";
import Step2 from "../steps/Step2";
import Step3 from "../steps/Step3";
import Step4 from "../steps/Step4";
import Step5 from "../steps/Step5";

const StyledMenuBox = styled(Box)`
	margin-right: 20px;
	padding-right: 20px;
	border-right: 1px solid #cfd8dc;
`;

const CreationProcessStepper = ({
	currentStep,
	isSubmitting,
	values,
	errors,
	resetForm,
	isValid,
	handleSubmit,
	setFieldValue,
	validateForm,
	saveMediaCallback,
	deleteMediaCallback,
	...otherProps
}: FormikProps<FormValues> & {
	currentStep: number;
	saveMediaCallback: (blob: Blob) => void;
	deleteMediaCallback: (id: string) => void;
}) => {
	const { t, withValuesAsString } = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();
	const theme = useTheme();
	const isMDScreen = useMediaQuery(theme.breakpoints.down("md"));
	const { addError, clear } = useNotification();

	const clickedSavedIndicator = useRef<boolean>(false);

	useEffect(() => {
		if (clickedSavedIndicator.current && Object.keys(errors).length > 0) {
			addError(withValuesAsString("ERRORS.VALIDATION_EXTENDED", { errors: Object.keys(errors).join(", ") }));

			try {
				document.getElementsByName(Object.keys(errors)[0])[0].focus();
				clickedSavedIndicator.current = false;
			} catch {
				clickedSavedIndicator.current = false;
			}
		}

		// eslint-disable-next-line
	}, [errors, clickedSavedIndicator.current]);

	const saveHandler = useCallback(async () => {
		clickedSavedIndicator.current = true;

		await setFieldValue("nextIndicator_HELPER", false);
		await setFieldValue("saveIndicator_HELPER", true);

		handleSubmit();
	}, [handleSubmit, setFieldValue]);

	const nextHandler = useCallback(async () => {
		clickedSavedIndicator.current = true;

		await setFieldValue("nextIndicator_HELPER", true);
		await setFieldValue("saveIndicator_HELPER", false);

		handleSubmit();
	}, [handleSubmit, setFieldValue]);

	const commonProps = {
		validateForm,
		isValid,
		values,
		errors,
		setFieldValue,
		isSubmitting,
		resetForm,
		handleSubmit,
		...otherProps,
	};

	return (
		<Box marginBottom="100px">
			<Header
				link={paths.EXPERIENCES}
				backToLabel={t("EXPERIENCE.BACK_TO_EXPERIENCES_BTN")}
				lastBreadcrumbLabel={t("TOPBAR.HOST_YOUR_EXP")}
			/>
			<Box display="flex">
				{!isMDScreen && (
					<StyledMenuBox flex={1}>
						<StickyBox>
							<>
								<Box pt={5} pb={5}>
									<Typography variant="h6">{t(`EXPERIENCE.TITLE`)}</Typography>
								</Box>

								<Divider />

								<StepAccordionMenu currentStep={currentStep} />
							</>
						</StickyBox>
					</StyledMenuBox>
				)}

				<Box flex={3}>
					{currentStep === 1 && <Step1 {...commonProps} />}
					{currentStep === 2 && <Step2 {...commonProps} />}
					{currentStep === 3 && <Step3 {...commonProps} />}
					{currentStep === 4 && (
						<Step4 {...commonProps} saveMediaCallback={saveMediaCallback} deleteMediaCallback={deleteMediaCallback} />
					)}
					{currentStep === 5 && <Step5 {...commonProps} />}

					<Divider />

					<Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px">
						<Button
							disabled={currentStep === 1}
							variant="outlined"
							onClick={() => {
								clear();
								history.push(`${match.url.slice(0, -1)}${currentStep - 1}`);
							}}
						>
							{t("EXPERIENCE.FOOTER.BACK")}
						</Button>

						<Box display="flex" alignItems="center" justifyContent="flex-end" gridGap={10}>
							<ActionButton
								translationDefault={"EXPERIENCE.FOOTER.SAVE"}
								isAction={isSubmitting}
								variant="outlined"
								color="default"
								padding={15}
								onClick={saveHandler}
							/>
							<ActionButton
								translationDefault={currentStep !== 5 ? "EXPERIENCE.FOOTER.CONTINUE" : "EXPERIENCE.FOOTER.SEND_REVIEW"}
								isAction={isSubmitting}
								variant="outlined"
								onClick={nextHandler}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default CreationProcessStepper;
