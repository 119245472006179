import AdventureImg from "assets/categories-graphics/adventure.png";
import ArchitectureImg from "assets/categories-graphics/architecture.png";
import ArtImg from "assets/categories-graphics/art&culture.png";
import DiscoveryImg from "assets/categories-graphics/discovery.png";
import FoodImg from "assets/categories-graphics/Food&Cuisine.png";
import FunImg from "assets/categories-graphics/fun.png";
import HistoryImg from "assets/categories-graphics/history.png";
import NatureImg from "assets/categories-graphics/nature.png";
import NightImg from "assets/categories-graphics/nightlive.png";
import PhotographyImg from "assets/categories-graphics/photography.png";
import SportImg from "assets/categories-graphics/sport.png";

export const categoriesConfig = [
	{
		imgUrl: AdventureImg,
		categoryName: "Adventure",
		filterName: "ADVENTURE",
	},
	{
		imgUrl: ArchitectureImg,
		categoryName: "Architecture",
		filterName: "ARCHITECTURE",
	},
	{
		imgUrl: ArtImg,
		categoryName: "Art & Culture",
		filterName: "ART_CULTURE",
	},
	{
		imgUrl: FoodImg,
		categoryName: "Food & Drinks",
		filterName: "FOOD_DRINKS",
	},
	{
		imgUrl: HistoryImg,
		categoryName: "History",
		filterName: "HISTORY",
	},
	{
		imgUrl: FunImg,
		categoryName: "Lifestyle",
		filterName: "LIFESTYLE",
	},
	{
		imgUrl: NatureImg,
		categoryName: "Nature",
		filterName: "NATURE",
	},
	{
		imgUrl: NightImg,
		categoryName: "Night Life",
		filterName: "NIGHT_LIFE",
	},
	{
		imgUrl: PhotographyImg,
		categoryName: "Photography",
		filterName: "PHOTOGRAPHY",
	},
	{
		imgUrl: SportImg,
		categoryName: "Sport",
		filterName: "SPORT",
	},
	{
		imgUrl: DiscoveryImg,
		categoryName: "Discovery",
		filterName: "DISCOVERY",
	},
];
