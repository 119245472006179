import { combineReducers } from "redux";

// backoffice START
import bookingsBackoffice, { bookingsBackofficeActions } from "./backoffice/bookings";
import draftsBackoffice, { draftsBackofficeActions } from "./backoffice/drafts";
import financialsBackoffice, { financialsBackofficeActions } from "./backoffice/financials";
import notificationsBackoffice, { notificationsBackofficeActions } from "./backoffice/notifications";
import partners, { partnersActions } from "./backoffice/partners";
import usersBackoffice, { usersBackofficeActions } from "./backoffice/users";
// backoffice END
import configs, { configsActions } from "./configs";
import experiences, { experiencesActions } from "./experiences";
import experienceTraveller, { experienceTravellerActions } from "./experienceTraveller";
import homePage, { homepageActions } from "./homePage";
import notifications, { notificationsActions } from "./notifications";
import user, { userActions } from "./users";

const rootReducer = combineReducers({
	// backoffice START
	partners,
	bookingsBackoffice,
	draftsBackoffice,
	usersBackoffice,
	financialsBackoffice,
	notificationsBackoffice,
	// backoffice END
	//
	// user START
	user,
	notifications,
	// user END
	experiences,
	experienceTraveller,
	// public START
	configs,
	homePage,
	// public END
});

export type State = typeof rootReducer;

export const resetEntireState = (withPublic?: boolean) => dispatch => {
	// backoffice START
	dispatch(partnersActions.reset());
	dispatch(bookingsBackofficeActions.reset());
	dispatch(draftsBackofficeActions.reset());
	dispatch(usersBackofficeActions.reset());
	dispatch(financialsBackofficeActions.reset());
	dispatch(notificationsBackofficeActions.reset());
	// backoffice END
	//
	// user START
	dispatch(userActions.reset());
	dispatch(notificationsActions.reset());
	// user END
	dispatch(experiencesActions.reset());
	dispatch(experienceTravellerActions.reset());

	if (withPublic) {
		dispatch(configsActions.reset());
		dispatch(homepageActions.reset());
	}
};

export default rootReducer;
