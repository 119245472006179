import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import slugify from "slugify";

import { ENotificationType } from "data/notifications/types";

import useErrors from "hooks/useErrors";

import { paths } from "routing/paths";

import experiencesService from "services/api/experiences";
import usersService from "services/api/users";

import fillRoute from "utils/routes";
import { foundModuleId } from "utils/strings";

import CircularLoader from "ui/loaders/CircularLoader";

const availableDetailsPages: Record<string, paths> = {
	usr: paths.PUBLIC_PROFILE,
	exp: paths.SEARCH_EXPERIENCES,
	hno: paths.MY_BOOKINGS,
	sbk: paths.MY_BOOKINGS,
	bok: paths.MY_BOOKINGS,
};

const specialTypes: Record<string, string> = {
	[ENotificationType.RATING_CREATED]: ENotificationType.RATING_CREATED,
};

const propertyLoaders = {
	usr: async userId => {
		const user = await usersService.fetchById(userId);
		return { uid: user.id, userName: user.public_profile?.first_name };
	},
	exp: async experienceId => {
		const experience = await experiencesService.getExperienceById(experienceId);
		return { id: experience.id, title: slugify(experience.title.toLowerCase()) };
	},
	hno: notificationId => ({
		search: { hno: notificationId },
	}),
	sbk: superBookingId => ({
		search: { sbk: superBookingId },
	}),
	bok: bookingId => ({
		search: { bok: bookingId },
	}),
};

const RedirectPage = () => {
	const history = useHistory();

	const { id, type } = useParams<{ id: string; type?: string }>();

	const { handleAndNotify } = useErrors();

	useEffect(() => {
		(async () => {
			const found = foundModuleId(availableDetailsPages, id);

			if (!found?.id) {
				history.replace(paths.NOT_FOUND);

				return;
			}

			let props: {
				[key: string]: string;
			} & {
				search?: {
					[key: string]: string;
				};
			} = {};

			try {
				if (found.prefix in propertyLoaders) {
					props = await propertyLoaders[found.prefix](found.id);
				}
			} catch (e) {
				handleAndNotify(e);

				history.replace(paths.NOT_FOUND);

				return;
			}

			let route = availableDetailsPages[found.prefix];
			let propsData = props;

			if (type && specialTypes[type] && type === ENotificationType.RATING_CREATED) {
				route = paths.SHOW_BOOKING_RATING;
				propsData = {
					bid: props?.search?.bok || "",
				};
			}

			const redirectTo = fillRoute(route, propsData);

			history.replace(redirectTo);
		})();

		// eslint-disable-next-line
	}, [id]);

	return <CircularLoader />;
};

export default RedirectPage;
