import { Alert } from "@material-ui/lab";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

export const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 36px;
	font-weight: bold;
	line-height: 1.08;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 16px;
  `};
`;

export const StyledAlert = styled(Alert)`
	.MuiAlert-icon {
		align-items: center;
	}
`;
