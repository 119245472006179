import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import HighQualityIcon from "@material-ui/icons/HighQuality";
import ListAltIcon from "@material-ui/icons/ListAlt";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PublicIcon from "@material-ui/icons/Public";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StorageIcon from "@material-ui/icons/Storage";

import ClassificationsIco from "assets/icons/backoffice/classifications.svg";
import CmsIco from "assets/icons/backoffice/cms.svg";
import ExperiencesIco from "assets/icons/backoffice/experiences.svg";
import FinancialsIco from "assets/icons/backoffice/financials.svg";
import ListedIco from "assets/icons/backoffice/listed.svg";
import LocalhostIco from "assets/icons/backoffice/local-hosts.svg";
import PartnersIco from "assets/icons/backoffice/partners.svg";
import PromoIco from "assets/icons/backoffice/promo-codes.svg";
import ReviewIco from "assets/icons/backoffice/review.svg";
import UsersIco from "assets/icons/backoffice/users.svg";

import { paths } from "routing/paths";

import { TSidebarMenu } from "components/backoffice/SideBar/types";

/*
import HomeSettingsIco from "assets/icons/backoffice/home-settings.svg";
import StatisticsIco from "assets/icons/backoffice/statistics.svg";
import WithDrawalsIco from "assets/icons/backoffice/withdrawals.svg";
import AdminIco from "assets/icons/backoffice/admin.svg";
import ArchiveIco from "assets/icons/backoffice/archive.svg";
import BinipoolManagerIco from "assets/icons/backoffice/binipool-manager.svg";
*/

export enum ESidebarMenuID {
	dashboard = 1,
	notifications = 2,
	cms = 3,
	users = 4,
	partners = 5,
	localhosts = 6,
	experiences = 7,
	bookings = 8,
	financials = 9,
	promo_codes = 10,
}

export enum ETableLoaders {
	GLOBAL = "GLOBAL",
	INNER = "INNER",
}

export type TLoadersState = ETableLoaders | false;

export const sidebarConfig: TSidebarMenu = [
	{
		IconComponent: DashboardIcon,
		label: "BO.MENU.DASHBOARD",
		link: paths.BACKOFFICE,
		itemId: ESidebarMenuID.dashboard,
	},
	{
		IconComponent: NotificationsNoneIcon,
		label: "BO.MENU.NOTIFICATIONS",
		link: paths.BACKOFFICE_NOTIFICATIONS,
		itemId: ESidebarMenuID.notifications,
	},
	{
		icon: CmsIco,
		label: "BO.MENU.CMS",
		itemId: ESidebarMenuID.cms,
		subroutes: [
			{
				IconComponent: PublicIcon,
				label: "BO.MENU.CMS.COUNTRIES_CITIES",
				link: paths.BACKOFFICE_CMS_COUNTRIES_CITIES,
			},
			{
				icon: LocalhostIco,
				label: "BO.MENU.CMS.LOCALHOSTS",
				link: paths.BACKOFFICE_CMS_LOCALHOSTS,
			},
			{
				icon: ExperiencesIco,
				label: "BO.MENU.CMS.EXPERIENCES",
				link: paths.BACKOFFICE_CMS_EXPERIENCES,
			},
		],
	},
	{
		icon: UsersIco,
		label: "BO.MENU.USERS",
		link: paths.BACKOFFICE_USERS,
		itemId: ESidebarMenuID.users,
	},
	{
		icon: PartnersIco,
		label: "BO.MENU.PARTNERS",
		itemId: ESidebarMenuID.partners,
		subroutes: [
			{
				IconComponent: ListAltIcon,
				label: "BO.MENU.PARTNERS.LIST",
				link: paths.BACKOFFICE_PARTNERS_LIST,
			},
			{
				IconComponent: AddBoxOutlinedIcon,
				label: "BO.MENU.PARTNERS.CREATE",
				link: paths.BACKOFFICE_PARTNERS_CREATE,
			},
			{
				IconComponent: AccountTreeIcon,
				label: "BO.MENU.PARTNERS.DEFAULT",
				link: paths.BACKOFFICE_PARTNERS_DEFAULT,
			},
		],
	},
	{
		icon: LocalhostIco,
		label: "BO.MENU.LOCALHOSTS",
		itemId: ESidebarMenuID.localhosts,
		subroutes: [
			{
				icon: ListedIco,
				label: "BO.MENU.LOCALHOSTS.LISTED",
				link: paths.BACKOFFICE_LOCALHOSTS_LISTED,
			},
			{
				icon: ReviewIco,
				label: "BO.MENU.LOCALHOSTS.NOT_LISTED",
				link: paths.BACKOFFICE_LOCALHOSTS_NOT_LISTED,
			},
		],
	},
	{
		icon: ExperiencesIco,
		label: "BO.MENU.EXPERIENCES",
		itemId: ESidebarMenuID.experiences,
		subroutes: [
			{
				icon: ListedIco,
				label: "BO.MENU.EXPERIENCES.LISTED",
				link: paths.BACKOFFICE_EXPERIENCE_LISTED,
			},
			{
				icon: ReviewIco,
				label: "BO.MENU.EXPERIENCES.NOT_LISTED",
				link: paths.BACKOFFICE_EXPERIENCE_NOT_LISTED,
			},
			{
				icon: ClassificationsIco,
				label: "BO.MENU.EXPERIENCES.CLASSIFICATIONS",
				link: paths.BACKOFFICE_CLASSIFICATIONS_LIST,
			},
			{
				icon: PartnersIco,
				label: "BO.MENU.EXPERIENCES.PROMOTING_EXPERIENCES",
				link: paths.BACKOFFICE_EXPERIENCE_PROMOTING,
			},
		],
	},
	{
		IconComponent: CardTravelIcon,
		label: "BO.MENU.BOOKINGS",
		itemId: ESidebarMenuID.bookings,
		subroutes: [
			{
				IconComponent: ListAltIcon,
				label: "BO.MENU.SUPERBOOKINGS.LIST",
				link: paths.BACKOFFICE_SUPERBOOKINGS_LIST,
			},
			{
				IconComponent: StorageIcon,
				label: "BO.MENU.BOOKINGS.LIST",
				link: paths.BACKOFFICE_BOOKINGS_LIST,
			},
			{
				IconComponent: HighQualityIcon,
				label: "BO.MENU.SUPERBOOKINGS.QA",
				link: paths.BACKOFFICE_SUPERBOOKINGS_QA,
			},
			{
				IconComponent: EmailOutlinedIcon,
				label: "BO.MENU.SUPERBOOKINGS.HOSTING_REQUESTS",
				link: paths.BACKOFFICE_SUPERBOOKINGS_HOSTING_REQUESTS,
			},
			{
				IconComponent: AddBoxOutlinedIcon,
				label: "BO.MENU.BOOKINGS.CREATE",
				link: paths.BACKOFFICE_BOOKING_ADD_NEW,
			},
		],
	},
	{
		icon: FinancialsIco,
		label: "BO.MENU.FINANCIALS",
		link: paths.BACKOFFICE_FINANCIALS,
		itemId: ESidebarMenuID.financials,
		subroutes: [
			{
				IconComponent: AccountBalanceWalletIcon,
				label: "BO.MENU.FINANCIALS.LOCALHOSTS_EARNINGS",
				link: paths.BACKOFFICE_FINANCIALS_LOCALHOST_EARNINGS,
			},
			{
				IconComponent: ReceiptIcon,
				label: "BO.MENU.FINANCIALS.COMMISSION_SUMMARY",
				link: paths.BACKOFFICE_FINANCIALS_COMMISSION_SUMMARY,
			},
		],
	},
	{
		icon: PromoIco,
		label: "BO.MENU.PROMO_CODES",
		link: paths.BACKOFFICE_PROMO_CODES,
		itemId: ESidebarMenuID.promo_codes,
		subroutes: [
			{
				IconComponent: AddBoxOutlinedIcon,
				label: "BO.MENU.PROMO_CODES.CREATE",
				link: paths.BACKOFFICE_PROMO_CODES_CREATE,
			},
			{
				IconComponent: PlaylistAddCheckIcon,
				label: "BO.MENU.PROMO_CODES.USAGE_LIST",
				link: paths.BACKOFFICE_PROMO_CODES_USAGE,
			},
		],
	},
	/*
	{
		icon: HomeSettingsIco,
		label: "BO.MENU.HOME_SETTINGS",
		itemId: 3,
		disabled: true,
	},
	{
		icon: AdminIco,
		label: "BO.MENU.ADMIN",
		itemId: 15,
		disabled: true,
	},
	{
		icon: BinipoolManagerIco,
		label: "BO.MENU.BINIPOOL_MANAGER",
		itemId: 10,
		disabled: true,
	},
	{
		icon: WithDrawalsIco,
		label: "BO.MENU.WITHDRAWALS",
		itemId: 11,
		disabled: true,
	},
	{
		icon: StatisticsIco,
		label: "BO.MENU.STATISTICS",
		itemId: 12,
		disabled: true,
	},
	{
		icon: ArchiveIco,
		label: "BO.MENU.ARCHIVE",
		itemId: 14,
		disabled: true,
	},
	*/
];

// Update it after some time!
export const newFeaturesInBackoffice = [];

export const scrollThrottleTime = 1000;
export const listItemsOffset = 20;
export const listEndOffset = 600;
export const containerId = "backoffice-main-view";
