import { Box } from "@material-ui/core";
import { Cache } from "aws-amplify";
import { isEmpty } from "lodash-es";
import qs from "query-string";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Redirect, RouteComponentProps } from "react-router-dom";
import slugify from "slugify";
import styled from "styled-components";

import { EBookingStatus, IBookingIId } from "data/backoffice/bookings/types";
import { IExperience } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import experiencesService from "services/api/experiences";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectGuest, selectUser } from "store/selectors/user";
import { fetchGuestAuthCredentials, userActions } from "store/slices/users";

import { prices } from "utils/prices";
import fillRoute from "utils/routes";
import { checkPermission } from "utils/tokens";

import { parseStatus } from "pages/account/myBookings/utils";

import { CancelBookingButton } from "components/bookings/CancelBookingButton";
import DownloadTicketButton from "components/bookings/DownloadTicketButton";
import RateBookingButton from "components/bookings/RateBookingButton";
import { BookingDetails } from "components/dashboard/DashboardSeeDetailsTraveler";
import { StyledDivider, Title } from "components/dashboard/shared.styled";
import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import Breadcrumbs from "ui/Breadcrumbs";
import ActionButton from "ui/buttons/ActionButton";

import { Container, StyledLink } from "styles/common";

const StyledTitle = styled(Title)`
	font-size: 14px;
	margin-top: 45px;
`;

const GuestBookingPage = ({ location, history, match }: RouteComponentProps) => {
	const { t } = useTranslation();

	const { findNameByKey } = useLanguages();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const loggedUser = useAppSelector(selectUser);
	const { data: guestCredentials, status: guestCredentialsStatus } = useAppSelector(selectGuest);

	const [booking, setBooking] = useState<IBookingIId>();
	const [experience, setExperience] = useState<IExperience>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [forceReload, setForceReload] = useState<number>();

	const { booking_id, token } = match.params as { booking_id: string; token?: string };
	const { token: tokenFromQueryParams } = qs.parse(location.search);

	const tokenParam = (token || tokenFromQueryParams) as string | null | undefined;

	const fetchBookingDetails = async (withGuestCredentials?: boolean) => {
		try {
			setLoaded(false);

			const loadedBooking = await bookingsService.getBooking(booking_id, withGuestCredentials);

			setBooking(loadedBooking.data);

			const loadedExperience = await experiencesService.getExperienceById(
				loadedBooking?.data?.eid,
				undefined,
				undefined,
				withGuestCredentials,
			);

			setExperience(loadedExperience);
		} catch (e) {
			handleAndNotify(e, {
				statusOverrideMessages: {
					404: t<string>("NOTIFICATION.INVALID_PARAMETERS"),
				},
			});

			history.push(paths.HOMEPAGE);
		} finally {
			setLoaded(true);
		}
	};

	useEffect(() => {
		if (tokenParam) {
			dispatch(fetchGuestAuthCredentials({ token: tokenParam, trigger: paths.GUEST_BOOKING_PAGE }));
		} else if (loggedUser) {
			fetchBookingDetails();
		}

		// eslint-disable-next-line
	}, [booking_id, tokenParam, loggedUser]);

	useEffect(() => {
		if (guestCredentialsStatus === "success" && guestCredentials?.trigger === paths.GUEST_BOOKING_PAGE) {
			dispatch(userActions.resetGuestStatus());

			fetchBookingDetails(true);
		}

		// eslint-disable-next-line
	}, [guestCredentialsStatus]);

	useEffect(() => {
		if (forceReload) {
			if (tokenParam) {
				fetchBookingDetails(true);
			} else if (loggedUser) {
				fetchBookingDetails();
			}
		}

		// eslint-disable-next-line
	}, [forceReload]);

	if (!tokenParam && !loggedUser) {
		return <Redirect to={fillRoute(paths.LOGIN, { search: { redirect: location.pathname } })} />;
	}

	const canCancelBooking =
		!!booking_id &&
		!!booking?.status &&
		[EBookingStatus.NEW, EBookingStatus.PAID, EBookingStatus.PAYMENT_FAILED].includes(booking.status) &&
		checkPermission(
			"allow.internal_bookings.cancel_booking",
			tokenParam ? guestCredentials?.authorization : Cache.getItem("Authorization"),
		);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("MY_BOOKINGS.YOUR_BOOKING")} />

				{booking && experience ? (
					<BookingDetails
						{...booking}
						link={
							booking?.eid
								? fillRoute(paths.SEARCH_EXPERIENCES, {
										title: slugify(booking?.exp_title?.toLowerCase() || ""),
										id: booking.eid,
								  })
								: ""
						}
						statusLabel={
							booking?.status && (
								<Box display="inline" color={parseStatus(booking.status).color}>
									{parseStatus(booking.status).label}
								</Box>
							)
						}
						firstNameLocalhost={booking?.localhost_details?.first_name || null}
						phoneNumberLocalhost={booking?.localhost_details?.phone || null}
						avatarUrlLocalhost={booking?.localhost_details?.photo_url || undefined}
						localhostLink={
							booking?.localhost_id &&
							booking?.localhost_details?.first_name &&
							fillRoute(paths.PUBLIC_PROFILE, {
								userName: encodeURI(booking.localhost_details.first_name.toLowerCase()),
								uid: booking.localhost_id,
							})
						}
						tickets={booking?.tickets}
						title={booking?.exp_title || ""}
						startDate={booking?.date_time}
						startLocation={experience?.location.start.formatted_address || "-"}
						language={findNameByKey(booking?.language) || "-"}
						mainPhoto={experience?.photo_main}
						experienceTime={experience?.duration_minutes}
						experienceTimeZone={experience?.location?.start?.timezone}
						wrapper={{ maxHeight: "auto", padding: "15px" }}
						cost={prices(booking.price, experience?.pricing.currency, undefined, undefined, true)}
						bookingId={booking_id}
					>
						{[EBookingStatus.NEW, EBookingStatus.PAYMENT_FAILED].includes(booking.status) ? (
							<>
								<StyledTitle>{t("MY_BOOKINGS.PAY_FOR_BOOKING")}</StyledTitle>

								<StyledDivider />

								<StyledLink
									to={fillRoute(paths.GUEST_PAYMENT, {
										bid: booking_id,
										...(tokenParam && { search: { token: tokenParam } }),
									})}
									$withoutHoverUnderline
								>
									<ActionButton translationDefault={"MY_BOOKINGS.GO_TO_PAYMENT"} />
								</StyledLink>
							</>
						) : (
							!isEmpty(booking?.held_code) && (
								<>
									<StyledTitle>{t("MY_BOOKINGS.QRCODE_TITLE")}</StyledTitle>

									<StyledDivider />

									<Box display="flex" alignItems="flex-start" gridGap="60px" flexWrap="wrap">
										<QRCode
											value={
												process.env.REACT_APP_SITE_URL +
												fillRoute(paths.CONFIRM_BOOKING, {
													bid: booking_id,
													heldCode: booking?.held_code || "",
												})
											}
										/>

										<Box>
											{!!booking_id && booking?.status === EBookingStatus.PERFORMED && (
												<Box marginBottom="25px">
													<RateBookingButton bookingId={booking_id} token={tokenParam} />
												</Box>
											)}

											{!!booking?.ticket_url &&
												[EBookingStatus.PAID, EBookingStatus.PERFORMED].includes(booking?.status) && (
													<DownloadTicketButton
														bookingId={booking_id}
														link={booking.ticket_url}
														outlined={booking?.status === EBookingStatus.PERFORMED}
													/>
												)}

											{canCancelBooking && (
												<Box marginTop="25px">
													<CancelBookingButton
														id={booking_id}
														reload={setForceReload}
														withGuestCredentials={!!tokenParam}
													/>
												</Box>
											)}
										</Box>
									</Box>
								</>
							)
						)}
					</BookingDetails>
				) : (
					!loaded && <Loader />
				)}
			</Container>
		</AppWrapper>
	);
};

export default GuestBookingPage;
