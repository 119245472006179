import { useState } from "react";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import bookingsService from "services/api/bookings";

import ActionButton from "ui/buttons/ActionButton";

interface IDownloadTicketButton {
	bookingId: string | undefined;
	link?: string;
	outlined?: boolean;
	isDownloadReceipt?: boolean;
}

const DownloadTicketButton = ({ bookingId, link, outlined, isDownloadReceipt }: IDownloadTicketButton) => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const [loader, setLoader] = useState<boolean>(false);

	const downloadTicketOrReceipt = async () => {
		try {
			setLoader(true);

			let linkParam = link;

			if (!linkParam && bookingId) {
				if (isDownloadReceipt) {
					const receiptData = await bookingsService.getBookingReceipt(bookingId);
					linkParam = receiptData.url;
				} else {
					const bookingData = await bookingsService.getBooking(bookingId);

					linkParam = bookingData.data.ticket_url;
				}
			}

			if (linkParam) {
				const linkElem = document.createElement("a");

				linkElem.href = linkParam;

				linkElem.click();
			} else {
				throw new Error(t("DOWNLOAD_TICKET_BUTTON.ERROR_MESSAGE"));
			}
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setLoader(false);
		}
	};

	return (
		<ActionButton
			onClick={() => downloadTicketOrReceipt()}
			translationDefault={isDownloadReceipt ? "DOWNLOAD_RECEIPT_BUTTON.LABEL" : "DOWNLOAD_TICKET_BUTTON.LABEL"}
			padding={20}
			isAction={loader}
			disabled={loader || !bookingId}
			variant={outlined ? "outlined" : "contained"}
		/>
	);
};

export default DownloadTicketButton;
