import { rgba } from "polished";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

const DialogWrapper = styled.div<{ center?: boolean }>`
	width: 100%;
	height: 100%;
	font-family: ${MontserratFontFamily};
	line-height: 1;
	display: flex;
	flex-direction: column;
	${props => props.center && "align-items: center"};
	padding-bottom: 15px;
`;

const DialogTitle = styled.h1<{ underline?: boolean }>`
	padding: 15px;
	text-align: center;
	font-size: 24px;
	font-weight: 600;
	color: ${rgba(colors.mainFontColor, 0.87)};
	margin: 0 0 15px;
	width: 100%;
	line-height: 1;

	${props =>
		props.underline &&
		css`
			border-bottom: 1px solid ${colors.dividerColor};
		`}
`;

interface IDialogContentWrapper {
	title?: string | ReactNode;
	center?: boolean;
	underline?: boolean;
	children: ReactNode;
}

const DialogContentWrapper = ({ title, center, underline, children }: IDialogContentWrapper) => (
	<DialogWrapper center={center}>
		{!!title && <DialogTitle underline={underline}>{title}</DialogTitle>}

		{children}
	</DialogWrapper>
);

export default DialogContentWrapper;
