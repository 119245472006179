import { Step } from "@material-ui/core";

import { StepLabel, Stepper } from "./styled";

interface IProps {
	activeStep?: number;
	stepsAmount?: number;
}

const StepBar = ({ activeStep = 1, stepsAmount = 4 }: IProps) => (
	<Stepper activeStep={activeStep - 1}>
		{Array.from(Array(stepsAmount)).map((_, i) => (
			<Step key={i}>
				<StepLabel>{i + 1}</StepLabel>
			</Step>
		))}
	</Stepper>
);

export default StepBar;
