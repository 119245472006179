import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getTime, isValid, subYears } from "date-fns";
import { isEmpty, isString } from "lodash-es";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";

import { EUserStatus } from "data/users/types";

import { DATE_FORMATS, tryDateObjectToString } from "utils/dates";
import { omit } from "utils/objects";

import { ISharedOnboardingProps } from "../../types";
import BioLayout from "./components/BioLayout";

interface ChangeEventObject {
	value: unknown;
	name?: string | undefined;
}

interface BioPostData {
	languages: string[];
	skills_and_interests: string[];
	avatar_url: string;
	avatar_blob: Blob | null;
	occupation: string;
	motto: string;
	description: string;
	birthdate: string;
}

interface BioInputsDataErrors {
	[key: string]: boolean;
}

interface BioInputsData extends BioPostData {
	birthDatePure: Date | null;
	errors: BioInputsDataErrors;
}

interface Interest {
	label: string;
	value: string;
}

interface Language {
	label: string;
	value: string;
}

export interface BioProps {
	inputs: BioInputsData;
	userName: string;
	maxDateRangeTimestamp: number;

	handleDateChange(date: MaterialUiPickersDate, value: string | null): void;

	handleInputChange(event: ChangeEvent<ChangeEventObject>): void;

	handleInterestsChange(event: ChangeEvent<{}>, value: Interest[]): void;

	handleLanguagesChange(event: ChangeEvent<{}>, value: Language[]): void;

	handleOnFilesAdded(imgBlob: Blob, imgBase64: string): void;

	handleSendButtonClick(event: MouseEvent): void;

	isSendButtonDisabled(): boolean;

	validateField(fieldName: string): boolean;
}

const maxDateRangeTimestamp = getTime(subYears(new Date(), 18));

const usePrevious = (value: any): any => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const Bio = ({ updateProfileData, isBusy, profileData, moveToNextStep }: ISharedOnboardingProps) => {
	const isFirstRenderRef = useRef<boolean>(true);

	const [userName, setUserName] = useState<string>(profileData?.first_name || "");
	const [inputs, setInputs] = useState<BioInputsData>({
		avatar_url: "",
		avatar_blob: null,
		description: "",
		birthDatePure: null,
		birthdate: "",
		motto: "",
		occupation: "",
		skills_and_interests: [],
		languages: [],
		errors: {},
	});

	const interestsLength = inputs.skills_and_interests.length;
	const prevInterestsLength = usePrevious(inputs.skills_and_interests.length);
	const languagesLength = inputs.languages.length;
	const prevLanguagesLength = usePrevious(inputs.languages.length);

	useEffect(() => {
		setUserName(profileData?.first_name || "");

		setInputs(prevState => ({
			...prevState,
			...(profileData?.birthdate && {
				birthdate: profileData.birthdate,
				birthDatePure: new Date(profileData.birthdate),
			}),
			...(profileData?.occupation && { occupation: profileData.occupation }),
			...(profileData?.skills_and_interests && { skills_and_interests: profileData.skills_and_interests }),
			...(profileData?.languages && { languages: profileData.languages }),
			...(profileData?.motto && { motto: profileData.motto }),
			...(profileData?.description && { description: profileData.description }),
			...(profileData?.pictures?.profile && { avatar_url: profileData.pictures.profile }),
		}));
	}, [profileData]);

	const handleOnFilesAdded = (imgBlob: Blob, imgBase64: string) =>
		setInputs((inputsData: BioInputsData) => ({ ...inputsData, avatar_url: imgBase64, avatar_blob: imgBlob }));

	const handleInputChange = (event: ChangeEvent<ChangeEventObject>) => {
		event.persist();

		validateField("avatar_url");

		setInputs((inputsData: BioInputsData) => ({ ...inputsData, [event.target.name as string]: event.target.value }));
	};

	const handleDateChange = (date: Date | null, value: string | null) => {
		if (date) {
			const birthdate = tryDateObjectToString(date, DATE_FORMATS.DATE_FORMAT) ?? value;

			if (isString(birthdate)) {
				setInputs((inputsData: BioInputsData) => ({ ...inputsData, birthdate }));

				validateField("avatar_url");
			}
		}

		setInputs((inputsData: BioInputsData) => ({ ...inputsData, birthDatePure: date }));
	};

	const handleInterestsChange = (event: ChangeEvent, value: Interest[]) =>
		setInputs((inputsData: BioInputsData) => ({
			...inputsData,
			skills_and_interests: value.map(interest => interest.label),
		}));

	const handleLanguagesChange = (event: ChangeEvent, value: Language[]) =>
		setInputs((inputsData: BioInputsData) => ({ ...inputsData, languages: value.map(language => language.value) }));

	const handleSendButtonClick = async (event: MouseEvent) => {
		event.preventDefault();

		const apiCall = await updateProfileData({
			...omit(inputs, ["birthDatePure", "avatar_url", "errors"]),
			status: EUserStatus.AWAITING_REVIEW,
		});

		if (apiCall) {
			moveToNextStep();
		}
	};

	const isSendButtonDisabled = (): boolean => {
		const { errors, avatar_blob, birthDatePure, ...inputsForValidation } = inputs;

		const areValuesEmptyOrFalse: boolean =
			Object.values(inputsForValidation).filter(input => isEmpty(input)).length > 0;

		const areAnyErrors: boolean = Object.values(inputs.errors).filter(error => error).length > 0;

		return !!isBusy || areValuesEmptyOrFalse || areAnyErrors;
	};

	const isInvalidDate = () => {
		const date = new Date(inputs.birthdate);

		return (
			!isValid(date) || !isValid(inputs.birthDatePure) || (isValid(date) && date.getTime() > maxDateRangeTimestamp)
		);
	};

	const validateField = useCallback(
		(fieldName: string): boolean => {
			if (
				(fieldName === "occupation" && (inputs[fieldName].length < 2 || inputs[fieldName].length > 100)) ||
				(fieldName === "motto" && (inputs[fieldName].length < 5 || inputs[fieldName].length > 300)) ||
				(fieldName === "description" && (inputs[fieldName].length < 40 || inputs[fieldName].length > 1000)) ||
				(fieldName === "birthdate" && isInvalidDate()) ||
				inputs[fieldName].length <= 0
			) {
				setInputs((inputsData: BioInputsData) => ({
					...inputsData,
					errors: { ...inputsData.errors, [fieldName]: true },
				}));

				return true;
			}

			setInputs((inputsData: BioInputsData) => ({
				...inputsData,
				errors: { ...inputsData.errors, [fieldName]: false },
			}));

			return false;
		},
		// eslint-disable-next-line
		[inputs],
	);

	useEffect(() => {
		if (prevInterestsLength && interestsLength < prevInterestsLength) {
			validateField("skills_and_interests");
		}

		if (prevLanguagesLength && languagesLength < prevLanguagesLength) {
			validateField("languages");
		}
	}, [interestsLength, prevInterestsLength, languagesLength, prevLanguagesLength, validateField]);

	useEffect(() => {
		if (inputs.avatar_url) {
			validateField("avatar_url");
		}
		// eslint-disable-next-line
	}, [inputs.avatar_url]);

	useEffect(() => {
		if (!isFirstRenderRef.current) {
			validateField("birthdate");
		} else {
			isFirstRenderRef.current = false;
		}
		// eslint-disable-next-line
	}, [inputs.birthDatePure]);

	const bioProps = {
		inputs,
		maxDateRangeTimestamp,
		userName,
		handleDateChange,
		handleInputChange,
		handleInterestsChange,
		handleLanguagesChange,
		handleOnFilesAdded,
		handleSendButtonClick,
		isSendButtonDisabled,
		validateField,
	};

	return <BioLayout {...bioProps} />;
};

export default Bio;
