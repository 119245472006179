import { CircularProgress } from "@material-ui/core";
import { Crop, DeleteOutlineOutlined, PanoramaOutlined } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";

import CoverImg from "assets/images/common/placeholder-no-photo-big.svg";

import { IGalleryItem } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import GalleryImgEditorDialog from "ui/GalleryImgEditorDialog";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

import { configImg } from "./config";
import { PhotoTileType } from "./types";

interface IPhotoTileProps {
	type: PhotoTileType;
	photo?: IGalleryItem;
	saveMainPhoto?: (v: IGalleryItem) => void;
	savePhoto?: (v: Blob) => void;
	deletePhoto?: (id: string) => void;
}

const InnerWrapper = styled.div`
	visibility: hidden;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.7);
	border-radius: inherit;
`;

const Wrapper = styled.div<{ urlImg: any; heightTile: string; widthTile: string; backgroundSize: string }>`
	margin-top: 20px;
	background-image: url(${props => props.urlImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: ${props => props.backgroundSize};
	width: ${props => props.widthTile};
	height: ${props => props.heightTile};
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s linear;
	position: relative;

	&:hover {
		${InnerWrapper} {
			visibility: visible;
		}
	}
	${media.desktop`
    width: 100%;
  `};
`;

const OptionBox = styled.div`
	width: 80px;
	height: 70px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	border-radius: 4px;

	&:hover {
		background: rgba(0, 0, 0, 0.5);
		transform: scale(1.05);
	}
`;

const Text = styled.p`
	font-family: ${RobotoFontFamily};
	font-size: 9px;
	letter-spacing: 0.06px;
	text-align: center;
	color: ${colors.sunShade};
	text-transform: uppercase;
`;

const AddPhoto = styled.div`
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: inherit;

	&:hover {
		background: rgba(0, 0, 0, 0.1);
	}
`;

const LoaderContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	background: rgba(0, 0, 0, 0.5);
`;

const PhotoTile = ({ type, photo, saveMainPhoto, savePhoto, deletePhoto }: IPhotoTileProps) => {
	const { t } = useTranslation();

	const [openAddPhotoDialog, setOpenAddPhotoDialog] = useState<boolean>(false);
	const [initialImage, setInitialImage] = useState<{ media_url: string; media_url_blob?: Blob } | undefined>(
		photo || undefined,
	);
	const [isAction, setIsAction] = useState<boolean>(false);

	const handleSaveMainPhoto = () => {
		if (saveMainPhoto && photo) {
			saveMainPhoto(photo);
		}
	};

	const handleCropPhoto = () => {
		setOpenAddPhotoDialog(true);
	};

	const handleDeletePhoto = async () => {
		if (deletePhoto && photo) {
			setIsAction(true);
			await deletePhoto(photo.id);
		}
	};

	const handleSaveDialog = async (imgBlob: Blob, imgBase64: string) => {
		setInitialImage({ media_url: imgBase64, media_url_blob: imgBlob });

		if (savePhoto) {
			setIsAction(true);
			await savePhoto(imgBlob);
			setIsAction(false);
		}
	};

	return (
		<Wrapper
			urlImg={initialImage?.media_url || CoverImg}
			heightTile={configImg[type].height}
			widthTile={configImg[type].width}
			backgroundSize={initialImage ? "cover" : "auto"}
		>
			{isAction && (
				<LoaderContainer>
					<CircularProgress color="primary" />
				</LoaderContainer>
			)}

			{openAddPhotoDialog && (
				<GalleryImgEditorDialog
					showDialog
					initialImage={initialImage?.media_url}
					handleClose={() => setOpenAddPhotoDialog(prevState => !prevState)}
					saveImage={handleSaveDialog}
				/>
			)}

			{initialImage?.media_url && !isAction ? (
				<InnerWrapper>
					{type !== PhotoTileType.large && (
						<OptionBox onClick={handleSaveMainPhoto}>
							<PanoramaOutlined color="primary" />
							<Text>{t("EXPERIENCE.MEDIA.SET_AS_DEFAULT_BTN")}</Text>
						</OptionBox>
					)}

					<OptionBox onClick={handleCropPhoto}>
						<Crop color="primary" />
						<Text>{t("EXPERIENCE.MEDIA.CROP_BTN")}</Text>
					</OptionBox>

					<OptionBox onClick={handleDeletePhoto}>
						<DeleteOutlineOutlined color="primary" />
						<Text>{t("EXPERIENCE.MEDIA.DELETE_BTN")}</Text>
					</OptionBox>
				</InnerWrapper>
			) : (
				<AddPhoto onClick={() => setOpenAddPhotoDialog(prevState => !prevState)} />
			)}
		</Wrapper>
	);
};

export default PhotoTile;
