import { Box, CircularProgress } from "@material-ui/core";
import { FormikProps, withFormik } from "formik";
import { omit, pick } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { EExpType, IExperienceDTO } from "data/experiences/types";
import {
	EOnboardingSteps,
	EUserOnboardingStatus,
	EUserOnboardingType,
	EUserProfilePictureKind,
	EUserStatus,
	EUserType,
	IProfileDraft,
	IPublicProfile,
	IUser,
} from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import experiencesService from "services/api/experiences";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import store from "store/index";
import { selectUser, selectUserById, selectUserByIdStatus, selectUserStatus } from "store/selectors/user";
import {
	deleteUserProfileMedia,
	fetchAuthenticatedUser,
	fetchUserById,
	getUserProfileMediaUrl,
	updateUserProfile,
	userActions,
} from "store/slices/users";

import fillRoute from "utils/routes";

import RecordHP from "components/experiences/RecordHP";
import { carouselSwiperConfig } from "components/experiences/RecordHP/config";
import AppWrapper from "components/layout/AppWrapper";

import CarouselSwiper from "ui/CarouselSwiper";

import { Container } from "styles/common";

import ProfileView from "./components/ProfileView";
import { MainTitleSection } from "./components/ProfileView/shared.styled";
import useValidationSchema from "./validate";

const Wrapper = styled.div`
	margin-top: 40px;
`;

const TitleSection = styled(MainTitleSection)`
	margin: 0 0 40px;
`;

const LoaderContent = styled(Box)`
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export type FormValues = Pick<
	IProfileDraft,
	| "description"
	| "motto"
	| "occupation"
	| "languages"
	| "skills_and_interests"
	| "pictures"
	| "first_name"
	| "last_name"
	| "status"
	| "cities"
> &
	Pick<IPublicProfile, "is_verified"> &
	Pick<IUser, "ratings"> & {
		picturesBlobs_HELPER?: {
			[EUserProfilePictureKind.profile]?: Blob;
			[EUserProfilePictureKind.cover]?: Blob;
		};
		media_gallery_HELPER?: IProfileDraft["media_gallery"];
		pictures_HELPER?: IProfileDraft["pictures"];
		sendToReviewIndicator_HELPER?: boolean;
	};

const defaultValues: FormValues = {
	first_name: "",
	last_name: "",
	description: "",
	motto: "",
	occupation: "",
	cities: [],
	languages: [],
	skills_and_interests: [],
	pictures: {
		[EUserProfilePictureKind.profile]: "",
		[EUserProfilePictureKind.cover]: "",
	},
	is_verified: false,
	status: EUserStatus.DRAFT,
};

interface IProfilePage {
	staffEditMode?: boolean;
}

const ProfilePage = ({ staffEditMode }: IProfilePage) => {
	const { uid } = useParams<{ uid?: string }>();

	const history = useHistory();

	const { addError } = useNotification();
	const { handleAndNotify } = useErrors();

	const { filterArrayWithKeysOnlyLabels, filterArrayWithNamesOnlyKeys } = useLanguages();

	const [localUserData, setLocalUserData] = useState<IUser | null>(null);
	const [experiencesList, setExperiencesList] = useState<IExperienceDTO[] | null>(null);
	const [experiencesDesignedList, setExperiencesDesignedList] = useState<IExperienceDTO[] | null>(null);
	const [loader, setLoader] = useState<boolean>(false);

	const userData = useAppSelector(selectUser);
	const userStatus = useAppSelector(selectUserStatus);
	const publicUserData = useAppSelector(selectUserById);
	const publicUserStatus = useAppSelector(selectUserByIdStatus);

	const publicProfile = !!uid;
	const dataPath = publicProfile && !staffEditMode ? "public_profile" : "profile_draft";

	useEffect(() => {
		if (userStatus === "error") {
			setLoader(false);

			addError();
		}

		// eslint-disable-next-line
	}, [userStatus]);

	useEffect(() => {
		if (publicProfile && publicUserStatus === "error") {
			history.push(paths.NOT_FOUND);
		}

		// eslint-disable-next-line
	}, [publicProfile, publicUserStatus]);

	useEffect(() => {
		if (publicProfile) {
			// To reset the global state if somebody will open profile page on new tab in the browser
			store.dispatch<any>(userActions.resetUserById());

			store.dispatch<any>(fetchUserById(uid));
		} else {
			store.dispatch<any>(fetchAuthenticatedUser({ dontCatchUserApiError: true }));
		}

		return () => {
			if (publicProfile) {
				store.dispatch<any>(userActions.resetUserById());
			} else {
				store.dispatch<any>(fetchAuthenticatedUser({ dontCatchUserApiError: true }));
			}
		};

		// eslint-disable-next-line
	}, [publicProfile, uid]);

	useEffect(() => {
		if (publicProfile || !(userData?.userType === EUserType.TRAVELER)) {
			const callsToApi = [
				experiencesService
					.getListOfExperiences({
						exp_type: EExpType.UNIQUE,
						created_by: uid || userData?.id,
						offset: 0,
						limit: 100,
					})
					.then(experiences => setExperiencesList(experiences.data))
					.catch(e => handleAndNotify(e)),
				experiencesService
					.getListOfExperiences({
						exp_type: EExpType.COHOSTED,
						designed_by: uid || userData?.id,
						offset: 0,
						limit: 100,
					})
					.then(experiences => setExperiencesDesignedList(experiences.data))
					.catch(e => handleAndNotify(e)),
			];

			Promise.all(callsToApi);
		}

		// eslint-disable-next-line
	}, [userData, uid]);

	useEffect(() => {
		if (
			staffEditMode &&
			![EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT, undefined].includes(
				userData?.userType,
			)
		) {
			return history.replace(paths.NOT_FOUND);
		}

		if (
			!publicProfile &&
			userData?.userType === EUserType.LOCALHOST &&
			(
				[EUserOnboardingStatus.IN_PROGRESS, EUserOnboardingStatus.REGISTERED] as (EUserOnboardingStatus | undefined)[]
			).includes(userData?.onboarding?.status)
		) {
			return history.replace(
				fillRoute(paths.ONBOARDING, {
					step: userData.onboarding?.current_step || EOnboardingSteps.PhoneVerify,
					...(userData.onboarding?.type !== EUserOnboardingType.UNIQUE && { search: { type: "binipool" } }),
				}),
			);
		}

		setLocalUserData(publicProfile ? publicUserData : userData);

		// eslint-disable-next-line
	}, [publicProfile, staffEditMode, userData, publicUserData]);

	const { t } = useTranslation();

	const createListOfSliders = useCallback(
		() =>
			experiencesList?.map(v => (
				<RecordHP
					key={`${v.id}-${v.title}`}
					id={v.id}
					avatarUrl={(localUserData && localUserData[dataPath]?.pictures?.profile) || ""}
					city={v.city}
					hours={`${v.duration_minutes || 0} minutes`}
					imgUrl={v.photo_main}
					name={(localUserData && localUserData[dataPath]?.first_name) || ""}
					pricePerPerson={v.pricing.filter_price || 0}
					priceCurrency={v.pricing.currency}
					ratings={v.ratings}
					title={v.title}
					exp_type={v.exp_type}
					description={v.description}
					exposures={v.internal?.exposures}
				/>
			)),
		[dataPath, experiencesList, localUserData],
	);

	const createListOfSlidersDesigned = useCallback(
		() =>
			experiencesDesignedList?.map(v => (
				<RecordHP
					key={`${v.id}-${v.title}`}
					id={v.id}
					avatarUrl={(localUserData && localUserData[dataPath]?.pictures?.profile) || ""}
					city={v.city}
					hours={`${v.duration_minutes || 0} minutes`}
					imgUrl={v.photo_main}
					name={(localUserData && localUserData[dataPath]?.first_name) || ""}
					pricePerPerson={v.pricing.filter_price || 0}
					priceCurrency={v.pricing.currency}
					ratings={v.ratings}
					title={v.title}
					exp_type={v.exp_type}
					description={v.description}
					exposures={v.internal?.exposures}
				/>
			)),
		[dataPath, experiencesDesignedList, localUserData],
	);

	const handleSubmit = async (values: FormValues) => {
		let timer = 1000;

		setLoader(true);

		const data: { profile_draft: Partial<FormValues> } = {
			profile_draft: {
				...omit(values, "ratings"),
			},
		};

		if (!values?.skills_and_interests?.length) {
			delete data?.profile_draft?.skills_and_interests;
		}

		if (!data.profile_draft?.cities?.length) {
			delete data.profile_draft?.cities;
		}

		if (values?.languages?.length) {
			data.profile_draft.languages = filterArrayWithNamesOnlyKeys(values.languages);
		} else {
			delete data.profile_draft?.languages;
		}

		if (values.picturesBlobs_HELPER) {
			timer = timer + 4000;

			if (values.picturesBlobs_HELPER[EUserProfilePictureKind.profile]) {
				if (
					values.media_gallery_HELPER &&
					values.media_gallery_HELPER.length &&
					values.pictures_HELPER &&
					values.pictures_HELPER[EUserProfilePictureKind.profile]
				) {
					const mediaToDelete = values.media_gallery_HELPER.find(
						elem =>
							elem.media_url === (values.pictures_HELPER && values.pictures_HELPER[EUserProfilePictureKind.profile]),
					);

					if (mediaToDelete) {
						await store.dispatch<any>(
							deleteUserProfileMedia({ mediaId: mediaToDelete.id, ...(staffEditMode && { uid }) }),
						);
					}
				}

				await store.dispatch<any>(
					getUserProfileMediaUrl({
						file: values.picturesBlobs_HELPER[EUserProfilePictureKind.profile] as Blob,
						queryParams: { usage: "PROFILE" },
						...(staffEditMode && { uid }),
					}),
				);
			}

			if (values.picturesBlobs_HELPER[EUserProfilePictureKind.cover]) {
				if (
					values.media_gallery_HELPER &&
					values.media_gallery_HELPER.length &&
					values.pictures_HELPER &&
					values.pictures_HELPER[EUserProfilePictureKind.cover]
				) {
					const mediaToDelete = values.media_gallery_HELPER.find(
						elem =>
							elem.media_url === (values.pictures_HELPER && values.pictures_HELPER[EUserProfilePictureKind.cover]),
					);

					if (mediaToDelete) {
						await store.dispatch<any>(
							deleteUserProfileMedia({ mediaId: mediaToDelete.id, ...(staffEditMode && { uid }) }),
						);
					}
				}

				await store.dispatch<any>(
					getUserProfileMediaUrl({
						file: values.picturesBlobs_HELPER[EUserProfilePictureKind.cover] as Blob,
						queryParams: { usage: "COVER" },
						...(staffEditMode && { uid }),
					}),
				);
			}
		}

		delete data.profile_draft?.status;

		if (values?.sendToReviewIndicator_HELPER) {
			data.profile_draft.status = EUserStatus.AWAITING_REVIEW;
		}

		await store.dispatch<any>(
			updateUserProfile({
				data: omit(data, [
					"profile_draft.pictures",
					"profile_draft.is_verified",
					"profile_draft.picturesBlobs_HELPER",
					"profile_draft.media_gallery_HELPER",
					"profile_draft.pictures_HELPER",
					"profile_draft.sendToReviewIndicator_HELPER",
				]),
				...(staffEditMode && { uid }),
			}),
		);

		await setTimeout(() => {
			staffEditMode
				? store.dispatch<any>(fetchUserById(uid as string))
				: store.dispatch<any>(fetchAuthenticatedUser({ dontCatchUserApiError: true }));
		}, timer);

		await setTimeout(() => {
			setLoader(false);
		}, timer + 1000);
	};

	const validationSchema = useValidationSchema(userData?.userType === EUserType.TRAVELER);

	const ProfileViewWithFormik = useMemo(
		() =>
			withFormik({
				mapPropsToValues: () => {
					let initialValues: FormValues = defaultValues;

					if (localUserData) {
						initialValues = { ...initialValues, ...pick(localUserData[dataPath], Object.keys(initialValues)) };

						if (localUserData.public_profile && localUserData.public_profile?.is_verified) {
							initialValues.is_verified = localUserData.public_profile.is_verified;
						}

						initialValues.languages = filterArrayWithKeysOnlyLabels(initialValues.languages);

						initialValues.media_gallery_HELPER = localUserData[dataPath]?.media_gallery;
						initialValues.pictures_HELPER = localUserData[dataPath]?.pictures;

						initialValues.ratings = localUserData.ratings;
					}

					return initialValues;
				},
				handleSubmit,
				validationSchema,
				enableReinitialize: true,
			})(({ ...formikProps }: FormikProps<FormValues>) => (
				<ProfileView
					{...formikProps}
					publicProfile={publicProfile}
					staffEditMode={staffEditMode}
					travelerType={userData?.userType === EUserType.TRAVELER}
				/>
			)),

		// eslint-disable-next-line
		[uid, publicProfile, staffEditMode, userData, localUserData, dataPath, filterArrayWithKeysOnlyLabels],
	);

	return (
		<AppWrapper>
			<Container>
				{localUserData && !loader ? (
					<ProfileViewWithFormik />
				) : (
					<LoaderContent>
						<CircularProgress color="primary" />
					</LoaderContent>
				)}

				{(publicProfile || !(userData?.userType === EUserType.TRAVELER)) && (
					<>
						{experiencesList === null ? (
							<Wrapper>
								<LoaderContent>
									<CircularProgress color="primary" />
								</LoaderContent>
							</Wrapper>
						) : (
							!!experiencesList &&
							!!experiencesList.length && (
								<Wrapper>
									<TitleSection>{t("PROFILE.MY_UNIQUE_EXP_TITLE")}</TitleSection>

									<CarouselSwiper
										heightSlide="inherit"
										widthSlide="inherit"
										paddingBox={5}
										settings={carouselSwiperConfig}
										slides={createListOfSliders() || []}
									/>
								</Wrapper>
							)
						)}

						{experiencesDesignedList === null ? (
							<Wrapper>
								<LoaderContent>
									<CircularProgress color="primary" />
								</LoaderContent>
							</Wrapper>
						) : (
							!!experiencesDesignedList &&
							!!experiencesDesignedList.length && (
								<Wrapper>
									<TitleSection>{t("PROFILE.MY_DESIGNED_EXP_TITLE")}</TitleSection>

									<CarouselSwiper
										heightSlide="inherit"
										widthSlide="inherit"
										paddingBox={5}
										settings={carouselSwiperConfig}
										slides={createListOfSlidersDesigned() || []}
									/>
								</Wrapper>
							)
						)}
					</>
				)}
			</Container>
		</AppWrapper>
	);
};

export default ProfilePage;
