import { Button, FormGroup, InputAdornment, TextField } from "@material-ui/core";
import { rgba } from "polished";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled, { css } from "styled-components";

import { ReactComponent as LinkIcon } from "assets/icons/link-icon.svg";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import Dialog from "ui/Dialog";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import Share, { IShare } from "../../index";
import { getSharedUrl } from "../../utils";

interface ISharePopup extends IShare {
	showDialog: boolean;
	setShowDialog: (value: boolean) => void;
	popupTitle?: string;
	popupDescription?: string;
}

const Text = styled.p<{ margin?: string; withoutLines?: boolean }>`
	text-align: center;
	color: ${rgba(colors.middleGray, 0.87)};
	font-family: ${RobotoFontFamily};
	line-height: 1.5;
	font-size: 14px;
	margin: ${props => props.margin || "25px 0"};
	position: relative;
	width: 100%;

	span {
		position: relative;
		z-index: 2;
		background: white;
		padding: 0 20px;
	}

	${props =>
		!props.withoutLines &&
		css`
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background: #e5e5e5;
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 0;
				right: 0;
				transform: translateY(-50%);
			}
		`}
`;

const StyledFormGroup = styled(FormGroup)`
	gap: 15px;
`;

const SharePopup = ({ popupTitle, popupDescription, showDialog, setShowDialog, ...rest }: ISharePopup) => {
	const { t } = useTranslation();
	const { addSuccess } = useNotification();

	const closeDialog = () => setShowDialog(false);
	const onSuccessCopy = () => addSuccess("SHARE.LINK_COPIED", true);

	return (
		<Dialog showDialog={showDialog} handleClose={closeDialog} minHeight="auto">
			<DialogContentWrapper title={popupTitle || t("SHARE.EXPERIENCE_DIALOG_TITLE")} center>
				<Text margin="-10px 0 25px" withoutLines>
					{popupDescription || t("SHARE.SHARE_LINK_VIA")}
				</Text>
				<Share {...rest} />

				<Text>
					<span>{t("SHARE.COPY_LINK_TEXT")}</span>
				</Text>
				<StyledFormGroup row>
					<TextField
						size="small"
						variant="outlined"
						disabled
						defaultValue={getSharedUrl(rest?.shareUrl, false, rest?.addHostName)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LinkIcon />
								</InputAdornment>
							),
						}}
					/>

					<CopyToClipboard text={getSharedUrl(rest?.shareUrl, true, rest?.addHostName)} onCopy={onSuccessCopy}>
						<Button variant="contained" color="primary">
							{t("SHARE.BUTTON_COPY_LABEL")}
						</Button>
					</CopyToClipboard>
				</StyledFormGroup>
			</DialogContentWrapper>
		</Dialog>
	);
};

export default SharePopup;
