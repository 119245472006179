import { Box, TextField } from "@material-ui/core";
import { ChangeEvent, useCallback } from "react";

export interface IRangeInputValues {
	from: string;
	to: string;
}

interface IRangeInput {
	labelFirstInput: string;
	labelSecondInput: string;
	handleChange: (v: IRangeInputValues) => void;
	name: string;
	typeInputs: "text" | "number";
	initialValue: IRangeInputValues;
	withoutDecimalsRemove?: boolean;
}

const RangeInput = ({
	name,
	labelFirstInput,
	labelSecondInput,
	handleChange,
	typeInputs = "text",
	initialValue,
	withoutDecimalsRemove,
}: IRangeInput) => {
	const handleChangeRange = useCallback(
		(type: string) => (event: ChangeEvent<HTMLInputElement>) => {
			event.persist();

			const value =
				!withoutDecimalsRemove && typeInputs === "number" ? parseInt(event.target.value, 10) : event.target.value;

			handleChange({ ...initialValue, [type]: value });
		},
		[handleChange, initialValue, typeInputs, withoutDecimalsRemove],
	);

	return (
		<Box display="flex">
			<Box flex={0.8}>
				<TextField
					id={name}
					label={labelFirstInput}
					value={initialValue.from}
					onChange={handleChangeRange("from")}
					style={{ width: "100%" }}
					type={typeInputs}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Box>

			<Box display="flex" alignItems="flex-end" justifyContent="center" width="20px" paddingBottom="5px">
				-
			</Box>

			<Box flex={0.8}>
				<TextField
					id={name}
					label={labelSecondInput}
					value={initialValue.to}
					onChange={handleChangeRange("to")}
					style={{ width: "100%" }}
					type={typeInputs}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Box>
		</Box>
	);
};

export default RangeInput;
