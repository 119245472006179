import { isObject } from "lodash-es";

import colors from "styles/colors";

import { localbiniProductionDomainLink } from "./constants";

enum EEnvNames {
	PRODUCTION = "PRODUCTION",
	DEVELOPMENT = "DEVELOPMENT",
}

const mainDomain = new URL(localbiniProductionDomainLink);

const isProduction = process.env.REACT_APP_SITE_URL === mainDomain.origin;

const isDevelopment = !isProduction;

const getSubdomain = (subdomain?: string | null): URL | null =>
	subdomain ? new URL(`https://${subdomain}.${mainDomain.host}`) : null;

const environmentBackgrounds: Record<string, string | Record<string, string>> = {
	develop: {
		default: colors.green,
		alpha: "#844DD1",
		beta: colors.sunShade,
		gama: "#3d8086",
		localhost: colors.mainFontColor,
		staging:
			"radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 100%)," +
			"radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
	},
	demo: {
		default: colors.red,
		localhost: colors.mainFontColor,
	},
};

const getEnvironmentDetails = (
	apiUrl = process.env.REACT_APP_API_URL,
	locationHost?: string,
): { color: string; name: string; uniqueEnvName: string } | false => {
	if (!apiUrl || !Object.keys(environmentBackgrounds).includes(apiUrl)) {
		return false;
	}

	const found = environmentBackgrounds[apiUrl];

	if (typeof found === "string") {
		return { color: found, name: apiUrl, uniqueEnvName: apiUrl.toString().toUpperCase() };
	}

	if (isObject(found)) {
		const host = locationHost || window.location.host;

		const contains = Object.keys(found).find(v => host.includes(v));

		if (!!contains) {
			return { color: found[contains], name: contains, uniqueEnvName: (apiUrl + "+" + contains).toUpperCase() };
		}

		return found?.default ? { color: found.default, name: apiUrl, uniqueEnvName: EEnvNames.DEVELOPMENT } : false;
	}

	return false;
};

const getEnvironmentUniqueName = (apiUrl?: string, locationHost?: string) => {
	const info = getEnvironmentDetails(apiUrl, locationHost);

	return info ? info.uniqueEnvName : EEnvNames.PRODUCTION;
};

export { isProduction, isDevelopment, mainDomain, getEnvironmentDetails, getEnvironmentUniqueName, getSubdomain };
