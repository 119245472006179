import { CircularProgress, createStyles, Grid, makeStyles, TextField } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { Form, FormikProvider, useFormik } from "formik";
import { keys, omit, pick, sortBy } from "lodash-es";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import {
	EContactUsOptions,
	IContactUsDataFields,
	TContactUsFields,
	TKeyOfContactUsOptions,
} from "data/notifications/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import notificationsService from "services/api/notifications";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { DATE_FORMATS } from "utils/dates";
import { omitInEnum } from "utils/enums";

import MuiDateProvider from "providers/MuiDateProvider";

import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";
import PrivacyUrlsFooter from "ui/PrivacyUrlsFooter";

import createValidationSchema from "./validate";

const Textarea = styled(Input)`
	.MuiTextField-root,
	.MuiInputBase-input {
		min-height: 50px;
	}
`;

const commonStyles = css`
	margin-bottom: 0.625rem;

	& * {
		cursor: pointer;
	}
`;

const StyledAutocomplete = styled(Autocomplete)`
	${commonStyles}
`;

const StyledDateTimePicker = styled(DateTimePicker)`
	width: 100%;
	${commonStyles}
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridButtonStyle: {
			[theme.breakpoints.up("md")]: {
				justifyContent: "flex-end",
			},
			[theme.breakpoints.down("md")]: {
				justifyContent: "center",
			},
		},
	}),
);

interface IContactForm {
	setIsSent: (isSent: boolean) => void;
}

const ContactForm = ({ setIsSent }: IContactForm) => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const classes = useStyles();

	const userData = useAppSelector(selectUser);

	const [options, setOptions] = useState<{ [K in TKeyOfContactUsOptions]: TContactUsFields }>();
	const [values, setValues] = useState<{ [K in keyof IContactUsDataFields]: string } & { subject_HELPER: string }>(
		createValidationSchema([]).initialValues,
	);

	const { validationSchema, initialValues } = createValidationSchema(
		values.subject_HELPER && options ? options[values.subject_HELPER] : [],
	);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema,
		initialValues: {
			...initialValues,
			...pick(values, keys(initialValues)),
			...(userData?.personal?.email && { from: userData.personal.email }),
			...(userData?.profile_draft?.first_name && { name: userData.profile_draft.first_name }),
		},
		onSubmit: async formValues => {
			try {
				await notificationsService.postSendContactUsEmail({
					option: formValues.subject_HELPER as TKeyOfContactUsOptions,
					email_config: {
						...omit(formValues, "subject_HELPER"),
						...(formValues.datetime && { datetime: new Date(formValues.datetime).toISOString().slice(0, -5) }),
						...(userData?.id && { user_id: userData.id }),
					},
				});

				setIsSent(true);
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	useEffect(() => {
		(async () => {
			try {
				const data = await notificationsService.getContactUsOptions();

				setOptions(data);
			} catch (e) {
				handleAndNotify(e);
			}
		})();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (formikProps.values.subject_HELPER) {
			setValues(formikProps.values);
		}
		// eslint-disable-next-line
	}, [formikProps.values.subject_HELPER]);

	const subjectsList = sortBy(
		Object.entries(omitInEnum(EContactUsOptions, [EContactUsOptions.vip_booking_request])).map(([value, label]) => ({
			value,
			label: t(label),
		})),
		elem => elem.label,
	);

	return (
		<FormikProvider value={formikProps}>
			{!!options ? (
				<Form>
					<Grid container spacing={10} item lg={6} xs={12}>
						<Grid item md={6} xs={12}>
							<Input
								name="name"
								inputClassName="full"
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.FIRST_NAME")}
								required
								disabled={!!userData?.profile_draft?.first_name || formikProps.isSubmitting}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Input
								name="from"
								label={t("CONTACT.EMAIL")}
								inputClassName="full"
								required
								disabled={!!userData?.personal?.email || formikProps.isSubmitting}
							/>
						</Grid>

						<Grid item xs={12}>
							<StyledAutocomplete
								onChange={(event, elem: { value: string; label: string }) =>
									formikProps.setFieldValue("subject_HELPER", elem.value)
								}
								disableClearable
								options={subjectsList}
								getOptionLabel={(option: { value: string; label: string }) => option.label}
								renderInput={params => (
									<TextField
										{...params}
										name="subject_HELPER"
										label={t("CONTACT.SUBJECT")}
										variant="outlined"
										required
										disabled={formikProps.isSubmitting}
										InputProps={{ ...params.InputProps, readOnly: true }}
										error={formikProps.touched.subject_HELPER && !!formikProps.errors.subject_HELPER}
										helperText={formikProps.touched.subject_HELPER && formikProps.errors.subject_HELPER}
									/>
								)}
							/>
						</Grid>

						{formikProps.values.phone !== undefined && (
							<Grid item xs={12}>
								<Input
									name="phone"
									label={t("CONTACT.PHONE")}
									inputClassName="full"
									required
									disabled={formikProps.isSubmitting}
								/>
							</Grid>
						)}

						{formikProps.values.subject !== undefined && (
							<Grid item xs={12}>
								<Input
									name="subject"
									label={t("CONTACT.CUSTOM_SUBJECT")}
									inputClassName="full"
									required
									disabled={formikProps.isSubmitting}
								/>
							</Grid>
						)}

						{formikProps.values.booking_id !== undefined && (
							<Grid item xs={12}>
								<Input
									name="booking_id"
									label={t("CONTACT.BOOKING_ID")}
									inputClassName="full"
									required
									disabled={formikProps.isSubmitting}
								/>
							</Grid>
						)}

						{formikProps.values.city !== undefined && (
							<Grid item md={6} xs={12}>
								<Input
									name="city"
									label={t("CONTACT.CITY")}
									inputClassName="full"
									required
									disabled={formikProps.isSubmitting}
								/>
							</Grid>
						)}

						{formikProps.values.participants_amount !== undefined && (
							<Grid item md={6} xs={12}>
								<Input
									type="number"
									name="participants_amount"
									label={t("CONTACT.PARTICIPANTS_AMOUNT")}
									inputClassName="full"
									required
									disabled={formikProps.isSubmitting}
								/>
							</Grid>
						)}

						{formikProps.values.datetime !== undefined && (
							<Grid item xs={12}>
								<MuiDateProvider>
									<StyledDateTimePicker
										onChange={value => formikProps.setFieldValue("datetime", value)}
										value={formikProps.values.datetime || new Date()}
										TextFieldComponent={params => (
											<TextField
												{...params}
												value={
													formikProps.values.datetime
														? format(formikProps.values.datetime as unknown as Date, DATE_FORMATS.DATETIME_AMPM_FORMAT)
														: ""
												}
												name="datetime"
												label={t("CONTACT.DATETIME")}
												variant="outlined"
												required
												disabled={formikProps.isSubmitting}
												error={formikProps.touched.datetime && !!formikProps.errors.datetime}
												helperText={formikProps.touched.datetime && formikProps.errors.datetime}
											/>
										)}
									/>
								</MuiDateProvider>
							</Grid>
						)}

						<Grid item xs={12}>
							<Textarea
								name="description"
								label={t("CONTACT.MESSAGE")}
								inputClassName="full"
								multiline
								required
								disabled={formikProps.isSubmitting}
							/>
							<PrivacyUrlsFooter firstMessage={t("AUTH.REGISTER.AGREEMENTS_FIRST_PART")} />
						</Grid>

						<Grid item xs={12} container className={classes.gridButtonStyle}>
							<ActionButton
								type="button"
								onClick={() => formikProps.handleSubmit()}
								translationDefault="CONTACT.SEND_MESSAGE"
								translationAction="CONTACT.SENDING_MESSAGE"
								isAction={formikProps.isSubmitting}
								disabled={formikProps.isSubmitting}
							/>
						</Grid>
					</Grid>
				</Form>
			) : (
				<CircularProgress color="primary" />
			)}
		</FormikProvider>
	);
};

export default ContactForm;
