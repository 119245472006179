import { KeyboardEvent } from "react";

type THandleInputEvent = KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;
type TCallbackOrNothing = ((event?: THandleInputEvent) => void) | false;

const handleEnter = (event: THandleInputEvent, callback: TCallbackOrNothing, elseCallback?: () => void) => {
	if (event.key === "Enter") {
		if (!!callback) {
			callback(event);
		}

		event.preventDefault();
		return;
	}

	if (!!elseCallback) {
		elseCallback();
	}
};

const handleBackspace = (event: THandleInputEvent, callback: TCallbackOrNothing, elseCallback?: () => void) => {
	if (["Delete", "Backspace"].includes(event.key)) {
		if (!!callback) {
			callback(event);
		}

		event.preventDefault();

		return;
	}

	if (!!elseCallback) {
		elseCallback();
	}
};

export { handleEnter, handleBackspace };
