import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IExperience } from "data/experiences/types";

import draftsService from "services/api/drafts";

export interface IDraftsBackofficeState {
	data: {
		drafts: IExperience[] | null;
		count: number | null;
		draft: IExperience | null;
	};
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IDraftsBackofficeState = {
	data: {
		drafts: null,
		count: null,
		draft: null,
	},
	status: "idle",
	error: null,
};

export const fetchDraftsBackoffice = createAsyncThunk<any, any>(
	"draftsBackoffice/fetch",
	async (queryParams = {}, thunkAPI) => {
		try {
			const draftsList = await draftsService.fetchAll(queryParams);

			return draftsList;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const { actions, reducer } = createSlice({
	name: "draftsBackoffice",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: {
		[fetchDraftsBackoffice.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.drafts = action.payload.data;
			state.data.count = action.payload.count;
			state.status = "succeeded";
			state.error = null;
		},
		[fetchDraftsBackoffice.pending.toString()]: (state, action: PayloadAction<any>) => {
			state.status = "loading";
			state.error = null;
		},
		[fetchDraftsBackoffice.rejected.toString()]: (state, action: PayloadAction<any>) => {
			state.status = "failed";
			state.error = true;
		},
	},
});

export const draftsBackofficeActions = actions;

export default reducer;
