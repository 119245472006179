import { isUndefined } from "lodash-es";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";

import { selectConfigsAllError, selectConfigsEarnings } from "../selectors/configs";
import { fetchConfigsAll } from "../slices/configs";

const useConfigEarnings = () => {
	const configsAllError = useAppSelector(selectConfigsAllError);

	const earnings = useAppSelector(selectConfigsEarnings);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (configsAllError === true && !earnings) {
			dispatch(fetchConfigsAll());
		}

		// eslint-disable-next-line
	}, [configsAllError, earnings]);

	const isLoading = isUndefined(earnings);

	return {
		earnings,
		isLoading,
	};
};

export default useConfigEarnings;
