import { eachDayOfInterval, parse } from "date-fns";

import { IDaysOfWeek } from "data/types";

import { DATE_FORMATS } from "./dates";

export const availableDaysOfWeek = (week: IDaysOfWeek) => {
	const tempWeek = {};

	Object.entries(week).forEach(([key, value]) => {
		tempWeek[key] = value.length > 0;
	});

	return tempWeek;
};

export const generateDatesInRange = (fromString: string, toString: string) => {
	try {
		const startDate = parse(fromString, DATE_FORMATS.DATE_FORMAT3, new Date());

		const endDate = parse(toString, DATE_FORMATS.DATE_FORMAT3, new Date());

		if (startDate.getTime() > endDate.getTime()) {
			endDate.setFullYear(endDate.getFullYear() + 1);
		}

		return eachDayOfInterval({ start: startDate, end: endDate });
	} catch {
		return [];
	}
};
