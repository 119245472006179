import { IDataProps } from "./index";

export const insertInArray = (arr, index, newItem) => [
	// part of the array before the specified index
	...arr.slice(0, index),
	// inserted item
	newItem,
	// part of the array after the specified index
	...arr.slice(index),
];

// from '1-3' to [1, 3]
export const parseParticipants = (value: string) => value.split("-").map(val => Number(val));

// transform array into an object with proper format for backend
export const transformArray = (array: IDataProps[]) => {
	const list = {};
	array.forEach(value => {
		const [from, to] = parseParticipants(value.participants);
		for (let i = from; i <= to; i++) {
			list[i] = value.pricePerPerson;
		}
	});

	return list;
};

export const emptyChartValues: Record<number, number> = {
	1: 0,
	2: 0,
	3: 0,
	4: 0,
	5: 0,
	6: 0,
};
