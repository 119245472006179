import { Box, CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import { FormikErrors, FormikValues } from "formik/dist/types";
import { isEmpty } from "lodash-es";
import { Dispatch, SetStateAction } from "react";

import { EPromotingSections, IExperienceDTO } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";

import { DATE_FORMATS } from "utils/dates";
import { prices } from "utils/prices";

import People from "components/experiences/People";
import VerifiedStatusBox from "components/experiences/VerifiedStatusBox";
import ChipsField from "components/formik/Chips";

import ActionButton from "ui/buttons/ActionButton";
import Calendar from "ui/forms/date-time/Calendar";

import colors from "styles/colors";
import { StyledLink } from "styles/common";

import {
	CalendarPopup,
	LinksWrapper,
	MainTitle,
	NotResultsLabel,
	PriceLabel,
	StyledCalendarToday,
	StyledDivider,
	StyledLanguageOutlined,
	StyledQueryBuilder,
	StyledSelectField,
	Text,
	TitlePage,
	TitleSection,
	TotalPriceLabel,
	Wrapper,
} from "../../shared.styled";
import { BookingFormValues } from "../../types";

interface IBookingFormViewProps
	extends Pick<IExperienceDTO, "title" | "is_verified" | "cancellation_policy_hours" | "languages" | "internal"> {
	handleSubmit(): void;
	getDateFromCalendar(v: any): void;
	externalHandleClosePeople(): Promise<boolean>;
	setShowCalendar: Dispatch<SetStateAction<boolean>>;
	datesList: string[];
	timesList: string[];
	showCalendar: boolean;
	isLoadingTimes: boolean;
	isLoadingPrices: boolean;
	formikPricing: BookingFormValues["pricing"];
	kids: number;
	adults: number;
	errors: FormikErrors<BookingFormValues>;
	values: FormikValues;
	touchedForm: boolean;
	filterPrice?: number;
}

const BookingFormView = ({
	title,
	is_verified,
	cancellation_policy_hours,
	languages,
	datesList,
	timesList,
	showCalendar,
	isLoadingTimes,
	isLoadingPrices,
	setShowCalendar,
	formikPricing,
	kids,
	adults,
	errors,
	values,
	touchedForm,
	internal,
	handleSubmit,
	getDateFromCalendar,
	externalHandleClosePeople,
	filterPrice,
}: IBookingFormViewProps) => {
	const { t, withRaw } = useTranslation();

	const { filterArrayWithKeys } = useLanguages();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const bookingData = useAppSelector(selectExperienceTravellerBooking);

	const priceUponRequest = internal?.exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);

	return (
		<Wrapper>
			{isSmallScreen && (
				<Box display="flex" alignItems="center">
					<TitlePage>{title}</TitlePage>

					{is_verified && <VerifiedStatusBox currentStatus={t("EXPERIENCE.STATUS_VERIFIED_EXPERIENCE")} />}
				</Box>
			)}

			<Box mb={4} mt={4}>
				<MainTitle>{t("BOOKING_FORM.BOOKING_SUMMARY")}:</MainTitle>
			</Box>

			<Box mb={6}>
				<People
					label={t("BOOKING_FORM.NB_PEOPLE")}
					nrAdults={adults}
					nrKids={kids}
					errors={errors}
					externalHandleClose={externalHandleClosePeople}
					touched={touchedForm}
				/>
			</Box>

			<Box mb={6}>
				<TitleSection>{t("BOOKING_FORM.WHEN_DATE")}</TitleSection>

				<Box display="flex" flexDirection="column" mt={3} mb={6}>
					<Box display="flex" alignItems="center">
						<Box mr={7} position="relative">
							<StyledCalendarToday
								onClick={() => setShowCalendar(prevState => !prevState)}
								disabled={!!bookingData.superbooking_id}
							/>

							{showCalendar && (
								<CalendarPopup>
									<Calendar
										disablePast
										initialValue={isEmpty(datesList) ? new Date() : new Date(datesList[0])}
										onClickDate={getDateFromCalendar}
										chosenDatesList={datesList}
										customDateFormat={DATE_FORMATS.DATE_FORMAT}
										customBackgroundColor={colors.white}
										width="350px"
										height="330px"
									/>
								</CalendarPopup>
							)}
						</Box>

						<ChipsField
							chipWidth={100}
							singleSelect
							clickable
							name="date"
							options={datesList.slice(0, 6)}
							disabled={!!bookingData.superbooking_id}
						/>
					</Box>

					<StyledDivider />
				</Box>
			</Box>

			<Box mb={6}>
				<TitleSection>{t("BOOKING_FORM.WHEN_TIME")}:</TitleSection>

				<Box display="flex" flexDirection="column" mt={3} mb={6}>
					<Box display="flex" alignItems="center">
						<Box mr={7}>
							<StyledQueryBuilder />
						</Box>

						{isLoadingTimes ? (
							<Box marginBottom="5px">
								<CircularProgress color="primary" size={15} />
							</Box>
						) : timesList.length ? (
							<ChipsField
								singleSelect
								clickable
								name="hour"
								options={timesList}
								chipWidth={60}
								disabled={!!bookingData.superbooking_id}
							/>
						) : null}
					</Box>

					<StyledDivider />

					{touchedForm && !isLoadingTimes && timesList.length === 0 && values.date?.length > 0 && (
						<NotResultsLabel>{withRaw("BOOKING_FORM.0_AVAILABLE_HOURS", null)}</NotResultsLabel>
					)}
				</Box>
			</Box>

			<Box mb={6}>
				<TitleSection>{t("BOOKING_FORM.LANGUAGE")}</TitleSection>

				<Box display="flex" alignItems="center" marginTop={3}>
					<StyledLanguageOutlined />

					<StyledSelectField
						variant="standard"
						name="language"
						withEmptyFirstElement={false}
						options={filterArrayWithKeys(languages)}
						disableUnderline
						disabled={!!bookingData.superbooking_id}
					/>
				</Box>

				<StyledDivider />
			</Box>

			{!priceUponRequest && !!filterPrice && (
				<>
					<Box mb={6}>
						<TitleSection>{t("BOOKING_FORM.PRICE")}</TitleSection>

						<Box marginTop={3} marginBottom={3}>
							<Box display="flex" mb={3}>
								<Box width={60}>
									<Text>{t("BOOKING_FORM.ADULTS")}</Text>
								</Box>

								<Text>
									<strong>{formikPricing.tickets?.adults.quantity}</strong> x{" "}
									<strong>
										{prices(formikPricing.tickets?.adults.price, formikPricing.currency, undefined, undefined, true)}
									</strong>
								</Text>
							</Box>

							<Box>
								{formikPricing.tickets?.kids &&
									Object.entries(formikPricing.tickets.kids).map(([age, details]) => (
										<Box display="flex" mb={3} key={age}>
											<Box width={60}>
												<Text>{t("BOOKING_FORM.KIDS")}</Text>
											</Box>

											<Text>
												<b>{details.quantity}</b> x{" "}
												<b>{prices(details.price, formikPricing.currency, undefined, undefined, true)}</b>
											</Text>
										</Box>
									))}
							</Box>
						</Box>

						<StyledDivider />
					</Box>

					<Box display="flex" flexDirection="column" alignItems="flex-end" mt={9} mb={12}>
						<TotalPriceLabel>{t("BOOKING_FORM.TOTAL_PRICE")}</TotalPriceLabel>

						<Box display="flex" alignItems="center" gridGap="10px">
							{bookingData.showVat && typeof formikPricing?.tax?.vat === "number" && (
								<Box color={colors.bermudaGray}>
									incl. {bookingData.vatRate}% VAT ({prices(formikPricing.tax.vat, formikPricing.currency)})
								</Box>
							)}

							<PriceLabel>
								{prices(formikPricing.final_gross_price, formikPricing.currency, undefined, undefined, true)}
							</PriceLabel>
						</Box>
					</Box>
				</>
			)}

			<Box mt={priceUponRequest ? 24 : undefined}>
				<ActionButton
					translationDefault={
						!priceUponRequest && filterPrice ? "BOOKING_FORM.BOOK_THIS_EXP_BTN" : "BOOKING_FORM.SEND_REQUEST_FOR_PRICE"
					}
					onClick={() => handleSubmit()}
					isDisabled={datesList.length === 0}
					isAction={isLoadingTimes || isLoadingPrices}
					fullWidth
				/>
			</Box>

			<LinksWrapper>
				{!priceUponRequest && !!filterPrice && (
					<span>
						{withRaw("BOOKING_FORM.FREE_CANCELLATION_1", null)}

						{!!cancellation_policy_hours ? (
							<>
								<strong> {cancellation_policy_hours}</strong> {t("BOOKING_FORM.FREE_CANCELLATION_3")}
							</>
						) : (
							t("BOOKING_FORM.FREE_CANCELLATION_2")
						)}
					</span>
				)}

				<Box display="flex" justifyContent="space-between" mt={3} mb={5} width={isSmallScreen ? "100%" : "80%"}>
					<StyledLink color={colors.mainFontColor} to={paths.HELP_CANCELLATION_POLICY}>
						<strong>{t("BOOKING_FORM.CANCELLATION_POLICY")}</strong>
					</StyledLink>

					<StyledLink color={colors.mainFontColor} to={paths.HELP_LEGAL_TERMS}>
						<strong>{t("BOOKING_FORM.TERMS_CONDITIONS")}</strong>
					</StyledLink>
				</Box>
			</LinksWrapper>
		</Wrapper>
	);
};

export default BookingFormView;
