import { ReactNode } from "react";

import { IInput } from "../../formik/Input";
import { NodeLabel, RateTextarea } from "../shared.styled";

interface ITextarea extends IInput {
	nodeLabel: ReactNode;
}

const Textarea = ({ nodeLabel, ...props }: ITextarea) => (
	<>
		<NodeLabel>{nodeLabel}</NodeLabel>
		<RateTextarea {...props} />
	</>
);

export default Textarea;
