import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";

import DufryImg from "assets/images/help/dufry.svg";
import GlobetrotterImg from "assets/images/help/globetrotter.svg";
import ImgPartner1 from "assets/images/help/musement.svg";
import PartnersImgSmall from "assets/images/help/partners-main.png";
import PartnersImgMedium from "assets/images/help/partners-main@2x.png";
import PartnersImgLarge from "assets/images/help/partners-main@3x.png";
import RailtourImg from "assets/images/help/railtour.svg";
import TauckImg from "assets/images/help/tauck.svg";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, ImgWrapper, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const Col = styled.div`
	flex: 1;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

const LogoWrapper = styled.section`
	margin: 20px 0 0 0;
`;

const DescriptionText = styled.p<{ isCenter?: boolean; isFontWeight600?: boolean }>`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	max-width: 80%;
	line-height: 1.69;
	letter-spacing: 0.03px;
	${props => props.isCenter && `text-align: center;`}
	${props => props.isFontWeight600 && `font-weight: 600;`}

  ${media.tablet`
    max-width: 100%;
  `};
`;

const LogoContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	${media.tablet`
    justify-content: center;
  `};
`;

const sharedStylesForLogoPicture = css`
	margin: 2.5rem auto;
`;

const LogoPicture = styled.img`
	${sharedStylesForLogoPicture}
`;

const LinkWithImage = styled.a`
	${sharedStylesForLogoPicture}
`;

const Background = styled.div`
	margin-top: 60px;
	padding-top: 40px;
	width: 100%;
	background-image: linear-gradient(178deg, rgba(255, 229, 210, 0.63) 7%, rgba(255, 244, 229, 0) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    padding-bottom: 20px;
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const MoreInfoWrapper = styled.section`
	margin: 0 auto;
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${media.desktop`
    width: 90%;
  `};
`;

const ButtonContainer = styled.div`
	margin-top: 1.25rem;
`;

const KeyPartnersPage = () => {
	const { t, withRaw } = useTranslation();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.KEY_PARTNERS.MAIN_TITLE")} />

				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.KEY_PARTNERS.MAIN_TITLE")}
							subTitle={t("HELP.KEY_PARTNERS.SUB_TITLE")}
							description={withRaw("HELP.KEY_PARTNERS.MAIN_DESCRIPTION", null)}
						/>
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Key partners - picture"
								imgSmall={PartnersImgSmall}
								imgMedium={PartnersImgMedium}
								imgLarge={PartnersImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>

				<LogoWrapper>
					<SmallerTitle>{t("HELP.KEY_PARTNERS.TITLE_SOME_OUR_KEY_DISTRIBUTION")}</SmallerTitle>

					<LogoContent>
						<LogoPicture src={ImgPartner1} title="LocalBini - Musement" alt="LocalBini - Musement" />
						<LogoPicture src={DufryImg} title="LocalBini - Dufry" alt="LocalBini - Dufry" />
						<LogoPicture src={RailtourImg} title="LocalBini - Railtour" alt="LocalBini - Railtour" />
						<LogoPicture src={TauckImg} title="LocalBini - Tauck" alt="LocalBini - Tauck" />
						<LogoPicture src={GlobetrotterImg} title="LocalBini - Globetrotter" alt="LocalBini - Globetrotter" />
					</LogoContent>

					<LogoContent>
						<LinkWithImage href="https://www.kayak.co.uk/Munich.14057.guide" target="_blank">
							<img
								src="https://www.kayak.com/news/badge/kk/tg010.png"
								width="250"
								height="100"
								title="LocalBini - Kayak"
								alt="LocalBini - Kayak"
							/>
						</LinkWithImage>
					</LogoContent>
				</LogoWrapper>
			</Container>

			<Background>
				<Container>
					<MoreInfoWrapper>
						<SmallerTitle>{t("HELP.KEY_PARTNERS.TITLE_MORE_DETAILS")}</SmallerTitle>

						<DescriptionText isCenter isFontWeight600>
							{withRaw("HELP.KEY_PARTNERS.DESC_MORE_DETAILS")}
						</DescriptionText>

						<ButtonContainer>
							<StyledLink to={paths.HELP_DISTRIBUTION_PARTNERS} $withoutHoverUnderline>
								<Button color="primary" variant="contained" size="large">
									{t("HELP.KEY_PARTNERS.MORE_INFO_BTN")}
								</Button>
							</StyledLink>
						</ButtonContainer>
					</MoreInfoWrapper>
				</Container>
			</Background>
		</AppWrapper>
	);
};

export default KeyPartnersPage;
