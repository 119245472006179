import { Box } from "@material-ui/core";
import { HTMLAttributes, ReactNode } from "react";

interface ITabPanel extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
	index: number;
	value: number;
}

const TabPanel = ({ children, value, index, ...rest }: ITabPanel) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`scrollable-auto-tabpanel-${index}`}
		aria-labelledby={`scrollable-auto-tab-${index}`}
		{...rest}
	>
		{value === index && <Box p={3}>{children}</Box>}
	</div>
);

export default TabPanel;
