import { Box, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { EBankAccountStatus, IFinancialsAddress, IFinancialsBankingDetails } from "data/financials/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import financialsService, { errorCodes } from "services/api/financials";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCity from "store/hooks/useCity";
import useCountry from "store/hooks/useCountry";
import { selectUser } from "store/selectors/user";

import { GlobOutlinedIcon, LocationOnOutlinedIcon } from "components/backoffice/shared.styled";
import Autocomplete from "components/formik/Autocomplete";
import Input from "components/formik/Input";
import InputV2 from "components/formik/InputV2";

import ActionButton from "ui/buttons/ActionButton";
import HoverTooltip from "ui/tooltips/HoverTooltip";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const Header3 = styled.h3`
	font-size: 16px;
	font-weight: normal;
	font-family: ${RobotoFontFamily};
	line-height: 1.5;
	letter-spacing: 0.09px;
	color: ${colors.darkGreen};
	margin: 20px 0 30px;
`;

const Header4 = styled.h4`
	font-size: 14px;
	font-weight: normal;
	font-family: ${RobotoFontFamily};
	line-height: 1.5;
	letter-spacing: 0.09px;
	color: ${colors.darkGreen};
	margin-bottom: 20px;
`;

const FooterContent = styled.div`
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #dadada;
	margin-top: 40px;
	padding-top: 10px;
`;

const StyledTooltip = styled(HoverTooltip)`
	position: absolute;
	right: 5px;
	top: 5px;
	margin: 0;
	width: 25px;
`;

const StyledInputWithPadding = styled(InputV2)`
	.MuiInputBase-input {
		margin-right: 25px;
	}
`;

const BankingDetails = () => {
	const { t } = useTranslation();

	const { success } = useNotification();
	const { handleAndNotify } = useErrors();

	const { citiesSelectSorted } = useCity();
	const { countriesSelectSorted } = useCountry();

	const loggedUser = useAppSelector(selectUser);

	const [userFinancials, setUserFinancials] = useState<IFinancialsBankingDetails>();
	const [update, setUpdate] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			try {
				if (!!loggedUser?.id) {
					const apiData = await financialsService.getBankingDetails(loggedUser?.id);

					setUserFinancials(apiData);

					setUpdate(true);
				}
			} catch (e) {
				// @ts-ignore
				if (e?.getError()?.error_code !== errorCodes.getBankingDetails.missingBankingDetails) {
					handleAndNotify(e);
				}

				setUpdate(false);
			}
		})();

		// eslint-disable-next-line
	}, [loggedUser?.id]);

	const formikProps = useFormik<
		Pick<IFinancialsBankingDetails, "first_name" | "last_name" | "account_number" | "swift"> & IFinancialsAddress
	>({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			first_name: Yup.string().required(t("ERRORS.REQUIRED")),
			last_name: Yup.string().required(t("ERRORS.REQUIRED")),
			account_number: Yup.string()
				.matches(/^[A-Z0-9\s]+$/, t("ERRORS.INVALID_AZ09SPACE"))
				.required(t("ERRORS.REQUIRED")),
			swift: Yup.string()
				.matches(/^[A-Z0-9\s]+$/, t("ERRORS.INVALID_AZ09SPACE"))
				.required(t("ERRORS.REQUIRED")),
			street: Yup.string().required(t("ERRORS.REQUIRED")),
			zip_code: Yup.string().required(t("ERRORS.REQUIRED")),
			city: Yup.string().required(t("ERRORS.REQUIRED")),
			country: Yup.string().required(t("ERRORS.REQUIRED")),
		}),
		initialValues: {
			first_name: userFinancials?.first_name || "",
			last_name: userFinancials?.last_name || "",
			account_number: userFinancials?.account_number || "",
			swift: userFinancials?.swift || "",
			street: userFinancials?.bank_address?.street || "",
			zip_code: userFinancials?.bank_address?.zip_code || "",
			city: userFinancials?.bank_address?.city || "",
			country: userFinancials?.bank_address?.country || "",
		},
		onSubmit: async values => {
			// @ts-ignore
			const { street, zip_code, city, country, account_number, ...rest } = values;

			const data = {
				bank_address: {
					street,
					zip_code,
					city,
					country,
				},
				account_number,
				...rest,
			};

			try {
				if (update) {
					const result = await financialsService.patchBankingDetails(loggedUser?.id || "", data);

					setUserFinancials(result);
				} else {
					const result = await financialsService.postBankingDetails({ ...data, uid: loggedUser?.id || "" });

					setUserFinancials(result);
				}

				success();
				setUpdate(true);

				formikProps.resetForm();
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	const savedAccountNumber =
		[EBankAccountStatus.VALID, EBankAccountStatus.INVALID, EBankAccountStatus.VALID_NOT_SEPA].includes(
			// @ts-ignore
			userFinancials?.bank_account_status,
		) && !formikProps.touched.account_number;

	return (
		<>
			<Header3>{t("MY_FINANCES.BANKING_DETAILS.SUB_HEADER")}</Header3>

			<FormikProvider value={formikProps}>
				<Form>
					<Header4>{t("MY_FINANCES.BANKING_DETAILS.FORM.ACCOUNT_OWNER_MAIN_LABEL")}</Header4>

					<Grid container spacing={10}>
						<Grid item md={5} xs={12}>
							<Input
								required
								name="first_name"
								label={t("MY_FINANCES.BANKING_DETAILS.FORM.FIRST_NAME")}
								inputClassName="full"
							/>
						</Grid>

						<Grid item md={5} xs={12}>
							<Input
								required
								name="last_name"
								label={t("MY_FINANCES.BANKING_DETAILS.FORM.LAST_NAME")}
								inputClassName="full"
							/>
						</Grid>
					</Grid>

					<Box marginTop="20px">
						<Header4>{t("MY_FINANCES.BANKING_DETAILS.FORM.BANK_DETAILS_MAIN_LABEL")}</Header4>

						<Grid container spacing={10}>
							<Grid item md={3} xs={12}>
								<Autocomplete
									name="country"
									label={t("MY_FINANCES.BANKING_DETAILS.FORM.COUNTRY")}
									options={countriesSelectSorted}
									textFieldProps={{
										InputProps: { startAdornment: <GlobOutlinedIcon /> },
										required: true,
									}}
									inputProps={{ autoComplete: "none" }}
									filterSelectedOptions
									freeSolo
								/>
							</Grid>

							<Grid item md={3} xs={12}>
								<Autocomplete
									name="city"
									label={t("MY_FINANCES.BANKING_DETAILS.FORM.CITY")}
									options={citiesSelectSorted}
									textFieldProps={{
										InputProps: { startAdornment: <LocationOnOutlinedIcon /> },
										required: true,
									}}
									inputProps={{ autoComplete: "none" }}
									filterSelectedOptions
									freeSolo
								/>
							</Grid>

							<Grid item md={4} xs={12}>
								<Input
									name="street"
									label={t("MY_FINANCES.BANKING_DETAILS.FORM.STREET_ADDRESS")}
									inputClassName="full"
									required
									maxLength={100}
								/>
							</Grid>

							<Grid item md={2} xs={12}>
								<Input
									name="zip_code"
									label={t("MY_FINANCES.BANKING_DETAILS.FORM.POST_CODE")}
									inputClassName="full"
									required
									maxLength={20}
								/>
							</Grid>
						</Grid>
					</Box>

					<Box marginTop="20px">
						<Grid container spacing={10}>
							<Grid item md={5} xs={12}>
								<Box position="relative">
									<StyledInputWithPadding
										name="account_number"
										label={t("MY_FINANCES.BANKING_DETAILS.FORM.ACCOUNT_NUMBER")}
										fullWidth
										required
										uppercase
										error={
											savedAccountNumber &&
											[EBankAccountStatus.INVALID, EBankAccountStatus.VALID_NOT_SEPA].includes(
												// @ts-ignore
												userFinancials?.bank_account_status,
											)
										}
									/>

									<StyledTooltip
										translation={
											savedAccountNumber
												? `MY_FINANCES.BANKING_DETAILS.FORM.ACCOUNT_NUMBER.TOOLTIP.${userFinancials?.bank_account_status}`
												: "MY_FINANCES.BANKING_DETAILS.FORM.ACCOUNT_NUMBER.TOOLTIP"
										}
										iconColor={
											savedAccountNumber
												? userFinancials?.bank_account_status === EBankAccountStatus.VALID
													? colors.brightGreen
													: colors.red
												: undefined
										}
										backgroundColor={
											savedAccountNumber
												? userFinancials?.bank_account_status === EBankAccountStatus.VALID
													? colors.brightGreen
													: colors.red
												: undefined
										}
									/>
								</Box>
							</Grid>

							<Grid item md={5} xs={12}>
								<InputV2
									name="swift"
									label={t("MY_FINANCES.BANKING_DETAILS.FORM.SWIFT")}
									fullWidth
									required
									uppercase
								/>
							</Grid>
						</Grid>
					</Box>

					<FooterContent>
						<ActionButton
							translationDefault={"SETTINGS.SAVE_CHANGES"}
							isAction={formikProps.isSubmitting}
							size="large"
							onClick={formikProps.handleSubmit}
						/>
					</FooterContent>
				</Form>
			</FormikProvider>
		</>
	);
};

export default BankingDetails;
