import { StepLabel as StepLabelMui, Stepper as StepperMui } from "@material-ui/core";
import styled from "styled-components";

import colors from "styles/colors";

export const Stepper = styled(StepperMui)`
	padding-top: 0;
	padding-bottom: 0;
`;

export const StepLabel = styled(StepLabelMui)`
	.MuiStepLabel-label {
		display: none;
	}

	.MuiStepLabel-iconContainer {
		padding-right: 0;
	}

	.MuiStepIcon-root:not(.MuiStepIcon-active) {
		color: #e5e5e5;
	}

	.MuiSvgIcon-root {
		width: 40px;
		height: 40px;
	}

	.MuiStepIcon-completed {
		color: ${colors.black} !important;
	}

	.MuiStepIcon-text {
		transform: translate(0, -1px);
		font-size: 9px;
		fill: ${colors.white};
	}
`;
