import { Box, CircularProgress } from "@material-ui/core";
import qs from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import useNotification from "hooks/useNotification";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import {
	selectDiscoverList,
	selectDiscoverStatus,
	selectExploreList,
	selectExploreStatus,
} from "store/selectors/homePage";
import { fetchExperiencesDiscover, fetchExperiencesExplore } from "store/slices/homePage";

import { sessionExpiredQueryParam } from "utils/constants";

import Advertisement from "components/home/Advertisement";
import Categories from "components/home/Categories";
import Discover from "components/home/Discover";
import Explore from "components/home/Explore";
import Header from "components/home/Header";
import AppWrapper from "components/layout/AppWrapper";

const LoaderContent = styled(Box)`
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const HomePage = () => {
	const { addInfo } = useNotification();

	const dispatch = useAppDispatch();

	const discoverList = useAppSelector(selectDiscoverList);
	const exploreList = useAppSelector(selectExploreList);
	const statusDiscover = useAppSelector(selectDiscoverStatus);
	const statusExplore = useAppSelector(selectExploreStatus);

	const location = useLocation();
	const params = qs.parse(location.search);

	useEffect(() => {
		dispatch(fetchExperiencesDiscover());

		dispatch(fetchExperiencesExplore());
	}, [dispatch]);

	useEffect(() => {
		if (!!params[sessionExpiredQueryParam]) {
			addInfo("NOTIFICATION.SESSION_ENDS", true);
		}

		// eslint-disable-next-line
	}, []);

	return (
		<AppWrapper isHomePage>
			<Header />

			{statusDiscover === "loading" || discoverList === null ? (
				<LoaderContent>
					<CircularProgress color="primary" />
				</LoaderContent>
			) : (
				discoverList.length !== 0 && <Discover discoverList={discoverList} />
			)}

			<Categories />

			{statusExplore === "loading" || exploreList === null ? (
				<LoaderContent>
					<CircularProgress color="primary" />
				</LoaderContent>
			) : (
				exploreList.length !== 0 && <Explore exploreList={exploreList} />
			)}

			<Advertisement />
		</AppWrapper>
	);
};

export default HomePage;
