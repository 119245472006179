import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Field } from "formik";

import Select, { ISelect, Option } from "ui/forms/Select";

interface ISelectField extends Omit<ISelect, "onChange" | "value"> {
	withEmptyFirstElement?: boolean;
	emptyLabel?: string;
}

const SelectField = ({ emptyLabel, withEmptyFirstElement = true, ...rest }: ISelectField) => {
	const restProps = { ...rest };

	if (restProps.variant === "outlined") {
		restProps.input = <OutlinedInput notched label={restProps.label} />;
	}

	if (restProps.required) {
		restProps.formControlProps = { ...restProps.formControlProps, required: restProps.required };
	}

	const keys = restProps.options.map(o => o.value);

	const otherOptions = withEmptyFirstElement ? [{ value: "", label: emptyLabel || "" }] : [];

	return (
		<Field name={restProps.name}>
			{({ field, form: { setFieldTouched, setFieldValue }, meta }) => {
				const validValue = keys.includes(field.value);

				const onChange = (value: Option["value"]) => {
					setFieldTouched(restProps.name, true);

					setFieldValue(restProps.name, value);
				};

				if (!withEmptyFirstElement && (!field.value || !validValue) && keys.length > 0) {
					// Prevent React error (updating state when rendering)
					setTimeout(() => {
						onChange(keys[0]);
					}, 0);
				}

				return (
					<Select
						{...restProps}
						value={field.value || ""}
						onChange={onChange}
						options={[...otherOptions, ...restProps.options]}
						error={meta.touched && meta.error}
					/>
				);
			}}
		</Field>
	);
};

export default SelectField;
