import { memo, ReactElement } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { SmallerTitle } from "ui/CommonParagraph";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

interface IQAndAItemProps {
	question: string | ReactElement;
	answer: string | ReactElement;
}

const QAMainContent = styled.div`
	width: 100%;
	margin: 2.5rem auto;
	display: flex;
	flex-direction: row;
	align-items: center;

	${media.desktop`
    flex-direction: column;
    margin: 2rem auto;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const QAndAItemWrapper = styled.div`
	max-width: 33.75rem;
	margin-bottom: 1.875rem;

	${media.desktop`
     max-width: 100%;
  `};
`;

const QAndAItemTitle = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: 18px;
	font-weight: bold;
	line-height: 1.44;
	letter-spacing: 0.04px;

	${media.tablet`
    line-height: 1.86;
     font-size: ${dimensions.fontSize.large};
  `};
`;

const QAndAItemDesc = styled.div`
	font-family: ${MontserratFontFamily};
	opacity: 0.9;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.86;
	letter-spacing: 0.03px;

	${media.tablet`
    line-height: 2.17;
    font-size: ${dimensions.fontSize.medium};
  `};
`;

const QAndAItem = memo(({ answer, question }: IQAndAItemProps) => (
	<QAndAItemWrapper>
		<QAndAItemTitle>{question}</QAndAItemTitle>
		<QAndAItemDesc>{answer}</QAndAItemDesc>
	</QAndAItemWrapper>
));

const QuestionsAndAnswers = memo(() => {
	const { t, withComponents } = useTranslation();

	return (
		<WrapperSection>
			<Container>
				<SmallerTitle>{t("UNIQUE_EXPERIENCES.TITLE_Q_A")}</SmallerTitle>

				<QAMainContent>
					<Col>
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_1")} answer={t("UNIQUE_EXPERIENCES.ANSWER_1")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_2")} answer={t("UNIQUE_EXPERIENCES.ANSWER_2")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_3")} answer={t("UNIQUE_EXPERIENCES.ANSWER_3")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_4")} answer={t("UNIQUE_EXPERIENCES.ANSWER_4")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_5")} answer={t("UNIQUE_EXPERIENCES.ANSWER_5")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_6")} answer={t("UNIQUE_EXPERIENCES.ANSWER_6")} />
						<QAndAItem
							question={t("UNIQUE_EXPERIENCES.QUESTION_7")}
							answer={withComponents(
								"UNIQUE_EXPERIENCES.ANSWER_7",
								{ Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} /> },
								null,
							)}
						/>
					</Col>

					<Col>
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_8")} answer={t("UNIQUE_EXPERIENCES.ANSWER_8")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_9")} answer={t("UNIQUE_EXPERIENCES.ANSWER_9")} />
						<QAndAItem question={t("UNIQUE_EXPERIENCES.QUESTION_10")} answer={t("UNIQUE_EXPERIENCES.ANSWER_10")} />
						<QAndAItem
							question={t("UNIQUE_EXPERIENCES.QUESTION_11")}
							answer={withComponents(
								"UNIQUE_EXPERIENCES.ANSWER_11",
								{ Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} /> },
								null,
							)}
						/>
					</Col>
				</QAMainContent>
			</Container>
		</WrapperSection>
	);
});

export default QuestionsAndAnswers;
