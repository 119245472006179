import { Box, Breadcrumbs as MaterialBreadcrumbs } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import colors from "styles/colors";
import { StyledLink } from "styles/common";
import media from "styles/media";

const BreadcrumbsArrow = styled(ArrowBackIos)`
	width: 1rem;
	height: 1rem;
	font-weight: 500;
	color: ${colors.mainFontColor};
	cursor: pointer;
	opacity: 0.7;
`;

const BreadcrumbsAnchor = styled.span`
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 2.58;
	letter-spacing: 0.052rem;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
	text-decoration: none;
	opacity: 0.7;

	${media.tablet`
    line-height: 1;
  `};
`;

const BreadcrumbsLabel = styled(BreadcrumbsAnchor)`
	opacity: 1;
`;

const CustomStyledLink = styled(StyledLink)`
	opacity: 0.7;
	color: ${colors.mainFontColor};
`;

interface IBreadcrumbsProps {
	list?: {
		label: string;
		route: paths | string;
	}[];
	withoutHomepage?: boolean;
	withoutHoverUnderline?: boolean;
	lastBreadcrumbLabel?: string;
	customFirstBreadcrumb?: {
		label: string;
		route: paths | string;
	};
}

const Breadcrumbs = ({
	list,
	withoutHomepage = false,
	withoutHoverUnderline = false,
	lastBreadcrumbLabel,
	customFirstBreadcrumb,
}: IBreadcrumbsProps) => {
	const { t } = useTranslation();

	return (
		<Box marginTop={5} marginBottom={5}>
			<MaterialBreadcrumbs aria-label="breadcrumbs">
				{!withoutHomepage && (
					<CustomStyledLink to={paths.HOMEPAGE} $withoutHoverUnderline={withoutHoverUnderline}>
						<Box display="flex" alignItems="center">
							<BreadcrumbsArrow />

							<BreadcrumbsAnchor>{t("BREADCRUMBS.BACK_TO_MAIN_BTN")}</BreadcrumbsAnchor>
						</Box>
					</CustomStyledLink>
				)}

				{withoutHomepage && customFirstBreadcrumb && (
					<CustomStyledLink to={customFirstBreadcrumb.route} $withoutHoverUnderline={withoutHoverUnderline}>
						<Box display="flex" alignItems="center">
							<BreadcrumbsArrow />

							<BreadcrumbsAnchor>{customFirstBreadcrumb.label}</BreadcrumbsAnchor>
						</Box>
					</CustomStyledLink>
				)}

				{list?.map(breadcrumb => (
					<CustomStyledLink
						to={breadcrumb.route}
						key={`breadcrumb-${breadcrumb.label}`}
						$withoutHoverUnderline={withoutHoverUnderline}
					>
						<Box display="flex" alignItems="center">
							<BreadcrumbsAnchor>{breadcrumb.label}</BreadcrumbsAnchor>
						</Box>
					</CustomStyledLink>
				))}

				{lastBreadcrumbLabel && <BreadcrumbsLabel>{lastBreadcrumbLabel}</BreadcrumbsLabel>}
			</MaterialBreadcrumbs>
		</Box>
	);
};

export default Breadcrumbs;
