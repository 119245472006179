import { EUserType } from "data/users/types";

import { APISingleData } from "./index";
import { IUnregisteredUserAccessTokenPostBody, IUserAuthByAccessToken } from "./types";
import { IAuthorizationResponse } from "./types/users";

const authorizationNamespace = "auth";

const authorizationServices = {
	getSwitchAccountType: (type: EUserType) =>
		APISingleData.get<IUserAuthByAccessToken>(authorizationNamespace, `/by-user-type/${type}`),
	getUserAuthByAccessToken: (accessToken: string) =>
		APISingleData.get<IUserAuthByAccessToken>(authorizationNamespace, `/by-access-token/${accessToken}`, {
			headers: {
				Authorization: null,
				Authentication: null,
			},
		}),
	postUnregisteredUserAuthAccessToken: (body: IUnregisteredUserAccessTokenPostBody) =>
		APISingleData.post<IAuthorizationResponse>(authorizationNamespace, "/access-token", { body }),
};

export default authorizationServices;
