import { FormikProvider, useFormik } from "formik";
import { memo, useState } from "react";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import Ratings from "components/Ratings";
import Textarea from "components/Ratings/components/Textarea";
import { FormikForm } from "components/Ratings/shared.styled";
import { TRateResult } from "components/Ratings/types";

import ActionButton from "ui/buttons/ActionButton";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";
import Stepper from "ui/Dialog/components/Stepper";

import { icons } from "../../config";
import { isValid } from "../../shared";
import { ESteps, IRateAction } from "../../types";
import { BookingInfo } from "../BookingInfo";

const categories = {
	enjoyment: "RATING.EXPERIENCE.CATEGORY.ENJOYMENT",
	meeting_expectations: "RATING.EXPERIENCE.CATEGORY.MEETING_EXPECTATIONS",
	safety: "RATING.EXPERIENCE.CATEGORY.SAFETY",
};

interface IFormValues {
	comment_private: string | null;
	comment_public: string | null;
}

type TRateResultWithCategories = TRateResult<typeof categories>;

const RateExperience = memo(({ action, bookingData }: IRateAction) => {
	const { t, withComponents } = useTranslation();

	const { addError } = useNotification();

	const {
		yup: { validations, stringMaxLength },
	} = useValidations();

	const [ratingData, setRatingData] = useState<TRateResultWithCategories>();
	const [isAction, setIsAction] = useState<boolean>(false);

	const handleData = (data: TRateResultWithCategories) => {
		setRatingData(data);
	};

	const save = async (formikValues: IFormValues) => {
		if (isValid(ratingData, categories)) {
			setIsAction(true);

			const requestData = {
				user_scores: ratingData,
				comment_private: formikValues.comment_private || null,
				comment_public: formikValues.comment_public || null,
			};

			await action(ESteps.EXPERIENCE, requestData, ESteps.LOCALHOST);

			setIsAction(false);

			return;
		}

		return addError(t("RATING.REQUIRED.RATES"));
	};

	const formikProps = useFormik<IFormValues>({
		enableReinitialize: true,
		initialValues: {
			comment_private: null,
			comment_public: null,
		},
		validationSchema: validations({
			comment_private: stringMaxLength(500).nullable(),
			comment_public: stringMaxLength(500).nullable(),
		}),
		onSubmit: async values => await save(values),
	});

	return (
		<>
			<DialogContentWrapper
				title={
					<>
						{t("RATING.USER.RATE_EXPERIENCE")}

						{bookingData && <BookingInfo bookingData={bookingData} />}
					</>
				}
				center
				underline
			>
				<Ratings icons={icons} categories={categories} update={handleData} />

				<FormikProvider value={formikProps}>
					<FormikForm>
						<Textarea
							name="comment_public"
							placeholder={t("RATING.COMMENT_FORM_LABEL")}
							nodeLabel={withComponents("RATING.EXPERIENCE.PUBLIC_MESSAGE", {
								strong: <strong />,
							})}
							multiline
						/>

						<Textarea
							name="comment_private"
							placeholder={t("RATING.COMMENT_FORM_LABEL")}
							nodeLabel={
								<>
									<strong>{t("RATING.EXPERIENCE.PRIVATE_MESSAGE_ROW1")}</strong>

									<small>{t("RATING.EXPERIENCE.PRIVATE_MESSAGE_ROW2")}</small>
								</>
							}
							multiline
						/>

						<Stepper step={1} steps={2}>
							<ActionButton
								onClick={() => formikProps.handleSubmit()}
								type="button"
								translationDefault="RATING.USER.EXPERIENCE_NEXT_BUTTON_TITLE"
								isAction={isAction}
							/>
						</Stepper>
					</FormikForm>
				</FormikProvider>
			</DialogContentWrapper>
		</>
	);
});

export default RateExperience;
