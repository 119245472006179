import { Box, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const CircularProgressContent = styled(Box)`
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Loader = () => (
	<CircularProgressContent>
		<CircularProgress color="primary" />
	</CircularProgressContent>
);

export default Loader;
