export enum EMFAStatusTypes {
	SMS_MFA = "SMS_MFA",
	SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
	NOMFA = "NOMFA",
}

export enum EMFActivating {
	SMS_MFA = "SMS_MFA",
	SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export enum EMFASettingsTypes {
	SMS = "SMS",
	TOTP = "TOTP",
	NOMFA = "NOMFA",
}

export interface IConfigMfaItem {
	status: EMFAStatusTypes;
	settings: EMFASettingsTypes;
	qrCode?: boolean;
	toDisable?: boolean;
	needConfirmCode?: boolean;
}

export type TMFAConfig = {
	[key: string]: IConfigMfaItem;
};
