import { Box, TextField } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import InfoInput from "ui/InfoInput";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";

interface ISelectPeopleProps {
	values: { nbAdults: number; nbKids: number; [key: string]: number };
	label: string;
	onChange: (value: { [key: string]: number }) => void;
	withoutChildrenAges?: boolean;
}

const Text = styled.span`
	margin-left: ${dimensions.spaces.xsmall};
	font-family: ${RobotoFontFamily};
	font-size: 16px !important;
	color: ${colors.mainFontColor};
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const ItemWrapper = styled.div`
	width: 100%;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const InlineLabel = styled.span<{ isTitle?: boolean }>`
	${props => props.isTitle && "margin-top: 20px;"}
	${props => !props.isTitle && "font-weight: 500;"}
  font-family: ${RobotoFontFamily};
	font-size: 14px;
	letter-spacing: 0.44px;
	color: ${colors.mainFontColor};
`;

const PersonOutlineIcon = styled(PersonOutline)`
	width: 17px;
	height: 17px;
	color: ${colors.hokiColor};
`;

const PopupWrapper = styled.div`
	padding: 10px;
`;

const Input = styled(TextField)<{ fullWidth?: boolean }>`
	width: ${props => (props.fullWidth ? "100%" : "80px")};
	position: relative;

	input {
		padding: 10px;
	}
`;

const Adornment = styled.span`
	position: absolute;
	right: 30px;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	letter-spacing: 0.44px;
	color: ${colors.mainFontColor};
	width: 80px;
`;

const SelectPeople = ({ values, label, onChange, withoutChildrenAges }: ISelectPeopleProps) => {
	const { t } = useTranslation();

	const onChangeInput = (value, prefix) => {
		onChange({ [prefix]: value });
	};

	return (
		<InfoInput
			label={label}
			error={undefined}
			menuStyle={{
				width: "220px",
				height: "auto",
			}}
			borderColor={colors.sunShade}
			icon={<PersonOutlineIcon />}
			overlayContent={
				<PopupWrapper>
					<ItemWrapper>
						<InlineLabel>{t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")}</InlineLabel>

						<Input
							name="adults"
							type="number"
							inputProps={{ min: 0 }}
							value={values.nbAdults}
							variant="outlined"
							onBlur={v => {
								const prefix = "nbAdults";
								const value = parseInt(v.target.value, 10);

								if (isNaN(value) || value < 0) {
									return onChangeInput(0, prefix);
								}
							}}
							onChange={v => {
								const prefix = "nbAdults";
								const value = parseInt(v.target.value, 10);

								if (isNaN(value)) {
									return onChangeInput("", prefix);
								}

								onChangeInput(value, prefix);
							}}
						/>
					</ItemWrapper>

					<ItemWrapper>
						<InlineLabel>{t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}</InlineLabel>

						<Input
							name="kids"
							type="number"
							inputProps={{ min: 0 }}
							value={values.nbKids}
							onBlur={v => {
								const prefix = "nbKids";
								const value = parseInt(v.target.value, 10);

								if (isNaN(value) || value < 0) {
									return onChangeInput(0, prefix);
								}
							}}
							onChange={v => {
								const prefix = "nbKids";
								const value = parseInt(v.target.value, 10);

								if (isNaN(value)) {
									return onChangeInput("", prefix);
								}

								onChangeInput(value, prefix);
							}}
							variant="outlined"
						/>
					</ItemWrapper>

					{!withoutChildrenAges && (
						<>
							{values.nbKids > 0 && (
								<ItemWrapper>
									<InlineLabel isTitle>{t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS_AGES")}</InlineLabel>
								</ItemWrapper>
							)}

							{values.nbKids > 0 && (
								<>
									{Array(values.nbKids)
										.fill(null)
										.map((_, index) => {
											const prefix = `kid-nr-${index}`;
											const max = 18;
											const min = 1;

											return (
												<Box marginBottom="10px" key={prefix}>
													<Input
														name={prefix}
														fullWidth
														type="number"
														variant="outlined"
														inputProps={{ min, max }}
														value={values[prefix]}
														defaultValue={values[prefix] || min}
														onBlur={v => {
															const value = parseInt(v.target.value, 10);

															if (isNaN(value) || value < min) {
																return onChangeInput(min, prefix);
															}
														}}
														onChange={v => {
															const value = parseInt(v.target.value, 10);

															if (isNaN(value)) {
																return onChangeInput("", prefix);
															}

															if (value > max) {
																return onChangeInput(max, prefix);
															}

															onChangeInput(value, prefix);
														}}
														InputProps={{
															endAdornment: <Adornment>{t("SEARCH.EXPERIENCES.MAIN_FILTERS.YEARS_OLD")}</Adornment>,
														}}
													/>
												</Box>
											);
										})}
								</>
							)}
						</>
					)}
				</PopupWrapper>
			}
			text={
				<Text>
					<TextBold>{values.nbAdults}</TextBold>{" "}
					{values.nbAdults === 1
						? t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULT")
						: t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")}
					, <TextBold>{values.nbKids} </TextBold>
					{values.nbKids === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.KID") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}
				</Text>
			}
		/>
	);
};

export default SelectPeople;
