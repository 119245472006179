export enum MY_FINANCES_TABS {
	PAST_EARNINGS = "past-earnings",
	WITHDRAWALS = "withdrawals",
	BANKING_DETAILS = "banking-details",
}

export enum MY_SETTINGS_TABS {
	ACCOUNT = "account",
	SECURITY = "security",
}

export enum BO_SUPERBOOKING_PREVIEW_TABS {
	SUPERBOOKING_INFO = "superbooking-info",
	HOSTING_REQUESTS = "hosting-requests",
}

export enum BO_LOCALHOST_PREVIEW_TABS {
	LOCALHOST_INFO = "localhost-info",
	PAST_EARNINGS = "past-earnings",
	WITHDRAWALS = "withdrawals",
	EXPERIENCES = "experiences",
	RATINGS = "ratings",
	BOOKINGS = "bookings",
	HOSTING_REQUESTS = "hosting-requests",
	INTERVIEW = "interview",
}

export enum paths {
	// BACKOFFICE
	BACKOFFICE = "/backoffice",
	BACKOFFICE_REDIRECT = "/backoffice/r/:id",

	BACKOFFICE_NOTIFICATIONS = "/backoffice/notifications",
	BACKOFFICE_USERS = "/backoffice/users",

	BACKOFFICE_CMS_COUNTRIES_CITIES = "/backoffice/cms/countries-cities",
	BACKOFFICE_CMS_LOCALHOSTS = "/backoffice/cms/localhosts",
	BACKOFFICE_CMS_EXPERIENCES = "/backoffice/cms/experiences",

	BACKOFFICE_PROMO_CODES = "/backoffice/promo-codes",
	BACKOFFICE_PROMO_CODES_CREATE = "/backoffice/promo-codes/create",
	BACKOFFICE_PROMO_CODES_EDIT = "/backoffice/promo-codes/edit/:code",
	BACKOFFICE_PROMO_CODES_USAGE = "/backoffice/promo-codes/usage",

	BACKOFFICE_FINANCIALS = "/backoffice/financials",
	BACKOFFICE_FINANCIALS_LOCALHOST_EARNINGS = "/backoffice/financials/localhost-earnings",
	BACKOFFICE_FINANCIALS_COMMISSION_SUMMARY = "/backoffice/financials/commission-summary",

	BACKOFFICE_EXPERIENCE_LISTED = "/backoffice/experiences/listed",
	BACKOFFICE_EXPERIENCE_NOT_LISTED = "/backoffice/experiences/not-listed",
	BACKOFFICE_EXPERIENCE_REVIEW = "/backoffice/experiences/review/:id",
	BACKOFFICE_EXPERIENCE_PREVIEW = "/backoffice/experiences/preview/:id",
	BACKOFFICE_EXPERIENCE_DRAFTS_PREVIEW = "/backoffice/experiences-drafts/preview/:id",
	BACKOFFICE_EXPERIENCE_PROMOTING = "/backoffice/experiences/promoting",

	BACKOFFICE_PARTNERS_LIST = "/backoffice/partners/list",
	BACKOFFICE_PARTNERS_CREATE = "/backoffice/partners/create",
	BACKOFFICE_PARTNERS_DEFAULT = "/backoffice/partners/default",
	BACKOFFICE_PARTNERS_EDIT = "/backoffice/partners/edit/:id",
	BACKOFFICE_PARTNERS_PREVIEW = "/backoffice/partners/preview/:id",

	BACKOFFICE_SUPERBOOKINGS_LIST = "/backoffice/superbookings/list",
	BACKOFFICE_SUPERBOOKINGS_QA = "/backoffice/superbookings/qa",
	BACKOFFICE_SUPERBOOKINGS_HOSTING_REQUESTS = "/backoffice/superbookings/hosting-requests",
	BACKOFFICE_BOOKINGS_LIST = "/backoffice/bookings/list",
	BACKOFFICE_BOOKING_ADD_NEW = "/backoffice/bookings/add-new-booking",
	BACKOFFICE_BOOKING_PREVIEW = "/backoffice/bookings/preview/:id",

	BACKOFFICE_SUPERBOOKING_PREVIEW = "/backoffice/bookings/preview/superbooking/:id/:page?",

	BACKOFFICE_LOCALHOSTS_LISTED = "/backoffice/localhosts/listed",
	BACKOFFICE_LOCALHOSTS_NOT_LISTED = "/backoffice/localhosts/not-listed",
	BACKOFFICE_LOCALHOSTS_REVIEW = "/backoffice/localhosts/review/:id",
	BACKOFFICE_LOCALHOSTS_INTERVIEW = "/backoffice/localhosts/interview/:id",
	BACKOFFICE_LOCALHOSTS_PREVIEW = "/backoffice/localhosts/preview/:id/:page?",

	BACKOFFICE_CLASSIFICATIONS_LIST = "/backoffice/classifications/list",
	BACKOFFICE_CLASSIFICATIONS_CREATE = "/backoffice/classifications/create",
	BACKOFFICE_CLASSIFICATIONS_EDIT = "/backoffice/classifications/edit/:id/:step?",
	BACKOFFICE_CLASSIFICATIONS_PREVIEW = "/backoffice/classifications/preview/:id",

	BACKOFFICE_BINIPOOL_CREATE = "/backoffice/binipool/create/:classification_id?",
	BACKOFFICE_BINIPOOL_EDIT = "/backoffice/binipool/edit/:id/:step?",
	BACKOFFICE_BINIPOOL_HOSTS_LIST = "/backoffice/binipool/locals-assigned/:id",

	// PROTECTED
	DASHBOARD = "/dashboard",
	EXPERIENCES = "/my-experiences",
	EXPERIENCE_CREATE_STEP = "/experience/create/:id?/:step?",
	CONGRATULATIONS = "/congratulations/:id?",
	UPDATE_EXPERIENCE_PAGE = "/experience/update/:id",
	PROFILE = "/my-profile",
	ONBOARDING = "/onboarding/step-:step",
	ONBOARDING_ONLY_REDIRECT = "/onboarding",
	SHOW_BOOKING_RATING = "/my-bookings/rating/:bid",
	CONFIRM_BOOKING = "/my-bookings/confirm/:bid/:heldCode",
	MY_BOOKINGS = "/my-bookings",
	MY_SETTINGS = "/settings/:page?",
	MY_FINANCES = "/my-finances/:page?",
	MY_REFERRALS = "/my-referrals",
	NOTIFICATIONS = "/notifications",
	PAYMENT = "/payment/:bid",

	// PARTNERS
	PARTNER_BOOKINGS = "/bookings",
	PARTNER_USERS = "/users",

	// FOR STAFF OUTSIDE BACKOFFICE
	STAFF_EDIT_LOCALHOST_PROFILE = "/localhost-profile/edit/:uid",

	// AUTH
	CONFIRM_EMAIL = "/confirm-email",
	LOGIN = "/login",
	REGISTER = "/register",
	FORGOT_PASSWORD = "/forgot-password",
	RESET_PASSWORD = "/reset-password",
	ONBOARDING_REGISTER = "/onboarding/register",
	ONBOARDING_CONFIRM_EMAIL = "/onboarding/confirm-email",
	ONBOARDING_LOGIN = "/onboarding/login",

	//
	// PUBLIC
	//

	// SEARCH START
	SEARCH = "/experiences",
	SEARCH_COUNTRY = "/experiences/country-:country",
	SEARCH_CITY = "/experiences/city-:city",
	SEARCH_CATEGORY = "/experiences/category-:category",
	SEARCH_COUNTRY_CITY = "/experiences/country-:country/city-:city",
	SEARCH_COUNTRY_CATEGORY = "/experiences/country-:country/category-:category",
	SEARCH_CITY_CATEGORY = "/experiences/city-:city/category-:category",
	SEARCH_COUNTRY_CITY_CATEGORY = "/experiences/country-:country/city-:city/category-:category",
	// SEARCH END

	SEARCH_EXPERIENCES = "/experience/:title?/:id",
	UNIQUE_EXPERIENCES = "/unique-experiences",
	BINIPOOL_CONCEPT = "/binipool-concept",
	CONTACT = "/help/contact-us",
	HELP_QUALITY_CONTROL = "/help/quality-control",
	HELP_CANCELLATION_POLICY = "/help/cancellation-policy",
	HELP_ABOUT_US = "/help/about-us",
	HELP_DISTRIBUTION_PARTNERS = "/help/distribution-partners",
	HELP_INVESTORS = "/help/investors",
	HELP_KEY_PARTNERS = "/help/key-partners",
	HELP_PRESS = "/help/press",
	HELP_DISCLAIMER_IMPRESSUM = "/help/disclaimer-impressum",
	HELP_PRIVACY_POLICY = "/help/privacy-policy",
	HELP_TERMS_SERVICE = "/help/terms-of-service",
	HELP_LEGAL_TERMS = "/help/legal-terms",
	HELP_FAQ = "/help/faq",
	HELP_COOKIES = "/help/cookies",
	PUBLIC_PROFILE = "/localhost-profile/:userName/:uid",
	PARTNER_REDIRECT = "/partner/:subdomain",
	GUEST_PAYMENT = "/p/:bid",

	// START public with token param in URL (as URL part or query param), protected without token param
	GUEST_BOOKING_PAGE = "/b/:booking_id/:token?",
	RATINGS = "/rate/:booking_id/:step?",
	// END

	// OTHER
	HOMEPAGE = "/",
	NOT_FOUND = "/404",
	REDIRECT = "/r/:id/:type?",
	HEALTH_CHECK = "/health",
}
