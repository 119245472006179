import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft, LocationOn, LocationOnOutlined, NearMeOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import parse from "autosuggest-highlight/parse";
import { shuffle } from "lodash-es";
import { ReactNode, useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import { useGeolocated } from "react-geolocated";
import styled from "styled-components";

import { searchKeys } from "data/dictionaries/searchKeys";
import { CustomOptionType, PlaceType } from "data/types";

import useNotification from "hooks/useNotification";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";

const StyledNearMeOutlinedIcon = styled(NearMeOutlined)`
	color: ${colors.hokiColor};
	width: 18px;
	height: 14px;
`;

const StyledLocationOnOutlinedIcon = styled(LocationOnOutlined)`
	margin-right: 5px;
	color: ${colors.hokiColor};
	height: 22px;
	width: 18px;
`;

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	textField: {
		borderColor: colors.sunShade,
	},
}));

const TextFieldWithCustomLabel = styled(TextField)<{ $heightInput: string }>`
	input {
		height: ${props => props.$heightInput};
	}

	label {
		font-family: ${RobotoFontFamily};
		font-size: 13px;
		color: ${colors.bermudaGray};
		margin-bottom: 5px;
	}
`;

const CustomOptionWrapper = styled.div`
	width: 100%;
	border-bottom: 1px solid ${colors.borderGray};
	padding: ${dimensions.spaces["5"]};
`;

export interface PlacesAutocompleteProps {
	className?: string;
	customOptions?: PlaceType[];
	onChange?: (val: string) => void;
	onClick?: (val: string) => void;
	label: ReactNode;
	selectedValue: string;
	heightInput?: string;
	onKeyPressAction?: (v) => void;
}

const OPTIONS_LIMIT = 200;
const shuffledOptions = shuffle(searchKeys);

const PlacesAutocomplete = ({
	className,
	customOptions = [],
	onChange,
	onClick,
	label,
	selectedValue,
	heightInput = "auto",
	onKeyPressAction,
}: PlacesAutocompleteProps) => {
	const classes = useStyles();

	const notifications = useNotification();

	const { isGeolocationEnabled, coords } = useGeolocated();

	const [inputValue, setInputValue] = useState<string>(selectedValue);

	useEffect(() => {
		if (!!selectedValue) {
			setInputValue(selectedValue);
		}
	}, [selectedValue]);

	const options = useMemo(
		() => [
			...customOptions,
			...shuffledOptions.map(elem => ({ label: elem, value: elem, type: CustomOptionType.RECENT_SEARCH })),
		],
		[customOptions],
	);

	return (
		<Autocomplete
			className={className}
			id="places-autocomplete"
			fullWidth
			autoHighlight
			freeSolo
			options={options}
			inputValue={inputValue}
			getOptionLabel={option => option.label}
			forcePopupIcon
			popupIcon={
				<ChevronLeft
					style={{
						transform: "rotate(-90deg)",
					}}
				/>
			}
			filterOptions={(currentOptions, state) =>
				currentOptions
					.filter((v, index) => v.label?.toLowerCase().includes(state.inputValue?.toLowerCase() || ""))
					.slice(0, OPTIONS_LIMIT)
			}
			onChange={(event: any, newValue: any | null) => {
				if (newValue?.type === CustomOptionType.CURRENT_LOCATION) {
					return;
				}

				setInputValue(newValue?.label || "");
				onChange?.(newValue?.label || "");
			}}
			onInputChange={(event, newInputValue) => {
				if (newInputValue === "Nearby") {
					return;
				}

				setInputValue(newInputValue || "");
				onChange?.(newInputValue || "");
			}}
			renderInput={params => (
				<TextFieldWithCustomLabel
					{...params}
					label={label}
					fullWidth
					$heightInput={heightInput}
					onKeyDown={onKeyPressAction && (v => onKeyPressAction(v))}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<StyledLocationOnOutlinedIcon />
							</InputAdornment>
						),
					}}
				/>
			)}
			renderOption={(option: any) => {
				if (!option.hasOwnProperty("structured_formatting")) {
					return (
						<CustomOptionWrapper
							onClick={async () => {
								notifications.clear();

								if (option.type !== CustomOptionType.CURRENT_LOCATION) {
									return undefined;
								}

								if (!isGeolocationEnabled || !coords || !coords?.latitude || !coords?.longitude) {
									notifications.addError("Geolocation unavailable, please check your settings.");

									return onChange?.("");
								}

								// geolocation
								try {
									const resp = await Geocode.fromLatLng(coords?.latitude, coords?.longitude);
									const address = resp.results[0].formatted_address;

									onChange?.(address);
								} catch {
									notifications.addError("Geolocation unavailable, please check your settings.");
								}
							}}
						>
							{option.type === CustomOptionType.CURRENT_LOCATION ? (
								<StyledNearMeOutlinedIcon />
							) : (
								<StyledLocationOnOutlinedIcon />
							)}{" "}
							{option.label}
						</CustomOptionWrapper>
					);
				}

				const matches = option?.structured_formatting?.main_text_matched_substrings;
				const parts = parse(
					option.structured_formatting?.main_text,
					matches ? matches.map((match: any) => [match.offset, match.offset + match.length]) : [],
				);

				return (
					<Grid container alignItems="center" onClick={() => onClick?.(option.structured_formatting?.secondary_text)}>
						<Grid item>
							<LocationOn className={classes.icon} />
						</Grid>

						<Grid item xs>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}

							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting?.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
};

export default PlacesAutocomplete;
