import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

export const Header = styled.h1`
	font-size: 24px;
	font-weight: 600;
	font-family: ${MontserratFontFamily};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	color: ${colors.mainFontColor};
	margin: 0 0 35px;
`;

export const Wrapper = styled.div`
	margin: 2.5rem 0;
	font-family: ${MontserratFontFamily};
`;

export const WelcomeContent = styled.div`
	margin: 60px 0 50px;
`;
