import { CssBaseline, LinearProgress } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import useErrors from "hooks/useErrors";

import AppService from "services/app";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import Error404Page from "pages/errors/Error404Page";

import { GoogleTagManagerComponent } from "components/analytics/GoogleTagManager";

import Development from "ui/Development";
import NotificationProvider from "ui/Notification/components/NotificationProvider";

import { jss, theme } from "styles/theme";

import { anonymousRoutes, protectedRoutes, routes } from "../routes";
import { RouteRCTypeProp } from "../types";
import useHandleSubdomain from "../useHandleSubdomain";
import { ErrorsProvider } from "./ErrorsProvider";
import HistoryListener from "./HistoryListener";
import LanguageChecker from "./LanguageChecker";
import { NotificationsClassProvider } from "./NotificationsClassProvider";
import RouteResolver from "./RouteResolver";
import ScrollToTop from "./ScrollToTop";

const RouteWrapper = () => {
	const { silent } = useErrors();

	const handleSubdomain = useHandleSubdomain();

	const userData = useAppSelector(selectUser);

	const [appInitialized, setAppInitialized] = useState<boolean>(false);

	useEffect(() => {
		handleSubdomain.handle();

		(async () => {
			try {
				await AppService.init();

				setAppInitialized(true);

				AppService.lessPriorityActions();
			} catch (e) {
				silent(e);
			}
		})();

		// eslint-disable-next-line
	}, []);

	return (
		<StylesProvider jss={jss}>
			<ThemeProvider theme={theme}>
				<StyledThemeProvider theme={theme}>
					<Router>
						<CssBaseline />
						<ScrollToTop />
						<HistoryListener />
						<LanguageChecker />
						<GoogleTagManagerComponent />

						<NotificationProvider>
							<ErrorsProvider />
							<NotificationsClassProvider />

							{appInitialized ? (
								<>
									<Switch>
										{protectedRoutes.map(({ component, ...route }) => (
											<RouteResolver
												key={`protected-routes-${route.path}`}
												Component={component as RouteRCTypeProp}
												userData={userData}
												isProtected
												{...route}
											/>
										))}

										{routes.map(({ component, ...route }) => (
											<RouteResolver key={`routes-${route.path}`} Component={component as RouteRCTypeProp} {...route} />
										))}

										{anonymousRoutes.map(({ component, ...route }) => (
											<RouteResolver
												key={`anonymous-routes-${route.path}`}
												Component={component as RouteRCTypeProp}
												userData={userData}
												isAnonymous
												{...route}
											/>
										))}

										<Route path="*" component={Error404Page} />
									</Switch>

									<Development />
								</>
							) : (
								<LinearProgress />
							)}
						</NotificationProvider>
					</Router>
				</StyledThemeProvider>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default RouteWrapper;
