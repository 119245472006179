import qs from "query-string";
import { useLocation } from "react-router-dom";

import MigrationResetPasswordImg from "assets/images/MigrationResetPasswordImg.jpg";
import RegisterSocialImg from "assets/images/RegisterSocialImg.jpg";

import ResetPasswordView from "components/auth/ResetPasswordView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

const ResetPasswordPage = () => {
	const location = useLocation();

	const { migration } = qs.parse(location.search);

	return (
		<AppWithImageWrapper image={!migration ? RegisterSocialImg : MigrationResetPasswordImg}>
			<ResetPasswordView />
		</AppWithImageWrapper>
	);
};

export default ResetPasswordPage;
