import { Box } from "@material-ui/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { getPreferredMFA } from "services/aws/auth";

import ActionButton from "ui/buttons/ActionButton";
import CircularLoader from "ui/loaders/CircularLoader";
import HoverTooltip from "ui/tooltips/HoverTooltip";

import CodeInput from "../../formik/CodeInput";
import { EMFASettingsTypes, EMFAStatusTypes, IConfigMfaItem, TMFAConfig } from "./types";
import useValidate from "./validate";

const SmsInfo = styled.div`
	font-size: 12px;

	a {
		color: inherit;
	}
`;

const Form = styled(Formik)`
	display: flex;
	flex-direction: column;
	margin: 15px 0;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const SmsLink = styled.span`
	font-weight: 600;
	text-decoration: underline;
	cursor: pointer;
`;

const MFATypes: TMFAConfig = {
	SMS: {
		status: EMFAStatusTypes.SMS_MFA,
		settings: EMFASettingsTypes.SMS,
	},
	TOTP: {
		status: EMFAStatusTypes.SOFTWARE_TOKEN_MFA,
		settings: EMFASettingsTypes.TOTP,
		qrCode: true,
		needConfirmCode: true,
	},
	NOMFA: {
		status: EMFAStatusTypes.NOMFA,
		settings: EMFASettingsTypes.NOMFA,
		toDisable: true,
	},
};

interface ISetMFA {
	type: EMFASettingsTypes;
	back: () => void;
	success: () => void;
}

const SetMFA = ({ type, back, success }: ISetMFA) => {
	const { t, withComponents, withRaw } = useTranslation();

	const { addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();

	const [code, setCode] = useState<string>("");
	const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
	const [isSetupPrepared, setIsSetupPrepared] = useState<boolean>(false);
	const [isEnabled, setIsEnabled] = useState<boolean>(false);
	const [needSetup, setNeedSetup] = useState<boolean>(false);
	const [localType, setLocalType] = useState<EMFASettingsTypes>(type);

	const isLoading = !needSetup || !isSetupPrepared;
	const mfaType: IConfigMfaItem = MFATypes?.[localType];
	const isValidType = !!mfaType;
	const isEnableAction = !mfaType?.toDisable;

	const reset = () => {
		setCognitoUser(undefined);
		setNeedSetup(false);
		setIsSetupPrepared(false);
		setIsEnabled(false);
	};

	useEffect(() => {
		(async () => {
			if (needSetup && !!cognitoUser) {
				await generateTokenCode();
			}
		})();
		// eslint-disable-next-line
	}, [needSetup, cognitoUser]);

	useEffect(() => {
		(async () => {
			if (!isValidType) {
				return;
			}

			try {
				const user = await Auth.currentAuthenticatedUser();

				const isCurrentSet = await checkIsCurrentSetMFA(user, mfaType.status);

				if (!isEnableAction || !mfaType?.needConfirmCode) {
					if (!isCurrentSet) {
						trySetPreferredMfa(user, mfaType.settings);

						return;
					}

					backWithError("AUTH.MFA.ALREADY.DISABLED");

					return;
				} else {
					if (isEnableAction && isCurrentSet) {
						backWithError("AUTH.MFA.ALREADY.ENABLED");

						return;
					}

					setNeedSetup(true);
				}

				setIsEnabled(isCurrentSet);
				setCognitoUser(user);
			} catch (error) {
				handleAndNotify(error);
			}
		})();
		// eslint-disable-next-line
	}, [localType]);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: useValidate(),
		initialValues: {
			code: "",
		},
		onSubmit: values => {
			validSetMethod(mfaType.settings, values.code);
		},
	});

	const checkIsCurrentSetMFA = async (user: CognitoUser, enabledType: EMFAStatusTypes) => {
		try {
			const preferred = await getPreferredMFA(user);

			return preferred === enabledType;
		} catch (error) {
			backWithError(error, false);
			return false;
		}
	};

	const backWithError = (error?: unknown, translate = true) => {
		handleAndNotify(translate && typeof error === "string" ? t(error) : error);
		back();
	};

	const backWithSuccess = (translationKey: string) => {
		addSuccess(translationKey, true);
		back();
	};

	const trySetPreferredMfa = (user: CognitoUser, method: EMFASettingsTypes) => {
		Auth.setPreferredMFA(user, method)
			.then(() => {
				success();

				backWithSuccess(isEnableAction ? "AUTH.MFA.ENABLED" : "AUTH.MFA.DISABLED");
			})
			.catch(error => {
				const { message } = error;

				if (
					message === "User has not set up software token mfa" ||
					message === "User has not verified software token mfa"
				) {
					setNeedSetup(true);
				} else {
					backWithError(message, false);
				}
			});
	};

	const generateTokenCode = async () => {
		if (!cognitoUser) {
			return;
		}

		try {
			await Auth.setupTOTP(cognitoUser).then(secretCode => {
				const issuer = window.location.hostname || "LocalBini.com";

				cognitoUser.getUserAttributes((err, result) => {
					const email = result?.find(r => r.getName() === "email")?.getValue();
					if (!!err || !email?.length) {
						backWithError(err?.message, false);
						return;
					}

					const localCode = "otpauth://totp/" + issuer + ":" + email + "?secret=" + secretCode + "&issuer=" + issuer;
					setCode(localCode);
				});
			});

			setIsSetupPrepared(true);
		} catch (e) {
			backWithError(e, false);
		}
	};

	const validSetMethod = (method: EMFASettingsTypes, userCode: string) => {
		if (!cognitoUser) {
			return;
		}

		if (needSetup) {
			Auth.verifyTotpToken(cognitoUser, userCode)
				.then(() => {
					trySetPreferredMfa(cognitoUser, method);
				})
				.catch(e => {
					formikProps.resetForm();
					handleAndNotify(e);
				});

			return;
		}

		trySetPreferredMfa(cognitoUser, method);
	};

	if (!isValidType) {
		backWithError("NOTIFICATION.INVALID_PARAMETERS");
		return null;
	}

	return isLoading ? (
		<CircularLoader />
	) : (
		<>
			{!isEnabled && (
				<div>
					{mfaType?.qrCode && (
						<>
							<Box mb="30px">
								<p>
									<strong>
										{withRaw("AUTH.MFA.STEP1", null)}&nbsp;
										<HoverTooltip translation="AUTH.MFA.STEP2_SUB" />
									</strong>
									<br />
									<small>{withRaw("AUTH.MFA.STEP2", null)}</small>
								</p>
							</Box>

							<QRCode value={code} />

							<Box mt="30px">
								<p>
									<strong>{t("AUTH.MFA.STEP3")}</strong>
								</p>
							</Box>
						</>
					)}
				</div>
			)}

			<FormikProvider value={formikProps}>
				<Form onSubmit={formikProps.handleSubmit}>
					<CodeInput fields={6} name="code" pattern="[0-9]+" inputMode="numeric" autoFocus />

					<ActionButton
						translationDefault={isEnabled ? `AUTH.MFA.DISABLE` : `AUTH.MFA.ENABLE`}
						isAction={formikProps.isSubmitting}
					/>

					<SmsInfo>
						{withComponents("AUTH.MFA.SMS_ADDITIONAL_OPTION", {
							Link: (
								<SmsLink
									onClick={() => {
										reset();
										setLocalType(EMFASettingsTypes.SMS);
									}}
								/>
							),
						})}
					</SmsInfo>
				</Form>
			</FormikProvider>
		</>
	);
};

export default SetMFA;
