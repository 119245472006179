import { Box, Button } from "@material-ui/core";
import { addMinutes, format, isValid, parse } from "date-fns";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTraveller } from "store/selectors/experienceTraveller";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import { getParams } from "utils/urls";

import { BookingFormValues } from "components/experiences/Traveller/components/BookingForm/types";

import colors from "styles/colors";
import { Container, RobotoFontFamily } from "styles/common";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 160px;
	padding-top: 10px;
	background: ${colors.white};
	border-radius: 4px 4px 0 0;
	box-shadow: 0 0 40px 0 #cecece;
	z-index: 10;
`;

const Title = styled.strong`
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 500;
	color: ${colors.mainFontColor};
`;

const StyledButton = styled(Button)`
	width: 100px;
	height: 48px;
`;

const PriceLabel = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 26px;
	line-height: 1;
	color: ${colors.mainFontColor};
	font-weight: bold;
`;

const TotalPriceLabel = styled.span`
	margin-left: 6px;
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	line-height: 1;
	color: ${colors.mainFontColor};
`;

const Text = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	color: ${colors.mainFontColor};
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

interface IDetailsBoxProps {
	date: string[];
	hour: string[];
	adults: number;
	kids: number;
	pricing: BookingFormValues["pricing"];
	buttonKeyTranslation?: string;
	isDisabled?: boolean;
	customNext?: () => void;
	hideButton?: boolean;
}

const DetailsBox = ({
	date,
	hour,
	adults,
	kids,
	pricing,
	buttonKeyTranslation = "BOOKING.SUMMARY.NEXT_STEP",
	isDisabled = false,
	customNext,
	hideButton,
}: IDetailsBoxProps) => {
	const { t } = useTranslation();

	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();

	const initialQueryParams: { step?: string } = getParams(new URLSearchParams(location.search));
	const currentStep = initialQueryParams?.step ? parseInt(initialQueryParams?.step, 10) : 0;

	const experience = useAppSelector(selectExperienceTraveller);

	const tempEndTime = addMinutes(
		parse(hour?.[0], DATE_FORMATS.TIME_FORMAT, new Date()),
		experience?.data?.duration_minutes,
	);

	let endTime = "";

	if (isValid(tempEndTime)) {
		endTime = format(tempEndTime, DATE_FORMATS.TIME_FORMAT);
	}

	const payAction = () => {
		if (customNext) {
			customNext();

			return;
		}

		history.push(`${match.url}?step=${currentStep + 1}`);
	};

	return (
		<Wrapper>
			<Container>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<Title>{experience?.data?.title}</Title>

						<Box marginTop="5px" marginBottom="5px">
							<Text>
								{parseISOWrapper(date?.[0], DATE_FORMATS.DAY_MONTH_SHORT_YEAR_SHORT_FORMAT)} {hour?.[0]} - {endTime} |{" "}
							</Text>

							<Text>
								<TextBold>{adults}</TextBold>{" "}
								{adults === 1
									? t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULT")
									: t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")}
								, <TextBold>{kids}</TextBold>{" "}
								{kids === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.KID") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}
							</Text>
						</Box>

						<Box display="flex" alignItems="flex-end">
							<PriceLabel>{prices(pricing.final_gross_price, pricing.currency, undefined, undefined, true)}</PriceLabel>

							<TotalPriceLabel>{t("BOOKING_INFO.TOTAL_PRICE")}</TotalPriceLabel>
						</Box>
					</Box>

					{!hideButton && (
						<Box display="flex" justifyContent="flex-end" height="85px">
							<StyledButton disabled={isDisabled} color="primary" variant="contained" onClick={payAction}>
								{t(buttonKeyTranslation)}
							</StyledButton>
						</Box>
					)}
				</Box>
			</Container>
		</Wrapper>
	);
};

export default DetailsBox;
