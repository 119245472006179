import GoogleMapReact, { Coords } from "google-map-react";
import { memo, useState } from "react";

import EndMarker from "assets/icons/avatar-location-end-station.svg";
import StartMarker from "assets/icons/avatar-location-start-station.svg";
import SearchMarker from "assets/icons/search-marker.svg";

import useViewPortDimension from "hooks/useViewPortDimension";

import InfoWindow from "./components/InfoWindow";
import { grayStyle } from "./config";

export enum MarkerStyle {
	search = "search",
	end = "end",
	start = "start",
}

export interface MarkerParams extends Coords {
	markerStyle?: MarkerStyle;
	customIconPath?: string;
	data?: any;
	infoWindowNode?: React.ReactNode;
}

export interface MapProps {
	center?: Coords;
	dynamicCenter?: Coords;
	markers?: MarkerParams[];
	zoom?: number;
	onClick?: any;
	fixedHeight?: number | undefined;
	getLocation?: any;
	renderInfoWindow?: (data: any) => React.ReactNode;
}

const Marker = memo(({ markerStyle, customIconPath, infoWindowNode }: MarkerParams) => {
	const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);

	let pathToIcon;
	let transform = "";

	switch (markerStyle) {
		case MarkerStyle.search:
			pathToIcon = SearchMarker;
			transform = "translate(-50%, -50%)";
			break;
		case MarkerStyle.end:
			pathToIcon = EndMarker;
			transform = "translate(-50%, -100%)";
			break;
		case MarkerStyle.start:
			pathToIcon = StartMarker;
			transform = "translate(-50%, -100%)";
			break;
		default:
			pathToIcon = "";
	}

	if (customIconPath) {
		pathToIcon = customIconPath;
	}

	return (
		<>
			<img
				onMouseLeave={() => infoWindowNode && setShowInfoWindow(false)}
				onMouseOver={() => infoWindowNode && setShowInfoWindow(true)}
				src={pathToIcon}
				alt="marker"
				style={{ transform }}
			/>
			{infoWindowNode && showInfoWindow && <InfoWindow>{infoWindowNode}</InfoWindow>}
		</>
	);
});

const defaultCenter = {
	lat: 46.8,
	lng: 7.1,
};

const Map = ({
	center = defaultCenter,
	dynamicCenter,
	fixedHeight,
	zoom = 1,
	markers,
	renderInfoWindow,
	getLocation,
}: MapProps) => {
	const { height: windowHeight } = useViewPortDimension();

	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY as string;

	return (
		<div style={{ height: fixedHeight || windowHeight, width: "100%" }}>
			<GoogleMapReact
				onClick={getLocation}
				bootstrapURLKeys={{ key: apiKey }}
				options={{
					styles: grayStyle,
				}}
				defaultCenter={center}
				center={dynamicCenter}
				defaultZoom={zoom}
			>
				{markers?.map(
					({ lat, lng, markerStyle = MarkerStyle.search, customIconPath = "", infoWindowNode, ...rest }) => (
						<Marker
							key={`${lat}-${lng}-${markerStyle}`}
							lat={lat}
							lng={lng}
							markerStyle={markerStyle}
							customIconPath={customIconPath}
							data={rest}
							infoWindowNode={renderInfoWindow && renderInfoWindow(infoWindowNode)}
						/>
					),
				)}
			</GoogleMapReact>
		</div>
	);
};

export default Map;
