import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBookingIId, ISuperbookingIId } from "data/backoffice/bookings/types";

import bookingsService from "services/api/bookings";

export interface IBookingsBackofficeState {
	bookings: {
		data: IBookingIId[] | null;
		count: number | null;
		status: "idle" | "loading" | "succeeded" | "failed";
		error: any | null;
	};
	superBookings: {
		data: ISuperbookingIId[] | null;
		count: number | null;
		status: "idle" | "loading" | "succeeded" | "failed";
		error: any | null;
	};
}

const initialState: IBookingsBackofficeState = {
	bookings: {
		data: null,
		count: null,
		status: "idle",
		error: null,
	},
	superBookings: {
		data: null,
		count: null,
		status: "idle",
		error: null,
	},
};

export const fetchBookingsBackoffice = createAsyncThunk<any, any>(
	"bookingsBackoffice/fetch",
	async (queryParams = {}, thunkAPI) => {
		try {
			const bookingsList = await bookingsService.fetchBookings(queryParams);

			return bookingsList;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

export const fetchSuperBookingsBackoffice = createAsyncThunk<any, any>(
	"superBookingsBackoffice/fetch",
	async (queryParams = {}, thunkAPI) => {
		try {
			const superBookingsList = await bookingsService.fetchSuperBookingsBackoffice(queryParams);

			return superBookingsList;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const { actions, reducer } = createSlice({
	name: "bookingsBackoffice",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: {
		[fetchBookingsBackoffice.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.bookings.data = action.payload.data;
			state.bookings.count = action.payload.count;
			state.bookings.status = "succeeded";
			state.bookings.error = null;
		},
		[fetchBookingsBackoffice.pending.toString()]: state => {
			state.bookings.status = "loading";
			state.bookings.error = null;
		},
		[fetchBookingsBackoffice.rejected.toString()]: state => {
			state.bookings.status = "failed";
			state.bookings.error = true;
		},
		[fetchSuperBookingsBackoffice.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.superBookings.data = action.payload.data;
			state.superBookings.count = action.payload.count;
			state.superBookings.status = "succeeded";
			state.superBookings.error = null;
		},
		[fetchSuperBookingsBackoffice.pending.toString()]: state => {
			state.superBookings.status = "loading";
			state.superBookings.error = null;
		},
		[fetchSuperBookingsBackoffice.rejected.toString()]: state => {
			state.superBookings.status = "failed";
			state.superBookings.error = true;
		},
	},
});

export const bookingsBackofficeActions = actions;

export default reducer;
