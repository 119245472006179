import { Box } from "@material-ui/core";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";

import { EPromoCodeType } from "data/promo-codes/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import pricingService from "services/api/pricing";
import promoCodesService from "services/api/promoCodes";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import { prices } from "utils/prices";

import CheckboxField from "components/formik/CheckboxField";
import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";

import colors from "styles/colors";
import media from "styles/media";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	${media.tablet`
    flex-direction: column;
      align-items: flex-start;
      gap: 20px;
  `};
`;

const StyledInput = styled(Input)`
	display: inline-block;
	flex: none;
	margin-right: 15px;
	margin-left: 30px;

	${media.tablet`
     margin-left: 0;
  `};

	.MuiTextField-root {
		margin-bottom: 0;
	}
`;

const StyledMessageContainer = styled(Box)`
	margin-left: 30px;

	${media.tablet`
     margin-left: 0;
  `};
`;

const StyledCheckbox = styled(CheckboxField)`
	display: inline-block;
`;

const Promocode = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const { addError } = useNotification();

	const {
		yup: { validations, stringMinLength },
	} = useValidations();

	const dispatch = useAppDispatch();

	const bookingData = useAppSelector(selectExperienceTravellerBooking);
	const userData = useAppSelector(selectUser);

	const [isBusyPromoCode, setIsBusyPromoCode] = useState<boolean>(false);
	const [isBusyGiftCard, setIsBusyGiftCard] = useState<boolean>(false);

	const [isAppliedPromoCode, setIsAppliedPromoCode] = useState<boolean>(false);
	const [isAppliedGiftCard, setIsAppliedGiftCard] = useState<boolean>(false);

	const [messagePromoCode, setMessagePromoCode] = useState<string | null>();
	const [messageGiftCard, setMessageGiftCard] = useState<string | null>();

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: validations({
			promoCode: stringMinLength(2),
			giftCard: stringMinLength(2),
		}),
		initialValues: {
			isActivePromoCode: !!bookingData.promoCode,
			promoCode: bookingData.promoCode || "",
			isActiveGiftCard: !!bookingData.giftCard,
			giftCard: bookingData.giftCard || "",
		},
		onSubmit: async formValues => {
			if (!formValues.promoCode && !formValues.giftCard) {
				return;
			}

			if (!!formValues.promoCode) {
				setIsBusyPromoCode(true);
			}

			if (!!formValues.giftCard) {
				setIsBusyGiftCard(true);
			}

			try {
				let affiliateCode = false;

				if (!!formValues.promoCode) {
					try {
						const codeValidation = await promoCodesService.postValidate({ code: formValues.promoCode });

						if (codeValidation.type === EPromoCodeType.AFFILIATE_CODE) {
							affiliateCode = true;
						}
					} catch (error) {
						// @ts-ignore
						if (error?.getStatus() !== 403) {
							handleAndNotify(error);

							return;
						}
					}
				}

				const newPrice = await pricingService.postGeneratePriceSummary({
					...bookingData.postGeneratePriceSummaryPayload,
					date_time: `${bookingData.date[0]}T${bookingData.hour[0]}:00`,
					private: bookingData.isPrivate,
					...(!!formValues.promoCode &&
						!affiliateCode && {
							promo_code: formValues.promoCode,
						}),
					...(!!formValues.promoCode &&
						affiliateCode && {
							affiliate_code: formValues.promoCode,
						}),
					...(!!formValues.giftCard && {
						gift_card: formValues.giftCard,
					}),
					// For previously added affiliate code
					...((!!bookingData.affiliateCode || bookingData.affiliateCode === null) && {
						affiliate_code: bookingData.affiliateCode,
					}),
				});

				dispatch(
					experienceTravellerActions.updateBooking({
						pricing: newPrice,
					}),
				);

				if (typeof newPrice.discount.promo_code?.value !== "undefined") {
					dispatch(
						experienceTravellerActions.updateBooking({
							promoCode: newPrice.discount.promo_code.code,
						}),
					);

					if (!isAppliedPromoCode) {
						setMessagePromoCode(newPrice.discount.promo_code.message);
					}

					setIsAppliedPromoCode(true);
				}

				if (typeof newPrice.discount.gift_card?.value !== "undefined") {
					dispatch(
						experienceTravellerActions.updateBooking({
							giftCard: newPrice.discount.gift_card.code,
						}),
					);

					if (!isAppliedGiftCard) {
						setMessageGiftCard(newPrice.discount.gift_card.message);
					}

					setIsAppliedGiftCard(true);
				}

				if (typeof newPrice.discount.affiliate_code?.value !== "undefined") {
					dispatch(
						experienceTravellerActions.updateBooking({
							affiliateCode: newPrice.discount.affiliate_code.code,
						}),
					);

					formikProps.setFieldValue("promoCode", "");
				}

				if (newPrice.discount.promo_code?.error_message) {
					addError(`${t("BOOKING.SUMMARY.PROMOCODE.ERROR")}: ${newPrice.discount.promo_code.error_message}`);

					formikProps.setFieldValue("promoCode", "");
				}

				if (newPrice.discount.gift_card?.error_message) {
					addError(`${t("BOOKING.SUMMARY.PROMOCODE.ERROR_GIFT_CARD")}: ${newPrice.discount.gift_card.error_message}`);

					formikProps.setFieldValue("giftCard", "");
				}

				if (newPrice.discount.affiliate_code?.error_message) {
					addError(
						`${t("BOOKING.SUMMARY.PROMOCODE.ERROR_AFFILIATE_CODE")}: ${newPrice.discount.affiliate_code.error_message}`,
					);

					dispatch(
						experienceTravellerActions.updateBooking({
							affiliateCode: null,
						}),
					);

					formikProps.setFieldValue("promoCode", "");
				}
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsBusyPromoCode(false);
				setIsBusyGiftCard(false);
			}
		},
	});

	const { handleSubmit, values } = formikProps;

	return (
		<FormikProvider value={formikProps}>
			<Formik>
				<Box display="flex" mt="15px">
					<Row>
						<Box alignSelf="flex-start">
							<StyledCheckbox
								disabled={isBusyPromoCode || isAppliedPromoCode}
								name="isActivePromoCode"
								label={t("BOOKING.SUMMARY.PROMOCODE.CHECKBOX_LABEL")}
							/>
						</Box>

						{values.isActivePromoCode && (
							<>
								<StyledInput
									disabled={isBusyPromoCode || isAppliedPromoCode}
									variant="standard"
									name="promoCode"
									placeholder={t("BOOKING.SUMMARY.PROMOCODE.INPUT_PLACEHOLDER")}
								/>

								<ActionButton
									isAction={isBusyPromoCode && !isAppliedPromoCode}
									disabled={isBusyPromoCode || isAppliedPromoCode}
									padding={isBusyPromoCode && !isAppliedPromoCode ? 30 : 15}
									type="button"
									color="primary"
									variant="contained"
									translationDefault="BOOKING.SUMMARY.PROMOCODE.APPLY"
									translationAction="BOOKING.SUMMARY.PROMOCODE.APPLYING"
									onClick={() => handleSubmit()}
								/>

								{!!messagePromoCode && (
									<StyledMessageContainer color={colors.sunShade}>{messagePromoCode}</StyledMessageContainer>
								)}
							</>
						)}
					</Row>

					{typeof bookingData.pricing.discount?.promo_code?.value !== "undefined" && (
						<Box
							color={colors.sunShade}
							flex={1}
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							minWidth="60px"
						>
							<strong>
								-
								{prices(
									bookingData.pricing.discount.promo_code.value,
									bookingData.pricing.currency,
									undefined,
									undefined,
									true,
								)}
							</strong>
						</Box>
					)}
				</Box>

				{!partnerType && (
					<Box display="flex" mt="15px">
						<Row>
							<Box alignSelf="flex-start">
								<StyledCheckbox
									disabled={isBusyGiftCard || isAppliedGiftCard}
									name="isActiveGiftCard"
									label={t("BOOKING.SUMMARY.PROMOCODE.CHECKBOX_LABEL_GIFT_CARD")}
								/>
							</Box>

							{values.isActiveGiftCard && (
								<>
									<StyledInput
										disabled={isBusyGiftCard || isAppliedGiftCard}
										variant="standard"
										name="giftCard"
										placeholder={t("BOOKING.SUMMARY.PROMOCODE.INPUT_PLACEHOLDER_GIFT_CARD")}
									/>

									<ActionButton
										isAction={isBusyGiftCard && !isAppliedGiftCard}
										disabled={isBusyGiftCard || isAppliedGiftCard}
										padding={isBusyGiftCard && !isAppliedGiftCard ? 30 : 15}
										type="button"
										color="primary"
										variant="contained"
										translationDefault="BOOKING.SUMMARY.PROMOCODE.APPLY"
										translationAction="BOOKING.SUMMARY.PROMOCODE.APPLYING"
										onClick={() => handleSubmit()}
									/>

									{!!messageGiftCard && (
										<StyledMessageContainer color={colors.sunShade}>{messageGiftCard}</StyledMessageContainer>
									)}
								</>
							)}
						</Row>

						{typeof bookingData.pricing.discount?.gift_card?.value !== "undefined" && (
							<Box
								color={colors.sunShade}
								flex={1}
								display="flex"
								justifyContent="flex-end"
								alignItems="center"
								minWidth="60px"
							>
								<strong>
									-
									{prices(
										bookingData.pricing.discount.gift_card.value,
										bookingData.pricing.currency,
										undefined,
										undefined,
										true,
									)}
								</strong>
							</Box>
						)}
					</Box>
				)}
			</Formik>
		</FormikProvider>
	);
};

export default Promocode;
