import confirmEmailImg from "assets/images/ConfirmEmailImg.jpg";

import ConfirmEmailView from "components/auth/ConfirmEmailView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

const ConfirmEmailPage = () => (
	<AppWithImageWrapper image={confirmEmailImg}>
		<ConfirmEmailView />
	</AppWithImageWrapper>
);

export default ConfirmEmailPage;
