import { Box, Grid } from "@material-ui/core";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { formatNumber } from "utils/prices";

import AccountStatus from "components/dashboard/AccountStatus";

import RatingStars from "ui/RatingStars";
import StatisticBox, { EValueLevel } from "ui/StatisticBox";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

const acceptanceAndReactionRateRanges = [
	{
		label: "0% - 19%",
		minValue: 0,
		maxValue: 19,
		icon: EValueLevel.terrible,
	},
	{
		label: "20% - 39%",
		minValue: 20,
		maxValue: 39,
		icon: EValueLevel.bad,
	},
	{
		label: "40% - 59%",
		minValue: 40,
		maxValue: 59,
		icon: EValueLevel.notBad,
	},
	{
		label: "60% - 79%",
		minValue: 60,
		maxValue: 79,
		icon: EValueLevel.good,
	},
	{
		label: "80% - 100%",
		minValue: 80,
		maxValue: 100,
		icon: EValueLevel.veryGood,
	},
];

export const ratingRanges = [
	{
		label: "0 - 1",
		minValue: 0,
		maxValue: 1,
		icon: EValueLevel.terrible,
	},
	{
		label: "1 - 2",
		minValue: 1,
		maxValue: 2,
		icon: EValueLevel.bad,
	},
	{
		label: "2 - 3",
		minValue: 2,
		maxValue: 3,
		icon: EValueLevel.notBad,
	},
	{
		label: "3 - 4",
		minValue: 3,
		maxValue: 4,
		icon: EValueLevel.good,
	},
	{
		label: "4 - 5",
		minValue: 4,
		maxValue: 5,
		icon: EValueLevel.veryGood,
	},
];

interface IStatisticsSection {
	showAccountStatusBox?: boolean;
}

const StatisticsSection = ({ showAccountStatusBox }: IStatisticsSection) => {
	const { t } = useTranslation();

	const userData = useAppSelector(selectUser);

	const acceptanceRateValue = userData?.statistics?.hosting_notification?.count?.by_status?.ACCEPTED
		? Math.round(
				(userData.statistics.hosting_notification.count.by_status.ACCEPTED /
					(userData.statistics.hosting_notification.count.by_status.ACCEPTED +
						userData.statistics.hosting_notification.count.by_status.REJECTED)) *
					100,
		  )
		: undefined;

	return (
		<Grid container spacing={10}>
			<Grid item md={3} xs={12}>
				<Box bgcolor={colors.white} boxShadow="0 0 40px 0 rgba(30, 30, 30, 0.12)" padding="30px">
					<StatisticBox
						value={acceptanceRateValue}
						valueLabel={acceptanceRateValue ? acceptanceRateValue + "%" : undefined}
						label={t("DASHBOARD.SECTIONS.STATISTICS.ACCEPTANCE")}
						ranges={acceptanceAndReactionRateRanges}
						withTooltip
						iconScale={1.8}
					/>
				</Box>
			</Grid>

			<Grid item md={3} xs={12}>
				<Box bgcolor={colors.white} boxShadow="0 0 40px 0 rgba(30, 30, 30, 0.12)" padding="30px">
					<StatisticBox
						value={userData?.statistics?.hosting_notification?.reaction_rate_percent}
						valueLabel={
							typeof userData?.statistics?.hosting_notification?.reaction_rate_percent === "number"
								? userData.statistics.hosting_notification.reaction_rate_percent + "%"
								: undefined
						}
						label={t("DASHBOARD.SECTIONS.STATISTICS.REACTION")}
						ranges={acceptanceAndReactionRateRanges}
						withTooltip
						iconScale={1.8}
					/>
				</Box>
			</Grid>

			<Grid item md={3} xs={12}>
				<Box
					bgcolor={colors.white}
					boxShadow="0 0 40px 0 rgba(30, 30, 30, 0.12)"
					padding={!!userData?.ratings?.count ? "20px 30px 23px" : "30px"}
				>
					<StatisticBox
						value={!!userData?.ratings?.count ? userData.ratings?.average : undefined}
						valueLabel={
							!!userData?.ratings?.count ? (
								<Box marginTop="-12px">
									<RatingStars
										value={userData.ratings.average}
										count={userData.ratings.count}
										detailsRating={userData.ratings.values}
										size="small"
										withoutLabel
									/>

									<Box
										marginTop="-12px"
										fontSize="28px"
										fontWeight="600"
										fontFamily={MontserratFontFamily}
										display="flex"
										gridGap="5px"
									>
										<Box>{formatNumber(userData.ratings.average, 2, ",")}</Box>

										<Box fontWeight="normal">({userData.ratings.count})</Box>
									</Box>
								</Box>
							) : undefined
						}
						label={t("DASHBOARD.SECTIONS.STATISTICS.RATING")}
						ranges={ratingRanges}
						iconScale={1.8}
					/>
				</Box>
			</Grid>

			{showAccountStatusBox && (
				<Grid item md={3} xs={12}>
					<AccountStatus showOnlyAllSet />
				</Grid>
			)}
		</Grid>
	);
};

export default StatisticsSection;
