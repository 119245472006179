import { Box, Tooltip } from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export const StyledRating = styled(Rating)`
	.MuiRating-icon:not(.MuiRating-iconEmpty) {
		color: ${colors.sunShade};
	}
`;

export const StyledEmptyStar = styled(StarBorderIcon)`
	color: ${colors.sunShade};
`;

export const StyledTooltip = styled(props => (
	<Tooltip classes={{ popper: props.className }} {...props}>
		{props.children}
	</Tooltip>
))`
	pointer-events: unset;

	.MuiTooltip-tooltip {
		padding: 20px;
		border-radius: 4px;
		box-shadow: 0 0 40px 0 #cecece;
		background-color: ${colors.white};
		color: ${colors.mainFontColor};
		font-weight: normal;
		max-width: 400px;

		.MuiTooltip-arrow {
			color: ${colors.white};
			font-size: 20px;
		}
	}
`;

export const RatingContainer = styled(Box)<{ $pointer?: boolean }>`
	${props =>
		props.$pointer &&
		css`
			cursor: pointer;
			user-select: none;
		`}
`;

export const Label = styled.span<{ withoutMarginLeft?: boolean; smallFontSize?: boolean }>`
	font-size: ${props => (props.smallFontSize ? 12 : 16)}px;
	line-height: ${props => (props.smallFontSize ? "20px" : 1.25)};
	font-family: ${RobotoFontFamily};

	${props => !props.withoutMarginLeft && "margin-left: 8px;"}
`;

export const RatingSpecificLabel = styled.span<{ specificWidth?: boolean }>`
	color: ${colors.sunShade};
	font-weight: 500;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	text-align: right;

	${props => props.specificWidth && "width: 36px;"}
`;

export const RatingBar = styled.div`
	width: 160px;
	height: 22px;
	border-radius: 4px;
	border: solid 1px #fb8c00;
	background-color: #ffeed5;
	display: flex;
	align-items: center;
`;

export const InnerRatingBar = styled.div<{ width: string }>`
	height: 18px;
	border-radius: 4px;
	background-color: ${colors.sunShade};
	margin: 0 1px;

	${props => `width: ${props.width}%;`}
`;
