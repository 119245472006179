import { useEffect } from "react";

import { Errors } from "classes/Errors";

import useErrors from "hooks/useErrors";

export const ErrorsProvider = () => {
	const useErrorsHook = useErrors();

	useEffect(() => {
		Errors.useErrors = useErrorsHook;

		// eslint-disable-next-line
	}, []);

	return null;
};
