import AccountDashboardPage from "pages/account/dashboard";
import AccountMyBookingsPage from "pages/account/myBookings";
import AccountMyExperiencesPage from "pages/account/myExperiences";
import AccountMyFinancesPage from "pages/account/myFinances";
import AccountMyReferralsPage from "pages/account/myReferrals";
import AccountMySettingsPage from "pages/account/mySettings";
import AccountNotificationsPage from "pages/account/notifications";
import ConfirmEmailPage from "pages/auth/ConfirmEmailPage";
import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import LoginPage from "pages/auth/LoginPage";
import RegisterPage from "pages/auth/RegisterPage";
import ResetPasswordPage from "pages/auth/ResetPasswordPage";
import BackofficeLazyLoader from "pages/backoffice/LazyLoader";
import BookingPage from "pages/booking";
import BookingConfirmPage from "pages/booking/confirm";
import ContactPage from "pages/contact";
import HealthPage from "pages/devTools/HealthPage";
import Error404Page from "pages/errors/Error404Page";
import ExperienceCongratulationsPage from "pages/experience/congratulations";
import ExperienceCreatePage from "pages/experience/create";
import ExperienceSearchPage from "pages/experience/search";
import ExperienceUpdatePage from "pages/experience/update";
import GuestBookingPage from "pages/guest/booking";
import HomePage from "pages/home";
import ConfirmEmailOnboardingPage from "pages/onboarding/confirmEmail";
import { OnboardingLazyLoader } from "pages/onboarding/LazyLoader";
import OnboardingLoginPage from "pages/onboarding/login";
import OnboardingRegisterPage from "pages/onboarding/register";
import PartnerBookingsPage from "pages/partners/bookings";
import PartnersRedirectPage from "pages/partners/redirect";
import PartnerUsersPage from "pages/partners/users";
import PaymentPage from "pages/payment";
import ProfilePage from "pages/profile";
import StaffEditLocalHostProfilePage from "pages/profile/StaffEditLocalHostProfile";
import RatingPage from "pages/rating";
import RedirectPage from "pages/redirect";
import AboutUsPage from "pages/static/info/AboutUsPage";
import CancellationPolicyPage from "pages/static/info/CancellationPolicyPage";
import CookiesPage from "pages/static/info/CookiesPage";
import DisclaimerImpressumPage from "pages/static/info/DisclaimerImpressumPage";
import DistributionPartnersPage from "pages/static/info/DistributionPartnersPage";
import InvestorsPage from "pages/static/info/InvestorsPage";
import KeyPartnersPage from "pages/static/info/KeyPartnersPage";
import LegalTermsPage from "pages/static/info/LegalTermsPage";
import PressPage from "pages/static/info/PressPage";
import PrivacyPolicyPage from "pages/static/info/PrivacyPolicyPage";
import QualityControlPage from "pages/static/info/QualityControlPage";
import TermsServicePage from "pages/static/info/TermsServicePage";
import BinipoolConceptPage from "pages/static/landing/binipoolConcept";
import UniqueExperiencesPage from "pages/static/landing/uniqueExperiences";

import { paths } from "./paths";
import { ProtectedRouteProps, PublicRouteProps } from "./types";

export const routes: PublicRouteProps[] = [
	{ path: paths.HOMEPAGE, component: HomePage, exact: true },
	{ path: paths.NOT_FOUND, component: Error404Page, exact: true },
	{ path: paths.REDIRECT, component: RedirectPage, exact: true },
	{ path: paths.HEALTH_CHECK, component: HealthPage, exact: true },

	{ path: paths.CONTACT, component: ContactPage, exact: true },
	{
		path: [
			paths.SEARCH,
			paths.SEARCH_COUNTRY,
			paths.SEARCH_CITY,
			paths.SEARCH_CATEGORY,
			paths.SEARCH_COUNTRY_CITY,
			paths.SEARCH_COUNTRY_CATEGORY,
			paths.SEARCH_CITY_CATEGORY,
			paths.SEARCH_COUNTRY_CITY_CATEGORY,
		],
		component: ExperienceSearchPage,
		exact: true,
	},
	{ path: paths.SEARCH_EXPERIENCES, component: BookingPage, exact: true }, // Structured data
	{ path: paths.UNIQUE_EXPERIENCES, component: UniqueExperiencesPage, exact: true },
	{ path: paths.BINIPOOL_CONCEPT, component: BinipoolConceptPage, exact: true },
	{ path: paths.HELP_QUALITY_CONTROL, component: QualityControlPage, exact: true },
	{ path: paths.HELP_CANCELLATION_POLICY, component: CancellationPolicyPage, exact: true },
	{ path: paths.HELP_COOKIES, component: CookiesPage, exact: true },
	{ path: paths.HELP_ABOUT_US, component: AboutUsPage, exact: true },
	{ path: paths.HELP_DISTRIBUTION_PARTNERS, component: DistributionPartnersPage, exact: true },
	{ path: paths.HELP_INVESTORS, component: InvestorsPage, exact: true },
	{ path: paths.HELP_KEY_PARTNERS, component: KeyPartnersPage, exact: true },
	{ path: paths.HELP_PRESS, component: PressPage, exact: true },
	{ path: paths.HELP_DISCLAIMER_IMPRESSUM, component: DisclaimerImpressumPage, exact: true },
	{ path: paths.HELP_PRIVACY_POLICY, component: PrivacyPolicyPage, exact: true },
	{ path: paths.HELP_LEGAL_TERMS, component: LegalTermsPage, exact: true },
	{ path: paths.HELP_TERMS_SERVICE, component: TermsServicePage, exact: true },
	{ path: paths.PUBLIC_PROFILE, component: ProfilePage, exact: true },
	{ path: paths.PARTNER_REDIRECT, component: PartnersRedirectPage }, // important: without exact: true
	{ path: paths.GUEST_PAYMENT, component: PaymentPage, exact: true },

	// START public with token param in URL (as URL part or query param), protected without token param
	{ path: paths.GUEST_BOOKING_PAGE, component: GuestBookingPage, exact: true },
	{ path: paths.RATINGS, component: RatingPage, exact: true },
	// END
];

export const protectedRoutes: ProtectedRouteProps[] = [
	// backoffice START
	{ path: paths.BACKOFFICE, component: BackofficeLazyLoader, onlyForStaff: true },

	// protected START
	{ path: paths.EXPERIENCES, component: AccountMyExperiencesPage, exact: true },
	{ path: paths.EXPERIENCE_CREATE_STEP, component: ExperienceCreatePage, exact: true },
	{ path: paths.CONGRATULATIONS, component: ExperienceCongratulationsPage, exact: true },
	{ path: paths.UPDATE_EXPERIENCE_PAGE, component: ExperienceUpdatePage, exact: true },
	{ path: paths.PROFILE, component: ProfilePage, exact: true },
	{ path: [paths.ONBOARDING, paths.ONBOARDING_ONLY_REDIRECT], component: OnboardingLazyLoader, exact: true },
	{ path: [paths.MY_BOOKINGS, paths.SHOW_BOOKING_RATING], component: AccountMyBookingsPage, exact: true },
	{ path: paths.MY_SETTINGS, component: AccountMySettingsPage, exact: true },
	{ path: paths.MY_FINANCES, component: AccountMyFinancesPage, exact: true },
	{ path: paths.MY_REFERRALS, component: AccountMyReferralsPage, exact: true },
	{ path: paths.DASHBOARD, component: AccountDashboardPage, exact: true },
	{ path: paths.NOTIFICATIONS, component: AccountNotificationsPage, exact: true },
	{ path: paths.CONFIRM_BOOKING, component: BookingConfirmPage, exact: true },
	{ path: paths.PAYMENT, component: PaymentPage, exact: true },

	// partners START
	{ path: paths.PARTNER_BOOKINGS, component: PartnerBookingsPage, exact: true },
	{ path: paths.PARTNER_USERS, component: PartnerUsersPage, exact: true },

	// staff outside backoffice START
	{ path: paths.STAFF_EDIT_LOCALHOST_PROFILE, component: StaffEditLocalHostProfilePage, exact: true },
];

export const anonymousRoutes: PublicRouteProps[] = [
	{ path: paths.LOGIN, component: LoginPage, exact: true },
	{ path: paths.CONFIRM_EMAIL, component: ConfirmEmailPage, exact: true },
	{ path: paths.REGISTER, component: RegisterPage, exact: true },
	{ path: paths.FORGOT_PASSWORD, component: ForgotPasswordPage, exact: true },
	{ path: paths.RESET_PASSWORD, component: ResetPasswordPage, exact: true },
	{ path: paths.ONBOARDING_REGISTER, component: OnboardingRegisterPage, exact: true },
	{ path: paths.ONBOARDING_CONFIRM_EMAIL, component: ConfirmEmailOnboardingPage, exact: true },
	{ path: paths.ONBOARDING_LOGIN, component: OnboardingLoginPage, exact: true },
];
