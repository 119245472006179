import { Box } from "@material-ui/core";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

export const BoxWithGrayBackgroundAbsolute = styled(Box)<{ $calcWidth: number }>`
	position: relative;

	&::before {
		content: "";
		background-color: ${colors.lightGray};
		position: absolute;
		width: calc(${props => props.$calcWidth}px + ((${props => props.$calcWidth}px - 1340px) / 2));
		height: 100%;
		z-index: 0;
		top: 0;
		left: calc(-${props => props.$calcWidth}px + 1340px);

		${media.largeDesktop // @ts-ignore prettier-ignore
		`width: calc(${props => props.$calcWidth}px + 100%);
      left: -${props => props.$calcWidth / 2}px;
  `};
	}
`;

export const Title = styled.h2`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 36px;
	line-height: 1.5;
	letter-spacing: 0.05px;
	font-weight: bold;
	color: ${colors.mainFontColor};

	${media.tablet`
    margin: 0 0 10px;
    font-size: 22px;
    line-height: 1.14;
  `}
`;

export const Subtitle = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	color: ${colors.mainFontColor};
	font-weight: normal;
	margin: 0;

	${media.tablet`
    font-size: 14px;
  `}
`;
