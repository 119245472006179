import styled from "styled-components";

import InvestorsImgSmall from "assets/images/help/investors_main.png";
import InvestorsImgMedium from "assets/images/help/investors_main@2x.png";
import InvestorsImgLarge from "assets/images/help/investors_main@3x.png";

import useTranslation from "hooks/useTranslation";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import ContactButton from "ui/buttons/ContactButton";
import CommonParagraph, { Description } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, ImgWrapper } from "styles/common";
import media from "styles/media";

const Col = styled.div`
	flex: 1;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

const StyledDescription = styled(Description)`
	font-weight: 400;
`;

const InvestorsPage = () => {
	const { t, withRaw } = useTranslation();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.INVESTORS.MAIN_TITLE")} />

				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.INVESTORS.MAIN_TITLE")}
							subTitle={<StyledDescription>{withRaw("HELP.INVESTORS.MAIN_DESCRIPTION", null)}</StyledDescription>}
						/>
						<ContactButton translationKey="HELP.INVESTORS.CONTACT_US_BTN" />
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Investors - picture"
								imgSmall={InvestorsImgSmall}
								imgMedium={InvestorsImgMedium}
								imgLarge={InvestorsImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>
			</Container>
		</AppWrapper>
	);
};

export default InvestorsPage;
