import { memo } from "react";

type TLogoVersion = "localbini" | "localbiniSmall" | "localbiniBig" | "localbiniWhite" | "localbiniWhiteSmall";

const widthMap: Partial<Record<TLogoVersion, string>> = {
	localbini: "100%",
	localbiniSmall: "100%",
	localbiniBig: "160px",
	localbiniWhite: "160px",
};

interface ILogo {
	version?: TLogoVersion;
	width?: number;
	height?: number;
}

const Logo = memo(({ version = "localbini", width, height = 40 }: ILogo) => (
	<img
		title="LocalBini"
		alt="LocalBini logo"
		src={`/${version}.svg`}
		style={{ maxWidth: width ? `${width}px` : widthMap[version], height: `${height}px` }}
	/>
));

export default Logo;
