import { Divider } from "@material-ui/core";
import styled from "styled-components";

import PasswordHint from "components/auth/RegisterView/components/PasswordHint";
import CheckboxField from "components/formik/CheckboxField";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

export const SubTitle = styled.h2`
	margin: 26px 0 18px;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.5px;
	color: ${colors.lightDark};
	opacity: 0.87;

	${media.tablet`
    font-size: 14px;
  `};
`;

export const InnerWrapper = styled.div`
	padding-top: 18px;
	display: flex;
`;

export const Col = styled.div`
	flex: 2;
	margin-right: 20px;

	${media.tablet`
    flex: 1;
    margin-right: 0;
  `};
`;

export const BookingCol = styled.div`
	flex: 1;
`;

export const StyledDivider = styled(Divider)`
	margin-bottom: 30px;
`;

export const CompactCheckbox = styled(CheckboxField)`
	padding: 0 9px;
`;

export const StyledPasswordHint = styled(PasswordHint)`
	top: -15px;
`;
