import { Box } from "@material-ui/core";
import { DatePicker as DatePickerUI } from "@material-ui/pickers";
import { format } from "date-fns";
import { useState } from "react";
import styled, { css } from "styled-components";

import { DATE_FORMATS } from "utils/dates";

import MuiDateProvider from "providers/MuiDateProvider";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

interface ICalendarProps {
	initialValue: Date | null;
	label?: string;
	onClickDate: (value: string | boolean) => void;
	disablePast?: boolean;
	customBackgroundColorToday?: string;
	customFontColorToday?: string;
	customDateFormat?: string;
	chosenDatesList: string[];
	onChangeMonth?: (v: number) => void;
	onChangeYear?: (v: number) => void;
	width?: string;
	height?: string;
	customBackgroundColor?: string;
}

const WrapperDatePicker = styled.div<{
	width: string;
	height: string;
	backgroundColorToday: string;
	fontColor: string;
	backgroundColor: string;
}>`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	letter-spacing: 0.1px;
	z-index: 200;

	.MuiPickersBasePicker-pickerViewLandscape {
		background: ${props => props.backgroundColor};
		border-radius: 6px;
		padding: 23px;
	}

	.MuiPickersCalendarHeader-iconButton {
		background: ${props => props.backgroundColor};
	}

	.MuiPickersDay-current {
		background: ${props => props.backgroundColorToday};
		color: ${props => props.fontColor};
		z-index: 100;

		p {
			font-weight: bold !important;
		}
	}

	.MuiPickersDay-daySelected:hover {
		background: #9e9e9e;
	}

	.MuiPickersBasePicker-pickerView {
		background: ${props => props.backgroundColor};
		min-width: ${props => props.width} !important;
		min-height: ${props => props.height} !important;
	}
`;

const SelectedDateCircle = styled.div<{ isColor?: boolean; customBackground?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	${props =>
		props.isColor &&
		css`
			p {
				font-weight: 500;
				color: white;
			}
			background-color: ${props.customBackground || colors.sunShade};
			clip-path: circle(40% at 50% 50%);
		`}
`;

const Calendar = ({
	initialValue,
	label,
	onClickDate,
	disablePast = false,
	customBackgroundColorToday = colors.black,
	customFontColorToday = colors.white,
	customDateFormat = DATE_FORMATS.DATE_FORMAT2,
	chosenDatesList,
	onChangeMonth,
	onChangeYear,
	width = "auto",
	height = "auto",
	customBackgroundColor = "#f2f2f2",
}: ICalendarProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue);

	const changeMonth = v => {
		if (onChangeMonth) {
			const currentMonth = new Date(v || 0).getMonth() + 1;

			onChangeMonth(currentMonth);
		}

		if (onChangeYear) {
			const currentYear = new Date(v).getFullYear();

			onChangeYear(currentYear);
		}
	};

	return (
		<MuiDateProvider>
			<Box display="flex" justifyContent="space-between">
				<WrapperDatePicker
					backgroundColor={customBackgroundColor}
					backgroundColorToday={customBackgroundColorToday}
					fontColor={customFontColorToday}
					height={height}
					width={width}
				>
					<DatePickerUI
						disableToolbar
						disablePast={disablePast}
						variant="static"
						label={label}
						value={selectedDate}
						onChange={(event: Date) => setSelectedDate(new Date(event))}
						onMonthChange={changeMonth}
						renderDay={(day, selectedDate2, isInCurrentMonth, dayComponent) => {
							// @ts-ignore
							const currentDateFormatted = format(day, customDateFormat);

							let isSelected = false;
							let isPast = false;

							if (chosenDatesList && chosenDatesList.includes(currentDateFormatted)) {
								isSelected = true;

								if (new Date(currentDateFormatted) < new Date()) {
									isPast = true;
								}
							}

							return (
								<SelectedDateCircle
									onClick={() => onClickDate(isSelected ? currentDateFormatted : false)}
									isColor={isSelected && isInCurrentMonth}
									customBackground={isPast ? "#9e9e9e" : undefined}
								>
									{dayComponent}
								</SelectedDateCircle>
							);
						}}
					/>
				</WrapperDatePicker>
			</Box>
		</MuiDateProvider>
	);
};

export default Calendar;
