import { IPartner, IPartnerPermissions, IPartnerPTItem, TPartialPartnerPTItem } from "data/backoffice/partners/types";

import { AmplifyAPIRequest } from "services/aws/types";

import API, { APISingleData } from "../index";
import { generateEntityEndpoint } from "../utils/createCRUDAPI";

const namespace = "internal";
const api = "/partners";

const partnersService = {
	getListOfPartners: () =>
		// TODO: add normal pagination if we will have more than 1000 partners :)
		API.get<IPartner[]>(namespace, generateEntityEndpoint(api), { queryStringParameters: { limit: 1000 } }),
	getDetails: (partnerId: string) => API.get<IPartner>(namespace, `${api}/${partnerId}`),
	postCreate: (body: Partial<IPartner>): AmplifyAPIRequest<IPartner> => API.post(namespace, api, { body }),
	patchPartialUpdate: (partnerId: string, body: Partial<IPartner>): AmplifyAPIRequest<IPartner> =>
		API.patch(namespace, generateEntityEndpoint(api, partnerId), { body }),
	patchGenerateNewApiKey: (partnerId: string) =>
		APISingleData.patch<{ api_key: string }>(namespace, `${api}/${partnerId}/api-key`),
	getPricingTables: (partnerId: string) => API.get<IPartnerPTItem[]>(namespace, `${api}/${partnerId}/pricing-tables`),
	postBulkCreatePricingTables: (partnerId: string, entries: TPartialPartnerPTItem[]) =>
		API.post(namespace, `${api}/${partnerId}/pricing-tables`, { body: { entries } }),
	deleteBulkDeletePricingTables: (partnerId: string) => API.del(namespace, `${api}/${partnerId}/pricing-tables`),
	patchPartialUpdatePricingTables: (partnerId: string, priceTableId: string, body: TPartialPartnerPTItem) =>
		API.patch(namespace, `${api}/${partnerId}/pricing-tables/${priceTableId}`, { body }),
	deletePricingTables: (partnerId: string, priceTableId: string) =>
		API.del(namespace, `${api}/${partnerId}/pricing-tables/${priceTableId}`),
	getPermissions: (partnerId: string) =>
		API.get<{ allow: { partner_permissions: IPartnerPermissions } }>(
			namespace,
			`${api}/${partnerId}/experience-permissions`,
		),
	putUpdatePermissions: (partnerId: string, body: IPartnerPermissions) =>
		API.put<IPartnerPermissions>(namespace, `${api}/${partnerId}/experience-permissions`, { body }),
};

export default partnersService;
