import { Grid, Hidden } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import { isEmpty } from "lodash-es";
import { useHistory, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import CheckboxField from "components/formik/CheckboxField";
import Input from "components/formik/Input";
import PhoneInputField from "components/formik/PhoneInputField";
import AppWrapper from "components/layout/AppWrapper";

import BookingInfo from "ui/BookingInfo";
import PrivacyUrlsFooter from "ui/PrivacyUrlsFooter";

import { Container } from "styles/common";

import { MainTitle } from "../../shared.styled";
import BreadCrumbsBooking from "../BreadCrumbsBooking";
import DetailsBox from "../DetailsBox";
import Stepper from "../Stepper";
import { BookingCol, Col, CompactCheckbox, InnerWrapper, StyledDivider, StyledPasswordHint, SubTitle } from "./styled";
import useValidateSchema from "./validate";

interface IGuestForm {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	createAccount: boolean;
	password: string;
	confirmPassword: string;
	claimsAdult: boolean;
	marketingAgreed: boolean;
}

const BookingGuestRegisterForm = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const booking = useAppSelector(selectExperienceTravellerBooking);
	const userData = useAppSelector(selectUser);

	const history = useHistory();
	const match = useRouteMatch();

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const validationSchema = useValidateSchema(partnerType);

	const formikProps = useFormik<IGuestForm>({
		enableReinitialize: true,
		validationSchema,
		initialValues: {
			firstName: booking?.travelerDetails?.first_name || "",
			lastName: booking?.travelerDetails?.last_name || "",
			phone: booking?.travelerDetails?.phone || "",
			email: booking?.travelerDetails?.email || "",
			password: "",
			confirmPassword: "",
			createAccount: false,
			claimsAdult: false,
			marketingAgreed: false,
		},
		onSubmit: async values => {
			try {
				let userIdToCreate: string | null = null;

				if (values.createAccount) {
					userIdToCreate = `usr-${uuidv4().replace(/-/g, "")}`;

					const params = {
						username: userIdToCreate,
						password: values.password,
						attributes: {
							email: values.email.toLowerCase(),
							name: values.firstName,
							family_name: values.lastName,

							"custom:claims_adult": values.claimsAdult.toString(),
							"custom:marketing_agreed": values.marketingAgreed.toString(),
							"custom:unverified_type": EUserType.TRAVELER,
						},
					};

					await Auth.signUp(params);
				}

				const details = {
					first_name: values.firstName,
					last_name: values.lastName,
					email: values.email,
					phone: values.phone,
				};

				dispatch(
					experienceTravellerActions.updateBooking({
						travelerDetails: details,
						createdUserId: userIdToCreate,
					}),
				);

				history.push(`${match.url}?step=2`);
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	const isCreateAccount = formikProps.values.createAccount;

	if (isEmpty(booking.date)) {
		history.push(`${match.url}?step=0`);

		return null;
	}

	return (
		<>
			<Hidden lgUp>
				<DetailsBox hideButton {...booking} />
			</Hidden>

			<AppWrapper>
				<Container>
					<BreadCrumbsBooking lastBreadcrumb={t("BOOKING_SUMMARY.BOOKING_EXPERIENCE_TITLE")} />

					<InnerWrapper>
						<Col>
							<MainTitle>{t("BOOKING_SUMMARY.BOOKING_EXPERIENCE_TITLE")}</MainTitle>
							<SubTitle>
								{t(
									partnerType
										? "BOOKING_SUMMARY.BOOKING_AS_PARTNER_SUBTITLE"
										: "BOOKING_SUMMARY.BOOKING_AS_GUEST_SUBTITLE",
								)}
							</SubTitle>
							<StyledDivider />

							<FormikProvider value={formikProps}>
								<Form>
									<Grid container spacing={5}>
										<Grid item sm={6} xs={12}>
											<Input
												name="firstName"
												inputClassName="full nomargin"
												required
												label={t("BOOKING_SUMMARY.ROW_NAME")}
												id="id-guest-traveler-name"
												variant="outlined"
											/>
										</Grid>

										<Grid item sm={6} xs={12}>
											<Input
												name="lastName"
												inputClassName="full nomargin"
												required
												label={t("BOOKING_SUMMARY.ROW_LAST_NAME")}
												id="id-guest-traveler-last-name"
												variant="outlined"
											/>
										</Grid>

										{partnerType ? (
											<>
												<Grid item sm={6} xs={12}>
													<PhoneInputField name="phone" label={t("BOOKING_SUMMARY.ROW_PHONE")} required />
												</Grid>

												<Grid item sm={6} xs={12} style={{ marginBottom: "30px" }}>
													<Input
														name="email"
														inputClassName="full nomargin"
														label={t("BOOKING_SUMMARY.ROW_EMAIL")}
														id="id-guest-traveler-email"
														variant="outlined"
														type="email"
													/>
												</Grid>
											</>
										) : (
											<>
												<Grid item sm={6} xs={12}>
													<Input
														name="email"
														inputClassName="full nomargin"
														required
														label={t("BOOKING_SUMMARY.ROW_EMAIL")}
														id="id-guest-traveler-email"
														variant="outlined"
														type="email"
													/>
												</Grid>

												<Grid item sm={6} xs={12}>
													<PhoneInputField
														name="phone"
														label={t("BOOKING_SUMMARY.ROW_PHONE")}
														required
														countryCodeEditable
														disableCountryGuess={false}
													/>
												</Grid>

												<Grid item container xs={12} justifyContent="flex-end">
													<CheckboxField name="createAccount" label={t("BOOKING_SUMMARY.CREATE_ACCOUNT")} />
												</Grid>
											</>
										)}

										{isCreateAccount && (
											<>
												<Grid item sm={6} xs={12}>
													<Input
														name="password"
														inputClassName="full nomargin"
														required
														label={t("BOOKING_SUMMARY.ROW_PASSWORD")}
														id="id-guest-password"
														variant="outlined"
														type="password"
													/>
												</Grid>

												<Grid item sm={6} xs={12}>
													<Input
														name="confirmPassword"
														inputClassName="full nomargin"
														required
														label={t("BOOKING_SUMMARY.ROW_CONFIRM_PASSWORD")}
														id="id-guest-confirm-password"
														variant="outlined"
														type="password"
													/>
												</Grid>

												<Grid item xs={12}>
													<StyledPasswordHint />
												</Grid>

												<Grid item xs={12}>
													<CompactCheckbox name="claimsAdult" label={t("AUTH.REGISTER.ABOVE_18")} withError required />
												</Grid>

												<Grid item xs={12}>
													<CompactCheckbox name="marketing_agreed" label={t("AUTH.REGISTER.MARKETING_AGREEMENT")} />
												</Grid>

												<Grid item xs={12}>
													<PrivacyUrlsFooter firstMessage={t("AUTH.REGISTER.AGREEMENTS_FIRST_PART")} align="left" />
												</Grid>
											</>
										)}
									</Grid>

									<Stepper
										customNext={formikProps.handleSubmit}
										isNextDisabled={!formikProps.isValid}
										isAction={formikProps.isSubmitting}
										backText={t("BOOKING.STEPPER.BACK")}
										nextText={t(isCreateAccount ? "BOOKING.SUMMARY.NEXT_REGISTER" : "BOOKING.SUMMARY.NEXT")}
									/>
								</Form>
							</FormikProvider>
						</Col>

						<Hidden mdDown>
							<BookingCol>
								<BookingInfo {...booking} />
							</BookingCol>
						</Hidden>
					</InnerWrapper>
				</Container>
			</AppWrapper>
		</>
	);
};

export default BookingGuestRegisterForm;
