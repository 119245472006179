import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useState } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import YouTubeFrameImg from "assets/images/binipoolConcept/youtube_frame.svg";
import YouTubeMaskImg from "assets/images/binipoolConcept/youtube_thumb_mask.png";

import useTranslation from "hooks/useTranslation";

import { Description, SmallerTitle } from "ui/CommonParagraph";

import { Container } from "styles/common";
import media from "styles/media";

import { WrapperSection } from "../../index";

const MainSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;

	${media.desktop`
      flex-direction: column;
  `};
`;

const Col = styled.div`
	flex: 1;
	width: 100%;
`;

const VideoContent = styled.div`
	position: relative;
	margin: 0 3.125rem auto 0;
	width: 39.25rem;
	height: 33.438rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: url(${YouTubeFrameImg});
	background-size: inherit;
	background-position: center;
	border-radius: initial;

	transform: rotate(-1deg);
	padding: 15% 5%;

	img {
		transform: rotate(-1deg);
	}

	${media.desktop`
    margin: 0 auto 4.125rem auto;
    background-image: none;
    background: rgb(255, 229, 210);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    transform: initial;
    padding: 5%;

    img {
      transform: initial;
  }
  `};
`;

const PlayBtn = styled(Button)`
	position: absolute;
	bottom: 4.688rem;
	height: 3rem;
	width: 10rem;
	transform: rotate(-1deg);

	${media.desktop`
    bottom: 0;
    width: 5rem;
    bottom: -1rem;
    transform: initial;
  `};
`;

const StyledReactPlayer = styled(ReactPlayer)`
	transform: rotate(-1deg);
	width: 100%;
	height: 320px;

	${media.largeDesktop`
    transform: initial;
    height: 202px;
  `};
`;

const BinipoolExperiences = memo(() => {
	const [play, setPlay] = useState(false);
	const { t, withRaw } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<WrapperSection>
			<Container>
				<MainSection>
					<Col>
						<VideoContent>
							{play ? (
								<StyledReactPlayer
									url="//www.youtube.com/embed/lHuaEv7cGbk"
									playing={play}
									controls
									height={isSmallScreen ? "220px" : "320px"}
								/>
							) : (
								<img
									src={YouTubeMaskImg}
									width="100%"
									height="100%"
									title="LocalBini - Binipool concept - youtube"
									alt="LocalBini - Binipool concept - youtube"
								/>
							)}

							<PlayBtn color="primary" variant="contained" onClick={() => setPlay(!play)}>
								{t("BINIPOOL_CONCEPT.BINIPOOL_MOVIE_PLAY_BTN")}
							</PlayBtn>
						</VideoContent>
					</Col>

					<Col>
						<SmallerTitle>{t("BINIPOOL_CONCEPT.BINIPOOL_MOVIE_TITLE")}</SmallerTitle>

						<Description>{withRaw("BINIPOOL_CONCEPT.BINIPOOL_MOVIE_DESC", null)}</Description>
					</Col>
				</MainSection>
			</Container>
		</WrapperSection>
	);
});

export default BinipoolExperiences;
