import { Box, Button } from "@material-ui/core";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { EUserType, TUserIId } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import usersService from "services/api/users";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";

// import ActionPanel from "components/backoffice/ActionPanel";
import ElemClipboard from "components/backoffice/ElemClipboard";
import AppWrapper from "components/layout/AppWrapper";
import InviteNewUserModal from "components/users/InviteNewUser";

import Breadcrumbs from "ui/Breadcrumbs";
import ChildrenLoader from "ui/loaders/ChildrenLoader";
import CircularLoader from "ui/loaders/CircularLoader";
import Table, { Order } from "ui/Table";

import colors from "styles/colors";
import { Container, StyledExternalLink } from "styles/common";

import { Header, Wrapper } from "../../account/shared.styled";
import FilterSearchPartnerUsers, { ISelectedFilters } from "./components/FilterSearch";
import { columns, fields, idForContainer, listItemsOffset, sortedColumns } from "./config";

const StyledButton = styled(Button)`
	background-color: ${colors.mainFontColor};
	color: ${colors.white};

	&:hover {
		background-color: ${colors.lightSunShade};
	}
`;

interface IQueryParams {
	text_search?: string;
	user_type?: EUserType.PARTNER_AGENT | EUserType.PARTNER_MANAGER;
}

const PartnerUsersPage = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const [results, setResults] = useState<{ data: TUserIId[]; count: number }>();
	const [page, setPage] = useState<number>(1);
	const [loadMore, setLoadMore] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<{ sort: string; order: Order; orderBy: number }>();
	const [queryParams, setQueryParams] = useState<IQueryParams>();
	const [inviteModal, setInviteModal] = useState<boolean>(false);

	const preventLoading = useRef<boolean>(false);

	useEffect(() => {
		const mainContainerNode = document.querySelector(`#${idForContainer}`);

		const handleScroll = throttle(() => {
			if (mainContainerNode) {
				if (document.documentElement.scrollTop >= mainContainerNode.scrollHeight - 600) {
					if (!preventLoading.current) {
						setLoadMore(true);

						preventLoading.current = true;
					}
				}
			}
		}, 1000);

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, []);

	const fetchData = useCallback(
		async (data?: object, reset?: boolean) => {
			try {
				const users = await usersService.getAllUsers({
					...(orderData && !reset && { sort: orderData.sort, order: orderData.order }),
					...(!reset && queryParams),
					...data,
				});

				setResults(prevState => ({
					count: users.count,
					data: !!prevState?.data ? [...prevState.data, ...users.data] : users.data,
				}));
			} catch (e) {
				handleAndNotify(e);
			} finally {
				preventLoading.current = false;

				setLoadMore(false);
			}
		},

		// eslint-disable-next-line
		[orderData, queryParams],
	);

	useEffect(() => {
		fetchData();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const loadMoreData = async () => {
			setLoader(false);

			if (loadMore) {
				if (page * listItemsOffset <= (results?.count || 0)) {
					setLoader(true);

					await fetchData({ offset: page * listItemsOffset });

					setPage(prevState => prevState + 1);

					setLoadMore(false);
				}
			}
		};

		loadMoreData();

		// eslint-disable-next-line
	}, [loadMore]);

	const sortData = (nameField: string, order: Order, orderBy: number) => {
		setPage(1);

		setResults(undefined);

		setOrderData({ sort: nameField, order, orderBy });

		fetchData({ sort: nameField, order });
	};

	const getFilters = (filters: ISelectedFilters) => {
		const queryParamsFromFilters: IQueryParams = {};

		Object.entries(filters).forEach(([filterType, filterValue]) => {
			if (!filterValue) {
				return;
			}

			if (filterType === "textSearch" && filterValue) {
				queryParamsFromFilters.text_search = filterValue;
			} else if (filterType === "partnerType" && filterValue) {
				queryParamsFromFilters.user_type = filterValue;
			}
		});

		setOrderData(undefined);
		setPage(1);
		setResults(undefined);

		setQueryParams(queryParamsFromFilters);

		fetchData(queryParamsFromFilters, true);
	};

	const resetData = () => {
		setOrderData(undefined);
		setPage(1);
		setResults(undefined);

		setQueryParams(undefined);

		fetchData({}, true);
	};

	const parsedData =
		results?.data?.map(elem => ({
			name_HELPER: (
				<Box fontWeight="500">
					{elem.profile_draft?.first_name} {elem.profile_draft?.last_name}
				</Box>
			),
			id: <ElemClipboard splitPart={15} elem={elem.id} />,
			created_on: parseISOWrapper(elem.created_on, DATE_FORMATS.DATE_FORMAT2),
			user_type: t(`PARTNER_USERS.USER_TYPE.${elem.user_type}`),
			phone_HELPER: elem.personal?.phone ? (
				<StyledExternalLink color="inherit" href={`tel:${elem.personal.phone}`}>
					{elem.personal.phone}
				</StyledExternalLink>
			) : (
				"-"
			),
			email_HELPER: elem.personal?.email ? (
				<StyledExternalLink color="inherit" href={`mailto:${elem.personal.email}`}>
					{elem.personal.email}
				</StyledExternalLink>
			) : (
				"-"
			),
			/*  action_HELPER: (
        <Box display="flex" width="100%" justifyContent="center">
          <ActionPanel id={elem.id} options={[]} />
        </Box>
      ),*/
		})) || [];

	return (
		<AppWrapper>
			<Container>
				{inviteModal && (
					<InviteNewUserModal handleClose={() => setInviteModal(false)} reloadMainTable={() => resetData()} />
				)}

				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.PARTNER_USERS")} />

				<Wrapper>
					<Header>
						{partnerStorage.getData()?.name} {t("TOPBAR.PARTNER_USERS")}
					</Header>

					<Box marginTop="80px">
						<Box textAlign="right" marginBottom="20px">
							<StyledButton onClick={() => setInviteModal(true)}>{t("PARTNER_USERS.BUTTON.INVITE_USER")}</StyledButton>
						</Box>

						<Box marginBottom="30px">
							<FilterSearchPartnerUsers applySelectedFiltersAction={getFilters} />
						</Box>

						<Box id={idForContainer} style={{ overflowX: "auto" }}>
							<ChildrenLoader check={results}>
								<Table
									data={parsedData}
									columns={columns}
									fields={fields}
									sortedColumns={sortedColumns}
									externalSort={sortData}
									initialOrder={orderData?.order}
									initialOrderBy={orderData?.orderBy}
									totalRecords={results?.count}
								/>
							</ChildrenLoader>
						</Box>

						{loader && (
							<Box marginTop="50px">
								<CircularLoader absolute={false} />
							</Box>
						)}
					</Box>
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default PartnerUsersPage;
