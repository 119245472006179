import { Hidden } from "@material-ui/core";
import { getCurrentLocale } from "i18n";
import {
	DateRange,
	DateRangeDelimiter,
	DesktopDateRangePickerProps,
	LocalizationProvider,
	MobileDateRangePickerProps,
} from "newer-pickers";
import DateFnsAdapter from "newer-pickers/adapter/date-fns";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS } from "utils/dates";
import { mergeDeep } from "utils/objects";

import { commonInputProps } from "./config";
import {
	InputContainer,
	StyledDesktopDateRangePicker,
	StyledMobileDateRangePicker,
	StyledTextField,
} from "./shared.styled";

interface IRangePicker
	extends Omit<DesktopDateRangePickerProps | MobileDateRangePickerProps, "onChange" | "renderInput" | "value"> {
	setDate(date: DateRange<Date>): void;
	date: DateRange<Date>;
	inputDateFormat: DATE_FORMATS;
	labelStart?: string;
	labelEnd?: string;
	colorOfInputBorder?: string;
}

const RangePicker = ({
	date,
	setDate,
	inputDateFormat,
	labelStart,
	labelEnd,
	colorOfInputBorder,
	...rest
}: IRangePicker) => {
	const { t } = useTranslation("ui");

	const locale = getCurrentLocale();

	const props: DesktopDateRangePickerProps = {
		startText: labelStart || t("RANGE_PICKER.LABEL.START"),
		endText: labelEnd || t("RANGE_PICKER.LABEL.END"),
		value: date,
		onChange: setDate,
		inputFormat: inputDateFormat,
		renderInput: (startProps, endProps) => (
			<InputContainer>
				<StyledTextField {...mergeDeep(startProps, commonInputProps)} $colorOfInputBorder={colorOfInputBorder} />

				<Hidden smDown>
					<DateRangeDelimiter>{t("COMMON.TO")}</DateRangeDelimiter>
				</Hidden>

				<StyledTextField {...mergeDeep(endProps, commonInputProps)} $colorOfInputBorder={colorOfInputBorder} />
			</InputContainer>
		),
		...rest,
	};

	return (
		<LocalizationProvider dateAdapter={DateFnsAdapter} locale={locale}>
			<Hidden mdUp>
				<StyledMobileDateRangePicker {...props} />
			</Hidden>

			<Hidden smDown>
				<StyledDesktopDateRangePicker {...props} />
			</Hidden>
		</LocalizationProvider>
	);
};

export default RangePicker;
