import { paths } from "routing/paths";

const isValidIdToPreview = (id?: string) => !id?.startsWith("not-");

const foundModuleId = <T extends Record<string, paths>>(
	availableList: T,
	search: string,
): {
	prefix: keyof T;
	id?: string;
} => {
	const clearSearch = search.trim();
	const type = clearSearch.split("-");

	if (type[0] in availableList && type[1]?.match(/^[A-Za-z0-9]+$/)) {
		return {
			prefix: type[0],
			id: clearSearch,
		};
	}

	return { prefix: type[0] };
};

const prettifyString = (value: string) => {
	if (value === "") {
		return value;
	}

	const lowerCaseStringWithSpaces = value.toLowerCase().replace(/_/g, " ");

	return lowerCaseStringWithSpaces.charAt(0).toUpperCase() + lowerCaseStringWithSpaces.slice(1);
};

const normalizeForAPIString = (value: string) => {
	if (value === "") {
		return value;
	}

	const stringWithoutSpaces = value.trim().replace(/\s/g, "_");

	return stringWithoutSpaces.toUpperCase();
};

export { isValidIdToPreview, foundModuleId, prettifyString, normalizeForAPIString };
