import { TUserIId } from "data/users/types";

export const columns = ["Full name", "User ID", "Date invited", "Partner type", "Phone", "E-mail"];

export const fields: (keyof TUserIId | "name_HELPER" | "phone_HELPER" | "email_HELPER" | "action_HELPER")[] = [
	"name_HELPER",
	"id",
	"created_on",
	"user_type",
	"phone_HELPER",
	"email_HELPER",
	// "action_HELPER",
];

export const sortedColumns = ["Date invited"];

export const idForContainer = "partner-users-main-view";
export const listItemsOffset = 20;
