import { Box } from "@material-ui/core";

import { IGalleryItem } from "data/experiences/types";

import PhotoTile from "../PhotoTile";
import { PhotoTileType } from "../PhotoTile/types";

export interface IGalleryProps {
	onChangeGallery: (v: Blob) => void;
	valueGallery: IGalleryItem[];
	valueMainPhoto?: IGalleryItem;
	onChangeMainPhoto: (v: IGalleryItem) => void;
	deleteMediaCallback: (id: string) => void;
}

const Gallery = ({
	onChangeGallery,
	valueGallery,
	valueMainPhoto,
	onChangeMainPhoto,
	deleteMediaCallback,
}: IGalleryProps) => (
	<>
		<PhotoTile
			key={`large-${valueMainPhoto?.id}`}
			type={PhotoTileType.large}
			photo={valueMainPhoto}
			savePhoto={onChangeGallery}
			deletePhoto={deleteMediaCallback}
		/>

		<Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap" style={{ columnGap: "17px" }}>
			{valueGallery.map(photo => (
				<PhotoTile
					key={`image-${photo.id}`}
					type={PhotoTileType.small}
					photo={photo}
					savePhoto={onChangeGallery}
					saveMainPhoto={onChangeMainPhoto}
					deletePhoto={deleteMediaCallback}
				/>
			))}

			{!!valueMainPhoto?.media_url && valueGallery.length < 11 && (
				<PhotoTile key={`empty-${valueGallery.length}`} type={PhotoTileType.small} savePhoto={onChangeGallery} />
			)}
		</Box>
	</>
);

export default Gallery;
