import FormHelperText from "@material-ui/core/FormHelperText";
import { UseAutocompleteProps } from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { Field } from "formik";
import { memo } from "react";

import { handleBackspace } from "utils/handlers";

import { AutocompleteTyped, Option } from "ui/forms/Autocomplete";
import { TAutocompleteTyped } from "ui/forms/Autocomplete/types";

export interface IAutocompleteV2Field
	extends Omit<TAutocompleteTyped<Option, boolean, boolean, boolean>, "onChange" | "onInputChange"> {
	customOnChange?: (value) => void;
	customInputChange?: UseAutocompleteProps<Option, boolean, boolean, boolean>["onInputChange"];
	empty?: boolean;
	required?: boolean;
}

const AutocompleteV2Field = memo(
	({ name, customInputChange, customOnChange, empty, required, ...rest }: IAutocompleteV2Field) => (
		<Field name={name}>
			{({ field, form: { setFieldValue, setFieldTouched }, meta }) => {
				const hasError = meta.touched && !!meta.error;

				const onChange = (v): void => {
					const value = rest.multiple ? v?.map(i => i?.value || i) || [] : v?.value || "";

					if (customOnChange) {
						customOnChange(value);
					}

					setFieldTouched(field.name, true);

					setFieldValue(field.name, value);
				};

				return (
					<>
						<AutocompleteTyped
							{...rest}
							inputProps={{
								onKeyUp: event => {
									handleBackspace(event, () => setFieldValue(field.name, null));
								},
								...rest?.inputProps,
							}}
							onInputChange={(event, value, reason) => {
								if (reason === "clear") {
									setFieldValue(field.name, rest?.multiple ? [] : "");

									return;
								}

								if (!!customInputChange) {
									customInputChange(event, value, reason);
								}
							}}
							name={field.name}
							value={!empty ? field.value : null}
							onChange={onChange}
							error={hasError}
							required={required}
						/>

						{hasError && <FormHelperText error>{meta.error}</FormHelperText>}
					</>
				);
			}}
		</Field>
	),
);

export default AutocompleteV2Field;
