import { Box, useMediaQuery, useTheme } from "@material-ui/core";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import CarouselSwiper from "ui/CarouselSwiper";

import { Container, StyledLink } from "styles/common";

import { SeeAllBtn } from "../shared.styled";
import TitleSection from "../TitleSection";
import CategoryTile from "./components/CategoryTile";
import { categoriesConfig } from "./config";

const discoverSettings = {
	lazy: true,
	slidesPerView: 1,
	pagination: { clickable: true },
	breakpoints: {
		300: {
			spaceBetween: 60,
			height: 480,
			width: 304,
		},
		600: {
			spaceBetween: 100,
			width: 320,
		},
		960: {
			spaceBetween: 70,
			height: 661,
			width: 434,
		},
	},
};

const createListOfSliders = categoriesConfig.map(v => (
	<CategoryTile key={v.filterName} imgUrl={v.imgUrl} categoryName={v.categoryName} filterName={v.filterName} />
));

const Categories = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const { t } = useTranslation();

	return (
		<Box
			pt={isSmallScreen ? 20 : 45}
			pb={isSmallScreen ? 20 : 45}
			style={{ background: theme.palette.background.greyBackground }}
		>
			<Container>
				<TitleSection title={t("HP.CATEGORIES.TITLE")} subTitle={t("HP.CATEGORIES.SUBTITLE")} />
			</Container>

			<CarouselSwiper
				isMarginLeft
				heightSlide="inherit"
				widthSlide="inherit"
				paddingBox={5}
				settings={discoverSettings}
				slides={createListOfSliders}
			/>

			<Box display="flex" alignItems="center" justifyContent="center" mt={isSmallScreen ? 16 : 22}>
				<StyledLink to={paths.SEARCH} $withoutHoverUnderline>
					<SeeAllBtn color="primary" variant="contained">
						{t("HP.SEE_ALL_BTN")}
					</SeeAllBtn>
				</StyledLink>
			</Box>
		</Box>
	);
};

export default Categories;
