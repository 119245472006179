import { Box } from "@material-ui/core";
import { ReactNode, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import styled, { createGlobalStyle, css } from "styled-components";
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination, SwiperOptions } from "swiper";
// For SCSS see aliases in vite.config.ts, now ESM imports are not used due to Storybook!
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/scrollbar/scrollbar.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import "./assets/CarouselSwiper.scss";

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

interface ICarouselSwiperProps {
	images?: string[];
	settings?: SwiperOptions;
	slides: ReactNode[];
	heightSlide?: string;
	widthSlide?: string;
	paddingBox?: number | "initial";
	isMarginLeft?: boolean;
	hidePrevNextBtn?: boolean;
}

const ExperienceRecord = styled(Box)`
	width: 100%;
	height: 100%;
`;

const StylesFixedScroll = createGlobalStyle`
  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

const defaultSettings: SwiperOptions = {
	slidesPerView: 1,
	spaceBetween: 20,
	height: 363,
	width: 300,
	pagination: { clickable: true },
};

const SwiperWrapper = styled.div<{ isMarginLeft?: boolean; hidePrevNextBtn?: boolean }>`
  ${props =>
		props.isMarginLeft &&
		css`
    .swiper-wrapper {
      & > div:first-child {
        margin-left: 5%;
      }
  `};
  };

  ${props =>
		props.hidePrevNextBtn &&
		css`
			.swiper-button-prev,
			.swiper-button-next,
			.swiper-pagination-bullet {
				display: none;
			}
		`};
`;

const CarouselSwiper = ({
	images,
	slides,
	heightSlide = "100%",
	widthSlide = "100%",
	settings = defaultSettings,
	paddingBox = "initial",
	isMarginLeft,
	hidePrevNextBtn,
}: ICarouselSwiperProps) => {
	const [photoIndex, setPhotoIndex] = useState<number>(0);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const showPreview = index => {
		if (images && images.length) {
			setPhotoIndex(index);
			setIsOpen(true);
		}
	};

	return (
		<>
			{isOpen && images?.length && (
				<>
					<StylesFixedScroll />
					<Lightbox
						mainSrc={images[photoIndex]}
						nextSrc={images[(photoIndex + 1) % images.length]}
						prevSrc={images[(photoIndex + images.length - 1) % images.length]}
						onCloseRequest={() => setIsOpen(false)}
						onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
						onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
					/>
				</>
			)}

			<SwiperWrapper isMarginLeft={isMarginLeft} hidePrevNextBtn={hidePrevNextBtn}>
				<Swiper {...settings}>
					{slides.map((slide, index) => (
						<SwiperSlide
							key={index}
							style={{ display: "flex", height: heightSlide, width: widthSlide, padding: paddingBox }}
						>
							<ExperienceRecord onClick={() => showPreview(index)}>{slide}</ExperienceRecord>
						</SwiperSlide>
					))}
				</Swiper>
			</SwiperWrapper>
		</>
	);
};

export default CarouselSwiper;
