export const stepperConfig = [
	{
		title: "BASIC_INFORMATION",
		order: 1,
		fieldsTitles: [
			"title",
			"city",
			"activity_type",
			"relevant_attractions",
			"categories",
			"description",
			"description_uniqueness",
		],
	},
	{
		title: "EXPERIENCE_DETAILS",
		order: 2,
		fieldsTitles: [
			"requirements",
			"included",
			"excluded",
			"accessibility",
			"health_restrictions",
			"physical_difficulty_level",
			"number_of_travelers",
			"location",
		],
	},
	{
		title: "SETTINGS",
		order: 3,
		fieldsTitles: [
			"duration_minutes",
			"schedule.availability_periods",
			"schedule.available_days_of_week",
			"schedule.excluded_dates",
			"cutoff_time_hours",
			"cancellation_policy_hours",
			"weather_sensitivities",
			"disaster_policies",
		],
	},
	{
		title: "MEDIA",
		order: 4,
		fieldsTitles: ["media_gallery"],
	},
	{
		title: "PRICING",
		order: 5,
		fieldsTitles: [
			"pricingType",
			"pricing.your_potential_earnings",
			"pricing.kids_free_under",
			"pricing.private_price",
			"pricing.preview",
		],
	},
];
