import FormHelperText from "@material-ui/core/FormHelperText";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { KeyboardTimePicker, KeyboardTimePickerProps } from "@material-ui/pickers";
import { Field } from "formik";
import styled from "styled-components";

import { DATE_FORMATS } from "utils/dates";

import MuiDateProvider from "providers/MuiDateProvider";

const StyledKeyboardTimePicker = styled(KeyboardTimePicker)`
	width: 100%;
`;

interface ITimePickerField extends Omit<KeyboardTimePickerProps, "onChange" | "value"> {
	name: string;
	onChangeValueModifier?: (date: Date | null) => Date | string;
	format?: DATE_FORMATS;
}

const TimePickerField = ({
	name,
	onChangeValueModifier,
	format = DATE_FORMATS.TIME_FORMAT,
	...rest
}: ITimePickerField) => (
	<Field name={name}>
		{({ field, form: { setFieldValue }, meta }) => {
			const hasError = meta.touched && !!meta.error;

			const onChange = (value: Date | null) =>
				setFieldValue(name, onChangeValueModifier ? onChangeValueModifier(value) : value);

			return (
				<MuiDateProvider>
					<StyledKeyboardTimePicker
						{...rest}
						name={name}
						value={field.value}
						format={format}
						onChange={onChange}
						InputLabelProps={{ shrink: true }}
						keyboardIcon={<AccessTimeIcon />}
					/>

					{hasError && <FormHelperText error>{meta.error}</FormHelperText>}
				</MuiDateProvider>
			);
		}}
	</Field>
);

export default TimePickerField;
