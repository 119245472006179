import { Box } from "@material-ui/core";
import styled from "styled-components";

import { DescriptionContent } from "styles/common";

interface IDifficultyLevelProps {
	descriptionStr: string;
	subDescriptionStr: string;
}

const LevelDesc = styled(DescriptionContent)`
	opacity: 0.5;
`;

const DifficultyLevel = ({ descriptionStr, subDescriptionStr }: IDifficultyLevelProps) => (
	<Box ml={10}>
		<Box mt={16}>
			<DescriptionContent>
				<strong>{descriptionStr}</strong>
			</DescriptionContent>
		</Box>

		<Box mb={20}>
			<LevelDesc>{subDescriptionStr}</LevelDesc>
		</Box>
	</Box>
);

export default DifficultyLevel;
