import { Auth } from "aws-amplify";
import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import usersService from "services/api/users";

import { useAlertMessage } from "../../index";
import { EPhoneConfirmationMethods, ISharedOnboardingProps } from "../../types";
import EnterVerificationCodeLayout from "./components/EnterVerificationCodeLayout";
import PhoneVerificationLayout from "./components/PhoneVerificationLayout";

const phoneCognitoAttrName = "phone_number";
const metaDataPhoneConfirmationMethodAttrName = "phoneConfirmationMethod";

const maxPhoneNumberWithPrefixLength = 16;

const PhoneVerification = ({ moveToNextStep, updateProfileData, uid }: ISharedOnboardingProps & { uid: string }) => {
	const { openAlertMessage, ...alertMessageProps } = useAlertMessage();

	const [isResend, setIsResend] = useState<boolean>(false);
	const [isSpinnerOpen, setIsSpinnerOpen] = useState<boolean>(false);
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [verifiedCode, setVerifiedCode] = useState<string>("");
	const [isPhoneNumberSent, setIsPhoneNumberSent] = useState<boolean>(false);
	const [prefixNumber, setPrefixNumber] = useState<string>("");
	const [countrySymbol, setCountrySymbol] = useState<string>("");

	useEffect(() => {
		if (verifiedCode?.length === 6) {
			(async () => {
				await verifyPhone();
			})();
		}

		// eslint-disable-next-line
	}, [verifiedCode]);

	const handleError = () => {
		setIsResend(false);

		openAlertMessage();
	};

	const sendTheCode = async (event: FormEvent) => {
		event.preventDefault();

		setIsSpinnerOpen(true);

		try {
			const user = await Auth.currentAuthenticatedUser();

			await Auth.updateUserAttributes(
				user,
				{ [phoneCognitoAttrName]: `${prefixNumber}${phoneNumber}` },
				{ [metaDataPhoneConfirmationMethodAttrName]: EPhoneConfirmationMethods.SMS },
			);

			await Auth.verifyCurrentUserAttribute(phoneCognitoAttrName);

			const apiCall = await updateProfileData({}, true);

			if (apiCall === true) {
				setIsPhoneNumberSent(true);
			}
		} catch {
			handleError();
		} finally {
			setIsSpinnerOpen(false);
		}
	};

	const handlePhoneNumberInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/[^0-9]/g, "");

		if (value.length + prefixNumber.length > maxPhoneNumberWithPrefixLength) {
			return;
		}

		setPhoneNumber(value);
	};

	const handlePrefixNumberInputChange = (event: ChangeEvent, value: null | { code: string; phone: string }) => {
		setCountrySymbol(value ? value.code : "");

		const valueTrimmed = value ? value.phone.replace(/\s/g, "") : "";
		const prefixLength = valueTrimmed.length;

		const phoneNumberLength = phoneNumber.length;

		if (prefixLength + phoneNumberLength > maxPhoneNumberWithPrefixLength) {
			setPhoneNumber(prevState => prevState.slice(0, maxPhoneNumberWithPrefixLength - prefixLength));
		}

		setPrefixNumber(valueTrimmed);
	};

	const verifyPhone = async () => {
		(document.activeElement as HTMLElement)?.blur();

		setIsSpinnerOpen(true);

		try {
			await Auth.verifyCurrentUserAttributeSubmit(phoneCognitoAttrName, verifiedCode);

			await usersService.syncUserPhone(uid);

			const options = {
				showPhoneModal: false,
			};

			// @ts-ignore
			window.__LOCALBINI__ = options;

			const apiCall = await updateProfileData({});

			if (apiCall === true) {
				moveToNextStep();
			}
		} catch {
			setVerifiedCode("");

			handleError();
		} finally {
			setIsSpinnerOpen(false);
		}
	};

	const handleCodeInputChange = (codeValue: string) => {
		setVerifiedCode(codeValue);
	};

	const handleClickChangeNumber = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		alertMessageProps.handleCloseAlert();

		setIsPhoneNumberSent(false);
	};

	const handleClickResendSMS = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		try {
			await Auth.verifyCurrentUserAttribute(phoneCognitoAttrName);

			setIsResend(true);

			openAlertMessage();
		} catch {
			handleError();
		}
	};

	const phoneVerificationProps = {
		phoneNumber,
		prefixNumber,
		countrySymbol,
		isSpinnerOpen,
		handlePhoneNumberInputChange,
		handlePrefixNumberInputChange,
		sendTheCode,
		isPhoneButtonDisabled: prefixNumber === "" || phoneNumber.trim() === "",
		...alertMessageProps,
	};

	const enterVerificationCodeProps = {
		isResend,
		isSpinnerOpen,
		verifiedCode,
		fullPhoneNumber: `${prefixNumber} ${phoneNumber}`,
		handleClickChangeNumber,
		handleClickResendSMS,
		handleCodeInputChange,
		...alertMessageProps,
	};

	return isPhoneNumberSent ? (
		<EnterVerificationCodeLayout {...enterVerificationCodeProps} />
	) : (
		<PhoneVerificationLayout {...phoneVerificationProps} />
	);
};

export default PhoneVerification;
