import { IPool } from "data/backoffice/bookings/types";
import { IExperience, IUpcomingBooking } from "data/experiences/types";

import API, { APISingleData } from "./index";
import { IFetchByIdQueryParams, IPostCreateInternalCommentBody } from "./types/experiences";

const namespace = "internal";
const api = "/experiences";

const experiencesService = {
	getExperienceById: (
		experienceId: string,
		queryParams?: IFetchByIdQueryParams,
		withExtraData?: boolean,
		withGuestCredentials?: boolean,
	) =>
		withExtraData
			? // TODO:refactor - change type to { data: IExperience; upcoming_bookings?: IUpcomingBooking[] }
			  APISingleData.get<IExperience>(
					namespace,
					`${api}/${experienceId}`,
					{
						queryStringParameters: queryParams,
					},
					undefined,
					withGuestCredentials,
			  )
			: API.get<IExperience>(
					namespace,
					`${api}/${experienceId}`,
					{
						queryStringParameters: queryParams,
					},
					undefined,
					withGuestCredentials,
			  ),
	patchExperience: (experienceId: string, data) =>
		API.patch<IExperience>(namespace, `${api}/${experienceId}`, { body: data }),
	getListOfExperiences: params =>
		APISingleData.get<{ count: number; data: IExperience[]; upcoming_bookings?: IUpcomingBooking[] }>(namespace, api, {
			queryStringParameters: params,
		}),
	getExperienceAvailabilityHours: (
		experienceId: string,
		params: { date_from: string; date_to: string; number_of_travelers: number },
	) =>
		APISingleData.get<{ data: any | {} }>(namespace, `${api}/${experienceId}/available-hours`, {
			queryStringParameters: params,
		}),
	//
	getPool: (eid: string) => API.get<IPool>(namespace, `${api}/${eid}/pool`),
	postJoinBinipool: (experienceId: string, data) =>
		API.post(namespace, `${api}/${experienceId}/pool/hosts`, { body: data }),
	deleteLeaveBinipool: (experienceId: string, userId: string) =>
		API.del(namespace, `${api}/${experienceId}/pool/hosts/${userId}`),
	//
	postCreateInternalComment: (experienceId: string, body: IPostCreateInternalCommentBody) =>
		API.post<IExperience>(namespace, `${api}/${experienceId}/internal-comments`, { body }),
	deleteInternalComment: (experienceId: string, commentId: string) =>
		API.del(namespace, `${api}/${experienceId}/internal-comments/${commentId}`),
};

export default experiencesService;
