import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import experiencesService from "services/api/experiences";

export interface IExperiencesState {
	data: {
		experiences: any;
		count: number | null;
	};
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: IExperiencesState = {
	data: {
		experiences: null,
		count: null,
	},
	status: "idle",
	error: null,
};
export const fetchExperiences = createAsyncThunk<any, any>("experiences/fetch", async (queryParams, thunkAPI) => {
	try {
		const experiencesList = await experiencesService.getListOfExperiences(queryParams);

		return experiencesList;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

const { actions, reducer } = createSlice({
	name: "experiences",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: {
		[fetchExperiences.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.experiences = action.payload.data;
			state.data.count = action.payload.count;
			state.status = "succeeded";
			state.error = null;
		},
		[fetchExperiences.pending.toString()]: state => {
			state.status = "loading";
			state.error = null;
		},
		[fetchExperiences.rejected.toString()]: state => {
			state.status = "failed";
			state.error = true;
		},
	},
});

export const experiencesActions = actions;

export default reducer;
