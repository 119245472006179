import { Button, Divider } from "@material-ui/core";
import { ReactNode } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

const Wrapper = styled.section<{ height: string }>`
	background: ${colors.white};
	width: 100%;
	height: ${props => props.height};

	${props => props.height === "auto" && "min-height: 500px;"}

	${media.largeDesktop<{ height: string }>`
    height: auto;

    ${props => props.height === "auto" && "min-height: inherit;"}
  `};
`;

const TitleWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Title = styled.h1`
	margin: 0;
	font-size: 18px;
	font-family: ${RobotoFontFamily};
	font-weight: 300;
	line-height: 1.33;
	letter-spacing: 0.1px;
	color: ${colors.darkGreen};
`;

const InnerWrapper = styled.div`
	width: 100%;
	margin-top: 10px;
`;

const StyledDivider = styled(Divider)`
	margin-top: 10px;
	border: solid 1px #e6e6e6;
`;

const EmptyWrapper = styled.div`
	width: 100%;
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: 300;
	line-height: 2;
	letter-spacing: 0.56px;
	color: #546e7a;
`;

const StyledSeeAllBtn = styled(Button)`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 300;
	color: ${colors.secondaryGray};
`;

interface IDashboardDetailsBoxProps {
	children?: ReactNode;
	title: string;
	heightBox?: string;
	emptyListMessage?: string;
	seeAllOnClick?: (v: boolean) => void;
	titleAdditionalText?: string | number;
}

const DashboardDetailsBox = ({
	heightBox = "auto",
	children,
	title,
	titleAdditionalText,
	emptyListMessage,
	seeAllOnClick,
}: IDashboardDetailsBoxProps) => {
	const { t } = useTranslation("ui");

	const seeAllAction = () => {
		if (!!seeAllOnClick) {
			seeAllOnClick(true);
		}
	};

	return (
		<Wrapper height={heightBox}>
			<TitleWrapper>
				<Title>
					{title} {titleAdditionalText && <strong>({titleAdditionalText})</strong>}
				</Title>

				{!emptyListMessage && !!seeAllOnClick && (
					<StyledSeeAllBtn onClick={seeAllAction}>{t("DASHBOARD_DETAILS_BOX_SEE_DETAILS_BTN")}</StyledSeeAllBtn>
				)}
			</TitleWrapper>

			<StyledDivider />

			{emptyListMessage ? (
				<EmptyWrapper>{emptyListMessage}</EmptyWrapper>
			) : (
				children && <InnerWrapper>{children}</InnerWrapper>
			)}
		</Wrapper>
	);
};

export default DashboardDetailsBox;
