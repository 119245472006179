import { FormControlLabel, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

export const MenuElem = styled.div<{ active?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.71;
	color: ${colors.mainFontColor};
	cursor: pointer;
	padding: 0 10px 10px;
	flex-grow: 1;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	${props =>
		props.active &&
		css`
			color: ${colors.sunShade};

			&::after {
				content: "";
				position: absolute;
				height: 1px;
				width: 100%;
				background-color: ${colors.sunShade};
				bottom: -1px;
				left: 0;
			}
		`}
`;

export const SwitchContainer = styled(Grid)`
	justify-content: flex-end;
	cursor: pointer;
	height: fit-content;
	width: fit-content;
	position: relative;
	top: -6px;
	font-family: ${RobotoFontFamily};
`;

export const CategoriesFilters = styled.div`
	background-color: #b0bec51a;
	padding: 10px 20px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
`;

export const StyledArrow = styled(KeyboardArrowDownIcon)<{ $isOpened: boolean }>`
	cursor: pointer;
	color: ${colors.mainFontColor};
	margin-left: 4px;

	${props =>
		props.$isOpened &&
		css`
			transform: rotate(180deg);
		`}
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;

	.MuiIconButton-label,
	.MuiFormControlLabel-label {
		color: #546e7a;
	}

	.MuiFormControlLabel-label {
		font-size: 12px;
	}
`;

export const Header = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 22px;
	font-weight: 600;
	line-height: 1.14;
	color: ${colors.mainFontColor};
	margin-left: 5px;
	margin-bottom: 20px;
`;
