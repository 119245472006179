import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
	IConfigsLanguages,
	IConfigsLocalhostEarnings,
	IPrivateConfig,
	TConfigsCityIId,
	TConfigsCountryIId,
} from "data/configs/types";

import configsServices from "services/api/configs";

import { handleRejectValues } from "../utils/handleRejectValues";

type TStatus = "idle" | "loading" | "succeeded" | "failed";
type TError = boolean | null;

export interface IConfigsState {
	data: {
		countries?: TConfigsCountryIId[];
		cities?: TConfigsCityIId[];
		languages?: IConfigsLanguages;
		earnings?: IConfigsLocalhostEarnings;
		private?: IPrivateConfig[];
	};
	statuses: {
		all: TStatus;
		countries: TStatus;
		cities: TStatus;
		private: TStatus;
	};
	errors: {
		all: TError;
		countries: TError;
		cities: TError;
		private: TError;
	};
}

const initialState: IConfigsState = {
	data: {},
	statuses: {
		all: "idle",
		countries: "idle",
		cities: "idle",
		private: "idle",
	},
	errors: {
		all: null,
		countries: null,
		cities: null,
		private: null,
	},
};

export const fetchConfigsAll = createAsyncThunk<any>("configs/all/fetch", async (_, thunkAPI) => {
	try {
		const configs = await configsServices.getAllConfig();

		return configs;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

export const fetchConfigsCountries = createAsyncThunk<any>("configs/countries/fetch", async (_, thunkAPI) => {
	try {
		const countries = await configsServices.getCountries();

		return countries;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

export const fetchConfigsCities = createAsyncThunk<any>("configs/cities/fetch", async (_, thunkAPI) => {
	try {
		const cities = await configsServices.getCities();

		return cities;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

export const fetchConfigsPrivate = handleRejectValues("configs/private/fetch", configsServices.getPrivateConfig);

const { actions, reducer } = createSlice({
	name: "configs",
	initialState,
	reducers: {
		reset() {
			return initialState;
		},
	},
	extraReducers: {
		[fetchConfigsAll.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			if (!!action.payload?.countries) {
				state.data.countries = action.payload.countries;
			}

			if (!!action.payload?.cities) {
				state.data.cities = action.payload.cities;
			}

			if (!!action.payload?.languages) {
				state.data.languages = action.payload.languages;
			}

			if (!!action.payload?.localhost_earnings) {
				state.data.earnings = action.payload.localhost_earnings;
			}

			state.statuses.all = "succeeded";
			state.errors.all = false;
		},
		[fetchConfigsAll.pending.toString()]: state => {
			state.statuses.all = "loading";
			state.errors.all = null;
		},
		[fetchConfigsAll.rejected.toString()]: state => {
			state.statuses.all = "failed";
			state.errors.all = true;
		},

		[fetchConfigsCountries.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.countries = action.payload;

			state.statuses.countries = "succeeded";
			state.errors.countries = false;
		},
		[fetchConfigsCountries.pending.toString()]: state => {
			state.statuses.countries = "loading";
			state.errors.countries = null;
		},
		[fetchConfigsCountries.rejected.toString()]: state => {
			state.statuses.countries = "failed";
			state.errors.countries = true;
		},

		[fetchConfigsCities.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.cities = action.payload;

			state.statuses.cities = "succeeded";
			state.errors.cities = false;
		},
		[fetchConfigsCities.pending.toString()]: state => {
			state.statuses.cities = "loading";
			state.errors.cities = null;
		},
		[fetchConfigsCities.rejected.toString()]: state => {
			state.statuses.cities = "failed";
			state.errors.cities = true;
		},

		[fetchConfigsPrivate.fulfilled.toString()]: (state, action: PayloadAction<IPrivateConfig[]>) => {
			state.data.private = action.payload;

			state.statuses.private = "succeeded";
			state.errors.private = false;
		},
		[fetchConfigsPrivate.pending.toString()]: state => {
			state.statuses.private = "loading";
			state.errors.private = null;
		},
		[fetchConfigsPrivate.rejected.toString()]: state => {
			state.statuses.private = "failed";
			state.errors.private = true;
		},
	},
});

export const configsActions = actions;

export default reducer;
