import { Box, FormHelperText, LinearProgress } from "@material-ui/core";
import { Field } from "formik";
import { lazy, ReactNode, Suspense, useState } from "react";

import useTranslation from "hooks/useTranslation";

import { IPhoneInputUI } from "ui/forms/PhoneInput";

export interface IPhoneInputField
	extends Omit<IPhoneInputUI, "value" | "specialLabel" | "onChange" | "onBlur" | "onFocus"> {
	name: string;
	className?: string;
	required?: boolean;
	label?: string;
	extraAdornment?: ReactNode;
}

const PhoneInputUI = lazy(() => import("ui/forms/PhoneInput"));

const PhoneInputField = ({ name, className, required, label, extraAdornment, ...restProps }: IPhoneInputField) => {
	const { t } = useTranslation("common");

	const [countrySelected, setCountrySelected] = useState<string | false | null>(null);
	const [resetValue, setResetValue] = useState<string>("");

	return (
		<Field name={name}>
			{({ field, form: { setFieldValue, setFieldTouched, setFieldError }, meta }) => {
				const hasError = meta.touched && !!meta.error;

				const localOnChange = (value: string, countryData: { dialCode?: string }, _, formattedValue: string) => {
					if (!!countryData?.dialCode) {
						setCountrySelected(countryData.dialCode);

						setResetValue(formattedValue);
					} else {
						setCountrySelected(false);
					}

					if (value !== countryData?.dialCode) {
						if (countryData?.dialCode && countrySelected && countrySelected !== countryData.dialCode) {
							setFieldValue(field.name, "");

							setResetValue(`+${countryData.dialCode}`);
						} else {
							setFieldValue(field.name, formattedValue.replace(/\s/g, ""));
						}
					} else {
						if (!!countrySelected) {
							setFieldValue(field.name, "");
						}
					}
				};

				const localOnFocus = () => {
					if (!countrySelected) {
						setCountrySelected(false);
					}
				};

				const localOnBlur = () => {
					setFieldTouched(field.name, true);
				};

				return (
					<Box
						className={className}
						marginBottom={restProps.materialStandardVariant ? "10px" : undefined}
						position={extraAdornment ? "relative" : undefined}
					>
						<Suspense fallback={<LinearProgress />}>
							<PhoneInputUI
								inputProps={{ name, required }}
								value={field.value || resetValue}
								onChange={localOnChange}
								onBlur={localOnBlur}
								onFocus={localOnFocus}
								specialLabel={
									!!field.value || resetValue
										? `${label || t("FORMS.PHONE_INPUT_FIELD.LABEL")}${required ? " *" : ""}`
										: ""
								}
								placeholder={`${label || t("FORMS.PHONE_INPUT_FIELD.LABEL")}${required ? " *" : ""}`}
								withError={countrySelected === false || hasError}
								{...restProps}
							/>

							{(countrySelected === false || hasError) && (
								<Box marginLeft={restProps.materialStandardVariant ? undefined : "14px"} marginRight="14px">
									<FormHelperText error>
										{countrySelected === false
											? t("FORMS.PHONE_INPUT_FIELD.VALIDATION.NO_COUNTRY_SELECTED")
											: meta.error}
									</FormHelperText>
								</Box>
							)}

							{extraAdornment}
						</Suspense>
					</Box>
				);
			}}
		</Field>
	);
};

export default PhoneInputField;
