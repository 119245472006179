import { RoutesPathsProp } from "routing/types";

type TParam = string | number | boolean | null | undefined | object;

interface IParams extends Record<string, TParam> {}

const fillRoute = (routeName: RoutesPathsProp, params: IParams = {}): string => {
	let outputRoute = routeName.toString();

	Object.keys(params).forEach(key => {
		const clearParam = null === params[key];

		const regex = new RegExp(`(/?)(:${key})\\??`, "gi");
		if (typeof params[key] !== "undefined") {
			outputRoute = outputRoute.replace(regex, clearParam ? "" : "$1" + (params[key] ?? "").toString());
		}
	});

	// @todo:future - validation of the passed properties
	// const missingTags = routeName.match(/(\/?):([a-zA-Z])\??/gi);
	// if (Array.isArray(missingTags)) {
	//   const missingAdditional = missingTags.filter((tag) => tag[tag.length - 1] === "?");
	//   if (missingAdditional?.length) {
	//     missingAdditional.forEach((additional) => {
	//       outputRoute.replace(additional, "");
	//     });
	//   }
	//
	//   const missingImportant = missingTags.filter((tag) => tag[tag.length - 1] !== "?");
	//   console.log(missingImportant);
	//
	//   if (missingImportant?.length) {
	//     throw new Error(
	//       `Missing requirement for ${routeName} tags: ` +
	//       missingImportant
	//         .map((tag) => tag.match(/\/?:([a-zA-Z])/i))
	//         .map((tag) => tag?.length === 2 ? tag[1] : tag)
	//         .join(", "),
	//     );
	//   }
	// }

	if (!!params?.search && typeof params.search === "object") {
		outputRoute +=
			"?" +
			Object.keys(params.search)
				.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(!!params.search ? params.search[key] : ""))
				.join("&");
	}

	return outputRoute;
};

export default fillRoute;
