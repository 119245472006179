import {
	Box,
	Button,
	CardActions,
	createStyles,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import StarBorder from "@material-ui/icons/StarBorder";
import { memo, ReactNode } from "react";
import slugify from "slugify";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { TCurrency } from "data/backoffice/financials/types";
import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import useCity from "store/hooks/useCity";
import useConfigEarnings from "store/hooks/useConfigEarnings";

import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import BinipoolAvatar from "ui/BinipoolAvatar";

import colors from "styles/colors";
import { RobotoFontFamily, StyledDivider, StyledLink } from "styles/common";
import media from "styles/media";

import ConfirmDialog from "../../dialogs/ConfirmDialog";

const ImgContent = styled(Box)`
	overflow: hidden;
	border-radius: 4px 4px 0 0;
`;

const Photo = styled(Box)<{ $backgroundUrl: string; $heightValue: number }>`
	position: relative;
	background-image: url(${props => props.$backgroundUrl});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px 4px 0 0;
	width: 100%;
	height: ${props => props.$heightValue}px;
	transition: all 0.5s linear;

	&:hover {
		transform: scale(1.1);
	}
`;

const RecordLink = styled(StyledLink)`
	width: 100%;
`;

const StyledRecordBinipoolButton = styled(Button)`
	height: 24px;
	min-width: 112px;
	font-size: 10px;
	line-height: 1.43;
	letter-spacing: 0.1px;
`;

const CircleAvatarContent = styled(Box)`
	position: absolute;
	bottom: -40px;
	right: 20px;
`;

const MainTitle = styled.div`
	padding-right: 15px;
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: bold;
	line-height: 1.06;
	letter-spacing: 0.56px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;

	${media.desktop`
    font-size: 16px;
  `};
`;

const StrongNoWrap = styled.strong`
	white-space: nowrap;
`;

const StyledCardStatusContent = styled(CardActions)`
	box-sizing: border-box;
	padding: 10px 20px;
	background: #f2f2f2;
	width: 100%;
	display: flex;
	justify-content: space-between;
	${media.phone`
    padding: 10px;
  `};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recordContent: {
			boxShadow: theme.shadows[4],
			borderRadius: 4,
			background: theme.palette.common.white,
			color: theme.palette.text.homePageFont,
		},
		cityHoursText: {
			[theme.breakpoints.down("sm")]: {
				fontSize: 10,
			},
			fontSize: 12,
			color: colors.secondaryGray,
			lineHeight: "20px",
		},
		priceRatingText: {
			[theme.breakpoints.down("sm")]: {
				fontSize: 10,
			},
			fontSize: 12,
			lineHeight: "20px",
		},
	}),
);

interface ILinkOrFragment {
	children: ReactNode;
	link: string | null;
}

const LinkOrFragment = ({ children, link }: ILinkOrFragment) =>
	!!link ? (
		<RecordLink to={link} $withoutHoverUnderline>
			{children}
		</RecordLink>
	) : (
		<>{children}</>
	);

interface IRecordBinipoolProps {
	id?: string;
	city: string;
	hours: number;
	duration: string | null;
	imgUrl: string;
	rating: {
		totalRating: number;
		nbReviews: number;
	};
	title: string;
	currency?: TCurrency;
	withoutButton?: boolean;
	withEarnings?: boolean;
	withOptions?: boolean;
	onLeaveBinipool?(): void;
	itinerary?: string;
}

const RecordBinipool = memo(
	({
		id,
		city,
		hours,
		duration,
		imgUrl,
		title,
		withoutButton,
		withEarnings,
		withOptions,
		onLeaveBinipool,
		currency,
		rating: { totalRating, nbReviews },
		itinerary,
	}: IRecordBinipoolProps) => {
		const { t } = useTranslation();

		const theme = useTheme();

		const classes = useStyles();

		const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

		const { cities } = useCity();
		const { earnings } = useConfigEarnings();

		const link = !!id ? fillRoute(paths.SEARCH_EXPERIENCES, { title: slugify(title.toLowerCase()), id }) : null;

		const foundCity = cities?.find(elem => elem.name === city);

		const cityEarnings =
			foundCity?.binipool_earnings_base?.NORMAL || foundCity?.country?.binipool_earnings_base?.NORMAL || 0;

		const minCityEarnings =
			typeof earnings?.hour_factor.NORMAL === "number"
				? cityEarnings * (1 + earnings.hour_factor.NORMAL * (hours - 1))
				: 0;
		const maxCityEarnings =
			typeof earnings?.max_factor.NORMAL === "number" ? minCityEarnings * earnings.max_factor.NORMAL : 0;

		const allSections = withEarnings && !withoutButton;

		return (
			<Box
				className={classes.recordContent}
				width={isSmallScreen ? "100%" : 434}
				height={isSmallScreen ? (allSections ? 580 : "auto") : allSections ? 680 : "auto"}
			>
				<LinkOrFragment link={link}>
					<Box position="relative">
						<ImgContent>
							<Photo $heightValue={isSmallScreen ? 400 : 480} $backgroundUrl={imgUrl || CoverImg} />
						</ImgContent>

						<CircleAvatarContent>
							<BinipoolAvatar />
						</CircleAvatarContent>
					</Box>
				</LinkOrFragment>

				<Box p={10}>
					<LinkOrFragment link={link}>
						<Box height={isSmallScreen ? "80px" : "100px"}>
							<Box display="flex" alignItems="center" justifyContent="flex-start">
								<Box width="80%">
									<MainTitle>{title?.length > 50 ? title.slice(0, 50) + "..." : title}</MainTitle>
								</Box>
							</Box>

							<Box display="flex">
								<Typography className={classes.cityHoursText}>{city}</Typography>&nbsp;&nbsp;|&nbsp;&nbsp;
								<Typography className={classes.cityHoursText}>{duration}</Typography>
							</Box>
						</Box>
					</LinkOrFragment>

					<StyledDivider />

					{(withEarnings || nbReviews > 0) && (
						<Box display="flex" alignItems="center" justifyContent="space-between" gridGap="5px" mt={4}>
							{withEarnings && minCityEarnings > 0 && (
								<Typography className={classes.priceRatingText}>
									{t("EXPERIENCE.BINIPOOL_RECORD.EXPECTED_EARNINGS")}{" "}
									<StrongNoWrap>
										{prices(Math.floor(minCityEarnings), currency, undefined, 0)}

										{minCityEarnings !== maxCityEarnings && (
											<>
												&nbsp;-&nbsp;
												{prices(Math.floor(maxCityEarnings), undefined, undefined, 0)}
											</>
										)}
									</StrongNoWrap>
								</Typography>
							)}

							{nbReviews > 0 && (
								<Box display="flex" alignItems="center" justifyContent="center">
									<StarBorder style={{ width: 15, height: 15, color: theme.palette.primary.main, marginRight: 5 }} />

									<Typography className={classes.priceRatingText}>
										<strong>{totalRating}</strong>&nbsp;({nbReviews})
									</Typography>
								</Box>
							)}
						</Box>
					)}

					{!withoutButton && (
						<Box display="flex" justifyContent="flex-end" mt={4}>
							<StyledLink
								to={fillRoute(paths.ONBOARDING, {
									step: EOnboardingSteps.Binipools,
									search: { type: "binipool", selectOnlyBinipools: true },
								})}
								$withoutHoverUnderline
							>
								<StyledRecordBinipoolButton variant="outlined">
									{t("EXPERIENCE.BINIPOOL_RECORD.BINIPOOL_JOIN_BTN")}
								</StyledRecordBinipoolButton>
							</StyledLink>
						</Box>
					)}
				</Box>

				{withOptions && (
					<StyledCardStatusContent>
						<a href={itinerary} target="_blank" rel="noreferrer">
							<StyledRecordBinipoolButton variant="outlined" startIcon={<DirectionsWalkIcon />}>
								{t("EXPERIENCE.BINIPOOL_RECORD.BINIPOOL_ITINERARY_BTN")}
							</StyledRecordBinipoolButton>
						</a>

						<Box width="100%" display="flex" justifyContent="flex-end">
							{!!onLeaveBinipool && (
								<ConfirmDialog
									onConfirm={() => onLeaveBinipool()}
									title={t("EXPERIENCE.BINIPOOL_RECORD.BINIPOOL_LEAVE_CONFIRMATION")}
								>
									<StyledRecordBinipoolButton variant="outlined" startIcon={<ClearIcon />}>
										{t("EXPERIENCE.BINIPOOL_RECORD.BINIPOOL_LEAVE_BTN")}
									</StyledRecordBinipoolButton>
								</ConfirmDialog>
							)}
						</Box>
					</StyledCardStatusContent>
				)}
			</Box>
		);
	},
);

export default RecordBinipool;
