import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import i18n, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { localStorageKeys } from "utils/constants";

// DE
import translation_DE from "./locales/de/common.json";
import translation_DE_SEO from "./locales/de/seo.json";
import translation_DE_UI from "./locales/de/ui.json";
// EN (with backoffice)
import translation_EN_BO from "./locales/en/bo.json";
import translation_EN from "./locales/en/common.json";
import translation_EN_SEO from "./locales/en/seo.json";
import translation_EN_UI from "./locales/en/ui.json";
// ES
import translation_ES from "./locales/es/common.json";
import translation_ES_SEO from "./locales/es/seo.json";
import translation_ES_UI from "./locales/es/ui.json";
// FR
import translation_FR from "./locales/fr/common.json";
import translation_FR_SEO from "./locales/fr/seo.json";
import translation_FR_UI from "./locales/fr/ui.json";
// IT
import translation_IT from "./locales/it/common.json";
import translation_IT_SEO from "./locales/it/seo.json";
import translation_IT_UI from "./locales/it/ui.json";

interface IAppLanguage {
	[language: string]: {
		resources: Resource;
		locale?: Locale;
		available?: boolean;
	};
}

const appLanguages: IAppLanguage = {
	en: {
		available: true,
		resources: {
			common: translation_EN,
			bo: translation_EN_BO,
			ui: translation_EN_UI,
			seo: translation_EN_SEO,
		},
		locale: en,
	},
	de: {
		available: true,
		resources: {
			common: translation_DE,
			ui: translation_DE_UI,
			seo: translation_DE_SEO,
		},
		locale: de,
	},
	es: {
		available: false, // TODO: enable it when translations will be ready
		resources: {
			common: translation_ES,
			ui: translation_ES_UI,
			seo: translation_ES_SEO,
		},
		locale: es,
	},
	fr: {
		available: false, // TODO: enable it when translations will be ready
		resources: {
			common: translation_FR,
			ui: translation_FR_UI,
			seo: translation_FR_SEO,
		},
		locale: fr,
	},
	it: {
		available: false, // TODO: enable it when translations will be ready
		resources: {
			common: translation_IT,
			ui: translation_IT_UI,
			seo: translation_IT_SEO,
		},
		locale: it,
	},
};

const getLanguageResources = () =>
	Object.entries(appLanguages).reduce((acc, [language, item]) => {
		acc[language] = item.resources;

		return acc;
	}, {});

const getCurrentLocale = () => appLanguages[i18n.language]?.locale || null;

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: getLanguageResources(),
		lng: localStorage.getItem(localStorageKeys.languages.selectedLanguage.key) || "en",
		fallbackLng: "en",
		debug: false, // process.env.NODE_ENV !== "production",
		interpolation: {
			escapeValue: false,
		},
		keySeparator: false,
		react: {
			transKeepBasicHtmlNodesFor: ["br", "strong", "i", "li", "ul", "ol", "p", "b", "u", "small", "h3"],
			defaultTransParent: "p",
		},
		// Change these options will require change types in whole app! See src/@types/i18next.d.ts
		returnNull: false,
		returnDetails: false,
		returnObjects: false,
		//
	});

export { appLanguages, getCurrentLocale };
export default i18n;
