import { Box, createStyles, Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { cloneElement, ReactElement } from "react";
import styled from "styled-components";

import media from "styles/media";

type StyleProps = {
	image: string;
};

interface AppWithImageWrapperProps extends StyleProps {
	down?: Breakpoint | number;
	children: ReactElement;
	teaserElem?: ReactElement;
	headerElem?: ReactElement;
}

const useStyles = makeStyles<Theme, { styles: StyleProps }>(() =>
	createStyles({
		image: {
			backgroundImage: ({ styles }) => `url(${styles.image})`,
			width: "100%",
			minHeight: "100vh",
			backgroundSize: "cover",
			backgroundPosition: "bottom",
		},
	}),
);

const left = 48;

const LeftGrid = styled(Grid)`
	max-width: ${left}%;
	flex-basis: ${left}%;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;

	${media.tabletMaterial`
		max-width: ${left * 2}%;
		flex-basis: ${left * 2}%;
  `}

	${media.tablet`
    max-width: 100%;
    flex-basis: 100%;
  `}
`;

const RightGrid = styled(Grid)`
	max-width: ${100 - left}%;
	flex-basis: ${100 - left}%;
	text-align: center;
`;

const LeftContent = styled(Box)`
	max-width: 535px;
	width: 100%;
	padding: 55px 75px;
	position: relative;

	${media.tablet`
    padding: 25px 15px;
  `}
`;

const AppWithImageWrapper = ({ children, teaserElem, headerElem, ...props }: AppWithImageWrapperProps) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down(props?.down ?? "sm"));

	const classes = useStyles({ styles: props });

	return (
		<Grid container justifyContent="center">
			<LeftGrid item xs={12}>
				{headerElem}

				<LeftContent>{cloneElement(children, { fullPage: isSmallScreen })}</LeftContent>
			</LeftGrid>

			{!isSmallScreen && (
				<RightGrid item xs={12} className={classes.image}>
					{teaserElem}
				</RightGrid>
			)}
		</Grid>
	);
};

export default AppWithImageWrapper;
