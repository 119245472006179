import { Box, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsServices from "services/api/bookings";

import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import Breadcrumbs from "ui/Breadcrumbs";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";

const Header = styled.h1`
	font-family: ${MontserratFontFamily};
	font-size: 36px;
	font-weight: 700;
	line-height: 39px;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};
`;

const SubHeader = styled(Header)`
	font-size: 20px;
	font-weight: normal;
	letter-spacing: 0.04px;
	margin-bottom: 80px;
`;

const BookingConfirmPage = () => {
	const { t } = useTranslation();
	const { bid, heldCode } = useParams<{ bid: string; heldCode: string }>();
	const [loader, setLoader] = useState<boolean>(true);
	const { handleAndNotify } = useErrors();

	useEffect(() => {
		if (!!bid && !!heldCode) {
			const postHeldCode = async () => {
				try {
					await bookingsServices.postHeldCode(bid, heldCode);

					setLoader(false);
				} catch (e) {
					handleAndNotify(e);
				}
			};

			postHeldCode();
		}
		// eslint-disable-next-line
	}, [bid, heldCode]);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs />

				<Box marginBottom={50}>
					{loader ? (
						<Loader />
					) : (
						<>
							<Header>{t("CONFIRM_BOOKING.HEADER")}</Header>

							<SubHeader as="h2">{t("CONFIRM_BOOKING.SUB_HEADER")}</SubHeader>

							<Button variant="contained" color="primary" size="large">
								<StyledLink to={paths.MY_BOOKINGS} $withoutHoverUnderline>
									{t("CONFIRM_BOOKING.CTA")}
								</StyledLink>
							</Button>
						</>
					)}
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default BookingConfirmPage;
