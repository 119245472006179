import { paths } from "routing/paths";

export const configFooter = {
	SUPPORT: [
		{
			id: "CANCELLATION_POLICY",
			link: paths.HELP_CANCELLATION_POLICY,
		},
		{
			id: "CONTACT_US",
			link: paths.CONTACT,
		},
		{
			id: "LEGAL_TERMS",
			link: paths.HELP_LEGAL_TERMS,
		},
		{
			id: "DISCLAIMER_IMPRESSUM",
			link: paths.HELP_DISCLAIMER_IMPRESSUM,
		},

		// {
		//   id: "SATISFACTION_GUARANTEE",
		//   title: "Satisfaction Guarantee",
		//   link: "",
		// },
	],
	HOST: [
		{
			id: "HOST_AN_EXPERIENCE",
			link: paths.UNIQUE_EXPERIENCES,
			showModal: true,
		},
		{
			id: "CO_HOST_A_BINIPOOL_EXPERIENCE",
			link: paths.BINIPOOL_CONCEPT,
		},
		{
			id: "VIRTUAL_EVENTS",
			link: "https://www.localbini-go.com",
			direct: true,
		},
		{
			id: "FAQS",
			link: "https://support.localbini.com",
			direct: true,
		},
	],
	COMPANY: [
		{
			id: "ABOUT_US",
			link: paths.HELP_ABOUT_US,
		},
		{
			id: "QUALITY_CONTROL",
			link: paths.HELP_QUALITY_CONTROL,
		},
		{
			id: "CAREER",
			// @todo:fix - environment file -> getSubdomain
			link: "https://career.localbini.com",
			direct: true,
		},
		{
			id: "PRESS",
			link: paths.HELP_PRESS,
		},
		{
			id: "INVESTORS",
			link: paths.HELP_INVESTORS,
		},
	],
	PARTNERS: [
		{
			id: "BECOME_A_DISTRIBUTION_PARTNER",
			link: paths.HELP_DISTRIBUTION_PARTNERS,
		},
		{
			id: "VIEW_SOME_OF_KEY_PARTNERS",
			link: paths.HELP_KEY_PARTNERS,
		},
	],
};
