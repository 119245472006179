import { memo } from "react";
import styled from "styled-components";

import ExpediaImg from "assets/images/binipoolConcept/expedia.svg";
import GetYourGuideImg from "assets/images/binipoolConcept/get_your_guide.svg";
import MusementImg from "assets/images/binipoolConcept/musement.svg";
import TripadvisorImg from "assets/images/binipoolConcept/tripadvisor.svg";

import useTranslation from "hooks/useTranslation";

import { SmallerTitle } from "ui/CommonParagraph";

import { Container } from "styles/common";
import media from "styles/media";

import { WrapperSection } from "../../index";

const MainContent = styled.div`
	margin: 4.375rem auto 0 auto;
	width: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	${media.desktop`
      width: 100%;
      flex-direction: column;
  `};
`;

const StyledImg = styled.img`
	margin-bottom: 0;

	${media.desktop`
     margin-bottom: 2.125rem;
  `};
`;

const ExperiencesFeaturedOn = memo(() => {
	const { t } = useTranslation();

	return (
		<WrapperSection>
			<Container>
				<SmallerTitle textAlignCenter>{t("BINIPOOL_CONCEPT.EXP_FEATURED_ON_TITLE")}</SmallerTitle>

				<MainContent>
					<StyledImg
						src={TripadvisorImg}
						title="LocalBini - experiences featured on - Tripadvisor"
						alt="LocalBini - experiences featured on - Tripadvisor"
					/>

					<StyledImg
						src={GetYourGuideImg}
						title="LocalBini - experiences featured on - Get tour guide"
						alt="LocalBini - experiences featured on - Get tour guide"
					/>

					<StyledImg
						src={ExpediaImg}
						title="LocalBini - experiences featured on - Expedia"
						alt="LocalBini - experiences featured on - Expedia"
					/>

					<StyledImg
						src={MusementImg}
						title="LocalBini - experiences featured on - Musement"
						alt="LocalBini - experiences featured on - Musement"
					/>
				</MainContent>
			</Container>
		</WrapperSection>
	);
});

export default ExperiencesFeaturedOn;
