import { Box, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ISuperbookingIId } from "data/backoffice/bookings/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";

import { dateParseAndFormat } from "utils/dates";
import fillRoute from "utils/routes";

import DashboardDetailsBox from "components/dashboard/DashboardDetailsBox";
import DashboardItemList from "components/dashboard/DashboardItemList";

import CircularLoader from "ui/loaders/CircularLoader";

import colors from "styles/colors";
import { StyledLink } from "styles/common";

import { parseStatus } from "../../myBookings/utils";

const UpcomingBookingsSection = () => {
	const { t } = useTranslation();

	const history = useHistory();

	const { handleAndNotify } = useErrors();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [results, setResults] = useState<{
		data: ISuperbookingIId[];
		count: number;
	}>({
		data: [],
		count: 0,
	});

	useEffect(() => {
		const fetchSuperbookings = async () => {
			try {
				const superBookingsList = await bookingsService.fetchSuperBookingsBackoffice({
					limit: 5,
					date_time_from: dateParseAndFormat(new Date(Date.now() - 2 * 1000 * 60 * 60)),
					order: "asc",
					sort: "utc_date_time",
					offset: 0,
				});

				if (superBookingsList) {
					setResults(superBookingsList);
				}
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsLoading(false);
			}
		};

		fetchSuperbookings();
		// eslint-disable-next-line
	}, []);

	return (
		<Box borderRadius="4px" bgcolor={colors.white} padding="20px" boxShadow="0 0 40px 0 rgba(30, 30, 30, 0.12)">
			<DashboardDetailsBox
				title={t("DASHBOARD.SECTIONS.BOOKINGS.TITLE")}
				titleAdditionalText={results.count > 0 ? results.count : undefined}
				emptyListMessage={
					results.data.length > 0 || isLoading ? undefined : t("DASHBOARD.SECTIONS.BOOKINGS.EMPTY_LIST_TITLE")
				}
				seeAllOnClick={results.count > 5 ? () => history.push(paths.MY_BOOKINGS) : undefined}
			>
				{isLoading ? (
					<Box position="relative" height="450px">
						<CircularLoader />
					</Box>
				) : (
					results.data.map(v => (
						<DashboardItemList
							key={`booking-${v.id}`}
							title={v?.exp_title || ""}
							expId={v.eid}
							localhostName={v.localhost_details?.first_name || t("YOUR_LOCALHOST_LABEL")}
							localhostId={v.localhost_id}
							avatarUrl={v.localhost_details?.photo_url || undefined}
							date={v.date_time}
							status={
								<Box display="inline" color={parseStatus(v.status).color}>
									{parseStatus(v.status).label}
								</Box>
							}
							seeDetailsOnClick={() => history.push(fillRoute(paths.MY_BOOKINGS, { search: { sbk: v.id } }))}
						/>
					))
				)}
			</DashboardDetailsBox>

			<Box textAlign="center" marginTop="20px">
				<StyledLink to={paths.MY_BOOKINGS} $withoutHoverUnderline>
					<Button variant="contained" color="primary">
						{t("DASHBOARD.SECTIONS.BOOKINGS.LINK")}
					</Button>
				</StyledLink>
			</Box>
		</Box>
	);
};

export default UpcomingBookingsSection;
