import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import WelcomeOnPage from "components/dashboard/DashboardWelcome";
import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";

import { Container } from "styles/common";

import { WelcomeContent } from "../shared.styled";
import MyBookingsLocal from "./components/MyBookingsLocal";
import MyBookingsTraveler from "./components/MyBookingsTraveler";

const AccountMyBookingsPage = () => {
	const { t } = useTranslation();

	const userData = useAppSelector(selectUser);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.MY_BOOKINGS_LABEL")} />

				<WelcomeContent>
					<WelcomeOnPage
						firstName={userData?.public_profile?.first_name || userData?.profile_draft?.first_name || ""}
						avatarUrl={
							userData?.public_profile?.pictures?.profile || userData?.profile_draft?.pictures?.profile || undefined
						}
					/>
				</WelcomeContent>

				{(
					[EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT, EUserType.LOCALHOST] as (
						| EUserType
						| undefined
					)[]
				).includes(userData?.userType) && <MyBookingsLocal />}

				{userData?.userType === "TRAVELER" && <MyBookingsTraveler />}
			</Container>
		</AppWrapper>
	);
};

export default AccountMyBookingsPage;
