import { InputAdornment } from "@material-ui/core";
import { MuiTextFieldProps } from "newer-pickers/_shared/PureDateInput";

import { StyledCalendarIcon } from "./shared.styled";

const commonInputProps: MuiTextFieldProps = {
	variant: "standard",
	helperText: undefined,
	InputLabelProps: {
		shrink: true,
	},
	inputProps: {
		placeholder: undefined,
	},
	InputProps: {
		disableUnderline: true,
		startAdornment: (
			<InputAdornment position="start">
				<StyledCalendarIcon />
			</InputAdornment>
		),
	},
};

export { commonInputProps };
