import tripImage from "assets/images/background/trip_photo.jpg";

import RegisterView from "components/auth/RegisterView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

import { LanguageSelectorOnboarding, TeaserOnboarding } from "../index";
import { LanguageSelectorContainer } from "../shared.styled";

const AuthOnboardingRegisterPage = () => (
	<AppWithImageWrapper
		image={tripImage}
		teaserElem={<TeaserOnboarding />}
		headerElem={
			<LanguageSelectorContainer>
				<LanguageSelectorOnboarding />
			</LanguageSelectorContainer>
		}
	>
		<RegisterView onboarding />
	</AppWithImageWrapper>
);

export default AuthOnboardingRegisterPage;
