import { Card, CardActionArea, CardMedia, FormControl } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

import { desktopWidth, mobileWidth, spaceDefault, StepContainer, tabletWidth } from "../../shared.styled";

export const SmallerColumn = styled.div`
	max-width: 460px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`;

export const CheckboxContainer = styled(FormControl)`
	:global(.MuiFormControlLabel-label) {
		font-size: 12px;
	}

	:global(.MuiFormGroup-root) {
		justify-content: left;
		margin-bottom: ${spaceDefault * 2}px;
	}
`;

export const CardsContainer = styled(StepContainer)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	max-height: 450px;
	overflow-y: scroll;
	padding: 10px 10px 0 10px;

	&::after {
		content: "";
		display: block;
		height: 100px;
		width: 100%;
		position: sticky;
		bottom: -5px;
		box-shadow: inset 0 -20px 25px 0 ${colors.white};

		@media screen and (max-width: ${desktopWidth}) {
			height: 50px;
		}

		@media screen and (max-width: ${tabletWidth}) {
			display: none;
		}
	}

	@media screen and (max-width: ${desktopWidth}) {
		margin-bottom: 0;
		max-height: 300px;
	}

	@media screen and (max-width: ${tabletWidth}) {
		max-height: none;
		overflow-y: hidden;
	}
`;

export const CardTitle = styled.h2`
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	line-height: 24px;
	margin: 0;
`;

export const CardLabel = styled.p`
	line-height: 16px;
	font-size: 12px;
	color: #999;
	margin: 0;
`;

export const CardBottomInfo = styled.div`
	display: flex;
	margin-top: ${spaceDefault}px;
	padding-top: 12px;
	border-top: 1px solid #eee;
	justify-content: flex-end;
`;

export const CheckIconContainer = styled.div<{ active: boolean }>`
	position: absolute;
	display: none;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 140px;
	height: 140px;

	svg {
		width: 100%;
		height: 100%;
		color: ${colors.brightGreen};
	}

	${props => props.active && "display: flex"};
`;

export const StyledCardMedia = styled(CardMedia)`
	position: relative;
	height: 200px;
`;

export const StyledCard = styled(Card)<{ $active: boolean }>`
	width: calc(50% - 10px);
	margin-bottom: 20px;
	position: relative;

	${props =>
		props.$active &&
		css`
			:before {
				content: "";
				display: block;
				box-shadow: inset 0 0 0 2px ${colors.brightGreen};
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
			}
		`};

	@media screen and (max-width: ${mobileWidth}) {
		width: 100%;
	}
`;

export const StyledLink = styled(Link)`
	color: ${colors.mainFontColor};
`;

export const StyledCardActionArea = styled(CardActionArea)`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: normal;
	justify-content: space-between;
`;

export const DetailsButton = styled.span`
	line-height: 1.75;
	border-radius: 4px;
	text-transform: capitalize;
	border: 1px solid rgba(0, 0, 0, 0.23);
	padding: 5px 15px;
	font-weight: normal;
	cursor: pointer;
	font-family: ${RobotoFontFamily};
	position: relative;
	z-index: 1;
`;
