import { Box, Grid, InputLabel, Slider } from "@material-ui/core";
import { DateRange } from "newer-pickers";
import { useState } from "react";

import { EPromoCodesUsageStatus, EPromoCodesUsageType } from "data/promo-codes/types";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS } from "utils/dates";
import { createSelectFromEnum, omitInEnum } from "utils/enums";

import FilterSearch from "ui/FilterSearch";
import AutocompleteUI, { Option } from "ui/forms/Autocomplete";
import RangePicker from "ui/forms/date-time/RangePicker";

export interface ISelectedFilters {
	type: EPromoCodesUsageType | "";
	status: EPromoCodesUsageStatus | "";
	amountRange: number[] | null;
	created: DateRange<Date> | null[];
}

interface IFilterSearchMyReferralsProps {
	applySelectedFiltersAction: (v: ISelectedFilters) => void;
}

const FilterSearchMyReferrals = ({ applySelectedFiltersAction }: IFilterSearchMyReferralsProps) => {
	const { t, withTaggedKey } = useTranslation();

	const initialDates = [null, null];

	const initialAmountRange = [0, 100];

	const [selectedType, setSelectedType] = useState<EPromoCodesUsageType | "">("");
	const [selectedStatus, setSelectedStatus] = useState<EPromoCodesUsageStatus | "">("");
	const [amountRange, setAmountRange] = useState<number[] | null>(null);
	const [selectedDates, setSelectedDates] = useState<DateRange<Date> | null[]>(initialDates);

	const setDefaultValues = () => {
		setSelectedType("");
		setSelectedStatus("");
		setAmountRange(null);
		setSelectedDates(initialDates);
	};

	const onChangeType = (value: EPromoCodesUsageType | "") => setSelectedType(value);

	const onChangeStatus = (value: EPromoCodesUsageStatus | "") => setSelectedStatus(value);

	const onChangeDates = (value: DateRange<Date>) => {
		setSelectedDates(value);
	};

	const setFilters = (value: boolean) => {
		if (value) {
			applySelectedFiltersAction({
				type: selectedType,
				status: selectedStatus,
				amountRange,
				created: selectedDates,
			});
		}
	};

	return (
		<FilterSearch onClickApply={setFilters} onClickClear={setDefaultValues}>
			<Grid container spacing={10}>
				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<AutocompleteUI
						name="type"
						label={t("MY_REFERRALS.FILTERS.LABEL.TYPE")}
						options={createSelectFromEnum(
							omitInEnum(EPromoCodesUsageType, [EPromoCodesUsageType.BOOKING_DISCOUNT]),
							type => withTaggedKey("MY_REFERRALS.TYPE.{0}", [type]),
						)}
						textFieldProps={{ variant: "standard" }}
						onChange={v => onChangeType(((v as Option)?.value?.toString() as EPromoCodesUsageType) || "")}
						value={selectedType}
						filterSelectedOptions
					/>
				</Grid>

				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<AutocompleteUI
						name="status"
						label={t("MY_REFERRALS.FILTERS.LABEL.STATUS")}
						options={createSelectFromEnum(EPromoCodesUsageStatus, status =>
							withTaggedKey("MY_REFERRALS.STATUS.{0}", [status]),
						)}
						textFieldProps={{ variant: "standard" }}
						onChange={v => onChangeStatus(((v as Option)?.value?.toString() as EPromoCodesUsageStatus) || "")}
						value={selectedStatus}
						filterSelectedOptions
					/>
				</Grid>

				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<InputLabel shrink>{t("MY_REFERRALS.FILTERS.LABEL.VALUE_RANGE")}</InputLabel>

					<Grid container spacing={10}>
						<Grid item style={{ display: "flex", alignItems: "center" }}>
							<Box fontSize="10px">{t("COMMON.MIN")}</Box>
						</Grid>

						<Grid item xs>
							<Slider
								value={amountRange || initialAmountRange}
								onChange={(_, value) => setAmountRange(value as number[])}
								valueLabelDisplay="auto"
								aria-labelledby="amount-range-slider"
								min={initialAmountRange[0]}
								max={initialAmountRange[1]}
							/>
						</Grid>

						<Grid item style={{ display: "flex", alignItems: "center" }}>
							<Box fontSize="10px">{t("COMMON.MAX")}</Box>
						</Grid>
					</Grid>

					<Box textAlign="center" fontSize="10px" color="#fb8c00" position="relative" top="-10px">
						{amountRange ? amountRange[0] : initialAmountRange[0]} -{" "}
						{amountRange ? amountRange[1] : initialAmountRange[1]}
					</Box>
				</Grid>

				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<RangePicker
						labelStart={t("MY_REFERRALS.FILTERS.LABEL.DATE")}
						labelEnd=" "
						// @ts-ignore
						date={selectedDates}
						setDate={onChangeDates}
						inputDateFormat={DATE_FORMATS.DATE_FORMAT2}
						mask="__.__.____"
						colorOfInputBorder="rgba(0, 0, 0, 0.42)"
					/>
				</Grid>
			</Grid>
		</FilterSearch>
	);
};

export default FilterSearchMyReferrals;
