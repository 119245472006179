import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	ListItemText,
	Radio,
	RadioGroup,
} from "@material-ui/core";
import { RadioGroupProps } from "@material-ui/core/RadioGroup/RadioGroup";
import { Field } from "formik";
import { ReactNode } from "react";
import styled from "styled-components";

const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 0;
`;

const InputContainer = styled.div`
	display: flex;
	position: relative;
`;

const InlineLabel = styled.span`
	color: #757575;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1;
`;

interface IRadioGroupField extends RadioGroupProps {
	name: string;
	label?: string;
	values: {
		[key: string]: {
			label?: string;
			disabled?: boolean;
			primary?: ReactNode;
			secondary?: ReactNode;
		};
	};
}

const RadioGroupField = ({ name, label, values, ...otherProps }: IRadioGroupField) => (
	<Field name={name}>
		{({ field, form: { setFieldTouched, setFieldValue }, meta }) => {
			const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
				setFieldTouched(field.name, true);

				setFieldValue(field.name, event.target.value);
			};

			const hasError = meta.touched && !!meta.error;

			return (
				<FormControl component="fieldset">
					{label && (
						<FormLabel component="legend">
							<InlineLabel>{label}</InlineLabel>
						</FormLabel>
					)}

					<RadioGroup row name={field.name} onChange={onChange} value={field.value || null} {...otherProps}>
						{Object.keys(values).map(key => (
							<InputContainer key={key}>
								<StyledFormControlLabel
									value={key}
									control={<Radio />}
									label={values[key]?.label}
									disabled={values[key]?.disabled}
								/>

								<ListItemText primary={values[key]?.primary} secondary={values[key]?.secondary} />
							</InputContainer>
						))}
					</RadioGroup>

					{hasError && <FormHelperText error>{meta.error}</FormHelperText>}
				</FormControl>
			);
		}}
	</Field>
);

export default RadioGroupField;
