import { isUndefined, sortBy } from "lodash-es";
import { useEffect } from "react";

import { TConfigsCountryIId } from "data/configs/types";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";

import { selectConfigsAllError, selectConfigsCountries } from "../selectors/configs";
import { fetchConfigsCountries } from "../slices/configs";

interface ICountryList {
	value: string;
	label: string;
}

const useCountry = (configs?: { selectValueKey: keyof TConfigsCountryIId }) => {
	const configsAllError = useAppSelector(selectConfigsAllError);

	const countries = useAppSelector(selectConfigsCountries);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (configsAllError === true && !countries) {
			dispatch(fetchConfigsCountries());
		}

		// eslint-disable-next-line
	}, [configsAllError, countries]);

	const isLoading = isUndefined(countries);

	const countriesSelect: ICountryList[] =
		countries?.map(v => ({ label: v.name, value: v[configs?.selectValueKey || "name"]?.toString() || "" })) || [];

	const countriesSelectSorted: ICountryList[] = sortBy(countriesSelect, elem => elem.label);

	const countriesSorted = sortBy(countries, elem => elem.name);

	const findByName = (country: string) => countries?.find(c => c?.name === country);

	const findById = (countryId?: string) => countries?.find(c => c?.id === countryId);

	return {
		countries,
		countriesSelect,
		countriesSelectSorted,
		countriesSorted,
		findByName,
		findById,
		isLoading,
	};
};

export default useCountry;
