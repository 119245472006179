import { isNull, isUndefined, round } from "lodash-es";

import { TCurrencyType } from "data/experiences/types";
import { ECurrencySymbol } from "data/financials/types";

type TPrice = string | number | null | undefined;

const earningsPart = 80;

const formatPercentage = (num: TPrice) => (num || 0).toString().replace(".", ",") + "%";

const prices = (
	num: TPrice,
	currency?: TCurrencyType | "N/A" | null,
	replaceValue?: string,
	digits?: number,
	returnEmpty?: boolean,
) => {
	let localNum = num;

	if (isNull(localNum) || isUndefined(localNum)) {
		if (returnEmpty) {
			return "";
		}

		localNum = 0;
	}

	return (
		(currency ? `${ECurrencySymbol[currency] || currency} ` : "") +
		formatNumber(localNum, typeof digits === "number" ? digits : 2, replaceValue || undefined)
	);
};

const formatNumber = (value: TPrice, decimals: number, decPoint?: string, thousandsSep?: string) => {
	let localValue = value;

	if (isNull(localValue) || isUndefined(localValue)) {
		localValue = 0;
	}

	const n = !isFinite(+localValue) ? 0 : +localValue;

	const precision = !isFinite(+decimals) ? 0 : Math.abs(decimals);

	const sep = isUndefined(thousandsSep) ? "'" : thousandsSep;

	const dec = isUndefined(decPoint) ? "." : decPoint;

	const toFixedFix = (v: number, p: number) => {
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		const k = Math.pow(10, p);

		return Math.round(v * k) / k;
	};

	const s = (precision ? toFixedFix(n, precision) : Math.round(n)).toString().split(".");

	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}

	if ((s[1] || "").length < precision) {
		s[1] = s[1] || "";

		s[1] += new Array(precision - s[1].length + 1).join("0");
	}

	return s.join(dec);
};

const getEarnings = (val: number) => Math.floor((val * earningsPart) / 100);

const formatAndCalcPercentage = (value: number, total: number, decimals?: number) =>
	formatPercentage(round((value * 100) / total, decimals));

export { prices, formatNumber, getEarnings, formatPercentage, formatAndCalcPercentage };
