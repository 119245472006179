import { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph, { Description } from "ui/CommonParagraph";
import Dialog from "ui/Dialog";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";

import ContactForm from "./components/ContactForm";

const Small = styled(Description)`
	font-size: 90%;
	margin-top: 0;
`;

const Wrapper = styled.div`
	margin: 2.5rem 0;
	font-family: ${MontserratFontFamily};
`;

const Orange = styled.a`
	text-decoration: none;
	color: ${colors.sunShade};
`;

const OrangeLink = styled(StyledLink)`
	text-decoration: underline;
	color: ${colors.sunShade};
`;

const ContactPage = () => {
	const { t, withComponents } = useTranslation();
	const [isSent, setIsSent] = useState<boolean>(false);

	const closeDialog = () => setIsSent(false);

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Dialog showDialog={isSent} handleClose={closeDialog} minHeight="180px">
				<DialogContentWrapper title={t("CONTACT.MESSAGE.SENT_TITLE")} center>
					{t("CONTACT.MESSAGE.SENT_DESCRIPTION")}
				</DialogContentWrapper>
			</Dialog>

			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("CONTACT.PAGE_TITLE")} />

				<CommonParagraph
					withMarginTop
					mainTitle={t("CONTACT.PAGE_TITLE")}
					description={withComponents(
						"CONTACT.DESCRIPTION",
						{
							FaqUrl: <OrangeLink to={paths.HELP_FAQ} />,
							PolicyUrl: <OrangeLink to={paths.HELP_CANCELLATION_POLICY} />,
						},
						null,
					)}
				/>

				<Wrapper>
					<ContactForm setIsSent={setIsSent} />

					<Description>
						{withComponents(
							"CONTACT.DESCRIPTION.FOOTER",
							{
								Orange: <Orange href="tel:+41715087013" />,
							},
							null,
						)}
						<Small>{t("CONTACT.DESCRIPTION.FOOTER_SMALL")}</Small>
					</Description>
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default ContactPage;
