import { Box, Chip, Grid, IconButton, List, ListItem, Typography } from "@material-ui/core";
import { Cancel, FileCopyOutlined } from "@material-ui/icons";
import { useState } from "react";
import styled from "styled-components";

import { IDaysOfWeek } from "data/types";

import useTranslation from "hooks/useTranslation";

import { TimePickerComponent } from "ui/forms/date-time/TimePickerComponent";

import colors from "styles/colors";

const StyledChip = styled(Chip)`
	background-color: ${colors.lightOrange};
`;

export interface IStringTagsTime {
	onChange: (newValue) => void;
	value: IDaysOfWeek;
	withCopyButton?: boolean;
}

const StringTagsTime = ({ onChange, value, withCopyButton }: IStringTagsTime) => {
	const { t } = useTranslation();

	const [currentDay, setCurrentDay] = useState<string>("");

	const handleDelete = (element, index) => {
		onChange({
			...value,
			[element]: [...value[element]].filter((_, i) => i !== index),
		});
	};

	const addNewTime = valueTime => {
		onChange({
			...value,
			[currentDay]: [...value[currentDay], valueTime],
		});
	};

	const copyForWholeWeek = (day: string) => {
		const values = { ...value };

		Object.keys(values).forEach(key => {
			values[key] = values[day];
		});

		onChange(values);
	};

	return (
		<Box display="flex" flexDirection="column">
			<List key="list">
				{Object.entries(value).map(([k, v], index) => (
					<ListItem key={index} divider style={{ padding: "10px 0" }}>
						<Grid container spacing={5} direction="column">
							<Grid item xs={12}>
								<Grid container direction="row">
									<Typography style={{ width: "120px" }}>{t(`EXPERIENCE.FORM.STRING_TAGS_TIME.${k}`)}</Typography>
									{v.map((element, i) => (
										<Box display="flex" pr={5} pb={5} key={i}>
											<StyledChip label={element} onDelete={() => handleDelete(k, i)} deleteIcon={<Cancel />} />
										</Box>
									))}

									<Box onClick={() => setCurrentDay(k)}>
										<TimePickerComponent
											callBackTime={addNewTime}
											label={
												!!v.length
													? t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_MORE")
													: t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_START_TIME")
											}
										/>
									</Box>

									{withCopyButton && !!v.length && (
										<Box position="relative" top="-7px" marginLeft="5px">
											<IconButton
												edge="end"
												aria-label={t("EXPERIENCE.FORM.STRING_TAGS.COPY_FOR_WHOLE_WEEK")}
												color="inherit"
												onClick={() => copyForWholeWeek(k)}
												title={t("EXPERIENCE.FORM.STRING_TAGS.COPY_FOR_WHOLE_WEEK")}
											>
												<FileCopyOutlined />
											</IconButton>
										</Box>
									)}
								</Grid>
							</Grid>
						</Grid>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default StringTagsTime;
