import { Box, List, ListItem, ListItemText, Radio, RadioGroup, TextField } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem/ListItem";
import { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import { itemOfListStyles } from "styles/common";

type TCurrentItem = "FLEXIBLE" | "STANDARD" | "MODERATE" | "STRICT";

export interface INumberRadioInput {
	onChange: (newValue) => void;
	value: number;
	listItemProps?: Pick<ListItemProps, "disableGutters"> & { style: CSSProperties };
}

const StyledList = styled(List)`
	display: flex;
	flex-direction: column;
	padding: 0;
`;

const configHours = {
	STANDARD: 24,
	MODERATE: 72,
	STRICT: 168,
};

const NumberRadioInput = ({ onChange, value, listItemProps }: INumberRadioInput) => {
	const [currentValue, setCurrentValue] = useState<TCurrentItem>("FLEXIBLE");
	const [currentValueInput, setCurrentValueInput] = useState<number>(value);

	useEffect(() => {
		const setFirstConfig = () => {
			Object.entries(configHours).forEach(([k, v]) => {
				if (v === value) {
					setCurrentValue(k as TCurrentItem);
				}
			});
		};

		setFirstConfig();
	}, [value]);

	const { t } = useTranslation();
	const radioDictionary = getDictionary("CANCELLATION_POLICY");

	const handleChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
		const currentElement = event.target.value;
		setCurrentValue(currentElement as TCurrentItem);
		if (currentElement !== "FLEXIBLE") {
			onChange(configHours[currentElement]);
		} else {
			setCurrentValueInput(1);
			onChange(1);
		}
	};

	const handleChangeItemText = (changedValue: string) => {
		const currentElement = changedValue;
		setCurrentValue(changedValue as TCurrentItem);
		if (currentElement !== "FLEXIBLE") {
			onChange(configHours[currentElement]);
		} else {
			setCurrentValueInput(1);
			onChange(1);
		}
	};

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const valueInput = parseInt(event.target.value, 10);
		if (!isNaN(valueInput)) {
			if (currentValue === "FLEXIBLE" && valueInput >= 1 && valueInput <= 24) {
				setCurrentValueInput(valueInput);
				onChange(event.target.value);
			}
		}
	};

	return (
		<Box>
			<RadioGroup
				aria-label="radio-group-item"
				name="id-radio-item"
				value={currentValue}
				onChange={handleChangeRadioGroup}
			>
				<StyledList key="list" dense>
					{radioDictionary.map((d: TCurrentItem, i) => (
						<ListItem key={i} style={itemOfListStyles} {...listItemProps}>
							<Radio value={d} />

							<ListItemText
								onClick={() => handleChangeItemText(d)}
								primary={t(`EXPERIENCE.FORM.NUMBER_RADIO_INPUT.${d}.TITLE`)}
								secondary={t(`EXPERIENCE.FORM.NUMBER_RADIO_INPUT.${d}.DESCRIPTION`)}
								style={{ maxWidth: "120px" }}
							/>

							{d === "FLEXIBLE" && (
								<TextField
									id="id-radio-group-input"
									label={t(`EXPERIENCE.FORM.NUMBER_RADIO_INPUT.LABEL_HOURS`)}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={currentValue !== "FLEXIBLE"}
									value={currentValueInput}
									variant="outlined"
									type="number"
									onChange={handleChangeInput}
									style={{ maxWidth: "120px" }}
								/>
							)}
						</ListItem>
					))}
				</StyledList>
			</RadioGroup>
		</Box>
	);
};

export default NumberRadioInput;
