import { Box, Button, Divider, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { Dispatch, SetStateAction, useState } from "react";

import LocalBiniLogo from "assets/icons/logo/localbini-greyish.svg";

import { EContactUsOptions } from "data/notifications/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import notificationsService from "services/api/notifications";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";

import CheckboxField from "components/formik/CheckboxField";
import Input from "components/formik/Input";

import BookingInfo from "ui/BookingInfo";
import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";
import PrivacyUrlsFooter from "ui/PrivacyUrlsFooter";

import { BookingInfoContainer, BookingInfoTitle, ModalSentSubTitle, ModalSentText, Textarea } from "./styled";

interface ISendRequestForPriceModal {
	showModalHandler: Dispatch<SetStateAction<boolean>>;
	experienceTitle: string;
}

const SendRequestForPriceModal = ({ showModalHandler, experienceTitle }: ISendRequestForPriceModal) => {
	const { t, withRaw } = useTranslation();

	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);
	const booking = useAppSelector(selectExperienceTravellerBooking);

	const [sent, setSent] = useState<boolean>(false);

	const {
		yup: { validations, requiredStringMinLength, requiredEmail, requiredCheckbox, requiredStringMinMaxLength },
	} = useValidations();

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: validations({
			name: requiredStringMinLength(2),
			phone: requiredStringMinMaxLength(5, 16),
			from: requiredEmail,
			description: requiredStringMinLength(2),
			agreement: requiredCheckbox(),
		}),
		initialValues: {
			name: "",
			phone: "",
			from: "",
			description: "",
			agreement: false,
			...(userData?.profile_draft?.first_name && { name: userData.profile_draft.first_name }),
			...(userData?.personal?.phone && { phone: userData.personal.phone }),
			...(userData?.personal?.email && { from: userData.personal.email }),
		},
		onSubmit: async formValues => {
			try {
				const { name, phone, from, description } = formValues;

				await notificationsService.postSendContactUsEmail({
					option: EContactUsOptions.vip_booking_request,
					email_config: {
						name,
						phone,
						from,
						participants_amount: booking.adults + booking.kids,
						datetime: `${booking.date[0]}T${booking.hour[0]}:00`,
						experience_id: booking.eid,
						experience_name: experienceTitle,
						...(!!description && { description }),
						...(userData?.id && { user_id: userData.id }),
						// @ts-ignore
						...(formValues.datetime && { datetime: new Date(formValues.datetime).toISOString().slice(0, -5) }),
					},
				});

				setSent(true);
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	return (
		<Dialog showDialog handleClose={() => showModalHandler(false)} minHeight="auto" decorator="withoutLogo">
			{!sent ? (
				<DialogContentWrapper title={experienceTitle} center>
					<FormikProvider value={formikProps}>
						<Form>
							<Box paddingX={10} paddingBottom={10}>
								<Box mb={10}>
									<BookingInfoTitle>
										{t("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.BOOKING_INFO_TITLE")}
									</BookingInfoTitle>

									<Divider />
								</Box>

								<BookingInfoContainer>
									<BookingInfo {...booking} onlyBookingDetails />
								</BookingInfoContainer>

								<Box mb={18}>
									<Divider />
								</Box>

								<Grid container spacing={10}>
									<Grid item xs={12}>
										<Input
											name="name"
											inputClassName="full"
											label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.FIRST_NAME")}
											required
											disabled={!!userData?.profile_draft?.first_name || formikProps.isSubmitting}
										/>
									</Grid>

									<Grid item xs={12}>
										<Input
											name="phone"
											label={t("CONTACT.PHONE")}
											inputClassName="full"
											required
											disabled={!!userData?.personal?.phone || formikProps.isSubmitting}
										/>
									</Grid>

									<Grid item xs={12}>
										<Input
											name="from"
											label={t("CONTACT.EMAIL")}
											inputClassName="full"
											required
											disabled={!!userData?.personal?.email || formikProps.isSubmitting}
										/>
									</Grid>

									<Grid item xs={12}>
										<Textarea
											name="description"
											label={t("CONTACT.MESSAGE")}
											inputClassName="full"
											multiline
											disabled={formikProps.isSubmitting}
											required
										/>
									</Grid>

									<Grid item xs={12}>
										<CheckboxField
											name="agreement"
											label={t("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.CHECKBOX_LABEL")}
											required
											disabled={formikProps.isSubmitting}
											withError
										/>

										<PrivacyUrlsFooter firstMessage={t("AUTH.REGISTER.AGREEMENTS_FIRST_PART")} />
									</Grid>

									<Grid item xs={12} container justifyContent="center">
										<ActionButton
											type="button"
											onClick={() => formikProps.handleSubmit()}
											translationDefault="BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.BUTTON_LABEL"
											translationAction="BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.BUTTON_ACTION_LABEL"
											isAction={formikProps.isSubmitting}
											disabled={formikProps.isSubmitting}
										/>
									</Grid>
								</Grid>
							</Box>
						</Form>
					</FormikProvider>
				</DialogContentWrapper>
			) : (
				<>
					<Box mt={20} textAlign="center">
						<img src={LocalBiniLogo} alt="LocalBini logo" height="64px" width="64px" />
					</Box>

					<DialogContentWrapper title={t("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.SENT_TITLE")} center>
						<Box paddingX={10} paddingBottom={10} textAlign="center">
							<ModalSentSubTitle>
								{withRaw("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.SENT_SUBTITLE", null)}
							</ModalSentSubTitle>

							<ModalSentText>{t("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.SENT_TEXT")}</ModalSentText>

							<Button variant="contained" color="primary" onClick={() => showModalHandler(false)}>
								{t("BOOKING_FORM.SEND_REQUEST_FOR_PRICE_MODAL.SENT_BUTTON")}
							</Button>
						</Box>
					</DialogContentWrapper>
				</>
			)}
		</Dialog>
	);
};

export default SendRequestForPriceModal;
