import { Button } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Img404 from "assets/images/404page/404_img.svg";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import { Container, MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const MainTitle = styled.h1`
	margin: 2.5rem 0 0 0;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xhuge};
	font-weight: bold;
	letter-spacing: 0.07px;

	${media.tablet`
    font-size: ${dimensions.fontSize.xxlarge};
  `};
`;

const SubTitle = styled.h2`
	margin: 0 0 2.5rem 0;
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	line-height: 1.95;
	letter-spacing: 0.04px;

	${media.tablet`
    margin: 0 0 1.25rem 0;
    font-size: ${dimensions.fontSize.xlarge};
  `};
`;

const PictureContent = styled.img`
	width: 100%;
	height: auto;
	margin-bottom: 2.5rem;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
`;

const Error404Page = () => {
	const { t } = useTranslation();

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<MainTitle>404 - Not Found</MainTitle>

				<SubTitle>{t("404_PAGE.SUB_TITLE")}</SubTitle>

				<StyledLink to={paths.HOMEPAGE}>
					<Button variant="contained" color="primary">
						{t("404_PAGE.GO_TO_HP_BTN")}
					</Button>
				</StyledLink>

				<PictureContent src={Img404} alt="404 img" />
			</Container>
		</AppWrapper>
	);
};

export default Error404Page;
