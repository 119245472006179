import { List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, ReactNode } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

interface IIconStringArray {
	icon: ReactNode;
	isDividerBelow?: boolean;
	translationKey?: string;
	valuesArray: string[];
}

const ItemOfArray = styled(ListItemText)`
	& span {
		font-family: ${RobotoFontFamily};
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: 0.09px;
		color: ${colors.mainFontColor};
		text-transform: capitalize;

		${media.tablet`
      font-size: 14px;
      line-height: 1.25;
    `};
	}
`;

const StyledList = styled(List)<{ $twoColumns: boolean }>`
	${props => props.$twoColumns && "column-count: 2;"}
`;

const StyledListItem = styled(ListItem)`
	background: ${colors.white};
	border-radius: 4px;
	width: 70%;
	margin-bottom: 2px;

	${media.tablet`
    width: 98%;
  `};
`;

const IconStringArray = memo(({ icon, isDividerBelow = false, translationKey, valuesArray }: IIconStringArray) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const twoColumns = valuesArray?.length > 3 && !isSmallScreen;
	const { t } = useTranslation();

	return (
		<StyledList key="list" $twoColumns={twoColumns}>
			{valuesArray.map((v, i) => (
				// @ts-ignore
				<StyledListItem key={i} divider={isDividerBelow}>
					<ListItemIcon>{icon}</ListItemIcon>
					<ItemOfArray primary={translationKey ? t(`${translationKey}.${v}`) : v} />
				</StyledListItem>
			))}
		</StyledList>
	);
});

export default IconStringArray;
