import useNotification from "hooks/useNotification";

type TUseNotificationInstance = ReturnType<typeof useNotification> | undefined;

export class Notifications {
	private static _useNotificationInstance: TUseNotificationInstance;

	static get useNotification(): TUseNotificationInstance {
		return this._useNotificationInstance;
	}

	static set useNotification(instance: TUseNotificationInstance) {
		if (!this._useNotificationInstance && !!instance) {
			this._useNotificationInstance = instance;
		}
	}
}
