import { Box, Button, Divider } from "@material-ui/core";

import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import Breadcrumbs from "ui/Breadcrumbs";

import { StyledLink } from "styles/common";

interface IHeaderProps {
	backToLabel: string;
	link: string;
	lastBreadcrumbLabel?: string;
}

const Header = ({ link, backToLabel, lastBreadcrumbLabel }: IHeaderProps) => {
	const { t } = useTranslation();

	return (
		<Box mt={5} mb={5}>
			<Breadcrumbs
				withoutHomepage
				customFirstBreadcrumb={{ route: link, label: backToLabel }}
				lastBreadcrumbLabel={lastBreadcrumbLabel}
			/>

			<Box display="flex" alignItems="center" justifyContent="flex-end" width="100%" height="100%" mb={5}>
				<Box mr={5}>
					<StyledLink
						to={fillRoute(paths.ONBOARDING, {
							step: EOnboardingSteps.Binipools,
							search: { type: "binipool", selectOnlyBinipools: true },
						})}
						$withoutHoverUnderline
					>
						<Button variant="outlined">{t("EXPERIENCE.JOIN_BINIPOOL_BTN")}</Button>
					</StyledLink>
				</Box>

				<StyledLink to={fillRoute(paths.EXPERIENCE_CREATE_STEP, { id: null, step: null })} $withoutHoverUnderline>
					<Button variant="contained" color="primary">
						{t("EXPERIENCE.NEW_EXPERIENCE_BTN")}
					</Button>
				</StyledLink>
			</Box>

			<Divider />
		</Box>
	);
};

export default Header;
