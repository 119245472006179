import * as Yup from "yup";

import useTranslation from "hooks/useTranslation";

const useValidationSchema = (travelerType: boolean) => {
	const { t, withValuesAsString } = useTranslation();

	const required = t("ERRORS.REQUIRED");
	const tooShort = (min: number) => withValuesAsString("ERRORS.TOO_SHORT_WITH_VALUE", { min });

	const tooLong = (max: number) => withValuesAsString("ERRORS.TOO_LONG_SEQUENCE", { max });

	return Yup.object().shape({
		first_name: Yup.string().min(2, tooShort(2)).max(100, tooLong(100)).required(required),
		description: Yup.string().min(40, tooShort(40)).max(1000, tooLong(1000)).required(required),
		motto: Yup.string().min(5, tooShort(5)).max(300, tooLong(300)).required(required),
		occupation: Yup.string().min(2, tooShort(2)).max(100, tooLong(100)).required(required),
		...(!travelerType && { cities: Yup.array().min(1).required(required) }),
	});
};

export default useValidationSchema;
