// @todo:chore - it would be good if the generic colors were named correctly ( based on https://colornames.org/ )
// e.g. middleGray, secondaryGray, blue etc
// Colors for a specific destination (e.g. mainFontColor) remain unchanged
const colors = {
	// Fonts
	mainFontColor: "#263238",
	textSecondary: "#546E7A",

	// Borders and dividers
	borderGray: "#e0e0e0",
	dividerColor: "#eeeeee",

	white: "#fff",
	lightGrayWhite: "#f1f1f1",
	lightDark: "#212121",
	black: "#000",
	lightGray: "#f7f7f7",
	middleGray: "#616161",
	secondaryGray: "#455a64",
	sunShade: "#ffa726",
	lightSunShade: "#ffb13e",
	lightOrange: "#ffe0b2",
	green: "#4caf50",
	lightGreen: "#51b955",
	brightGreen: "#00C853",
	darkGreen: "#37474f",
	red: "#d32f2f",
	darkRed: "#ad1519",
	hokiColor: "#607d8b",
	oceanDrive: "#b0bec5",
	bermudaGray: "#78909c",
	blue: "#26c6da",
	darkBlue: "#1976D2",

	// naming convention for color palette: https://www.figma.com/file/9OlqNH6YMsHsPbp17n6WfV/DesignSystem?type=design&node-id=1250-23731&mode=design&t=4vsFgWYhwGILfWW2-0
	// TODO: update colors above
	// green gamma
	accentGreen500: "#00C853",
	accentGreen200: "#B8ECB6",

	// yellow gamma
	accentYellow200: "#F6EB91",

	// red gamma
	accentRed500: "#E53935",

	// grey gamma
	accentGrey300: "#9BA6AC",

	// blue gamma
	accentBlue700: "#0D5BA9",
};

export default colors;
