import { Avatar, Box } from "@material-ui/core";
import styled, { css } from "styled-components";

import { TExpType } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import BinipoolAvatar from "ui/BinipoolAvatar";

import colors from "styles/colors";
import { DescriptionContent, RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { TitleSection } from "../../../Traveller";

const AvatarContentCommon = css`
	width: 5.25rem;
	height: 5.25rem;

	${media.phone`
    width: 3.5rem;
    height: 3.5rem;
  `};
`;

const AvatarContent = styled(Avatar)`
	${AvatarContentCommon}
`;

const BinipoolAvatarContent = styled.div`
	${AvatarContentCommon}
`;

const LocalhostName = styled.div`
	margin-top: 10px;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: bold;
	line-height: 1.2;
	text-align: center;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
`;

const YourLocalhostLabel = styled.div`
	font-size: ${dimensions.fontSize.small};
	font-weight: bold;
	line-height: 1.09;
	text-align: center;
	color: #546e7a;
`;

interface IAboutProps {
	avatar?: string | null;
	description: string;
	firstName?: string;
	uid?: string;
	expType: TExpType;
	designerId?: string | null;
	designerName?: string | null;
	designerAvatar?: string | null;
}

const About = ({
	avatar,
	description,
	firstName,
	uid,
	expType,
	designerId,
	designerName,
	designerAvatar,
}: IAboutProps) => {
	const { t } = useTranslation();

	const sharedPart = (
		<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
			<AvatarContent
				title={`${firstName || "Local Host"} - profile photo`}
				alt={`${firstName || "Local Host"} - profile photo`}
				src={avatar || ""}
			/>

			<LocalhostName>{firstName}</LocalhostName>

			<Box mt="5px">
				<YourLocalhostLabel>{t("EXPERIENCE_DETAILS_TRAVELLER.YOUR_LOCALHOST_LABEL")}</YourLocalhostLabel>
			</Box>
		</Box>
	);

	return (
		<Box display="flex" alignItems="center">
			<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mr={12}>
				{expType === "COHOSTED" && designerId && (
					<Box marginBottom="10px">
						<StyledLink
							to={fillRoute(paths.PUBLIC_PROFILE, {
								userName: encodeURI(designerName?.toLowerCase() || ""),
								uid: designerId,
							})}
							$withoutHoverUnderline
						>
							<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
								<AvatarContent
									title={`${designerName || "Local Host"} - profile photo`}
									alt={`${designerName || "Local Host"} - profile photo`}
									src={designerAvatar || ""}
								/>

								<LocalhostName>{designerName}</LocalhostName>

								<Box mt="5px">
									<YourLocalhostLabel>{t("EXPERIENCE_DETAILS_TRAVELLER.DESIGNER_LABEL")}</YourLocalhostLabel>
								</Box>
							</Box>
						</StyledLink>
					</Box>
				)}

				{expType === "UNIQUE" ? (
					uid ? (
						<StyledLink
							to={fillRoute(paths.PUBLIC_PROFILE, { userName: encodeURI(firstName?.toLowerCase() || ""), uid })}
							$withoutHoverUnderline
						>
							{sharedPart}
						</StyledLink>
					) : (
						sharedPart
					)
				) : (
					<BinipoolAvatarContent>
						<BinipoolAvatar withoutBorder />
					</BinipoolAvatarContent>
				)}
			</Box>

			<Box>
				<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_ABOUT")}</TitleSection>

				<Box marginTop="12px">
					<DescriptionContent>{description}</DescriptionContent>
				</Box>
			</Box>
		</Box>
	);
};

export default About;
