import { Box, InputAdornment, Typography } from "@material-ui/core";
import { PersonOutline } from "@material-ui/icons";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import InfoInput from "ui/InfoInput";

import colors from "styles/colors";
import dimensions from "styles/dimensions";

import Input from "../../formik/Input";

const BoldSpan = styled.span`
	font-weight: bold;
`;

const InfoText = styled(Typography)`
	font-size: 14px;
	margin-left: ${dimensions.spaces.xsmall};
`;

const Text = styled.p`
	font-size: ${dimensions.fontSize.medium};
	color: ${colors.bermudaGray};
`;

const Adornment = styled(InputAdornment)`
	font-size: 14px;
`;

const StyledIcon = styled(PersonOutline)`
	fill: ${colors.hokiColor};
	font-size: 20px;
`;

const InputContainer = styled(Box)<{ $error?: boolean; $errorMargin?: boolean }>`
	${props =>
		props.$error &&
		css`
			margin-bottom: ${props.$errorMargin ? 55 : 30}px;

			p.Mui-error {
				position: absolute;
				bottom: -45px;
				right: 0;
				top: 60px;
				display: block;
				width: 200px;
				text-align: right;
				margin: 0;
			}
		`}
`;

interface PeopleProps {
	label: string;
	nrAdults: number;
	nrKids: number;
	adornmentWidth?: number;
	errors?: any;
	touched?: boolean;
	externalHandleClose?(): Promise<boolean>;
}

const People = ({
	label,
	nrAdults,
	nrKids,
	adornmentWidth = 80,
	errors,
	touched,
	externalHandleClose,
}: PeopleProps) => {
	const { t } = useTranslation();

	const inputStyles = {
		width: adornmentWidth,
		marginBottom: 0,
	};

	return (
		<InfoInput
			externalHandleClose={externalHandleClose}
			label={label}
			error={touched && (errors?.adults || errors?.kids)}
			menuStyle={{
				width: 220,
				height: "auto",
			}}
			borderColor={colors.sunShade}
			overlayContent={
				<div>
					<InputContainer $error={touched && !!errors?.adults} $errorMargin>
						<Input
							inputAndWrapperStyles={inputStyles}
							inlineLabel={t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")}
							name="adults"
							type="number"
							inputProps={{ min: 1 }}
						/>
					</InputContainer>

					<InputContainer marginTop="10px" $error={touched && !!errors?.kids}>
						<Input
							inputAndWrapperStyles={inputStyles}
							inlineLabel={t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}
							name="kids"
							type="number"
							inputProps={{ min: 0 }}
						/>
					</InputContainer>

					{!errors?.kids && nrKids > 0 && <Text>{t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS_AGES")}</Text>}

					{!errors?.kids &&
						nrKids > 0 &&
						Array(nrKids)
							.fill(null)
							.map((_, index) => (
								<Input
									key={`selectedChildren.kid-nr-${index}`}
									inputClassName="full"
									name={`selectedChildren.kid-nr-${index}`}
									type="number"
									inputProps={{ min: 0 }}
									endAdornment={<Adornment position="end">{t("SEARCH.EXPERIENCES.MAIN_FILTERS.YEARS_OLD")}</Adornment>}
								/>
							))}
				</div>
			}
			icon={<StyledIcon />}
			text={
				<InfoText>
					<BoldSpan>{nrAdults}</BoldSpan>{" "}
					{nrAdults === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULT") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.ADULTS")},{" "}
					<BoldSpan>{nrKids}</BoldSpan>{" "}
					{nrKids === 1 ? t("SEARCH.EXPERIENCES.MAIN_FILTERS.KID") : t("SEARCH.EXPERIENCES.MAIN_FILTERS.KIDS")}
				</InfoText>
			}
		/>
	);
};

export default People;
