import { Avatar, Button } from "@material-ui/core";
import { CreateOutlined, ReportProblemOutlined, StarBorder, ThumbUpAltOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

export const TitleContent = styled.div`
	padding-bottom: 0.313rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MainTitleSection = styled.div`
	padding: 0;
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 500;
	line-height: 2.58;
	letter-spacing: 0.83px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
`;

export const MottoContent = styled.div`
	width: 100%;
	padding: 1.5rem 1.75rem;
`;

export const MottoText = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xxhudge};
	line-height: 1.33;
	color: ${colors.mainFontColor};

	${media.phone`
    font-size: ${dimensions.fontSize.xlarge};
    line-height: 1.25;
  `};
`;

export const AboutMeContent = styled.div`
	padding: 1.25rem;
	border-radius: 0.25rem;
	background-color: ${colors.lightGray};
`;

export const AboutMeText = styled.h2`
	margin: 1.5rem 0 0;
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	line-height: 1.81;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};
	opacity: 0.7;
	font-weight: 300;

	${media.phone`
    font-size: 14px;
    line-height: 1.71;
  `};
`;

export const EditBtn = styled.div<{ marginRight?: boolean }>`
	margin-right: ${props => (props.marginRight ? "1.25rem" : 0)};
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;
	box-shadow: 0 0 1px -2px rgba(255, 255, 255, 0.1);
	background-color: #fafafa;
	cursor: pointer;

	${media.largeDesktop`
    margin-right: 0;
  `};

	&:hover {
		background-color: #ededed;
	}
`;

export const EditBackgroundPhotoBtn = styled(EditBtn)`
	position: absolute;
	right: 10px;
	top: 10px;
`;

export const SaveBtnsContent = styled.div`
	margin-top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0.063rem 0 1.25rem;
	border-radius: 0.25rem;
	background-color: #bbdefb;

	${media.desktop`
    margin-top: 0.938rem;
    padding: 0.5rem;
    justify-content: center;
  `};
`;

export const SaveDesc = styled.label`
	margin-right: 70px;
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.large};
	font-weight: 500;
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: #0d3c61;

	${media.phone`
    margin-right: 0;
  `};
`;

export const DarkBlueBtn = styled(Button)`
	margin-right: 0.625rem;
	background-color: #1976d2;
	color: ${colors.white};
	font-size: ${dimensions.fontSize.large};

	&:hover {
		background-color: #166aba;
	}

	${media.phone`
    font-size:${dimensions.fontSize.medium};
  `};
`;

export const OutlinedBlueBtn = styled(Button)`
	background-color: ${props => props.theme.palette.common.white};
	color: #1976d2;
	font-weight: 500;
	border: solid 1px #1976d2;
	font-size: ${dimensions.fontSize.large};

	&:hover {
		background-color: ${colors.lightGray};
	}

	${media.phone`
    font-size:${dimensions.fontSize.medium};
  `};
`;

export const InfoIcon = styled(ReportProblemOutlined)`
	margin-right: 0.875rem;
	width: 1.5rem;
	height: 1.5rem;
`;

export const PhotoContent = styled.div<{ urlImg: string }>`
	margin: 2.25rem 0;
	background-image: url(${props => props.urlImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 14.125rem;
	border-radius: 0.25rem;
	position: relative;
`;

export const AvatarProfile = styled(Avatar)`
	margin-bottom: 1.25rem;
	width: 11.625rem;
	height: 11.625rem;
	border: 4px solid ${colors.white};
`;

export const DetailsContent = styled.div<{ column?: boolean }>`
	margin: 5px 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${props => props.column && "flex-direction: column;"}
`;

export const StarIcon = styled(StarBorder)`
	width: 1rem;
	height: 1rem;
	margin-right: 0.625rem;
	color: ${props => props.theme.palette.primary.main};
`;

export const ThumbIcon = styled(ThumbUpAltOutlined)`
	width: 1rem;
	height: 1rem;
	margin-right: 0.625rem;
	color: ${props => props.theme.palette.primary.main};
`;

export const RatingText = styled.span`
	font-size: ${dimensions.fontSize.large};
	line-height: 1.43;
	letter-spacing: 0.006rem;
	color: ${colors.mainFontColor};
`;

export const StatusContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.125rem 2.5rem;
`;

export const NameSurname = styled.h1`
	font-weight: normal;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xxhudge};
	font-style: normal;
	line-height: 1.33;
	text-align: center;
	color: ${colors.mainFontColor};
	margin: 0;
`;

export const Profession = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.large};
	line-height: 1.49;
	text-align: center;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
`;

export const EditAvatarBtn = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 2rem;
	width: 2.25rem;
	height: 2.25rem;
	background-color: ${props => props.theme.palette.common.white};
	border: none;
	border-radius: 0.25rem;
	outline: none;

	&:active,
	&:hover {
		background-color: ${props => props.theme.palette.grey[50]};
	}
`;

export const CreateOutlinedIcon = styled(CreateOutlined)`
	font-size: ${dimensions.fontSize.xxxlarge};
	opacity: 0.7;
`;

export const StyledAutocomplete = styled(Autocomplete)`
	width: 100%;
	margin-top: 20px;

	.MuiChip-root {
		background-color: ${colors.lightOrange};
	}
`;
