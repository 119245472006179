import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import styled from "styled-components";

import colors from "styles/colors";

export const InnerContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 30px;
	padding-top: 15px;
	flex-wrap: wrap;
`;

export const StyledRemoveIcon = styled(RemoveCircleOutlineIcon)<{ $size?: number; $color?: string }>`
	width: ${props => props.$size || 40}px;
	height: ${props => props.$size || 40}px;
	color: ${props => props.$color || colors.white};
`;

export const StyledCheckIcon = styled(CheckCircleOutlineIcon)<{ $size?: number; $color?: string }>`
	width: ${props => props.$size || 40}px;
	height: ${props => props.$size || 40}px;
	color: ${props => props.$color || colors.white};
`;
