import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import Logo from "ui/Logo";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

import AnimationFlyingElement from "../AnimationFlyingElement";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 100%;
`;

const MainTitle = styled.h1`
	margin: 30px 0 0 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	color: ${colors.sunShade};
	line-height: 1;

	${media.tablet`
    margin: 15px 0 1.25rem 0;
    font-size: 16px;
  `};
`;

const Container = styled.div`
	width: 100%;
`;

interface ILoader {
	withoutFlying?: boolean;
}

const Loader = ({ withoutFlying }: ILoader) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Container>
			<Wrapper>
				{!withoutFlying && (
					<>
						<AnimationFlyingElement topMargin="10%" leftMargin="10%" toTheLeft versionAnimation="second" />
						<AnimationFlyingElement topMargin="20%" leftMargin="40%" animationDelay="0.5s" height="20%" width="20%" />
					</>
				)}

				<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					<Logo version="localbiniBig" width={isSmallScreen ? 130 : 230} />
					<MainTitle>{t("LOADER")}</MainTitle>
				</Box>

				{!withoutFlying && (
					<>
						<AnimationFlyingElement
							topMargin="60%"
							leftMargin="10%"
							animationDelay="1s"
							height="100px"
							width="100px"
							versionAnimation="third"
						/>

						{!isSmallScreen && (
							<AnimationFlyingElement topMargin="70%" leftMargin="70%" height="40%" width="40%" toTheLeft />
						)}

						{!isSmallScreen && (
							<AnimationFlyingElement topMargin="20%" leftMargin="80%" animationDelay="0.5s" height="20%" width="20%" />
						)}
					</>
				)}
			</Wrapper>
		</Container>
	);
};

export default Loader;
