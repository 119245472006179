import { Box } from "@material-ui/core";

import useTranslation from "hooks/useTranslation";

import { DescriptionContent } from "styles/common";

interface INumberOfParticipants {
	fromInt: number;
	toInt: number;
}

const NumberOfParticipants = ({ fromInt, toInt }: INumberOfParticipants) => {
	const { withValues } = useTranslation();

	return (
		<Box mt={5} mb={20} ml={5}>
			<DescriptionContent>
				{withValues("EXPERIENCE.NUMBER_OF_PARTICIPANTS", {
					min: fromInt,
					max: toInt,
				})}
			</DescriptionContent>
		</Box>
	);
};

export default NumberOfParticipants;
