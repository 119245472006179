import { Typography } from "@material-ui/core";
import { InputProps } from "@material-ui/core/Input/Input";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";
import { BaseTextFieldProps, TextFieldProps } from "@material-ui/core/TextField/TextField";
import { Field } from "formik";
import { isUndefined } from "lodash-es";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

import TextFieldBox from "components/forms/TextFieldBox";

import dimensions from "styles/dimensions";

// @todo:chore - components using this component should not have "value" passed
// @todo:chore - this component should extends TextFieldProps
export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	label?: ReactNode;
	className?: string;
	inputClassName?: string;
	inlineLabel?: string;
	inputAndWrapperStyles?: CSSProperties;
	inputStyles?: CSSProperties;
	endAdornment?: ReactNode;
	startAdornment?: ReactNode;
	multiline?: boolean;
	variant?: BaseTextFieldProps["variant"];
	inputProps?: InputBaseComponentProps;
	materialSize?: BaseTextFieldProps["size"];
	helperText?: BaseTextFieldProps["helperText"];
	disableUnderline?: InputProps["disableUnderline"];
	shrink?: boolean;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 1;
`;

const StyledTextField = styled(TextFieldBox)`
	margin-bottom: ${dimensions.spaces.small};
`;

const InlineLabel = styled(Typography)`
	font-size: 0.875rem;
	font-weight: 500;
`;

/**
 * @deprecated - use InputV2 instead.
 */
const Input = ({
	name,
	inputAndWrapperStyles,
	inputStyles,
	inputClassName,
	startAdornment = null,
	endAdornment = null,
	label,
	inlineLabel,
	multiline = false,
	className,
	variant = "outlined",
	readOnly = false,
	materialSize,
	inputProps,
	disableUnderline,
	shrink,
	...otherProps
}: IInput) => (
	<Field name={name}>
		{({ field, meta }) => {
			const hasError = meta.touched && !!meta.error;

			const localInputProps: TextFieldProps["InputProps"] = {
				readOnly,
				endAdornment,
				startAdornment,
				inputProps: { ...inputProps, style: { ...inputAndWrapperStyles, ...inputStyles } },
				disableUnderline,
			};

			if (isUndefined(localInputProps.disableUnderline)) {
				delete localInputProps.disableUnderline;
			}

			return (
				<Wrapper className={className}>
					{inlineLabel && <InlineLabel>{inlineLabel}</InlineLabel>}
					<StyledTextField
						variant={variant}
						error={hasError}
						helperText={hasError && meta.error}
						label={label}
						style={inputAndWrapperStyles}
						size={materialSize}
						multiline={multiline}
						InputProps={localInputProps}
						InputLabelProps={{ shrink }}
						className={inputClassName}
						{...field}
						{...otherProps}
					/>
				</Wrapper>
			);
		}}
	</Field>
);

export default Input;
