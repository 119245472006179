import { rgba } from "polished";
import { createContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import colors from "styles/colors";

import RatingsSection from "./components/RatingsSection";
import { IRateCategories, IRateIcons, IRatingsSettings, TRateResult } from "./types";

export const RateAnswersContext = createContext({
	answers: {},
	// tslint:disable-next-line:no-empty
	rate: (categoryKey: string, rateValue: number | null) => {},
});

const RatingContainer = styled.div`
	margin: 0 100px;
	position: relative;
`;

const RatingName = styled.h2`
	font-weight: bold;
	font-size: 14px;
	line-height: 1.36;
	letter-spacing: 0.44px;
	margin-bottom: 32px;
	width: 100%;
	text-align: center;
	padding-top: 10px;
	color: ${rgba(colors.mainFontColor, 0.76)};
`;

type IRatings<C> = {
	icons: IRateIcons;
	categories: IRateCategories;
	name?: string;
	update: (data?: TRateResult<C>) => void;
	settings?: IRatingsSettings;
};

const Ratings = ({
	icons,
	categories,
	name,
	update,
	settings = { withoutLabel: false, withBottomLabel: false, size: 26 },
}: IRatings<typeof categories>) => {
	type TRateResultWithCategories = TRateResult<typeof categories>;

	const [answers, setAnswers] = useState<TRateResultWithCategories>({});

	useEffect(() => {
		const convertedAnswers = {};

		Object.entries(answers).forEach(([categoryKey, answerKey]) => {
			convertedAnswers[categoryKey] = icons[answerKey]?.answer !== undefined ? icons[answerKey].answer : null;
		});

		update(convertedAnswers);

		// eslint-disable-next-line
	}, [answers]);

	const rate = useCallback(
		(categoryKey: string, rateValue: number) =>
			setAnswers({
				...answers,
				[categoryKey]: rateValue,
			}),
		[answers],
	);

	const contextValue = {
		answers,
		rate,
	};

	return (
		<RateAnswersContext.Provider value={contextValue}>
			{!!name && <RatingName>{name}</RatingName>}

			<RatingContainer>
				{Object.keys(categories).map(categoryKey => (
					<RatingsSection
						icons={icons}
						categoryName={categories[categoryKey]}
						categoryKey={categoryKey}
						key={categoryKey}
						settings={settings}
					/>
				))}
			</RatingContainer>
		</RateAnswersContext.Provider>
	);
};

export default Ratings;
