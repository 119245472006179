import { useHistory } from "react-router-dom";

type THistoryInstance = ReturnType<typeof useHistory> | undefined;

class Routing {
	private static _historyInstance: THistoryInstance;

	static get history(): THistoryInstance {
		return this._historyInstance;
	}

	static set history(instance: THistoryInstance) {
		if (!this._historyInstance && !!instance) {
			this._historyInstance = instance;
		}
	}
}

export default Routing;
