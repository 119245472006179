import { ILocalhostFinancialStatement } from "data/backoffice/financials/types";

export const columns = [
	"MY_FINANCES.WITHDRAWALS.TABLE.YEAR",
	"MY_FINANCES.WITHDRAWALS.TABLE.MONTH",
	"MY_FINANCES.WITHDRAWALS.TABLE.EARNINGS",
	"MY_FINANCES.WITHDRAWALS.TABLE.REIMBURSEMENT",
	"MY_FINANCES.WITHDRAWALS.TABLE.STATUS",
];

export const fields: (keyof ILocalhostFinancialStatement)[] = ["year", "month", "earnings", "reimbursement", "status"];

export const sortedColumns = [
	"MY_FINANCES.WITHDRAWALS.TABLE.YEAR",
	"MY_FINANCES.WITHDRAWALS.TABLE.MONTH",
	"MY_FINANCES.WITHDRAWALS.TABLE.EARNINGS",
	"MY_FINANCES.WITHDRAWALS.TABLE.REIMBURSEMENT",
	"MY_FINANCES.WITHDRAWALS.TABLE.STATUS",
];
