import { LinearProgress } from "@material-ui/core";
import { isUndefined } from "lodash-es";
import { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div<{ height?: number; width?: number }>`
	display: inline-flex;
	align-items: center;
	width: 100%;
	height: ${props => (typeof props.height === "number" ? `${props.height}px` : "auto")};

	${props => typeof props.width === "number" && `width: ${props.width}px;`}
`;

const StyledLinearProgress = styled(LinearProgress)`
	width: 100%;
`;

interface IChildrenLoader {
	children: ReactNode;
	check?: ReactNode;
	height?: number;
	width?: number;
}

const ChildrenLoader = ({ children, check, height, width }: IChildrenLoader) =>
	isUndefined(check) ? (
		<Container height={height} width={width}>
			<StyledLinearProgress />
		</Container>
	) : (
		<>{children}</>
	);

export default ChildrenLoader;
