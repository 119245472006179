import {
	IContactUsDTO,
	INotification,
	IPartnerContactFormData,
	TContactUsFields,
	TKeyOfContactUsOptions,
	TNotificationsResponse,
} from "data/notifications/types";

import API, { APISingleData } from "./index";

const internalNamespace = "internal";
const apiName = "/notifications";

const notificationsService = {
	postSendPartnerEmail: (formData: IPartnerContactFormData): Promise<unknown> =>
		API.post(internalNamespace, `${apiName}/partner/email`, {
			body: formData,
		}),
	getContactUsOptions: (): Promise<{ [K in TKeyOfContactUsOptions]: TContactUsFields }> =>
		APISingleData.get(internalNamespace, `${apiName}/contact-us/options`, {}),
	postSendContactUsEmail: (DTO: IContactUsDTO): Promise<unknown> =>
		API.post(internalNamespace, `${apiName}/contact-us/email`, {
			body: DTO,
		}),
	getSearchNotifications: (queryParams?: object): Promise<TNotificationsResponse> =>
		APISingleData.get(internalNamespace, apiName, {
			queryStringParameters: queryParams,
		}),
	getNotification: (id: string): Promise<INotification> => APISingleData.get(internalNamespace, `${apiName}/${id}`),
	patchUpdateNotification: (id: string, read: boolean): Promise<INotification> =>
		APISingleData.patch(internalNamespace, `${apiName}/${id}`, {
			body: {
				marked_as_read: read,
			},
		}),
	postMarkNotificationsAsRead: (queryParams?: object): Promise<void> =>
		APISingleData.post(internalNamespace, `${apiName}/mark-as-read`, {
			queryStringParameters: queryParams,
		}),
};

export default notificationsService;
