import { TFunction } from "i18next";
import { isArray, isPlainObject, isUndefined, unset } from "lodash-es";

import { ITranslationEnumValue } from "data/types";

import { Option } from "ui/forms/Autocomplete";

const isEqual = (first: unknown, second: unknown) =>
	JSON.stringify(first).split("").sort().join() === JSON.stringify(second).split("").sort().join();

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
const mergeDeep = (...objects) => {
	const isObject = obj => obj && typeof obj === "object";

	return objects.reduce((prev, obj) => {
		Object.keys(obj).forEach(key => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = mergeDeep(pVal, oVal);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {});
};

/**
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the initial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
const getObjectDiff = (obj1, obj2) => {
	return Object.keys(obj1).reduce((result, key) => {
		if (!obj2.hasOwnProperty(key)) {
			result.push(key);
		} else if (isEqual(obj1[key], obj2[key])) {
			const resultKeyIndex = result.indexOf(key);
			result.splice(resultKeyIndex, 1);
		}

		return result;
	}, Object.keys(obj2));
};

const objectToDotNation = object => {
	const checkIsObject = val => typeof val === "object" && !Array.isArray(val);
	const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

	const paths = (obj = {}, head = "") =>
		Object.entries(obj).reduce((item, [key, value]) => {
			const fullPath = addDelimiter(head, key);
			return checkIsObject(value) ? item.concat(paths(value as object, fullPath)) : item.concat(fullPath);
		}, []);

	return paths(object);
};

const dotNationToValue = (object, dotNationItem) => {
	if (!Object.keys(object)?.length) {
		return [];
	}

	let localDotNation = dotNationItem;
	let localObject = object;

	localDotNation = localDotNation.replace(/\[(\w+)]/g, ".$1").replace(/^\./, "");
	const pathAsArray = localDotNation.split(".");

	for (let i = 0, n = pathAsArray.length; i < n; ++i) {
		const k = pathAsArray[i];
		if (k in localObject) {
			localObject = localObject[k];
		} else {
			return [];
		}
	}

	return localObject;
};

const groupAndSumArrayOfObjects = <T extends object>(toGroupFieldName: string, toSumFieldName: string, data?: T[]) => {
	if (!data) {
		return undefined;
	}

	const groupsObject = {};

	data.forEach(elem => {
		const groupKey = elem[toGroupFieldName];

		if (groupKey === undefined) {
			return;
		}

		if (!groupsObject[groupKey]) {
			groupsObject[groupKey] = { ...elem };

			return;
		}

		const sumValue = elem[toSumFieldName];

		if (!sumValue) {
			return;
		}

		groupsObject[groupKey][toSumFieldName] += sumValue;
	});

	return Object.values(groupsObject) as T[];
};

const omit = <T extends object, K extends Extract<keyof T, string>>(originalObject: T, keys: K[]): Omit<T, K> => {
	const clonedObject = { ...originalObject };

	for (const path of keys) {
		unset(clonedObject, path);
	}

	return clonedObject;
};

const mergeBySameValue = (elements?: Record<string | number, string | number>) => {
	const output = {};
	if (!elements) {
		return output;
	}

	Object.keys(elements ?? {}).forEach(item => {
		const value = elements[item];
		if (!(value in output)) {
			output[value] = [];
		}

		output[value].push(parseFloat(item));
	});

	return output;
};

const findLastChild = (tree, nodeId) => (tree?.[nodeId] ? findLastChild(tree[nodeId], nodeId) : tree);

const countGrouped = <T extends object>(
	data: T[],
	keys: (keyof T)[],
): { count: number; keys: (keyof T)[]; values: unknown[] }[] => {
	const result = {};

	Object.values(data).forEach(item => {
		const values = keys.map(key => item[key]);
		const keyName = values.filter(part => !isUndefined(part)).join(":");

		if (isUndefined(result?.[keyName])) {
			result[keyName] = { count: 0, keys, values };
		}

		result[keyName].count++;
	});

	return Object.values(result);
};

const recursiveSearch = (obj = {}, searchKey: string, results: any[] = []): any[][] => {
	const r: any[][] = [...results];

	Object.keys(obj).forEach(key => {
		const value: any = obj[key];

		if (key === searchKey && isArray(value)) {
			r.push(value);
		} else if (isPlainObject(value)) {
			const nested = recursiveSearch(value, searchKey);
			r.push(...nested);
		}
	});

	return r;
};

const selectFromTranslationValue = <T extends string>(t: TFunction, data: ITranslationEnumValue<T>[]): Option[] =>
	data.map(item => ({ value: item.value, label: t(item.translation) }));

export {
	omit,
	mergeDeep,
	getObjectDiff,
	objectToDotNation,
	dotNationToValue,
	mergeBySameValue,
	isEqual,
	groupAndSumArrayOfObjects,
	findLastChild,
	countGrouped,
	recursiveSearch,
	selectFromTranslationValue,
};
