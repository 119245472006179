import { Box, useTheme } from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";

import useTranslation from "hooks/useTranslation";

import IconStringArray from "../../../IconStringArray";
import { TitleSection } from "../../index";

interface IKeyAttractionsProps {
	attractionsArray: string[];
}

const KeyAttractions = ({ attractionsArray }: IKeyAttractionsProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Box>
			<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_KEY_ATTRACTIONS")}</TitleSection>

			<IconStringArray
				icon={<LocationOnOutlined style={{ fontSize: 26, color: theme.palette.primary.main }} />}
				valuesArray={attractionsArray}
			/>
		</Box>
	);
};

export default KeyAttractions;
