import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import styled from "styled-components";

import EndMarker from "assets/icons/avatar-location-end-station.svg";
import StartMarker from "assets/icons/avatar-location-start-station.svg";

import useTranslation from "hooks/useTranslation";

import Map, { MarkerStyle } from "ui/maps/Map";

import colors from "styles/colors";
import { DescriptionContent, RobotoFontFamily } from "styles/common";

import { TitleSection } from "../../index";

const NavigateButton = styled.div`
	padding: 5px 15px;
	display: flex;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 500;
	align-items: center;
	justify-content: center;
	background: ${colors.white};
	color: ${colors.mainFontColor};
	border-radius: 4px;
	border: 1px solid ${colors.oceanDrive};
`;

const StyledNavigationIcon = styled.img`
	margin-right: 5px;
	width: 30px;
	height: 30px;
`;

const PopupWrapper = styled.div`
	padding: 10px;
	background: ${colors.white};
	z-index: 2000;
`;

const PopupTitle = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};
`;

const PopupAddress = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};
`;

const StyledAnchor = styled.a`
	text-decoration: none;
`;

interface ILocation {
	tips?: string;
	coordinates: {
		lat: number;
		lon: number;
	};
	formatted_address?: string;
}

interface IMapProps {
	location: {
		start: ILocation;
		end: ILocation;
	};
}

interface ILocationPopupProps {
	label: string;
	address: string;
}

const LocationPopup = ({ label, address }: ILocationPopupProps) => (
	<PopupWrapper>
		<PopupTitle>{label}</PopupTitle>
		<PopupAddress>{address}</PopupAddress>
	</PopupWrapper>
);

const MapMode = ({ location }: IMapProps) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const startPnt = {
		lat: location?.start.coordinates.lat,
		lng: location?.start.coordinates.lon,
		markerStyle: MarkerStyle.start,
		infoWindowNode: location?.start?.formatted_address && (
			<LocationPopup label={t("EXPERIENCE.FORM.MAP.start.PLACEHOLDER")} address={location.start.formatted_address} />
		),
	};

	const endPnt = {
		lat: location?.end.coordinates.lat,
		lng: location?.end.coordinates.lon,
		markerStyle: MarkerStyle.end,
		infoWindowNode: location?.end?.formatted_address && (
			<LocationPopup label={t("EXPERIENCE.FORM.MAP.end.PLACEHOLDER")} address={location.end.formatted_address} />
		),
	};

	return (
		<Box marginBottom="40px">
			<Box mb={10}>
				<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_BEGIN")}</TitleSection>
			</Box>

			{location?.start.tips && (
				<Box mb={10} display="flex" alignItems="center">
					<StyledNavigationIcon src={StartMarker} alt="Start location icon" />
					<DescriptionContent>{location?.start.tips}</DescriptionContent>
				</Box>
			)}

			{location?.end.tips && (
				<Box mb={10} display="flex" alignItems="center">
					<StyledNavigationIcon src={EndMarker} alt="End location icon" />
					<DescriptionContent>{location?.end.tips}</DescriptionContent>
				</Box>
			)}

			{isSmallScreen && (
				<Box display="flex" justifyContent="center">
					<Box display="flex" alignItems="center" marginBottom="20px">
						<StyledAnchor
							href={`https://www.google.com/maps/dir/?api=1&destination=${location?.start.coordinates.lat}%2C${location?.start.coordinates.lon}`}
							// eslint-disable-next-line
							target="_blank"
						>
							<NavigateButton>
								<StyledNavigationIcon src={StartMarker} alt="Start location icon" />
								{t("EXPERIENCE_DETAILS_TRAVELLER.GO_TO_START_POINT_BTN")}
							</NavigateButton>
						</StyledAnchor>
					</Box>
				</Box>
			)}

			<Box>
				<Map
					markers={[startPnt, endPnt]}
					fixedHeight={460}
					center={startPnt}
					zoom={13}
					renderInfoWindow={windowContent => <div>{windowContent}</div>}
				/>
			</Box>
		</Box>
	);
};

export default MapMode;
