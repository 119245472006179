import { Box, CircularProgress, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ChangeEvent, MouseEvent } from "react";

import { TConfigsCityIId } from "data/configs/types";
import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import StepBar from "ui/StepBar";

import { stepsLength } from "../../../config";
import {
	Alignment,
	InputsContainer,
	MainContainer,
	MainContent,
	StepContainer,
	StyledAutocomplete,
	StyledButton,
	TitleHeader,
} from "../../../shared.styled";

interface CitiesSelectorProps {
	selectedCities: string[];
	cities?: TConfigsCityIId[];
	handleNextButtonClick(event: MouseEvent): void;
	handleCityAutocompleteChange(event: ChangeEvent, value: TConfigsCityIId[]): void;
	isNextButtonDisabled: boolean;
}

const CitiesSelectorLayout = ({
	selectedCities,
	cities,
	handleCityAutocompleteChange,
	handleNextButtonClick,
	isNextButtonDisabled,
}: CitiesSelectorProps) => {
	const { t } = useTranslation();

	return (
		<MainContainer>
			<Alignment>
				<MainContent>
					<TitleHeader>{t("ONBOARDING.CITIES.HEADER")}</TitleHeader>

					<StepContainer>
						<StepBar activeStep={EOnboardingSteps.SelectCities} stepsAmount={stepsLength} />
					</StepContainer>

					<InputsContainer>
						{!!cities?.length ? (
							<StyledAutocomplete
								popupIcon={<SearchIcon />}
								multiple
								filterSelectedOptions
								id="cities_list"
								options={cities}
								defaultValue={selectedCities ? selectedCities.map(elem => ({ name: elem })) : []}
								onChange={handleCityAutocompleteChange}
								getOptionLabel={(city: TConfigsCityIId) => city.name}
								renderInput={params => (
									<TextField
										{...params}
										id="cities_list_input"
										label={t("ONBOARDING.CITIES.INPUT_LABEL")}
										placeholder={t("ONBOARDING.CITIES.INPUT_PLACEHOLDER")}
										variant="outlined"
										fullWidth
									/>
								)}
							/>
						) : (
							<Box height={50} display="flex" justifyContent="center" alignItems="center">
								<CircularProgress color="secondary" />
							</Box>
						)}
					</InputsContainer>

					<StyledButton
						id="cities_list_button_next_step"
						disabled={isNextButtonDisabled}
						variant="contained"
						onClick={handleNextButtonClick}
						color="primary"
					>
						{t("ONBOARDING.NEXT_BUTTON")}
					</StyledButton>
				</MainContent>
			</Alignment>
		</MainContainer>
	);
};

export default CitiesSelectorLayout;
