import { FormControl, FormHelperText, Radio } from "@material-ui/core";
import { RadioProps } from "@material-ui/core/Radio/Radio";
import { Field } from "formik";

interface IRadioField extends RadioProps {
	name: string;
	value: string | number;
}

const RadioField = ({ name, value, ...rest }: IRadioField) => (
	<Field name={name}>
		{({ field, form: { setFieldValue, setFieldTouched }, meta }) => {
			const onChange = (event, checked: boolean) => {
				if (rest.readOnly) {
					return;
				}

				setFieldTouched(name, true);

				setFieldValue(name, checked ? value : undefined);
			};

			return (
				<FormControl component="fieldset">
					<Radio onChange={onChange} name={name} checked={field.value === value} {...rest} />

					{meta.touched && !!meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
				</FormControl>
			);
		}}
	</Field>
);

export default RadioField;
