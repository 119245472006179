import { ErrorOutline } from "@material-ui/icons";
import { HTMLAttributes } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const HelperText = styled.div<{ align?: string }>`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.2;
	letter-spacing: 0.06px;
	color: ${colors.textSecondary};
	display: inline-flex;
	gap: 10px;

	align-items: ${props => props.align || "center"};
`;

const StyledIcon = styled(ErrorOutline)`
	color: ${colors.textSecondary};
	font-size: 20px;
`;

interface IPasswordHint extends HTMLAttributes<HTMLDivElement> {
	align?: string;
}

const PasswordHint = ({ align, ...props }: IPasswordHint) => {
	const { t } = useTranslation();

	return (
		<HelperText {...props} align={align}>
			<StyledIcon />
			{t("AUTH.REGISTER.PASSWORD_HELPER_TEXT")}
		</HelperText>
	);
};

export default PasswordHint;
