import { Box } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import ActionButton from "ui/buttons/ActionButton";

import Input from "../../formik/Input";
import { HeaderLogo, StyledLinkAuth, SubTitle, Title } from "../shared";

interface IForgotPasswordView {
	isPopup?: boolean;
}

const ForgotPasswordView = ({ isPopup = false }: IForgotPasswordView) => {
	const { t } = useTranslation();
	const { handleAndNotify } = useErrors();
	const history = useHistory();
	const location = useLocation();

	const { email } = qs.parse(location.search);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string().email().required(),
		}),
		initialValues: {
			email: (email as string) ?? "",
		},
		onSubmit: async values => {
			try {
				await Auth.forgotPassword(values.email.toLowerCase());

				history.push({
					pathname: paths.RESET_PASSWORD,
					search: qs.stringify({ email: values.email }),
				});
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	return (
		<Box paddingBottom="24px">
			<HeaderLogo />
			<Box textAlign="center" marginBottom="10px">
				<Title>{t(`AUTH.FORGOT_PASSWORD.HEADER`)}</Title>
			</Box>

			<Box textAlign="center" marginBottom="20px">
				<SubTitle>{t(`AUTH.FORGOT_PASSWORD.SUB-HEADER`)}</SubTitle>
			</Box>

			<FormikProvider value={formikProps}>
				<Form>
					<Input required name="email" type="email" inputClassName="full break" variant="outlined" />

					<Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
						<ActionButton
							type="button"
							isAction={formikProps.isSubmitting}
							translationDefault="AUTH.FORGOT_PASSWORD.SUBMIT_BUTTON"
							onClick={() => formikProps.handleSubmit()}
						/>
					</Box>
				</Form>
			</FormikProvider>

			{!isPopup && (
				<Box display="flex" justifyContent="center" alignItems="center" mb={5} mt={5}>
					<StyledLinkAuth to={paths.LOGIN}>{t("COMMON.CANCEL")}</StyledLinkAuth>
				</Box>
			)}
		</Box>
	);
};

export default ForgotPasswordView;
