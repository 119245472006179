import {
	Box,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Check, Close, EditOutlined } from "@material-ui/icons";
import { ChangeEvent, useState } from "react";

import { TCurrency } from "data/backoffice/financials/types";
import { getDictionary } from "data/dictionaries";
import { ECurrencySymbol } from "data/financials/types";

import useTranslation from "hooks/useTranslation";

import { prices } from "utils/prices";

import { StyledHelperText, StyledTableCell } from "../shared.styled";

export interface ITableFixedPriceProps {
	onChange: (v: number | string) => void;
	value?: number | null;
	currency?: TCurrency;
	editMode?: boolean;
	roundPrices?: boolean;
}

const earningsPart = 80;

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.background.default,
			},
		},
	}),
)(TableRow);

const TableFixedPrice = ({ onChange, value, currency, editMode = true, roundPrices = true }: ITableFixedPriceProps) => {
	const { t } = useTranslation();

	const dictionary = getDictionary("JSON_PRICES_PER_TRAVELLER");

	const [showAddRow, setShowAddRow] = useState<boolean>(false);
	const [inputValueEditPrice, setInputValueEditPrice] = useState<number | string>(
		value === undefined || value === null ? 1 : value,
	);

	const countPercentOfValue = (valueNumber: number) => Math.floor((valueNumber * earningsPart) / 100);

	const startEdit = () => {
		setShowAddRow(true);
	};

	const clearEditRow = () => {
		setShowAddRow(false);
	};

	const submit = () => {
		onChange(inputValueEditPrice);

		clearEditRow();
	};

	const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		let price: number | string = event.target.value;

		if (roundPrices) {
			price = parseInt(price, 10) || price;
		}

		setInputValueEditPrice(price);
	};

	return (
		<>
			<TableContainer>
				<Table aria-label="fixed price table">
					<TableHead>
						<TableRow>
							{dictionary.map((v, i) => (
								<StyledTableCell key={i} align="left">
									{t(`EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.HEADRES_OF_COLUMN.${v}`)}
									{v === "PRICE_PER_PERSON" && (
										<StyledHelperText>
											{t(
												"EXPERIENCE.FORM.JSON_PRICES_FOR_CHILDREN.HEADRES_OF_COLUMN.PRICE_PER_PERSON.VAT_NOT_INCLUDED",
											)}
										</StyledHelperText>
									)}
								</StyledTableCell>
							))}

							<StyledTableCell align="left" />
						</TableRow>
					</TableHead>

					<TableBody>
						<StyledTableRow key="1">
							<StyledTableCell width="25%" align="left">
								{t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.FIXED_ROW_LABEL")}
							</StyledTableCell>

							<StyledTableCell align="left">{prices(value, currency)}</StyledTableCell>

							<StyledTableCell align="left">{prices(countPercentOfValue(value || 0), currency)}</StyledTableCell>

							{editMode && (
								<StyledTableCell align="right">
									<Box display="flex" justifyContent="flex-end" flexDirection="row">
										<Box>
											<IconButton edge="end" aria-label="delete" color="inherit" onClick={startEdit}>
												<EditOutlined />
											</IconButton>
										</Box>
									</Box>
								</StyledTableCell>
							)}
						</StyledTableRow>

						{showAddRow && (
							<StyledTableRow>
								<StyledTableCell align="left">
									<TextField
										id="free-for-kids"
										label={t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.HEADRES_OF_COLUMN.PRICE_PER_PERSON")}
										InputLabelProps={{
											shrink: true,
										}}
										value={inputValueEditPrice}
										type="number"
										onChange={onInputChange}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">{!!currency ? ECurrencySymbol[currency] : ""}</InputAdornment>
											),
											inputProps: { min: 1 },
										}}
									/>
								</StyledTableCell>

								<StyledTableCell>
									<Box display="flex" flexDirection="row">
										<Box pr={4}>
											<IconButton edge="end" aria-label="accept" color="inherit" onClick={submit}>
												<Check />
											</IconButton>
										</Box>

										<Box>
											<IconButton edge="end" aria-label="close" color="inherit" onClick={clearEditRow}>
												<Close />
											</IconButton>
										</Box>
									</Box>
								</StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default TableFixedPrice;
