import { Box, Button, LinearProgress } from "@material-ui/core";
import { NearMeOutlined, Search as SearchIco } from "@material-ui/icons";
import styled, { css } from "styled-components";

import PlacesAutocompleteField from "components/forms/PlacesAutocomplete";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

export const MainSearchWrapper = styled.div`
	margin: 30px auto -10px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 110px;
	width: 100%;
	position: relative;
	border-radius: ${dimensions.radius.small};
	background: ${colors.white};
	box-shadow: rgb(55 71 79 / 17%) 0 0 8px 0;
	padding: 0 ${dimensions.spaces.xlarge};

	${media.desktop`
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    padding: ${dimensions.spaces.large};
  `};
`;

export const FilterWrapper = styled(Box)`
	flex: 1;
	margin-right: 30px;

	${media.desktop`
    padding: 0 0 20px;
    margin-right: 0;
    width: 100%;
  `}
`;

export const SearchButton = styled(Button)`
	width: 150px;
	height: 60px;

	${media.tablet`
    font-size: 0;
    max-height: 50px;
    max-width: 50px;
    min-width: 50px;
  `};
`;

export const SearchIcon = styled(SearchIco)`
	height: 22px;
	width: 18px;
	color: ${colors.white};
	margin-right: ${dimensions.spaces.xsmall};

	${media.tablet`
    margin-right: 0;
  `};
`;

export const NbExperiences = styled.h1`
	margin: 40px 0 0;
	font-family: ${MontserratFontFamily};
	font-size: 36px;
	line-height: 1.5;
	letter-spacing: 0.05px;
	font-weight: bold;
	color: ${colors.mainFontColor};

	${media.tablet`
    margin: 80px 0 40px;
    font-size: 22px;
    line-height: 1.14;
  `}
`;

const StyledInputLabel = css`
	div > label {
		width: max-content;
		font-size: 16px;
		color: ${colors.hokiColor};
	}
`;

export const StyledPlacesAutocompleteField = styled(PlacesAutocompleteField)`
	${StyledInputLabel}

	svg {
		fill: ${colors.hokiColor};
	}

	.MuiInput-root {
		border-bottom: 1px solid ${colors.sunShade};
		height: 36px;
	}
	.MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline & {
		border: none !important;
	}
`;

export const StyledLinearProgress = styled(LinearProgress)`
	margin: 50px auto;
`;

export const StyledNearMeOutlinedIcon = styled(NearMeOutlined)`
	color: ${colors.hokiColor};
	width: 18px;
	height: 14px;
`;
