import styled from "styled-components";

import colors from "styles/colors";

export interface InfoWindowProps {
	children: React.ReactNode;
}

const Wrapper = styled.div`
	margin-top: -30px;
	background-color: ${colors.white};
	min-width: 200px;
	min-height: 50px;
	padding: 10px;
	border-radius: 4px;
	z-index: 2000;
`;

const InfoWindow = ({ children }: InfoWindowProps) => {
	return <Wrapper>{children}</Wrapper>;
};

export default InfoWindow;
