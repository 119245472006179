import { Box } from "@material-ui/core";

import { DescriptionContent } from "styles/common";

import ChipStringArray from "../../../ChipStringArray";
import { TitleSection } from "../../shared.styled";

interface ILocalsChipsArrayProps {
	chipsArray: string[];
	chipsColor?: "primary" | "default";
	descStr?: string;
	titleStr?: string;
}

const LocalsChipsArray = ({ chipsArray, chipsColor = "primary", descStr, titleStr }: ILocalsChipsArrayProps) => (
	<Box>
		{titleStr && (
			<Box mb="5px">
				<TitleSection>{titleStr}</TitleSection>
			</Box>
		)}

		{descStr && (
			<Box mb={10}>
				<DescriptionContent>{descStr}</DescriptionContent>
			</Box>
		)}

		<ChipStringArray valuesArray={chipsArray} color={chipsColor} />
	</Box>
);

export default LocalsChipsArray;
