import i18n from "i18n";
import { useEffect } from "react";

import useErrors from "hooks/useErrors";
import useQuery from "hooks/useQuery";

import { languageParam, localStorageKeys } from "utils/constants";

export default function LanguageChecker() {
	const { handleAndNotify } = useErrors();

	const query = useQuery();

	const langParamFromUrl = query.get(languageParam);

	useEffect(() => {
		if (langParamFromUrl) {
			const changeLanguage = async () => {
				try {
					const lowerCaseLangParam = langParamFromUrl.toLowerCase();

					await i18n.changeLanguage(lowerCaseLangParam);

					localStorage.setItem(localStorageKeys.languages.selectedLanguage.key, lowerCaseLangParam);
				} catch (e) {
					handleAndNotify(e);
				}
			};

			changeLanguage();
		}

		// eslint-disable-next-line
	}, [langParamFromUrl]);

	return null;
}
