const dimensions = {
	spaces: {
		xsmall: "0.313rem", // 5px
		small: "0.625rem", // 10px
		medium: "0.938rem", // 15px
		large: "1.25rem", // 20px
		xlarge: "1.875rem", // 30px
		huge: "2.5rem", // 40px
		xhuge: "3.125rem", // 50px
	},
	sizes: {
		5: "0.313rem",
		8: "0.5rem",
		10: "0.625rem",
		20: "1.25rem",
		30: "1.875rem",
		40: "2.5rem",
		120: "7.5rem",
	},
	radius: {
		xsmall: "0.188rem", // 3px
		small: "0.25rem",
		medium: "0.313rem",
		large: "0.625rem",
	},
	fontSize: {
		xsmall: "9px",
		small: "11px",
		medium: "12px",
		large: "14px",
		xlarge: "16px",
		xxxlarge: "24px",
		xxlarge: "26px",
		huge: "22px",
		xhuge: "36px",
		xxhudge: "24px",
	},
	containerWidth: {
		small: "360px",
		normal: "480px",
		large: "680px",
		xlarge: "920px",
	},
	imageWidth: {
		xsmall: "64px",
		small: "128px",
		medium: "256px",
		large: "512px",
	},
};

export default dimensions;
