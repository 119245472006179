import { differenceInCalendarYears, format, isValid, parse, parseISO } from "date-fns";
import i18n, { getCurrentLocale } from "i18n";

export type TDateType = Date | string | null | undefined;

export const daysOfWeekDateIndex = {
	1: 0, // Monday
	2: 1, // Tuesday
	3: 2, // Wednesday
	4: 3, // Thursday
	5: 4, // Friday
	6: 5, // Saturday
	0: 6, // Sunday
};

export const oneMinuteInSeconds = 60;
export const oneHourInMinutes = 60;
export const oneDayInHours = 24;
export const oneWeekInDays = 7;

export const oneHourInSeconds = oneMinuteInSeconds * oneHourInMinutes;
export const oneDayInSeconds = oneHourInSeconds * oneDayInHours;
export const oneWeekInSeconds = oneDayInSeconds * oneWeekInDays;
export const oneYearInSeconds = oneDayInSeconds * 365;
export const milliSecondsInADay = 1000 * 3600 * 24;

export enum DATE_FORMATS {
	API_FORMAT_DATETIME_MS = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS",
	API_FORMAT_DATETIME = "yyyy-MM-dd'T'HH:mm:ss",

	DATE_FORMAT = "yyyy-MM-dd",
	DATE_FORMAT2 = "dd.MM.yyyy",
	DATE_FORMAT3 = "MM-dd-yyyy",
	DATE_FORMAT4 = "dd-MM-yyyy",
	DATE_FORMAT5 = "MMMM yyyy",
	DATE_FORMAT6 = "dd MMM yyyy",

	TIME_FORMAT = "HH:mm",
	TIME_FULL_FORMAT = "HH:mm:ss",
	DATETIME_FORMAT = "yyyy-MM-dd HH:mm",
	DATETIME_FORMAT2 = "dd.MM.yyyy / HH:mm",
	DATETIME_FORMAT3 = "dd MMMM yyyy HH:mm",
	DATETIME_FORMAT4 = "dd LLLL yyyy, HH:mm",
	DATETIME_FORMAT5 = "dd/MM/yyyy, HH:mm:ss",
	DATETIME_FORMAT6 = "dd.MM.yyyy HH:mm",

	DATETIME_GOOGLE_CALENDAR_FORMAT = "yyyyMMdd'T'HHmmss'Z'",
	DATETIME_GOOGLE_CALENDAR_FORMAT_WITHOUT_TIMEZONE = "yyyyMMdd'T'HHmmss",
	DATETIME_AMPM_FORMAT = "dd.MM.yyyy hh:mm aaaaa'm'",
	TIME_AMPM_FORMAT = "hh:mm aaaaa'm'",

	DAY_MONTH_SHORT_FORMAT = "dd MMM",
	DAY_MONTH_SHORT_YEAR_SHORT_FORMAT = "dd MMM yy",
	MONTH_SHORT_AND_LONG_FORMAT = "MM MMMM",
	DAY_LONG_FORMAT = "EEEE",
	QUARTER_FORMAT = "qqq",
	MONTH_YEAR_FORMAT = "MM/yyyy",

	TIME_START_DAY = "T00:00:00",
	TIME_END_DAY = "T23:59:59",

	SWISS_TIMEZONE = "Europe/Zurich",
}

export const firstDayOfCurrentYear = new Date(new Date().getFullYear(), 0, 1);
export const lastDayOfCurrentYear = new Date(new Date().getFullYear(), 11, 31);

export const dateObjectToString = (date: Date, outputPattern: DATE_FORMATS) =>
	format(date, outputPattern, { locale: getCurrentLocale() || undefined });

export const tryDateObjectToString = (date: Date, outputPattern: DATE_FORMATS) => {
	try {
		return dateObjectToString(date, outputPattern);
	} catch (e) {
		return e;
	}
};

export const parseDateFromString = (date: string, inputPattern: DATE_FORMATS) => {
	const resultDate = parse(date, inputPattern, new Date());

	return isValid(resultDate) ? resultDate : null;
};

export const adjustDateFormat = (dateString: DATE_FORMATS): DATE_FORMATS => {
	const formatWithMilliseconds = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;
	return formatWithMilliseconds.test(dateString) ? dateString : (`${dateString}.000000` as DATE_FORMATS);
};

export const dateParseAndFormat = (
	date?: TDateType,
	outputPattern: DATE_FORMATS = DATE_FORMATS.DATETIME_FORMAT,
	inputPattern: DATE_FORMATS = DATE_FORMATS.API_FORMAT_DATETIME_MS,
) => {
	if (!date) {
		return null;
	}

	try {
		let parsed;

		if (date instanceof Date) {
			parsed = date;
		} else {
			parsed = parseDateFromString(date, inputPattern);
		}

		return format(parsed, outputPattern, { locale: getCurrentLocale() || undefined });
	} catch {
		throw new Error(`${date} does not match the ${inputPattern} pattern (output: ${outputPattern})`);
	}
};

export const calculateSeniority = (fromDateString: string) =>
	differenceInCalendarYears(new Date(), new Date(fromDateString));

export const getDateWithDefaultTime = (fullDate: Date, time: "FROM" | "TO"): string => {
	const fromTime = "T00:00:00";
	const toTime = "T23:59:59";

	const formattedDate = format(fullDate, DATE_FORMATS.DATE_FORMAT);

	if (time === "TO") {
		return `${formattedDate}${toTime}`;
	}

	return `${formattedDate}${fromTime}`;
};

export const minutesToHM = (minutes?: string | number | null) => {
	if (!minutes) {
		return null;
	}

	const output: string[] = [];

	let localMinutes = parseFloat(minutes.toString());

	// days
	const days = Math.floor(localMinutes / oneHourInMinutes / oneDayInHours);

	if (days > 0) {
		localMinutes -= days * oneDayInHours * oneHourInMinutes;

		output.push(`${days}d`);
	}

	// hours
	const hours = Math.floor(localMinutes / oneHourInMinutes);

	if (hours > 0) {
		localMinutes -= hours * oneHourInMinutes;

		output.push(`${hours}h`);
	}

	// minutes
	if (localMinutes > 0) {
		output.push(`${localMinutes}m`);
	}

	return output.join(" ");
};

export const secondsToOtherFormats = (seconds?: number | null, fullName?: boolean, withWeeks?: boolean) => {
	if (!seconds) {
		return "-";
	}

	if (seconds <= oneMinuteInSeconds) {
		return seconds + (fullName ? " seconds" : " sec");
	}

	let result;

	if (seconds <= oneHourInSeconds) {
		result = Math.ceil(seconds / oneMinuteInSeconds);

		return result + (fullName ? (result > 1 ? " minutes" : " minute") : " min");
	}

	if (seconds <= oneDayInSeconds) {
		result = Math.ceil(seconds / oneHourInSeconds);

		return result + (fullName ? (result > 1 ? " hours" : " hour") : " hr");
	}

	if (!withWeeks) {
		result = Math.ceil(seconds / oneDayInSeconds);

		return result + (fullName ? (result > 1 ? " days" : " day") : " d");
	} else {
		if (seconds <= oneWeekInSeconds) {
			result = Math.ceil(seconds / oneDayInSeconds);

			return result + (fullName ? (result > 1 ? " days" : " day") : " d");
		}
	}

	result = Math.ceil(seconds / oneWeekInSeconds);

	return result + (fullName ? (result > 1 ? " weeks" : " week") : " wk");
};

export const getMonthName = (month: number) => {
	const d = new Date();

	d.setMonth(month - 1);

	const monthName = d.toLocaleString(i18n.language, { month: "long" });

	return monthName;
};

// Using parseISO is safe for dates with timezones on old Safari browsers!
export const parseISOWrapper = (
	date: string | null | undefined,
	outputFormat: DATE_FORMATS,
	placeholder?: boolean,
): string => {
	if (typeof date === "string" && !!date.length) {
		return format(parseISO(date), outputFormat, { locale: getCurrentLocale() || undefined });
	}

	if (placeholder) {
		return "-";
	}

	return "";
};

// Switzerland has winter and summer time (+1 or +2 GMT)
export const findSwissTimeZoneOffset = (): number => {
	const currentDate = Date.now();

	const offset = new Intl.DateTimeFormat("en", {
		timeZone: DATE_FORMATS.SWISS_TIMEZONE,
		timeZoneName: "shortOffset",
	})
		.formatToParts(currentDate)
		.find(elem => elem.type === "timeZoneName")?.value;

	const matchElem = offset?.match(/\d+/);

	if (matchElem) {
		return parseInt(matchElem[0], 10);
	}

	return 0;
};

export const getCurrentDateTimeFormatted = (): string => {
	const currentTime: Date = new Date();
	const options: Intl.DateTimeFormatOptions = {
		weekday: "short",
		month: "short",
		day: "2-digit",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		timeZoneName: "short",
	};

	return currentTime.toLocaleString("en", options);
};
