import { EReviewOP } from "data/drafts/types";
import { IExperience } from "data/experiences/types";
import { ISignedResponse } from "data/types";

import { AmplifyAPIRequest } from "services/aws/types";

// @todo:fix - types.
import { ISendExperienceReview } from "pages/backoffice/experiences/types";

import API, { APISingleData } from "./index";
import { generateEntityEndpoint } from "./utils/createCRUDAPI";

const internalNamespace = "internal";
const apiName = "/drafts";

const draftsService = {
	fetchAll: (queryParams?: Record<string, any>) =>
		APISingleData.get<{ count: number; data: IExperience[] }>(internalNamespace, apiName, {
			queryStringParameters: queryParams,
		}),
	getDetails: (draftId: string): Promise<IExperience> =>
		API.get(internalNamespace, generateEntityEndpoint(apiName, draftId)),
	postReview: (draftId: string, op: EReviewOP) =>
		API.post(internalNamespace, generateEntityEndpoint(apiName, draftId, "review"), {
			body: { op },
		}),
	putReview: ({ draftId, ...body }: ISendExperienceReview) =>
		API.put(internalNamespace, generateEntityEndpoint(apiName, draftId, "review"), {
			body,
		}),
	postCreate: (data: Partial<IExperience>): AmplifyAPIRequest<IExperience> =>
		API.post(internalNamespace, apiName, {
			body: data,
		}),
	patchPartialUpdate: (
		draftId: string,
		data: Partial<IExperience>,
		queryParams?: any,
	): AmplifyAPIRequest<IExperience> =>
		API.patch(internalNamespace, generateEntityEndpoint(apiName, draftId), {
			body: data,
			queryStringParameters: queryParams,
		}),
	deleteRetire: (draftId: string): Promise<void> =>
		API.del(internalNamespace, generateEntityEndpoint(apiName, draftId)),
	deleteRemoveFromGallery: (draftId: string, mediaId: string) =>
		API.del(internalNamespace, generateEntityEndpoint(apiName, draftId, `media/${mediaId}`)),
	getSignedMediaUrl: (draftId: string, queryParams?: any): Promise<ISignedResponse> =>
		API.get(internalNamespace, generateEntityEndpoint(apiName, draftId, "media/url"), {
			queryStringParameters: queryParams,
		}),
	patchChangeOwner: (draftId: string, createdBy: string) =>
		API.patch(internalNamespace, `${apiName}/${draftId}/created-by`, {
			body: { created_by: createdBy },
		}),
	patchChangeDesigner: (draftId: string, designedBy: string | null) =>
		API.patch(internalNamespace, `${apiName}/${draftId}/designed-by`, {
			body: { designed_by: designedBy },
		}),
	postConvertToBinipool: (draftId: string) => API.post(internalNamespace, `${apiName}/${draftId}/convert-to-binipool`),
	deleteRemoveFieldFromDraft: (draftId: string, field: string) =>
		API.del(internalNamespace, `${apiName}/${draftId}/fields/${field}`),
};

export default draftsService;
