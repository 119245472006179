export enum ECategories {
	ART_CULTURE = "Art & Culture",
	FOOD_DRINKS = "Food & Drinks",
	NIGHT_LIFE = "Night Life",
	HISTORY = "History",
	SPORT = "Sport",
	ARCHITECTURE = "Architecture",
	LIFESTYLE = "Lifestyle",
	ADVENTURE = "Adventure",
	NATURE = "Nature",
	FASHION = "Fashion",
	PHOTOGRAPHY = "Photography",
	DISCOVERY = "Discovery",
}

export type TCategory = keyof typeof ECategories;
