import { Box, FormHelperText, Menu, useTheme } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { SVGProps, useState } from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const InnerWrapper = styled(Box)<{ $borderColor: string; $disabled?: boolean }>`
	display: flex;
	justify-content: space-between;
	position: relative;

	border-bottom: ${props => `1px solid ${props.$borderColor}`};
	cursor: ${props => (props.$disabled ? "default" : "pointer")};

	${props =>
		props.$disabled &&
		css`
			border-bottom-style: dotted;
		`}
`;

const Label = styled.label<{ $disabled?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	margin-bottom: 5px;

	color: ${props => (props.$disabled ? "rgba(0, 0, 0, 0.38)" : colors.bermudaGray)};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Icon = styled.span`
	width: 28px;
`;

const Text = styled.span`
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

interface InfoInputProps {
	onClick?: any;
	className?: string;
	label?: string;
	ariaOwns?: string;
	text?: React.ReactNode;
	icon?: SVGProps<SVGElement>;
	withChevron?: boolean;
	overlayContent?: React.ReactNode;
	menuStyle?: React.CSSProperties;
	error?: string;
	borderColor?: string;
	disabled?: boolean;
	externalHandleOpen?(): boolean;
	externalHandleClose?(): Promise<boolean>;
}

export const idForContainer = "info-input-selector";
export const innerWrapperClassName = "info-input__inner-wrapper";

const InfoInput = ({
	className,
	error,
	icon,
	withChevron,
	menuStyle,
	label,
	text,
	overlayContent,
	borderColor = "rgba(0, 0, 0, 0.42)",
	disabled,
	externalHandleOpen,
	externalHandleClose,
}: InfoInputProps) => {
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		if (!!externalHandleOpen) {
			return externalHandleOpen() && setAnchorEl(event.currentTarget);
		}

		return setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const ariaOwns = anchorEl ? "expand-menu" : undefined;

	return (
		<>
			<Wrapper
				id={idForContainer}
				className={className}
				aria-owns={ariaOwns}
				onClick={disabled ? undefined : handleClick}
			>
				<Label $disabled={disabled}>{label}</Label>

				<InnerWrapper $borderColor={borderColor} $disabled={disabled} className={innerWrapperClassName}>
					<Icon>{icon}</Icon>

					<Text>{text}</Text>

					{withChevron && (
						<ChevronLeft
							style={{
								transform: ariaOwns ? "rotate(-90deg)" : "rotate(90deg)",
							}}
						/>
					)}
				</InnerWrapper>

				{!!error && <FormHelperText error>{error}</FormHelperText>}
			</Wrapper>

			<Menu
				id="info-input-menu"
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={async () => {
					if (!!externalHandleClose) {
						return (await externalHandleClose()) && handleClose();
					}

					return handleClose();
				}}
				PaperProps={{
					style: {
						marginTop: 50,
						width: 638,
						height: 330,
						backgroundColor: colors.white,
						boxShadow: theme.shadows[3],
						padding: theme.spacing(5),
						...menuStyle,
					},
				}}
			>
				{overlayContent}
			</Menu>
		</>
	);
};

export default InfoInput;
