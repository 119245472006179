import { Box, Button, CircularProgress } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import slugify from "slugify";

import { ReactComponent as IconNotBadLarge } from "assets/icons/ratings/large/rate_icon_3color.svg";

import { EStatusHostingRequest, IPendingRequest } from "data/backoffice/bookings/types";
import { ERejectionReasonType } from "data/notifications/types";

import useErrors from "hooks/useErrors";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import bookingsService from "services/api/bookings";
import experiencesService from "services/api/experiences";

import useLanguages from "store/hooks/useLanguages";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import DashboardPendingRequestCard from "components/dashboard/DashboardPendingRequestCard";
import DashboardPendingRequestPreview from "components/dashboard/DashboardPendingRequestPreview";

import FilterButton from "ui/buttons/FilterButton";
import { EVariantButton } from "ui/buttons/FilterButton/types";
import Dialog from "ui/Dialog";

import {
	CircularProgressContent,
	FiltersWrapper,
	ModalContainer,
	ModalTitle,
	StyledDividerSection,
	TitleSection,
	Wrapper,
} from "./styled";

const initialResults = { data: [], count: 0 };
const requestPerPage = 3;

interface IResult {
	id: string;
	sid: string;
	eid: string;
	title: string;
	date_time: string;
	duration: number;
	earning: string;
	meeting_spot: string;
	language: string;
	statusPendingRequest: EStatusHostingRequest;
	linkSuggestedItinerary?: string;
	seen_on: string | null;
	totalTravelers: number;
	totalGroups: number;
	photo?: string;
}

const requestsLabels: Partial<Record<EStatusHostingRequest, string>> = {
	[EStatusHostingRequest.SENT]: "MY_BOOKINGS.PENDING_REQUESTS_TITLE",
	[EStatusHostingRequest.REJECTED]: "MY_BOOKINGS.REJECTED_REQUESTS_TITLE",
	[EStatusHostingRequest.TIMED_OUT]: "MY_BOOKINGS.MISSED_REQUESTS_TITLE",
};

const PendingRequests = memo(() => {
	const { t, withRaw } = useTranslation();

	const { handleAndNotify } = useErrors();

	const { findNameByKey } = useLanguages();

	const history = useHistory();
	const query = useQuery();
	const hno = query.get("hno");

	const [results, setResults] = useState<IResult[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [offsetValue, setOffsetValue] = useState<number>(0);
	const [showDetailsPendingRequest, setShowDetailsPendingRequest] = useState<boolean>(false);
	const [selectedPendingRequest, setSelectedPendingRequest] = useState<IResult | null>(null);
	const [pendingRequestsList, setPendingRequestsList] = useState<{ data: IPendingRequest[]; count: number }>(
		initialResults,
	);
	const [reloadList, setReloadList] = useState<boolean>(false);
	const [filterStatus, setFilterStatus] = useState<EStatusHostingRequest>(EStatusHostingRequest.SENT);
	const [initialDetailsShowed, setInitialDetailsShowed] = useState<boolean>(false);
	const [tooLateDialog, setTooLateDialog] = useState<boolean>(false);
	const [showRejectionReasons, setShowRejectionReasons] = useState<boolean>(false);
	const [showFeedbackReasonModal, setShowFeedbackReasonModal] = useState<boolean>(false);

	const fetchHostingNotifications = async (reset: boolean) => {
		try {
			setIsLoading(true);

			const todayFrom = getDateWithDefaultTime(new Date(), "FROM");

			const hostingNotifications = await bookingsService.getHostingNotifications({
				status:
					filterStatus === EStatusHostingRequest.TIMED_OUT
						? [EStatusHostingRequest.TIMED_OUT, EStatusHostingRequest.CANCELED]
						: filterStatus,
				sort: "date_time",
				order: filterStatus === EStatusHostingRequest.SENT ? "asc" : "desc",
				offset: reset ? 0 : offsetValue,
				limit: requestPerPage,
				// Date_time_from is for booking date_time, is required to don't show requests for past bookings!
				...(filterStatus === EStatusHostingRequest.SENT && { date_time_from: todayFrom }),
			});

			if (!!hno && !initialDetailsShowed) {
				try {
					const hnoNotification = await bookingsService.getHostingNotificationDetails(hno);

					let expDetails;

					try {
						expDetails = await experiencesService.getExperienceById(hnoNotification.superbooking_details.eid);
					} catch (e) {
						handleAndNotify(e);
					}

					if (hnoNotification.status === EStatusHostingRequest.ACCEPTED) {
						history.push(fillRoute(paths.MY_BOOKINGS, { search: { sbk: hnoNotification.superbooking_id } }));

						return;
					} else if (hnoNotification.status !== EStatusHostingRequest.SENT) {
						setTooLateDialog(true);
					} else {
						showBookingDetails({
							id: hnoNotification.id,
							sid: hnoNotification.superbooking_id,
							eid: hnoNotification.superbooking_details.eid,
							title: hnoNotification.superbooking_details.exp_title,
							date_time: hnoNotification.superbooking_details.date_time,
							duration: expDetails?.duration_minutes || 0,
							earning: prices(
								hnoNotification.localhost_earnings?.value,
								hnoNotification.localhost_earnings?.currency,
								undefined,
								undefined,
								true,
							),
							meeting_spot: expDetails?.location.start.formatted_address,
							photo: expDetails?.photo_main,
							language: hnoNotification.superbooking_details.language,
							statusPendingRequest: hnoNotification.status,
							linkSuggestedItinerary: expDetails?.internal?.suggested_route,
							seen_on: hnoNotification.seen_on,
							totalTravelers: hnoNotification.superbooking_details.total_travelers,
							totalGroups: hnoNotification.superbooking_details.total_groups,
						});
					}

					setInitialDetailsShowed(true);
				} catch {
					handleAndNotify(t("MY_BOOKINGS.NOTIFICATIONS_WRONG_URL_PARAM"));

					setInitialDetailsShowed(true);
				}
			}

			setPendingRequestsList(hostingNotifications);

			if (hostingNotifications.data) {
				const expIds = hostingNotifications.data.map(elem => elem.superbooking_details.eid);

				const callsToExperiencesApi = expIds.map(elem =>
					experiencesService.getExperienceById(elem).catch(e => handleAndNotify(e)),
				);

				const experiences = await Promise.all<any>(callsToExperiencesApi);

				const tempDetailsList: IResult[] = hostingNotifications.data.map((v, i) => {
					const exp = experiences[i];

					return {
						id: v.id,
						sid: v.superbooking_id,
						eid: v.superbooking_details.eid,
						title: v.superbooking_details.exp_title,
						date_time: v.superbooking_details.date_time,
						duration: exp?.duration_minutes || 0,
						earning: prices(v.localhost_earnings?.value, v.localhost_earnings?.currency, undefined, undefined, true),
						meeting_spot: exp?.location.start.formatted_address,
						photo: exp?.photo_main,
						language: v.superbooking_details.language,
						statusPendingRequest: v.status,
						linkSuggestedItinerary: exp?.internal?.suggested_route,
						seen_on: v.seen_on,
						totalTravelers: v.superbooking_details.total_travelers,
						totalGroups: v.superbooking_details.total_groups,
					};
				});

				if (reset) {
					setResults(tempDetailsList);
					setReloadList(false);
				} else {
					setResults(prevState => [...prevState, ...tempDetailsList]);
				}
			}
		} catch (e) {
			setResults([]);

			handleAndNotify(e);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (offsetValue > 0) {
			fetchHostingNotifications(false);
		}

		// eslint-disable-next-line
	}, [offsetValue]);

	useEffect(() => {
		fetchHostingNotifications(true);
		setOffsetValue(0);

		// eslint-disable-next-line
	}, [filterStatus, hno]);

	useEffect(() => {
		if (reloadList) {
			fetchHostingNotifications(true);

			setOffsetValue(0);
		}

		// eslint-disable-next-line
	}, [reloadList]);

	const showBookingDetails = (details: IResult) => {
		setShowDetailsPendingRequest(true);
		setSelectedPendingRequest(details);
	};

	const handleCloseDetailsDialog = () => {
		setShowRejectionReasons(false);
		setShowDetailsPendingRequest(false);
		setSelectedPendingRequest(null);
	};

	const sendPendingRequest = async (
		status: EStatusHostingRequest.ACCEPTED | EStatusHostingRequest.REJECTED,
		notificationId: string,
		reason?: { rejection_reason: ERejectionReasonType; rejection_comment?: string },
	) => {
		try {
			await bookingsService.patchPartialUpdateHostingNotification(notificationId, {
				status,
				...reason,
			});
		} catch (e) {
			handleAndNotify(e);
		}
		setReloadList(true);
		setShowDetailsPendingRequest(false);
		setShowRejectionReasons(false);
		if (reason && reason.rejection_reason === ERejectionReasonType.TIME) {
			setShowFeedbackReasonModal(true);
		}
	};

	const labelKey = requestsLabels?.[filterStatus];

	const sendSeen = () => {
		if (selectedPendingRequest?.seen_on) {
			return;
		}

		try {
			bookingsService.patchPartialUpdateHostingNotification(selectedPendingRequest?.id || "", { seen: true });
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const handleRejectionReasonModal = showModal => {
		setShowRejectionReasons(showModal);
	};

	return (
		<Box width="100%">
			<DashboardPendingRequestPreview
				title={selectedPendingRequest?.title || ""}
				startDate={parseISOWrapper(selectedPendingRequest?.date_time, DATE_FORMATS.DATETIME_FORMAT3, true)}
				startLocation={selectedPendingRequest?.meeting_spot || ""}
				language={findNameByKey(selectedPendingRequest?.language) || "-"}
				yourEarnings={selectedPendingRequest?.earning || ""}
				linkExp={
					selectedPendingRequest?.eid
						? fillRoute(paths.SEARCH_EXPERIENCES, {
								title: slugify(selectedPendingRequest.title.toLowerCase() || ""),
								id: selectedPendingRequest.eid,
						  })
						: ""
				}
				onClickDecline={() => handleRejectionReasonModal(true)}
				onClickAccept={() => sendPendingRequest(EStatusHostingRequest.ACCEPTED, selectedPendingRequest?.id || "")}
				onClickSendRejectionReason={reason =>
					sendPendingRequest(EStatusHostingRequest.REJECTED, selectedPendingRequest?.id || "", reason)
				}
				showDialog={showDetailsPendingRequest && !!selectedPendingRequest}
				handleClose={handleCloseDetailsDialog}
				statusPendingRequest={selectedPendingRequest?.statusPendingRequest}
				linkSuggestedItinerary={selectedPendingRequest?.linkSuggestedItinerary}
				sendSeen={sendSeen}
				totalTravelers={selectedPendingRequest?.totalTravelers}
				totalGroups={selectedPendingRequest?.totalGroups}
				showRejectionReasons={showRejectionReasons}
				isLoading={isLoading}
			/>

			<StyledDividerSection />

			<Box display="flex" justifyContent="space-between" marginBottom="21px" flexWrap="wrap">
				<TitleSection>
					{!!labelKey && t(labelKey)}:{" "}
					<b>
						{pendingRequestsList.count > 0 &&
							requestPerPage < pendingRequestsList.count &&
							`${
								offsetValue + requestPerPage < pendingRequestsList.count
									? offsetValue + requestPerPage
									: pendingRequestsList.count
							} ${t("COMMON.OF")} `}
						{pendingRequestsList.count}
					</b>
				</TitleSection>

				<FiltersWrapper>
					<FilterButton
						isChecked={filterStatus === EStatusHostingRequest.REJECTED}
						variant={EVariantButton.listed}
						handleClickFilter={() => setFilterStatus(EStatusHostingRequest.REJECTED)}
						nameFilter="rejected"
					/>

					<FilterButton
						isChecked={filterStatus === EStatusHostingRequest.SENT}
						variant={EVariantButton.binipool}
						handleClickFilter={() => setFilterStatus(EStatusHostingRequest.SENT)}
						nameFilter="pending"
					/>

					<FilterButton
						isChecked={filterStatus === EStatusHostingRequest.TIMED_OUT}
						variant={EVariantButton.go}
						handleClickFilter={() => setFilterStatus(EStatusHostingRequest.TIMED_OUT)}
						nameFilter="missed"
					/>
				</FiltersWrapper>
			</Box>

			{isLoading ? (
				<CircularProgressContent>
					<CircularProgress color="primary" />
				</CircularProgressContent>
			) : (
				<>
					<Dialog decorator="withoutLogo" showDialog={tooLateDialog} handleClose={() => setTooLateDialog(false)}>
						<ModalContainer>
							<IconNotBadLarge />

							<ModalTitle>{t("MY_BOOKINGS.PENDING_REQUESTS.MODAL_TOO_LATE.TITLE")}</ModalTitle>

							{withRaw("MY_BOOKINGS.PENDING_REQUESTS.MODAL_TOO_LATE.CONTENT", null)}
						</ModalContainer>
					</Dialog>

					<Wrapper>
						{results.map(value => (
							<DashboardPendingRequestCard
								key={value.id}
								title={value.title}
								yourPotentialEarning={value.earning}
								date={parseISOWrapper(value.date_time, DATE_FORMATS.DATETIME_FORMAT3)}
								time={value.duration}
								meetingSpot={value.meeting_spot}
								pictureUrl={value.photo}
								onClickShowDetails={() => showBookingDetails(value)}
							/>
						))}
					</Wrapper>

					{pendingRequestsList.count > requestPerPage && results.length !== pendingRequestsList.count && (
						<Box display="flex" alignItems="center" justifyContent="center">
							<Button
								variant="contained"
								color="primary"
								onClick={() => setOffsetValue(prevState => prevState + requestPerPage)}
							>
								{t("SEARCH.EXPERIENCES.SHOW_MORE")}
							</Button>
						</Box>
					)}
				</>
			)}

			<Dialog showDialog={showFeedbackReasonModal} handleClose={() => setShowFeedbackReasonModal(false)}>
				<ModalContainer>
					<ModalTitle>{t("ui:DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.FEEDBACK_MESSAGE")}</ModalTitle>
				</ModalContainer>
			</Dialog>
		</Box>
	);
});

export default PendingRequests;
