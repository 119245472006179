import { Box, CircularProgress, createStyles, makeStyles, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { Form, FormikProvider, useFormik } from "formik";
import qs from "query-string";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import EmailIcon from "assets/icons/email.svg";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import ActionButton from "ui/buttons/ActionButton";

import CodeInput from "../../formik/CodeInput";
import { HeaderLogo } from "../shared";

interface IConfirmEmailPage {
	onboarding?: boolean;
}

interface IConfirmEmailForm {
	code: string;
}

const useStyles = makeStyles(() =>
	createStyles({
		confirmTitle: {
			fontSize: "24px",
			fontWeight: "bold",
		},
		anchorStyle: {
			color: "#000",
		},
	}),
);

const ConfirmEmailView = ({ onboarding = false }: IConfirmEmailPage) => {
	const notification = useNotification();
	const { handleAndNotify } = useErrors();

	const history = useHistory();
	const location = useLocation();

	const { t, withValuesAsString } = useTranslation();

	const classes = useStyles();

	const [loader, setLoader] = useState<boolean>(false);

	const { email, username, token, type } = qs.parse(location.search);

	const binipool = type === "binipool";

	const formikProps = useFormik<IConfirmEmailForm>({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			code: Yup.string()
				.required(t("ERRORS.REQUIRED"))
				.matches(/^[0-9]{6}$/, withValuesAsString("ERRORS.EXACTLY_LENGTH", { length: 6 })),
		}),
		initialValues: {
			code: "",
		},
		onSubmit: async values => await confirmAccount(values.code),
	});

	const confirmAccount = async (confirmationToken: string) => {
		try {
			setLoader(true);

			await Auth.confirmSignUp(username as string, confirmationToken);

			history.push({
				pathname: onboarding ? fillRoute(paths.ONBOARDING_LOGIN) : paths.LOGIN,
				search: qs.stringify({ email, ...(binipool ? { type: "binipool" } : {}) }),
			});
		} catch (e) {
			// @ts-ignore
			if (e?.message === "User cannot be confirmed. Current status is CONFIRMED") {
				history.push({
					pathname: onboarding ? fillRoute(paths.ONBOARDING_LOGIN) : paths.LOGIN,
					search: qs.stringify({ email, ...(binipool ? { type: "binipool" } : {}) }),
				});
			} else {
				handleAndNotify(e);
			}
		} finally {
			setLoader(false);
		}
	};

	const handleResend = async () => {
		try {
			await Auth.resendSignUp(username as string);

			notification.success();
		} catch (e) {
			handleAndNotify(e);
		}
	};

	useEffect(() => {
		if (token) {
			confirmAccount(token as string);
		}
		// eslint-disable-next-line
	}, []);

	if (!email || !username) {
		return <Redirect to={paths.LOGIN} />;
	}

	return (
		<FormikProvider value={formikProps}>
			<Form onSubmit={formikProps.handleSubmit}>
				<HeaderLogo />

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<img alt="email icon" src={EmailIcon} height={"48px"} width={"52px"} />
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<Typography className={classes.confirmTitle} variant="h1">
						{t("AUTH.CONFIRM_EMAIL.HEADER")}
					</Typography>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<Typography align="center">{t("AUTH.CONFIRM_EMAIL.SUB-HEADER")}</Typography>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<Typography style={{ fontWeight: "bold", wordBreak: "break-all" }}>{email}</Typography>
				</Box>

				<Box display="flex" flexDirection="column" justifyContent="center" mb={10} textAlign="center">
					{loader ? (
						<Box>
							<CircularProgress color="primary" />
						</Box>
					) : (
						<CodeInput fields={6} name="code" pattern="[0-9]+" inputMode="numeric" autoFocus />
					)}
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<ActionButton
						translationDefault="AUTH.CONFIRM_EMAIL.SUBMIT_BUTTON"
						isAction={formikProps.isSubmitting || loader}
						disabled={loader}
					/>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={10}>
					<Typography align="center">{t("AUTH.CONFIRM_EMAIL.CHECK_SPAM_DESC")}</Typography>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center" mb={5}>
					<Typography>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a
							className={classes.anchorStyle}
							href="#"
							onClick={e => {
								e.preventDefault();

								handleResend();
							}}
						>
							{t("AUTH.CONFIRM_EMAIL.RESEND_ANCHOR")}
						</a>
					</Typography>
				</Box>
			</Form>
		</FormikProvider>
	);
};

export default ConfirmEmailView;
