import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setUser as sentrySetUser } from "@sentry/react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import axios from "axios";

import { Errors } from "classes/Errors";

import { DeepPartial } from "data/types";
import { EUserType, IAuthenticatedUser, IUser } from "data/users/types";

import authorizationServices from "services/api/authorization";
import { IUserAuthByAccessToken, IUserGetMediaUrlQueryParams } from "services/api/types";
import usersService from "services/api/users";

import { selectUser } from "store/selectors/user";
import { fetchConfigsAll } from "store/slices/configs";

import { TRootState } from "../index";

type TGuestData = {
	trigger?: string | null;
} & IUserAuthByAccessToken;

interface IUserState {
	data: (CognitoUser & IAuthenticatedUser) | null;
	status: "idle" | "busy" | "error";
	userById: IUser | null;
	userByIdStatus: "idle" | "busy" | "error";
	guest: {
		data: TGuestData | null;
		status: "idle" | "loading" | "success" | "error";
	};
}

const initialState: IUserState = {
	data: null,
	status: "idle",
	userById: null,
	userByIdStatus: "idle",
	guest: {
		data: null,
		status: "idle",
	},
};

// @todo:fix - types for user methods.
// interface IFetchAuthenticatedUserProps {
//   cognitoData?: UserData;
//   accountType?: TUserType;
// }
// (params: IFetchAuthenticatedUserProps | null, thunkAPI)

const fetchAuthenticatedUser = createAsyncThunk(
	"user/fetch",
	async (
		params: {
			cognitoData?: any;
			accountType?: EUserType;
			dontCatchUserApiError?: boolean;
			reFetchConfig?: boolean;
		} | null,
		thunkAPI,
	) => {
		try {
			if (params?.reFetchConfig) {
				thunkAPI.dispatch(fetchConfigsAll());
			}

			let session;

			if (!params?.cognitoData) {
				session = await Auth.currentAuthenticatedUser();
			}

			let userData;

			if (params?.dontCatchUserApiError) {
				userData = await usersService.fetchById(params?.cognitoData?.username || session.username);
			} else {
				try {
					userData = await usersService.fetchById(params?.cognitoData?.username || session.username);
				} catch {
					userData = {};
				}
			}

			const previousState = selectUser(thunkAPI.getState() as TRootState);

			let userType =
				params?.accountType || params?.cognitoData?.attributes["custom:type"] || session.attributes["custom:type"];
			const originalUserType = params?.cognitoData?.attributes["custom:type"] || session.attributes["custom:type"];

			const previousUserType = previousState?.userType;

			if (previousUserType && !params?.accountType) {
				userType = previousUserType;
			}

			const data = {
				...userData,
				userType,
				originalUserType,
			};

			const sentryData = {
				id: userData.id,
				ip_address: "{{auto}}",
				email: userData?.personal?.email,
				username: `${userData.public_profile?.first_name || userData.profile_draft?.first_name || ""} ${
					userData.private_profile?.last_name || userData.profile_draft?.last_name || ""
				}`,
			};

			sentrySetUser(sentryData);

			return data;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const fetchUserById = createAsyncThunk("user/fetchById", async (uid: string, thunkAPI) => {
	try {
		const userData = await usersService.fetchById(uid);

		return userData;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

const updateUserProfile = createAsyncThunk<any, { data: DeepPartial<IUser>; uid?: string }>(
	"user/update",
	async (params, thunkAPI) => {
		try {
			let currentUid;

			if (!params.uid) {
				const state = thunkAPI.getState();
				// @ts-ignore
				currentUid = state.user.data.id;
			} else {
				currentUid = params.uid;
			}

			await usersService.updateById(currentUid, params.data);

			return;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const getUserProfileMediaUrl = createAsyncThunk<
	any,
	{ file: Blob; queryParams: IUserGetMediaUrlQueryParams; uid?: string }
>("user/getMediaUrl", async (data, thunkAPI) => {
	try {
		let currentUid;

		if (!data.uid) {
			const state = thunkAPI.getState();
			// @ts-ignore
			currentUid = state.user.data.id;
		} else {
			currentUid = data.uid;
		}

		const mediaUrlObject = await usersService.getUserMediaUrl(currentUid, data.queryParams);

		const formData = new FormData();

		Object.keys(mediaUrlObject.fields).forEach(key => formData.append(key, mediaUrlObject.fields[key]));
		formData.append("file", data.file);

		await axios.post(mediaUrlObject.url, formData);

		return;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

const deleteUserProfileMedia = createAsyncThunk<any, { mediaId: string; uid?: string }>(
	"user/deleteMedia",
	async (data, thunkAPI) => {
		try {
			let currentUid;

			if (!data.uid) {
				const state = thunkAPI.getState();
				// @ts-ignore
				currentUid = state.user.data.id;
			} else {
				currentUid = data.uid;
			}

			await usersService.deleteUserMedia(currentUid, data.mediaId);

			return;
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}
	},
);

const fetchGuestAuthCredentials = createAsyncThunk(
	"user/fetchGuestAuthCredentials",
	async (data: { token: string; trigger?: string }, thunkAPI) => {
		try {
			const { token, trigger } = data;

			const authData = await authorizationServices.getUserAuthByAccessToken(token);

			return { ...authData, ...(trigger && { trigger }) };
		} catch (error) {
			Errors.useErrors?.handleAndNotify(error);

			return thunkAPI.rejectWithValue(error);
		}
	},
);

const { actions, reducer } = createSlice({
	name: "user",
	initialState,
	reducers: {
		reset() {
			return initialState;
		},
		resetUserById(state) {
			return {
				...state,
				userById: null,
				userByIdStatus: "idle",
			};
		},
		resetGuestStatus(state) {
			state.guest.status = "idle";
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAuthenticatedUser.fulfilled, (state, action) => {
				state.data = action.payload;
				state.status = initialState.status;
			})
			.addCase(fetchAuthenticatedUser.rejected, state => {
				state.status = "error";
			})
			//
			.addCase(fetchUserById.fulfilled, (state, action) => {
				state.userById = action.payload;
				state.userByIdStatus = initialState.userByIdStatus;
			})
			.addCase(fetchUserById.rejected, state => {
				state.userById = null;
				state.userByIdStatus = "error";
			})
			//
			.addCase(updateUserProfile.fulfilled, state => {
				state.status = initialState.status;
			})
			.addCase(updateUserProfile.rejected, state => {
				state.status = "error";
			})
			.addCase(updateUserProfile.pending, state => {
				state.status = "busy";
			})
			//
			.addCase(fetchGuestAuthCredentials.fulfilled, (state, action) => {
				state.guest = {
					data: action.payload,
					status: "success",
				};
			})
			.addCase(fetchGuestAuthCredentials.rejected, state => {
				state.guest = {
					data: null,
					status: "error",
				};
			})
			.addCase(fetchGuestAuthCredentials.pending, state => {
				state.guest = {
					data: null,
					status: "loading",
				};
			});
	},
});

export const userActions = actions;

export {
	fetchAuthenticatedUser,
	fetchUserById,
	updateUserProfile,
	getUserProfileMediaUrl,
	deleteUserProfileMedia,
	fetchGuestAuthCredentials,
};

export default reducer;
