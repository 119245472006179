import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export type TSorter = "UP" | "DOWN" | "NEUTRAL";

interface FilterProps {
	label: string;
	onChange: (Status) => void;
	value: TSorter;
	className?: string;
}

interface SorterIconProps {
	type: TSorter;
	onClick: (Status) => void;
}

const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 22px;

	& svg:hover {
		cursor: pointer;
	}
`;

const Label = styled.span`
	margin-right: 5px;
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 2.08;
	color: ${colors.mainFontColor};
`;

const CommonMoreLess = css`
	width: 30px;
	height: 20px;
	font-weight: bold;
`;

const StyledExpandLess = styled(ExpandLess)`
	margin-bottom: -5px;
	${CommonMoreLess}
`;

const StyledExpandMore = styled(ExpandMore)`
	${CommonMoreLess}
`;

const SorterIcon = ({ type, onClick }: SorterIconProps) => {
	return (
		<IconWrapper>
			<StyledExpandLess
				onClick={() => {
					type === "UP" ? onClick("NEUTRAL") : onClick("UP");
				}}
				fontSize="small"
				color={type === "UP" ? "primary" : "inherit"}
			/>

			<StyledExpandMore
				onClick={() => {
					type === "DOWN" ? onClick("NEUTRAL") : onClick("DOWN");
				}}
				fontSize="small"
				color={type === "DOWN" ? "primary" : "inherit"}
			/>
		</IconWrapper>
	);
};

const Sorter = ({ label, className, value = "NEUTRAL", onChange }: FilterProps) => {
	return (
		<FilterWrapper className={className}>
			<Label>{label}</Label>
			<SorterIcon onClick={onChange} type={value} />
		</FilterWrapper>
	);
};

export default Sorter;
