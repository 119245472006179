import slugify from "slugify";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTraveller } from "store/selectors/experienceTraveller";

import fillRoute from "utils/routes";

import Breadcrumbs from "ui/Breadcrumbs";

interface IBreadCrumbsBookingProps {
	lastBreadcrumb?: string;
}

const BreadCrumbsBooking = ({ lastBreadcrumb }: IBreadCrumbsBookingProps) => {
	const { t } = useTranslation();

	const experience = useAppSelector(selectExperienceTraveller);

	if (!experience) {
		return null;
	}

	const title = experience.data?.title || "";
	const country = experience.data?.country || "";
	const city = experience.data?.city || "";
	const id = experience.data?.id || "";

	return (
		<Breadcrumbs
			withoutHomepage
			list={[
				{ route: fillRoute(paths.SEARCH_COUNTRY, { country }), label: country },
				{ route: fillRoute(paths.SEARCH_COUNTRY_CITY, { country, city }), label: city },
				{ route: fillRoute(paths.SEARCH_EXPERIENCES, { title: slugify(title.toLowerCase()), id }), label: title },
			]}
			customFirstBreadcrumb={{ route: paths.SEARCH, label: t("EXPERIENCE.BACK_TO_EXPERIENCES_BTN") }}
			lastBreadcrumbLabel={lastBreadcrumb}
		/>
	);
};

export default BreadCrumbsBooking;
