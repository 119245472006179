import { Box, Grid, Step, StepLabel, Stepper, useMediaQuery, useTheme } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { EPromoCodesUsageStatus, EPromoCodesUsageType, TPromoCodesUsageIId } from "data/promo-codes/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import promoCodesService from "services/api/promoCodes";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import ElemClipboard from "components/backoffice/ElemClipboard";
import WelcomeOnPage from "components/dashboard/DashboardWelcome";
import AppWrapper from "components/layout/AppWrapper";
import GenerateReferralBox from "components/referrals/GenerateReferralBox";

import Breadcrumbs from "ui/Breadcrumbs";
import ChildrenLoader from "ui/loaders/ChildrenLoader";
import CircularLoader from "ui/loaders/CircularLoader";
import Table, { Order } from "ui/Table";

import colors from "styles/colors";
import { Container } from "styles/common";

import { WelcomeContent } from "../shared.styled";
import FilterSearchMyReferrals, { ISelectedFilters } from "./components/FilterSearch";
import { columns, fields, idForContainer, listItemsOffset, sortedColumns } from "./config";

const StyledStepper = styled(Stepper)`
	padding: 0;
	background: none;

	.MuiStepLabel-label {
		font-weight: normal;
		font-size: 10px;
		width: 130px;
		margin-top: 2px;
	}
`;

interface IQueryParams {
	min_created_on?: string;
	max_created_on?: string;
	type?: EPromoCodesUsageType;
	status?: EPromoCodesUsageStatus;
	min_amount?: number;
	max_amount?: number;
}

const AccountMyReferralsPage = () => {
	const { t, translateArray } = useTranslation();

	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [results, setResults] = useState<{ data: TPromoCodesUsageIId[]; count: number }>();
	const [page, setPage] = useState<number>(1);
	const [loadMore, setLoadMore] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<{ sort: string; order: Order; orderBy: number }>();
	const [queryParams, setQueryParams] = useState<IQueryParams>();

	const preventLoading = useRef<boolean>(false);

	useEffect(() => {
		const mainContainerNode = document.querySelector(`#${idForContainer}`);

		const handleScroll = throttle(() => {
			if (mainContainerNode) {
				if (document.documentElement.scrollTop >= mainContainerNode.scrollHeight - 600) {
					if (!preventLoading.current) {
						setLoadMore(true);

						preventLoading.current = true;
					}
				}
			}
		}, 1000);

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, []);

	const fetchData = useCallback(
		async (data?: object, reset?: boolean) => {
			try {
				const promoCodesUsage = await promoCodesService.getUsage({
					type: [
						EPromoCodesUsageType.BOOKING_PROFIT,
						EPromoCodesUsageType.NEW_LOCALHOST_PROFIT,
						EPromoCodesUsageType.NEW_LOCALHOST_BONUS,
					],
					...(orderData && !reset && { sort: orderData.sort, order: orderData.order }),
					...(!reset && queryParams),
					...data,
				});

				setResults(prevState => ({
					count: promoCodesUsage.count,
					data: !!prevState?.data ? [...prevState.data, ...promoCodesUsage.data] : promoCodesUsage.data,
				}));
			} catch (e) {
				handleAndNotify(e);
			} finally {
				preventLoading.current = false;

				setLoadMore(false);
			}
		},

		// eslint-disable-next-line
		[orderData, queryParams],
	);

	useEffect(() => {
		fetchData();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const loadMoreData = async () => {
			setLoader(false);

			if (loadMore) {
				if (page * listItemsOffset <= (results?.count || 0)) {
					setLoader(true);

					await fetchData({ offset: page * listItemsOffset });

					setPage(prevState => prevState + 1);

					setLoadMore(false);
				}
			}
		};

		loadMoreData();

		// eslint-disable-next-line
	}, [loadMore]);

	const sortData = (nameField: string, order: Order, orderBy: number) => {
		setPage(1);

		setResults(undefined);

		setOrderData({ sort: nameField, order, orderBy });

		fetchData({ sort: nameField, order });
	};

	const getFilters = (filters: ISelectedFilters) => {
		const queryParamsFromFilters: IQueryParams = {};

		Object.entries(filters).forEach(([filterType, filterValue]) => {
			if (!filterValue) {
				return;
			}

			if (filterType === "created") {
				if (filterValue[0]) {
					queryParamsFromFilters.min_created_on = getDateWithDefaultTime(filterValue[0], "FROM");
				}
				if (filterValue[1]) {
					queryParamsFromFilters.max_created_on = getDateWithDefaultTime(filterValue[1], "TO");
				}
			} else if (filterType === "amountRange") {
				if (filterValue) {
					queryParamsFromFilters.min_amount = filterValue[0];
					queryParamsFromFilters.max_amount = filterValue[1];
				}
			} else if (filterType === "type") {
				queryParamsFromFilters.type = filterValue;
			} else if (filterType === "status") {
				queryParamsFromFilters.status = filterValue;
			}
		});

		setOrderData(undefined);
		setPage(1);
		setResults(undefined);

		setQueryParams(queryParamsFromFilters);

		fetchData(queryParamsFromFilters, true);
	};

	const createProcess = (status: EPromoCodesUsageStatus, type: EPromoCodesUsageType) => {
		type processArray = { status: EPromoCodesUsageStatus; label: string; canceled?: true }[];

		const bookingProcess: processArray = [
			{
				status: EPromoCodesUsageStatus.BOOKING_CREATED,
				label: t("MY_REFERRALS.STATUS.BOOKING_CREATED"),
			},
			{
				status: EPromoCodesUsageStatus.BOOKING_CANCELED,
				label: t("MY_REFERRALS.STATUS.BOOKING_CANCELED"),
				canceled: true,
			},
			{
				status: EPromoCodesUsageStatus.BOOKING_PERFORMED,
				label: t("MY_REFERRALS.STATUS.BOOKING_PERFORMED"),
			},
			{
				status: EPromoCodesUsageStatus.PAID,
				label: t("MY_REFERRALS.STATUS.PAID"),
			},
		];

		const localhostProcess: processArray = [
			{
				status: EPromoCodesUsageStatus.LOCALHOST_REGISTERED,
				label: t("MY_REFERRALS.STATUS.LOCALHOST_REGISTERED"),
			},
			{
				status: EPromoCodesUsageStatus.LOCALHOST_DELETED,
				label: t("MY_REFERRALS.STATUS.LOCALHOST_DELETED"),
				canceled: true,
			},
			{
				status: EPromoCodesUsageStatus.LOCALHOST_BOOKED,
				label: t("MY_REFERRALS.STATUS.LOCALHOST_BOOKED"),
			},
			{
				status: EPromoCodesUsageStatus.LOCALHOST_RATED,
				label: t("MY_REFERRALS.STATUS.LOCALHOST_RATED"),
			},
			{
				status: EPromoCodesUsageStatus.PAID,
				label: t("MY_REFERRALS.STATUS.PAID"),
			},
		];

		const bookingType = type.includes("BOOKING");

		let currentProcess = bookingType ? [...bookingProcess] : [...localhostProcess];

		if (status === (bookingType ? EPromoCodesUsageStatus.BOOKING_CANCELED : EPromoCodesUsageStatus.LOCALHOST_DELETED)) {
			currentProcess = currentProcess.slice(0, currentProcess.findIndex(elem => elem.canceled === true) + 1);
		} else {
			currentProcess = currentProcess.filter(
				elem =>
					elem.status !==
					(bookingType ? EPromoCodesUsageStatus.BOOKING_CANCELED : EPromoCodesUsageStatus.LOCALHOST_DELETED),
			);
		}

		const steps = currentProcess.map((elem, index) => {
			if (
				elem.status ===
				(bookingType ? EPromoCodesUsageStatus.BOOKING_CANCELED : EPromoCodesUsageStatus.LOCALHOST_DELETED)
			) {
				return (
					<Step key={elem.label}>
						<StepLabel StepIconComponent={() => <HighlightOffIcon style={{ color: colors.red }} />}>
							{elem.label}
						</StepLabel>
					</Step>
				);
			}

			return (
				<Step key={elem.label}>
					<StepLabel
						StepIconComponent={({ active, completed }) => (
							<CheckCircleOutlineIcon style={{ color: completed || active ? colors.green : "#90a4ae" }} />
						)}
					>
						{elem.label}
					</StepLabel>
				</Step>
			);
		});

		return (
			<StyledStepper alternativeLabel activeStep={currentProcess.findIndex(elem => elem.status === status)}>
				{steps}
			</StyledStepper>
		);
	};

	const parsedData =
		results?.data?.map(elem => ({
			type: t(`MY_REFERRALS.TYPE.${elem.type}`),
			referred_localhost_id: elem.referred_localhost_id ? (
				<>
					User ID:{" "}
					<ElemClipboard
						splitPart={10}
						elem={elem.referred_localhost_id}
						route={fillRoute(paths.PUBLIC_PROFILE, { userName: "referred-localhost", uid: elem.referred_localhost_id })}
					/>
				</>
			) : (
				"-"
			),
			created_on: `${parseISOWrapper(elem.created_on, DATE_FORMATS.DATETIME_AMPM_FORMAT)} UTC`,
			amount: prices(elem.amount, elem.currency),
			status: <Box display="flex">{createProcess(elem.status, elem.type)}</Box>,
		})) || [];

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.MY_REFERRALS_LABEL")} />

				<WelcomeContent>
					<Grid container spacing={10}>
						<Grid item md={4} xs={12}>
							<WelcomeOnPage
								firstName={userData?.public_profile?.first_name || userData?.profile_draft?.first_name || ""}
								avatarUrl={
									userData?.public_profile?.pictures?.profile || userData?.profile_draft?.pictures?.profile || undefined
								}
							/>
						</Grid>

						<Grid item md={8} xs={12} style={isSmallScreen ? { marginTop: 30 } : undefined}>
							<GenerateReferralBox />
						</Grid>
					</Grid>
				</WelcomeContent>

				<Box marginBottom="20px">
					<FilterSearchMyReferrals applySelectedFiltersAction={getFilters} />
				</Box>

				<Box id={idForContainer} style={{ overflowX: "auto" }}>
					<ChildrenLoader check={results}>
						<Table
							data={parsedData}
							columns={translateArray(columns)}
							fields={fields}
							sortedColumns={translateArray(sortedColumns)}
							externalSort={sortData}
							initialOrder={orderData?.order}
							initialOrderBy={orderData?.orderBy}
						/>
					</ChildrenLoader>
				</Box>

				{loader && (
					<Box marginTop="50px">
						<CircularLoader absolute={false} />
					</Box>
				)}
			</Container>
		</AppWrapper>
	);
};

export default AccountMyReferralsPage;
