export enum EPartnerConnectionType {
	"api" = "HELP.DISTRIBUTION_PARTNER.API_TYPE.API",
	"concierge" = "HELP.DISTRIBUTION_PARTNER.API_TYPE.CONCIERGE",
	"co_branded_website" = "HELP.DISTRIBUTION_PARTNER.API_TYPE.CO_BRANDED_WEBSITE",
	"white_label" = "HELP.DISTRIBUTION_PARTNER.API_TYPE.WHITE_LABEL",
}

export enum EPartnerType {
	"airline" = "HELP.DISTRIBUTION_PARTNER.TYPE.AIRLINE",
	"hotel" = "HELP.DISTRIBUTION_PARTNER.TYPE.HOTEL",
	"tourism_office" = "HELP.DISTRIBUTION_PARTNER.TYPE.TOURISM_OFFICE",
	"travel_agent" = "HELP.DISTRIBUTION_PARTNER.TYPE.TRAVEL_AGENT",
	"ota" = "HELP.DISTRIBUTION_PARTNER.TYPE.OTA",
	"tour_operator" = "HELP.DISTRIBUTION_PARTNER.TYPE.TOUR_OPERATOR",
	"cruise_company" = "HELP.DISTRIBUTION_PARTNER.TYPE.CRUISE_COMPANY",
	"loyalty_rewards" = "HELP.DISTRIBUTION_PARTNER.TYPE.LOYALTY_REWARDS",
	"other" = "HELP.DISTRIBUTION_PARTNER.TYPE.OTHER",
}

export enum EContactUsOptions {
	"booking_issues" = "CONTACT.SUBJECT.BOOKING_ISSUES",
	"bugs" = "CONTACT.SUBJECT.BUGS",
	"cancellation_refund" = "CONTACT.SUBJECT.CANCELLATION_REFUND",
	"dpa" = "CONTACT.SUBJECT.DPA",
	"existing_booking" = "CONTACT.SUBJECT.EXISTING_BOOKING",
	"feedback" = "CONTACT.SUBJECT.FEEDBACK",
	"investor" = "CONTACT.SUBJECT.INVESTOR",
	"legal" = "CONTACT.SUBJECT.LEGAL",
	"local_host_community" = "CONTACT.SUBJECT.LOCAL_HOST_COMMUNITY",
	"other" = "CONTACT.SUBJECT.OTHER",
	"payment_issues" = "CONTACT.SUBJECT.PAYMENT_ISSUES",
	"tailored_booking" = "CONTACT.SUBJECT.TAILORED_BOOKING",
	"technical_support" = "CONTACT.SUBJECT.TECHNICAL_SUPPORT",
	"vip_booking_request" = "vip_booking_request",
}

export interface IPartnerContactFormData<InitialValue = void> {
	company: string;
	description: string;
	first_name: string;
	last_name: string;
	from: string;
	partner_connection_type: (typeof EPartnerConnectionType)[keyof typeof EPartnerConnectionType] | InitialValue;
	partner_type: (typeof EPartnerType)[keyof typeof EPartnerType] | InitialValue;
	phone: string;
	website: string;
	user_id?: string;
}

export type TKeyOfContactUsOptions = keyof typeof EContactUsOptions;

export interface IContactUsDataFields {
	name: string;
	from: string;
	description: string;
	booking_id?: string;
	subject?: string;
	participants_amount?: number | string;
	city?: string;
	datetime?: string;
	user_id?: string;
	phone?: string;
}

export type TContactUsFieldsKeys =
	| "name"
	| "from"
	| "description"
	| "booking_id"
	| "subject"
	| "participants_amount"
	| "city"
	| "datetime"
	| "phone";

export type TContactUsFields = TContactUsFieldsKeys[];

export interface IContactUsDTO {
	email_config: IContactUsDataFields;
	option: TKeyOfContactUsOptions;
}

export enum ENotificationCategory {
	BOOKING = "BOOKING",
	EXPERIENCE = "EXPERIENCE",
	RATING = "RATING",
}

export enum ENotificationRecipient {
	LOCALHOST = "LOCALHOST",
	ROOT = "ROOT",
	STAFF = "STAFF",
	TRAVELER = "TRAVELER",
}

export enum ENotificationChannel {
	SMS = "SMS",
	EMAIL = "EMAIL",
}

export enum ENotificationType {
	BOOKING_ACCEPTED = "BOOKING_ACCEPTED",
	BOOKING_CANCELED = "BOOKING_CANCELED",
	BOOKING_CREATED = "BOOKING_CREATED",
	EXPERIENCE_ACCEPTED = "EXPERIENCE_ACCEPTED",
	EXPERIENCE_NEEDS_CHANGES = "EXPERIENCE_NEEDS_CHANGES",
	RATING_CREATED = "RATING_CREATED",
}

export interface INotification {
	category: ENotificationCategory;
	recipient_id: string;
	id: string;
	recipient_type: ENotificationRecipient;
	message: string;
	title: string;
	type: ENotificationType;
	subject_id: string;
	subject_name: string;
	marked_as_read: boolean;
	created_by: string;
	created_on: string;
	modified_by: string;
	modified_on: string;
	channel: {
		[key in ENotificationChannel]?: string;
	};
}

export interface INotificationsStatistics {
	total: number;
	by_category: {
		[key in ENotificationCategory]?: number;
	};
}

export type TNotificationsResponse = { data: INotification[]; count: number; statistics: INotificationsStatistics };

export enum ERejectionReasonType {
	TIME = "TIME",
	OTHER = "OTHER",
	NOT_AVAILABLE = "NOT_AVAILABLE",
}
