import { Grid } from "@material-ui/core";
import { memo, ReactNode } from "react";
import styled from "styled-components";

import Footer from "../Footer";
import TopBar from "../Topbar";

const StyledGridContent = styled(Grid)`
	margin-top: 6rem;
	background: white;
`;

interface IAppWrapperProps {
	children: ReactNode;
	isHomePage?: boolean;
}

const AppWrapper = memo(({ children, isHomePage = false }: IAppWrapperProps) => (
	<>
		<Grid container>
			<Grid item xs={12}>
				<TopBar isHomePage={isHomePage} />
			</Grid>

			<StyledGridContent item xs={12}>
				<div>{children}</div>
			</StyledGridContent>
		</Grid>

		<Footer />
	</>
));

export default AppWrapper;
