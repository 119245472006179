import { Divider, IconButton } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import media from "styles/media";

import colors from "./colors";
import dimensions from "./dimensions";

export const MontserratFontFamily = "Montserrat, sans-serif";
export const RobotoFontFamily = "Roboto, sans-serif";

export const codeInputStyle = {
	margin: "4px",
	width: "30px",
	borderRadius: "4px",
	fontSize: "14px",
	height: "40px",
	paddingLeft: "7px",
	backgroundColor: "#f2f2f2",
	color: "black",
	border: `1px solid ${colors.borderGray}`,
	outlineColor: orange[400],
};

export const itemOfListStyles = {
	padding: "10px 0 10px 28px",
};

export const SubTitleSectionV2 = styled.h3`
	font-size: ${dimensions.fontSize.medium};
	font-weight: 500;
	letter-spacing: 0.83px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;
	margin: 0;
`;

export const DescriptionContent = styled.h3`
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	font-weight: 400;
	line-height: 1.71;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};
	opacity: 0.7;
	margin: 0;

	${media.tablet`
    font-size: ${dimensions.fontSize.large};
    line-height: 1.5;
  `};
`;

export const Container = styled.div`
	margin: 0 auto;
	width: 1340px;

	${media.largeDesktop`
    width: 90%;
  `};
`;

export const CloseIconBtn = styled(IconButton)`
	position: absolute;
	right: 0.3rem;
	top: 0.3rem;
	color: ${colors.black};
	z-index: 1;

	${media.phone`
    top: 0;
  `};
`;

export const StyledLinkContainer = styled.div<{ $margin?: string }>`
	display: flex;
	align-items: center;
	> a:first-child {
		margin-right: 4px;
	}
	${props => props.$margin && `margin: ${props.$margin};`}
`;

export const StyledLink = styled(Link)<{
	$withoutHoverUnderline?: boolean;
	$color?: string;
	$decoration?: string;
	$iconSize?: "small";
}>`
	text-decoration: ${props => props.$decoration || "none"};
	color: ${props => props.$color || "inherit"};

	&:hover {
		text-decoration: ${props => (props.$withoutHoverUnderline ? "none" : "underline")};
	}

	${props =>
		props.$iconSize === "small" &&
		css`
			svg {
				width: 18px;
				height: 18px;
			}
		`}
`;

export const StyledExternalLink = styled.a<{ color?: string }>`
	text-decoration: none;

	${props => props.color && `color: ${props.color};`}
`;

export const StandardLink = styled.a`
	color: inherit;

	&:not(:hover) {
		text-decoration: none;
	}
`;

export const ImgWrapper = styled.div`
	height: auto;
	margin-top: 2.5rem;
	width: 100%;
`;

export const CurrencyWrapper = styled.span`
	margin-right: 3px;
`;

export const StyledDivider = styled(Divider)`
	background-color: ${colors.dividerColor};
`;
