import MaskInput from "ui/forms/MaskInput";
import { MASKS } from "ui/forms/MaskInput/utils";

const DateInput = ({ onChange, ...props }: any) => (
	<MaskInput
		{...MASKS.dayMonthYearMask}
		lazy={false}
		autofix={true}
		onAccept={(value: string) => onChange(value)}
		{...props}
	/>
);

export default DateInput;
