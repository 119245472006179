import { rgba } from "polished";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export const FormSection = styled.div`
	padding: 25px 0;
`;

export const Header2 = styled.h2`
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: 0.1px;
	color: ${rgba(colors.darkGreen, 0.87)};
	margin-top: 35px;
`;

export const Info = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
`;
