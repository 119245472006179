import { PhotoTileType } from "./types";

export const configImg = {
	// 4x3 ratio
	[PhotoTileType.small]: {
		height: "240px",
		width: "323px",
	},
	[PhotoTileType.large]: {
		height: "565px",
		width: "1004px",
	},
};
