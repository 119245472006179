import { Box, useTheme } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";

import useTranslation from "hooks/useTranslation";

import IconStringArray from "../../../IconStringArray";
import { TitleSection } from "../../../Traveller";

interface IAccessibilityRequirementsProps {
	accessibilityArray: string[];
	translationsK: string;
}

const AccessibilityRequirements = ({ accessibilityArray, translationsK }: IAccessibilityRequirementsProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Box>
			<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_ACCESSIBILITY_REQ")}</TitleSection>

			<IconStringArray
				icon={<CheckCircleOutline style={{ fontSize: 20, color: theme.palette.primary.main }} />}
				valuesArray={accessibilityArray}
				translationKey={translationsK}
			/>
		</Box>
	);
};

export default AccessibilityRequirements;
