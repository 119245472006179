import { Box } from "@material-ui/core";
import qs from "query-string";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { HeaderLogo, Label, LargeHeader, StyledLinkAuth, SubTitle, Title } from "../shared";
import LoginForm from "./components/LoginForm";
// import { SocialMediaLoginComponent } from "../SocialMediaLoginComponent";

interface ILoginView {
	isPopup?: boolean;
	showForgotPasswordOnPopup?: (value: boolean) => void;
	onboarding?: boolean;
}

const LoginView = ({ isPopup = false, showForgotPasswordOnPopup, onboarding = false }: ILoginView) => {
	const { t } = useTranslation();

	const location = useLocation();

	const [isMFA, setIsMFA] = useState<boolean>(false);

	const { email, type } = qs.parse(location.search);

	const binipool = type === "binipool";

	const handleShowForgotPasswordOnPopup = () => {
		if (showForgotPasswordOnPopup) {
			showForgotPasswordOnPopup(true);
		}
	};

	return (
		<>
			<HeaderLogo isPopup={isPopup} />

			{!isMFA && (
				<Box textAlign="center" marginBottom="40px">
					<Title>{t(`AUTH.LOGIN.HEADER`)}</Title>

					{!onboarding && isPopup && <SubTitle>{t("AUTH.LOGIN.SUB-HEADER")}</SubTitle>}
				</Box>
			)}

			{isMFA && <LargeHeader header_text={t("AUTH.LOGIN.HEADER_MFA")} />}

			<LoginForm setMFA={setIsMFA} email={email as string | null} onboarding={onboarding} binipool={binipool} />

			{!isMFA && (
				<>
					<Box display="flex" justifyContent="center" alignItems="center" mb={10} mt={15}>
						{!isPopup ? (
							<StyledLinkAuth to={paths.FORGOT_PASSWORD}>{t("AUTH.LOGIN.FORGOT_PASSWORD")}</StyledLinkAuth>
						) : (
							<StyledLinkAuth
								to={paths.FORGOT_PASSWORD}
								onClick={e => {
									e.preventDefault();

									handleShowForgotPasswordOnPopup();
								}}
							>
								{t("AUTH.LOGIN.FORGOT_PASSWORD")}
							</StyledLinkAuth>
						)}
					</Box>

					{/*<SocialMediaLoginComponent />*/}

					{!onboarding && (
						<Box display="flex" justifyContent="center" alignItems="center" mb={5}>
							<Label>{t("AUTH.LOGIN.SIGN_UP_LABEL")}</Label>

							<StyledLinkAuth to={paths.REGISTER}>{t("AUTH.LOGIN.SIGN_UP_LINK")}</StyledLinkAuth>
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default LoginView;
