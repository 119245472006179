import { isUndefined, sortBy } from "lodash-es";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";

import { selectConfigsAllError, selectConfigsCities } from "../selectors/configs";
import { fetchConfigsCities } from "../slices/configs";

interface ICityList {
	value: string;
	label: string;
	country_name?: string;
}

const useCity = () => {
	const configsAllError = useAppSelector(selectConfigsAllError);

	const cities = useAppSelector(selectConfigsCities);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (configsAllError === true && !cities) {
			dispatch(fetchConfigsCities());
		}

		// eslint-disable-next-line
	}, [configsAllError, cities]);

	const isLoading = isUndefined(cities);

	const citiesSelect: ICityList[] =
		cities?.map(v => ({ label: v.name, value: v.name, country_name: v.country.name })) || [];

	const citiesSelectSorted: ICityList[] = sortBy(citiesSelect, elem => elem.label);

	const citiesSorted = sortBy(cities, elem => elem.name);

	const findById = (cityId?: string) => cities?.find(c => c?.id === cityId);

	const findByName = (city: string) => cities?.find(c => c?.name === city);

	const citiesSelectForCountryOrAll = (selectedCountry?: string) =>
		selectedCountry
			? citiesSelectSorted?.filter(c => selectedCountry && c.country_name === selectedCountry)
			: citiesSelectSorted;

	return {
		cities,
		citiesSelect,
		citiesSelectSorted,
		citiesSorted,
		citiesSelectForCountryOrAll,
		findById,
		findByName,
		isLoading,
	};
};

export default useCity;
