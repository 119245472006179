import IMask from "imask";

import { EFormatter, IDateParams } from "./types";

const parser = (str, type = "dayMonth") => {
	if (type === "dayMonth") {
		const [m, d] = str.split("-");
		return new Date(new Date().getFullYear(), m - 1, d);
	}

	const [year, month, day] = str.split("-");
	return new Date(year, month - 1, day);
};

const formatters: { [key in EFormatter]: (params: IDateParams) => string } = {
	[EFormatter.dayMonth]: params => [params.month, params.day].join("-"),
	[EFormatter.dayMonthYear]: params => [params.year, params.month, params.day].join("-"),
	[EFormatter.time]: params => [params.hours, params.minutes].join(":"),
};

const formatter = (date: Date, type: EFormatter) => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	return formatters[type]({
		year,
		month: month < 10 ? "0" + month : month,
		day: day < 10 ? "0" + day : day,
		hours,
		minutes,
	});
};

const dateRange = {
	min: new Date(2000, 0, 1),
	max: new Date(2100, 0, 1),
};

export const MASKS = {
	dayMonthMask: {
		mask: Date,
		pattern: "m-d",
		blocks: {
			d: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 31,
				maxLength: 2,
			},
			m: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 12,
				maxLength: 2,
			},
		},
		format: (date: Date) => formatter(date, EFormatter.dayMonth),
		parse: (str: string) => parser(str, EFormatter.dayMonth),
		...dateRange,
	},
	dayMonthYearMask: {
		mask: Date,
		pattern: "Y-`m-`d",
		blocks: {
			d: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 31,
				maxLength: 2,
			},
			m: {
				mask: IMask.MaskedRange,
				from: 1,
				to: 12,
				maxLength: 2,
			},
			Y: {
				mask: IMask.MaskedRange,
				from: 1900,
				to: 9999,
			},
		},
		format: (date: Date) => formatter(date, EFormatter.dayMonthYear),
		parse: (str: string) => parser(str, EFormatter.dayMonthYear),
		...dateRange,
	},
	// @todo:fix - fix timeMask pattern for time.
	timeMask: {
		mask: Date,
		pattern: "HH mm A",
		blocks: {
			HH: {
				mask: value => {
					if (isNaN(value)) {
						return false;
					}
					const intValue = parseInt(value, 10);
					return value.length < 3 && 0 <= intValue && intValue <= 24;
				},
				maxLength: 2,
				placeholderChar: "_",
			},
			h: {
				mask: value => {
					if (isNaN(value)) {
						return false;
					}
					const intValue = parseInt(value, 10);
					return value.length < 3 && 0 <= intValue && intValue <= 12;
				},
				maxLength: 2,
				placeholderChar: "_",
			},
			mm: {
				mask: IMask.MaskedRange,
				placeholderChar: "_",
				from: 0,
				to: 59,
				maxLength: 2,
			},
			A: {
				mask: IMask.MaskedEnum,
				enum: ["AM", "am", "PM", "pm", "aM", "Am", "pM", "Pm"],
			},
		},
	},
};
