import { isArray, isEmpty, omit } from "lodash-es";

import { ETypeRadioGroup, IExperienceTemp, IExperienceWithTemp, Pricing } from "data/experiences/types";

import { excludedIIdFields } from "utils/constants";
import { getEarnings } from "utils/prices";

class Experience {
	static prepareToStore(experience?: IExperienceWithTemp) {
		if (!experience) {
			return {};
		}

		// @todo:refactor - change to pick instead of omit
		const omitColumns = [
			...excludedIIdFields,
			"temp",
			"pricing.filter_price",
			"pricing.currency",
			"lb_review",
			"exp_type",
			"version",
			"status",
			"number_of_locals",
			"ratings",
			"media_gallery",
			"is_verified",
			"designed_by",
			"designer_details",
		];

		const output = { ...experience };

		if (experience?.temp?.priceType === ETypeRadioGroup.tiered && !!output?.pricing) {
			output.pricing.price_per_person = null;
		}

		if (experience?.temp?.priceType === ETypeRadioGroup.fixed && !!output?.pricing) {
			output.pricing.tiered_pricing_details = {};
		}

		if (experience.schedule?.availability_periods?.length === 0 && !!output?.schedule) {
			output.schedule.availability_periods = [
				{
					from: "01-01",
					to: "12-31",
				},
			];
		}

		if (!isEmpty(experience?.id) && !experience?.temp?.kidsFreeUnder) {
			output.pricing = { ...(output?.pricing || {}), kids_free_under: null };
		}

		if (isEmpty(experience?.photo_main)) {
			if (!isEmpty(experience?.temp?.mainPhoto?.media_url)) {
				output.photo_main = experience.temp?.mainPhoto?.media_url;
			} else {
				omitColumns.push("photo_main");
			}
		}

		if (isEmpty(experience?.internal?.classification_id) && isEmpty(experience?.internal?.suggested_route)) {
			omitColumns.push("internal");
		} else {
			output.internal = {};

			if (!isEmpty(experience?.internal?.classification_id)) {
				output.internal.classification_id = experience?.internal?.classification_id;
			}

			if (!isEmpty(experience?.internal?.suggested_route)) {
				output.internal.suggested_route = experience?.internal?.suggested_route;
			}
		}

		if (isEmpty(experience?.pricing?.kids_pricing_details)) {
			omitColumns.push("pricing.kids_pricing_details");
		}

		return omit(output, omitColumns);
	}

	static getPriceChartData = (emptyChartValues: Record<number, number>, pricing?: Pricing) => {
		return !!pricing?.price_per_person
			? this.countPricePerPersonFixed(pricing?.price_per_person)
			: !!pricing?.tiered_pricing_details
			? this.countPricePerPersonTiered(pricing?.tiered_pricing_details || {})
			: emptyChartValues;
	};

	static prepareImages(experience: Partial<IExperienceWithTemp>) {
		let imagesList;

		let mainPhoto;

		if (isArray(experience?.media_gallery)) {
			imagesList = experience.media_gallery.map(elem => ({
				media_url: elem.media_url,
				id: elem.id,
			}));
		}

		if (!!experience?.photo_main) {
			mainPhoto = {
				media_url: experience.photo_main,
				id: imagesList?.find(elem => elem.media_url === experience.photo_main)?.id || "",
			};

			imagesList = imagesList?.filter(elem => elem.media_url !== mainPhoto.media_url);
		} else {
			mainPhoto = !!imagesList?.length && imagesList[0];

			if (mainPhoto) {
				imagesList = imagesList.filter(elem => elem.id !== mainPhoto.id);
			}
		}

		return { imagesList, mainPhoto };
	}

	static determinateTempValues(experience?: IExperienceWithTemp, additionalTemp?: {}): IExperienceTemp {
		if (!experience) {
			return additionalTemp || {};
		}

		const priceType = experience?.pricing?.price_per_person ? ETypeRadioGroup.fixed : ETypeRadioGroup.tiered;

		const priceChart = this.getPriceChartData;

		const { imagesList, mainPhoto } = this.prepareImages(experience);

		return Object.assign(
			{},
			{
				...(imagesList && { imagesList }),
				...(mainPhoto && { mainPhoto }),
				kidsFreeUnder: !!experience?.pricing?.kids_free_under,
				priceType,
				priceChart,
			},
			additionalTemp,
		);
	}

	static countPricePerPersonTiered = (pricesDetails: Record<string, number>) => {
		const earningsListOfValues = {};

		Object.entries(pricesDetails).forEach(([k, v]) => {
			earningsListOfValues[k] = parseInt(k, 10) * getEarnings(v);
		});

		return earningsListOfValues;
	};

	static countPricePerPersonFixed = (price: number) => ({
		1: getEarnings(price),
		2: getEarnings(price) * 2,
		3: getEarnings(price) * 3,
		4: getEarnings(price) * 4,
		5: getEarnings(price) * 5,
		6: getEarnings(price) * 6,
	});
}

export default Experience;
