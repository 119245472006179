import { Box, Divider } from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

export const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	${media.desktop`
    flex-direction: column;
  `};
`;

export const ItemWrapper = styled.div<{ width?: string }>`
	width: ${props => (props.width ? props.width : "45%")};

	${media.largeDesktop`
    margin: 50px 0;
  `};

	${media.desktop`
    width: 100%;
  `};
`;

export const StyledDividerMain = styled(Divider)`
	margin: 50px 0;
`;

export const CircularProgressContent = styled(Box)`
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledDividerSection = styled(Divider)`
	margin: 10px 0 50px;
	border: solid 1px #e6e6e6;
`;

export const TitleSection = styled.h1`
	margin: 0;
	font-size: 18px;
	font-family: ${RobotoFontFamily};
	font-weight: 300;
	line-height: 1.33;
	letter-spacing: 0.1px;
	color: ${colors.darkGreen};
`;

export const StyledBrightness1 = styled(Brightness1)`
	margin-right: 8px;
	font-size: 12px;
	color: ${colors.sunShade};
`;

export const Text = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 2;
	letter-spacing: 0.07px;
	color: ${colors.darkGreen};
`;
