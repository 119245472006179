import { configureScope } from "@sentry/react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { API, Auth, Cache } from "aws-amplify";
import qs from "query-string";

import { partnerStorage } from "classes/partner/session/PartnerStorage";
import Routing from "classes/Routing";

import { EUserType } from "data/users/types";

import { paths } from "routing/paths";

import store from "store/index";
import { selectUser } from "store/selectors/user";
import { resetEntireState } from "store/slices";
import { fetchAuthenticatedUser } from "store/slices/users";

import { localStorageKeys, newPasswordRequiredQueryParam, sessionExpiredQueryParam } from "utils/constants";
import ApiError from "utils/errors/ApiError";

import { EMFActivating } from "components/auth/SetMFA/types";

type UserFromCognito = CognitoUser & {
	challengeName?: string;
};

const getAuthorizationToken = async (authentication?: string, byType?: EUserType) => {
	try {
		const authorizationToken = await API.get("auth", byType ? `/by-user-type/${byType}` : "", {
			headers: {
				Authentication: authentication,
			},
		});

		return authorizationToken.authorization;
	} catch (e) {
		throw new ApiError(e);
	}
};

const saveUserSession = async (userData: UserFromCognito) => {
	const jwtToken = userData.getSignInUserSession()?.getIdToken()?.getJwtToken();

	const authorizationToken = await getAuthorizationToken(jwtToken);

	// cache write must be below cognito fetch methods
	Cache.setItem("Authentication", jwtToken, { expires: expirationTimeForCache });
	Cache.setItem("Authorization", authorizationToken, { expires: expirationTimeForCache });

	// @ts-ignore
	if (!userData?.attributes?.phone_number || !userData?.attributes?.phone_number_verified) {
		const options = {
			showPhoneModal: true,
		};

		// @ts-ignore
		window.__LOCALBINI__ = options;
	}

	await store.dispatch<any>(fetchAuthenticatedUser({ cognitoData: userData, reFetchConfig: true }));
};

// @todo:future change it in 2100 year :)
export const expirationTimeForCache = new Date(2100, 1, 1).getTime();

export const isMFALogin = (user: UserFromCognito) =>
	!!user?.challengeName && user?.challengeName in EMFActivating ? (user.challengeName as EMFActivating) : undefined;

export const getPreferredMFA = async (user: CognitoUser) =>
	await Auth.getPreferredMFA(user, {
		bypassCache: true,
	});

export const confirmSignInWithMFA = async (
	user: UserFromCognito,
	code: string,
	mfaType?: keyof typeof EMFActivating,
) => {
	try {
		await Auth.confirmSignIn(user, code, mfaType);

		const userData = await Auth.currentAuthenticatedUser();

		await saveUserSession(userData);
	} catch (e) {
		throw new ApiError(e);
	}
};

export const signIn = async (onboarding: boolean, ...args: Parameters<typeof Auth.signIn>) => {
	try {
		const userData = await Auth.signIn(...args);

		if (userData.challengeName === "NEW_PASSWORD_REQUIRED") {
			return Routing.history?.push({
				pathname: paths.RESET_PASSWORD,
				search: qs.stringify({
					[newPasswordRequiredQueryParam]: true,
					email: userData.challengeParam?.userAttributes?.email || "",
					password: args[1] || "",
					...(onboarding && {
						onboarding: true,
						...(userData.challengeParam?.userAttributes["custom:binipool_onboarding"] === "true" && {
							type: "binipool",
						}),
					}),
				}),
			});
		}

		if (isMFALogin(userData)) {
			return userData;
		}

		await saveUserSession(userData);

		return true;
	} catch (e) {
		throw new ApiError(e);
	}
};

export const signOut = async (withRedirect?: boolean) => {
	window.localStorage.setItem(
		localStorageKeys.user.hasEverLogged.key,
		localStorageKeys.user.hasEverLogged.defaultValue,
	);

	await Auth.signOut();

	partnerStorage.clear();

	Cache.clear();

	configureScope(scope => scope.setUser(null));

	await store.dispatch<any>(resetEntireState());

	if (withRedirect) {
		Routing.history?.push({ pathname: paths.HOMEPAGE, search: qs.stringify({ [sessionExpiredQueryParam]: true }) });

		// To be sure that new clean state of whole app will be in user browser
		window.location.reload();
	}
};

export const refreshSession = async (withoutAuthorizationToken?: boolean, withoutFetchUser?: boolean) => {
	const authToken = Cache.getItem("Authentication");

	if (authToken) {
		window.localStorage.setItem(
			localStorageKeys.user.hasEverLogged.key,
			localStorageKeys.user.hasEverLogged.defaultValue,
		);

		try {
			const userDataFromStore = selectUser(store.getState());

			const userChangedType =
				userDataFromStore?.userType &&
				userDataFromStore?.originalUserType &&
				userDataFromStore?.userType !== userDataFromStore?.originalUserType;

			const sessionData = await Auth.currentSession();

			const jwtToken = sessionData.getIdToken().getJwtToken();

			if (!withoutAuthorizationToken) {
				const authorizationTokenNormal = await getAuthorizationToken(jwtToken);

				Cache.setItem("Authorization", authorizationTokenNormal, { expires: expirationTimeForCache });

				if (userChangedType) {
					const authorizationTokenByType = await getAuthorizationToken(jwtToken, userDataFromStore.userType);

					Cache.setItem("Authorization", authorizationTokenByType, { expires: expirationTimeForCache });
				}
			}

			// cache write must be below cognito fetch methods
			Cache.setItem("Authentication", jwtToken, { expires: expirationTimeForCache });

			if (!withoutFetchUser) {
				const userData = await Auth.currentAuthenticatedUser();

				await store.dispatch<any>(fetchAuthenticatedUser({ cognitoData: userData }));
			}
		} catch (e) {
			await signOut(true);

			throw new ApiError(e);
		}
	}
};
