import {
	Box,
	Divider,
	FormControlLabel,
	FormHelperText,
	Grid,
	Radio,
	RadioGroup,
	Slider,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, Lens } from "@material-ui/icons";
import { Form as Formik, FormikProps } from "formik";
import { sortBy } from "lodash-es";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import StickyBox from "react-sticky-box";
import { SwiperOptions } from "swiper";

import { EExperienceStatus, IGalleryItem, TUpdateExperienceFormValues } from "data/experiences/types";
import { IDaysOfWeek } from "data/types";

import useNotification from "hooks/useNotification";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCountry from "store/hooks/useCountry";
import useLanguages from "store/hooks/useLanguages";
import { selectUser } from "store/selectors/user";

import { minutesToHM } from "utils/dates";

import Breadcrumbs from "ui/Breadcrumbs";
import SaveButtons from "ui/buttons/SaveButtons";
import CarouselSwiper from "ui/CarouselSwiper";
import HoverTooltip from "ui/tooltips/HoverTooltip";

import { Container, DescriptionContent, SubTitleSectionV2 } from "styles/common";

import Gallery from "../../creation-process/Gallery";
import MapMode from "../../creation-process/MapMode";
import NumberDurationsMinutes from "../../creation-process/NumberDurationsMinutes";
import StringArray from "../../creation-process/StringArray";
import StringCheckboxes from "../../creation-process/StringArrayCheckboxes";
import StringRadioGroup from "../../creation-process/StringRadioGroup";
import StringSwitches from "../../creation-process/StringSwitches";
import StringTags from "../../creation-process/StringTags";
import StringTagsFromToDates from "../../creation-process/StringTagsFromToDates";
import StringTagsOneDate from "../../creation-process/StringTagsOneDate";
import StringTagsTime from "../../creation-process/StringTagsTime";
import IconStringArray from "../../experiences/IconStringArray";
import CheckboxField from "../../formik/CheckboxField";
import Input from "../../formik/Input";
import VerifiedStatusBox from "../VerifiedStatusBox";
import AvailableDays from "./components/AvailableDays";
import DetailsBanner, { DetailsBannerWrapper } from "./components/DetailsBanner";
import DifficultyLevel from "./components/DifficultyLevel";
import EditPen from "./components/EditPen/EditPen";
import LocalsChipsArray from "./components/LocalsChipsArray";
import Map from "./components/MapMode";
import NumberOfParticipants from "./components/NumberOfParticipants";
import PricingEarningPopup from "./components/PricingEarningPopup";
import {
	BoxWithGrayBackground,
	MainPhotoBox,
	StyledAutocomplete,
	StyledCropOriginalIcon,
	StyledStarBorderIcon,
	TitlePage,
	TitleSection,
	TitleSectionWrapper,
} from "./shared.styled";

const detailsGallerySettings: SwiperOptions = {
	lazy: true,
	spaceBetween: 10,
	height: 200,
	width: 290,
};

const MIN_VALUE = 1;
const MAX_VALUE = 15;

const marks = [
	{
		value: MIN_VALUE,
		label: MIN_VALUE,
	},
	{
		value: MAX_VALUE,
		label: MAX_VALUE,
	},
];

const UpdateExpView = ({
	values,
	errors,
	handleSubmit,
	validateForm,
	submitForm,
	isSubmitting,
	setFieldValue,
	resetForm,
	saveMediaCallback,
	deleteMediaCallback,
	daysEmptyValues,
}: FormikProps<TUpdateExperienceFormValues> & {
	saveMediaCallback: (blob: Blob) => void;
	deleteMediaCallback: (id: string) => void;
	daysEmptyValues: IDaysOfWeek;
}) => {
	const [galleryImgs, setGalleryImgs] = useState<ReactNode[]>();
	const [previewGalleryImgs, setPreviewGalleryImgs] = useState<string[]>();
	const [sliderValue, setSliderValue] = useState<number[]>([
		values?.number_of_travelers?.min || MIN_VALUE,
		values?.number_of_travelers?.max || MAX_VALUE,
	]);

	const [isEditable, setIsEditable] = useState<boolean>(false);
	const [editTitle, setEditTitle] = useState<boolean>(false);
	const [editRelevantAttractions, setEditRelevantAttractions] = useState<boolean>(false);
	const [editDescription, setEditDescription] = useState<boolean>(false);
	const [editDescriptionUnique, setEditDescriptionUnique] = useState<boolean>(false);
	const [editDifficultyLevel, setEditDifficultyLevel] = useState<boolean>(false);
	const [editNbTravellers, setEditNbTravellers] = useState<boolean>(false);
	const [editIncluded, setEditIncluded] = useState<boolean>(false);
	const [editExcluded, setEditExcluded] = useState<boolean>(false);
	const [editRequirements, setEditRequirements] = useState<boolean>(false);
	const [editHealthRestrictions, setEditHealthRestrictions] = useState<boolean>(false);
	const [editAccessibility, setEditAccessibility] = useState<boolean>(false);
	const [editWeatherSensitives, setWeatherSensitives] = useState<boolean>(false);
	const [editDisasterPolicies, setDisasterPolicies] = useState<boolean>(false);
	const [editAvailabilityPeriods, setAvailabilityPeriods] = useState<boolean>(false);
	const [editExcludedDates, setExcludedDates] = useState<boolean>(false);
	const [editAvailableDaysOfWeek, setAvailableDaysOfWeek] = useState<boolean>(false);
	const [editLocation, setEditLocation] = useState<boolean>(false);
	const [editPricingDetails, setEditPricingDetails] = useState<boolean>(false);
	const [editGallery, setEditGallery] = useState<boolean>(false);

	const query = useQuery();
	const preview = query.get("preview");

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const { t, withValuesAsString } = useTranslation();
	const { addError } = useNotification();

	const { findByName } = useCountry();
	const { languagesSelectSorted, findNameByKey } = useLanguages();

	const userData = useAppSelector(selectUser);

	const closeEditVersion = () => {
		resetForm();

		setIsEditable(prevState => !prevState);
		setEditTitle(false);
		setEditRelevantAttractions(false);
		setEditDescription(false);
		setEditDescriptionUnique(false);
		setEditDifficultyLevel(false);
		setEditNbTravellers(false);
		setEditIncluded(false);
		setEditExcluded(false);
		setEditRequirements(false);
		setEditHealthRestrictions(false);
		setWeatherSensitives(false);
		setDisasterPolicies(false);
		setAvailabilityPeriods(false);
		setExcludedDates(false);
		setAvailableDaysOfWeek(false);
		setEditLocation(false);
		setEditPricingDetails(false);
		setEditGallery(false);
	};

	const {
		title,
		description,
		description_uniqueness,
		is_verified,
		media_gallery,
		city,
		languages,
		included,
		categories,
		relevant_attractions,
		number_of_travelers,
		excluded,
		requirements,
		activity_type,
		duration_minutes,
		ratings,
		status,
		location,
		cancellation_policy_hours,
		cutoff_time_hours,
		pricing,
		health_restrictions,
		weather_sensitivities,
		schedule,
		accessibility,
		physical_difficulty_level,
		disaster_policies,
		photo_main,
		country,
	} = values;

	const currency = findByName(country || "")?.currency;

	useEffect(() => {
		if (media_gallery) {
			const listOfImgs = sortBy(media_gallery, elem => elem.media_url !== photo_main).map((v, i) => (
				<div
					key={i}
					style={{
						position: "relative",
						backgroundImage: `url(${v.media_url})`,
						width: "100%",
						height: "100%",
						backgroundSize: "cover",
						backgroundPosition: "center",
						borderRadius: "4px",
						cursor: "pointer",
					}}
				>
					{v.media_url === photo_main ? (
						<MainPhotoBox>
							<StyledCropOriginalIcon />
							{t("EXPERIENCE.DEFAULT.PHOTO")}
						</MainPhotoBox>
					) : null}
				</div>
			));

			const tempListOfImgs = media_gallery.map(v => v.media_url);

			setPreviewGalleryImgs(tempListOfImgs);
			setGalleryImgs(listOfImgs);
		}

		// eslint-disable-next-line
	}, [media_gallery, photo_main]);

	const saveAction = useCallback(
		async (sendToReview?: boolean) => {
			if (sendToReview) {
				setFieldValue("sendToReview_HELPER", true);
			}

			const validationErrors = await validateForm();

			if (Object.keys(validationErrors).length) {
				addError(
					withValuesAsString("ERRORS.VALIDATION_EXTENDED", { errors: Object.keys(validationErrors).join(", ") }),
				);

				try {
					document.getElementsByName(Object.keys(validationErrors)[0])[0].focus();

					return;
				} catch {
					return;
				}
			}

			await submitForm();
		},

		// eslint-disable-next-line
		[validateForm, submitForm],
	);

	const handleChangeSlider = (_, v: number[]) => {
		const valueMin = v[0];
		const valueMax = v[1];

		if (valueMin >= valueMax || valueMax <= valueMin) {
			return;
		}

		setFieldValue("number_of_travelers.min", valueMin);
		setFieldValue("number_of_travelers.max", valueMax);
		setSliderValue(v);
	};

	const valueText = (v: number) => `${v}`;

	const mapOnChange = useCallback(v => setFieldValue("location", { ...v }), [setFieldValue]);

	const mapValue = useMemo(
		() => values.location,

		// eslint-disable-next-line
		[values?.location?.start?.formatted_address, values?.location?.end?.formatted_address],
	);

	const changeMainPhoto = async (v: IGalleryItem) => {
		closeEditVersion();

		window.scrollTo(0, 0);

		await setFieldValue("photo_main", v.media_url);

		handleSubmit();
	};

	return (
		<Formik>
			<Container>
				<Box mt={5} mb={isSmallScreen ? 0 : 5} pb={isSmallScreen ? 0 : 5}>
					<Breadcrumbs
						withoutHomepage
						customFirstBreadcrumb={{ route: paths.EXPERIENCES, label: t("EXPERIENCE.BACK_TO_EXPERIENCES_BTN") }}
						lastBreadcrumbLabel={preview ? title : t("EXPERIENCE_DETAILS_LOCALS.UPDATE_YOUR_EXPERIENCE")}
					/>

					{!(
						preview ||
						status === EExperienceStatus.UNDER_REVIEW ||
						status === EExperienceStatus.PUBLISHED__UNDER_REVIEW
					) && (
						<Box mt="16px" mb="26px">
							<SaveButtons
								title={t("EXPERIENCE_DETAILS_LOCALS.EDIT_BOX_TITLE")}
								isSubmitting={isSubmitting}
								isEditable={isEditable}
								saveLabel={t("COMMON.SAVE_AS_DRAFT")}
								editLabel={t("EXPERIENCE_DETAILS_LOCALS.EDIT_EXP_BTN")}
								saveAsDraft={() => saveAction()}
								saveForReview={() => saveAction(true)}
								cancelForm={closeEditVersion}
								onChangeEditable={() => setIsEditable(true)}
							/>
						</Box>
					)}

					{!isSmallScreen && <Divider />}
				</Box>

				<Box display="flex" alignItems="center" justifyContent="space-between" mt={isSmallScreen ? 12 : 17} mb={12}>
					<Box display="flex" alignItems="center" mr={isSmallScreen ? 10 : 0} width="70%">
						{editTitle ? (
							<Input
								required
								inputAndWrapperStyles={{ width: "100%" }}
								label={t("EXPERIENCE.FIELDS.title.INPUT_TITLE")}
								name="title"
								type="text"
								readOnly={isSubmitting}
								value={values.title}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue("title", e.target.value)}
								onKeyDown={e => {
									if (e.key === "Enter") {
										e.preventDefault();

										setEditTitle(prevState => !prevState);
									}
								}}
							/>
						) : (
							<TitlePage>{title}</TitlePage>
						)}

						{isEditable && (
							<Box marginLeft="10px">
								<EditPen onClickEdit={() => setEditTitle(prevState => !prevState)} />
							</Box>
						)}

						{is_verified && <VerifiedStatusBox currentStatus={t("EXPERIENCE.STATUS_VERIFIED_EXPERIENCE")} />}
					</Box>
				</Box>

				{isEditable ? (
					<DetailsBannerWrapper>
						<Box>
							<TitleSection>
								{t("EXPERIENCE.FIELDS.languages.TITLE")}&nbsp;
								<HoverTooltip translation="EXPERIENCE.FIELDS.languages.DESCRIPTION" />
							</TitleSection>

							<Box mt={10} mb={20}>
								<StyledAutocomplete
									name="languages"
									id="edit_experience_autocomplete_languages_list"
									multiple
									autoHighlight
									options={
										userData?.profile_draft?.languages?.length
											? userData.profile_draft.languages.map(elem => ({ value: elem, label: findNameByKey(elem) }))
											: languagesSelectSorted
									}
									getOptionLabel={option => findNameByKey(option.value as string) || ""}
									getOptionSelected={(option, selected) => option.value === selected.value}
									filterSelectedOptions
									variant="standard"
									label={t("EXPERIENCE.FIELDS.languages.INPUT_TITLE")}
									inputProps={{
										onKeyUp: undefined,
									}}
								/>
							</Box>

							<TitleSection>{t("EXPERIENCE.FIELDS.activity_type.TITLE")}</TitleSection>

							<RadioGroup
								row
								defaultValue={values.activity_type}
								onChange={e => setFieldValue("activity_type", e.target.value)}
							>
								<FormControlLabel value="TOUR" control={<Radio />} label={t("EXPERIENCE.FIELDS.activity_type.TOUR")} />

								<FormControlLabel
									value="ACTIVITY"
									control={<Radio />}
									label={t("EXPERIENCE.FIELDS.activity_type.ACTIVITY")}
								/>
							</RadioGroup>

							<TitleSection>{t("EXPERIENCE.FIELDS.duration_minutes.TITLE")}</TitleSection>

							<Box mt={10} mb={20}>
								<NumberDurationsMinutes
									onChange={v => setFieldValue("duration_minutes", v)}
									value={values?.duration_minutes || 0}
								/>
							</Box>

							<TitleSection>{t("EXPERIENCE.FIELDS.categories.TITLE")}</TitleSection>

							<Box mt={10}>
								<StringTags
									onChange={v => setFieldValue("categories", [...v])}
									value={values?.categories || []}
									field="CATEGORY"
									translateDic
								/>

								{!!errors.categories && <FormHelperText error>{errors.categories}</FormHelperText>}
							</Box>
						</Box>
					</DetailsBannerWrapper>
				) : (
					<DetailsBanner
						withoutVerticalPadding
						status={status}
						rating={ratings}
						activityType={t(`EXPERIENCE.FIELDS.activity_type.${activity_type}`)}
						durationMin={typeof duration_minutes === "number" ? (minutesToHM(duration_minutes) as string) : undefined}
						city={city}
						languages={languages?.map(elem => findNameByKey(elem)).join(", ") || undefined}
						includingArray={categories?.map(v => t(`EXPERIENCE.CATEGORY.${v}`)) || []}
					/>
				)}

				<Box mt={6} mb={15}>
					{isEditable && (
						<Box display="flex" justifyContent="flex-end" marginBottom="5px" width="100%">
							<EditPen onClickEdit={() => setEditGallery(prevState => !prevState)} />
						</Box>
					)}

					{editGallery ? (
						<>
							<Gallery
								onChangeGallery={v => saveMediaCallback(v)}
								valueGallery={values.media_gallery_HELPER || []}
								valueMainPhoto={values.photo_main_HELPER}
								onChangeMainPhoto={changeMainPhoto}
								deleteMediaCallback={deleteMediaCallback}
							/>
							<Box mt={10}>
								<CheckboxField
									name="media_rights_confirmed_HELPER"
									label={t("EXPERIENCE.FORM.MEDIA.COPYRIGHTS")}
									withError
									required
								/>
							</Box>
						</>
					) : (
						<CarouselSwiper
							images={previewGalleryImgs}
							heightSlide="200px"
							slides={galleryImgs || []}
							settings={detailsGallerySettings}
						/>
					)}
				</Box>

				<Grid container direction="row-reverse" spacing={10}>
					<Grid item xs={12} lg={4}>
						<StickyBox offsetTop={30} offsetBottom={30} style={{ zIndex: 1 }}>
							<Box>
								{isEditable && (
									<Box display="flex" justifyContent="flex-end" marginBottom="5px" width="100%">
										<EditPen onClickEdit={() => setEditPricingDetails(prevState => !prevState)} />
									</Box>
								)}

								<PricingEarningPopup
									setFieldValue={setFieldValue}
									isEditBox={editPricingDetails}
									cancellationPolicyNumber={cancellation_policy_hours || 0}
									preparationTimeNumber={cutoff_time_hours || 0}
									numberOfTravelers={number_of_travelers}
									pricing={pricing}
									currency={currency}
								/>
							</Box>
						</StickyBox>
					</Grid>

					<Grid item xs={12} lg={8}>
						<Box>
							<SubTitleSectionV2>{t("EXPERIENCE.LIST.TITLE")}</SubTitleSectionV2>
						</Box>

						<Box mt={15}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_ABOUT")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditDescription(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editDescription ? (
								<>
									<Box mt={15} mb={30}>
										<Input
											required
											inputAndWrapperStyles={{ width: "100%" }}
											name="description"
											label={t("EXPERIENCE.FIELDS.description.TITLE")}
											type="text"
											readOnly={isSubmitting}
											value={values.description}
											multiline
											onChange={(v: ChangeEvent<HTMLInputElement>) => setFieldValue("description", v.target.value)}
											maxLength={300}
										/>
									</Box>
								</>
							) : (
								<Box mt={10}>
									<DescriptionContent>{description || ""}</DescriptionContent>
								</Box>
							)}
						</Box>

						<Box mt={15}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_KEY_ATTRACTIONS")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditRelevantAttractions(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editRelevantAttractions ? (
								<Box mt={5} mb={30}>
									<StringArray
										icon={<StyledStarBorderIcon />}
										onChange={v => setFieldValue("relevant_attractions", [...v])}
										value={values.relevant_attractions || []}
									/>
								</Box>
							) : (
								<IconStringArray
									icon={<Lens style={{ fontSize: 8, color: theme.palette.common.black }} />}
									isDividerBelow={isSmallScreen}
									valuesArray={relevant_attractions || []}
								/>
							)}
						</Box>

						<Box mt={10}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_UNIQUE")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditDescriptionUnique(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editDescriptionUnique ? (
								<>
									<Box mt={15} mb={30}>
										<Input
											required
											inputAndWrapperStyles={{ width: "100%" }}
											name="description_uniqueness"
											label={t("EXPERIENCE.FIELDS.description_uniqueness.TITLE")}
											type="text"
											readOnly={isSubmitting}
											value={values.description_uniqueness}
											multiline
											onChange={(v: ChangeEvent<HTMLInputElement>) =>
												setFieldValue("description_uniqueness", v.target.value)
											}
											maxLength={1000}
										/>
									</Box>
								</>
							) : (
								<Box mt={10} mb={20}>
									<DescriptionContent>{description_uniqueness || ""}</DescriptionContent>
								</Box>
							)}
						</Box>

						<BoxWithGrayBackground
							pt={5}
							$calcWidth={
								isMobile
									? // @ts-ignore
									  document.body.clientWidth - document.querySelector("form > div")?.clientWidth
									: document.body.clientWidth
							}
						>
							<Box position="relative">
								<Box mt={15} mb={16}>
									<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_LOCALS.TITLE_LOCATION")}</SubTitleSectionV2>
								</Box>

								<TitleSectionWrapper>
									<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_START_END_POINT")}</TitleSection>

									{isEditable && (
										<Box paddingRight="5px">
											<EditPen onClickEdit={() => setEditLocation(prevState => !prevState)} />
										</Box>
									)}
								</TitleSectionWrapper>

								{editLocation ? (
									<Box paddingRight="5px" pb={20} pt={10}>
										<MapMode onChange={mapOnChange} value={mapValue || null} />
									</Box>
								) : (
									<Map locationData={location} />
								)}
							</Box>
						</BoxWithGrayBackground>

						<Box mt={25}>
							<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_LOCALS.TITLE_EXPERIENCE_DETAILS")}</SubTitleSectionV2>
						</Box>

						<Box mt={15}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_PARTICIPANTS")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditNbTravellers(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editNbTravellers ? (
								<Box mt={30} mb={30} pl={10} pr={10}>
									<Slider
										value={sliderValue}
										onChange={handleChangeSlider}
										valueLabelDisplay="on"
										aria-labelledby="number-travelers-range-slider"
										getAriaValueText={valueText}
										min={MIN_VALUE}
										max={MAX_VALUE}
										marks={marks}
									/>
								</Box>
							) : (
								<NumberOfParticipants fromInt={number_of_travelers?.min || 0} toInt={number_of_travelers?.max || 0} />
							)}
						</Box>

						<Box mt={21}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_INCLUDED")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditIncluded(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editIncluded ? (
								<Box mt={10} mb={30}>
									<StringTags
										onChange={v => setFieldValue("included", [...v])}
										value={values.included || []}
										field="INCLUDED"
										translateDic={false}
										add
									/>
								</Box>
							) : (
								<LocalsChipsArray chipsArray={included || []} descStr={t("EXPERIENCE_DETAILS_LOCALS.DESC_INCLUDED")} />
							)}
						</Box>

						<Box mt={21}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_EXCLUDED")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditExcluded(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editExcluded ? (
								<Box mt={10} mb={30}>
									<StringTags
										onChange={v => setFieldValue("excluded", [...v])}
										value={values.excluded || []}
										field="EXCLUDED"
										translateDic={false}
										add
									/>
								</Box>
							) : (
								<LocalsChipsArray chipsArray={excluded || []} descStr={t("EXPERIENCE_DETAILS_LOCALS.DESC_EXCLUDED")} />
							)}
						</Box>

						<Box mt={21}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_REQUIREMENTS")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditRequirements(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editRequirements ? (
								<Box mb={30}>
									<StringArray
										onChange={v => setFieldValue("requirements", [...v])}
										value={values.requirements || []}
									/>
								</Box>
							) : (
								<IconStringArray
									icon={<Lens style={{ fontSize: 8, color: theme.palette.common.black }} />}
									isDividerBelow={isSmallScreen}
									valuesArray={requirements || []}
								/>
							)}
						</Box>

						<Box mt={21}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_DIFFICULTY_LEVEL")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditDifficultyLevel(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editDifficultyLevel ? (
								<Box mb={30}>
									<StringRadioGroup
										onChange={v => setFieldValue("physical_difficulty_level", v)}
										value={values?.physical_difficulty_level || "EASY"}
									/>
								</Box>
							) : (
								<DifficultyLevel
									descriptionStr={t(`EXPERIENCE.FORM.STRING_RADIO.${physical_difficulty_level}.TITLE`)}
									subDescriptionStr={t(`EXPERIENCE.FORM.STRING_RADIO.${physical_difficulty_level}.DESCRIPTION`)}
								/>
							)}
						</Box>

						<Box mt={21}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_HEALTH_RESTRICTIONS")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditHealthRestrictions(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editHealthRestrictions ? (
								<Box mb={30}>
									<StringCheckboxes
										onChange={v => setFieldValue("health_restrictions", [...v])}
										onChangeCustomArray={v => setFieldValue("custom_health_restrictions", [...v])}
										value={values.health_restrictions || []}
										customValue={values.custom_health_restrictions || undefined}
										field="HEALTH_RESTRICTIONS"
									/>
								</Box>
							) : (
								<IconStringArray
									icon={<ErrorOutlineOutlined style={{ fontSize: 24, color: theme.palette.common.black }} />}
									isDividerBelow={isSmallScreen}
									valuesArray={health_restrictions || []}
									translationKey="EXPERIENCE.HEALTH_RESTRICTIONS"
								/>
							)}
						</Box>

						<Box mt={15} mb={15}>
							<TitleSectionWrapper>
								<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_ACCESSIBILITY")}</TitleSection>

								{isEditable && <EditPen onClickEdit={() => setEditAccessibility(prevState => !prevState)} />}
							</TitleSectionWrapper>

							{editAccessibility ? (
								<Box mb={30}>
									<StringSwitches
										onChange={v => setFieldValue("accessibility", [...v])}
										value={values.accessibility || []}
										field="ACCESSIBILITY_TYPES"
									/>
								</Box>
							) : (
								<IconStringArray
									icon={<CheckCircleOutlineOutlined style={{ fontSize: 24, color: theme.palette.common.black }} />}
									isDividerBelow={isSmallScreen}
									valuesArray={accessibility || []}
									translationKey="EXPERIENCE.ACCESSIBILITY_TYPES"
								/>
							)}
						</Box>

						<BoxWithGrayBackground
							pt={8}
							pb={30}
							$calcWidth={
								isMobile
									? // @ts-ignore
									  document.body.clientWidth - document.querySelector("form > div")?.clientWidth
									: document.body.clientWidth
							}
						>
							<Box position="relative">
								<Box mt={5} mb={20}>
									<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_LOCALS.TITLE_TERMS_CONDITIONS")}</SubTitleSectionV2>
								</Box>

								<Box mt={21}>
									<TitleSectionWrapper mb={8}>
										<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_AVAILABLE")}</TitleSection>

										{isEditable && (
											<Box paddingRight="5px">
												<EditPen onClickEdit={() => setAvailabilityPeriods(prevState => !prevState)} />
											</Box>
										)}
									</TitleSectionWrapper>

									{editAvailabilityPeriods ? (
										<Box mt={10}>
											<StringTagsFromToDates
												isSwitcherYesNo
												onChange={v => setFieldValue("schedule.availability_periods", [...v])}
												value={values?.schedule?.availability_periods || []}
											/>
										</Box>
									) : (
										<>
											{!schedule?.availability_periods?.length ? (
												<strong>{t("EXPERIENCE.UPDATE.ENTIRE_YEAR")}</strong>
											) : (
												<LocalsChipsArray
													chipsColor="default"
													chipsArray={schedule?.availability_periods?.map(v => `${v.from} - ${v.to}`) || []}
												/>
											)}
										</>
									)}
								</Box>

								<Box mt={21}>
									<TitleSectionWrapper mb={8}>
										<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_NOT_AVAILABLE")}</TitleSection>

										{isEditable && (
											<Box display="flex" justifyContent="flex-end" paddingRight="5px" width="100%">
												<EditPen onClickEdit={() => setExcludedDates(prevState => !prevState)} />
											</Box>
										)}
									</TitleSectionWrapper>

									{editExcludedDates ? (
										<StringTagsOneDate
											onChange={v => setFieldValue("schedule.excluded_dates", [...v])}
											value={values?.schedule?.excluded_dates || []}
										/>
									) : (
										<>
											{!!schedule?.excluded_dates?.length && (
												<LocalsChipsArray chipsColor="default" chipsArray={schedule.excluded_dates} />
											)}
										</>
									)}
								</Box>

								<Box mt={21}>
									<TitleSectionWrapper mb={8}>
										<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_HOST_YOUR_EXPERIENCE")}</TitleSection>

										{isEditable && (
											<Box paddingRight="5px">
												<EditPen onClickEdit={() => setAvailableDaysOfWeek(prevState => !prevState)} />
											</Box>
										)}
									</TitleSectionWrapper>

									{editAvailableDaysOfWeek ? (
										<StringTagsTime
											onChange={v => setFieldValue("schedule.available_days_of_week", { ...v })}
											value={values?.schedule?.available_days_of_week || daysEmptyValues}
										/>
									) : (
										<AvailableDays chipsColor="default" daysArray={schedule?.available_days_of_week} />
									)}
								</Box>

								<Box mt={21}>
									<TitleSectionWrapper mb={8}>
										<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_WEATHER_SENSITIVE")}</TitleSection>

										{isEditable && (
											<Box paddingRight="5px">
												<EditPen onClickEdit={() => setWeatherSensitives(prevState => !prevState)} />
											</Box>
										)}
									</TitleSectionWrapper>

									{editWeatherSensitives ? (
										<Box mt={10} mb={30}>
											<StringArray
												isSwitcherYesNo
												onChange={v => setFieldValue("weather_sensitivities", [...v])}
												value={values.weather_sensitivities || []}
											/>
										</Box>
									) : (
										<IconStringArray
											icon={<Lens style={{ fontSize: 8, color: theme.palette.common.black }} />}
											isDividerBelow={isSmallScreen}
											valuesArray={weather_sensitivities || []}
										/>
									)}
								</Box>

								<Box mt={15}>
									<TitleSectionWrapper mb={8}>
										<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.SUBTITLE_EXP_COVID_SAFETY")}</TitleSection>

										{isEditable && (
											<Box paddingRight="5px">
												<EditPen onClickEdit={() => setDisasterPolicies(prevState => !prevState)} />
											</Box>
										)}
									</TitleSectionWrapper>

									{editDisasterPolicies ? (
										<StringCheckboxes
											isSwitcherYesNo
											onChange={v => setFieldValue("disaster_policies", [...v])}
											value={values.disaster_policies || []}
											onChangeCustomArray={v => setFieldValue("custom_disaster_policies", [...v])}
											customValue={values.custom_disaster_policies || undefined}
											field="DISASTER_POLICIES"
										/>
									) : (
										<IconStringArray
											icon={<CheckCircleOutlineOutlined style={{ fontSize: 24, color: theme.palette.common.black }} />}
											isDividerBelow={isSmallScreen}
											valuesArray={disaster_policies || []}
											translationKey="EXPERIENCE.DISASTER_POLICIES"
										/>
									)}
								</Box>
							</Box>
						</BoxWithGrayBackground>
					</Grid>
				</Grid>
			</Container>
		</Formik>
	);
};

export default UpdateExpView;
