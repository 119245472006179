import { Box, Chip, Grid } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";

import { TDateField } from "data/types";

import useTranslation from "hooks/useTranslation";

import SwitcherYesNo from "ui/forms/SwitcherYesNo";

import DatePickerCurrentYear from "../../forms/DatePickerCurrentYear";

export interface IStringTagsFromToDates {
	onChange: (newValue) => void;
	value: TDateField[];
	isSwitcherYesNo?: boolean;
}

const StringTagsFromToDates = ({ onChange, value, isSwitcherYesNo }: IStringTagsFromToDates) => {
	const { t } = useTranslation();

	const [showSection, setShowSection] = useState<boolean>(value.length === 0);

	useEffect(() => {
		setShowSection(value.length === 0);
	}, [value.length]);

	const handleDelete = index => {
		onChange(value.filter((_, i) => i !== index));
	};

	const addNewDates = (valueStartDate, valueEndDate) => {
		onChange([...value, { from: valueStartDate, to: valueEndDate }]);
	};

	const handleYesNo = yesNoValue => {
		setShowSection(yesNoValue);

		if (isSwitcherYesNo && yesNoValue) {
			onChange([]);
		}
	};

	return (
		<Grid container spacing={5} direction="column">
			{isSwitcherYesNo && (
				<Box mb={10}>
					<SwitcherYesNo onChangeYesNo={handleYesNo} value={showSection} />
				</Box>
			)}

			{!isSwitcherYesNo ||
				(!showSection && (
					<Box>
						<Grid item xs container direction="row">
							{value.map((v, i) => (
								<Box display="flex" pr={5} pb={5} key={i}>
									<Chip
										color="primary"
										label={`${format(parse(v.from, "MM-dd", 0), "dd MMM")} - ${format(
											parse(v.to, "MM-dd", 0),
											"dd MMM",
										)}`}
										onDelete={() => handleDelete(i)}
										deleteIcon={<Cancel />}
									/>
								</Box>
							))}

							<DatePickerCurrentYear
								callBackDate={addNewDates}
								dateFormat="MM-dd"
								label={
									!!value.length
										? t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_MORE")
										: t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_FIRST_ELEMENT")
								}
							/>
						</Grid>
					</Box>
				))}
		</Grid>
	);
};

export default StringTagsFromToDates;
