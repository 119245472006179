import { Button } from "@material-ui/core";
import styled from "styled-components";

import media from "styles/media";

export const SeeAllBtn = styled(Button)`
	height: 48px;
	width: 207px;

	${media.tablet`
    width: 177px;
  `};
`;
