import { Box, Divider } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash-es";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { EExperienceStatus, EExpType } from "data/experiences/types";
import {
	EOnboardingSteps,
	EUserOnboardingMeetingStatus,
	EUserOnboardingStatus,
	EUserOnboardingType,
	EUserStatus,
} from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { MY_FINANCES_TABS, MY_SETTINGS_TABS, paths } from "routing/paths";

import draftsService from "services/api/drafts";
import experiencesService from "services/api/experiences";
import financialsService, { errorCodes } from "services/api/financials";
import promoCodesService from "services/api/promoCodes";
import { getPreferredMFA } from "services/aws/auth";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import ChildrenLoader from "ui/loaders/ChildrenLoader";

import colors from "styles/colors";
import { StyledLink } from "styles/common";

import { EMFAStatusTypes } from "../../auth/SetMFA/types";
import { InnerContainer, StyledCheckIcon, StyledRemoveIcon } from "./styled";

interface IAccountStatus {
	showOnlyAllSet?: boolean;
	hideIfAllSet?: boolean;
	allSetCallback?: Dispatch<SetStateAction<true>>;
}

const AccountStatus = ({ showOnlyAllSet = false, hideIfAllSet = false, allSetCallback }: IAccountStatus) => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const loggedUser = useAppSelector(selectUser);

	const [phoneNumber, setPhoneNumber] = useState<boolean | null>(null);
	const [bankingDetails, setBankingDetails] = useState<boolean | null>(null);
	const [referral, setReferral] = useState<boolean | null>(null);
	const [binipools, setBinipools] = useState<boolean | null>(null);
	const [uniques, setUniques] = useState<boolean | null>(null);
	const [mfa, setMfa] = useState<boolean | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			const requests = [
				financialsService
					.getBankingDetails(loggedUser?.id || "")
					.then(bankingDetailsData => setBankingDetails(!!bankingDetailsData?.account_number))
					.catch(e =>
						e?.getError()?.error_code === errorCodes.getBankingDetails.missingBankingDetails
							? setBankingDetails(false)
							: handleAndNotify(e),
					),
				Auth.currentUserInfo()
					.then(userInfoData => setPhoneNumber(!!userInfoData?.attributes?.phone_number_verified))
					.catch(e => handleAndNotify(e)),
				promoCodesService
					.getAffiliateCode()
					.then(referralData => setReferral(!!referralData))
					.catch(e => (e?.getStatus && e.getStatus() === 404 ? setReferral(false) : handleAndNotify(e))),
				experiencesService
					.getListOfExperiences({
						exp_type: EExpType.COHOSTED,
						cohosted_by_user: true,
						limit: 100,
					})
					.then(usersBinipools => setBinipools(!!usersBinipools.data.length))
					.catch(e => handleAndNotify(e)),
				draftsService
					.fetchAll({
						limit: 100,
						exp_type: EExpType.UNIQUE,
						created_by: loggedUser?.id,
						status: [
							EExperienceStatus.PUBLISHED,
							EExperienceStatus.PUBLISHED__AWAITING_REVIEW,
							EExperienceStatus.PUBLISHED__UNDER_REVIEW,
							EExperienceStatus.PUBLISHED__NEEDS_CHANGES,
							EExperienceStatus.PUBLISHED__DRAFT,
						],
					})
					.then(usersUniques => setUniques(!!usersUniques.data.length))
					.catch(e => handleAndNotify(e)),
				Auth.currentAuthenticatedUser()
					.then(user => getPreferredMFA(user))
					.then(mfaType => setMfa(mfaType !== EMFAStatusTypes.NOMFA))
					.catch(e => handleAndNotify(e)),
			];

			await Promise.all(requests);
		};

		if (!showOnlyAllSet) {
			fetchData();
		}

		// eslint-disable-next-line
	}, [loggedUser, showOnlyAllSet]);

	const profileDraft = loggedUser?.profile_draft;

	const onboardingInProgress = (
		[EUserOnboardingStatus.IN_PROGRESS, EUserOnboardingStatus.REGISTERED] as (EUserOnboardingStatus | undefined)[]
	).includes(loggedUser?.onboarding?.status);

	const config: {
		title: string;
		subTitle?: string;
		reviewInProgress?: string | false;
		link?: string;
		check: boolean | null;
	}[] = [
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.CALENDLY.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.CALENDLY.SUB_TITLE",
			link: fillRoute(paths.ONBOARDING, {
				step: EOnboardingSteps.Calendly,
				...(loggedUser?.onboarding?.type === EUserOnboardingType.BINIPOOL && { search: { type: "binipool" } }),
			}),
			check: onboardingInProgress
				? (
						[EUserOnboardingMeetingStatus.SCHEDULED, EUserOnboardingMeetingStatus.COMPLETED] as (
							| EUserOnboardingMeetingStatus
							| undefined
						)[]
				  ).includes(loggedUser?.onboarding?.meeting_status)
				: true,
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.PROFILE.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.PROFILE.SUB_TITLE",
			reviewInProgress: onboardingInProgress && "DASHBOARD.SECTIONS.ACCOUNT_STATUS.REVIEW_IN_PROGRESS",
			link: paths.PROFILE,
			check: onboardingInProgress
				? true
				: !!profileDraft?.cities?.length &&
				  !!profileDraft?.skills_and_interests?.length &&
				  !!profileDraft?.languages?.length &&
				  !!profileDraft?.pictures?.profile &&
				  !!profileDraft?.occupation &&
				  !!profileDraft?.motto &&
				  !!profileDraft?.description &&
				  ![EUserStatus.DRAFT, EUserStatus.REJECTED, EUserStatus.NEEDS_CHANGES].includes(profileDraft?.status),
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.PHONE_NUMBER.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.PHONE_NUMBER.SUB_TITLE",
			link: fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.SECURITY }),
			check: phoneNumber,
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.BANKING_DETAILS.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.BANKING_DETAILS.SUB_TITLE",
			link: fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.BANKING_DETAILS }),
			check: bankingDetails,
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.EXPERIENCES.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.EXPERIENCES.SUB_TITLE",
			reviewInProgress: onboardingInProgress && "DASHBOARD.SECTIONS.ACCOUNT_STATUS.REVIEW_IN_PROGRESS",
			link: paths.EXPERIENCES,
			check: onboardingInProgress ? true : binipools || uniques,
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.MFA.TITLE",
			subTitle: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.MFA.SUB_TITLE",
			link: fillRoute(paths.MY_SETTINGS, { page: MY_SETTINGS_TABS.SECURITY }),
			check: mfa,
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.MOBILE_APP.TITLE",
			check: !isEmpty(loggedUser?.personal?.devices),
		},
		{
			title: "DASHBOARD.SECTIONS.ACCOUNT_STATUS.REFERRAL.TITLE",
			check: referral,
		},
	];

	const loadedAllData = config.every(elem => elem.check !== null) || undefined;

	const allSet = showOnlyAllSet ? true : loadedAllData && config.every(elem => elem.check);

	if (hideIfAllSet && allSet) {
		if (allSetCallback) {
			allSetCallback(true);
		}

		return null;
	}

	return (
		<Box
			display="flex"
			gridGap="45px"
			height={showOnlyAllSet ? "100%" : undefined}
			marginBottom={showOnlyAllSet ? undefined : "50px"}
		>
			<ChildrenLoader check={showOnlyAllSet ? true : loadedAllData}>
				{!showOnlyAllSet && (
					<Box width="100%">
						<Box fontSize="12px" fontWeight="500">
							{t("DASHBOARD.SECTIONS.ACCOUNT_STATUS.TITLE")}
						</Box>

						<Divider />

						<InnerContainer>
							{config.map(elem => (
								<Box key={elem.title} flex="1" display="flex" flexDirection="column" alignItems="center" gridGap="5px">
									{elem.check ? (
										<StyledCheckIcon $size={24} $color={colors.oceanDrive} />
									) : (
										<StyledRemoveIcon $size={24} $color={colors.darkRed} />
									)}

									{elem.link && !elem.check ? (
										<StyledLink to={elem.link}>
											<Box fontSize="10px" textAlign="center">
												<div>{t(elem.title)}</div>

												{elem.subTitle && <div>{t(elem.subTitle)}</div>}
											</Box>
										</StyledLink>
									) : (
										<Box fontSize="10px" textAlign="center" color={elem.check ? colors.oceanDrive : undefined}>
											<div>{t(elem.title)}</div>

											{elem.subTitle && !elem.check && <div>{t(elem.subTitle)}</div>}

											{elem.reviewInProgress && <div>{t(elem.reviewInProgress)}</div>}
										</Box>
									)}
								</Box>
							))}
						</InnerContainer>
					</Box>
				)}

				<Box
					width="100px"
					height={showOnlyAllSet ? undefined : "fit-content"}
					bgcolor={allSet ? colors.green : colors.darkRed}
					padding="8px"
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					gridGap="8px"
				>
					{allSet ? <StyledCheckIcon /> : <StyledRemoveIcon />}

					<Box color={colors.white} fontSize="10px" textAlign="center">
						{t(allSet ? "DASHBOARD.SECTIONS.ACCOUNT_STATUS.FILLED" : "DASHBOARD.SECTIONS.ACCOUNT_STATUS.NOT_FILLED")}
					</Box>
				</Box>
			</ChildrenLoader>
		</Box>
	);
};

export default AccountStatus;
