import { Chip } from "@material-ui/core";
import styled from "styled-components";

import { mobileWidth, spaceDefault, tabletWidth } from "pages/onboarding/shared.styled";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

const imageHeight = "340px";
const mobileImageHeight = "230px";

export const ModalContent = styled.div`
	max-width: 780px;
`;

export const Desc = styled.p<{ noBottom?: boolean }>`
	margin-bottom: ${spaceDefault * 2}px;
	font-size: 14px;
	line-height: 24px;
	color: #666;
	word-break: break-word;

	${props => props.noBottom && " margin-bottom: 0"};

	b {
		margin-bottom: ${spaceDefault + spaceDefault / 2}px;
		color: ${colors.black};
		font-size: 16px;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	height: ${imageHeight};

	@media screen and (max-width: ${tabletWidth}) {
		height: ${mobileImageHeight};
	}
`;

export const Image = styled.img`
	width: 780px;
	height: ${imageHeight};
	object-fit: cover;

	@media screen and (max-width: ${tabletWidth}) {
		width: 100%;
		height: ${mobileImageHeight};
	}
`;

export const CloseButtonContainer = styled.div`
	position: absolute;
	top: ${spaceDefault / 2}px;
	right: ${spaceDefault / 2}px;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: ${spaceDefault}px;
	line-height: 40px;
`;

export const TitleHeader = styled.h1`
	font-family: ${MontserratFontFamily};
	font-size: 24px;

	@media screen and (max-width: ${mobileWidth}) {
		font-size: 18px;
		line-height: 35px;
	}
`;

export const DurationLabel = styled.span`
	font-size: 14px;
	color: #666;
	min-width: 65px;
	text-align: right;

	svg {
		position: relative;
		top: 6px;
		margin-right: 3px;
	}
`;

export const ContainerWithMB = styled.div`
	margin-bottom: ${spaceDefault * 2}px;
`;

export const StyledChip = styled(Chip)`
	margin-right: 10px;
	background-color: ${colors.lightOrange};
`;
