import { array, boolean, mixed, number, object, ref, string } from "yup";
import { ObjectShape } from "yup/lib/object";

import { minutesToHM } from "utils/dates";

import useTranslation from "./useTranslation";

const useValidations = () => {
	const { t, withValuesAsString } = useTranslation();

	// translations
	const required = t("ERRORS.REQUIRED");
	const wrongEmailFormat = t("ERRORS.INVALID_EMAIL");
	const invalidFormat = t("ERRORS.INVALID_FORMAT");
	const mustBePastDate = t("ERRORS.MUST_BE_PAST_DATE");
	const tooShort = (min: number) => withValuesAsString("ERRORS.TOO_SHORT_SEQUENCE", { min });
	const tooLong = (max: number) => withValuesAsString("ERRORS.TOO_LONG_SEQUENCE", { max });
	const tooMany = (max: number) => withValuesAsString("ERRORS.TOO_MANY_ITEMS", { max });
	const tooFew = (min: number) => withValuesAsString("ERRORS.TOO_FEW_ITEMS", { min });
	const tooShortMinutes = (min: number) => withValuesAsString("ERRORS.TOO_SHORT_TIME", { min });
	const tooLongMinutes = (max: number) =>
		withValuesAsString("ERRORS.TOO_LONG_TIME", { max, formatted: minutesToHM(max) });

	// general
	const validations = (items?: ObjectShape, excludes?: any[]) => object().shape(items || {}, excludes);

	// @todo:fix - generic types
	// const validations = <T extends {}>(items?: ObjectSchema<T>) => object().shape(items || {});

	// required
	const requiredString = string().required(required);
	const requiredCheckbox = (errorMessage?: string) =>
		boolean()
			.required(required)
			.oneOf([true], errorMessage || required);
	const requiredEmail = string().email().required(required);
	const requiredStrongPassword = string().min(8).required(required);
	const requiredOneOfEnum = (enumObject: Record<string, string | number>) => mixed().oneOf(Object.values(enumObject));
	const requiredClearNameLength = (min: number) => requiredStringMinLength(min).matches(/^([^0-9]*)$/, invalidFormat);
	const requiredClearNameMinMaxLength = (min: number, max: number) =>
		requiredStringMinMaxLength(min, max).matches(/^([^0-9]*)$/, invalidFormat);
	const requiredStringMinLength = (min: number) => stringMinLength(min).required(required);
	const requiredStringMaxLength = (max: number) => string().max(max, tooLong(max)).required(required);
	const requiredStringMinMaxLength = (min: number, max: number) =>
		string().min(min, tooShort(min)).max(max, tooLong(max)).required(required);
	const stringRepeat = (repeatedField, messageError) =>
		string()
			.oneOf([ref(repeatedField), null], messageError)
			.required(required);
	const requiredNumber = number().required(required);
	const requiredMinMinutes = (min: number) => number().min(min, tooShortMinutes(min)).required(required);
	const requiredMinMaxMinutes = (min: number, max: number) =>
		number().min(min, tooShortMinutes(min)).max(max, tooLongMinutes(max)).required(required);

	// non-required
	const stringMinLength = (min: number) => string().min(min, tooShort(min));
	const stringMaxLength = (max: number) => string().max(max, tooLong(max));
	const stringMinMaxLength = (min: number, max: number) => string().min(min, tooShort(min)).max(max, tooLong(max));
	const maxArrayItems = (max: number) => array().max(max, tooMany(max));
	const minArrayItems = (min: number) => array().min(min, tooFew(min));
	const minMaxArrayItems = (min: number, max: number) => array().min(min, tooFew(min)).max(max, tooMany(max));

	return {
		yup: {
			// general
			validations,

			// required
			requiredString,
			requiredCheckbox,
			requiredEmail,
			requiredStrongPassword,
			requiredStringMinLength,
			requiredStringMaxLength,
			requiredStringMinMaxLength,
			requiredClearNameMinMaxLength,
			requiredClearNameLength,
			stringRepeat,
			requiredNumber,
			requiredMinMinutes,
			requiredMinMaxMinutes,
			requiredOneOfEnum,

			// non-required
			maxArrayItems,
			minArrayItems,
			minMaxArrayItems,
			stringMinLength,
			stringMaxLength,
			stringMinMaxLength,
		},
		translations: {
			tooShort,
			tooLong,
			tooMany,
			tooFew,
			tooShortMinutes,
			required,
			wrongEmailFormat,
			invalidFormat,
			mustBePastDate,
		},
	};
};

export default useValidations;
