import { Button } from "@material-ui/core";
import styled from "styled-components";

import MediapackImgSmall from "assets/images/help/mediapack-mockup.png";
import MediapackImgMedium from "assets/images/help/mediapack-mockup@2x.png";
import MediapackImgLarge from "assets/images/help/mediapack-mockup@3x.png";
import PressKitImgSmall from "assets/images/help/press-kit-mockup.png";
import PressKitImgMedium from "assets/images/help/press-kit-mockup@2x.png";
import PressKitImgLarge from "assets/images/help/press-kit-mockup@3x.png";
import PressImgSmall from "assets/images/help/press_main.png";
import PressImgMedium from "assets/images/help/press_main@2x.png";
import PressImgLarge from "assets/images/help/press_main@3x.png";

import useTranslation from "hooks/useTranslation";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import ContactButton from "ui/buttons/ContactButton";
import CommonParagraph, { SmallerTitle } from "ui/CommonParagraph";
import ResponsiveImage from "ui/ResponsiveImage";

import { Container, ImgWrapper, StyledExternalLink } from "styles/common";
import media from "styles/media";

const Col = styled.div`
	flex: 1;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      flex-direction: column;
  `};
`;

// const TilesWrapper = styled.div`
//   margin: 1.875rem auto;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex-wrap: wrap;
//
//   ${media.desktop`
//     flex-direction: column;
//     justify-content: center;
//   `};
// `;
//
// const TileContent = styled.div<{ backgroundUrl: string }>`
//   margin: 0.625rem 0.313rem 0 0;
//   padding: 1.563rem;
//   width: 20rem;
//   height: 23.75rem;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-end;
//   background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.backgroundUrl});
//   background-repeat: no-repeat;
//   background-size: cover;
//   border-radius: ${dimensions.radius.medium};
//   transition: all 0.5s linear;
//
//   &:hover {
//     transform: scale(1.03);
//   }
//
//   ${media.desktop`
//     margin: 1.875rem 0 0 0;
//   `};
// `;
//
// const TileDate = styled.div`
//   font-family: ${MontserratFontFamily};
//   font-size: ${dimensions.fontSize.medium};
//   font-weight: 500;
//   line-height: 2.17;
//   letter-spacing: 0.38px;
//   color: ${colors.white};
// `;
//
// const TileTitle = styled.h3`
//   margin: 0;
//   font-family: ${MontserratFontFamily};
//   font-size: 22px;
//   color: ${colors.white};
//   font-weight: bold;
//   line-height: 1.18;
//   letter-spacing: 0.69px;
// `;

// const ViewBtn = styled(Button)`
//   margin-top: 1.25rem;
//   height: 2.5rem;
//   width: 6.25rem;
// `;

// const ViewMoreBtn = styled(ViewBtn)`
//   height: 3rem;
//   width: 10rem;
// `;

const DownloadWrapper = styled.div`
	margin: 0 auto;
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.desktop`
    flex-direction: column;
    justify-content: center;
  `};
`;

const DownloadBoxContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const DownloadBtn = styled(Button)`
	height: 3rem;
	width: 13.625rem;
`;

const PressPage = () => {
	const { t, withRaw } = useTranslation();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("HELP.PRESS.MAIN_TITLE")} />

				<InnerWrapper>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("HELP.PRESS.MAIN_TITLE")}
							description={withRaw("HELP.PRESS.MAIN_DESCRIPTION", null)}
						/>
						<ContactButton translationKey="HELP.PRESS.CONTACT_US_BTN" />
					</Col>

					<Col>
						<ImgWrapper>
							<ResponsiveImage
								altDesc="Press - picture"
								imgSmall={PressImgSmall}
								imgMedium={PressImgMedium}
								imgLarge={PressImgLarge}
							/>
						</ImgWrapper>
					</Col>
				</InnerWrapper>

				{/*<>*/}
				{/*  <SmallerTitle>{t("HELP.PRESS.NEWSROOM_TITLE")}</SmallerTitle>*/}
				{/*  <TilesWrapper>*/}
				{/*    {*/}
				{/*      // @todo:future - add list from API*/}
				{/*      newsroomList.map((value, i) => (*/}
				{/*        <TileContent key={`${value.title}-${i}`} backgroundUrl={value.main_photo}>*/}
				{/*          <TileDate>{value.date}</TileDate>*/}
				{/*          <TileTitle>{value.title}</TileTitle>*/}
				{/*          <ViewBtn color="primary" variant="contained">*/}
				{/*            {t("HELP.PRESS.VIEW_BTN")}*/}
				{/*          </ViewBtn>*/}
				{/*        </TileContent>*/}
				{/*      ))*/}
				{/*    }*/}
				{/*  </TilesWrapper>*/}
				{/*  <Box display="flex" alignItems="center" justifyContent="center" width="100%">*/}
				{/*    <ViewMoreBtn color="primary" variant="contained">*/}
				{/*      {t("HELP.PRESS.VIEW_MORE_BTN")}*/}
				{/*    </ViewMoreBtn>*/}
				{/*  </Box>*/}
				{/*</>*/}

				<>
					<SmallerTitle>{t("HELP.PRESS.DOWNLOAD_TITLE")}</SmallerTitle>

					<DownloadWrapper>
						<DownloadBoxContent>
							<ResponsiveImage
								altDesc="Download Media Pack - picture"
								imgSmall={MediapackImgSmall}
								imgMedium={MediapackImgMedium}
								imgLarge={MediapackImgLarge}
							/>

							<StyledExternalLink href="/documents/LocalBini-Media-Pack.zip" target="_blank">
								<DownloadBtn color="primary" variant="contained">
									{t("HELP.PRESS.DOWNLOAD_MEDIA_BTN")}
								</DownloadBtn>
							</StyledExternalLink>
						</DownloadBoxContent>

						<DownloadBoxContent>
							<ResponsiveImage
								altDesc="Download Press Kit - picture"
								imgSmall={PressKitImgSmall}
								imgMedium={PressKitImgMedium}
								imgLarge={PressKitImgLarge}
							/>

							<StyledExternalLink href="/documents/LocalBini-Press-Kit.pdf" target="_blank">
								<DownloadBtn color="primary" variant="contained">
									{t("HELP.PRESS.DOWNLOAD_PRESS_BTN")}
								</DownloadBtn>
							</StyledExternalLink>
						</DownloadBoxContent>
					</DownloadWrapper>
				</>
			</Container>
		</AppWrapper>
	);
};

export default PressPage;
