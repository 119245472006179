import { Namespace, TFunction } from "i18next";
import { isArray } from "lodash-es";
import { ComponentType, ReactElement } from "react";
import { Trans, useTranslation as i18Translation } from "react-i18next";

type TComponents = readonly ReactElement[] | { readonly [tagName: string]: ReactElement };
type TTransParent = string | ComponentType<any> | null;

export const replaceInTranslationKey = (translationKey: string, values: Record<string, any>) => {
	let translationKeyLocal = translationKey;

	for (const key of Object.keys(values)) {
		translationKeyLocal = translationKeyLocal.replace(`{${key}}`, values[key]);
	}

	return translationKeyLocal;
};

function useTranslation(namespace?: Namespace) {
	const localNamespace: string[] = namespace ? (isArray(namespace) ? namespace : [namespace]) : ["common"];

	const translation = i18Translation(localNamespace);

	// TODO:refactor improve types!
	// @ts-ignore
	const t: TFunction = (key, options) => {
		let translated;

		translated = translation.t(key, options);

		if (translated !== key) {
			return translated;
		}

		const validNs = localNamespace.find(ns => translation.t(key, { ...options, ns }) !== key);

		if (!!validNs) {
			return translation.t(key, { ...options, ns: validNs });
		}

		translated = translation.t(key, { ...options, ns: key.split(".")[0].toLowerCase() });

		if (translated !== key) {
			return translated;
		}

		return translated;
	};

	const withComponentsValues = (
		translationKey: string,
		components: TComponents,
		values?: {},
		parent?: TTransParent,
	) => <Trans ns={localNamespace} i18nKey={translationKey} values={values} components={components} parent={parent} />;

	const withValues = (translationKey: string, values?: {}, parent?: TTransParent) => (
		<Trans ns={localNamespace} i18nKey={translationKey} values={values} parent={parent} />
	);

	const withValuesAsString = (translationKey: string, values?: {}): string =>
		// TODO:refactor improve types!
		// @ts-ignore
		t(translationKey, values);

	const withComponents = (translationKey: string, components: TComponents, parent?: TTransParent) => (
		<Trans ns={localNamespace} i18nKey={translationKey} components={components} parent={parent} />
	);

	const withRaw = (translationKey: string, parent?: TTransParent) => (
		<Trans ns={localNamespace} i18nKey={translationKey} parent={parent} />
	);

	const withTaggedKey = (translationKey: string, keys: Record<string, any>) => t(tagKey(translationKey, keys));

	const tagKey = (translationKey: string, keys: Record<string, any>) => replaceInTranslationKey(translationKey, keys);

	const withTaggedKeyWithValues = (
		translationKey: string,
		keys: Record<string, any>,
		values: Record<string, any>,
	): string => withValuesAsString(replaceInTranslationKey(translationKey, keys), values);

	const translateArray = (data: string[]) => data?.map(translationKey => t(translationKey));

	return {
		t,
		translateArray,
		withRaw,
		withValues,
		withValuesAsString,
		withComponents,
		withComponentsValues,
		withTaggedKey,
		withTaggedKeyWithValues,
		tagKey,

		i18n: translation.i18n,
	};
}

export default useTranslation;
