import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ECurrency } from "data/financials/types";

import experiencesService from "services/api/experiences";
import financialsService from "services/api/financials";

import { BookingFormValues } from "components/experiences/Traveller/components/BookingForm/types";

export const initialBookingValues: BookingFormValues = {
	eid: "",
	language: "",
	adults: 1,
	kids: 0,
	selectedChildren: {},
	date: [],
	hour: [],
	selectedDates: {
		dateTo: null,
		dateFrom: null,
	},
	isPrivate: false,
	pricing: {
		// null values for not showing initial values as number to user
		// @ts-ignore
		final_gross_price: null,
		tax: {
			// null values for not showing initial values as number to user
			// @ts-ignore
			vat: null,
		},
		tickets: {
			adults: {
				// @ts-ignore
				price: null,
				// @ts-ignore
				quantity: null,
			},
			kids: {},
		},
	},
	showVat: false,
	travelerDetails: {
		email: "",
		first_name: "",
		phone: "",
		last_name: "",
	},
	comment: "",
	createdUserId: null,
	postGeneratePriceSummaryPayload: {
		experience_id: "",
		tickets: {
			adults: 1,
		},
	},
};

export interface IInitialState {
	data: any;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
	booking: BookingFormValues;
	braintreeToken: string | null | undefined;
}
const initialState: IInitialState = {
	data: null,
	status: "idle",
	error: null,
	booking: initialBookingValues,
	braintreeToken: null,
};

export const fetchExperienceDetailsTraveller = createAsyncThunk<any, any>(
	"experience/fetch",
	async (params: {
		id: string;
		withExtraData?: boolean;
		withoutUpcomingBookings?: boolean;
		withGuestCredentials?: boolean;
	}) => {
		return experiencesService.getExperienceById(
			params.id,
			params.withoutUpcomingBookings ? undefined : { with_upcoming_bookings: true },
			params.withExtraData,
			params.withGuestCredentials,
		);
	},
);

export const getBrainTreeToken = createAsyncThunk("financials/braintree/token", async (currency: ECurrency) => {
	return await financialsService.getBrainTreeToken(currency);
});

const { actions, reducer } = createSlice({
	name: "experienceTraveller",
	initialState,
	reducers: {
		reset() {
			return initialState;
		},
		updateBooking(state, action: PayloadAction<Partial<BookingFormValues>>) {
			return {
				...state,
				booking: {
					...state.booking,
					...action.payload,
				},
			};
		},
		resetBooking(state) {
			return {
				...state,
				booking: {
					...initialBookingValues,
					eid: state.booking.eid,
				},
			};
		},
	},
	extraReducers: {
		[getBrainTreeToken.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.braintreeToken = action.payload?.token;
		},
		[getBrainTreeToken.rejected.toString()]: state => {
			state.braintreeToken = undefined;
		},
		[fetchExperienceDetailsTraveller.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data = action.payload;
			state.status = "succeeded";
			state.error = null;
		},
		[fetchExperienceDetailsTraveller.pending.toString()]: state => {
			state.status = "loading";
			state.error = null;
		},
		[fetchExperienceDetailsTraveller.rejected.toString()]: (state, action: any) => {
			state.status = "failed";
			state.error = action.error;
		},
	},
});

export const experienceTravellerActions = {
	...actions,
};
export default reducer;
