import { isUndefined } from "lodash-es";
import { DependencyList, MutableRefObject, useLayoutEffect, useRef, useState } from "react";

interface IPosition {
	x: number;
	y: number;
}

interface IScrollProps {
	prevPos: IPosition;
	currPos: IPosition;
}

type ElementRef = MutableRefObject<HTMLElement | undefined>;

const zeroPosition = { x: 0, y: 0 };

const getClientRect = (element?: HTMLElement) => element?.getBoundingClientRect();

const getScrollPosition = ({
	element,
	useWindow,
	boundingElement,
}: {
	element?: ElementRef;
	boundingElement?: ElementRef;
	useWindow?: boolean;
}) => {
	if (useWindow) {
		return { x: window.scrollX, y: window.scrollY };
	}

	const targetPosition = getClientRect(element?.current || document.body);
	const containerPosition = getClientRect(boundingElement?.current);

	if (!targetPosition) {
		return zeroPosition;
	}

	return containerPosition
		? {
				x: (containerPosition.x || 0) - (targetPosition.x || 0),
				y: (containerPosition.y || 0) - (targetPosition.y || 0),
		  }
		: { x: targetPosition.left, y: targetPosition.top };
};

// https://github.com/n8tb1t/use-scroll-position
const useScrollPosition = (
	effect: (props: IScrollProps) => void,
	deps: DependencyList = [],
	element?: ElementRef,
	useWindow?: boolean,
	wait?: number,
	boundingElement?: ElementRef,
): void => {
	const position = useRef(getScrollPosition({ useWindow, boundingElement }));

	const [throttleTimeout, setThrottleTimeout] = useState<ReturnType<typeof setTimeout>>();

	const callBack = () => {
		const currPos = getScrollPosition({ element, useWindow, boundingElement });

		effect({ prevPos: position.current, currPos });

		position.current = currPos;

		setThrottleTimeout(undefined);
	};

	useLayoutEffect(() => {
		const elementRef = boundingElement?.current;

		const handleScroll = () => {
			if (wait) {
				if (isUndefined(throttleTimeout)) {
					setThrottleTimeout(setTimeout(callBack, wait));
				}
			} else {
				callBack();
			}
		};

		if (elementRef) {
			elementRef?.addEventListener("scroll", handleScroll, { passive: true });
		} else {
			window.addEventListener("scroll", handleScroll, { passive: true });
		}

		return () => {
			if (elementRef) {
				elementRef?.removeEventListener("scroll", handleScroll);
			} else {
				window.removeEventListener("scroll", handleScroll);
			}

			if (throttleTimeout) {
				clearTimeout(throttleTimeout);
			}
		};

		// eslint-disable-next-line
	}, deps);
};

export { useScrollPosition };
