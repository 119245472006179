import { EExpType } from "../experiences/types";
import { ECurrency } from "../financials/types";
import { IId } from "../types";

export enum EPromoCodeType {
	AFFILIATE_CODE = "AFFILIATE_CODE",
	PROMO_CODE = "PROMO_CODE",
	// PROMO_CODE_POOL = "PROMO_CODE_POOL",
	VOUCHER = "VOUCHER",
	// VOUCHER_POOL = "VOUCHER_POOL",
	GIFT_CARD = "GIFT_CARD",
	// GIFT_CARD_POOL = "GIFT_CARD_POOL",
}

export enum EPromoCodeStatus {
	ACTIVE = "ACTIVE",
	EXPIRED = "EXPIRED",
	INACTIVE = "INACTIVE",
	NEW = "NEW",
	REMOVED = "REMOVED",
	USED = "USED",
}

export enum EPromoCodeScope {
	CITY = "CITY",
	COUNTRY = "COUNTRY",
}

enum EPromoCodeUserType {
	LOCALHOST = "LOCALHOST",
	OTA = "OTA",
	ROOT = "ROOT",
	STAFF = "STAFF",
	TRAVELER = "TRAVELER",
	UNREGISTERED_TRAVELER = "UNREGISTERED_TRAVELER",
}

export enum EPromoCodeDiscountType {
	CURRENCY = "CURRENCY",
	PERCENTAGE = "PERCENTAGE",
}

export enum EPromoCodesUsageStatus {
	BOOKING_CREATED = "BOOKING_CREATED",
	BOOKING_PERFORMED = "BOOKING_PERFORMED",
	BOOKING_CANCELED = "BOOKING_CANCELED",
	LOCALHOST_REGISTERED = "LOCALHOST_REGISTERED",
	LOCALHOST_BOOKED = "LOCALHOST_BOOKED",
	LOCALHOST_RATED = "LOCALHOST_RATED",
	LOCALHOST_DELETED = "LOCALHOST_DELETED",
	PAID = "PAID",
}

export enum EPromoCodesUsageType {
	BOOKING_PROFIT = "BOOKING_PROFIT",
	BOOKING_DISCOUNT = "BOOKING_DISCOUNT",
	NEW_LOCALHOST_PROFIT = "NEW_LOCALHOST_PROFIT",
	NEW_LOCALHOST_BONUS = "NEW_LOCALHOST_BONUS",
}

export type TDiscountType = keyof typeof ECurrency & EPromoCodeDiscountType.PERCENTAGE;
export type TPromoCodeType = keyof typeof EPromoCodeType;
export type TPromoCodeDiscountType = keyof typeof EPromoCodeDiscountType;
export type TPromoCodeScope = keyof typeof EPromoCodeScope;

interface IDatePeriod {
	end: Date | string | null;
	start: Date | string | null;
}

export interface IPromoCode {
	allowed_experiences: {
		allow_private: boolean;
		allow_vips_and_over_200_pp: boolean;
		cities: string[] | null;
		countries: string[] | null;
		ids: string[] | null;
		types: EExpType[] | null;
	};
	booking_availability_period: IDatePeriod;
	code_availability_period: IDatePeriod;
	booking_price_limits: {
		max_price: number | null;
		min_price: number | null;
	};
	code?: string | null;
	domain_limits: {
		domains: string[];
		localbini: boolean;
	};
	message?: string | null;
	name?: string | null;
	non_traveler_benefits: {
		flat_gain: number | null;
		max_flat_gain_percent: number | null;
		max_percent_gain_amount: number | null;
		percent_gain: number | null;
		localhost_recruitment_bonus?: number | null;
		new_localhost_signup_bonus?: number | null;
	};
	owner: `${"usr-" | "par-"}${string}` | null;
	currency?: ECurrency | null;
	traveler_benefits: {
		flat_discount: number | null;
		max_flat_discount_percent: number | null;
		max_percent_discount_amount: number | null;
		percent_discount: number | null;
	};
	type?: EPromoCodeType | null;
	usage_limits: {
		per_user_limit: number | null;
		total_limit: number | null;
	};
	user_limits: {
		registered?: boolean | null;
		types?: EPromoCodeUserType | null;
		unregistered?: boolean | null;
	};
	usage_count?: number;
	status?: EPromoCodeStatus | null;
}

export enum EPartnerOrUser {
	partner = "partner",
	user = "user",
}

export enum EUserUsageRadio {
	once = "once",
	more = "more",
}

export interface IPromoCodeTemp {
	user_usage_radio?: EUserUsageRadio;
	partner_or_user?: EPartnerOrUser | null;
	traveler_discount_type?: EPromoCodeDiscountType;
	non_traveler_discount_type?: EPromoCodeDiscountType;
	user_enabled: {
		usage_per_user?: boolean;
		usage_total?: boolean;
		type_limits?: boolean;
	};
	experience_enabled: {
		city?: boolean;
		country?: boolean;
	};
	enabled: {
		time_section?: boolean;
		experience_section?: boolean;
		user_section?: boolean;
		owner_section?: boolean;
		booking_section?: boolean;
		traveler_section?: boolean;
		non_traveler_section?: boolean;
	};
	unused_owner_field?: string;
}

export interface IPromoCodeWithTemp extends IPromoCode {
	temp?: IPromoCodeTemp;
}

export type TPromoCodeIId = IPromoCode & IId;

export type TPromoCodeStore = Omit<IPromoCode, "usage_count" | "status">;

export type TPromoCodePartialStore = Partial<TPromoCodeStore>;

export interface IPromoCodesUsage {
	amount: number;
	beneficiary: string;
	booking_id: string | null;
	code: string;
	currency: ECurrency;
	experience_id: string | null;
	experience_version: number | null;
	referred_localhost_id: string | null;
	superbooking_id: string | null;
	status: EPromoCodesUsageStatus;
	type: EPromoCodesUsageType;
}

export interface IPromoCodesUsageStatistics {
	total: number;
	by_type?: {
		[key in EPromoCodesUsageType]?: number;
	};
}

export interface IPromoCodeUsageNotGenerated {
	non_traveler_benefits: {
		flat_gain: number | null;
		localhost_recruitment_bonus: number | null;
		max_flat_gain_percent: number | null;
		max_percent_gain_amount: number | null;
		new_localhost_signup_bonus: number | null;
		percent_gain: number | null;
	};
	traveler_benefits: {
		flat_discount: number | null;
		max_flat_discount_percent: number | null;
		max_percent_discount_amount: number | null;
		percent_discount: number | null;
	};
	usage_limits: {
		per_user_limit: number | null;
		total_limit: number | null;
	};
	user_limits: {
		registered: boolean;
		unregistered: boolean;
		types: unknown;
	};
}

export type TPromoCodesUsageIId = IPromoCodesUsage & IId;
