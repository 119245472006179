import { Button } from "@material-ui/core";
import { memo } from "react";
import styled from "styled-components";

import HostingExperienceImg from "assets/images/binipoolConcept/hosting_experience.svg";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import { Description, SmallerTitle } from "ui/CommonParagraph";

import { Container, StyledLink } from "styles/common";
import media from "styles/media";

import { WrapperSection } from "../../index";

const MainSection = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${media.desktop`
      flex-direction: column;
      justify-content: center;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const MainImgContent = styled.img`
	margin-top: 0;
	width: 493px;
	height: 551px;

	${media.desktop`
      margin-top: 3.125rem;
      width: 100%;
      height: 100%;
  `};
`;

const LetsGoBtn = styled(Button)`
	margin-top: 1.875rem;
	height: 3rem;
	width: 10rem;
`;

const HostingExperience = memo(() => {
	const { t, withRaw } = useTranslation();

	const userData = useAppSelector(selectUser);

	return (
		<WrapperSection>
			<Container>
				<MainSection>
					<Col>
						<SmallerTitle>{t("BINIPOOL_CONCEPT.HOSTING_EXP_TITLE")}</SmallerTitle>

						<Description>{withRaw("BINIPOOL_CONCEPT.HOSTING_EXP_DESC", null)}</Description>

						{!([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
							userData?.userType,
						) && (
							<StyledLink
								to={fillRoute(paths.ONBOARDING_REGISTER, { search: { type: "binipool" } })}
								$withoutHoverUnderline
							>
								<LetsGoBtn color="primary" variant="contained">
									{t("BINIPOOL_CONCEPT.HOSTING_EXP_LETS_GO")}
								</LetsGoBtn>
							</StyledLink>
						)}
					</Col>

					<Col>
						<MainImgContent
							src={HostingExperienceImg}
							title={t("BINIPOOL_CONCEPT.HOSTING_EXP_TITLE")}
							alt={t("BINIPOOL_CONCEPT.HOSTING_EXP_TITLE")}
						/>
					</Col>
				</MainSection>
			</Container>
		</WrapperSection>
	);
});

export default HostingExperience;
