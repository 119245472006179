import * as Yup from "yup";

import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

const useValidateSchema = (partner?: boolean) => {
	const { t } = useTranslation();

	const {
		yup: {
			validations,
			requiredStringMinMaxLength,
			requiredCheckbox,
			stringRepeat,
			requiredString,
			requiredEmail,
			requiredStrongPassword,
		},
	} = useValidations();

	return validations({
		firstName: requiredStringMinMaxLength(2, 100),
		lastName: requiredStringMinMaxLength(2, 100),
		phone: requiredString,
		email: !partner ? requiredEmail : Yup.string().email(),
		password: Yup.string().when("createAccount", {
			is: true,
			then: requiredStrongPassword,
		}),
		confirmPassword: Yup.string().when("createAccount", {
			is: true,
			then: stringRepeat("password", t("SETTINGS.PASSWORD.NOT_MATCH")),
		}),
		claimsAdult: Yup.bool().when("createAccount", {
			is: true,
			then: requiredCheckbox(),
		}),
	});
};

export default useValidateSchema;
