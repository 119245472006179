import { Button, Dialog, DialogActions, DialogContent, Divider } from "@material-ui/core";
import { default as CloseIcon } from "@material-ui/icons/Close";
import { default as ScheduleIcon } from "@material-ui/icons/Schedule";

import useTranslation from "hooks/useTranslation";

import useCity from "store/hooks/useCity";
import useConfigEarnings from "store/hooks/useConfigEarnings";

import { prices } from "utils/prices";

import { IExperienceElem } from "../../index";
import {
	CloseButtonContainer,
	ContainerWithMB,
	Desc,
	DurationLabel,
	Image,
	ImageContainer,
	ModalContent,
	StyledChip,
	TitleContainer,
	TitleHeader,
} from "./styled";

interface ModalProps {
	openModal: boolean;
	handleCloseModal(): void;
}

interface ExperienceEmailModalProps extends ModalProps {
	experienceDetails: IExperienceElem;
}

const ExperienceDetailsModal = ({ handleCloseModal, openModal, experienceDetails }: ExperienceEmailModalProps) => {
	const { t, withValues } = useTranslation();

	const { cities } = useCity();
	const { earnings } = useConfigEarnings();

	const hours = experienceDetails?.duration_time || 0;

	const foundCity = cities?.find(city => city.name === experienceDetails.city);

	const currency = experienceDetails.currency_symbol ?? foundCity?.country?.currency;

	const cityEarnings =
		foundCity?.binipool_earnings_base?.NORMAL || foundCity?.country?.binipool_earnings_base?.NORMAL || 0;

	const minCityEarnings =
		typeof earnings?.hour_factor.NORMAL === "number"
			? cityEarnings * (1 + earnings.hour_factor.NORMAL * (hours - 1))
			: 0;
	const maxCityEarnings =
		typeof earnings?.max_factor.NORMAL === "number" ? minCityEarnings * earnings.max_factor.NORMAL : 0;

	return (
		<Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal} maxWidth="md">
			<ImageContainer>
				<Image src={experienceDetails.photo} alt={experienceDetails.title} />

				<CloseButtonContainer>
					<DialogActions>
						<Button autoFocus onClick={handleCloseModal} color="inherit">
							<CloseIcon />
						</Button>
					</DialogActions>
				</CloseButtonContainer>
			</ImageContainer>

			<ModalContent>
				<DialogContent dividers>
					<TitleContainer id="experience-details-modal-title">
						<TitleHeader>{experienceDetails.title}</TitleHeader>

						<DurationLabel>
							<ScheduleIcon /> {experienceDetails.duration_time} {t("COMMON.HOUR_SHORT")}
						</DurationLabel>
					</TitleContainer>

					<ContainerWithMB>
						{experienceDetails.categories.map(category => (
							<StyledChip label={category} key={category} />
						))}
					</ContainerWithMB>

					<Desc>
						<b>{experienceDetails.description}</b>
					</Desc>

					<Desc>{experienceDetails.description_uniqueness}</Desc>

					{minCityEarnings > 0 && (
						<>
							<ContainerWithMB>
								<Divider />
							</ContainerWithMB>

							<Desc>
								<b>{t("ONBOARDING.BINIPOOL.MODAL.EARNINGS")}</b>
							</Desc>

							<Desc noBottom>
								{withValues(
									minCityEarnings === maxCityEarnings
										? "ONBOARDING.BINIPOOL.EARNINGS_EQUAL_DESCRIPTION"
										: "ONBOARDING.BINIPOOL.EARNINGS_DESCRIPTION",
									{
										min: prices(Math.floor(minCityEarnings), currency, undefined, 0),
										max: prices(Math.floor(maxCityEarnings), undefined, undefined, 0),
										hours,
									},
									null,
								)}
							</Desc>
						</>
					)}
				</DialogContent>
			</ModalContent>
		</Dialog>
	);
};

export default ExperienceDetailsModal;
