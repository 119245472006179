import { Box } from "@material-ui/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import qs from "query-string";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { partnerStorage } from "classes/partner/session/PartnerStorage";

import { EOnboardingSteps, EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import partnersService from "services/api/backoffice/partners";
import { confirmSignInWithMFA, isMFALogin, signIn } from "services/aws/auth";

import { localStorageKeys } from "utils/constants";
import fillRoute from "utils/routes";

import CheckboxField from "components/formik/CheckboxField";
import CodeInput from "components/formik/CodeInput";
import Input from "components/formik/Input";
import PasswordInput from "components/formik/Input/variant/PasswordInput";

import ActionButton from "ui/buttons/ActionButton";

import { EMFActivating } from "../../../SetMFA/types";
import validateMfaForm from "../../../SetMFA/validate";
import { RememberMeContainer, StyledForm } from "./styled";
import validateLoginForm from "./validate";

interface ILoginForm {
	email?: string | null;
	setMFA?: (value: boolean) => void;
	onboarding?: boolean;
	binipool?: boolean;
}

const Login = ({ email, setMFA, onboarding = false, binipool = false }: ILoginForm) => {
	const { t } = useTranslation();

	const history = useHistory();
	const location = useLocation();

	const { handleAndNotify, silent } = useErrors();

	const [userTemp, setUserTemp] = useState<CognitoUser | any>();
	const [isMFA, setIsMFA] = useState<boolean>(false);
	const [mfaType, setMfaType] = useState<EMFActivating>();
	const [rememberMe, setRememberMe] = useState<boolean>(false);

	const { redirect } = qs.parse(location.search);

	const setLocalMFA = (value: boolean) => {
		setIsMFA(value);

		if (!!setMFA) {
			setMFA(value);
		}
	};

	const createRoute = async () => {
		if (onboarding) {
			return fillRoute(paths.ONBOARDING, { step: EOnboardingSteps.PhoneVerify });
		}

		try {
			const user = await Auth.currentAuthenticatedUser();

			const userType = user.attributes?.["custom:type"];

			if (userType === EUserType.TRAVELER) {
				return paths.MY_BOOKINGS;
			}

			if (
				[EUserType.LOCALHOST, EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT].includes(
					userType,
				)
			) {
				return paths.DASHBOARD;
			}

			if ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER].includes(userType)) {
				const id = user.attributes["custom:partner_id"];

				let partnerData;

				try {
					partnerData = await partnersService.getDetails(id);
				} catch (e) {
					handleAndNotify(e);
				}

				partnerStorage.clear();

				partnerStorage.store(
					{
						id,
						...(partnerData && { logo: partnerData.logo }),
						...(partnerData && { name: partnerData.name }),
					},
					"local",
				);

				return paths.PARTNER_BOOKINGS;
			}
		} catch {
			return paths.HOMEPAGE;
		}

		return paths.HOMEPAGE;
	};

	const successResult = async () => {
		if (rememberMe) {
			await Auth.rememberDevice().catch(e => silent(e));
		}

		const route = await createRoute();

		window.localStorage.setItem(
			localStorageKeys.user.hasEverLogged.key,
			localStorageKeys.user.hasEverLogged.defaultValue,
		);

		history.push(
			!!redirect
				? (redirect as string)
				: {
						pathname: route,
						search: qs.stringify({ ...(binipool ? { type: "binipool" } : {}) }),
				  },
		);

		return;
	};

	const formikLoginProps = useFormik({
		enableReinitialize: true,
		validationSchema: validateLoginForm(),
		initialValues: {
			username: email || "",
			password: "",
			remember_me: false,
		},
		onSubmit: async values => {
			try {
				setRememberMe(values.remember_me);

				const result = await signIn(onboarding, values.username.toLowerCase(), values.password);

				const checkMFA = isMFALogin(result);

				if (checkMFA) {
					setUserTemp(result);

					setLocalMFA(true);

					setMfaType(checkMFA);

					return;
				}

				if (!!result) {
					await successResult();
				}
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	const formikMFAProps = useFormik({
		enableReinitialize: true,
		validationSchema: validateMfaForm(),
		initialValues: {
			code: "",
		},
		onSubmit: async values => {
			try {
				await confirmSignInWithMFA(userTemp, values.code, mfaType);

				await successResult();
			} catch (e) {
				formikMFAProps.resetForm();

				handleAndNotify(e);

				setUserTemp(null);

				setLocalMFA(false);
			}
		},
	});

	return isMFA ? (
		<FormikProvider value={formikMFAProps}>
			<Box textAlign="center">
				{t("AUTH.LOGIN.SUB_HEADER_MFA")} <strong>{t(`AUTH.LOGIN.SELECTED_MFA_TYPE.${mfaType}`)}</strong>
			</Box>

			<StyledForm onSubmit={formikMFAProps.handleSubmit}>
				<CodeInput fields={6} name="code" pattern="[0-9]+" inputMode="numeric" autoFocus />

				<ActionButton
					style={{ marginTop: 15 }}
					isAction={formikMFAProps.isSubmitting}
					translationDefault="AUTH.MFA.LOGIN"
					translationAction="AUTH.LOGIN.LOGGING_IN"
				/>
			</StyledForm>
		</FormikProvider>
	) : (
		<FormikProvider value={formikLoginProps}>
			<Formik onSubmit={formikLoginProps.handleSubmit} noValidate>
				<Input
					id="id-username"
					required
					name="username"
					type="email"
					inputClassName="full break"
					label={t(`AUTH.LOGIN.USERNAME`)}
				/>

				<PasswordInput
					required
					name="password"
					label={t("AUTH.LOGIN.PASSWORD")}
					inputClassName="full"
					autoComplete="current-password"
				/>

				<RememberMeContainer>
					<CheckboxField name="remember_me" label={t("AUTH.LOGIN.REMEMBER_ME")} />
				</RememberMeContainer>

				<Box textAlign="center">
					<ActionButton
						isAction={formikLoginProps.isSubmitting}
						translationDefault="AUTH.LOGIN.SUBMIT_BUTTON"
						translationAction="AUTH.LOGIN.LOGGING_IN"
					/>
				</Box>
			</Formik>
		</FormikProvider>
	);
};

export default Login;
