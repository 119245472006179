import { Box, Checkbox, Grid, Switch, useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ENotificationCategory, ENotificationRecipient, INotification } from "data/notifications/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import notificationsService from "services/api/notifications";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectNotifications } from "store/selectors/notifications";
import { selectUser } from "store/selectors/user";
import { fetchUnreadNotifications } from "store/slices/notifications";

import { localStorageKeys } from "utils/constants";
import { secondsToOtherFormats } from "utils/dates";
import fillRoute from "utils/routes";

import ActionButton from "ui/buttons/ActionButton";
import CircularLoader from "ui/loaders/CircularLoader";
import NotificationRecord from "ui/NotificationRecord";

import { CategoriesFilters, Header, MenuElem, StyledArrow, StyledFormControlLabel, SwitchContainer } from "./styled";

interface IQueryParams {
	limit?: number;
	category?: ENotificationCategory[];
	marked_as_read?: boolean;
	recipient_type?: ENotificationRecipient;
}

const listItemsOffset = 5;

const NotificationsSimple = () => {
	const { t, withRaw } = useTranslation();

	const history = useHistory();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const userData = useAppSelector(selectUser);
	const notificationsGlobal = useAppSelector(selectNotifications);

	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [results, setResults] = useState<INotification[]>();
	const [loader, setLoader] = useState<"GLOBAL" | false>("GLOBAL");

	const [showCategories, setShowCategories] = useState<boolean>(false);
	const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(
		!!window.localStorage.getItem(localStorageKeys.notifications.showOnlyUnread.key),
	);
	const [categories, setCategories] = useState<ENotificationCategory[]>([]);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const fetchData = async () => {
		setLoader("GLOBAL");

		const queryParams: IQueryParams = {
			limit: listItemsOffset,
			...(!partnerType && { recipient_type: userData?.userType as unknown as ENotificationRecipient }),
			...(showOnlyUnread && { marked_as_read: false }),
			...(categories.length && { category: categories }),
		};

		try {
			const response = await notificationsService.getSearchNotifications(queryParams);

			setResults(response.data);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		fetchData();

		// eslint-disable-next-line
	}, [userData?.userType, showOnlyUnread, categories]);

	const markAsRead = async (id: string, read: boolean) => {
		if (results) {
			const newResults = [...results];

			const objIndex = newResults.findIndex(obj => obj.id === id);

			try {
				newResults[objIndex].marked_as_read = read;

				setResults(newResults);

				await notificationsService.patchUpdateNotification(id, read);

				dispatch(fetchUnreadNotifications(!partnerType ? { recipient_type: userData?.userType } : undefined));
			} catch (e) {
				handleAndNotify(e);

				newResults[objIndex].marked_as_read = !read;

				setResults(newResults);
			}
		}
	};

	const countOfAllCategories = Object.values(ENotificationCategory).reduce(
		(result, elem) => result + (notificationsGlobal.unreadNotifications?.statistics.by_category?.[elem] || 0),
		0,
	);

	const SwitcherComponent = () => (
		// @ts-ignore
		<SwitchContainer component="label" container spacing={1}>
			<Grid item>
				<Box fontSize="12px" lineHeight="39px">
					{t("NOTIFICATIONS.SHOW_ONLY_UNREAD_BUTTON")}
				</Box>
			</Grid>

			<Grid item>
				<Switch
					checked={showOnlyUnread}
					size="medium"
					onChange={() =>
						setShowOnlyUnread(prevState => {
							if (prevState) {
								window.localStorage.removeItem(localStorageKeys.notifications.showOnlyUnread.key);
							} else {
								window.localStorage.setItem(
									localStorageKeys.notifications.showOnlyUnread.key,
									localStorageKeys.notifications.showOnlyUnread.defaultValue,
								);
							}

							return !prevState;
						})
					}
				/>
			</Grid>
		</SwitchContainer>
	);

	return (
		<Box width={isMobileScreen ? "100%" : "415px"} paddingY="30px" paddingX="15px" borderRadius="4px">
			<Box display="flex" justifyContent="space-between">
				<Header>{t("TOPBAR.NOTIFICATIONS_LABEL")}</Header>

				<SwitcherComponent />
			</Box>

			<Box display="flex" alignItems="flex-end" borderBottom="1px solid #dadada">
				<MenuElem active={!categories.length} onClick={() => setCategories([])}>
					{t("NOTIFICATIONS.CATEGORIES_FILTER.ALL")}

					{!!notificationsGlobal.unreadNotifications?.statistics.total && (
						<>
							&nbsp;<strong>({notificationsGlobal.unreadNotifications.statistics.total})</strong>
						</>
					)}
				</MenuElem>

				<MenuElem active={!!categories.length} onClick={() => setShowCategories(prevState => !prevState)}>
					{t("NOTIFICATIONS.CATEGORIES_FILTER")}

					{countOfAllCategories > 0 && (
						<>
							&nbsp;<strong>({countOfAllCategories})</strong>
						</>
					)}

					<StyledArrow $isOpened={showCategories} />
				</MenuElem>
			</Box>

			{showCategories && (
				<CategoriesFilters>
					<Grid container>
						{Object.values(ENotificationCategory).map(elem => (
							<Grid key={elem} xs={6} item>
								<StyledFormControlLabel
									control={
										<Checkbox
											checked={categories.includes(elem)}
											onChange={() =>
												setCategories(prevState => {
													if (prevState.includes(elem)) {
														return prevState.filter(oldElem => oldElem !== elem);
													} else {
														return [...prevState, elem];
													}
												})
											}
											name={elem}
										/>
									}
									label={
										<span>
											{t(`NOTIFICATIONS.CATEGORIES.${elem}`)}

											{!!notificationsGlobal.unreadNotifications?.statistics.by_category?.[elem] && (
												<>
													&nbsp;
													<strong>({notificationsGlobal.unreadNotifications.statistics.by_category[elem]})</strong>
												</>
											)}
										</span>
									}
								/>
							</Grid>
						))}
					</Grid>
				</CategoriesFilters>
			)}

			<Box marginTop="20px">
				<Box>
					{loader === "GLOBAL" ? (
						<Box position="relative" height="150px">
							<CircularLoader />
						</Box>
					) : (
						<>
							{results?.length === 0 && (
								<Box textAlign="center" marginTop="30px">
									<Box fontWeight="bold" fontSize="16px" marginBottom="10px">
										{t(
											showOnlyUnread ? "NOTIFICATIONS.NO_RESULTS.TITLE.ONLY_UNREAD" : "NOTIFICATIONS.NO_RESULTS.TITLE",
										)}
									</Box>

									<Box fontSize="14px">
										{withRaw(
											showOnlyUnread
												? "NOTIFICATIONS.NO_RESULTS.SUB_TITLE.ONLY_UNREAD"
												: "NOTIFICATIONS.NO_RESULTS.SUB_TITLE",
											null,
										)}
									</Box>
								</Box>
							)}

							{results?.map(elem => (
								<NotificationRecord
									key={elem.id}
									compactVersion
									title={elem.title}
									description={elem.message}
									subTitle={elem.subject_name}
									sent={
										secondsToOtherFormats(
											Math.round(
												(new Date().getTime() +
													new Date().getTimezoneOffset() * 60000 -
													new Date(elem.created_on).getTime()) /
													1000,
											),
											true,
											true,
										) + " ago"
									}
									read={elem.marked_as_read}
									makeRead={() => markAsRead(elem.id, !elem.marked_as_read)}
									click={() => {
										markAsRead(elem.id, true);

										history.push(fillRoute(paths.REDIRECT, { id: elem.subject_id, type: elem.type }));
									}}
								/>
							))}

							{!!results?.length && history.location.pathname !== paths.NOTIFICATIONS && (
								<Box textAlign="center" marginTop="20px">
									<ActionButton
										translationDefault={t("NOTIFICATIONS.SHOW_ALL_BUTTON")}
										size="large"
										onClick={() => history.push(paths.NOTIFICATIONS)}
									/>
								</Box>
							)}
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default NotificationsSimple;
