import { Box } from "@material-ui/core";
import { lazy, Suspense } from "react";

import CircularLoader from "ui/loaders/CircularLoader";

const Backoffice = lazy(() => import("./index"));

const BackofficeLazyLoader = () => (
	<Suspense
		fallback={
			<Box position="relative" height="100vh" width="100vw">
				<CircularLoader />
			</Box>
		}
	>
		<Backoffice />
	</Suspense>
);

export default BackofficeLazyLoader;
