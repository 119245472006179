import { memo, useState } from "react";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import Ratings from "components/Ratings";
import { TRateResult } from "components/Ratings/types";

import ActionButton from "ui/buttons/ActionButton";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";
import Stepper from "ui/Dialog/components/Stepper";

import { icons } from "../../config";
import { isValid } from "../../shared";
import { ESteps, IRateAction } from "../../types";
import { BookingInfo } from "../BookingInfo";

const categories = {
	friendliness: "RATING.USER.CATEGORY.FRIENDLINESS",
	mannerism: "RATING.USER.CATEGORY.MANNERISM",
	mindfulness: "RATING.USER.CATEGORY.MINDFULNESS",
	appearance: "RATING.USER.CATEGORY.APPEARANCE",
	knowledgeability: "RATING.USER.CATEGORY.KNOWLEDGEABILITY",
	authenticity: "RATING.USER.CATEGORY.AUTHENTICITY",
};

type TRateResultWithCategories = TRateResult<typeof categories>;

const RateLocalhost = memo(({ action, bookingData }: IRateAction) => {
	const { t } = useTranslation();

	const { addError } = useNotification();

	const [ratingData, setRatingData] = useState<TRateResultWithCategories>();
	const [isAction, setIsAction] = useState<boolean>(false);

	const handleData = (data: TRateResultWithCategories) => {
		setRatingData(data);
	};

	const save = async () => {
		if (isValid(ratingData, categories)) {
			setIsAction(true);

			const requestData = {
				user_scores: ratingData,
			};

			await action(ESteps.LOCALHOST, requestData, ESteps.FINISH);

			setIsAction(false);

			return;
		}

		return addError(t("RATING.REQUIRED.RATES"));
	};

	return (
		<>
			<DialogContentWrapper
				title={
					<>
						{t("RATING.USER.RATE_HOST")}

						{bookingData && <BookingInfo bookingData={bookingData} />}
					</>
				}
				center
				underline
			>
				<Ratings
					icons={icons}
					categories={categories}
					name={t("RATING.USER.LOCALHOST_RATE_TITLE")}
					update={handleData}
				/>

				<Stepper step={2} steps={2}>
					<ActionButton
						onClick={() => save()}
						translationDefault="RATING.USER.LOCALHOST_NEXT_BUTTON_TITLE"
						isAction={isAction}
					/>
				</Stepper>
			</DialogContentWrapper>
		</>
	);
});

export default RateLocalhost;
