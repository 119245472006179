import { TextField, TextFieldProps } from "@material-ui/core";
import styled from "styled-components";

import useValidations from "hooks/useValidations";

import colors from "styles/colors";

interface ITextFieldBoxCustomProps {
	maxLength?: number;
	customBottom?: string;
}

export type TextFieldBoxProps = TextFieldProps & ITextFieldBoxCustomProps;

const StyledTextFieldBox = styled.div<{ width?: number }>`
	width: ${props => (props.width ? props.width + "px" : "100%")};
	position: relative;
	.MuiInputBase-input.MuiInputBase-inputMultiline {
		margin-right: 25px;
	}
	.MuiFormControl-root .MuiFormHelperText-root.Mui-error {
		line-height: 12px;
	}
`;

const StyledTextField = styled(TextField)`
	width: 100%;
`;

const StyledLengthCounter = styled.div<{ bottom?: string }>`
	position: absolute;
	right: 15px;
	font-size: 12px;
	bottom: ${props => props.bottom || "-10px"};
	color: ${colors.mainFontColor};
`;

const TextFieldBox = ({ maxLength, value, helperText, customBottom, error, ...rest }: TextFieldBoxProps) => {
	const {
		translations: { tooLong },
	} = useValidations();

	let customHelperText = helperText;
	let customError = error;

	if (maxLength && typeof value === "string" && value.length > maxLength) {
		customHelperText = tooLong(maxLength);
		customError = true;
	}

	const width = typeof rest?.style?.width === "number" ? rest.style.width : undefined;

	return (
		<StyledTextFieldBox width={width}>
			<StyledTextField helperText={customHelperText} error={customError} value={value} {...rest} />
			{maxLength && !customError && (
				<StyledLengthCounter bottom={customBottom}>
					{typeof value === "string" ? `${value.length}/${maxLength}` : ""}
				</StyledLengthCounter>
			)}
		</StyledTextFieldBox>
	);
};

export default TextFieldBox;
