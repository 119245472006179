import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Routing from "classes/Routing";

const HistoryListener = () => {
	const history = useHistory();

	useEffect(() => {
		Routing.history = history;
	}, [history]);

	return null;
};

export default HistoryListener;
