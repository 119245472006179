import colors from "styles/colors";

import { EVariantButton } from "./types";

export const styleConfig = {
	[EVariantButton.standard]: {
		fontColor: colors.mainFontColor,
		fontColorSelectedItem: colors.white,
		boxShadow: `0 0 0 1px ${colors.borderGray} inset`,
		backgroundColor: colors.white,
		backgroundColorSelectedItem: colors.sunShade,
	},
	[EVariantButton.binipool]: {
		fontColor: colors.green,
		fontColorSelectedItem: colors.white,
		boxShadow: "none",
		backgroundColor: "rgba(76, 175, 80, 0.15)",
		backgroundColorSelectedItem: colors.green,
	},
	[EVariantButton.unique]: {
		fontColor: colors.sunShade,
		fontColorSelectedItem: colors.white,
		boxShadow: "none",
		backgroundColor: "rgba(255, 167, 38, 0.15)",
		backgroundColorSelectedItem: colors.sunShade,
	},
	[EVariantButton.listed]: {
		fontColor: colors.white,
		fontColorSelectedItem: colors.white,
		boxShadow: "none",
		backgroundColor: colors.lightOrange,
		backgroundColorSelectedItem: colors.sunShade,
	},
	[EVariantButton.go]: {
		fontColor: "#00bcd4",
		fontColorSelectedItem: colors.white,
		boxShadow: "none",
		backgroundColor: "rgba(0, 188, 212, 0.15)",
		backgroundColorSelectedItem: "#00bcd4",
	},
};
