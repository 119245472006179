import { makeStyles, Tab, TabProps, Tabs as TabsUI, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { TabsProps } from "@material-ui/core/Tabs/Tabs";
import { isArray, isEmpty, isString, isUndefined } from "lodash-es";
import { rgba } from "polished";
import { HTMLAttributes, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import TabPanel from "./components/TabPanel";
import { ITabsListItem } from "./types";

const a11yProps = (index: number) => ({
	id: `scrollable-auto-tab-${index}`,
	"aria-controls": `scrollable-auto-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: "transparent",
		"& .MuiTabs-root": {
			borderBottom: "1px solid #dadada",
		},
		"& .MuiTabs-indicator": {
			height: 1,
		},
		"& .MuiTab-root": {
			padding: theme.spacing(10, 20),
			fontFamily: RobotoFontFamily,
			color: rgba(colors.darkGreen, 0.87),
			fontSize: 16,
			fontWeight: "normal",
			letterSpacing: 0.09,
			"&.Mui-selected": {
				fontWeight: 600,
				color: colors.sunShade,
			},
		},
	},
}));

interface ITabs extends TabsProps {
	tabs: ITabsListItem[];
	active?: number;
	scrollBreakpoint?: Breakpoint | number;
	tabProps?: TabProps;
	tabPanelProps?: HTMLAttributes<HTMLDivElement>;
}

const Tabs = ({ tabs, active, scrollBreakpoint = "lg", tabProps, tabPanelProps, ...rest }: ITabs) => {
	const location = useLocation();
	const history = useHistory();

	const classes = useStyles();

	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up(scrollBreakpoint));

	const [value, setValue] = useState<number>(active || -1);

	useEffect(() => {
		const foundIndex = tabs.findIndex(element =>
			Array.isArray(element.route) ? element.route.includes(location.pathname) : element.route === location.pathname,
		);

		if (foundIndex >= 0) {
			setValue(foundIndex);
			return;
		}

		if (!isUndefined(active) && active >= 0) {
			setValue(active);
			return;
		}

		setValue(0);
	}, [location, tabs, active]);

	const handleChange = (event: React.ChangeEvent, newValue: number) => {
		if (!isEmpty(tabs[newValue].route)) {
			let redirect;

			if (isArray<string>(tabs[newValue].route)) {
				redirect = tabs[newValue]?.route?.[0];
			}

			if (!redirect && isString(tabs[newValue].route)) {
				redirect = tabs[newValue].route;
			}

			history.push(redirect);
			return;
		}

		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			{value >= 0 && (
				<>
					<TabsUI
						value={value}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons={isLargeScreen ? "off" : "on"}
						aria-label="tabs"
						{...rest}
					>
						{tabs.map((tabItem, index) => (
							<Tab label={tabItem.title} key={index} {...a11yProps(index)} {...tabProps} />
						))}
					</TabsUI>

					{tabs.map((tabItem, index) => (
						<TabPanel value={value} index={index} key={`tab-panel-${index}`} {...tabPanelProps}>
							{tabItem.content}
						</TabPanel>
					))}
				</>
			)}
		</div>
	);
};

export default Tabs;
