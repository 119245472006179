import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EPromotingSections, IExperience } from "data/experiences/types";

import experiencesService from "services/api/experiences";

type TStatus = "idle" | "loading" | "succeeded" | "failed";

interface InitialState {
	data: {
		discover: IExperience[] | null;
		explore: IExperience[] | null;
	};
	status: {
		discover: TStatus;
		explore: TStatus;
	};
	error: {
		discover: any;
		explore: any;
	};
}
const initialState: InitialState = {
	data: {
		discover: null,
		explore: null,
	},
	status: {
		discover: "idle",
		explore: "idle",
	},
	error: {
		discover: null,
		explore: null,
	},
};

export const fetchExperiencesDiscover = createAsyncThunk("experiences/hp/discover/fetch", async (_, thunkAPI) => {
	try {
		const discoverList = await experiencesService.getListOfExperiences({
			exposures: EPromotingSections.DISCOVER,
			limit: 100,
		});

		return discoverList;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

export const fetchExperiencesExplore = createAsyncThunk("experiences/hp/explore/fetch", async (_, thunkAPI) => {
	try {
		const exploreList = await experiencesService.getListOfExperiences({
			exposures: EPromotingSections.EXPLORE,
			limit: 100,
		});

		return exploreList;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

const { actions, reducer } = createSlice({
	name: "homePage",
	initialState,
	reducers: {
		reset() {
			return initialState;
		},
	},
	extraReducers: {
		[fetchExperiencesDiscover.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.discover = action.payload.data;

			state.status.discover = "succeeded";
			state.error.discover = null;
		},
		[fetchExperiencesDiscover.pending.toString()]: state => {
			state.status.discover = "loading";
			state.error.discover = null;
		},
		[fetchExperiencesDiscover.rejected.toString()]: (state, action: PayloadAction<any> & { error: any }) => {
			state.status.discover = "failed";
			state.error.discover = action.error;
		},

		[fetchExperiencesExplore.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
			state.data.explore = action.payload.data;

			state.status.explore = "succeeded";
			state.error.explore = null;
		},
		[fetchExperiencesExplore.pending.toString()]: state => {
			state.status.explore = "loading";
			state.error.explore = null;
		},
		[fetchExperiencesExplore.rejected.toString()]: (state, action: PayloadAction<any> & { error: any }) => {
			state.status.explore = "failed";
			state.error.explore = action.error;
		},
	},
});

export const homepageActions = actions;

export default reducer;
