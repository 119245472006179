import TextField from "@material-ui/core/TextField";
import { CalendarToday } from "@material-ui/icons";
import { DesktopDateRangePicker, MobileDateRangePicker } from "newer-pickers";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export const StyledCalendarIcon = styled(CalendarToday)`
	width: 15px;
	height: 17px;
	color: ${colors.hokiColor};
`;

export const StyledDesktopDateRangePicker = styled(DesktopDateRangePicker)`
	box-shadow: 0 0 30px 2px rgba(206, 206, 206, 0.52);

	.MuiButtonBase-root.Mui-disabled {
		opacity: 0.5;
	}
`;

export const StyledMobileDateRangePicker = styled(MobileDateRangePicker)`
	box-shadow: 0 0 30px 2px rgba(206, 206, 206, 0.52);

	.MuiPickersToolbar-root {
		padding: 15px;
		color: white;

		.MuiPickersToolbarButton-root {
			color: white;

			.MuiPickersToolbarText-root {
				color: white;
				padding: 0 5px;

				&.Mui-selected {
					background: white;
					color: ${colors.sunShade};
				}
			}
		}
	}

	.MuiPickersMobileKeyboardInputView-root {
		padding: 15px;
	}
`;

export const StyledTextField = styled(TextField)<{ $colorOfInputBorder?: string }>`
	border-bottom: 1px solid ${props => props.$colorOfInputBorder || colors.sunShade};
	width: 100%;

	.MuiInputBase-root {
		padding: 1px 0;

		.MuiInputBase-inputAdornedStart {
			margin-left: -23px;
			padding-left: 23px;
		}
	}

	.MuiFormLabel-root {
		font-family: ${RobotoFontFamily};
		font-size: 16px;
		letter-spacing: 0.44px;
		color: ${colors.hokiColor};

		&.Mui-focused {
			color: ${colors.sunShade};
		}
	}
`;

export const InputContainer = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
	align-items: center;

	.MuiTypography-body1 {
		margin: 0;
		color: ${colors.hokiColor};
	}
`;
