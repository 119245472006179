import { Box, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import { isUndefined } from "lodash-es";
import { rgba } from "polished";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import usersService from "services/api/users";
import { getPreferredMFA } from "services/aws/auth";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import SetMFA from "components/auth/SetMFA";
import { EMFASettingsTypes, EMFAStatusTypes } from "components/auth/SetMFA/types";
import { LargeHeader } from "components/auth/shared";
import ConfirmDialog from "components/dialogs/ConfirmDialog";

import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import ChangePassword from "../components/ChangePassword";
import ChangePhoneNumber from "../components/ChangePhoneNumber";
import DeleteAccount from "../components/DeleteAccount";
import { Header2, Info } from "../shared.styled";

const MFAType = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: ${rgba(colors.darkGreen, 0.87)};
`;

const StyledBox = styled.div`
	margin-top: 10px;
	padding-top: 10px;
	display: inline-flex;
	gap: 15px;
	border-top: solid 1px #dadada;
`;

const BoxItem = styled.div`
	display: flex;
	align-items: center;
`;

const CurrentTypeItem = styled(BoxItem)`
	font-weight: 700;
	min-width: 120px;
`;

const DisableButton = styled(Button)`
	background: #212121;
	color: white;
`;

const SecuritySettings = () => {
	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);

	const [mfaDialogType, setMfaDialogType] = useState<EMFASettingsTypes>();
	const [preferredMFA, setPreferredMFA] = useState<EMFAStatusTypes>();

	const openDialog = (set: EMFASettingsTypes) => setMfaDialogType(set);
	const closeDialog = () => setMfaDialogType(undefined);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	useEffect(() => {
		(async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();

				const preferred = await getPreferredMFA(user);

				setPreferredMFA(preferred as EMFAStatusTypes);
			} catch (e) {
				handleAndNotify(e);
			}
		})();

		// eslint-disable-next-line
	}, [mfaDialogType]);

	const afterSuccess = async () => {
		try {
			const userId = userData?.id;

			if (!isUndefined(userId)) {
				await usersService.syncUserMFA(userId);
			}
		} catch (e) {
			handleAndNotify(e);
		}
	};

	return (
		<>
			<Dialog showDialog={!!mfaDialogType} handleClose={closeDialog}>
				<Box textAlign="center">
					<LargeHeader header_text={t("AUTH.MFA.HEADER")} />

					{!!mfaDialogType && <SetMFA type={mfaDialogType} back={closeDialog} success={afterSuccess} />}
				</Box>
			</Dialog>

			<Header2>{t("SETTINGS.CHANGE_YOUR_PASSWORD")}</Header2>
			<ChangePassword />

			<Header2>{t("SETTINGS.PHONE.UPDATE_PHONE_LABEL")}</Header2>
			<ChangePhoneNumber />

			{userData?.userType !== EUserType.PARTNER_AGENT && (
				<>
					<Header2>{t("SETTINGS.SECURITY")}</Header2>

					{!!preferredMFA ? (
						<>
							{preferredMFA === EMFAStatusTypes.NOMFA ? (
								<>
									<Info>{t("AUTH.MFA.DISABLED_STATUS_INFO")}</Info>

									<Button
										color="primary"
										variant="contained"
										size="large"
										onClick={() => openDialog(EMFASettingsTypes.TOTP)}
									>
										{t("AUTH.MFA.ENABLE")}
									</Button>
								</>
							) : (
								<>
									<MFAType>{t("AUTH.MFA.TYPE")}</MFAType>

									<StyledBox>
										<CurrentTypeItem>{t("AUTH.MFA.CURRENT." + preferredMFA.toString())}</CurrentTypeItem>

										<BoxItem>
											<ConfirmDialog
												onConfirm={() => openDialog(EMFASettingsTypes.NOMFA)}
												title={t("AUTH.MFA.CONFIRM_DISABLE_TITLE")}
												message={t("AUTH.MFA.CONFIRM_DISABLE_MESSAGE")}
											>
												<DisableButton variant="contained">{t("AUTH.MFA.DISABLE")}</DisableButton>
											</ConfirmDialog>
										</BoxItem>
									</StyledBox>
								</>
							)}
						</>
					) : (
						<CircularProgress color="primary" size={25} value={100} />
					)}
				</>
			)}

			{!partnerType && (
				<>
					<Header2>{t("SETTINGS.DELETE_ACCOUNT.TITLE")}</Header2>

					<DeleteAccount />
				</>
			)}
		</>
	);
};

export default SecuritySettings;
