import { Box } from "@material-ui/core";
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";

import { getSharedUrl } from "./utils";

export interface IShare {
	config?: {
		facebook?: boolean;
		linkedin?: boolean;
		twitter?: boolean;
		whatsapp?: boolean;
		telegram?: boolean;
	};
	title?: string;
	description?: string;
	shareUrl?: string;
	addHostName?: boolean;
	size?: number;
}

const shareDefault = {
	facebook: true,
	linkedin: true,
	twitter: true,
	whatsapp: true,
	telegram: true,
};

const Share = ({ config = shareDefault, shareUrl, title, description, size = 35, addHostName = false }: IShare) => {
	const settings = { ...shareDefault, ...config };
	const url = getSharedUrl(shareUrl, true, addHostName);

	return (
		<Box display="flex" gridGap={25}>
			{settings.facebook && (
				<FacebookShareButton url={url} quote={title}>
					<FacebookIcon size={size} round />
				</FacebookShareButton>
			)}

			{settings.linkedin && (
				<LinkedinShareButton url={url} source={url} title={title} summary={description}>
					<LinkedinIcon size={size} round />
				</LinkedinShareButton>
			)}

			{settings.twitter && (
				<TwitterShareButton url={url} title={title}>
					<TwitterIcon size={size} round />
				</TwitterShareButton>
			)}

			{settings.whatsapp && (
				<WhatsappShareButton url={url} title={title}>
					<WhatsappIcon size={size} round />
				</WhatsappShareButton>
			)}

			{settings.telegram && (
				<TelegramShareButton url={url} title={title}>
					<TelegramIcon size={size} round />
				</TelegramShareButton>
			)}
		</Box>
	);
};

export default Share;
