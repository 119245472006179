import { ReactElement, useContext } from "react";

import { NotificationProviderContext } from "ui/Notification/components/NotificationProvider";

import useTranslation from "./useTranslation";

function useNotification() {
	const { t } = useTranslation();

	const { notifications, add, remove, clear, total } = useContext(NotificationProviderContext);

	const resolveMessage = (defaultMessage: string, message?: string | ReactElement, translate = false) => {
		if (!message) {
			return t(defaultMessage);
		}

		if (translate) {
			return t(message as string);
		}

		return message;
	};

	const addError = (message?: string | ReactElement, translate = false) =>
		add(resolveMessage("NOTIFICATION.UNDEFINED_ERROR", message, translate), "error");

	const addSuccess = (message?: string | ReactElement, translate = false) =>
		add(resolveMessage("NOTIFICATION.UNDEFINED_SUCCESS", message, translate), "success");

	const addInfo = (message?: string | ReactElement, translate = false) =>
		add(resolveMessage("NOTIFICATION.UNDEFINED_INFO", message, translate), "info");

	return {
		notifications,
		total,
		add,
		remove,
		clear,
		addError,
		addSuccess,
		addInfo,
		success: () => addSuccess("NOTIFICATION.ACTION_SUCCESS", true),
		deleted: () => addSuccess("NOTIFICATION.SUCCESSFUL_DELETED", true),
		created: () => addSuccess("NOTIFICATION.SUCCESSFUL_CREATED", true),
		uploaded: () => addSuccess("NOTIFICATION.FILE_UPLOADED", true),
		updated: () => addSuccess("NOTIFICATION.SUCCESSFUL_UPDATED", true),
		removed: () => addSuccess("NOTIFICATION.FILE_REMOVED", true),
		invalidParameters: () => addError("NOTIFICATION.INVALID_PARAMETERS", true),
	};
}

export default useNotification;
