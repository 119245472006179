import {
	Box,
	createStyles,
	Grid,
	InputAdornment,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { TCurrency } from "data/backoffice/financials/types";
import { currencySymbolsDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import { getEarnings } from "utils/prices";

import BarChartVertical from "ui/BarChartVertical";
import SwitcherYesNo from "ui/forms/SwitcherYesNo";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

export interface IPrivatePriceProps {
	onChange: (v: number | null) => void;
	value: number | null;
	chartValues?: { [key: string]: number };
	customFormat?: boolean;
	currency?: TCurrency;
}

const SubTitle = styled.h2`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	font-weight: 500;
	line-height: 1.6;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};
`;

const ItemContent = styled.div<{ disableHeight?: boolean; chart?: boolean }>`
	${props => !props.disableHeight && "height: 350px;"}
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;

	${props =>
		props.chart &&
		css`
			padding-left: 180px;
			overflow-x: auto;
		`}
`;

const StyledTableContainer = styled(TableContainer)`
	width: 70%;
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		headerCell: {
			backgroundColor: theme.palette.background.secondary,
			fontFamily: "Roboto",
			width: "120px",
		},
	}),
);

const PrivatePrice = ({ onChange, value, chartValues, customFormat, currency }: IPrivatePriceProps) => {
	const [privatePrice, setPrivatePrice] = useState<number>(value || 1);
	const [showPrivatePricing, setShowPrivatePricing] = useState<boolean>(!(value === null));

	const { t } = useTranslation();
	const classes = useStyles();

	useEffect(() => {
		setPrivatePrice(value || 1);
		setShowPrivatePricing(!(value === null));
	}, [value]);

	const handleYesNo = (yesNoValue: boolean) => {
		setShowPrivatePricing(yesNoValue);
		if (yesNoValue) {
			setPrivatePrice(1);
			onChange(1);
		} else {
			setShowPrivatePricing(false);
			onChange(null);
		}
	};

	const handleChange = event => {
		const newValue = parseInt(event.target.value, 10);
		setPrivatePrice(newValue);
		onChange(newValue);
	};

	const currencySymbol = !!currency ? currencySymbolsDictionary[currency] : "";
	return (
		<Box display="flex" flexDirection="column">
			<SwitcherYesNo onChangeYesNo={handleYesNo} value={showPrivatePricing} />

			{showPrivatePricing && (
				<>
					<Grid container spacing={10} style={{ paddingTop: "50px" }}>
						<Grid item xs={12} md={4}>
							{!customFormat && <SubTitle>{t("EXPERIENCE.FORM.PRICING_PRIVATE.MIN_PRICE")}</SubTitle>}

							<ItemContent>
								<StyledTableContainer>
									<Table aria-label="private price table">
										<TableBody>
											<TableRow key={1}>
												<TableCell className={classes.headerCell}>
													{t("EXPERIENCE.FORM.PRICING_PRIVATE.PRICE")}
												</TableCell>

												<TableCell align="left" width="195px">
													<TextField
														placeholder={t("EXPERIENCE.FORM.PRICING_PRIVATE.PRICE")}
														value={privatePrice}
														type="number"
														onChange={handleChange}
														inputProps={{ min: 1 }}
														InputProps={{
															startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
														}}
													/>
												</TableCell>
											</TableRow>

											<TableRow key={2}>
												<TableCell className={classes.headerCell}>
													{t("EXPERIENCE.FORM.PRICING_PRIVATE.YOUR_EARNINGS")}
												</TableCell>

												<TableCell align="left">
													<Typography>{`${currencySymbol} ${privatePrice ? getEarnings(privatePrice) : 0}`}</Typography>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</StyledTableContainer>
							</ItemContent>
						</Grid>

						{chartValues && !!getEarnings(privatePrice) && (
							<Grid item xs={12} md={8}>
								{!customFormat && <SubTitle>{t("EXPERIENCE.FIELDS.pricing.your_potential_earnings.TITLE")}</SubTitle>}

								<ItemContent chart>
									<BarChartVertical
										currency={currency}
										value={chartValues}
										minEarning={getEarnings(privatePrice) || 0}
									/>
								</ItemContent>
							</Grid>
						)}
					</Grid>
				</>
			)}
		</Box>
	);
};

export default PrivatePrice;
