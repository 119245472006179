import { Clear, DoneAll, EditOutlined, HourglassEmptyOutlined, ReportOutlined, Visibility } from "@material-ui/icons";
import { memo } from "react";

import { EExperienceStatus } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

import { buttonAdditionalStyles, StatusLabelBox } from "../common";

interface IStatusBtnProps {
	type?: EExperienceStatus;
}

const ExperienceStatusLabel = memo(({ type }: IStatusBtnProps) => {
	const { t } = useTranslation();

	const getLabel = (name: EExperienceStatus) => {
		let icon;
		let label;
		let color;

		switch (name) {
			case EExperienceStatus.AWAITING_HOSTS:
				label = t("EXPERIENCE.STATUS.AWAITING_HOSTS");
				icon = <HourglassEmptyOutlined style={buttonAdditionalStyles} />;
				color = "#2196f3";
				break;

			case EExperienceStatus.PUBLISHED:
				label = t("EXPERIENCE.STATUS.PUBLISHED");
				icon = <DoneAll style={buttonAdditionalStyles} />;
				color = colors.green;
				break;

			case EExperienceStatus.DRAFT:
				label = t("EXPERIENCE.STATUS.DRAFT");
				icon = <EditOutlined style={buttonAdditionalStyles} />;
				color = colors.middleGray;
				break;

			case EExperienceStatus.AWAITING_REVIEW:
				label = t("EXPERIENCE.STATUS.PENDING");
				icon = <HourglassEmptyOutlined style={buttonAdditionalStyles} />;
				color = "#2196f3";
				break;

			case EExperienceStatus.UNDER_REVIEW:
				label = t("EXPERIENCE.STATUS.IN_REVIEW");
				icon = <Visibility style={buttonAdditionalStyles} />;
				color = colors.sunShade;
				break;

			case EExperienceStatus.NEEDS_CHANGES:
				label = t("EXPERIENCE.STATUS.NEEDS_CHANGES");
				icon = <ReportOutlined style={buttonAdditionalStyles} />;
				color = colors.red;
				break;

			case EExperienceStatus.REJECTED:
				label = t("EXPERIENCE.STATUS.REJECTED");
				icon = <ReportOutlined style={buttonAdditionalStyles} />;
				color = colors.red;
				break;

			case EExperienceStatus.RETIRED:
				label = t("EXPERIENCE.STATUS.RETIRED");
				icon = <Clear style={buttonAdditionalStyles} />;
				color = colors.oceanDrive;
				break;

			default:
				icon = "";
				label = "";
				color = "";
		}

		return {
			icon,
			label,
			color,
		};
	};

	return <StatusLabelBox getLabel={getLabel} type={type || ""} />;
});

export default ExperienceStatusLabel;
