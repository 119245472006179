import { Box, Button, Divider } from "@material-ui/core";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { getParams } from "utils/urls";

import ActionButton from "ui/buttons/ActionButton";

interface IStepper {
	backStep?: number;
	nextStep?: number;
	nextText: string;
	backText: string;
	onNextStepClick?: () => void;
	onPrevStepClick?: () => void;
	customNext?: () => void;
	isNextDisabled?: boolean;
	isAction?: boolean;
}

const Stepper = ({
	backStep,
	nextStep,
	backText,
	nextText,
	onNextStepClick,
	onPrevStepClick,
	customNext,
	isNextDisabled = false,
	isAction,
}: IStepper) => {
	const history = useHistory();
	const match = useRouteMatch();

	const location = useLocation();
	const initialQueryParams: any = getParams(new URLSearchParams(location.search));

	const currentStep = initialQueryParams?.step ? parseInt(initialQueryParams?.step, 10) : 0;

	return (
		<>
			<Divider />

			<Box display="flex" alignItems="center" justifyContent="space-between" flex={1} marginTop="10px">
				<Button
					variant="outlined"
					onClick={() => {
						if (onPrevStepClick) {
							onPrevStepClick();
						}

						history.push(`${match.url}?step=${backStep ? backStep : currentStep - 1}`);
					}}
				>
					{backText}
				</Button>

				<ActionButton
					translationDefault={nextText}
					type="button"
					isDisabled={isNextDisabled}
					isAction={isAction}
					onClick={() => {
						if (!!customNext) {
							customNext();
							return;
						}

						if (onNextStepClick) {
							onNextStepClick();
						}

						history.push(`${match.url}?step=${nextStep ? nextStep : currentStep + 1}`);
					}}
				/>
			</Box>
		</>
	);
};

export default Stepper;
