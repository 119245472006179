import { Box, CircularProgress } from "@material-ui/core";
import { MouseEvent } from "react";
import ReactCodeInput from "react-code-input";

import useTranslation from "hooks/useTranslation";

import {
	Alignment,
	Desc,
	MainContainer,
	MainContent,
	StepContainer,
	TitleHeader,
} from "pages/onboarding/shared.styled";

import AlertMessage, { AlertMessageProps } from "ui/AlertMessage";
import StepBar from "ui/StepBar";

import { codeInputStyle } from "styles/common";

import { stepsLength } from "../../../../config";
import { InputsContainer, StyledLink } from "./styled";

interface EnterVerificationCodeLayoutProps extends AlertMessageProps {
	fullPhoneNumber: string;
	isResend: boolean;
	isSpinnerOpen: boolean;
	verifiedCode: string;
	handleClickChangeNumber(event: MouseEvent): void;
	handleCodeInputChange(code: string): void;
	handleClickResendSMS(event: MouseEvent): void;
}

const EnterVerificationCodeLayout = ({
	isResend,
	isSpinnerOpen,
	fullPhoneNumber,
	verifiedCode,
	handleCodeInputChange,
	handleClickChangeNumber,
	handleClickResendSMS,
	handleCloseAlert,
	openAlert,
}: EnterVerificationCodeLayoutProps) => {
	const { t } = useTranslation();

	return (
		<MainContainer>
			<Alignment>
				<MainContent>
					<TitleHeader>{t("ONBOARDING.PHONE_VERIFY.STEP_2.HEADER")}</TitleHeader>

					<StepContainer>
						<StepBar stepsAmount={stepsLength} />
					</StepContainer>

					<InputsContainer>
						<Desc>{t("ONBOARDING.PHONE_VERIFY.STEP_2.DESC")}</Desc>

						<Box textAlign="center" mb={-18} minWidth="300px">
							{!isSpinnerOpen ? (
								<ReactCodeInput
									fields={6}
									inputMode="numeric"
									inputStyle={codeInputStyle}
									type="text"
									name="code"
									pattern={`[0-9]+`}
									onChange={handleCodeInputChange}
									value={verifiedCode}
								/>
							) : (
								<CircularProgress color="secondary" />
							)}
						</Box>
					</InputsContainer>

					<Desc>
						{`${t("ONBOARDING.PHONE_VERIFY.STEP_2.PHONE")} `}
						{fullPhoneNumber}&nbsp;
						<StyledLink
							id="enter_verification_code_change_number_link"
							color="textPrimary"
							underline="always"
							onClick={handleClickChangeNumber}
						>
							{t("ONBOARDING.PHONE_VERIFY.STEP_2.PHONE_CHANGE_BUTTON")}
						</StyledLink>
					</Desc>

					<Desc>
						<StyledLink
							id="enter_verification_code_resend_sms_link"
							color="textPrimary"
							underline="always"
							onClick={handleClickResendSMS}
						>
							{t("ONBOARDING.PHONE_VERIFY.STEP_2.SMS_RESEND_BUTTON")}
						</StyledLink>
					</Desc>
				</MainContent>
			</Alignment>

			{!isResend && (
				<AlertMessage openAlert={openAlert} handleCloseAlert={handleCloseAlert} severity="error">
					{t("ONBOARDING.PHONE_VERIFY.STEP_2.ALERT_ERROR")}
				</AlertMessage>
			)}

			{isResend && (
				<AlertMessage openAlert={openAlert} handleCloseAlert={handleCloseAlert} severity="success">
					{t("ONBOARDING.PHONE_VERIFY.STEP_2.ALERT_CORRECT")}
				</AlertMessage>
			)}
		</MainContainer>
	);
};

export default EnterVerificationCodeLayout;
