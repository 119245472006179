import { Avatar, Box } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";

import RatingStars from "ui/RatingStars";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const StyledAvatar = styled(Avatar)`
	width: 56px;
	height: 56px;
	border: 2px solid ${colors.white};
`;

const NameLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};
`;

const DateLabel = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	line-height: 1.58;
	letter-spacing: 0.3px;
	color: ${colors.mainFontColor};
`;

const Comment = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.1px;
	color: ${colors.mainFontColor};
`;

interface IReview {
	dateValue: string;
	value: number;
	avatarImg?: string;
	descriptionValue?: string | null;
	nameValue?: string | null;
}

const Review = ({ dateValue, value, avatarImg, descriptionValue, nameValue }: IReview) => {
	const { t } = useTranslation();

	const displayName = nameValue || t("RATINGS.EMPTY_TRAVELER_USER_NAME");

	return (
		<Box pb={30}>
			<Box display="flex">
				<StyledAvatar alt={displayName} src={avatarImg} />

				<Box ml={12} display="flex" justifyContent="center" flexDirection="column">
					<NameLabel>{displayName}</NameLabel>

					<RatingStars value={value} size="small" withoutModal />

					<DateLabel>{parseISOWrapper(dateValue, DATE_FORMATS.DATE_FORMAT5)}</DateLabel>
				</Box>
			</Box>

			{descriptionValue && descriptionValue.length > 0 && (
				<Box mt={8}>
					<Comment>{descriptionValue}</Comment>
				</Box>
			)}
		</Box>
	);
};

export default Review;
