import { Box } from "@material-ui/core";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import slugify from "slugify";

import { IAggregationBySuperbooking } from "data/backoffice/financials/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import financialsServices from "services/api/financials";

import { DATE_FORMATS, getDateWithDefaultTime, parseISOWrapper } from "utils/dates";
import { prices } from "utils/prices";
import fillRoute from "utils/routes";

import ElemClipboard from "components/backoffice/ElemClipboard";
import FilterSearchPastEarnings, { ISelectedFilters } from "components/finances/FilterSearchPastEarnings";

import ChildrenLoader from "ui/loaders/ChildrenLoader";
import CircularLoader from "ui/loaders/CircularLoader";
import Table, { Order } from "ui/Table";

import { StyledLink } from "styles/common";

import { columns, fields, idForContainer, listItemsOffset, sortedColumns } from "./config";

interface IQueryParams {
	min_created_on?: string;
	max_created_on?: string;
	superbooking_id?: string;
}

const PastEarnings = () => {
	const { translateArray } = useTranslation();

	const { handleAndNotify } = useErrors();

	const [results, setResults] = useState<{ data: IAggregationBySuperbooking[]; count: number }>();
	const [page, setPage] = useState<number>(1);
	const [loadMore, setLoadMore] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const [orderData, setOrderData] = useState<{ sort: string; order: Order; orderBy: number }>();
	const [queryParams, setQueryParams] = useState<IQueryParams>();

	const preventLoading = useRef<boolean>(false);

	useEffect(() => {
		const mainContainerNode = document.querySelector(`#${idForContainer}`);

		const handleScroll = throttle(() => {
			if (mainContainerNode) {
				if (document.documentElement.scrollTop >= mainContainerNode.scrollHeight - 600) {
					if (!preventLoading.current) {
						setLoadMore(true);

						preventLoading.current = true;
					}
				}
			}
		}, 1000);

		document.addEventListener("scroll", handleScroll);

		return () => {
			document.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, []);

	const fetchPastEarnings = useCallback(
		async (data?: object, reset?: boolean) => {
			try {
				const pastEarnings = await financialsServices.getAggregationBySuperbooking({
					...(orderData && !reset && { sort: orderData.sort, order: orderData.order }),
					...(!reset && queryParams),
					...data,
				});

				setResults(prevState => ({
					count: pastEarnings.count,
					data: !!prevState?.data ? [...prevState.data, ...pastEarnings.data] : pastEarnings.data,
				}));
			} catch (e) {
				handleAndNotify(e);
			} finally {
				preventLoading.current = false;
				setLoadMore(false);
			}
		},

		// eslint-disable-next-line
		[orderData, queryParams],
	);

	useEffect(() => {
		fetchPastEarnings();

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const loadMoreData = async () => {
			setLoader(false);

			if (loadMore) {
				if (page * listItemsOffset <= (results?.count || 0)) {
					setLoader(true);

					await fetchPastEarnings({ offset: page * listItemsOffset });

					setPage(prevState => prevState + 1);
					setLoadMore(false);
				}
			}
		};

		loadMoreData();

		// eslint-disable-next-line
	}, [loadMore]);

	const sortData = (nameField: string, order: Order, orderBy: number) => {
		setPage(1);
		setResults(undefined);

		setOrderData({ sort: nameField, order, orderBy });
		fetchPastEarnings({ sort: nameField, order });
	};

	const getFilters = (filters: ISelectedFilters) => {
		const queryParamsFromFilters: IQueryParams = {};

		Object.entries(filters).forEach(([filterType, filterValue]) => {
			if (!filterValue) {
				return;
			}

			if (filterType === "dates") {
				if (filterValue.start) {
					queryParamsFromFilters.min_created_on = getDateWithDefaultTime(filterValue.start, "FROM");
				}
				if (filterValue.end) {
					queryParamsFromFilters.max_created_on = getDateWithDefaultTime(filterValue.end, "TO");
				}
			} else if (filterType === "booking_id") {
				queryParamsFromFilters.superbooking_id = filterValue.replace(/\s/g, "");
			}
		});

		setOrderData(undefined);
		setPage(1);
		setResults(undefined);

		setQueryParams(queryParamsFromFilters);
		fetchPastEarnings(queryParamsFromFilters, true);
	};

	const parsedData =
		results?.data?.map(elem => ({
			...elem,
			superbooking_id: <ElemClipboard elem={elem.superbooking_id} splitPart={36} />,
			"superbooking.exp_title": (
				<StyledLink
					to={fillRoute(paths.SEARCH_EXPERIENCES, {
						title: slugify(elem?.superbooking?.exp_title?.toLowerCase() || ""),
						id: elem?.superbooking?.eid || "",
					})}
				>
					{elem?.superbooking?.exp_title}
				</StyledLink>
			),
			created_on: `${parseISOWrapper(elem.created_on, DATE_FORMATS.DATETIME_AMPM_FORMAT)} UTC`,
			localhost_earnings: <strong>{prices(elem.localhost_earnings, elem.currency)}</strong>,
			localhost_reimbursement: !!elem.localhost_reimbursement && (
				<strong>{prices(elem.localhost_reimbursement, elem.currency)}</strong>
			),
		})) || [];

	return (
		<>
			<Box marginBottom="20px">
				<FilterSearchPastEarnings applySelectedFiltersAction={getFilters} />
			</Box>

			<Box id={idForContainer} style={{ overflowX: "auto" }}>
				<ChildrenLoader check={results}>
					<Table
						data={parsedData}
						columns={translateArray(columns)}
						fields={fields}
						sortedColumns={translateArray(sortedColumns)}
						externalSort={sortData}
						initialOrder={orderData?.order}
						initialOrderBy={orderData?.orderBy}
						totalRecords={results?.count}
					/>
				</ChildrenLoader>
			</Box>

			{loader && (
				<Box marginTop="50px">
					<CircularLoader absolute={false} />
				</Box>
			)}
		</>
	);
};

export default PastEarnings;
