import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete as MaterialAutocomplete, createFilterOptions } from "@material-ui/lab";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import { isString } from "lodash-es";
import { ReactNode } from "react";

import useTranslation from "hooks/useTranslation";

import Checkbox from "ui/forms/Checkbox";

import { StartAdornment, StyledAutocompleteTextField } from "./styled";
import { TAutocompleteTyped } from "./types";
import { mapValueToOptionsValue } from "./utils";

export type Option = {
	value: string | number;
	label: ReactNode;
};

export interface IAutocompleteUI {
	name: string;
	onChange: (v: Option | Option[] | null) => void;
	label?: ReactNode;
	placeholder?: string;
	variant?: TextFieldProps["variant"];
	textFieldProps?: TextFieldProps;
	inputProps?: AutocompleteRenderInputParams["inputProps"];
	options: Option[];
	filterSelectedOptions?: boolean;
	multiple?: boolean;
	freeSolo?: boolean;
	error?: boolean;
	value?: unknown | unknown[];
	id?: string;
	disableClearable?: boolean;
	disabled?: boolean;
	disableCloseOnSelect?: boolean;
	limitTags?: number;
	size?: "small" | "medium";
	withCheckboxes?: boolean;
}

export const AutocompleteTyped = ({
	startAdornment,
	disableHtmlAutocomplete,
	inputProps,
	required,
	...props
}: TAutocompleteTyped<Option, boolean, boolean, boolean>) => (
	<AutocompleteUI
		textFieldProps={{
			InputProps: { startAdornment },
			required,
		}}
		inputProps={{
			...inputProps,
			autoComplete: disableHtmlAutocomplete ? "off" : undefined,
			role: "presentation",
		}}
		{...props}
	/>
);

const filter = createFilterOptions<Option>();

const AutocompleteUI = ({
	name,
	label,
	placeholder,
	options,
	textFieldProps,
	inputProps,
	onChange,
	value,
	variant = "outlined",
	id,
	error,
	withCheckboxes,
	...rest
}: IAutocompleteUI) => {
	const { withValuesAsString } = useTranslation("ui");

	const change = (_, newValue: any) => {
		if (isString(newValue)) {
			return onChange(options?.find(o => o.value === newValue) || null);
		}

		if (newValue && newValue.inputValue) {
			return onChange({ label: newValue.inputValue, value: newValue.inputValue });
		}

		return onChange(newValue);
	};

	const localValue = mapValueToOptionsValue(value, options, {
		multiple: rest?.multiple,
		freeSolo: rest?.freeSolo,
	});

	return (
		<MaterialAutocomplete
			value={localValue}
			id={id || "autocomplete_input"}
			options={options}
			getOptionLabel={option => {
				if (typeof option === "string") {
					return option;
				}

				if (option.inputValue && rest?.freeSolo) {
					return option.inputValue;
				}

				return option?.label;
			}}
			filterOptions={(opts, params) => {
				const filtered = filter(opts, params) as Option[];

				if (params.inputValue !== "" && rest?.freeSolo) {
					filtered.push({
						value: params.inputValue,
						label: withValuesAsString("AUTOCOMPLETE.CONFIRM_FREE_SOLO", { value: params.inputValue }),
					});
				}

				return filtered;
			}}
			renderOption={(option, { selected }) => (
				<>
					{withCheckboxes && (
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
							checkedIcon={<CheckBoxIcon fontSize="small" />}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
					)}

					{option.label}
				</>
			)}
			onChange={change}
			renderInput={params => (
				<StyledAutocompleteTextField
					{...params}
					label={label}
					error={error}
					variant={variant}
					name={name}
					onBlur={_ => change(_, localValue)}
					placeholder={placeholder}
					{...textFieldProps}
					inputProps={{
						...params.inputProps,
						...inputProps,
					}}
					InputProps={{
						...textFieldProps?.InputProps,
						...params.InputProps,
						endAdornment: params.InputProps.endAdornment,
						startAdornment: (
							<StartAdornment>
								{textFieldProps?.InputProps?.startAdornment}
								{params.InputProps.startAdornment}
							</StartAdornment>
						),
					}}
				/>
			)}
			{...rest}
		/>
	);
};

export default AutocompleteUI;
