import { useEffect, useState } from "react";

export interface IViewPortDimension {
	width?: Window["innerWidth"];
	height?: Window["innerHeight"];
}

function useViewPortDimension() {
	const defaultDimensions: IViewPortDimension = {
		width: undefined,
		height: undefined,
	};

	const [dimensions, setDimensions] = useState(defaultDimensions);

	const updateWindowDimensions = () => {
		setDimensions({ width: window.innerWidth, height: window.innerHeight });
	};

	useEffect(() => {
		updateWindowDimensions();

		window.addEventListener("resize", updateWindowDimensions);

		return () => {
			window.removeEventListener("resize", updateWindowDimensions);
		};
	}, []);

	return dimensions;
}

export default useViewPortDimension;
