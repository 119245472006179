import { Grid } from "@material-ui/core";
import { rgba } from "polished";
import { ReactNode } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const StepperGridContainer = styled(Grid)`
	padding-top: 15px;
	border-top: 1px solid #eee;
`;

const StepInfo = styled(Grid)`
	font-size: 14px;
	font-family: ${RobotoFontFamily};
	letter-spacing: 0.3px;
	color: ${rgba(colors.mainFontColor, 0.6)};
	text-align: left;
`;

interface IStepper {
	children: ReactNode;
	step?: number;
	steps?: number;
}

const Stepper = ({ children, step, steps }: IStepper) => {
	const withStep = !!step && !!steps;
	const { withValues } = useTranslation();

	return (
		<StepperGridContainer container alignItems="center">
			<Grid item xs={6}>
				{withStep && <StepInfo>{withValues("RATING.STEP", { current: step, total: steps })}</StepInfo>}
			</Grid>

			<Grid item xs={withStep ? 6 : 12} container justify={withStep ? "flex-end" : "center"}>
				{children}
			</Grid>
		</StepperGridContainer>
	);
};

export default Stepper;
