import { Button } from "@material-ui/core";
import { memo, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import BecomeLocaleAmbassadorImg from "assets/images/binipoolConcept/become_local_ambasador.svg";
import FairCompenansionImg from "assets/images/binipoolConcept/fair_compensation.svg";
import GrowingCommunityImg from "assets/images/binipoolConcept/growing_community.svg";
import WorkImg from "assets/images/binipoolConcept/work.svg";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import CommonParagraph from "ui/CommonParagraph";

import { Container, RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

interface ITileProps {
	picture: ReactNode;
	title: string;
	description: string;
	isAdditionalPadding?: boolean;
}

const MainSection = styled.div`
	display: flex;
	flex-direction: row;

	${media.desktop`
      width: 100%;
      flex-direction: column;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const StyledMainImg = styled.img`
	margin: 0;
	width: 575px;
	height: 575px;

	${media.desktop`
      margin: 3.125rem auto;
      width: 100%;
      height: 100%;
  `};
`;

const RegisterBtnContent = styled.div`
	margin-top: 1.25rem;
	width: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const RegisterBtn = styled(Button)`
	height: 48px;
	width: 100%;
`;

const ItsFreeLabel = styled.div`
	margin-top: 0.625rem;
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.large};
	font-weight: 500;
	line-height: 1.57;
	letter-spacing: 0.32px;
`;

const Background = styled.div`
	width: 100%;
	background-image: linear-gradient(178deg, rgba(255, 244, 229, 0) 7%, rgba(255, 229, 210, 0.63) 93%);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const PicturesWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;

	${media.desktop`
    justify-content: center;
    flex-direction: column;
  `};
`;

const TileWrapper = styled.div`
	width: 100%;
	margin-top: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
`;

const TileImgContent = styled.div<{ isAdditionalPadding?: boolean }>`
	padding-bottom: ${props => (props.isAdditionalPadding ? "45px" : "0")};
	height: 17.563rem;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`;

const TileTextContent = styled.div`
	height: 200px;
	max-width: 366px;
`;

const TileTitle = styled.h3`
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.7;
	letter-spacing: 0.04px;
	text-align: center;
`;

const TileDesc = styled.div`
	margin: 0;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.86;
	letter-spacing: 0.03px;
	text-align: center;
`;

const FirstTileImg = styled.img`
	width: 366px;
	height: 281px;

	${media.desktop`
      width: 100%;
      height: 100%;
  `};
`;

const SecondTileImg = styled.img`
	width: 278px;
	height: 211px;

	${media.desktop`
      width: 100%;
      height: 100%;
  `};
`;

const ThirdTileImg = styled.img`
	width: 347px;
	height: 181px;

	${media.desktop`
      width: 100%;
      height: 100%;
  `};
`;

const Tile = memo(({ picture, title, description, isAdditionalPadding = false }: ITileProps) => (
	<TileWrapper>
		<TileImgContent isAdditionalPadding={isAdditionalPadding}>{picture}</TileImgContent>

		<TileTextContent>
			<TileTitle>{title}</TileTitle>
			<TileDesc>{description}</TileDesc>
		</TileTextContent>
	</TileWrapper>
));

const Header = memo(() => {
	const { t, withRaw } = useTranslation();

	const userData = useAppSelector(selectUser);

	return (
		<>
			<Helmet>
				<title>{t("BINIPOOL_CONCEPT.HEADER_MAIN_TITLE")} | LocalBini</title>
				<meta property="og:title" content={t("BINIPOOL_CONCEPT.HEADER_MAIN_TITLE") + "| LocalBini"} />
				<meta name="description" content={t("BINIPOOL_CONCEPT.HEADER_MAIN_DESC")} />
				<meta property="og:description" content={t("BINIPOOL_CONCEPT.HEADER_MAIN_DESC")} />
			</Helmet>

			<Container>
				<MainSection>
					<Col>
						<CommonParagraph
							withMarginTop
							mainTitle={t("BINIPOOL_CONCEPT.HEADER_MAIN_TITLE")}
							subTitle={t("BINIPOOL_CONCEPT.HEADER_MAIN_SUBTITLE")}
							description={withRaw("BINIPOOL_CONCEPT.HEADER_MAIN_DESC", null)}
						/>

						{!([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
							userData?.userType,
						) && (
							<RegisterBtnContent>
								<StyledLink
									to={fillRoute(paths.ONBOARDING_REGISTER, { search: { type: "binipool" } })}
									$withoutHoverUnderline
								>
									<RegisterBtn color="primary" variant="contained">
										{t("BINIPOOL_CONCEPT.HEADER_REGISTER_NOW")}
									</RegisterBtn>
								</StyledLink>

								<ItsFreeLabel>{t("BINIPOOL_CONCEPT.HEADER_ITS_FREE")}</ItsFreeLabel>
							</RegisterBtnContent>
						)}
					</Col>

					<Col>
						<StyledMainImg
							src={BecomeLocaleAmbassadorImg}
							title={t("BINIPOOL_CONCEPT.HEADER_MAIN_TITLE")}
							alt={t("BINIPOOL_CONCEPT.HEADER_MAIN_TITLE")}
						/>
					</Col>
				</MainSection>
			</Container>

			<Background>
				<Container>
					<PicturesWrapper>
						<Tile
							picture={
								<FirstTileImg
									src={WorkImg}
									title={t("BINIPOOL_CONCEPT.HEADER_TILE_1_TITLE")}
									alt={t("BINIPOOL_CONCEPT.HEADER_TILE_1_TITLE")}
								/>
							}
							title={t("BINIPOOL_CONCEPT.HEADER_TILE_1_TITLE")}
							description={t("BINIPOOL_CONCEPT.HEADER_TILE_1_DESC")}
						/>

						<Tile
							isAdditionalPadding
							picture={
								<SecondTileImg
									src={FairCompenansionImg}
									title={t("BINIPOOL_CONCEPT.HEADER_TILE_2_TITLE")}
									alt={t("BINIPOOL_CONCEPT.HEADER_TILE_2_TITLE")}
								/>
							}
							title={t("BINIPOOL_CONCEPT.HEADER_TILE_2_TITLE")}
							description={t("BINIPOOL_CONCEPT.HEADER_TILE_2_DESC")}
						/>

						<Tile
							isAdditionalPadding
							picture={
								<ThirdTileImg
									src={GrowingCommunityImg}
									title={t("BINIPOOL_CONCEPT.HEADER_TILE_3_TITLE")}
									alt={t("BINIPOOL_CONCEPT.HEADER_TILE_3_TITLE")}
								/>
							}
							title={t("BINIPOOL_CONCEPT.HEADER_TILE_3_TITLE")}
							description={t("BINIPOOL_CONCEPT.HEADER_TILE_3_DESC")}
						/>
					</PicturesWrapper>
				</Container>
			</Background>
		</>
	);
});

export default Header;
