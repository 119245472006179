import { TableCell } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.background.secondary,
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell);

export const StyledHelperText = styled.div`
	font-size: 12px;
	font-weight: bold;
	font-style: italic;
`;
