import { Box, useTheme } from "@material-ui/core";
import { ErrorOutline, Lens } from "@material-ui/icons";

import useTranslation from "hooks/useTranslation";

import { SubTitleSectionV2 } from "styles/common";

import ChipStringArray from "../../../ChipStringArray";
import IconStringArray from "../../../IconStringArray";
import { TitleSection } from "../../index";

interface IImportantInfoProps {
	difficultyLevel: string;
	excludedArray?: string[];
	healthReastrictionsArray?: string[];
	requirementsArray?: string[];
	weatherSensitive?: string[];
}

const ImportantInfo = ({
	difficultyLevel,
	excludedArray,
	requirementsArray,
	healthReastrictionsArray,
	weatherSensitive,
}: IImportantInfoProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const difficultyLevelStr =
		t(`EXPERIENCE.FORM.STRING_RADIO.${difficultyLevel}.TITLE`) +
		" - " +
		t(`EXPERIENCE.FORM.STRING_RADIO.${difficultyLevel}.DESCRIPTION`);

	return (
		<Box>
			<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_IMPORTANT_INFO")}</TitleSection>

			{!!excludedArray?.length && (
				<Box mt={15}>
					<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_EXCLUDED")}</SubTitleSectionV2>
					<Box mt={5}>
						<ChipStringArray valuesArray={excludedArray || []} />
					</Box>
				</Box>
			)}

			{!!requirementsArray?.length && (
				<Box mt={15}>
					<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_REQUIREMENTS")}</SubTitleSectionV2>
					<IconStringArray
						icon={<Lens style={{ fontSize: 8, color: theme.palette.primary.main }} />}
						valuesArray={requirementsArray}
					/>
				</Box>
			)}

			<Box mt={15}>
				<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_DIFFICULTY_LEVEL")}</SubTitleSectionV2>
				<IconStringArray
					icon={<Lens style={{ fontSize: 8, color: theme.palette.primary.main }} />}
					valuesArray={[difficultyLevelStr]}
				/>
			</Box>

			{!!healthReastrictionsArray?.length && (
				<Box mt={15}>
					<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_HEALTH_RESTRICTIONS")}</SubTitleSectionV2>
					<IconStringArray
						translationKey="EXPERIENCE.HEALTH_RESTRICTIONS"
						icon={<ErrorOutline style={{ fontSize: 20, color: theme.palette.error.main }} />}
						valuesArray={healthReastrictionsArray}
					/>
				</Box>
			)}

			{!!weatherSensitive?.length && (
				<Box mt={15}>
					<SubTitleSectionV2>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_WEATHER_SENSITIVE")}</SubTitleSectionV2>
					<IconStringArray
						icon={<Lens style={{ fontSize: 8, color: theme.palette.primary.main }} />}
						valuesArray={weatherSensitive}
					/>
				</Box>
			)}
		</Box>
	);
};

export default ImportantInfo;
