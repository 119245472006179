import { CommonQueryParams } from "services/api/types";

import { CRUDOperation } from "types/data";

import API from "../index";

/**
 * @deprecated - start using string templates.
 *
 * @param apiName
 * @param firstParam
 * @param secondParam
 */
// @todo:chore - lead to a situation in which we do not use this function
export const generateEntityEndpoint = (apiName: string, firstParam?: string, secondParam?: string) => {
	const firstPart = firstParam ? `/${firstParam}` : "";
	const secondPart = secondParam ? `/${secondParam}` : "";

	return [apiName, firstPart, secondPart].join("");
};

// @todo:chore - lead to a situation in which we do not use CRUDAPI
export const createCRUDAPI = <EntityDTO, EntityType = EntityDTO, QueryParams = CommonQueryParams>(
	endpoint: string,
	apiName: string = "internal",
	mapFromDTO = d => d,
	mapToDTO = d => d,
) => ({
	[CRUDOperation.fetchAll]: ({ queryParams }: { queryParams?: QueryParams }): Promise<EntityType[]> => {
		return API.get<EntityType[]>(
			apiName,
			generateEntityEndpoint(endpoint),
			{
				queryStringParameters: {
					...queryParams,
				},
			},
			mapFromDTO,
		);
	},
	[CRUDOperation.fetchById]: ({ entityId }: { entityId?: string }): Promise<EntityType> => {
		return API.get<EntityType>(apiName, generateEntityEndpoint(endpoint, entityId), {}, mapFromDTO);
	},
	[CRUDOperation.createNew]: ({ data, entityId }: { data: EntityType; entityId?: string }): Promise<any> => {
		return API.post<any>(
			apiName,
			generateEntityEndpoint(endpoint, entityId),
			{
				body: mapToDTO(data),
			},
			mapFromDTO,
		);
	},
	[CRUDOperation.updateById]: ({
		data,
		queryParams,
	}: {
		data: EntityType;
		queryParams?: QueryParams;
	}): Promise<any> => {
		return API.patch<any>(apiName, generateEntityEndpoint(endpoint), {
			body: mapToDTO(data),
			queryStringParameters: {
				...queryParams,
			},
		});
	},
	[CRUDOperation.deleteById]: ({ entityId }: { entityId?: string }): Promise<any> => {
		return API.del<any>(apiName, generateEntityEndpoint(endpoint, entityId), {});
	},
});
