import { Cache } from "aws-amplify";
import jwtDecode, { JwtPayload } from "jwt-decode";

import { affiliateSessionStorage } from "classes/AffiliateSession";
import { partnerStorage } from "classes/partner/session/PartnerStorage";

import store from "store/index";
import { selectUser } from "store/selectors/user";

import { refreshSession, signOut } from "./auth";

const customHeaderGenerator = async function* (
	withoutAuthorizationToken?: boolean,
	withoutFetchUser?: boolean,
	withoutValidateAfiParam?: boolean,
) {
	let index = 0;

	while (true) {
		const userDataFromStore = selectUser(store.getState());

		let Authentication = Cache.getItem("Authentication");
		let Authorization = Cache.getItem("Authorization");

		let partnerId;
		let afiParam;

		if (Authentication) {
			const tokenDecoded = jwtDecode<JwtPayload>(Authentication);
			const authorizationTokenDecoded = jwtDecode<JwtPayload>(Authorization);

			const now = Date.now() / 1000;

			if (
				!tokenDecoded?.exp ||
				tokenDecoded.exp < now ||
				!authorizationTokenDecoded?.exp ||
				authorizationTokenDecoded.exp < now
			) {
				await refreshSession(withoutAuthorizationToken, withoutFetchUser);

				Authentication = Cache.getItem("Authentication");
				Authorization = Cache.getItem("Authorization");
			}
		} else {
			if (!!userDataFromStore) {
				await signOut(true);
			}
		}

		const partner = partnerStorage.getPartner();

		if (partner?.isActive()) {
			partnerId = partner.id;
		}

		const headers = {
			Authorization,
			Authentication,
			"x-partner-id": partnerId,
		};

		if (index === 0 && !withoutValidateAfiParam) {
			afiParam = await affiliateSessionStorage.getAndValidateAffiliateParam(headers);
		} else {
			afiParam = affiliateSessionStorage.getAffiliateParam();
		}

		yield {
			...headers,
			"x-affiliate-code": afiParam,
		};

		index++;
	}
};

const generatorCommon = customHeaderGenerator(false, true);
const generatorAuth = customHeaderGenerator(true, true, true);

const createHeaders = (type?: "auth") => async () => {
	let generator;

	if (type === "auth") {
		generator = await generatorAuth.next();
	} else {
		generator = await generatorCommon.next();
	}

	return generator.value;
};

export const apiAddress = !!process.env.REACT_APP_API_URL ? "." + process.env.REACT_APP_API_URL : "";

const config = {
	Auth: {
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		region: process.env.REACT_APP_COGNITO_REGION,
		userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
		oauth: {
			domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
			scope: ["email"],
			redirectSignIn: process.env.REACT_APP_SITE_URL,
			redirectSignOut: process.env.REACT_APP_SITE_URL,
			responseType: "code",
			options: {
				AdvancedSecurityDataCollectionFlag: false,
			},
		},
	},

	API: {
		endpoints: [
			{
				name: "",
				// @todo:fix - environment file and getSubdomain
				endpoint: `https://api${apiAddress}.localbini.com`,
				custom_header: createHeaders(),
			},
			{
				name: "internal",
				// @todo:fix - environment file and getSubdomain
				endpoint: `https://internal.api${apiAddress}.localbini.com`,
				custom_header: createHeaders(),
			},
			{
				name: "auth",
				// @todo:fix - environment file and getSubdomain
				endpoint: `https://auth${apiAddress}.localbini.com`,
				custom_header: createHeaders("auth"),
			},
		],
	},
};

export default config;
