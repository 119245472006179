import { DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { CloseIconBtn } from "styles/common";

import { IDialogDecorator } from "../../types";

const WithoutLogoDecorator = ({ children, handleClose }: IDialogDecorator) => (
	<DialogContent>
		<CloseIconBtn aria-label="close-button" onClick={handleClose}>
			<CloseIcon />
		</CloseIconBtn>

		{children}
	</DialogContent>
);

export default WithoutLogoDecorator;
