import * as Yup from "yup";

const createValidationSchema = (minAdults?: number, maxAdults?: number) => {
	const _midAdults = minAdults || 1;
	const _maxAdults = maxAdults || 100;

	const adultsErrorMin = `This experience required min. ${_midAdults} adults.`;
	const adultsErrorMax = `This experience can be with max. ${_maxAdults} adults.`;
	const wrongValueError = "Wrong value.";
	const wrongDateSelected = "Please select the date.";
	const wrongHourSelected = "Please select the time.";

	return Yup.object().shape({
		adults: Yup.number().min(_midAdults, adultsErrorMin).max(_maxAdults, adultsErrorMax).required(adultsErrorMin),
		kids: Yup.number().min(0, wrongValueError).max(20, "Too many.").required(wrongValueError),
		language: Yup.string().required("Select experience language."),
		hour: Yup.array().min(1, wrongHourSelected).required(wrongHourSelected),
		date: Yup.array().min(1, wrongDateSelected).required(wrongDateSelected),
		selectedChildren: Yup.lazy(obj => {
			const schemaObject = {};

			Object.keys(obj).forEach(
				elem =>
					(schemaObject[elem] = Yup.number()
						.min(0, wrongValueError)
						.max(21, "Max. 21 years old.")
						.default(null)
						.nullable()
						.required(wrongValueError)),
			);

			return Yup.object().shape(schemaObject);
		}),
	});
};

export default createValidationSchema;
