import { Box, Button, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useEffect, useState } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

export interface INumberDurationsMinutes {
	onChange: (newValue) => void;
	value: number;
	inputProps?: TextFieldProps;
}

type TPeriod = "DAYS" | "HOURS" | "MINUTES";

interface IButtonPlusMinus {
	addValue: (value: number, period: TPeriod) => void;
	variant: "PLUS" | "MINUS";
	period: TPeriod;
}

const dateTimePeriods = {
	DAYS: { max: 365 },
	HOURS: { max: 24 },
	MINUTES: { max: 60 },
};

const PeriodInput = styled(TextField)`
	width: 100px;
`;

const ButtonPlusMinus = ({ addValue, variant, period }: IButtonPlusMinus) => {
	const handleClick = () => {
		if (variant === "PLUS") {
			addValue(1, period);
		} else {
			addValue(-1, period);
		}
	};

	return (
		<Box display="flex" alignItems="center" mb={5} mt={5} mr={10} ml={10}>
			<Button onClick={handleClick} variant="outlined">
				{variant === "PLUS" ? <AddIcon /> : <RemoveIcon />}
			</Button>
		</Box>
	);
};

const dayInMinutes = dateTimePeriods.MINUTES.max * dateTimePeriods.HOURS.max;

const NumberDurationsMinutes = ({ onChange, value = 0, inputProps }: INumberDurationsMinutes) => {
	const fullDays = Math.floor(value / dayInMinutes);
	const fullHours = Math.floor((value - fullDays * dayInMinutes) / dateTimePeriods.MINUTES.max);
	const fullMinutes = value - fullDays * dayInMinutes - fullHours * dateTimePeriods.MINUTES.max;

	const [periods, setPeriods] = useState<Record<TPeriod, number>>({
		DAYS: fullDays,
		HOURS: fullHours,
		MINUTES: fullMinutes,
	});

	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (periods.DAYS !== fullDays || periods.HOURS !== fullHours || periods.MINUTES !== fullMinutes) {
			setPeriods({
				DAYS: fullDays,
				HOURS: fullHours,
				MINUTES: fullMinutes,
			});
		}
	}, [periods, fullMinutes, fullDays, fullHours]);

	const calculateMinutes = periodsData =>
		periodsData.DAYS * dayInMinutes + periodsData.HOURS * dateTimePeriods.MINUTES.max + periodsData.MINUTES;

	const changeValue = (increment: number, period) => {
		setPeriods(prev => {
			if (!(prev[period] + increment < 0)) {
				const newData = { ...prev };
				newData[period] = newData[period] + increment;

				const valueInMinutes = calculateMinutes(newData);
				onChange(valueInMinutes);

				return newData;
			}

			return prev;
		});
	};

	const onBlur = () => {
		const tempPeriods = { ...periods };

		const minutesInHour = dateTimePeriods.MINUTES.max;
		if (tempPeriods.MINUTES >= minutesInHour) {
			const hours = Math.floor(tempPeriods.MINUTES / minutesInHour);
			tempPeriods.MINUTES -= hours * minutesInHour;
			tempPeriods.HOURS += hours;
		}

		const hoursInDay = dateTimePeriods.HOURS.max;
		if (tempPeriods.HOURS >= hoursInDay) {
			const days = Math.floor(tempPeriods.HOURS / hoursInDay);
			tempPeriods.HOURS -= days * hoursInDay;
			tempPeriods.DAYS += days;
		}

		setPeriods(tempPeriods);
	};

	const handleChange = (event, period) => {
		const currentValue = event.target.value ? parseInt(event.target.value, 10) : 0;
		changeValue(currentValue - periods[period], period);
	};

	return (
		<Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
			{Object.keys(dateTimePeriods).map((period: TPeriod) => (
				<Box display="flex" flexDirection="row" mb={isSmallScreen ? 10 : 0} mr={5} key={period}>
					{isSmallScreen && <ButtonPlusMinus period={period} addValue={changeValue} variant="MINUS" />}

					<Box>
						<PeriodInput
							id={`${period.toLowerCase()}-number`}
							label={t(`EXPERIENCE.FORM.NUMBERS_TIME.${period}_LABEL`)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ onBlur, min: 0, max: dateTimePeriods[period].max, step: 1 }}
							value={(periods[period] || "").toString()}
							variant="outlined"
							type="number"
							onChange={e => handleChange(e, period)}
							{...inputProps}
						/>
					</Box>

					{isSmallScreen && <ButtonPlusMinus period={period} addValue={changeValue} variant="PLUS" />}
				</Box>
			))}
		</Box>
	);
};

export default NumberDurationsMinutes;
