import { ReactComponent as CheckIcon } from "assets/icons/check_confirmed.svg";

import useTranslation from "hooks/useTranslation";

import Dialog from "ui/Dialog";

import { TitleHeader } from "../../shared.styled";
import { CTAButton, Desc, IconContainer, LinkContainer, ModalContent } from "./styled";

interface IModalProps {
	openModal: boolean;
	handleCloseModal(): void;
	binipool?: boolean;
}

const FinalModal = ({ handleCloseModal, openModal, binipool }: IModalProps) => {
	const { t, withRaw } = useTranslation();

	return (
		<Dialog handleClose={handleCloseModal} showDialog={openModal} maxWidth="md" decorator="withoutLogo">
			<ModalContent>
				<IconContainer>
					<CheckIcon />
				</IconContainer>

				<TitleHeader>{t("ONBOARDING.FINAL_MODAL.HEADER")}</TitleHeader>

				<Desc>
					{!binipool ? withRaw("ONBOARDING.FINAL_MODAL.DESC", null) : t("ONBOARDING.FINAL_MODAL.DESC_BINIPOOL")}
				</Desc>

				<LinkContainer>
					<CTAButton variant="contained" color="primary" onClick={() => handleCloseModal()}>
						{t("ONBOARDING.FINAL_MODAL.CTA")}
					</CTAButton>
				</LinkContainer>
			</ModalContent>
		</Dialog>
	);
};

export default FinalModal;
