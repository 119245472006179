import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { memo, useCallback } from "react";

import { IExperienceDTO } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { minutesToHM } from "utils/dates";

import CarouselSwiper from "ui/CarouselSwiper";

import { Container, StyledLink } from "styles/common";

import RecordHP from "../../experiences/RecordHP";
import { carouselSwiperConfig } from "../../experiences/RecordHP/config";
import { SeeAllBtn } from "../shared.styled";
import TitleSection from "../TitleSection";

const Discover = memo(
	({
		discoverList,
		titleTranslationKey = "HP.DISCOVER.TITLE",
	}: {
		discoverList: IExperienceDTO[];
		titleTranslationKey?: string;
	}) => {
		const { t } = useTranslation();

		const theme = useTheme();
		const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

		const createListOfSliders = useCallback(
			() =>
				discoverList.map(v => (
					<RecordHP
						key={`${v.id}-${v.title}`}
						id={v.id}
						avatarUrl={v.localhost?.photo_url || undefined}
						city={v.city}
						hours={minutesToHM(v.duration_minutes)}
						imgUrl={v.photo_main}
						name={v.localhost?.first_name || t("SEARCH.EXPERIENCES.LOCALHOST")}
						pricePerPerson={v.pricing.filter_price || 0}
						priceCurrency={v.pricing.currency}
						ratings={v.ratings}
						title={v.title}
						exp_type={v.exp_type}
						description={v.description}
						exposures={v.internal?.exposures}
					/>
				)),

			// eslint-disable-next-line
			[discoverList],
		);

		return (
			<Box pt={isSmallScreen ? 20 : 45} pb={isSmallScreen ? 20 : 45}>
				<Container>
					<TitleSection title={t(titleTranslationKey)} subTitle={t("HP.DISCOVER.SUBTITLE")} />
				</Container>

				<CarouselSwiper
					isMarginLeft
					heightSlide="inherit"
					widthSlide="inherit"
					paddingBox={5}
					settings={carouselSwiperConfig}
					slides={createListOfSliders()}
					hidePrevNextBtn={discoverList.length < 2}
				/>

				<Box display="flex" alignItems="center" justifyContent="center" mt={isSmallScreen ? 16 : 22}>
					<StyledLink to={paths.SEARCH} $withoutHoverUnderline>
						<SeeAllBtn color="primary" variant="contained">
							{t("HP.SEE_ALL_BTN")}
						</SeeAllBtn>
					</StyledLink>
				</Box>
			</Box>
		);
	},
);

export default Discover;
