import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

const Image = styled.img`
	height: 50px;
`;

const Title = styled.h1`
	margin: 10px 0;
	font-family: ${MontserratFontFamily};
	font-size: 25px;
	font-weight: 700;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};
`;

const Link = styled.a`
	background: ${colors.mainFontColor};
	display: inline-flex;
	padding: 10px 25px;
	border-radius: 5px;
	color: white;
	text-decoration: none;
	border: 0;
	line-height: 1;
`;

const Content = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const ErrorCode = styled.code`
	display: block;
	margin-top: 20px;
	padding: 20px;
	background: ${colors.red};
	color: ${colors.white};
`;

interface ICrash {
	withError?: Error;
}

const Crash = ({ withError }: ICrash) => (
	<Content>
		<Image src="/localbiniBig.svg" alt="LocalBini logo" />

		<Title>Ups, we have some problems....</Title>

		{!withError ? (
			<Link href="/">GO TO HOMEPAGE</Link>
		) : (
			<ErrorCode>{withError?.stack || withError?.message}</ErrorCode>
		)}
	</Content>
);

export default Crash;
