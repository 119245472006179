import { createAsyncThunk } from "@reduxjs/toolkit";

import { Errors } from "classes/Errors";

import ApiError from "utils/errors/ApiError";

export const handleRejectValues = (name: string, action: any, handleAndNotify?: boolean) =>
	createAsyncThunk(name, async (data: object | undefined, { rejectWithValue }) => {
		try {
			return await action(data);
		} catch (error) {
			if (handleAndNotify && Errors.useErrors) {
				Errors.useErrors.handleAndNotify(error);
			}

			if (error instanceof ApiError) {
				return rejectWithValue(error);
			}

			return rejectWithValue(new ApiError(error));
		}
	});
