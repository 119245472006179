import {
	Box,
	Button,
	Checkbox,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	TextField,
	Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ListItemProps } from "@material-ui/core/ListItem/ListItem";
import { Check, Close, DeleteOutline } from "@material-ui/icons";
import { CSSProperties, useState } from "react";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

import SwitcherYesNo from "ui/forms/SwitcherYesNo";

import { itemOfListStyles } from "styles/common";

export interface IStringArrayCheckboxes {
	field: string;
	onChange: (v) => void;
	onChangeCustomArray?: (v) => void;
	value: string[];
	customValue?: string[];
	isSwitcherYesNo?: boolean;
	listItemProps?: Pick<ListItemProps, "disableGutters"> & { style: CSSProperties };
}

const StringArrayCheckboxes = ({
	field,
	onChange,
	value,
	isSwitcherYesNo,
	customValue,
	onChangeCustomArray,
	listItemProps,
}: IStringArrayCheckboxes) => {
	const [isAddMode, setIsAddMode] = useState<boolean>(false);
	const [currentlyAdded, setCurrentlyAdded] = useState<string>("");
	const [showListSection, setShowListSection] = useState<boolean>(
		value.length > 0 || (customValue && customValue.length > 0) || false,
	);

	const { t } = useTranslation();
	// @ts-ignore
	const dictionary = getDictionary(field);

	const handleDelete = element =>
		onChangeCustomArray && customValue && onChangeCustomArray(customValue.filter(v => v !== element));

	const handleAdd = () => {
		if (!currentlyAdded) {
			return;
		}

		if (onChangeCustomArray && customValue) {
			onChangeCustomArray([...customValue, currentlyAdded]);
		}

		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	const handleCancel = () => {
		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	const handleChangeCheckbox = element => {
		if (value.indexOf(element) === -1) {
			onChange([...value, element]);
		} else {
			onChange(value.filter(v => v !== element));
		}
	};

	const handleYesNo = yesNoValue => {
		setShowListSection(yesNoValue);
		if (isSwitcherYesNo && !yesNoValue) {
			onChange([]);
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			{isSwitcherYesNo && <SwitcherYesNo onChangeYesNo={handleYesNo} value={showListSection} />}
			{(showListSection || !isSwitcherYesNo) && (
				<Box>
					<List key="list">
						{dictionary.map((d, i) => (
							<ListItem key={i} divider style={itemOfListStyles} {...listItemProps}>
								<FormControlLabel
									control={
										<Checkbox
											checked={value.indexOf(d) !== -1}
											onChange={() => handleChangeCheckbox(d)}
											name={t(`EXPERIENCE.${field}.${d}`)}
											color="primary"
										/>
									}
									style={{ padding: "0" }}
									label={t(`EXPERIENCE.${field}.${d}`)}
								/>
							</ListItem>
						))}

						{customValue &&
							customValue.map((v, i) => (
								<ListItem key={i} divider style={itemOfListStyles} {...listItemProps}>
									<FormControlLabel
										control={<Checkbox checked readOnly name={v} color="primary" />}
										style={{ padding: "0" }}
										label={v}
									/>

									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete" color="inherit" onClick={() => handleDelete(v)}>
											<DeleteOutline />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}

						{isAddMode && (
							<ListItem divider key="add_more" {...listItemProps}>
								<TextField
									placeholder={t("EXPERIENCE.FORM.STRING_CHECKBOXES.PLACEHOLDER.DEFAULT")}
									variant="outlined"
									onChange={e => setCurrentlyAdded(e.target.value)}
									value={currentlyAdded}
									size="small"
								/>

								<ListItemSecondaryAction>
									<Box display="flex" flexDirection="row">
										<Box pr={4}>
											<IconButton edge="end" aria-label="accept" color="inherit" onClick={handleAdd}>
												<Check />
											</IconButton>
										</Box>

										<Box>
											<IconButton edge="end" aria-label="close" color="inherit" onClick={handleCancel}>
												<Close />
											</IconButton>
										</Box>
									</Box>
								</ListItemSecondaryAction>
							</ListItem>
						)}
					</List>

					<Box display="flex" justifyContent="flex-end">
						<Box pt={10}>
							<Button
								disabled={isAddMode}
								variant="contained"
								onClick={() => {
									setIsAddMode(!isAddMode);
								}}
							>
								<Typography variant="button">{t("EXPERIENCE.FORM.STRING_CHECKBOXES.ADD_POSITION.DEFAULT")}</Typography>
							</Button>
						</Box>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default StringArrayCheckboxes;
