import { DoneAll, EditOutlined, HourglassEmptyOutlined } from "@material-ui/icons";
import { memo } from "react";

import { EUserStatus, TUserStatus } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";

import { buttonAdditionalStyles, StatusLabelBox } from "../common";

interface IStatusBtnProps {
	type: TUserStatus;
}

const LocalhostStatusLabel = memo(({ type }: IStatusBtnProps) => {
	const { t } = useTranslation();

	const getLabel = (name: TUserStatus) => {
		let icon;
		let label;
		let color;

		switch (name) {
			case EUserStatus.PUBLISHED:
				label = t("EXPERIENCE.STATUS.PUBLISHED");
				icon = <DoneAll style={buttonAdditionalStyles} />;
				color = colors.green;
				break;

			case EUserStatus.DRAFT:
				label = t("EXPERIENCE.STATUS.DRAFT");
				icon = <EditOutlined style={buttonAdditionalStyles} />;
				color = colors.middleGray;
				break;

			case EUserStatus.NEEDS_CHANGES:
				label = t("EXPERIENCE.STATUS.NEED_CHANGES");
				icon = <EditOutlined style={buttonAdditionalStyles} />;
				color = colors.red;
				break;

			case EUserStatus.UNDER_REVIEW:
			case EUserStatus.REJECTED:
			case EUserStatus.AWAITING_REVIEW:
				label = t("EXPERIENCE.STATUS.PENDING");
				icon = <HourglassEmptyOutlined style={buttonAdditionalStyles} />;
				color = "#2196f3";
				break;

			default:
				icon = "";
				label = "";
				color = "";
		}

		return {
			icon,
			label,
			color,
		};
	};

	return <StatusLabelBox getLabel={getLabel} type={type} />;
});

export default LocalhostStatusLabel;
