import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { IExperienceResponse } from "data/ratings/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import ratingsService from "services/api/ratings";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTraveller, selectExperienceTravellerStatus } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { experienceTravellerActions, fetchExperienceDetailsTraveller } from "store/slices/experienceTraveller";

import Traveller from "components/experiences/Traveller";
import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import { Container } from "styles/common";

import BookingCheckout from "./components/BookingCheckout";
import BookingConfirmation from "./components/BookingConfirmation";
import BookingSummary from "./components/BookingSummary";
import BookingGuestRegisterForm from "./components/GuestRegisterForm";

interface TTravellerPageParams {
	id: string;
}

const BookingPage = ({ match }: RouteComponentProps<TTravellerPageParams>) => {
	const { t } = useTranslation();

	const { silent } = useErrors();

	const dispatch = useAppDispatch();

	const loggedUser = useAppSelector(selectUser);
	const experience = useAppSelector(selectExperienceTraveller);
	const status = useAppSelector(selectExperienceTravellerStatus);

	const query = useQuery();

	const [reviews, setReviews] = useState<IExperienceResponse[] | null>();

	const { id } = match.params;

	const step = Number(query.get("step")) || 0;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [step]);

	useEffect(() => {
		dispatch(fetchExperienceDetailsTraveller({ id, withExtraData: true }));

		dispatch(
			experienceTravellerActions.updateBooking({
				eid: id,
			}),
		);

		(async () => {
			try {
				const reviewsData = await ratingsService.getSearchExpRatings(id);

				setReviews(reviewsData);
			} catch (e) {
				setReviews(null);

				silent(e);
			}
		})();

		return () => {
			dispatch(
				experienceTravellerActions.updateBooking({
					superbooking_id: undefined,
					// For previously added wrong affiliate code
					affiliateCode: undefined,
				}),
			);
		};

		// eslint-disable-next-line
	}, [id]);

	if (status === "loading") {
		return (
			<AppWrapper>
				<Container>
					<Loader />
				</Container>
			</AppWrapper>
		);
	}

	if (status === "failed") {
		return (
			<AppWrapper>
				<Container>
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
						<Alert severity="error">{t("NOTIFICATION.UNDEFINED_ERROR")}</Alert>
					</Box>
				</Container>
			</AppWrapper>
		);
	}

	if (query.get("confirmed")) {
		return <BookingConfirmation />;
	}

	if (step === 0) {
		return (
			<AppWrapper>
				{experience?.data && (
					<Traveller {...experience.data} reviews={reviews} upcomingBookings={experience?.upcoming_bookings} />
				)}
			</AppWrapper>
		);
	}

	if (loggedUser && ![EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER].includes(loggedUser?.userType)) {
		if (step === 1) {
			return <BookingSummary />;
		}
	} else {
		if (step === 1) {
			return <BookingGuestRegisterForm />;
		}

		if (step === 2) {
			return <BookingSummary />;
		}
	}

	return <BookingCheckout />;
};

export default BookingPage;
