import CurrentPartner, { ICurrentPartner } from "./CurrentPartner";

class PartnerStorage {
	data?: CurrentPartner;
	storageKey: string;

	constructor(storageKey: string) {
		if (this.data instanceof CurrentPartner) {
			return;
		}

		this.storageKey = storageKey;

		this.load();
	}

	load() {
		this.data = new CurrentPartner();

		try {
			const partner = sessionStorage.getItem(this.storageKey) || localStorage.getItem(this.storageKey);

			if (null !== partner) {
				this.data = new CurrentPartner(JSON.parse(partner));

				return;
			}
		} catch {
			return;
		}
	}

	getPartner() {
		return this.data;
	}

	getData() {
		return this.data?.partner;
	}

	clear() {
		sessionStorage.removeItem(this.storageKey);
		localStorage.removeItem(this.storageKey);

		this.load();

		return true;
	}

	store(data: ICurrentPartner, storeType?: "local" | "session") {
		if (storeType === "local") {
			localStorage.setItem(this.storageKey, JSON.stringify(data));
		} else {
			sessionStorage.setItem(this.storageKey, JSON.stringify(data));
		}

		this.load();

		return this.data;
	}
}

const partnerStorage = new PartnerStorage("partner.session.data");

export { partnerStorage };

export default PartnerStorage;
