import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";

import { IExperienceDTO, IRatings } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { Container, StyledLink } from "styles/common";

import RecordHP, { IRecordHPProps } from "../../experiences/RecordHP";
import { SeeAllBtn } from "../shared.styled";
import TitleSection from "../TitleSection";

type ExperienceRating = IExperienceDTO & { ratings: IRatings };

const Explore = memo(({ exploreList }: { exploreList: ExperienceRating[] }) => {
	const { t } = useTranslation();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isMediumScreen = useMediaQuery(theme.breakpoints.up("lg"));

	const recordHP = (index: number, props?: Partial<IRecordHPProps>) =>
		!!exploreList[index] && (
			<RecordHP
				{...props}
				id={exploreList[index].id || ""}
				avatarUrl={exploreList[index].localhost?.photo_url || undefined}
				city={exploreList[index].city || ""}
				hours={`${exploreList[index].duration_minutes / 60 || 0} hours`}
				imgUrl={exploreList[index].photo_main || ""}
				name={exploreList[index].localhost?.first_name || "Local Host"}
				pricePerPerson={exploreList[index].pricing.filter_price || 0}
				priceCurrency={exploreList[index].pricing.currency}
				ratings={exploreList[index].ratings}
				title={exploreList[index].title || ""}
				exp_type={exploreList[index].exp_type}
				description={exploreList[index].description}
				exposures={exploreList[index].internal?.exposures}
			/>
		);

	return (
		<Box pt={isSmallScreen ? 20 : 45} pb={isSmallScreen ? 20 : 50}>
			<Container>
				<TitleSection title={t("HP.EXPLORE.TITLE")} subTitle={t("HP.EXPLORE.SUBTITLE")} />
				{!isMediumScreen && !isSmallScreen && (
					<Box display="flex" alignItems="center" justifyContent="space-between" pl={5} pr={5}>
						<Box width="50%" mr={4}>
							{recordHP(0, { isSmallerImgExplore: true })}
							<br />
							{recordHP(1)}
							<br />
							{recordHP(2, { isSmallerImgExplore: true })}
						</Box>

						<Box width="50%">
							{recordHP(3)}
							<br />
							{recordHP(4, { isSmallerImgExplore: true })}
							<br />
							{recordHP(5, { isSmallerImgExplore: true })}
						</Box>
					</Box>
				)}

				{/* desktop */}
				{isMediumScreen && !isSmallScreen && (
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Box>
							{recordHP(0)}
							<br />
							{recordHP(1, { isSmallerImgExplore: true })}
						</Box>
						<Box>
							{recordHP(2, { isSmallerImgExplore: true })}
							<br />
							{recordHP(3)}
						</Box>

						<Box>
							{recordHP(4)}
							<br />
							{recordHP(5, { isSmallerImgExplore: true })}
						</Box>
					</Box>
				)}

				{/* mobile*/}
				{isSmallScreen && (
					<Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
						{recordHP(0)}
						<br />
						{recordHP(1, { isSmallerImgExplore: true })}
						<br />
						{recordHP(2)}
						<br />
						{recordHP(3, { isSmallerImgExplore: true })}
						<br />
						{recordHP(4)}
						<br />
						{recordHP(5, { isSmallerImgExplore: true })}
					</Box>
				)}
			</Container>

			<Box display="flex" alignItems="center" justifyContent="center" mt={isSmallScreen ? 16 : 22}>
				<StyledLink to={paths.SEARCH} $withoutHoverUnderline>
					<SeeAllBtn color="primary" variant="contained">
						{t("HP.SEE_ALL_BTN")}
					</SeeAllBtn>
				</StyledLink>
			</Box>
		</Box>
	);
});

export default Explore;
