import { css, ThemedBaseStyledInterface, ThemedCssFunction } from "styled-components";

export const sizes = {
	largeDesktop: 1340,
	desktop: 1024,
	tabletMaterial: 960,
	tablet: 768,
	phone: 478,
};

// Iterate through the sizes and create a media template
const media = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
	acc[label] = (first: any, ...interpolations: any[]) => css`
		@media (max-width: ${sizes[label]}px) {
			${css(first, ...interpolations)}
		}
	`;

	return acc;
}, {} as { [key in keyof typeof sizes]: ThemedCssFunction<ThemedBaseStyledInterface<any>> });

export const mediaMobileFirst = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
	acc[label] = (first: any, ...interpolations: any[]) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(first, ...interpolations)}
		}
	`;

	return acc;
}, {} as { [key in keyof typeof sizes]: ThemedCssFunction<ThemedBaseStyledInterface<any>> });

export default media;
