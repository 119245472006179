import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { ExpandMore, Lens } from "@material-ui/icons";
import { useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import CircularProgressWithLabel from "./components/CircularProgressWithLabel";
import { stepperConfig } from "./config";

const CircularProgressWithLabelContent = styled.div`
	color: ${colors.hokiColor};
`;

const StyledLensIcon = styled(Lens)`
	font-size: 8px;
	color: ${colors.hokiColor};
`;

const Label = styled.p`
	margin-left: 10px;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	letter-spacing: 0.1px;
	color: ${colors.middleGray};
`;

const StepAccordionMenu = ({ currentStep }: { currentStep: number }) => {
	const { id, step } = useParams<{ id: string; step: string }>();
	const history = useHistory();
	const match = useRouteMatch();

	const [expanded, setExpanded] = useState(currentStep - 1);
	const { t } = useTranslation();

	const handleChange = handleStep => (e, isExpanded) => {
		setExpanded(!isExpanded || handleStep);
	};

	return (
		<>
			{stepperConfig.map((itemStep, index) => (
				<Accordion expanded={expanded === index} onChange={handleChange(index)} key={index}>
					<AccordionSummary
						onClick={() => {
							if (id && step && itemStep.order !== expanded + 1) {
								history.push(`${match.url.slice(0, -1)}${itemStep.order}`);
							}
						}}
						expandIcon={<ExpandMore />}
						IconButtonProps={{ edge: false }}
						aria-controls={`step-${index}-content`}
						id={`step-${index}`}
					>
						<Box display="flex">
							<Box display="flex" mr={4}>
								<CircularProgressWithLabelContent>
									<CircularProgressWithLabel
										color={currentStep === itemStep.order ? "primary" : "inherit"}
										size={26}
										value={100}
										label={itemStep.order}
									/>
								</CircularProgressWithLabelContent>
							</Box>

							<Box mt={1}>
								<Typography style={{ fontSize: "16px" }}>{t(`EXPERIENCE.${itemStep.title}`)}</Typography>
							</Box>
						</Box>
					</AccordionSummary>

					<AccordionDetails>
						<Box display="flex" flexDirection="column" pl={15}>
							{itemStep.fieldsTitles.map(key => (
								<Box key={key} display="flex" alignItems="center">
									<StyledLensIcon />
									<Label>{t(`EXPERIENCE.FIELDS.${key}.TITLE`)}</Label>
								</Box>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

export default StepAccordionMenu;
