import { FormikProps } from "formik";

import useTranslation from "hooks/useTranslation";

import Gallery from "components/creation-process/Gallery";
import GalleryTooltip from "components/creation-process/tooltips/media/Gallery";
import CheckboxField from "components/formik/CheckboxField";

import { Tooltip } from "ui/tooltips/Tooltip";

import { FormValuesWithHelpers } from "../../../types";
import {
	ItemDescription,
	ItemTitle,
	ItemWrapper,
	MainTitleSection,
	TitleTooltipWrapper,
	Wrapper,
} from "../shared.styled";

const Step4 = ({
	values,
	handleSubmit,
	setFieldValue,
	saveMediaCallback,
	deleteMediaCallback,
}: FormikProps<FormValuesWithHelpers> & {
	saveMediaCallback: (blob: Blob) => void;
	deleteMediaCallback: (id: string) => void;
}) => {
	const { t } = useTranslation();

	const changeMainPhoto = async v => {
		await setFieldValue("photo_main", v.media_url);

		handleSubmit();
	};

	return (
		<Wrapper>
			<MainTitleSection>{t("EXPERIENCE.MEDIA")}</MainTitleSection>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.media_gallery.TITLE")}</ItemTitle>

					<Tooltip>
						<GalleryTooltip />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.media_gallery.DESCRIPTION")}</ItemDescription>

				<Gallery
					onChangeGallery={v => saveMediaCallback(v)}
					valueGallery={values.media_gallery_HELPER || []}
					valueMainPhoto={values.photo_main_HELPER}
					onChangeMainPhoto={changeMainPhoto}
					deleteMediaCallback={deleteMediaCallback}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<CheckboxField
					name="media_rights_confirmed_HELPER"
					label={t("EXPERIENCE.FORM.MEDIA.COPYRIGHTS")}
					withError
					required
				/>
			</ItemWrapper>
		</Wrapper>
	);
};

export default Step4;
