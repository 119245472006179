import { Box, IconButton } from "@material-ui/core";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { isArray, isEmpty, isNumber, isUndefined } from "lodash-es";
import { ReactNode } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { isEqual } from "utils/objects";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const StyledFormTitle = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: 600;
	line-height: 2.17;
	letter-spacing: 0.83px;
	color: ${colors.mainFontColor};
	margin: 25px 0 0;
`;

const ModifiedIcon = styled.span`
	display: inline-block;
	background: ${colors.green};
	border-radius: 5px;
	color: white;
	padding: 0 5px;
	font-size: 10px;
	line-height: 1.5;
	font-weight: normal;
	margin-right: 5px;
	font-family: ${RobotoFontFamily};
	letter-spacing: 0.69px;
`;

interface IFormTitle {
	compare?: {
		current?: unknown;
		comparable?: unknown;
	};
	children: ReactNode;
	restoreToClassificationValuesCallback?(): void;
}

const FormTitle = ({ children, compare, restoreToClassificationValuesCallback }: IFormTitle) => {
	const { t } = useTranslation(["bo", "common"]);

	const modified =
		!!compare &&
		!isUndefined(compare?.current) &&
		!isUndefined(compare?.comparable) &&
		(!isEmpty(compare?.comparable) || isNumber(compare?.comparable) || isArray(compare?.comparable)) &&
		!isEqual(compare?.current, compare?.comparable);

	return (
		<StyledFormTitle>
			{modified && <ModifiedIcon>{t("TEXT.MODIFIED")}</ModifiedIcon>}

			{children}

			{!!restoreToClassificationValuesCallback && modified && (
				<Box marginLeft="5px" display="inline-block">
					<IconButton
						size="small"
						onClick={restoreToClassificationValuesCallback}
						title={t("BO.EXPERIENCE.REVIEW.RESTORE_TO_DEFAULT_CLASSIFICATION")}
					>
						<SettingsBackupRestoreIcon color="secondary" />
					</IconButton>
				</Box>
			)}
		</StyledFormTitle>
	);
};

export default FormTitle;
