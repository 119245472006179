import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Typography,
} from "@material-ui/core";
import { Check, Close, DeleteOutline, Lens } from "@material-ui/icons";
import { ReactNode, useCallback, useState } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import SwitcherYesNo from "ui/forms/SwitcherYesNo";

import colors from "styles/colors";
import { itemOfListStyles } from "styles/common";

export interface IStringArrayProps {
	icon?: ReactNode;
	isSwitcherYesNo?: boolean;
	onChange: (newValue) => void;
	value: string[];
}

const StyledLensIcon = styled(Lens)`
	font-size: 8px;
	color: ${colors.black};
`;

const StringArray = ({ isSwitcherYesNo, icon = <StyledLensIcon />, onChange, value }: IStringArrayProps) => {
	const [isAddMode, setIsAddMode] = useState<boolean>(value?.length === 0);
	const [currentlyAdded, setCurrentlyAdded] = useState<string>("");
	const [showListSection, setShowListSection] = useState<boolean>(!!value.length);

	const { t } = useTranslation();

	const handleDelete = useCallback(
		index => {
			onChange(value.filter((_, i) => i !== index));
		},
		[onChange, value],
	);

	const handleAdd = () => {
		if (!currentlyAdded) {
			return;
		}

		onChange([...value, currentlyAdded]);
		setCurrentlyAdded("");
	};

	const handleCancel = useCallback(() => {
		setCurrentlyAdded("");
		setIsAddMode(false);
	}, []);

	const handleYesNo = yesNoValue => {
		setShowListSection(yesNoValue);

		if (isSwitcherYesNo && !yesNoValue) {
			onChange([]);
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			{isSwitcherYesNo && <SwitcherYesNo onChangeYesNo={handleYesNo} value={showListSection} />}
			{(showListSection || !isSwitcherYesNo) && (
				<>
					<List key="list">
						{value &&
							value.map((v, i) => (
								<ListItem key={v} divider style={itemOfListStyles}>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText primary={v} />

									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete" color="inherit" onClick={() => handleDelete(i)}>
											<DeleteOutline />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))}

						{isAddMode && (
							<ListItem divider key="add_more" style={itemOfListStyles}>
								<ListItemIcon>{icon}</ListItemIcon>
								<TextField
									placeholder={t(`EXPERIENCE.FORM.STRING_ARRAY.PLACEHOLDER.DEFAULT`)}
									variant="outlined"
									onChange={e => setCurrentlyAdded(e.target.value)}
									value={currentlyAdded}
									size="small"
								/>

								<ListItemSecondaryAction>
									<Box display="flex" flexDirection="row">
										<Box>
											<IconButton edge="end" aria-label="accept" color="inherit" onClick={handleAdd}>
												<Check />
											</IconButton>
										</Box>

										<Box>
											<IconButton edge="end" aria-label="close" color="inherit" onClick={handleCancel}>
												<Close />
											</IconButton>
										</Box>
									</Box>
								</ListItemSecondaryAction>
							</ListItem>
						)}
					</List>

					{!isAddMode && (
						<Box display="flex" justifyContent="flex-end">
							<Box pt={10}>
								<Button variant="contained" onClick={() => setIsAddMode(!isAddMode)}>
									<Typography variant="button">{t("EXPERIENCE.FORM.STRING_ARRAY.BUTTON.DEFAULT")}</Typography>
								</Button>
							</Box>
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export default StringArray;
