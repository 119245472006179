import { FormControl } from "@material-ui/core";
import styled from "styled-components";

import AutocompleteV2Field from "components/formik/AutocompleteV2Field";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

export const Wrapper = styled.section`
	width: 100%;
`;

export const MainTitleSection = styled.h1`
	margin: 30px 0 40px 0;
	font-family: ${MontserratFontFamily};
	font-size: 48px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.02;
	letter-spacing: normal;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size:${dimensions.fontSize.huge};
  `};
`;

export const ItemWrapper = styled.div`
	margin-bottom: 40px;
`;

export const ItemTitle = styled.h2`
	margin: 10px 10px 10px 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size:${dimensions.fontSize.xlarge};
  `};
`;

export const ItemDescription = styled.p`
	opacity: 0.7;
	font-family: ${RobotoFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.09px;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size:${dimensions.fontSize.medium};
  `};
`;

export const TitleTooltipWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const StyledFormControl = styled(FormControl)`
	.MuiNativeSelect-select.MuiNativeSelect-select {
		height: 30px;
		padding-top: 16px;
	}
`;

export const StyledAutocomplete = styled(AutocompleteV2Field)`
	.MuiChip-root {
		background-color: ${colors.lightOrange};
	}

	.MuiAutocomplete-popupIndicator {
		color: ${colors.sunShade};
	}
`;
