import { ErrorBoundary, init as initSentry, reactRouterV5Instrumentation } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";

import { affiliateSessionStorage } from "classes/AffiliateSession";

import "services/aws";

import { getEnvironmentUniqueName, mainDomain } from "utils/environments";

import Crash from "ui/Crash";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

const envName = getEnvironmentUniqueName();

affiliateSessionStorage.store();

if (!envName.includes("LOCALHOST")) {
	initSentry({
		dsn: "https://f1e75c8e803e482a94e676fe40c73271@o640923.ingest.sentry.io/6164747",
		integrations: [
			new BrowserTracing({
				tracingOrigins: ["*." + mainDomain.host],
				traceFetch: false,
				traceXHR: false,
				routingInstrumentation: reactRouterV5Instrumentation(history),
			}),
		],
		debug: false,
		environment: envName,
		beforeBreadcrumb: (breadcrumb, hint) => {
			if (breadcrumb.category === "xhr") {
				const errorType = hint?.xhr.__sentry_xhr__.status_code && hint.xhr.__sentry_xhr__.status_code >= 400;

				const errorData = {
					...(hint?.xhr.response && { responseData: hint.xhr.response }),
				};

				const data = {
					method: hint?.xhr.__sentry_xhr__.method,
					url: hint?.xhr.responseURL,
					status_code: hint?.xhr.__sentry_xhr__.status_code,
					...(hint?.xhr.__sentry_xhr__.body && { requestBody: hint.xhr.__sentry_xhr__.body }),
					...(errorType && errorData),
				};

				return { ...breadcrumb, level: errorType ? "error" : breadcrumb.level, data };
			}

			return breadcrumb;
		},
		// @todo:future - uncomment if the feedback option will needed.
		// beforeSend(event, hint) {
		//   // Check if it is an exception, and if so, show the report dialog
		//   if (event.exception) {
		//     showReportDialog({ eventId: event.event_id });
		//   }
		//   return event;
		// },
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<ErrorBoundary fallback={<Crash />}>
		<App />
	</ErrorBoundary>,
	document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
