import {
	Avatar,
	Box,
	Button,
	createStyles,
	IconButton,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { memo, MouseEvent, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { currencySymbolsDictionary } from "data/dictionaries";
import { IRatings } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { dateParseAndFormat, DATE_FORMATS } from "utils/dates";
import fillRoute from "utils/routes";

import RatingStars from "ui/RatingStars";

import colors from "styles/colors";
import { CurrencyWrapper, RobotoFontFamily, StyledDivider, StyledLink } from "styles/common";
import media from "styles/media";

import SharePopup from "../../Share/components/SharePopup";

const ImgContent = styled(Box)`
	position: relative;
	overflow: hidden;
	border-radius: 4px 4px 0 0;
`;

const Photo = styled(Box)<{ background: string; height: number }>`
	position: relative;
	background-image: url(${props => props.background});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px 4px 0 0;
	width: 100%;
	height: ${props => props.height}px;
	transition: all 0.5s linear;

	&:hover {
		transform: scale(1.1);
	}
`;

const NameLabel = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	color: ${colors.mainFontColor};
`;

const ImageOptions = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 34px;
	height: 34px;
	box-sizing: border-box;
`;

const ActionButton = styled(IconButton)`
	background-color: ${colors.sunShade};
	width: 100%;
	height: 100%;

	&:hover {
		background-color: ${colors.mainFontColor};
	}
`;

const ShareIcon = styled(ReplyIcon)`
	transform: scaleX(-1);
	color: ${colors.white};
`;

const MainTitle = styled.div`
	padding-right: 15px;
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: bold;
	line-height: 1.06;
	letter-spacing: 0.56px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;

	${media.desktop`
    font-size: 16px;
  `};
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recordContent: {
			boxShadow: "0 0 8px 0 rgb(55 71 79 / 17%)",
			borderRadius: 4,
			background: theme.palette.common.white,
			color: theme.palette.text.homePageFont,
		},
		cityHoursText: {
			fontFamily: RobotoFontFamily,
			fontSize: 12,
			color: colors.secondaryGray,
			lineHeight: "20px",
		},
		priceRatingText: {
			fontFamily: RobotoFontFamily,
			fontSize: 12,
			lineHeight: "20px",
		},
		priceWrapperText: {
			fontSize: 26,
		},
		avatar: {
			[theme.breakpoints.down("sm")]: {
				width: theme.spacing(22),
				height: theme.spacing(22),
			},
			width: theme.spacing(33),
			height: theme.spacing(33),
			border: "4px solid white",
		},
		pictureContent: {
			[theme.breakpoints.down("sm")]: {
				height: "400px",
			},
			width: "100%",
			height: "480px",
		},
		button: {
			height: 40,
			color: theme.palette.common.white,
			backgroundColor: "#e65100",
			"&:hover": {
				backgroundColor: "#e65100",
			},
		},
		badge: {
			textTransform: "uppercase",
			backgroundColor: "#e65100",
			color: theme.palette.common.white,
			fontSize: 11,
			fontFamily: RobotoFontFamily,
		},
		icon: {
			marginRight: 10,
		},
	}),
);

const config = {
	standard: {
		content: {
			mobile: {
				width: "100%",
				height: "auto",
			},
			desktop: {
				width: 434,
				height: 513,
			},
		},
		img: {
			mobile: {
				height: 220,
			},
			desktop: {
				height: 285,
			},
		},
	},
};

export interface IRecordUpcomingBooking {
	eid: string;
	avatarUrl?: string;
	city: string;
	hours: string | null;
	imgUrl?: string | null;
	name: string;
	pricePerPerson: number | null;
	ratings: IRatings | null;
	title: string;
	priceCurrency?: string;
	spots: number;
	dateTime: string;
	utcOffset: number;
	localhostId: string | null;
}

const RecordUpcomingBooking = memo(
	({
		eid,
		avatarUrl,
		city,
		hours,
		imgUrl,
		name,
		pricePerPerson,
		ratings,
		title,
		priceCurrency,
		spots,
		dateTime,
		utcOffset,
		localhostId,
	}: IRecordUpcomingBooking) => {
		const classes = useStyles();
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
		const { t } = useTranslation();

		const [showSharePopup, setShowSharePopup] = useState<boolean>(false);

		const boxSize = config.standard;
		const currency = currencySymbolsDictionary[priceCurrency || ""];

		const shareClickHandle = (ev: MouseEvent<HTMLButtonElement>) => {
			ev.preventDefault();

			setShowSharePopup(true);
		};

		const link = fillRoute(paths.SEARCH_EXPERIENCES, {
			title: slugify(title.toLowerCase()),
			id: eid,
			search: { upcomingBooking: true },
		});

		const date = new Date();
		const nowUTC = new Date(
			Date.UTC(
				date.getUTCFullYear(),
				date.getUTCMonth(),
				date.getUTCDate(),
				date.getUTCHours() - utcOffset,
				date.getUTCMinutes(),
				date.getUTCSeconds(),
			),
		);
		// @ts-ignore
		const hoursDifference = Math.abs(nowUTC - new Date(dateTime)) / 36e5;
		let daysDifference;

		if (hoursDifference > 36) {
			daysDifference = hoursDifference / 24;
		}

		let timeLabel = (
			<span>
				Start in <strong>{Math.round(hoursDifference > 36 ? daysDifference : hoursDifference)}</strong>{" "}
				{hoursDifference > 36 ? "days" : "hours"}
			</span>
		);

		if (daysDifference && daysDifference > 7) {
			timeLabel = (
				<span>
					Start on{" "}
					<strong>{dateParseAndFormat(dateTime, DATE_FORMATS.DATE_FORMAT2, DATE_FORMATS.API_FORMAT_DATETIME)}</strong>
				</span>
			);
		}

		return (
			<>
				{showSharePopup && title && link && (
					<SharePopup
						showDialog={showSharePopup}
						setShowDialog={() => setShowSharePopup(false)}
						title={title}
						shareUrl={link}
						addHostName={true}
					/>
				)}

				<Box
					className={classes.recordContent}
					style={{
						width: isSmallScreen ? boxSize.content.mobile.width : boxSize.content.desktop.width,
						height: isSmallScreen ? boxSize.content.mobile.height : boxSize.content.desktop.height,
					}}
				>
					<Box position="relative">
						<StyledLink to={link} $withoutHoverUnderline>
							<ImgContent>
								<Photo
									background={imgUrl || CoverImg}
									height={isSmallScreen ? boxSize.img.mobile.height : boxSize.img.desktop.height}
								/>

								{title && (
									<ImageOptions>
										<ActionButton aria-label="share" onClick={shareClickHandle}>
											<ShareIcon />
										</ActionButton>
									</ImageOptions>
								)}
							</ImgContent>
						</StyledLink>

						{!!localhostId && (
							<StyledLink
								to={fillRoute(paths.PUBLIC_PROFILE, {
									userName: encodeURI(name?.toLowerCase() || ""),
									uid: localhostId,
								})}
								$withoutHoverUnderline
							>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
									flexDirection="column"
									style={{
										position: "absolute",
										bottom: isSmallScreen ? "-90px" : "-80px",
										right: "20px",
									}}
								>
									<Avatar alt={`Avatar Local Host - ${name}`} src={avatarUrl} className={classes.avatar} />
									<NameLabel>{name}</NameLabel>
								</Box>
							</StyledLink>
						)}
					</Box>

					<Box
						display="flex"
						alignItems="center"
						padding="8px"
						paddingTop="4px"
						paddingBottom="4px"
						paddingRight={isSmallScreen ? "70px" : undefined}
						className={classes.badge}
					>
						<TimelapseIcon className={classes.icon} />
						<span>
							{timeLabel}
							{isSmallScreen ? <br /> : <>&nbsp;&nbsp;|&nbsp;&nbsp;</>}There are only <strong>{spots}</strong> spots
							left!
						</span>
					</Box>

					<Box p={10} pt={5}>
						<Box height="75px">
							{ratings && ratings?.count > 0 && (
								<RatingStars
									value={ratings?.average}
									count={ratings?.count}
									detailsRating={ratings?.values}
									size="small"
									withoutModal={!ratings?.values}
								/>
							)}

							<Box width="80%">
								<StyledLink to={link} $withoutHoverUnderline>
									<MainTitle>{title?.length > 40 ? title.slice(0, 40) + "..." : title}</MainTitle>
								</StyledLink>
							</Box>
						</Box>

						<Box display="flex" mb="8px">
							<Typography className={classes.cityHoursText}>{city}</Typography>&nbsp;&nbsp;|&nbsp;&nbsp;
							<Typography className={classes.cityHoursText}>{hours}</Typography>
						</Box>

						<StyledDivider />

						<Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
							<div className={classes.priceRatingText}>
								{t("HP.DISCOVER.SEE_FROM_LABEL")}
								<Box fontSize="26px">
									<strong>
										<CurrencyWrapper>{currency}</CurrencyWrapper>

										{pricePerPerson}
									</strong>
								</Box>
								{t("HP.DISCOVER.SEE_PER_PERSON_LABEL")}
							</div>

							<StyledLink to={link} $withoutHoverUnderline>
								<Button className={classes.button} variant="contained">
									Join now
								</Button>
							</StyledLink>
						</Box>
					</Box>
				</Box>
			</>
		);
	},
);

export default RecordUpcomingBooking;
