import { useState } from "react";
import styled from "styled-components";

import { useScrollPosition } from "hooks/useScrollPosition";

import { getEnvironmentDetails, isDevelopment } from "utils/environments";

const StyledAlert = styled.div<{ visible?: boolean; bg: string }>`
	z-index: 10000;
	position: fixed;
	width: 100%;
	color: white;
	font-weight: 500;
	padding: 5px 15px;
	top: 0;
	margin-top: ${props => (props.visible ? 0 : -25)}px;
	opacity: ${props => (props.visible ? 1 : 0)};
	transition: all 0.5s ease-in-out;
	background: ${props => props.bg};
	line-height: 1;
	overflow: hidden;
	font-size: 12px;

	div {
		white-space: nowrap;
		overflow: hidden;
	}
`;

const canDisplay = isDevelopment && !!process.env.REACT_APP_API_URL;

const Development = () => {
	const [visible, setVisible] = useState<boolean>(true);

	const info = getEnvironmentDetails();

	useScrollPosition(({ prevPos, currPos }) => setVisible(currPos.y > prevPos.y), [visible]);

	if (!canDisplay || !info) {
		return null;
	}

	return (
		<StyledAlert visible={visible} bg={info.color}>
			<div>{new Array(50).fill(process.env.REACT_APP_API_URL + ", " + info.name).join(", ")}</div>
		</StyledAlert>
	);
};

export default Development;
