import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph from "ui/CommonParagraph";
import HelpSection from "ui/HelpSection";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";

const StyledLinkToSection = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xlarge};
	line-height: 2.2;
	letter-spacing: 0.03px;
	font-weight: bold;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const PrivacyPolicyPage = () => {
	const { t, withRaw, withComponents } = useTranslation();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const refAboutTitle = useRef<HTMLDivElement | null>(null);
	const refDefinitionsTitle = useRef<HTMLDivElement | null>(null);
	const refPolicyAppliesTitle = useRef<HTMLDivElement | null>(null);
	const refDataGatherTitle = useRef<HTMLDivElement | null>(null);
	const refInformationTitle = useRef<HTMLDivElement | null>(null);
	const refCommunicationTitle = useRef<HTMLDivElement | null>(null);
	const refInformationSharedTitle = useRef<HTMLDivElement | null>(null);
	const refRetentionTitle = useRef<HTMLDivElement | null>(null);
	const refDataTransfersTitle = useRef<HTMLDivElement | null>(null);
	const refYourRightsTitle = useRef<HTMLDivElement | null>(null);
	const refSecurityTitle = useRef<HTMLDivElement | null>(null);
	const refChildrenTitle = useRef<HTMLDivElement | null>(null);
	const refBusinessTransfersTitle = useRef<HTMLDivElement | null>(null);
	const refChangesPrivacyPolicyTitle = useRef<HTMLDivElement | null>(null);

	const scrollToInfo = currentRef => {
		if (currentRef.current) {
			window.scrollTo({
				behavior: "smooth",
				top: currentRef.current.offsetTop,
			});
		}
	};

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<Breadcrumbs
					list={[
						{
							route: paths.HELP_LEGAL_TERMS,
							label: t("FOOTER.LINKS.LEGAL_TERMS.label"),
						},
					]}
					lastBreadcrumbLabel={t("HELP.PRIVACY.TITLE")}
				/>
				<CommonParagraph
					withMarginTop
					mainTitle={t("HELP.PRIVACY.TITLE")}
					subTitle={t("HELP.PRIVACY.UPDATED_AT")}
					description={withRaw("HELP.PRIVACY.MAIN_DESCRIPTION", null)}
				/>

				<Box
					display="flex"
					justifyContent="space-between"
					marginTop="30px"
					marginBottom="30px"
					flexDirection={isSmallScreen ? "column" : undefined}
				>
					<Box>
						<StyledLinkToSection onClick={() => scrollToInfo(refAboutTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_1.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDefinitionsTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_2.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refPolicyAppliesTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_3.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDataGatherTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_4.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refInformationTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_5.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refCommunicationTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_6.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refInformationSharedTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_7.TITLE")}
						</StyledLinkToSection>
					</Box>

					<Box>
						<StyledLinkToSection onClick={() => scrollToInfo(refRetentionTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_8.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDataTransfersTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_9.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refYourRightsTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_10.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refSecurityTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_11.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refChildrenTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_12.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refBusinessTransfersTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_13.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refChangesPrivacyPolicyTitle)}>
							{withRaw("HELP.PRIVACY.SECTION_14.TITLE")}
						</StyledLinkToSection>
					</Box>
				</Box>

				<HelpSection
					refElement={refAboutTitle}
					title={withRaw("HELP.PRIVACY.SECTION_1.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_1.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refDefinitionsTitle}
					title={withRaw("HELP.PRIVACY.SECTION_2.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_2.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refPolicyAppliesTitle}
					title={withRaw("HELP.PRIVACY.SECTION_3.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_3.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refDataGatherTitle}
					title={withRaw("HELP.PRIVACY.SECTION_4.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_4.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refInformationTitle}
					title={withRaw("HELP.PRIVACY.SECTION_5.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_5.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refCommunicationTitle}
					title={withRaw("HELP.PRIVACY.SECTION_6.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_6.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refInformationSharedTitle}
					title={withRaw("HELP.PRIVACY.SECTION_7.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_7.DESCRIPTION_LEFT")}
					descriptionRight={withRaw("HELP.PRIVACY.SECTION_7.DESCRIPTION_RIGHT")}
				/>

				<HelpSection
					refElement={refRetentionTitle}
					title={withRaw("HELP.PRIVACY.SECTION_8.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_8.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refDataTransfersTitle}
					title={withRaw("HELP.PRIVACY.SECTION_9.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_9.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refYourRightsTitle}
					title={withRaw("HELP.PRIVACY.SECTION_10.TITLE")}
					descriptionLeft={withComponents("HELP.PRIVACY.SECTION_10.DESCRIPTION_LEFT", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
					descriptionRight={withRaw("HELP.PRIVACY.SECTION_10.DESCRIPTION_RIGHT")}
				/>

				<HelpSection
					refElement={refSecurityTitle}
					title={withRaw("HELP.PRIVACY.SECTION_11.TITLE")}
					descriptionLeft={withComponents("HELP.PRIVACY.SECTION_11.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>

				<HelpSection
					refElement={refChildrenTitle}
					title={withRaw("HELP.PRIVACY.SECTION_12.TITLE")}
					descriptionLeft={withComponents("HELP.PRIVACY.SECTION_12.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>

				<HelpSection
					refElement={refBusinessTransfersTitle}
					title={withRaw("HELP.PRIVACY.SECTION_13.TITLE")}
					descriptionLeft={withRaw("HELP.PRIVACY.SECTION_13.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refChangesPrivacyPolicyTitle}
					title={withRaw("HELP.PRIVACY.SECTION_14.TITLE")}
					descriptionLeft={withComponents("HELP.PRIVACY.SECTION_14.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>
			</Container>
		</AppWrapper>
	);
};

export default PrivacyPolicyPage;
