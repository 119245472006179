import { Avatar } from "@material-ui/core";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import { mediaMobileFirst } from "styles/media";

export const Container = styled.div<{ read: boolean; pointer: boolean; compact?: boolean }>`
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	padding: 10px 10px 15px;
	border-radius: 6px;

	background-color: ${props => (props.read ? colors.white : "#B0BEC51A")};
	border: 1px solid ${props => (props.read ? "#b0bec54d" : "#B0BEC51A")};

	${props =>
		!props.compact &&
		mediaMobileFirst.tablet`
    padding: 20px 12px 25px;
  `}

	${props =>
		props.pointer &&
		css`
			cursor: pointer;
		`};
`;

export const StyledAvatar = styled(Avatar)<{ $withPadding: boolean; $compact?: boolean }>`
	background-color: ${colors.white};
	margin-right: 10px;

	${props =>
		!props.$compact &&
		mediaMobileFirst.tablet`
    width: 56px;
    height: 56px;
   `}

	${props =>
		props.$withPadding &&
		css`
			padding: 6px;

			${!props.$compact &&
			mediaMobileFirst.tablet`
        padding: 10px;
      `}
		`};
`;

export const Title = styled.div<{ compact?: boolean }>`
	font-family: ${RobotoFontFamily};
	color: ${colors.mainFontColor};
	font-size: 14px;
	line-height: 1.36;
	letter-spacing: 0.44px;

	${props =>
		!props.compact &&
		mediaMobileFirst.tablet`
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.5px;
  `}
`;

export const SubTitle = styled(Title)<{ compact?: boolean }>`
	font-weight: bold;
	font-size: 12px;

	${props =>
		!props.compact &&
		mediaMobileFirst.tablet`
    font-size: 14px;
  `}
`;

export const Sent = styled(Title)`
	margin-left: 8px;
	color: #607d8b;
`;

export const Description = styled.div<{ compact?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 10px;
	color: #455a64;
	line-height: 2;

	${props =>
		!props.compact &&
		mediaMobileFirst.tablet`
    font-size: 12px;
    line-height: 1.67;
  `}
`;

export const ReadIndicator = styled.div<{ read: boolean }>`
	background-color: #2632380d;
	width: 20px;
	min-width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-left: 25px;
	cursor: pointer;
	position: relative;

	${props =>
		!props.read &&
		css`
			&::after {
				content: "";
				background-color: ${colors.sunShade};
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		`};
`;
