import { Box } from "@material-ui/core";
import styled from "styled-components";

import Logo from "ui/Logo";

interface IAnimationFlyingElement {
	animationDelay?: string;
	height?: string;
	leftMargin?: string;
	topMargin?: string;
	toTheLeft?: boolean;
	width?: string;
	versionAnimation?: "first" | "second" | "third";
}

const Wrapper = styled(Box)<{ $animationDelay: string; $topMargin: string; $leftMargin: string; $rotation: string }>`
	position: absolute;
	width: 200px;
	height: 200px;
	top: ${props => props.$topMargin};
	left: ${props => props.$leftMargin};
	margin: 0;
	animation: mainAnimation 6s linear infinite;
	animation-delay: ${props => props.$animationDelay};

	@keyframes mainAnimation {
		100% {
			transform: rotate(${props => props.$rotation}deg);
		}
	}
`;

const FlyingBee = styled(Box)<{ version: any }>`
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	animation: beeAnimation 3s ${props => paths[props.version]} infinite;

	@keyframes beeAnimation {
		50% {
			transform: translateY(-100px);
		}
	}
`;

const paths = {
	first: "cubic-bezier(0.02, 0.01, 0.31, 1)",
	second: "cubic-bezier(0.00, 0.01, 0.31, 0.8, 1)",
	third: "cubic-bezier(0.00, 0.2, 0.9, 1)",
};

const AnimationFlyingElement = ({
	toTheLeft = false,
	topMargin = "0",
	leftMargin = "0",
	animationDelay = "initial",
	height = "20px",
	width = "20px",
	versionAnimation = "first",
}: IAnimationFlyingElement) => (
	<Wrapper
		$topMargin={topMargin}
		$leftMargin={leftMargin}
		$animationDelay={animationDelay}
		$rotation={toTheLeft ? "360" : "-360"}
	>
		<FlyingBee
			display="flex"
			alignItems="center"
			justifyContent="center"
			height={height}
			width={width}
			version={versionAnimation}
		>
			<Logo version="localbini" />
		</FlyingBee>
	</Wrapper>
);

export default AnimationFlyingElement;
