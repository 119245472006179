export const getSharedUrl = (url?: string, withScheme?: boolean, addHostName?: boolean) => {
	const withSchemeLocal = withScheme || true;
	let link = url || window.location.href;

	if (!!url && addHostName) {
		link = window.location.protocol + "//" + window.location.host + link;
	}

	if (withSchemeLocal) {
		return link;
	}

	return link.replace(/(^\w+:|^)\/\//, "");
};
