import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { IExperienceDTO } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { getParams } from "utils/urls";

import dimensions from "styles/dimensions";

import Item from "./Item";

const listItemsOffset = 20;

export interface ListProps {
	showLoadMore?: boolean;
	expCount: number;
	experiencesList: IExperienceDTO[];
	isLoading: boolean;
}

const Wrapper = styled.div`
	margin: 0 auto 30px;
`;

const ShowMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: ${dimensions.spaces.xlarge} 0 0;
`;

const List = ({ showLoadMore, expCount, experiencesList, isLoading }: ListProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const initialQueryParams = getParams(new URLSearchParams(location.search));
	const parsedQueryParams: any = { ...initialQueryParams };

	const loadMoreExperiences = () => {
		const currentOffset = parsedQueryParams.offset ? parseInt(parsedQueryParams.offset, 10) : 0;
		if (currentOffset + listItemsOffset <= expCount) {
			const queryURL = new URLSearchParams({ ...parsedQueryParams, offset: currentOffset + listItemsOffset });
			if (Array.isArray(parsedQueryParams.categories)) {
				queryURL.delete("categories");

				parsedQueryParams.categories.forEach(elem => {
					queryURL.append("categories", elem);
				});
			}

			if (Array.isArray(parsedQueryParams.languages)) {
				queryURL.delete("languages");

				parsedQueryParams.languages.forEach(elem => {
					queryURL.append("languages", elem);
				});
			}

			history.push({ pathname: location.pathname, search: queryURL.toString() });
		}
	};

	return (
		<>
			<Wrapper>
				{experiencesList.map(exp => (
					<Item key={exp.id} {...exp} />
				))}
			</Wrapper>

			{showLoadMore ? (
				<ShowMoreWrapper>
					<Button
						disabled={isLoading}
						onClick={loadMoreExperiences}
						style={{
							padding: 10,
						}}
						variant="contained"
						color="primary"
						type="button"
					>
						{t("SEARCH.EXPERIENCES.SHOW_MORE")}
					</Button>
				</ShowMoreWrapper>
			) : null}
		</>
	);
};

export default List;
