import * as Yup from "yup";

import useTranslation from "hooks/useTranslation";

const useValidate = () => {
	const { t } = useTranslation();

	const requiredMessage = t("ERRORS.REQUIRED");

	return Yup.object().shape({
		username: Yup.string().email(t("AUTH.LOGIN.INVALID_EMAIL")).required(requiredMessage),
		password: Yup.string().required(requiredMessage),
	});
};

export default useValidate;
