import { IPartner } from "data/backoffice/partners/types";
import { DeepPartial } from "data/types";

export interface ICurrentPartner extends DeepPartial<IPartner> {
	id: string;
	name?: string;
	logo?: string;
}

class CurrentPartner {
	partner;

	constructor(p?: ICurrentPartner) {
		this.partner = p;
	}

	isActive(): boolean {
		return !!this.partner;
	}

	hasLogo(): boolean {
		return !!this.logo;
	}

	canDisplayLogo(): boolean {
		return this.isActive() && this.hasLogo();
	}

	get logo() {
		return (
			this.partner?.logo ||
			(process.env.NODE_ENV !== "production" && `https://via.placeholder.com/160x34.png?text=${this?.partner?.name}`)
		);
	}

	get id() {
		return this.partner?.id;
	}
}

export default CurrentPartner;
