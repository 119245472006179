import { Box, Grid, Switch } from "@material-ui/core";
import { throttle } from "lodash-es";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ENotificationCategory, ENotificationRecipient, INotification } from "data/notifications/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import notificationsService from "services/api/notifications";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectNotificationsBackoffice } from "store/selectors/backoffice/notifications";
import { fetchUnreadBackofficeNotifications } from "store/slices/backoffice/notifications";

import { localStorageKeys } from "utils/constants";
import { getDateWithDefaultTime, secondsToOtherFormats } from "utils/dates";
import fillRoute from "utils/routes";
import { getParams } from "utils/urls";

import Loader from "components/loaders/Loader";

import FilterButton from "ui/buttons/FilterButton";
import { Wrapper } from "ui/FilterSearch";
import NotificationRecord from "ui/NotificationRecord";

import InnerTableLoader from "../components/InnerTableLoader";
import { ETableLoaders, listEndOffset, listItemsOffset, scrollThrottleTime, TLoadersState } from "../config";

interface IUrlQueryParams {
	offset?: number;
	category?: ENotificationCategory;
	min_created_on?: string;
	max_created_on?: string;
	marked_as_read?: string;
	recipient_type?: ENotificationRecipient | ENotificationRecipient[];
}

export const allowedRecipientTypes = [ENotificationRecipient.STAFF];

const NotificationsPage = () => {
	const history = useHistory();
	const location = useLocation();

	const { t, withRaw } = useTranslation("bo");

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const notificationsBackoffice = useAppSelector(selectNotificationsBackoffice);

	const [results, setResults] = useState<{
		count?: number;
		data?: INotification[];
	}>();
	const [page, setPage] = useState<number>(1);
	const [loader, setLoader] = useState<TLoadersState>(ETableLoaders.GLOBAL);
	const [loadMore, setLoadMore] = useState<boolean>(false);

	const preventLoading = useRef<boolean>(false);
	const scrollContainer = useRef<Element>();
	const scrollPosition = useRef<number>(0);

	const initialQueryParams: IUrlQueryParams = getParams(new URLSearchParams(location.search));

	const fetchData = async (reset: boolean, offset?: number) => {
		setLoader(reset ? ETableLoaders.GLOBAL : ETableLoaders.INNER);

		const parsedQueryParams: IUrlQueryParams = { ...initialQueryParams };

		if (offset !== undefined) {
			parsedQueryParams.offset = offset;
		}

		// Must be always!
		parsedQueryParams.recipient_type = allowedRecipientTypes;

		try {
			const response = await notificationsService.getSearchNotifications(parsedQueryParams);

			setResults(prevState =>
				reset
					? { count: response.count, data: response.data }
					: {
							count: response.count,
							data: !!prevState?.data ? [...prevState.data, ...response.data] : response.data,
					  },
			);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			preventLoading.current = false;

			setLoader(false);

			if (scrollContainer.current && scrollPosition.current) {
				setTimeout(() => {
					// @ts-ignore
					scrollContainer.current.scrollTo(0, scrollPosition.current);
				}, 0);
			}
		}
	};

	const fetchDataWithReset = () => {
		fetchData(true);

		setPage(1);
		setLoadMore(false);

		preventLoading.current = false;
	};

	useLayoutEffect(() => {
		const mainContainerNode = document.querySelector(".backoffice-main-view");

		const handleScroll = throttle(() => {
			if (mainContainerNode) {
				scrollContainer.current = mainContainerNode;
				scrollPosition.current = mainContainerNode.scrollTop;

				if (window.innerHeight + mainContainerNode.scrollTop >= mainContainerNode.scrollHeight - listEndOffset) {
					if (!preventLoading.current) {
						setLoadMore(true);

						preventLoading.current = true;
					}
				}
			}
		}, scrollThrottleTime);

		mainContainerNode?.addEventListener("scroll", handleScroll);

		return () => {
			mainContainerNode?.removeEventListener("scroll", handleScroll);
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (
			!!window.localStorage.getItem(localStorageKeys.notifications.showOnlyUnread.key) &&
			!initialQueryParams.marked_as_read
		) {
			// @ts-ignore
			const queryURL = new URLSearchParams(initialQueryParams);

			queryURL.append("marked_as_read", "false");

			history.push({ pathname: location.pathname, search: queryURL.toString() });
		} else {
			fetchDataWithReset();
		}

		// eslint-disable-next-line
	}, [location.search]);

	useEffect(() => {
		if (loadMore) {
			if (page * listItemsOffset <= (results?.count || 0)) {
				fetchData(false, page * listItemsOffset);

				setPage(prevState => prevState + 1);
				setLoadMore(false);
			}
		}

		// eslint-disable-next-line
	}, [loadMore]);

	const markAllAsRead = async () => {
		setLoader(ETableLoaders.GLOBAL);

		const parsedQueryParams: IUrlQueryParams = { ...initialQueryParams };

		delete parsedQueryParams.offset;

		// Must be always!
		parsedQueryParams.recipient_type = allowedRecipientTypes;

		try {
			await notificationsService.postMarkNotificationsAsRead(parsedQueryParams);

			dispatch(fetchUnreadBackofficeNotifications());

			fetchDataWithReset();
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const markAsRead = async (id: string, read: boolean) => {
		if (results?.data) {
			const newResults = [...results.data];

			const objIndex = newResults.findIndex(obj => obj.id === id);

			try {
				newResults[objIndex].marked_as_read = read;

				setResults(prevState => ({
					...prevState,
					data: newResults,
				}));

				await notificationsService.patchUpdateNotification(id, read);

				dispatch(fetchUnreadBackofficeNotifications());
			} catch (e) {
				handleAndNotify(e);

				newResults[objIndex].marked_as_read = !read;

				setResults(prevState => ({
					...prevState,
					data: newResults,
				}));
			}
		}
	};

	const callFilters = (v: string) => {
		if (v === "MARK_ALL_READ") {
			markAllAsRead();

			return;
		}

		// @ts-ignore
		const queryURL = new URLSearchParams(initialQueryParams);

		if (
			v === ENotificationCategory.BOOKING ||
			v === ENotificationCategory.EXPERIENCE ||
			v === ENotificationCategory.RATING
		) {
			queryURL.delete("category");

			queryURL.append("category", v);
		}

		if (v === "ALL") {
			queryURL.delete("category");
		}

		if (v === "TODAY") {
			if (initialQueryParams.min_created_on && initialQueryParams.max_created_on) {
				queryURL.delete("min_created_on");
				queryURL.delete("max_created_on");
			} else {
				queryURL.append("min_created_on", getDateWithDefaultTime(new Date(), "FROM"));
				queryURL.append("max_created_on", getDateWithDefaultTime(new Date(), "TO"));
			}
		}

		if (v === "ONLY_SHOW_UNREAD") {
			if (initialQueryParams.marked_as_read) {
				queryURL.delete("marked_as_read");

				window.localStorage.removeItem(localStorageKeys.notifications.showOnlyUnread.key);
			} else {
				queryURL.append("marked_as_read", "false");

				window.localStorage.setItem(
					localStorageKeys.notifications.showOnlyUnread.key,
					localStorageKeys.notifications.showOnlyUnread.defaultValue,
				);
			}
		}

		history.push({ pathname: location.pathname, search: queryURL.toString() });
	};

	return (
		<>
			<Box marginBottom="20px">
				<Wrapper>
					<Box display="flex" justifyContent="space-between" flexWrap="wrap" gridGap="40px">
						<Box display="flex" flexWrap="wrap" gridGap="10px" alignItems="center">
							<Box marginRight="10px">
								<FilterButton
									isChecked={!initialQueryParams.category}
									nameFilter="ALL"
									filterValue={notificationsBackoffice.unreadBackofficeNotifications?.statistics.total || undefined}
									handleClickFilter={v => callFilters(v)}
									label={t("BO.NOTIFICATIONS.FILTERS.ALL")}
									compactWidth
									withBoxShadow={false}
								/>
							</Box>

							{Object.values(ENotificationCategory).map(elem => (
								<FilterButton
									key={elem}
									isChecked={initialQueryParams.category === elem}
									nameFilter={elem}
									filterValue={notificationsBackoffice.unreadBackofficeNotifications?.statistics.by_category?.[elem]}
									handleClickFilter={v => callFilters(v)}
									label={t(`BO.NOTIFICATIONS.FILTERS.${elem}`)}
									compactWidth
									withBoxShadow={false}
								/>
							))}
						</Box>

						<Box display="flex" flexWrap="wrap" gridGap="20px" alignItems="center">
							<FilterButton
								isChecked={!!initialQueryParams.min_created_on && !!initialQueryParams.max_created_on}
								nameFilter="TODAY"
								handleClickFilter={v => callFilters(v)}
								label={t("BO.NOTIFICATIONS.FILTERS.TODAY")}
								compactWidth
								withBoxShadow={false}
							/>

							<FilterButton
								nameFilter="MARK_ALL_READ"
								handleClickFilter={v => callFilters(v)}
								label={t("BO.NOTIFICATIONS.FILTERS.MARK_ALL_READ")}
								compactWidth
								withBoxShadow={false}
							/>

							<Box border="1px solid #e0e0e0" height="30px" borderRadius="4px" paddingX="10px">
								<Grid component="label" container alignItems="center" spacing={1} style={{ cursor: "pointer" }}>
									<Grid item>
										<Box fontSize="12px" lineHeight="28px">
											{t("BO.NOTIFICATIONS.FILTERS.ONLY_SHOW_UNREAD")}
										</Box>
									</Grid>

									<Grid item>
										<Switch
											checked={initialQueryParams.marked_as_read === "false"}
											size="small"
											onChange={() => callFilters("ONLY_SHOW_UNREAD")}
										/>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Box>
				</Wrapper>
			</Box>

			<Box>
				{loader === ETableLoaders.GLOBAL ? (
					<Loader />
				) : (
					<Box position="relative">
						{results?.data?.length === 0 && (
							<Box position="absolute" left="0" right="0" margin="auto" textAlign="center" top="200px">
								<Box fontWeight="bold" fontSize="24px" marginBottom="10px">
									{t(
										initialQueryParams.marked_as_read === "false"
											? "BO.NOTIFICATIONS.NO_RESULTS.TITLE.ONLY_UNREAD"
											: "BO.NOTIFICATIONS.NO_RESULTS.TITLE",
									)}
								</Box>

								<Box fontSize="16px">
									{withRaw(
										initialQueryParams.marked_as_read === "false"
											? "BO.NOTIFICATIONS.NO_RESULTS.SUB_TITLE.ONLY_UNREAD"
											: "BO.NOTIFICATIONS.NO_RESULTS.SUB_TITLE",
									)}
								</Box>
							</Box>
						)}

						{results?.data?.map(elem => (
							<NotificationRecord
								key={elem.id}
								title={elem.title}
								description={elem.message}
								subTitle={elem.subject_name}
								sent={
									secondsToOtherFormats(
										Math.round(
											(new Date().getTime() +
												new Date().getTimezoneOffset() * 60000 -
												new Date(elem.created_on).getTime()) /
												1000,
										),
										true,
										true,
									) + " ago"
								}
								read={elem.marked_as_read}
								makeRead={() => markAsRead(elem.id, !elem.marked_as_read)}
								click={() => {
									markAsRead(elem.id, true);

									history.push(fillRoute(paths.BACKOFFICE_REDIRECT, { id: elem.subject_id }));
								}}
							/>
						))}

						{loader === ETableLoaders.INNER && <InnerTableLoader />}
					</Box>
				)}
			</Box>
		</>
	);
};

export default NotificationsPage;
