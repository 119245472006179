import { IPartner } from "data/backoffice/partners/types";

import { APISingleData } from "./index";

const namespace = "internal";
const api = "/partners";

const partnersService = {
	getBySubdomain: (subdomain: string) => APISingleData.get<IPartner>(namespace, `${api}/by-subdomain/${subdomain}`),
};

export default partnersService;
