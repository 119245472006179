import { Helmet } from "react-helmet-async";
import { Route, useLocation } from "react-router-dom";

import { EUserType } from "data/users/types";

import { RouteResolverProps } from "../types";
import LayoutComponent from "./LayoutComponent";
import ProtectedRoute from "./ProtectedRoute";

const RouteResolver = ({
	userData,
	isProtected,
	isAnonymous,
	onlyForStaff,
	Component,
	...route
}: RouteResolverProps) => {
	const location = useLocation();

	if (isProtected || isAnonymous) {
		return (
			<Route
				{...route}
				render={routerProps => (
					<>
						<Helmet>
							<meta name="robots" content="noindex, follow" />
						</Helmet>

						<ProtectedRoute
							expected={isProtected || !isAnonymous}
							isAuthenticated={
								!!userData &&
								(onlyForStaff
									? (
											[EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT] as (
												| EUserType
												| undefined
											)[]
									  ).includes(userData?.userType)
									: true)
							}
							Component={Component}
							routerProps={routerProps}
						/>
					</>
				)}
			/>
		);
	}

	return (
		<Route
			{...route}
			render={routerProps => (
				<>
					<Helmet>
						<meta name="robots" content="index, follow" />
						<link
							rel="canonical"
							// @todo:fix - environment file and mainDomain
							href={(process.env.REACT_APP_SITE_URL || "https://localbini.com") + location.pathname}
						/>
					</Helmet>
					<LayoutComponent Component={Component} routerProps={routerProps} />
				</>
			)}
		/>
	);
};

export default RouteResolver;
