import { isEmpty } from "lodash-es";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import ratingsService from "services/api/ratings";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectGuest } from "store/selectors/user";
import { fetchGuestAuthCredentials, userActions } from "store/slices/users";

import fillRoute from "utils/routes";

import ActionButton from "ui/buttons/ActionButton";

interface IRateBookingButton {
	bookingId: string;
	token?: string | null;
}

const RateBookingButton = ({ bookingId, token }: IRateBookingButton) => {
	const { t } = useTranslation();

	const history = useHistory();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const { data: guestCredentials, status: guestCredentialsStatus } = useAppSelector(selectGuest);

	const [ratingExist, setRatingExist] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(true);

	const componentUniqueId = RateBookingButton.toString();

	const fetchRating = async (withGuestCredentials?: boolean) => {
		try {
			setLoader(true);

			const ratingState = await ratingsService.getRatingByBooking(bookingId, withGuestCredentials);

			// === 0 - for negative NPS rating
			if (
				!!ratingState &&
				(Object.entries(ratingState).every(([_, values]) => !isEmpty(values)) || ratingState?.nps?.answer === 0)
			) {
				setRatingExist(true);
			}

			setLoader(false);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(fetchGuestAuthCredentials({ token, trigger: componentUniqueId }));
		} else {
			fetchRating();
		}

		// eslint-disable-next-line
	}, [bookingId, token]);

	useEffect(() => {
		if (guestCredentialsStatus === "success" && guestCredentials?.trigger === componentUniqueId) {
			dispatch(userActions.resetGuestStatus());

			fetchRating(true);
		}

		// eslint-disable-next-line
	}, [guestCredentialsStatus]);

	return (
		<ActionButton
			isAction={loader}
			isDisabled={loader || ratingExist}
			variant={ratingExist ? "outlined" : "contained"}
			color="primary"
			onClick={() =>
				history.push(
					fillRoute(paths.RATINGS, {
						booking_id: bookingId,
						step: null,
						...(token && { search: { token } }),
					}),
				)
			}
			translationDefault={
				ratingExist
					? t("MY_BOOKINGS.RATE_BOOKING") + t("MY_BOOKINGS.RATED_BOOKING_TEXT")
					: t("MY_BOOKINGS.RATE_BOOKING")
			}
			translationAction="COMMON.LOADING"
		/>
	);
};

export default RateBookingButton;
