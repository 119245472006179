import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph from "ui/CommonParagraph";
import HelpSection from "ui/HelpSection";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";

const StyledLinkToSection = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	line-height: 2.2;
	letter-spacing: 0.03px;
	font-weight: bold;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const TermsServicePage = () => {
	const { t, withRaw, withComponents } = useTranslation();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const refAboutTitle = useRef<HTMLDivElement | null>(null);
	const refDefinitionsTitle = useRef<HTMLDivElement | null>(null);
	const refWhoCanUseLocalbini = useRef<HTMLDivElement | null>(null);
	const refComplianceWithLocalLaws = useRef<HTMLDivElement | null>(null);
	const refYourRightsTitle = useRef<HTMLDivElement | null>(null);
	const refYourAccountTitle = useRef<HTMLDivElement | null>(null);
	const refWaysToLoginTitle = useRef<HTMLDivElement | null>(null);
	const refLoginViaThirdPartyIdenifyProvider = useRef<HTMLDivElement | null>(null);
	const refFinanceLocalbiniTitle = useRef<HTMLDivElement | null>(null);
	const refCurrenciesTitle = useRef<HTMLDivElement | null>(null);
	const refDonatingYourEarningsTitle = useRef<HTMLDivElement | null>(null);
	const refPaymentsWithdrawalsTitle = useRef<HTMLDivElement | null>(null);
	const refResponsibilityOfLocalbiniTitle = useRef<HTMLDivElement | null>(null);
	const refQualitiOfServiceTitle = useRef<HTMLDivElement | null>(null);
	const refNoEndorsementTitle = useRef<HTMLDivElement | null>(null);
	const refYouShoulDoTitle = useRef<HTMLDivElement | null>(null);
	const refStuffAreNorResponsibleTitle = useRef<HTMLDivElement | null>(null);
	const refBookingCancellationTitle = useRef<HTMLDivElement | null>(null);
	const refDisclaimersTitle = useRef<HTMLDivElement | null>(null);
	const refAdditionalProvisionsForBiniblogTitle = useRef<HTMLDivElement | null>(null);
	const refIndemnificationTitle = useRef<HTMLDivElement | null>(null);
	const refLinkToThirdPartySitesTitle = useRef<HTMLDivElement | null>(null);
	const refYouGrantLoclbiniRightsTitle = useRef<HTMLDivElement | null>(null);
	const refUpdatesToTheseTermsTitle = useRef<HTMLDivElement | null>(null);
	const refInvalityOfProvisionTitle = useRef<HTMLDivElement | null>(null);
	const refGoveringLawTitle = useRef<HTMLDivElement | null>(null);

	const scrollToInfo = currentRef => {
		if (currentRef.current) {
			window.scrollTo({
				behavior: "smooth",
				top: currentRef.current.offsetTop,
			});
		}
	};

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<Breadcrumbs
					list={[
						{
							route: paths.HELP_LEGAL_TERMS,
							label: t("FOOTER.LINKS.LEGAL_TERMS.label"),
						},
					]}
					lastBreadcrumbLabel={t("HELP.LEGAL_TERMS.TITLE")}
				/>
				<CommonParagraph
					withMarginTop
					mainTitle={t("HELP.LEGAL_TERMS.TITLE")}
					subTitle={t("HELP.LEGAL_TERMS.UPDATED_AT")}
					description={withRaw("HELP.LEGAL_TERMS.MAIN_DESCRIPTION", null)}
				/>

				<Box
					display="flex"
					justifyContent="space-between"
					marginTop="30px"
					marginBottom="30px"
					flexDirection={isSmallScreen ? "column" : undefined}
				>
					<Box>
						<StyledLinkToSection onClick={() => scrollToInfo(refAboutTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_1.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDefinitionsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_2.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refWhoCanUseLocalbini)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_3.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refComplianceWithLocalLaws)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_4.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refYourRightsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_5.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refYourAccountTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_6.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refWaysToLoginTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_7.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refLoginViaThirdPartyIdenifyProvider)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_8.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refFinanceLocalbiniTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_9.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refCurrenciesTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_10.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDonatingYourEarningsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_11.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refPaymentsWithdrawalsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_12.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refResponsibilityOfLocalbiniTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_13.TITLE")}
						</StyledLinkToSection>
					</Box>

					<Box>
						<StyledLinkToSection onClick={() => scrollToInfo(refQualitiOfServiceTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_14.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refNoEndorsementTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_15.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refYouShoulDoTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_16.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refStuffAreNorResponsibleTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_17.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refBookingCancellationTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_18.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refDisclaimersTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_19.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refAdditionalProvisionsForBiniblogTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_20.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refIndemnificationTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_21.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refLinkToThirdPartySitesTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_22.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refYouGrantLoclbiniRightsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_23.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refUpdatesToTheseTermsTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_24.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refInvalityOfProvisionTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_25.TITLE")}
						</StyledLinkToSection>

						<StyledLinkToSection onClick={() => scrollToInfo(refGoveringLawTitle)}>
							{withRaw("HELP.LEGAL_TERMS.SECTION_26.TITLE")}
						</StyledLinkToSection>
					</Box>
				</Box>

				<HelpSection
					refElement={refAboutTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_1.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_1.DESCRIPTION", {
						LinkCancellationPolicy: (
							<StyledLink $color={colors.sunShade} $decoration="underline" to={paths.HELP_CANCELLATION_POLICY} />
						),
						LinkCookiePolicy: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.HELP_COOKIES} />,
						LinkPrivacyPolicy: (
							<StyledLink $color={colors.sunShade} $decoration="underline" to={paths.HELP_PRIVACY_POLICY} />
						),
					})}
				/>

				<HelpSection
					refElement={refDefinitionsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_2.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_2.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>

				<HelpSection
					refElement={refWhoCanUseLocalbini}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_3.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_3.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>

				<HelpSection
					refElement={refComplianceWithLocalLaws}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_4.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_4.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refYourRightsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_5.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_5.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refYourAccountTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_6.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_6.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refWaysToLoginTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_7.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_7.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refLoginViaThirdPartyIdenifyProvider}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_8.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_8.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refFinanceLocalbiniTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_9.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_9.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refCurrenciesTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_10.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_10.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refDonatingYourEarningsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_11.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_11.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refPaymentsWithdrawalsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_12.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_12.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refResponsibilityOfLocalbiniTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_13.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_13.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refQualitiOfServiceTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_14.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_14.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refNoEndorsementTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_15.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_15.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refYouShoulDoTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_16.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_16.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refStuffAreNorResponsibleTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_17.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_17.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refBookingCancellationTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_18.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_18.DESCRIPTION", {
						LinkCancellation: (
							<StyledLink $color={colors.sunShade} $decoration="underline" to={paths.HELP_CANCELLATION_POLICY} />
						),
					})}
				/>

				<HelpSection
					refElement={refDisclaimersTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_19.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_19.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refAdditionalProvisionsForBiniblogTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_20.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_20.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refIndemnificationTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_21.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_21.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refLinkToThirdPartySitesTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_22.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_22.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refYouGrantLoclbiniRightsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_23.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_23.DESCRIPTION", {
						LinkPrivacyPolicy: (
							<StyledLink $color={colors.sunShade} $decoration="underline" to={paths.HELP_PRIVACY_POLICY} />
						),
					})}
				/>

				<HelpSection
					refElement={refUpdatesToTheseTermsTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_24.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_24.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refInvalityOfProvisionTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_25.TITLE")}
					descriptionLeft={withRaw("HELP.LEGAL_TERMS.SECTION_25.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refGoveringLawTitle}
					title={withRaw("HELP.LEGAL_TERMS.SECTION_26.TITLE")}
					descriptionLeft={withComponents("HELP.LEGAL_TERMS.SECTION_26.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>
			</Container>
		</AppWrapper>
	);
};

export default TermsServicePage;
