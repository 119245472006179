import { Box, Button, Grid } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import styled from "styled-components";

import { MontserratFontFamily, RobotoFontFamily } from "styles/common";
import media from "styles/media";

export const StyledLastMinuteBox = styled(Box)`
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0 0 40px 0 #cecece;
	border: solid 1px #e65100;
	margin-bottom: 20px;
`;

export const BoxHeader = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	line-height: 1.68;
	color: #e65100;
	font-weight: 500;
	margin-bottom: 12px;
`;

export const BoxRow = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
`;

export const StyledDoneIcon = styled(DoneIcon)`
	color: #e65100;
	margin-right: 15px;
`;

export const StyledButton = styled(Button)<{ $fullWidth?: boolean; $withoutMargin?: boolean }>`
	background-color: #e65100;
	color: white;
	width: ${props => (props.$fullWidth ? "100%" : "90px")};
	margin-top: ${props => (props.$withoutMargin ? 0 : "10px")};

	&:hover {
		background-color: #e65100;
	}
`;

export const StyledButtonMobile = styled(Button)`
	background-color: white;
	color: #e65100;
	width: 100px;
	height: 30px;

	&:hover {
		background-color: white;
	}
`;

export const ModalInnerContainer = styled(Grid)`
	min-width: 550px;
	row-gap: 10px;

	${media.tabletMaterial`
     min-width: unset;
  `};
`;
