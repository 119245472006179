import { Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "formik";
import ReactCodeInput, { ReactCodeInputProps } from "react-code-input";
import styled from "styled-components";

import colors from "styles/colors";

interface ICodeInput extends ReactCodeInputProps {
	name: string;
	className?: string;
	label?: string;
	value?: string;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const codeInputStyle = {
	margin: "4px",
	width: "30px",
	borderRadius: "4px",
	fontSize: "14px",
	height: "40px",
	paddingLeft: "7px",
	backgroundColor: "#f2f2f2",
	color: "black",
	border: `1px solid ${colors.borderGray}`,
	outlineColor: orange[400],
};

const CodeInput = ({ name, label, className, ...otherProps }: ICodeInput) => (
	<Field name={name}>
		{({ field, form: { setFieldValue }, meta }) => {
			const hasError = meta.touched && !!meta.error;
			return (
				<Wrapper className={className}>
					{label && <Typography>{label}</Typography>}
					<ReactCodeInput
						inputStyle={codeInputStyle}
						type="text"
						name={field.name}
						onChange={text => setFieldValue(field.name, text)}
						value={field.value}
						{...otherProps}
					/>

					{hasError && <FormHelperText error>{meta.error}</FormHelperText>}
				</Wrapper>
			);
		}}
	</Field>
);

export default CodeInput;
