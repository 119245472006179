import { Box, Divider, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { format } from "date-fns";
import { memo } from "react";
import styled from "styled-components";

import BlogIcon from "assets/icons/footer/blog.svg";
import FBIcon from "assets/icons/footer/fb.svg";
import InstagramIcon from "assets/icons/footer/instagram.svg";
// import TikTokIcon from "assets/icons/footer/tiktok.svg";
import LinkedinIcon from "assets/icons/footer/linkedin.svg";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import Logo from "ui/Logo";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink as CommonStyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

interface IFooterLandingPageProps {
	isDivider?: boolean;
}

const StyledLink = styled(CommonStyledLink)`
	font-size: ${dimensions.fontSize.medium};
	color: ${colors.mainFontColor};
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const Wrapper = styled.footer`
	width: 100%;
	background: ${colors.white};
`;

const InnerWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
	font-family: ${MontserratFontFamily};

	${media.desktop`
     flex-direction: column;
  `};
`;

const Col = styled.div`
	flex: 1;
`;

const Title = styled.span`
	display: block;
	margin: 10px 0;
	font-size: 12px;
	font-weight: bold;

	letter-spacing: 0.02px;
	text-transform: uppercase;
`;

const Text = styled.div`
	font-size: ${dimensions.fontSize.medium};
	font-weight: 400;
	line-height: 1.7;
	letter-spacing: 0.02px;
`;

const StyledDivider = styled(Divider)`
	border: 1px solid #dde7f2;
	margin-bottom: 2.813rem;
`;

const FooterLandingPage = memo(({ isDivider = true }: IFooterLandingPageProps) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { t } = useTranslation();

	return (
		<Wrapper>
			<Box pb={10} pl={isSmallScreen ? 5 : 0} pr={isSmallScreen ? 5 : 0}>
				<Container>
					{isDivider && <StyledDivider />}

					<Logo version="localbiniBig" />

					<InnerWrapper>
						<Col>
							<Title>LocalBini AG</Title>

							<Text>Poststrasse 4, 9000 St. Gallen,</Text>

							<Text>Switzerland</Text>
						</Col>

						<Col>
							<Title>{t("FOOTER.ABOUT_US")}</Title>

							<Text>{t("FOOTER.ABOUT_US.ITEM1")}</Text>

							<Text>{t("FOOTER.ABOUT_US.ITEM2")}</Text>
						</Col>

						<Col>
							<Title>{t("FOOTER.LEGAL")}</Title>

							<Text>
								<CommonStyledLink to={paths.HELP_PRIVACY_POLICY} $color={colors.mainFontColor}>
									{t("FOOTER.LEGAL.ITEM1")}
								</CommonStyledLink>
							</Text>

							<Text>
								<CommonStyledLink to={paths.HELP_DISCLAIMER_IMPRESSUM} $color={colors.mainFontColor}>
									{t("FOOTER.LEGAL.ITEM2")}
								</CommonStyledLink>
							</Text>

							<Text>
								<CommonStyledLink
									to={{ pathname: paths.HELP_CANCELLATION_POLICY, hash: "#localhost-cancellation-policy" }}
									$color={colors.mainFontColor}
								>
									{t("HELP.CANCELLATION.TITLE")}
								</CommonStyledLink>
							</Text>
						</Col>
					</InnerWrapper>
				</Container>
			</Box>

			<Box
				pt={10}
				pb={10}
				pl={isSmallScreen ? 5 : 0}
				pr={isSmallScreen ? 5 : 0}
				style={{ background: theme.palette.background.greyBackground }}
			>
				<Container>
					<Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="flex-start"
							flexDirection="column"
							width={isSmallScreen ? "100%" : "initial"}
						>
							<Typography variant="body1">
								<StyledLink to={paths.HOMEPAGE}>© {format(new Date(), "Y")} LocalBini AG</StyledLink>
								&nbsp;|&nbsp;&nbsp;
								<StyledLink to={paths.HELP_DISCLAIMER_IMPRESSUM}>{t("FOOTER.LINKS.DISCLAIMER.label")}</StyledLink>{" "}
								&nbsp;&nbsp;|&nbsp;&nbsp;
								<StyledLink to={paths.HELP_DISCLAIMER_IMPRESSUM}>{t("FOOTER.LINKS.IMPRESSUM.label")}</StyledLink>
							</Typography>
						</Box>

						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
							width={isSmallScreen ? "100%" : "initial"}
							mt={isSmallScreen ? 14 : 0}
						>
							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								<a href="https://biniblog.localbini.com" target="_blank" rel="noopener noreferrer">
									<img
										src={BlogIcon}
										height={26}
										width={26}
										title="LocalBini - Blog link"
										alt="LocalBini - Blog link"
									/>
								</a>
							</Box>

							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								<a href="https://www.instagram.com/localbini" target="_blank" rel="noopener noreferrer nofollow">
									<img
										src={InstagramIcon}
										height={26}
										width={26}
										title="LocalBini - Instagram link"
										alt="LocalBini - Instagram link"
									/>
								</a>
							</Box>

							{/*<Box display="flex" justifyContent="center" alignItems="center" mr={5} pb={2.5}>*/}
							{/*  /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
							{/*  <a href="#" target="_blank" rel="nofollow">*/}
							{/*    <img alt="localbini - tiktok" src={TikTokIcon} />*/}
							{/*  </a>*/}
							{/*</Box>*/}

							<Box display="flex" justifyContent="center" alignItems="center" mr={5}>
								<a href="https://www.facebook.com/localbini" target="_blank" rel="noopener noreferrer nofollow">
									<img
										src={FBIcon}
										height={26}
										width={26}
										title="LocalBini - Facebook link"
										alt="LocalBini - Facebook link"
									/>
								</a>
							</Box>

							<Box display="flex" justifyContent="center" alignItems="center">
								<a href="https://www.linkedin.com/company/localbini" target="_blank" rel="noopener noreferrer nofollow">
									<img
										src={LinkedinIcon}
										height={26}
										width={26}
										title="LocalBini - Linkedin link"
										alt="LocalBini - Linkedin link"
									/>
								</a>
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Wrapper>
	);
});

export default FooterLandingPage;
