import { Box, Button, Dialog, DialogContent, useMediaQuery, useTheme } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { getCurrentLocale } from "i18n";
import { memo, useState } from "react";
import styled from "styled-components";

import LanguageIcon from "assets/icons/footer/language.svg";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectUser } from "store/selectors/user";

import Logo from "ui/Logo";

import { CloseIconBtn, Container, StyledLink } from "styles/common";

import ForgotPasswordView from "../../auth/ForgotPasswordView";
import LoginView from "../../auth/LoginView";
import LanguageSelectorModal from "../../translations/LanguageSelectorModal";

type TDialog = "LOGIN" | "FORGOT_PSWD";

const Wrapper = styled.nav`
	width: 100%;
`;

const MainContent = styled.div`
	padding: 2rem 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 20px;
`;

const LoginBtn = styled(Button)`
	height: 48px;
	width: 96px;
`;

const BoxWithCursor = styled(Box)`
	cursor: pointer;
`;

const TopBarLandingPage = memo(() => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const userData = useAppSelector(selectUser);

	const { findNameByKey } = useLanguages();

	const [openDialog, setOpenDialog] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [typeOfDialog, setTypeOfDialog] = useState<TDialog>("LOGIN");

	const handleClickOpenDialog = (typeDialog: TDialog) => {
		setOpenDialog(true);

		setTypeOfDialog(typeDialog);
	};

	const currentLanguage = getCurrentLocale()?.code?.split("-")[0] || "en";

	const languageLabel = findNameByKey(currentLanguage);

	return (
		<Wrapper>
			<LanguageSelectorModal
				anchorElement={anchorEl}
				setAnchorElement={setAnchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: -10, // change it when new languages will come!
					horizontal: 40,
				}}
			/>

			<Dialog
				fullScreen={isSmallScreen}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				aria-labelledby="login-dialog"
			>
				<DialogContent>
					<CloseIconBtn onClick={() => setOpenDialog(false)}>
						<CloseIcon />
					</CloseIconBtn>

					{typeOfDialog === "LOGIN" && (
						<LoginView isPopup showForgotPasswordOnPopup={() => setTypeOfDialog("FORGOT_PSWD")} />
					)}

					{typeOfDialog === "FORGOT_PSWD" && <ForgotPasswordView isPopup />}
				</DialogContent>
			</Dialog>

			<Container>
				<MainContent>
					<StyledLink to={paths.HOMEPAGE}>
						<Logo version={isSmallScreen ? "localbiniSmall" : "localbiniBig"} height={isSmallScreen ? 60 : undefined} />
					</StyledLink>

					<Box display="flex" alignItems="center" gridGap={isSmallScreen ? "10px" : "20px"}>
						<BoxWithCursor
							display="flex"
							justifyContent="center"
							alignItems="center"
							onClick={e => setAnchorEl(e.currentTarget)}
						>
							<img
								title="Change language version"
								alt="Change language version icon"
								src={LanguageIcon}
								height={26}
								width={26}
							/>

							<Box marginLeft="8px">{languageLabel}</Box>
						</BoxWithCursor>

						{!userData && (
							<LoginBtn color="primary" variant="contained" onClick={() => handleClickOpenDialog("LOGIN")}>
								Login
							</LoginBtn>
						)}
					</Box>
				</MainContent>
			</Container>
		</Wrapper>
	);
});

export default TopBarLandingPage;
