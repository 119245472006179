import { Grid, TextField } from "@material-ui/core";
import { ChangeEvent, useState } from "react";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { createSelectFromEnum } from "utils/enums";

import FilterSearch from "ui/FilterSearch";
import AutocompleteUI, { Option } from "ui/forms/Autocomplete";

type TAllowedUserType = EUserType.PARTNER_AGENT | EUserType.PARTNER_MANAGER | "";

export interface ISelectedFilters {
	textSearch: string;
	partnerType: TAllowedUserType;
}

interface IFilterSearchPartnerUsersProps {
	applySelectedFiltersAction: (v: ISelectedFilters) => void;
}

const FilterSearchPartnerUsers = ({ applySelectedFiltersAction }: IFilterSearchPartnerUsersProps) => {
	const { t, withTaggedKey } = useTranslation();

	const [textSearch, setTextSearch] = useState<string>("");
	const [partnerType, setPartnerType] = useState<TAllowedUserType>("");

	const setDefaultValues = () => {
		setTextSearch("");
		setPartnerType("");
	};

	const onChangeTextSearch = (event: ChangeEvent<HTMLInputElement>) => setTextSearch(event.target.value);

	const onChangePartnerType = (value: EUserType.PARTNER_AGENT | EUserType.PARTNER_MANAGER) => setPartnerType(value);

	const setFilters = (value: boolean) => {
		if (value) {
			applySelectedFiltersAction({
				textSearch,
				partnerType,
			});
		}
	};

	return (
		<FilterSearch onClickApply={setFilters} onClickClear={setDefaultValues}>
			<Grid container spacing={10}>
				<Grid item md={4} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<TextField
						fullWidth
						id="text-search-partner-users-input"
						label={t("PARTNER_USERS.FILTERS.LABEL.TEXT_SEARCH")}
						value={textSearch}
						onChange={onChangeTextSearch}
						type="text"
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>

				<Grid item md={3} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
					<AutocompleteUI
						name="partner-type"
						label={t("PARTNER_USERS.FILTERS.LABEL.PARTNER_TYPE")}
						options={createSelectFromEnum(
							{
								[EUserType.PARTNER_AGENT]: EUserType.PARTNER_AGENT,
								[EUserType.PARTNER_MANAGER]: EUserType.PARTNER_MANAGER,
							},
							partnerTypeOption => withTaggedKey("PARTNER_USERS.USER_TYPE.{0}", [partnerTypeOption]),
						)}
						textFieldProps={{ variant: "standard" }}
						onChange={v =>
							onChangePartnerType(
								((v as Option)?.value?.toString() as EUserType.PARTNER_AGENT | EUserType.PARTNER_MANAGER) || "",
							)
						}
						value={partnerType}
						filterSelectedOptions
					/>
				</Grid>
			</Grid>
		</FilterSearch>
	);
};

export default FilterSearchPartnerUsers;
