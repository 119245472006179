import store from "store";

import { refreshSession } from "services/aws/auth";

import { fetchConfigsAll } from "store/slices/configs";

const init = async () => {
	await refreshSession();
};

const lessPriorityActions = () => {
	store.dispatch<any>(fetchConfigsAll());
};

const AppService = {
	init,
	lessPriorityActions,
};

export default AppService;
