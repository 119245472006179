import { Box } from "@material-ui/core";

import LogoGreyish from "assets/icons/logo/localbini-greyish.svg";

import HoverTooltip from "ui/tooltips/HoverTooltip";

import { Container, Description, ReadIndicator, Sent, StyledAvatar, SubTitle, Title } from "./styled";

interface INotificationRecord {
	title: string;
	description: string;
	subTitle?: string;
	avatar?: string;
	read?: boolean;
	sent?: string;
	compactVersion?: boolean;
	click?(): void;
	makeRead?(): void;
}

const NotificationRecord = ({
	title,
	description,
	subTitle,
	avatar,
	read,
	sent,
	compactVersion,
	click,
	makeRead,
}: INotificationRecord) => {
	return (
		<Container read={!!read} pointer={!!click} onClick={click} compact={compactVersion}>
			<StyledAvatar src={avatar || LogoGreyish} alt="Avatar image" $withPadding={!avatar} $compact={compactVersion} />

			<Box flexGrow={1}>
				<Box display="flex">
					<Title compact={compactVersion}>
						<strong>{title}</strong>
					</Title>

					{sent && <Sent compact={compactVersion}>{sent}</Sent>}
				</Box>

				<Box marginTop={subTitle ? "5px" : "3px"}>
					{subTitle && <SubTitle compact={compactVersion}>{subTitle}</SubTitle>}

					<Description compact={compactVersion}>{description}</Description>
				</Box>
			</Box>

			{makeRead && (
				<HoverTooltip
					ns="ui"
					translation={read ? "NOTIFICATION_RECORD.TOOLTIP.MAKE_UNREAD" : "NOTIFICATION_RECORD.TOOLTIP.MAKE_READ"}
					icon={
						<ReadIndicator
							read={!!read}
							onClick={event => {
								event.stopPropagation();

								makeRead();
							}}
						/>
					}
				/>
			)}
		</Container>
	);
};

export default NotificationRecord;
