import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import { styleConfig } from "./config";
import { EVariantButton } from "./types";

const Wrapper = styled.div<{
	backgroundColor: string;
	border: string;
	fontColor: string;
	boxShadow?: string | false;
	disabled?: boolean;
	compactWidth?: boolean;
}>`
	min-width: 120px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${props => props.fontColor};
	background: ${props => props.backgroundColor};
	border: ${props => props.border || "1px solid transparent"};
	border-radius: 4px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 10px;

	${props =>
		props.boxShadow &&
		css`
			box-shadow: ${props.boxShadow};
		`};

	${props =>
		props.disabled &&
		css`
			pointer-events: none;
			opacity: 0.4;
		`};

	${props =>
		props.compactWidth &&
		css`
			min-width: auto;
		`};

	&:hover {
		transform: scale(1.05);
	}
`;

const StyledLabel = styled.span<{ compactWidth?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-weight: ${props => (props.compactWidth ? "normal" : 500)};
	font-size: 12px;
	line-height: 1.67;
	letter-spacing: 0.09px;
`;

const StyledValueLabel = styled(StyledLabel)<{ compactWidth?: boolean }>`
	font-weight: bold;

	${props =>
		props.compactWidth &&
		css`
			margin-left: 12px;
		`};
`;

interface IFilterButtonProps {
	variant?: EVariantButton;
	filterValue?: number | string;
	handleClickFilter: (name: string) => void;
	nameFilter: string;
	isChecked?: boolean;
	isDisabled?: boolean;
	label?: string;
	compactWidth?: boolean;
	withBoxShadow?: boolean;
}

const FilterButton = ({
	variant = EVariantButton.standard,
	filterValue,
	handleClickFilter,
	nameFilter,
	isChecked = false,
	isDisabled = false,
	label,
	compactWidth,
	withBoxShadow = true,
}: IFilterButtonProps) => {
	const { t } = useTranslation("ui");

	return (
		<Wrapper
			disabled={isDisabled}
			backgroundColor={
				isChecked ? styleConfig[variant].backgroundColorSelectedItem : styleConfig[variant].backgroundColor
			}
			border={!isChecked && variant === EVariantButton.standard ? `1px solid ${colors.borderGray}` : ""}
			boxShadow={withBoxShadow && styleConfig[variant].boxShadow}
			fontColor={isChecked ? styleConfig[variant].fontColorSelectedItem : styleConfig[variant].fontColor}
			onClick={() => handleClickFilter(nameFilter)}
			compactWidth={compactWidth}
		>
			<StyledLabel compactWidth={compactWidth}>{label || t(`FILTER_BUTTON_${nameFilter}`)}</StyledLabel>

			{filterValue !== undefined && <StyledValueLabel compactWidth={compactWidth}>{filterValue}</StyledValueLabel>}
		</Wrapper>
	);
};

export default FilterButton;
