import { IAggregationBySuperbooking } from "data/backoffice/financials/types";

export const columns = [
	"MY_FINANCES.PAST_EARNINGS.TABLE.BOOKING_ID",
	"MY_FINANCES.PAST_EARNINGS.TABLE.EXPERIENCE_TITLE",
	"MY_FINANCES.PAST_EARNINGS.TABLE.DATE_TIME",
	"MY_FINANCES.PAST_EARNINGS.TABLE.EARNINGS",
	"MY_FINANCES.PAST_EARNINGS.TABLE.REIMBURSEMENT",
];

export const fields: (keyof IAggregationBySuperbooking | "superbooking.exp_title")[] = [
	"superbooking_id",
	"superbooking.exp_title",
	"created_on",
	"localhost_earnings",
	"localhost_reimbursement",
];

export const sortedColumns = [
	"MY_FINANCES.PAST_EARNINGS.TABLE.DATE_TIME",
	"MY_FINANCES.PAST_EARNINGS.TABLE.EARNINGS",
	"MY_FINANCES.PAST_EARNINGS.TABLE.REIMBURSEMENT",
];

export const idForContainer = "past-earnings-main-view";
export const listItemsOffset = 20;
