import { Button } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import bookingsServices from "services/api/bookings";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

const DialogWrapper = styled.div`
	margin: 60px 0 30px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const CancelBtn = styled(ActionButton)`
	background: ${colors.red};
`;

const TitleCancelBooking = styled.h1`
	margin: 0 0 20px;
	opacity: 0.76;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.29;
	color: ${colors.mainFontColor};
`;

const DescCancelBooking = styled.div`
	margin-bottom: 40px;
	opacity: 0.76;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5;
	color: ${colors.mainFontColor};
`;

interface ICancelBookingButton {
	id?: string;
	handleClose?: () => void;
	reload?: (time: number) => void;
	withGuestCredentials?: boolean;
}

export const CancelBookingButton = ({ id, handleClose, reload, withGuestCredentials }: ICancelBookingButton) => {
	const { t } = useTranslation();

	const { invalidParameters, addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();

	const [busy, setBusy] = useState<boolean>(false);
	const [showCancelBookingDialog, setShowCancelBookingDialog] = useState<boolean>(false);

	const cancelBooking = async () => {
		if (!id) {
			invalidParameters();

			return;
		}

		try {
			setBusy(true);

			await bookingsServices.postCancelBooking(id, undefined, withGuestCredentials);

			addSuccess(t("MY_BOOKINGS.BOOKING_CANCELED"));

			if (!!reload) {
				reload(new Date().getTime());
			}

			if (handleClose) {
				handleClose();
			}

			setShowCancelBookingDialog(false);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setBusy(false);
		}
	};

	return (
		<>
			<Dialog
				minHeight="auto"
				decorator="withoutLogo"
				showDialog={showCancelBookingDialog}
				handleClose={() => setShowCancelBookingDialog(false)}
			>
				<DialogWrapper>
					<TitleCancelBooking>{t("MY_BOOKINGS.CANCEL_BOOKING")}</TitleCancelBooking>

					<DescCancelBooking>{t("MY_BOOKINGS.CONFIRM_CANCEL_MESSAGE")}</DescCancelBooking>

					<CancelBtn
						variant="contained"
						color="primary"
						onClick={cancelBooking}
						translationDefault="MY_BOOKINGS.CANCEL_BOOKING_CONFIRM_BTN"
						isAction={busy}
					/>
				</DialogWrapper>
			</Dialog>

			<Button variant="outlined" onClick={() => setShowCancelBookingDialog(true)}>
				{t("MY_BOOKINGS.CANCEL_BOOKING")}
			</Button>
		</>
	);
};
