import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { Fragment, memo, useState } from "react";

import useTranslation from "hooks/useTranslation";

import MuiDateProvider from "providers/MuiDateProvider";

import DateInput from "./components/DateInput";

interface ISingleDatePicker {
	callBackDate: (selectedDate) => void;
	label: string;
	dateFormat?: string;
	value?: Date;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		addMoreBtn: {
			borderRadius: "16px",
			padding: "3px 15px",
			textTransform: "lowercase",
		},
		dateContent: {
			backgroundColor: theme.palette.background.secondary,
		},
	}),
);

export const SingleDatePickerComponent = memo(
	({ callBackDate, value, label, dateFormat = "yyyy-MM-dd" }: ISingleDatePicker) => {
		const { t } = useTranslation();

		const [open, setOpen] = useState(false);
		const [selectedDate, setSelectedDate] = useState<Date | null>(value || new Date());

		const classes = useStyles();

		const handleClickOpen = () => setOpen(true);

		const handleClose = () => setOpen(false);

		const handleSubmit = () => {
			setOpen(false);

			if (selectedDate) {
				callBackDate(format(selectedDate, dateFormat));
			}
		};

		const handleChangeDate = (dateString: any) => {
			if (dateString.includes("_")) return;

			setSelectedDate(new Date(dateString));
		};

		return (
			<div>
				<Button className={classes.addMoreBtn} variant="outlined" onClick={handleClickOpen}>
					{label}
				</Button>

				<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
					<DialogContent>
						<Fragment>
							<Box>
								<DateInput
									id="start-date"
									value={selectedDate && format(selectedDate, dateFormat)}
									onChange={handleChangeDate}
									label={t("EXPERIENCE.FORM.COMMON_FOR_DATE_TIME_PICKERS.DATE")}
									variant="outlined"
								/>
							</Box>

							<MuiDateProvider>
								<Box mt={5}>
									<DatePicker
										disableToolbar
										variant="static"
										value={selectedDate}
										onChange={(event: Date) => setSelectedDate(new Date(event))}
										className={classes.dateContent}
									/>
								</Box>
							</MuiDateProvider>
						</Fragment>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleSubmit} color="primary">
							{t("COMMON.SUBMIT")}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	},
);
