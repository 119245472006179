import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ENotificationRecipient, TNotificationsResponse } from "data/notifications/types";

import notificationsService from "services/api/notifications";

import { handleRejectValues } from "store/utils/handleRejectValues";

export interface INotificationsState {
	unreadNotifications: TNotificationsResponse | null;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: any;
}

const initialState: INotificationsState = {
	unreadNotifications: null,
	status: "idle",
	error: null,
};

export const fetchUnreadNotifications = handleRejectValues(
	"notifications/fetchUnreadNotifications",
	(queryParams?: { recipient_type: ENotificationRecipient }) =>
		notificationsService.getSearchNotifications({
			...(queryParams?.recipient_type && { recipient_type: queryParams.recipient_type }),
			marked_as_read: false,
		}),
);

const { actions, reducer } = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		reset(state) {
			return (state = initialState);
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUnreadNotifications.fulfilled, (state, action: PayloadAction<TNotificationsResponse>) => {
				state.unreadNotifications = action.payload;
				state.status = "succeeded";
				state.error = null;
			})
			.addCase(fetchUnreadNotifications.pending, state => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(fetchUnreadNotifications.rejected, state => {
				state.unreadNotifications = null;
				state.status = "failed";
				state.error = true;
			});
	},
});

export const notificationsActions = actions;

export default reducer;
