import { memo, ReactNode } from "react";
import styled from "styled-components";

import MaurizioImgSmall from "assets/images/uniqueExperiences/maurizio.png";
import MaurizioImgMedium from "assets/images/uniqueExperiences/maurizio@2x.png";
import MayaImgSmall from "assets/images/uniqueExperiences/maya.png";
import MayaImgMedium from "assets/images/uniqueExperiences/maya@2x.png";

import useTranslation from "hooks/useTranslation";

import { SmallerTitle } from "ui/CommonParagraph";

import { Container, MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

interface ILocalhostResponsiveImgProps {
	altDesc: string;
	imgSmall: string;
	imgMedium: string;
}

interface ITileProps {
	picture: ReactNode;
	title: string;
	description: ReactNode;
	isRowReverse?: boolean;
	localhostDesc: ReactNode;
}

const Background = styled.div`
	width: 100%;
	padding: 80px 0;
	background: rgba(255, 229, 210, 0.63);
	clip-path: ellipse(100% 50% at 50% 50%);

	${media.desktop`
    clip-path: ellipse(150% 50% at 50% 50%);
  `};
`;

const MainContent = styled.div`
	width: 80%;
	padding-top: 50px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	${media.desktop`
      width: 100%;
      padding: 4rem 0;
  `};
`;

const TileWrapper = styled.div<{ isRowReverse?: boolean }>`
	width: 100%;
	${props => !props.isRowReverse && "margin-bottom: 4.063rem"};
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: ${props => (props.isRowReverse ? "row-reverse" : "row")};

	${media.desktop`
      flex-direction: column;
  `};
`;

const TileImgContent = styled.div`
	height: auto;
	min-width: 26.563rem;

	${media.tablet`
     min-width: 100%;
  `};
`;

const TileTextContent = styled.div<{ isRowReverse?: boolean }>`
	height: auto;
	margin-right: ${props => (props.isRowReverse ? "5rem" : "0")};
	margin-left: ${props => (props.isRowReverse ? "0" : "5rem")};
	text-align: ${props => (props.isRowReverse ? "right" : "left")};

	${media.desktop`
      margin: 3.125rem 0 0 0;
      text-align: center;
  `};
`;

const TileTitle = styled.h3`
	font-family: ${MontserratFontFamily};
	margin: 0 0 0.313rem;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.44;
	letter-spacing: 0.04px;
`;

const TileDesc = styled.div`
	font-family: ${MontserratFontFamily};
	margin: 0 0 1.25rem;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.93;
	letter-spacing: 0.03px;
`;

const LocalhostResponsiveImg = ({ altDesc, imgSmall, imgMedium }: ILocalhostResponsiveImgProps) => (
	<img srcSet={`${imgSmall} 1x, ${imgMedium} 1.5x`} src={imgMedium} title={altDesc} alt={altDesc} width="100%" />
);

const Tile = memo(({ picture, title, description, isRowReverse, localhostDesc }: ITileProps) => (
	<TileWrapper isRowReverse={isRowReverse}>
		<TileImgContent>{picture}</TileImgContent>

		<TileTextContent isRowReverse={isRowReverse}>
			<TileTitle>{title}</TileTitle>
			<TileDesc>{localhostDesc}</TileDesc>
			<TileDesc>{description}</TileDesc>
		</TileTextContent>
	</TileWrapper>
));

const LocalsSay = memo(() => {
	const { t, withRaw } = useTranslation();

	return (
		<WrapperSection>
			<Background>
				<Container>
					<SmallerTitle textAlignCenter>{t("UNIQUE_EXPERIENCES.LOCALS_SAY_TITLE")}</SmallerTitle>

					<MainContent>
						<Tile
							picture={
								<LocalhostResponsiveImg
									altDesc="local - Maurizio"
									imgSmall={MaurizioImgSmall}
									imgMedium={MaurizioImgMedium}
								/>
							}
							title="Maurizio"
							localhostDesc={withRaw("UNIQUE_EXPERIENCES.LOCALHOST_PARIS_MAURIZIO")}
							description={withRaw("UNIQUE_EXPERIENCES.LOCALHOST_MAURIZIO_DESC")}
						/>

						<Tile
							isRowReverse
							picture={
								<LocalhostResponsiveImg altDesc="local - Maya" imgSmall={MayaImgSmall} imgMedium={MayaImgMedium} />
							}
							title="Maya"
							localhostDesc={withRaw("UNIQUE_EXPERIENCES.LOCALHOST_ROTTERDAM_MAYA")}
							description={withRaw("UNIQUE_EXPERIENCES.LOCALHOST_MAYA_DESC")}
						/>
					</MainContent>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default LocalsSay;
