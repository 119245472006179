import { Box, ClickAwayListener } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { RatingProps } from "@material-ui/lab/Rating";
import { orderBy } from "lodash-es";
import { memo, useMemo, useState } from "react";

import useTranslation from "hooks/useTranslation";

import { formatNumber } from "utils/prices";

import {
	InnerRatingBar,
	Label,
	RatingBar,
	RatingContainer,
	RatingSpecificLabel,
	StyledEmptyStar,
	StyledRating,
	StyledTooltip,
} from "./styled";

interface IDetailsRating {
	1: number;
	2: number;
	3: number;
	4: number;
	5: number;
}

interface IRatingStars extends RatingProps {
	count?: number;
	withoutArrow?: boolean;
	withoutLabel?: boolean;
	withoutModal?: boolean;
	detailsRating?: IDetailsRating;
}

const maxStar = 5;

const RatingStars = memo(
	({ value, count, precision, size, withoutArrow, withoutLabel, withoutModal, detailsRating }: IRatingStars) => {
		const { t } = useTranslation("ui");

		const [open, setOpen] = useState<boolean>(false);

		const labelValue = useMemo(() => formatNumber(value, 2, ","), [value]);

		const RatingContent = useMemo(
			() => (
				<RatingContainer
					display="flex"
					alignItems="center"
					onClick={
						!withoutModal
							? event => {
									event.preventDefault();

									setOpen(prevState => !prevState);
							  }
							: undefined
					}
					$pointer={!withoutModal}
				>
					<StyledRating
						readOnly
						value={value}
						precision={precision || 0.1}
						size={size}
						emptyIcon={<StyledEmptyStar fontSize="inherit" />}
					/>

					{!(withoutArrow || withoutModal) &&
						(open ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />)}

					{!withoutLabel && (
						<Label smallFontSize={size === "small"}>
							<strong>{labelValue}</strong>
							{count && <> ({count})</>}
						</Label>
					)}
				</RatingContainer>
			),
			[withoutModal, withoutLabel, withoutArrow, count, labelValue, precision, open, size, value],
		);

		const TooltipContent = useMemo(
			() =>
				!withoutModal && (
					<>
						<Box display="flex" alignItems="center" marginBottom="10px">
							<StyledRating
								readOnly
								value={value}
								precision={precision || 0.1}
								emptyIcon={<StyledEmptyStar fontSize="inherit" />}
							/>

							<Label>
								<strong>
									{labelValue} / {maxStar}
								</strong>
							</Label>
						</Box>

						{typeof count === "number" && (
							<Label withoutMarginLeft>
								{count} {t("RATING_STARS.REVIEWS_LABEL")}
							</Label>
						)}

						{!!detailsRating && (
							<Box display="flex" flexDirection="column" marginTop="15px" gridGap="20px">
								{orderBy(Object.entries(detailsRating), ([type, typeCount]) => type, "desc").map(
									([type, typeCount]) => {
										const percentPart = formatNumber((typeCount / (count || 0)) * 100, 0);

										return (
											<Box
												display="flex"
												alignItems="center"
												justifyContent="space-between"
												width="100%"
												key={`${type}-${typeCount}`}
												gridGap="20px"
											>
												<RatingSpecificLabel>
													{type}&nbsp;{t("RATING_STARS.STARS_LABEL")}
												</RatingSpecificLabel>
												<RatingBar>
													<InnerRatingBar width={percentPart} />
												</RatingBar>
												<RatingSpecificLabel specificWidth>{percentPart}%</RatingSpecificLabel>
											</Box>
										);
									},
								)}
							</Box>
						)}
					</>
				),
			[t, withoutModal, count, detailsRating, labelValue, precision, value],
		);

		return (
			<ClickAwayListener onClickAway={() => setOpen(false)}>
				<Box display="inline-block">
					{!withoutModal ? (
						<StyledTooltip
							onClose={() => setOpen(false)}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
							title={TooltipContent}
							arrow
						>
							{RatingContent}
						</StyledTooltip>
					) : (
						RatingContent
					)}
				</Box>
			</ClickAwayListener>
		);
	},
);

export default RatingStars;
