import { TRootState } from "store";

export const selectConfigsAllError = (state: TRootState) => state.configs.errors.all;

export const selectConfigsCountries = (state: TRootState) => state.configs.data.countries;
export const selectConfigsCities = (state: TRootState) => state.configs.data.cities;

export const selectConfigsLanguages = (state: TRootState) => state.configs.data.languages;

export const selectConfigsEarnings = (state: TRootState) => state.configs.data.earnings;

export const selectConfigsPrivate = (state: TRootState) => state.configs.data.private;
export const selectConfigsPrivateError = (state: TRootState) => state.configs.errors.private;
