import styled from "styled-components";

import Input from "components/formik/Input";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export const Textarea = styled(Input)`
	.MuiTextField-root,
	.MuiInputBase-input {
		min-height: 80px;
	}
`;

export const BookingInfoContainer = styled.div`
	column-count: 2;
	width: 100%;
`;

export const BookingInfoTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 2;
	font-family: ${RobotoFontFamily};
`;

export const ModalSentSubTitle = styled(BookingInfoTitle)`
	line-height: 1.3;
	color: ${colors.middleGray};
	margin-bottom: 40px;
`;

export const ModalSentText = styled(ModalSentSubTitle)`
	font-size: 14px;
`;
