import { Box, Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { Fragment, useState } from "react";

import { IUpcomingBooking } from "data/experiences/types";

import { useAppDispatch } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import { dateParseAndFormat, DATE_FORMATS } from "utils/dates";

import Dialog from "ui/Dialog";

import {
	BoxHeader,
	BoxRow,
	ModalInnerContainer,
	StyledButton,
	StyledButtonMobile,
	StyledDoneIcon,
	StyledLastMinuteBox,
} from "./styled";

interface ILastMinuteBox {
	upcomingBookings: IUpcomingBooking[];
	initialModalOpened: boolean;
	onlyButton?: boolean;
	bookCallback?: () => void;
}

export const LastMinuteBox = ({ upcomingBookings, initialModalOpened, onlyButton, bookCallback }: ILastMinuteBox) => {
	const dispatch = useAppDispatch();

	const theme = useTheme();
	const isUpMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

	const { findNameByKey } = useLanguages();

	const [modalOpen, setModalOpen] = useState<boolean>(initialModalOpened);

	const date = new Date();

	const nowUTC = new Date(
		Date.UTC(
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours() - upcomingBookings[0].utc_offset,
			date.getUTCMinutes(),
			date.getUTCSeconds(),
		),
	);

	// @ts-ignore
	const hoursDifference = Math.abs(nowUTC - new Date(upcomingBookings[0].utc_date_time)) / 36e5;

	let daysDifference;

	if (hoursDifference > 36) {
		daysDifference = hoursDifference / 24;
	}

	const timeLabel = (
		<span>
			Last chance in the next <strong>{Math.round(hoursDifference > 36 ? daysDifference : hoursDifference)}</strong>{" "}
			{hoursDifference > 36 ? "days" : "hours"}!
		</span>
	);

	return (
		<>
			<Dialog decorator="withoutLogo" showDialog={modalOpen} handleClose={() => setModalOpen(false)}>
				<Box padding={!isUpMediumScreen ? "20px" : undefined} paddingY={isUpMediumScreen ? "40px" : "20px"}>
					<Box fontSize="18px" fontWeight="bold">
						LAST MINUTE
					</Box>

					<Box fontSize="14px" fontWeight="bold" marginBottom="10px">
						{upcomingBookings[0].exp_title}
					</Box>

					{upcomingBookings.map(elem => {
						const dateElem = new Date();

						const nowUTCElem = new Date(
							Date.UTC(
								dateElem.getUTCFullYear(),
								dateElem.getUTCMonth(),
								dateElem.getUTCDate(),
								dateElem.getUTCHours() - upcomingBookings[0].utc_offset,
								dateElem.getUTCMinutes(),
								dateElem.getUTCSeconds(),
							),
						);

						// @ts-ignore
						const hoursDifferenceElem = Math.abs(nowUTCElem - new Date(elem.utc_date_time)) / 36e5;

						let timeLabelElem: any = dateParseAndFormat(
							elem.date_time,
							DATE_FORMATS.DATETIME_FORMAT4,
							DATE_FORMATS.API_FORMAT_DATETIME,
						);

						if (hoursDifferenceElem < 24) {
							timeLabelElem = (
								<Box color="#e65100">
									{timeLabelElem}

									<Box display="flex" alignItems="center" fontSize="10px" fontWeight="normal">
										<Box fontSize="12px" marginRight="2px">
											<TimelapseIcon fontSize="inherit" />
										</Box>
										<div>{Math.round(hoursDifferenceElem)} hours left to start!</div>
									</Box>
								</Box>
							);
						}

						const BookButtonContainer = (
							<Box textAlign="right">
								<StyledButton
									$withoutMargin
									onClick={() => {
										dispatch(
											experienceTravellerActions.updateBooking({
												superbooking_id: elem.id,
												hour: [
													dateParseAndFormat(
														elem.date_time,
														DATE_FORMATS.TIME_FORMAT,
														DATE_FORMATS.API_FORMAT_DATETIME,
													) as string,
												],
												date: [
													dateParseAndFormat(
														elem.date_time,
														DATE_FORMATS.DATE_FORMAT,
														DATE_FORMATS.API_FORMAT_DATETIME,
													) as string,
												],
												language: elem.language,
												maxPeople: elem.available_spots,
											}),
										);

										setModalOpen(false);

										if (bookCallback) {
											bookCallback();
										}
									}}
								>
									Book
								</StyledButton>
							</Box>
						);

						return (
							<Fragment key={elem.id}>
								<Divider />

								<Box marginTop="20px" paddingBottom="20px">
									<ModalInnerContainer container>
										<Grid item xs={6} md={3}>
											<Box fontSize="12px">Starting date:</Box>

											<Box fontSize="12px" fontWeight="bold">
												{timeLabelElem}
											</Box>

											<Box fontSize="12px" marginTop="10px">
												Language:
											</Box>

											<Box fontSize="12px" fontWeight="bold">
												{findNameByKey(elem.language)}
											</Box>
										</Grid>

										<Grid item xs={6} hidden={isUpMediumScreen}>
											{BookButtonContainer}
										</Grid>

										<Grid item xs={12} md={3}>
											<Box fontSize="12px">Available spots:</Box>

											<Box fontSize="12px" fontWeight="bold">
												{elem.available_spots}
											</Box>
										</Grid>

										<Grid item xs={12} md={3}>
											<Box fontSize="12px">Price (per person) from:</Box>

											<Box fontSize="12px" fontWeight="bold">
												{elem.exp_currency} {elem.exp_filter_price}
											</Box>
										</Grid>

										<Grid item hidden={!isUpMediumScreen} md={3}>
											{BookButtonContainer}
										</Grid>
									</ModalInnerContainer>
								</Box>
							</Fragment>
						);
					})}

					<Divider />
				</Box>
			</Dialog>

			{onlyButton ? (
				<Box
					bgcolor="#e65100"
					color="white"
					fontSize="14px"
					fontWeight="500"
					fontFamily="Montserrat,sans-serif"
					marginBottom="10px"
				>
					<Box
						height="44px"
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						marginY={0}
						marginX="auto"
						width="90%"
					>
						<span>Last Minute!</span>

						<StyledButtonMobile onClick={() => setModalOpen(true)}>Join Now!</StyledButtonMobile>
					</Box>
				</Box>
			) : (
				<StyledLastMinuteBox display="flex" flexDirection="column">
					<BoxHeader>Last Minute!</BoxHeader>

					<div>
						<BoxRow>
							<StyledDoneIcon />

							{timeLabel}
						</BoxRow>

						<BoxRow>
							<StyledDoneIcon />

							<span>
								There are only <strong>{upcomingBookings[0].available_spots}</strong> spots left!
							</span>
						</BoxRow>

						<BoxRow>
							<StyledDoneIcon />
							Hurry up and have fun!
						</BoxRow>

						<StyledButton size="large" $fullWidth onClick={() => setModalOpen(true)}>
							Join Now!
						</StyledButton>
					</div>
				</StyledLastMinuteBox>
			)}
		</>
	);
};
