import { FormHelperText } from "@material-ui/core";
import { FormikProps } from "formik";

import { IExperience as FormValues } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import NumberDurationsMinutes from "components/creation-process/NumberDurationsMinutes";
import NumberRadioInput from "components/creation-process/NumberRadioInput";
import NumberSlider from "components/creation-process/NumberSlider";
import StringArray from "components/creation-process/StringArray";
import StringCheckboxes from "components/creation-process/StringArrayCheckboxes";
import StringTagsFromToDates from "components/creation-process/StringTagsFromToDates";
import StringTagsOneDate from "components/creation-process/StringTagsOneDate";
import StringTagsTime from "components/creation-process/StringTagsTime";
import AvailableExp from "components/creation-process/tooltips/settings/AvailableExp";
import Covid from "components/creation-process/tooltips/settings/Covid";
import ExpNotAvailable from "components/creation-process/tooltips/settings/ExpNotAvailable";

import { Tooltip } from "ui/tooltips/Tooltip";

import {
	ItemDescription,
	ItemTitle,
	ItemWrapper,
	MainTitleSection,
	TitleTooltipWrapper,
	Wrapper,
} from "../shared.styled";

const Step3 = ({ values, errors, setFieldValue }: FormikProps<FormValues>) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<MainTitleSection>{t("EXPERIENCE.SETTINGS")}</MainTitleSection>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.duration_minutes.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.duration_minutes.DESCRIPTION")}</ItemDescription>

				<NumberDurationsMinutes onChange={v => setFieldValue("duration_minutes", v)} value={values.duration_minutes} />

				<FormHelperText error>{errors?.duration_minutes}</FormHelperText>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.schedule.availability_periods.TITLE")}</ItemTitle>

					<Tooltip>
						<AvailableExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.schedule.availability_periods.DESCRIPTION")}</ItemDescription>

				<StringTagsFromToDates
					isSwitcherYesNo
					onChange={v => setFieldValue("schedule.availability_periods", [...v])}
					value={values.schedule.availability_periods || []}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.schedule.available_days_of_week.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.schedule.available_days_of_week.DESCRIPTION")}</ItemDescription>

				<StringTagsTime
					onChange={v => setFieldValue("schedule.available_days_of_week", { ...v })}
					value={values.schedule.available_days_of_week || []}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.schedule.excluded_dates.TITLE")}</ItemTitle>

					<Tooltip>
						<ExpNotAvailable />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.schedule.excluded_dates.DESCRIPTION")}</ItemDescription>

				<StringTagsOneDate
					onChange={v => setFieldValue("schedule.excluded_dates", [...v])}
					value={values.schedule.excluded_dates || []}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.cutoff_time_hours.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.cutoff_time_hours.DESCRIPTION")}</ItemDescription>

				<NumberSlider onChange={v => setFieldValue("cutoff_time_hours", v)} value={values.cutoff_time_hours} />
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.cancellation_policy_hours.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.cancellation_policy_hours.DESCRIPTION")}</ItemDescription>

				<NumberRadioInput
					onChange={v => setFieldValue("cancellation_policy_hours", parseInt(v, 10))}
					value={values.cancellation_policy_hours}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.weather_sensitivities.TITLE")}</ItemTitle>

				<ItemDescription>{t("EXPERIENCE.FIELDS.weather_sensitivities.DESCRIPTION")}</ItemDescription>

				<StringArray
					isSwitcherYesNo
					onChange={v => setFieldValue("weather_sensitivities", [...v])}
					value={values.weather_sensitivities || []}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.disaster_policies.TITLE")}</ItemTitle>

					<Tooltip>
						<Covid />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.disaster_policies.DESCRIPTION")}</ItemDescription>

				<StringCheckboxes
					isSwitcherYesNo
					onChange={v => setFieldValue("disaster_policies", [...v])}
					value={values.disaster_policies || []}
					onChangeCustomArray={v => setFieldValue("custom_disaster_policies", [...v])}
					customValue={values.custom_disaster_policies || undefined}
					field="DISASTER_POLICIES"
				/>
			</ItemWrapper>
		</Wrapper>
	);
};

export default Step3;
