import { Avatar, Box } from "@material-ui/core";
import slugify from "slugify";
import styled from "styled-components";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import RatingStars from "ui/RatingStars";

import { StyledLink } from "styles/common";

const StyledAvatar = styled(Avatar)`
	width: 56px;
	height: 56px;
`;

interface IRatingOnBookingDetails {
	score?: number;
	comment?: string | null;
	travellerName?: string | null;
	travellerID?: string;
	travellerPhoto?: string | null;
}

export const RatingOnBookingDetails = ({
	score,
	comment,
	travellerName,
	travellerID,
	travellerPhoto,
}: IRatingOnBookingDetails) => {
	const linkToTravellerProfile =
		travellerID &&
		fillRoute(paths.PUBLIC_PROFILE, {
			userName: slugify((travellerName || "").toLowerCase()),
			uid: travellerID,
		});

	return (
		<Box bgcolor="#CFD8DC" padding="20px 25px 40px" display="flex" gridGap="15px">
			{linkToTravellerProfile && (
				<StyledLink to={linkToTravellerProfile} target="_blank">
					<StyledAvatar alt={`Avatar Traveller - ${travellerName}`} src={travellerPhoto || undefined} />
				</StyledLink>
			)}
			<Box width="100%">
				<Box display="flex" justifyContent="space-between" marginBottom="15px" flexWrap="wrap">
					<Box fontSize="16px">
						<strong>
							{linkToTravellerProfile ? (
								<StyledLink to={linkToTravellerProfile} target="_blank">
									{travellerName}
								</StyledLink>
							) : (
								travellerName
							)}
						</strong>
					</Box>

					<RatingStars value={score} withoutArrow />
				</Box>

				<Box fontSize="14px" lineHeight="20px">
					{comment}
				</Box>
			</Box>
		</Box>
	);
};
