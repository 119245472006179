import {
	ELocalhostFinancialStatementStatus,
	IAggregationBySuperbooking,
	IBankTransferFileForLocalhostFinancialStatementsParams,
	ILocalhostFinancialStatementStatistics,
	TFinancialIId,
	TLocalhostFinancialStatementIId,
} from "data/backoffice/financials/types";
import { ECurrency, IFinancialsBankingDetails, TFinancialsBankingDetailsIId } from "data/financials/types";

import API, { APISingleData } from "./index";

const internalNamespace = "internal";
const apiName = "/financials";

export const errorCodes = {
	getBankingDetails: {
		missingBankingDetails: "FINA404A0",
	},
};

const financialsServices = {
	getBrainTreeToken: (currency: ECurrency) =>
		APISingleData.get<{ token: string }>(internalNamespace, `${apiName}/braintree/token`, {
			queryStringParameters: {
				currency,
			},
		}),
	postBrainTreeCheckout: (data: {
		booking_id: string;
		payment_method_nonce: string;
		device_data: string;
	}): Promise<any> => APISingleData.post(internalNamespace, `${apiName}/braintree/checkout`, { body: data }),
	getSearchFinancials: (queryParams): Promise<{ data: TFinancialIId[]; count: number }> =>
		APISingleData.get(internalNamespace, apiName, {
			queryStringParameters: queryParams,
		}),
	patchBankingDetails: (id: string, body: IFinancialsBankingDetails): Promise<any> =>
		API.patch(internalNamespace, `${apiName}/banking-details/${id}`, { body }),
	postBankingDetails: (body: IFinancialsBankingDetails): Promise<any> =>
		API.post(internalNamespace, `${apiName}/banking-details`, { body }),
	getBankingDetails: (id: string) =>
		API.get<TFinancialsBankingDetailsIId>(internalNamespace, `${apiName}/banking-details/${id}`),
	getSearchLocalhostFinancialStatements: (
		queryParams,
	): Promise<{
		data: TLocalhostFinancialStatementIId[];
		count: number;
		statistics: ILocalhostFinancialStatementStatistics;
	}> =>
		APISingleData.get(internalNamespace, `${apiName}/statements/localhost`, {
			queryStringParameters: queryParams,
		}),
	patchUpdateLocalhostFinancialStatement: (
		id: string,
		body: { status?: ELocalhostFinancialStatementStatus; superbookings?: any },
	): Promise<TLocalhostFinancialStatementIId> =>
		APISingleData.patch(internalNamespace, `${apiName}/statements/localhost/${id}`, { body }),
	getAggregationBySuperbooking: (queryParams): Promise<{ data: IAggregationBySuperbooking[]; count: number }> =>
		APISingleData.get(internalNamespace, `${apiName}/aggregations/by-superbooking`, {
			queryStringParameters: queryParams,
		}),
	getBankTransferFileForLocalhostFinancialStatements: (
		queryParams: IBankTransferFileForLocalhostFinancialStatementsParams,
	): Promise<{ url: string }> =>
		APISingleData.get(internalNamespace, `${apiName}/statements/localhost/bank-transfer-file`, {
			queryStringParameters: queryParams,
		}),
};

export default financialsServices;
