import * as Yup from "yup";

import useTranslation from "hooks/useTranslation";

const useValidate = () => {
	const { t } = useTranslation();

	return Yup.object().shape({
		code: Yup.number().required(t("ERRORS.REQUIRED")),
	});
};

export default useValidate;
