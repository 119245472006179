import { Box } from "@material-ui/core";

import useTranslation from "hooks/useTranslation";

import { DescriptionContent } from "styles/common";

import { TitleSection } from "../../index";

interface IDescriptionProps {
	descriptionExperienceUnique: string;
}

const Description = ({ descriptionExperienceUnique }: IDescriptionProps) => {
	const { t } = useTranslation();

	return (
		<Box>
			<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_UNIQUE")}</TitleSection>

			<Box mt={10} mb={20}>
				<DescriptionContent>{descriptionExperienceUnique}</DescriptionContent>
			</Box>
		</Box>
	);
};

export default Description;
