import { isEmpty } from "lodash-es";
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";

import useCity from "store/hooks/useCity";

import { omit } from "utils/objects";

import { ISharedOnboardingProps } from "../../types";
import Layout from "./layout";

interface BioPostData {
	avatar_url: string;
	avatar_blob: Blob | null;
	languages: string[];
	cities: string[];
}

interface BioInputsDataErrors {
	[key: string]: boolean;
}

interface BioInputsData extends BioPostData {
	errors: BioInputsDataErrors;
}

interface SelectOption {
	label: string;
	value: string;
}

export interface BioProps {
	inputs: BioInputsData;
	userName: string;
	cities?: SelectOption[];

	handleLanguagesChange(event: ChangeEvent<{}>, value: SelectOption[]): void;

	handleCitiesChange(event: ChangeEvent<{}>, value: SelectOption[]): void;

	handleOnFilesAdded(imgBlob: Blob, imgBase64: string): void;

	handleSendButtonClick(event: MouseEvent): void;

	isSendButtonDisabled(): boolean;

	validateField(fieldName: string): boolean;
}

const ShortBioWithCity = ({ updateProfileData, isBusy, profileData, moveToNextStep }: ISharedOnboardingProps) => {
	const { citiesSelectSorted } = useCity();

	const [userName, setUserName] = useState<string>(profileData?.first_name || "");
	const [inputs, setInputs] = useState<BioInputsData>({
		avatar_url: "",
		avatar_blob: null,
		languages: [],
		cities: [],
		errors: {},
	});

	const isFirstRenderLanguagesRef = useRef<boolean>(true);
	const isFirstRenderCitiesRef = useRef<boolean>(true);

	useEffect(() => {
		setUserName(profileData?.first_name || "");

		setInputs(prevState => ({
			...prevState,
			...(profileData?.languages && { languages: profileData.languages }),
			...(profileData?.cities && { cities: profileData.cities }),
			...(profileData?.pictures?.profile && { avatar_url: profileData.pictures.profile }),
		}));
	}, [profileData]);

	useEffect(() => {
		if (!isFirstRenderLanguagesRef.current) {
			validateField("languages");
		} else {
			isFirstRenderLanguagesRef.current = false;
		}

		// eslint-disable-next-line
	}, [inputs.languages.length]);

	useEffect(() => {
		if (!isFirstRenderCitiesRef.current) {
			validateField("cities");
		} else {
			isFirstRenderCitiesRef.current = false;
		}

		// eslint-disable-next-line
	}, [inputs.cities.length]);

	useEffect(() => {
		if (inputs.avatar_url) {
			validateField("avatar_url");
		}

		// eslint-disable-next-line
	}, [inputs.avatar_url]);

	const validateField = useCallback(
		(fieldName: string): boolean => {
			if (inputs[fieldName].length <= 0) {
				setInputs((inputsData: BioInputsData) => ({
					...inputsData,
					errors: { ...inputsData.errors, [fieldName]: true },
				}));

				return true;
			}

			setInputs((inputsData: BioInputsData) => ({
				...inputsData,
				errors: { ...inputsData.errors, [fieldName]: false },
			}));

			return false;
		},

		// eslint-disable-next-line
		[inputs],
	);

	const handleOnFilesAdded = (imgBlob: Blob, imgBase64: string) =>
		setInputs((inputsData: BioInputsData) => ({ ...inputsData, avatar_url: imgBase64, avatar_blob: imgBlob }));

	const handleLanguagesChange = (event: ChangeEvent, value: SelectOption[]) => {
		setInputs((inputsData: BioInputsData) => ({ ...inputsData, languages: value.map(language => language.value) }));

		validateField("avatar_url");
	};

	const handleCitiesChange = (event: ChangeEvent, value: SelectOption[]) =>
		setInputs((inputsData: BioInputsData) => ({ ...inputsData, cities: value.map(city => city.value) }));

	const handleSendButtonClick = async (event: MouseEvent) => {
		event.preventDefault();

		const apiCall = await updateProfileData(omit(inputs, ["avatar_url", "errors"]));

		if (apiCall) {
			moveToNextStep();
		}
	};

	const isSendButtonDisabled = (): boolean => {
		const { errors, avatar_blob, ...inputsForValidation } = inputs;

		const areValuesEmptyOrFalse: boolean =
			Object.values(inputsForValidation).filter(input => isEmpty(input)).length > 0;

		const areAnyErrors: boolean = Object.values(inputs.errors).filter(error => error).length > 0;

		return !!isBusy || areValuesEmptyOrFalse || areAnyErrors;
	};

	const bioProps: BioProps = {
		inputs,
		userName,
		cities: citiesSelectSorted,
		handleLanguagesChange,
		handleCitiesChange,
		handleOnFilesAdded,
		handleSendButtonClick,
		isSendButtonDisabled,
		validateField,
	};

	return <Layout {...bioProps} />;
};

export default ShortBioWithCity;
