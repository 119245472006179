import axios from "axios";
import { FormikProps, withFormik } from "formik";
import { pick } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { EReviewOP } from "data/drafts/types";
import { IExperience, TUpdateExperienceFormValues } from "data/experiences/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";

import { paths } from "routing/paths";

import draftsService from "services/api/drafts";

import UpdateExpView from "components/experiences/UpdateExpView";
import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import { daysEmptyValues, initialValuesDraft } from "./config";
import useValidationSchema from "./validate";

const extraFieldsToPick = ["status"];

const ExperienceUpdatePage = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const { clear, addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();

	const validationSchema = useValidationSchema();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [draftData, setDraftData] = useState<Partial<IExperience>>(initialValuesDraft);

	useEffect(() => {
		clear();

		getSavedExperience();

		// eslint-disable-next-line
	}, [id]);

	const getSavedExperience = async () => {
		try {
			setIsLoading(true);

			const savedData = await draftsService.getDetails(id);

			setDraftData(pick(savedData, [...Object.keys(initialValuesDraft), ...extraFieldsToPick]));

			setIsLoading(false);
		} catch (error) {
			handleAndNotify(error);

			// @ts-ignore
			if (error?.getStatus() === 400 || error?.getStatus() === 404) {
				history.push(paths.NOT_FOUND);
			}
		}
	};

	const saveMediaFile = async (blob: Blob) => {
		try {
			setIsLoading(true);

			window.scrollTo(0, 0);

			const mediaUrlObject = await draftsService.getSignedMediaUrl(id);

			const formData = new FormData();

			// @ts-ignore
			Object.keys(mediaUrlObject.fields).forEach(key => formData.append(key, mediaUrlObject.fields[key]));
			formData.append("file", blob);

			// @ts-ignore
			await axios.post(mediaUrlObject.url, formData);

			setTimeout(() => {
				getSavedExperience();
			}, 5000); // Time for get updated, with new media files draft data
		} catch (error) {
			handleAndNotify(error);
		}
	};

	const deleteMediaFile = async (mediaId: string) => {
		try {
			setIsLoading(true);

			window.scrollTo(0, 0);

			await draftsService.deleteRemoveFromGallery(id, mediaId);

			setTimeout(() => {
				getSavedExperience();
			}, 5000); // Time for get updated, with new media files draft data
		} catch (error) {
			handleAndNotify(error);
		}
	};

	const handleSubmit = async (values: TUpdateExperienceFormValues) => {
		clear();

		const data = { ...values };

		if (!data.photo_main && data.media_gallery?.length) {
			data.photo_main = data.media_gallery[0].media_url;
		}

		if (data.schedule?.availability_periods?.length === 0) {
			data.schedule.availability_periods = [
				{
					from: "01-01",
					to: "12-31",
				},
			];
		}

		delete data.status;

		if (data.pricing?.currency) {
			delete data.pricing.currency;
		}
		if (data.pricing?.filter_price) {
			delete data.pricing.filter_price;
		}

		delete data.photo_main_HELPER;
		delete data.media_gallery;
		delete data.media_gallery_HELPER;
		delete data.media_rights_confirmed_HELPER;
		delete data.sendToReview_HELPER;

		try {
			const newData = await draftsService.patchPartialUpdate(id, data, { validate: true });

			if (values.sendToReview_HELPER) {
				await draftsService.postReview(id, EReviewOP.SUBMIT);

				await getSavedExperience();

				addSuccess("EXPERIENCE.UPDATE.SAVED_AND_SEND_TO_REVIEW", true);

				return;
			} else {
				addSuccess("EXPERIENCE.UPDATE.SAVED_AS_DRAFT", true);
			}

			setDraftData(pick(newData as Partial<IExperience>, [...Object.keys(initialValuesDraft), ...extraFieldsToPick]));
		} catch (error) {
			handleAndNotify(error);
		}
	};

	const UpdateExpViewFormik = useMemo(
		() =>
			withFormik({
				mapPropsToValues: () => {
					let mediaGalleryHelper;
					let mainPhotoHelper;

					if (draftData.media_gallery?.length) {
						mediaGalleryHelper = draftData.media_gallery.map(elem => ({
							media_url: elem.media_url,
							id: elem.id,
						}));

						if (draftData.photo_main) {
							mainPhotoHelper = {
								media_url: draftData.photo_main,
								id: mediaGalleryHelper?.find(elem => elem.media_url === draftData.photo_main).id || "",
							};

							mediaGalleryHelper = mediaGalleryHelper?.filter(elem => elem.media_url !== mainPhotoHelper.media_url);
						} else {
							mainPhotoHelper = mediaGalleryHelper?.length ? mediaGalleryHelper[0] : undefined;

							if (mainPhotoHelper) {
								mediaGalleryHelper = mediaGalleryHelper.filter(elem => elem.id !== mainPhotoHelper.id);
							}
						}
					}

					if (draftData.schedule?.availability_periods && draftData.schedule.availability_periods.length === 1) {
						if (
							draftData.schedule.availability_periods[0].from === "01-01" &&
							draftData.schedule.availability_periods[0].to === "12-31"
						) {
							draftData.schedule.availability_periods = [];
						}
					}

					if (draftData?.schedule?.available_days_of_week) {
						draftData.schedule.available_days_of_week = {
							...daysEmptyValues,
							...draftData.schedule.available_days_of_week,
						};
					}

					return {
						...initialValuesDraft,
						...draftData,
						...(mediaGalleryHelper && { media_gallery_HELPER: mediaGalleryHelper }),
						...(mainPhotoHelper && { photo_main_HELPER: mainPhotoHelper }),
					};
				},
				handleSubmit,
				enableReinitialize: true,
				validationSchema,
			})(({ ...formikProps }: FormikProps<TUpdateExperienceFormValues>) => (
				<UpdateExpView
					{...formikProps}
					saveMediaCallback={saveMediaFile}
					deleteMediaCallback={deleteMediaFile}
					daysEmptyValues={daysEmptyValues}
				/>
			)),

		// eslint-disable-next-line
		[draftData],
	);

	return <AppWrapper>{isLoading ? <Loader /> : <UpdateExpViewFormik />}</AppWrapper>;
};

export default ExperienceUpdatePage;
