import { Avatar } from "@material-ui/core";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

interface IDashboardWelcomeProps {
	firstName: string;
	avatarUrl?: string;
}

const Wrapper = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const StyledAvatar = styled(Avatar)`
	margin-right: 25px;
	width: 86px;
	height: 86px;
`;

const WelcomeText = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.33;
	color: ${colors.mainFontColor};
`;

const Text = styled.div`
	margin-top: 8px;
	font-family: ${MontserratFontFamily};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.49;
	color: ${colors.bermudaGray};
`;

const DashboardWelcome = ({ firstName, avatarUrl }: IDashboardWelcomeProps) => {
	const { t } = useTranslation("ui");

	return (
		<Wrapper>
			<StyledAvatar alt={`Avatar localhost - ${firstName}`} src={avatarUrl} />

			<div>
				<WelcomeText>
					{t("DASHBOARD_HELLO")} {firstName}
				</WelcomeText>

				<Text>{t("DASHBOARD_WELCOME_BACK")}</Text>
			</div>
		</Wrapper>
	);
};

export default DashboardWelcome;
