import { Box, Snackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Alert } from "@material-ui/lab";
import { omit } from "lodash-es";
import styled from "styled-components";

import useNotification from "hooks/useNotification";

import config from "./config";
import { INotificationSnackbar } from "./types";

const StyledAlert = styled(Alert)`
	.MuiAlert-message {
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-word;
		font-weight: 400;
	}
`;

interface INotification {
	snackbarProps?: INotificationSnackbar;
}

const Notification = ({ snackbarProps }: INotification) => {
	const { notifications, remove, clear } = useNotification();

	const isNotifications = notifications.length > 0;

	return notifications.length > 0 ? (
		<Snackbar
			id="alert_message"
			open={isNotifications}
			autoHideDuration={config.autoHideDuration}
			anchorOrigin={{ vertical: config.positionVertical, horizontal: config.positionHorizontal }}
			TransitionComponent={Slide}
			// @todo:fix - when we can add a new message to the queue then is called this onClose
			// finally we can't to it and only one message can be visible or we have to add them all at once
			onClose={!snackbarProps?.disableClose ? clear : undefined}
			disableWindowBlurListener
			{...omit(snackbarProps, "disableClose")}
		>
			<div>
				{notifications.map(item => (
					<Box marginBottom={5} key={item.id}>
						<StyledAlert
							onClose={!snackbarProps?.disableClose ? () => (!!item.id ? remove(item.id) : undefined) : undefined}
							severity={item?.type}
							variant="filled"
						>
							{item.message}
						</StyledAlert>
					</Box>
				))}
			</div>
		</Snackbar>
	) : null;
};

export default Notification;
