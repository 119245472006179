import { Box, Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { memo } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import { StyledLink } from "styles/common";

interface ICategoryTileProps {
	imgUrl: string;
	categoryName: string;
	filterName: string;
}

const ImgContent = styled(Box)<{ $backgroundUrl: string }>`
	position: relative;
	background-image: url(${props => props.$backgroundUrl});
	background-position: center;
	background-repeat: no-repeat;
	background-size: inherit;
	border-radius: 4px;
	width: 100%;
	height: 100%;
	transition: all 0.5s linear;
	cursor: pointer;

	&:hover {
		transform: scale(1.01);
	}
`;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		categoryContent: {
			[theme.breakpoints.down("sm")]: {
				width: 304,
				height: 550,
			},
			width: 434,
			height: 661,
			borderRadius: 4,
			background: "none",
		},
		nameContent: {
			position: "absolute",
			top: 160,
			right: -140,
			transform: "rotate(90deg)",
			zIndex: 100,
		},
		nameLabel: {
			color: theme.palette.text.homePageFont,
			fontWeight: "bold",
			fontSize: 18,
			lineHeight: "20px",
			letterSpacing: "0.56px",
			flexShrink: 0,
		},
		pictureContent: {
			[theme.breakpoints.down("sm")]: {
				backgroundSize: "cover",
			},
			backgroundSize: "inherit",
			width: "100%",
			height: "100%",
		},
		dividerStyle: {
			marginLeft: 10,
			background: theme.palette.primary.main,
			height: "1px",
			width: "-webkit-fill-available",
		},
		exploreBtn: {
			[theme.breakpoints.down("sm")]: {
				width: 90,
				height: 40,
				fontSize: 12,
			},
			fontSize: 15,
			fontWeight: 500,
			color: "#fff",
			boxShadow: "0 0 8px 0 rgba(55, 71, 79, 0.17)",
			border: "solid 1px #fafafa",
			marginBottom: 30,
			minWidth: 126,
			height: 48,
		},
	}),
);

const CategoryTile = memo(({ imgUrl, categoryName, filterName }: ICategoryTileProps) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<StyledLink to={fillRoute(paths.SEARCH_CATEGORY, { category: categoryName })} $withoutHoverUnderline>
			<Box className={classes.categoryContent}>
				<ImgContent display="flex" alignItems="flex-end" justifyContent="center" $backgroundUrl={imgUrl}>
					<Button className={classes.exploreBtn} variant="outlined">
						{t("HP.CATEGORIES.EXPLORE_BTN")}
					</Button>
				</ImgContent>

				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					className={classes.nameContent}
					width={250}
				>
					<Typography className={classes.nameLabel}>{t("EXPERIENCE.CATEGORY." + filterName)}</Typography>
					<Box className={classes.dividerStyle} />
				</Box>
			</Box>
		</StyledLink>
	);
});

export default CategoryTile;
