import { Box, useTheme } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";

import useTranslation from "hooks/useTranslation";

import IconStringArray from "../../../IconStringArray";
import { TitleSection } from "../../index";

interface ICovidInfoProps {
	covidSafetyMeasuresArray: string[];
}

const CovidInfo = ({ covidSafetyMeasuresArray }: ICovidInfoProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Box>
			<TitleSection>{t("EXPERIENCE_DETAILS_TRAVELLER.SUBTITLE_EXP_COVID_SAFETY")}</TitleSection>

			<IconStringArray
				icon={<CheckCircleOutline style={{ fontSize: 20, color: theme.palette.primary.main }} />}
				valuesArray={covidSafetyMeasuresArray}
				translationKey="EXPERIENCE.DISASTER_POLICIES"
			/>
		</Box>
	);
};

// @ts-ignore
export default CovidInfo;
