import { ReactNode } from "react";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

interface ICommonParagraphProps {
	mainTitle: ReactNode | string;
	subTitle?: ReactNode | string;
	description?: ReactNode | string;
	withMarginTop?: boolean;
	isSecondHeader?: boolean;
	isLightTitle?: boolean;
	isTextAlignCenter?: boolean;
}

const Wrapper = styled.section<{ addMarginTop?: boolean }>`
	width: 90%;
	${props => props.addMarginTop && "margin-top: 80px;"}

	${media.tablet`
    width: 100%;
    margin-top: 40px;
  `};
`;

const Title = styled.h1<{ lightTitle?: boolean; textAlignCenter?: boolean }>`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: ${props => (props.lightTitle ? "30px" : "36px")};
	${props => props.textAlignCenter && "text-align: center;"}
	font-weight: bold;
	line-height: 1.08;
	letter-spacing: 0.07px;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 26px;
  `};
`;

const SubTitle = styled.h2<{ textAlignCenter?: boolean }>`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 26px;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: 0.05px;
	color: ${colors.mainFontColor};
	${props => props.textAlignCenter && "text-align: center;"}

	${media.tablet`
    font-size: 20px;
  `};
`;

export const Description = styled.div<{ textAlignCenter?: boolean }>`
	margin-top: 20px;
	opacity: 0.8;
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	line-height: 1.69;
	letter-spacing: 0.03px;
	color: ${colors.mainFontColor};
	${props => props.textAlignCenter && "text-align: center;"}

	p {
		display: inline;
		color: ${colors.sunShade};
	}

	${media.tablet`
    font-size: 14px;
  `};
`;

export const SmallerTitle = styled.h2<{ textAlignCenter?: boolean }>`
	margin: 0 0 40px;
	font-family: ${MontserratFontFamily};
	font-weight: bold;
	font-size: 30px;
	line-height: 1.08;
	letter-spacing: 0.07px;
	${props => props.textAlignCenter && "text-align: center;"}

	${media.tablet`
    font-size: 26px;
    margin-top: 40px;
  `};
`;

const CommonParagraph = ({
	mainTitle,
	isSecondHeader,
	subTitle,
	description,
	withMarginTop,
	isLightTitle,
	isTextAlignCenter,
}: ICommonParagraphProps) => (
	<Wrapper addMarginTop={withMarginTop}>
		<Title as={isSecondHeader ? "h2" : "h1"} lightTitle={isLightTitle} textAlignCenter={isTextAlignCenter}>
			{mainTitle}
		</Title>

		{subTitle && (
			<SubTitle as={isSecondHeader ? "h3" : "h2"} textAlignCenter={isTextAlignCenter}>
				{subTitle}
			</SubTitle>
		)}

		{description && <Description textAlignCenter={isTextAlignCenter}>{description}</Description>}
	</Wrapper>
);

export default CommonParagraph;
