import { Grid } from "@material-ui/core";
import { ChangeEvent, useState } from "react";

import { TStatus } from "data/backoffice/financials/types";

import useTranslation from "hooks/useTranslation";

import FilterSearch from "ui/FilterSearch";
import AutocompleteUI, { Option } from "ui/forms/Autocomplete";
import DatePicker from "ui/forms/date-time/DateRangePicker";
import RangeInput, { IRangeInputValues } from "ui/forms/date-time/RangeInput";

import { StyledInput } from "../../backoffice/shared.styled";
import { statusConfig } from "./config";

interface IDatesStartEnd {
	start: Date | null;
	end: Date | null;
}

export interface ISelectedFilters {
	booking_id: string;
	dates: IDatesStartEnd;
	price: IRangeInputValues;
	status: TStatus | "";
}

export enum EType {
	"EARNINGS" = "EARNINGS",
	"WITHDRAWALS" = "WITHDRAWALS",
}

interface IFilterSearchPastEarningsProps {
	applySelectedFiltersAction: (v: ISelectedFilters) => void;
	type?: EType;
}

const FilterSearchPastEarnings = ({
	applySelectedFiltersAction,
	type = EType.EARNINGS,
}: IFilterSearchPastEarningsProps) => {
	const { t } = useTranslation();

	const defaultRangeInput = {
		from: "",
		to: "",
	};

	const initialDates = {
		start: null,
		end: null,
	};

	const [selectedDates, setSelectedDates] = useState<IDatesStartEnd>(initialDates);
	const [selectedBookingId, setSelectedBookingId] = useState<string>("");
	const [selectedPrice, setSelectedPrice] = useState<IRangeInputValues>(defaultRangeInput);
	const [selectedStatus, setSelectedStatus] = useState<TStatus | "">("");

	const setDefaultValues = () => {
		setSelectedDates(initialDates);
		setSelectedPrice(defaultRangeInput);

		setSelectedBookingId("");
		setSelectedStatus("");
	};

	const onChangeDates = (value: IDatesStartEnd) => {
		setSelectedDates(value);
	};

	const onChangePriceInput = (value: IRangeInputValues) => {
		setSelectedPrice(value);
	};

	const onChangeBookingId = (event: ChangeEvent<HTMLInputElement>) => {
		setSelectedBookingId(event.target.value);
	};

	const onChangeStatus = (value: string) => setSelectedStatus(value as TStatus);

	const setFilters = (value: boolean) => {
		if (value) {
			applySelectedFiltersAction({
				dates: selectedDates,
				booking_id: selectedBookingId,
				price: selectedPrice,
				status: selectedStatus,
			});
		}
	};

	return (
		<FilterSearch onClickApply={setFilters} onClickClear={setDefaultValues}>
			<Grid container spacing={10}>
				{type === EType.EARNINGS && (
					<Grid item md={4} xs={12}>
						<StyledInput
							fullWidth
							id="booking-id-input"
							label={t("MY_FINANCES.PAST_EARNINGS.FILTER_SEARCH.FIELDS.BOOKING_ID")}
							value={selectedBookingId}
							onChange={onChangeBookingId}
							type="text"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				)}

				<Grid item md={3} xs={12}>
					<DatePicker
						initialValue={selectedDates}
						label={t("MY_FINANCES.PAST_EARNINGS.FILTER_SEARCH.FIELDS.DATE")}
						onChange={onChangeDates}
					/>
				</Grid>

				{type === EType.WITHDRAWALS && (
					<>
						<Grid item md={3} xs={12}>
							<RangeInput
								initialValue={selectedPrice}
								labelFirstInput={t("MY_FINANCES.PAST_EARNINGS.FILTER_SEARCH.FIELDS.MIN_PRICE")}
								labelSecondInput={t("MY_FINANCES.PAST_EARNINGS.FILTER_SEARCH.FIELDS.MAX_PRICE")}
								handleChange={onChangePriceInput}
								name="price-input-range"
								typeInputs="number"
								withoutDecimalsRemove
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<AutocompleteUI
								name="status"
								label="Status"
								options={statusConfig}
								textFieldProps={{ variant: "standard" }}
								onChange={v => onChangeStatus((v as Option)?.value?.toString() || "")}
								value={selectedStatus}
								filterSelectedOptions
							/>
						</Grid>
					</>
				)}
			</Grid>
		</FilterSearch>
	);
};

export default FilterSearchPastEarnings;
