import { Box, Button, Dialog, DialogActions, DialogContent, Grid, InputAdornment, TextField } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import { DatePicker as DatePickerUI } from "@material-ui/pickers";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import { DATE_FORMATS } from "utils/dates";

import MuiDateProvider from "providers/MuiDateProvider";

import FilterButton from "ui/buttons/FilterButton";

import colors from "styles/colors";
import media from "styles/media";

const CalendarTodayIcon = styled(CalendarToday)<{ $customMarginRight: string; $isDisabled?: boolean }>`
	margin-right: ${props => props.$customMarginRight};
	width: 15px;
	height: 17px;
	color: ${colors.hokiColor};

	${props =>
		props.$isDisabled &&
		css`
			opacity: 0.5;
		`}
`;

const cssOrangeBorder = css`
	.MuiInput-root {
		border-bottom: 1px solid ${colors.sunShade};
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after,
	.MuiInput-underline & {
		border: none !important;
	}
`;

const StyledDateInput = styled(TextField)<{ $isOrangeBorder?: boolean; $isDisabled?: boolean }>`
	width: 100%;

	${props => props.$isOrangeBorder && cssOrangeBorder};

	${props =>
		!props.$isDisabled &&
		css`
			* {
				cursor: pointer;
			}
		`}
`;

const StyledDialog = styled(Dialog)`
	padding: 10px;
`;

const StyledDialogContent = styled(DialogContent)`
	padding: 10px;
`;

const CalendarsWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;

	${media.desktop`
    flex-direction: column;
    justify-content: center;
  `};
`;

const StyledDatePicker = styled(DatePickerUI)`
	background: ${colors.lightGrayWhite};
`;

type TDateRange = { start: Date | null; end: Date | null };

interface IDateRangePickerProps {
	initialValue: TDateRange;
	label: string;
	onChange: ({ start, end }: TDateRange) => void;
	iconCustomMarginRight?: string;
	isOrangeBorder?: boolean;
	disabled?: boolean;
	withExtraButtons?: boolean;
}

const DateRangePicker = ({
	initialValue,
	label,
	onChange,
	iconCustomMarginRight = "0",
	isOrangeBorder = false,
	withExtraButtons = false,
	disabled,
}: IDateRangePickerProps) => {
	const { t } = useTranslation("ui");

	const [open, setOpen] = useState<boolean>(false);
	const [usedExtraFilter, setUsedExtraFilter] = useState<boolean>(false);
	const [selectedDateStart, setSelectedDateStart] = useState<Date | null>(initialValue.start);
	const [selectedDateEnd, setSelectedDateEnd] = useState<Date | null>(initialValue.end);
	const [selectedDates, setSelectedDates] = useState<string>(
		`${initialValue.start ? format(initialValue.start, DATE_FORMATS.DATE_FORMAT2) : ""}-${
			initialValue.end ? format(initialValue.end, DATE_FORMATS.DATE_FORMAT2) : ""
		}`,
	);

	useEffect(() => {
		setSelectedDateStart(initialValue.start);
		setSelectedDateEnd(initialValue.end);

		setSelectedDates(
			`${initialValue.start ? format(initialValue.start, DATE_FORMATS.DATE_FORMAT2) : ""}-${
				initialValue.end ? format(initialValue.end, DATE_FORMATS.DATE_FORMAT2) : ""
			}`,
		);
	}, [initialValue.start, initialValue.end]);

	useEffect(() => {
		if (usedExtraFilter) {
			handleSubmit();

			setUsedExtraFilter(false);
		}

		// eslint-disable-next-line
	}, [usedExtraFilter]);

	const handleExtraFilter = (value: string) => {
		let fromDuration;
		let duration;

		switch (value) {
			case "lastSevenDays":
				fromDuration = -7;
				duration = 0;
				break;
			case "lastThreeDays":
				fromDuration = -3;
				duration = 0;
				break;
			case "yesterday":
				fromDuration = -1;
				duration = -1;
				break;
			case "today":
				duration = 0;
				break;
			case "tomorrow":
				fromDuration = 1;
				duration = 1;
				break;
			case "threeDays":
				duration = 3;
				break;
			case "sevenDays":
				duration = 7;
				break;
			case "fourteenDays":
				duration = 14;
				break;
			case "thirtyDays":
				duration = 30;
				break;
			default:
				duration = 0;
				break;
		}

		const pushedStartDate = new Date();

		if (fromDuration) {
			pushedStartDate.setDate(pushedStartDate.getDate() + fromDuration);
		}

		setSelectedDateStart(pushedStartDate);

		const pushedEndDate = new Date();

		pushedEndDate.setDate(pushedEndDate.getDate() + duration);

		setSelectedDateEnd(pushedEndDate);

		setUsedExtraFilter(true);
	};

	const openCalendar = () => {
		if (disabled) {
			return;
		}

		setOpen(prevState => !prevState);
	};

	const handleClose = () => setOpen(false);

	const handleSubmit = () => {
		setOpen(false);

		setSelectedDates(
			`${selectedDateStart ? format(selectedDateStart, DATE_FORMATS.DATE_FORMAT2) : ""} - ${
				selectedDateEnd ? format(selectedDateEnd, DATE_FORMATS.DATE_FORMAT2) : ""
			}`,
		);

		onChange({ start: selectedDateStart, end: selectedDateEnd });
	};

	return (
		<>
			<StyledDateInput
				id="start-end-date"
				value={selectedDates}
				label={label}
				onClick={openCalendar}
				InputLabelProps={{
					shrink: true,
				}}
				InputProps={{
					readOnly: true,
					startAdornment: (
						<InputAdornment position="start">
							<CalendarTodayIcon $customMarginRight={iconCustomMarginRight} $isDisabled={disabled} />
						</InputAdornment>
					),
				}}
				disabled={disabled}
				$isDisabled={disabled}
				$isOrangeBorder={isOrangeBorder}
			/>

			<StyledDialog open={open} onClose={handleClose} aria-labelledby="form-dialog-date-range-picker" maxWidth="lg">
				<StyledDialogContent>
					<>
						<MuiDateProvider>
							<CalendarsWrapper>
								<Box mr={15}>
									<StyledDatePicker
										disableToolbar
										variant="static"
										label="Calendar start"
										value={selectedDateStart}
										onChange={(event: Date) => setSelectedDateStart(new Date(event))}
									/>
								</Box>

								<Box>
									<StyledDatePicker
										disableToolbar
										variant="static"
										label="Calendar end"
										value={selectedDateEnd}
										onChange={(event: Date) => setSelectedDateEnd(new Date(event))}
									/>
								</Box>
							</CalendarsWrapper>
						</MuiDateProvider>

						{withExtraButtons && (
							<Box paddingX="20px" maxWidth="650px">
								<Grid container spacing={10}>
									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="lastSevenDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="lastThreeDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="yesterday"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="today"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="tomorrow"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="threeDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="sevenDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="fourteenDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>

									<Grid item lg={4} sm={6} xs={12}>
										<FilterButton
											nameFilter="thirtyDays"
											filterValue=""
											handleClickFilter={handleExtraFilter}
											compactWidth
										/>
									</Grid>
								</Grid>
							</Box>
						)}
					</>
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={() => handleSubmit()} color="primary">
						{t("DATE_PICKER_ADD_BTN")}
					</Button>
				</DialogActions>
			</StyledDialog>
		</>
	);
};

export default DateRangePicker;
