import { Helmet } from "react-helmet-async";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph from "ui/CommonParagraph";

import colors from "styles/colors";
import { Container, StyledLink } from "styles/common";

const DisclaimerImpressumPage = () => {
	const { t, withComponents } = useTranslation(["common", "seo"]);

	return (
		<AppWrapper>
			<Helmet>
				<meta name="description" content={t("SEO.DISCLAIMER_IMPRESSUM.META.DESCRIPTION")} />
				<meta property="og:description" content={t("SEO.DISCLAIMER_IMPRESSUM.META.DESCRIPTION")} />
			</Helmet>

			<Container>
				<Breadcrumbs
					lastBreadcrumbLabel={`${t("HELP.DISCLAIMER_IMPRESSUM.DISCLAIMER_TITLE")} ${t(
						"HELP.DISCLAIMER_IMPRESSUM.IMPRESSUM_TITLE",
					)}`}
				/>
				<CommonParagraph
					withMarginTop
					mainTitle={t("HELP.DISCLAIMER_IMPRESSUM.DISCLAIMER_TITLE")}
					description={withComponents(
						"HELP.DISCLAIMER_IMPRESSUM.DISCLAIMER_DESCRIPTION",
						{
							Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
						},
						null,
					)}
				/>

				<CommonParagraph
					withMarginTop
					isSecondHeader
					mainTitle={t("HELP.DISCLAIMER_IMPRESSUM.IMPRESSUM_TITLE")}
					description={withComponents(
						"HELP.DISCLAIMER_IMPRESSUM.IMPRESSUM_DESCRIPTION",
						{
							Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
						},
						null,
					)}
				/>
			</Container>
		</AppWrapper>
	);
};

export default DisclaimerImpressumPage;
