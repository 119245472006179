import tripImage from "assets/images/background/trip_photo.jpg";

import ConfirmEmailView from "components/auth/ConfirmEmailView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

import { LanguageSelectorOnboarding, TeaserOnboarding } from "../index";
import { LanguageSelectorContainer } from "../shared.styled";

const AuthConfirmEmailOnboardingPage = () => (
	<AppWithImageWrapper
		image={tripImage}
		teaserElem={<TeaserOnboarding />}
		headerElem={
			<LanguageSelectorContainer>
				<LanguageSelectorOnboarding />
			</LanguageSelectorContainer>
		}
	>
		<ConfirmEmailView onboarding />
	</AppWithImageWrapper>
);

export default AuthConfirmEmailOnboardingPage;
