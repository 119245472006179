import { Box, Divider, Grid } from "@material-ui/core";
import { useState } from "react";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import AccountStatus from "components/dashboard/AccountStatus";
import WelcomeOnPage from "components/dashboard/DashboardWelcome";
import AppWrapper from "components/layout/AppWrapper";
import GenerateReferralBox from "components/referrals/GenerateReferralBox";

import Breadcrumbs from "ui/Breadcrumbs";

import { Container } from "styles/common";

import { WelcomeContent } from "../shared.styled";
import StatisticsSection from "./sections/Statistics";
import UpcomingBookingsSection from "./sections/UpcomingBookings";

const AccountDashboardPage = () => {
	const { t } = useTranslation();

	const userData = useAppSelector(selectUser);

	const [showAccountStatusAllSet, setShowAccountStatusAllSet] = useState<true>();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.DASHBOARD_LABEL")} />

				<WelcomeContent>
					<Grid container spacing={10}>
						<Grid item md={4} xs={12}>
							<WelcomeOnPage
								firstName={userData?.public_profile?.first_name || userData?.profile_draft?.first_name || ""}
								avatarUrl={
									userData?.public_profile?.pictures?.profile || userData?.profile_draft?.pictures?.profile || undefined
								}
							/>
						</Grid>
					</Grid>
				</WelcomeContent>

				<Box paddingY="40px">
					<StatisticsSection showAccountStatusBox={showAccountStatusAllSet} />
				</Box>

				<AccountStatus hideIfAllSet allSetCallback={setShowAccountStatusAllSet} />

				<Divider />

				<Box paddingY="40px">
					<Grid container spacing={10}>
						<Grid item md={6} xs={12}>
							<UpcomingBookingsSection />
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box paddingTop="60px">
					<GenerateReferralBox variant="withStatistics" />
				</Box>
			</Container>
		</AppWrapper>
	);
};

export default AccountDashboardPage;
