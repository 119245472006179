import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo, ReactNode } from "react";
import styled from "styled-components";

import AthensSmall from "assets/images/binipoolConcept/athens/experiences_athens.png";
import AthensMedium from "assets/images/binipoolConcept/athens/experiences_athens@2x.png";
import BarcelonaSmall from "assets/images/binipoolConcept/barcelona/experiences_barcelona.png";
import BarcelonaMedium from "assets/images/binipoolConcept/barcelona/experiences_barcelona@2x.png";
import BerlinSmall from "assets/images/binipoolConcept/berlin/experiences_berlin.png";
import BerlinMedium from "assets/images/binipoolConcept/berlin/experiences_berlin@2x.png";
import BudapestSmall from "assets/images/binipoolConcept/budapest/experiences_budapest.png";
import BudapestMedium from "assets/images/binipoolConcept/budapest/experiences_budapest@2x.png";
import CopenhagenSmall from "assets/images/binipoolConcept/copenhagen/experiences_copenhagen.png";
import CopenhagenMedium from "assets/images/binipoolConcept/copenhagen/experiences_copenhagen@2x.png";
import StockholmSmall from "assets/images/binipoolConcept/stockholm/experiences_stockholm.png";
import StockholmMedium from "assets/images/binipoolConcept/stockholm/experiences_stockholm@2x.png";

import useTranslation from "hooks/useTranslation";

import { SmallerTitle } from "ui/CommonParagraph";

import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

interface ICityResponsiveImgProps {
	altDesc: string;
	imgSmall: string;
	imgMedium: string;
}

interface ITileProps {
	picture: ReactNode;
	title: string;
	description: string;
}

const Container = styled.div`
	margin: 0 auto;
	width: 1340px;

	${media.largeDesktop`
    width: 95%;
  `};
`;

const MainContent = styled.div`
	width: 100%;
	margin: 2.188rem auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	${media.tablet`
    justify-content: center;
    flex-direction: column;
  `};
`;

const TileWrapper = styled.div`
	width: 400px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;

	${media.phone`
    width: 100%;
  `};
`;

const TileImgContent = styled.div`
	width: 90%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	${media.tablet`
     width: 100%;
  `};
`;

const TileTextContent = styled.div`
	margin: 0 auto;
	height: 12.5rem;
	max-width: 340px;

	${media.tablet`
     height: auto;
     margin-bottom: 30px;
  `};
`;

const TileTitle = styled.h3`
	margin: 0.625rem 0;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.44;
	letter-spacing: 0.04px;
`;

const TileDesc = styled.div`
	margin: 0;
	font-size: ${dimensions.fontSize.large};
	line-height: 1.93;
	letter-spacing: 0.03px;
`;

const CityResponsiveImg = ({ altDesc, imgSmall, imgMedium }: ICityResponsiveImgProps) => (
	<img srcSet={`${imgSmall} 1x, ${imgMedium} 1.5x`} src={imgMedium} title={altDesc} alt={altDesc} width="100%" />
);

const Tile = memo(({ picture, title, description }: ITileProps) => (
	<TileWrapper>
		<TileImgContent>{picture}</TileImgContent>

		<TileTextContent>
			<TileTitle>{title}</TileTitle>
			<TileDesc>{description}</TileDesc>
		</TileTextContent>
	</TileWrapper>
));

const SomeExperiences = memo(() => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<WrapperSection>
			<Container>
				<SmallerTitle textAlignCenter={!isSmallScreen}>{t("BINIPOOL_CONCEPT.TITLE_SOME_EXP")}</SmallerTitle>

				<MainContent>
					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={StockholmSmall}
								imgMedium={StockholmMedium}
								altDesc="binipool - Discover Stockholm in 60 minutes - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_STOCKHOLM")}
						description={t("BINIPOOL_CONCEPT.DESC_STOCKHOLM")}
					/>

					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={BerlinSmall}
								imgMedium={BerlinMedium}
								altDesc="binipool - Berlin's Past and Present - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_BERLIN")}
						description={t("BINIPOOL_CONCEPT.DESC_BERLIN")}
					/>

					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={BarcelonaSmall}
								imgMedium={BarcelonaMedium}
								altDesc="binipool - Architectural Walk of Barcelona - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_BARCELONA")}
						description={t("BINIPOOL_CONCEPT.DESC_BARCELONA")}
					/>

					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={AthensSmall}
								imgMedium={AthensMedium}
								altDesc="binipool - The Instagrammable Spots of Athens - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_ATHENS")}
						description={t("BINIPOOL_CONCEPT.DESC_ATHENS")}
					/>

					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={BudapestSmall}
								imgMedium={BudapestMedium}
								altDesc="binipool - Discover Budapest's Art and Culture - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_BUDAPESTS")}
						description={t("BINIPOOL_CONCEPT.DESC_BUDAPESTS")}
					/>

					<Tile
						picture={
							<CityResponsiveImg
								imgSmall={CopenhagenSmall}
								imgMedium={CopenhagenMedium}
								altDesc="binipool - Photogenic Copenhagen - picture"
							/>
						}
						title={t("BINIPOOL_CONCEPT.TITLE_COPENHAGEN")}
						description={t("BINIPOOL_CONCEPT.DESC_COPENHAGEN")}
					/>
				</MainContent>
			</Container>
		</WrapperSection>
	);
});

export default SomeExperiences;
