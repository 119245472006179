import registerSocialImg from "assets/images/RegisterSocialImg.jpg";

import LoginView from "components/auth/LoginView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

const LoginPage = () => (
	<AppWithImageWrapper image={registerSocialImg}>
		<LoginView />
	</AppWithImageWrapper>
);

export default LoginPage;
