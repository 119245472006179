import styled from "styled-components";

import colors from "styles/colors";

import { StyledButton } from "../../shared.styled";

export const ModalContent = styled.div`
	text-align: center;
`;

export const IconContainer = styled.div`
	display: inline-block;
	position: relative;
	margin-bottom: 16px;

	svg {
		font-size: 120px;
		color: ${colors.sunShade};
	}
`;

export const Desc = styled.p`
	margin-bottom: 16px;
	font-size: 14px;
	line-height: 24px;
	color: #666;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LinkContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`;

export const CTAButton = styled(StyledButton)`
	width: auto;
`;
