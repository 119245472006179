import { CreateOutlinedIcon, EditBtn } from "../../shared.styled";

const EditPen = ({ onClickEdit }: { onClickEdit?: () => void }) => {
	const showHideEditableItem = () => {
		if (onClickEdit) {
			onClickEdit();
		}
	};

	return (
		<EditBtn onClick={showHideEditableItem}>
			<CreateOutlinedIcon />
		</EditBtn>
	);
};

export default EditPen;
