import { Box, Chip, Grid } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import useTranslation from "hooks/useTranslation";

import { SingleDatePickerComponent } from "../../forms/SingleDatePickerComponent";

export interface IStringTagsOneDate {
	onChange: (newValue) => void;
	value: string[];
}

const StringTagsOneDate = ({ onChange, value }: IStringTagsOneDate) => {
	const { t } = useTranslation();

	const handleDelete = index => onChange(value.filter((_, i) => i !== index));
	const addNewDates = valueDate => onChange([...value, valueDate]);

	return (
		<Grid container spacing={5} direction="column">
			<Grid item xs container direction="row">
				{value.map((v, i) => (
					<Box display="flex" pr={5} pb={5} key={i}>
						<Chip color="primary" label={v} onDelete={() => handleDelete(i)} deleteIcon={<Cancel />} />
					</Box>
				))}

				<SingleDatePickerComponent
					callBackDate={addNewDates}
					label={
						!!value.length
							? t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_MORE")
							: t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.ADD_START_DATE")
					}
				/>
			</Grid>
		</Grid>
	);
};

export default StringTagsOneDate;
