import { Box } from "@material-ui/core";
import { memo, ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import draftsService from "services/api/drafts";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import fillRoute from "utils/routes";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";

import media from "styles/media";

import { SubTitle, Title } from "../../auth/shared";

interface ILayoutProps {
	children: ReactNode;
}

const StyledDialog = styled(Dialog)`
	div {
		min-width: 400px;
		${media.tablet`
      min-width: auto;
  `};
	}
`;

// @todo:fix - export.
export const MainLayout = memo(({ children }: ILayoutProps) => {
	const { t } = useTranslation();

	const history = useHistory();
	const location = useLocation();

	const userData = useAppSelector(selectUser);

	const [openDraftsModal, setOpenDraftsModal] = useState<boolean>(false);
	const [openPhoneVerifyModal, setPhoneVerifyModal] = useState<boolean>(false);

	useEffect(() => {
		if (
			userData?.userType &&
			userData.userType !== "TRAVELER" &&
			// @ts-ignore
			!window?.__LOCALBINI__?.showPhoneModal &&
			!localStorage.getItem("showedDraftsModal") &&
			location.pathname !== fillRoute(paths.MY_SETTINGS, { page: "security" }) &&
			!location.pathname.includes("experience/create") &&
			!location.pathname.includes("my-experiences") &&
			// @todo:fix - remove it in nearest future
			new Date(userData?.created_on).getTime() < new Date("12-01-2021").getTime()
		) {
			const fetchDrafts = async () => {
				try {
					const drafts = await draftsService.fetchAll();

					if (drafts.data.length) {
						setOpenDraftsModal(true);
					}
				} catch (e) {
					console.error(e);
				}
			};

			fetchDrafts();
		}
	}, [userData, location.pathname]);

	useEffect(() => {
		if (
			// @ts-ignore
			window?.__LOCALBINI__?.showPhoneModal &&
			!location.pathname.includes("onboarding") &&
			!([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(userData?.userType)
		) {
			setPhoneVerifyModal(true);
		}

		// eslint-disable-next-line
	}, [location.pathname]);

	const handleClose = () => {
		// @ts-ignore
		if (window?.__LOCALBINI__?.showPhoneModal) {
			// @ts-ignore
			window.__LOCALBINI__.showPhoneModal = false;
		}

		setPhoneVerifyModal(false);
	};

	const handleCloseDrafts = () => {
		localStorage.setItem("showedDraftsModal", "true");

		setOpenDraftsModal(false);
	};

	return (
		<>
			<StyledDialog showDialog={openPhoneVerifyModal} handleClose={handleClose} minHeight="230px">
				<Box textAlign="center">
					<Title>{t("MODAL.PHONE_VERIFY.TITLE")}</Title>

					<Box mt={5} mb={10}>
						<SubTitle>{t("MODAL.PHONE_VERIFY.SUBTITLE")}</SubTitle>
					</Box>

					<ActionButton
						type="button"
						onClick={() => {
							handleClose();

							history.push({ pathname: fillRoute(paths.MY_SETTINGS, { page: "security" }) });
						}}
						size="large"
						translationDefault={"MODAL.PHONE_VERIFY.CTA"}
					/>
				</Box>
			</StyledDialog>

			<StyledDialog showDialog={openDraftsModal} handleClose={handleCloseDrafts} minHeight="230px">
				<Box textAlign="center">
					<Title>{t("MODAL.VERIFY_EXPERIENCES.TITLE")}</Title>

					<Box mt={5} mb={10}>
						<SubTitle>{t("MODAL.VERIFY_EXPERIENCES.SUBTITLE")}</SubTitle>
					</Box>

					<ActionButton
						type="button"
						onClick={() => {
							handleCloseDrafts();

							history.push({ pathname: paths.EXPERIENCES });
						}}
						size="large"
						translationDefault={"MODAL.VERIFY_EXPERIENCES.CTA"}
					/>
				</Box>
			</StyledDialog>

			<div>{children}</div>
		</>
	);
});
