import { ECategories } from "data/categories/types";
import { EPromotingSections } from "data/experiences/types";
import { ECurrencySymbol } from "data/financials/types";

import { createSelectFromEnum } from "utils/enums";

export type TDictionaryName =
	| "CATEGORY"
	| "CATEGORIES"
	| "HEALTH_RESTRICTIONS"
	| "ACCESSIBILITY_TYPES"
	| "PHYSICAL_DIFFICULTY_LEVEL"
	| "DISASTER_POLICIES"
	| "CANCELLATION_POLICY"
	| "INCLUDED"
	| "EXCLUDED"
	| "JSON_PRICES_FOR_CHILDREN"
	| "JSON_PRICES_PER_TRAVELLER"
	| "FOOTER_TITLES"
	| "SKILLS_INTERESTS"
	| "FINANCIAL_TYPE"
	| "PROMOTING_TYPE";

const DICTIONARIES: Record<TDictionaryName, any[]> = {
	CATEGORIES: createSelectFromEnum(ECategories),
	FINANCIAL_TYPE: [
		{ label: "Income", value: "INCOME" },
		{ label: "Outcome", value: "OUTCOME" },
	],
	CATEGORY: Object.keys(ECategories),
	HEALTH_RESTRICTIONS: ["BACK_PROBLEMS", "PREGNANT", "HEART_OTHER"],
	ACCESSIBILITY_TYPES: [
		"CHILDREN",
		"HEARING_IMPAIRMENT",
		"INFANTS",
		"PETS",
		"REDUCED_MOBILITY",
		"SERVICE_ANIMALS",
		"STROLLER",
		"VISUALLY_IMPAIRMENT",
		"PUBLIC_TRANSIT",
	],
	INCLUDED: ["Museum ticket", "Water bottle", "Drink"],
	EXCLUDED: ["Subway ticket", "Food"],
	PHYSICAL_DIFFICULTY_LEVEL: ["EASY", "MODERATE", "CHALLENGING"],
	DISASTER_POLICIES: [
		"FACE_MASKS_REQUIRED",
		"FACE_MASKS_PROVIDED",
		"HAND_SANITIZER_PROVIDED",
		"SOCIAL_DISTANCING",
		"SANITIZED_HIGH_TRAFFIC_AREAS",
		"GEAR_SANITIZED",
		"CONTACTLESS_PAYMENTS",
	],
	CANCELLATION_POLICY: ["FLEXIBLE", "STANDARD", "MODERATE", "STRICT"],
	JSON_PRICES_FOR_CHILDREN: ["AGE", "PRICE_PER_PERSON", "YOUR_EARNINGS"],
	JSON_PRICES_PER_TRAVELLER: ["PARTICIPANTS", "PRICE_PER_PERSON", "YOUR_EARNINGS"],
	FOOTER_TITLES: ["SUPPORT", "HOST", "COMPANY", "PARTNERS"],
	SKILLS_INTERESTS: Object.values(ECategories),
	PROMOTING_TYPE: Object.values(EPromotingSections),
};

export const getDictionary = (dictionary: TDictionaryName) => DICTIONARIES[dictionary];

export const currencySymbolsDictionary = ECurrencySymbol;
