import { Box, Slider, TextField } from "@material-ui/core";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";
import { SliderProps } from "@material-ui/core/Slider/Slider";
import { ChangeEvent } from "react";

export interface INumberSlider {
	onChange: (newValue) => void;
	value: number;
	sliderProps?: SliderProps;
	inputProps?: InputBaseComponentProps;
}

const marks = [
	{
		value: 1,
		label: "1h",
	},
	{
		value: 48,
		label: "48h",
	},
];

const NumberSlider = ({ onChange, value, sliderProps, inputProps }: INumberSlider) => {
	const handleChangeSlider = (event: ChangeEvent<HTMLInputElement>, newValue: number) => {
		onChange(newValue);
	};

	const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const currentValueInput = parseInt(event.target.value, 10);
		if (!isNaN(currentValueInput)) {
			if (currentValueInput >= marks[0].value && currentValueInput <= marks[1].value) {
				onChange(currentValueInput);
			}
		}
	};

	const valuetext = (valueText: number) => `${valueText}H`;

	return (
		<>
			<Box display="flex" justifyContent="space-between" mt={15} mb={15} width={1}>
				<Box width={1} paddingTop="30px" paddingLeft="20px">
					<Slider
						value={value}
						getAriaValueText={valuetext}
						aria-labelledby="hours-number-slider"
						step={1}
						min={marks[0].value}
						max={marks[1].value}
						marks={marks}
						valueLabelDisplay="on"
						onChange={handleChangeSlider}
						{...sliderProps}
					/>
				</Box>

				<Box minWidth="118px" ml={15}>
					<TextField
						id="hours-number-input"
						label="hours"
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{ inputProps: { min: marks[0].value, max: marks[1].value, ...inputProps } }}
						value={value}
						variant="outlined"
						type="number"
						onChange={handleChangeInput}
						style={{ width: "100%" }}
					/>
				</Box>
			</Box>
		</>
	);
};

export default NumberSlider;
