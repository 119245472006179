import { CircularProgress } from "@material-ui/core";
import { CircularProgressProps } from "@material-ui/core/CircularProgress/CircularProgress";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

interface ICircularLoader extends CircularProgressProps {
	disabled?: boolean;
	absolute?: boolean;
	fixed?: boolean;
	children?: ReactNode;
	bg?: string;
}

const LoaderContainer = styled.div<{ absolute?: boolean; fixed?: boolean; bg: string }>`
	${props =>
		props.absolute &&
		css`
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: ${props.bg};
			z-index: 1000;
		`}
	${props =>
		props.fixed &&
		css`
			position: fixed;
		`}

  display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
`;

const CircularLoader = ({ children, disabled, absolute = true, fixed, bg = "transparent", ...rest }: ICircularLoader) =>
	!disabled ? (
		<LoaderContainer absolute={absolute} fixed={fixed} bg={bg}>
			<CircularProgress {...rest} />

			{children}
		</LoaderContainer>
	) : null;

export default CircularLoader;
