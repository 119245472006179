import { Box } from "@material-ui/core";
import { isValid } from "date-fns";
import { omit } from "lodash-es";
import { DateRange } from "newer-pickers";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AffiliateSession from "classes/AffiliateSession";

import { getDictionary } from "data/dictionaries";
import { IExperienceDTO, IUpcomingBooking } from "data/experiences/types";
import { CustomOptionType } from "data/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import experiencesService from "services/api/experiences";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectDiscoverList } from "store/selectors/homePage";
import { experienceTravellerActions } from "store/slices/experienceTraveller";
import { fetchExperiencesDiscover } from "store/slices/homePage";

import { accountTypeParam, languageParam, marketingIDParam } from "utils/constants";
import { dateObjectToString, DATE_FORMATS } from "utils/dates";
import fillRoute from "utils/routes";
import { getParams } from "utils/urls";

import SelectPeople from "components/experiences/SelectPeople";
import Discover from "components/home/Discover";
import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import EmptyList from "ui/EmptyList";
import RangePicker from "ui/forms/date-time/RangePicker";
import Sorter, { TSorter } from "ui/Sorter";

import { Container } from "styles/common";

import ExperiencesList from "./components/List";
import MoreFilters from "./components/MoreFilters";
import UpcomingBookings from "./components/UpcomingBookings";
import {
	FilterWrapper,
	MainSearchWrapper,
	NbExperiences,
	SearchButton,
	SearchIcon,
	StyledLinearProgress,
	StyledPlacesAutocompleteField,
} from "./styled";

export type ISearchParams = {
	text_search?: string;
	order?: "desc" | "asc";
	sort?: string;
	date_from?: string;
	date_to?: string;
	offset?: string;
	limit?: string;
	min_price?: string;
	max_price?: string;
	min_duration?: string;
	country?: string;
	city?: string;
	categories?: string | string[];
	languages?: string | string[];
	number_of_travelers?: string;
};

interface ISelectedPeople {
	nbAdults: number;
	nbKids: number;

	[key: string]: number;
}

const ExperienceSearchPage = memo(() => {
	const { t } = useTranslation(["common", "seo"]);

	const categoriesDict = getDictionary("CATEGORIES");

	const history = useHistory();
	const location = useLocation();
	const { country, city, category } = useParams<{ country?: string; city?: string; category?: string }>();

	const dispatch = useAppDispatch();

	const discoverList = useAppSelector(selectDiscoverList);

	const initialQueryParams: ISearchParams = omit(
		{
			...getParams(new URLSearchParams(location.search)),
			...(country && { country }),
			...(city && { city }),
		},
		[marketingIDParam, accountTypeParam, languageParam, AffiliateSession.affiliateUrlParam],
	);

	const [sorterDuration, setSorterDuration] = useState<TSorter>("NEUTRAL");
	const [sorterRating, setSorterRating] = useState<TSorter>("NEUTRAL");
	const [sorterPrice, setSorterPrice] = useState<TSorter>("NEUTRAL");
	const [results, setResults] = useState<IExperienceDTO[]>([]);
	const [upcomingBookings, setUpcomingBookings] = useState<IUpcomingBooking[]>([]);
	const [count, setCount] = useState<number>(0);
	const [keyForRerender, setKeyForRerender] = useState<number>(0);

	const [filterDate, setFilterDate] = useState<DateRange<Date>>([
		initialQueryParams.date_from ? new Date(initialQueryParams.date_from) : null,
		initialQueryParams.date_to ? new Date(initialQueryParams.date_to) : null,
	]);

	const [selectedTextSearch, setSelectedTextSearch] = useState<string>(initialQueryParams.text_search || "");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedPeople, setSelectedPeople] = useState<ISelectedPeople>({
		nbAdults: initialQueryParams.number_of_travelers ? Number(initialQueryParams.number_of_travelers) : 0,
		nbKids: 0,
	});

	const refExploreSection = useRef<HTMLDivElement | null>(null);

	// Order is important!!! START
	let metaTags;

	if (country) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.COUNTRY_TITLE", { country })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.COUNTRY_TITLE", { country })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.COUNTRY_DESCRIPTION", { country })} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.COUNTRY_DESCRIPTION", { country })} />
			</Helmet>
		);
	}

	if (city) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.CITY_TITLE", { city })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.CITY_TITLE", { city })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.CITY_DESCRIPTION", { city })} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.CITY_DESCRIPTION", { city })} />
			</Helmet>
		);
	}

	if (category) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.CATEGORY_TITLE", { category })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.CATEGORY_TITLE", { category })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.CATEGORY_DESCRIPTION", { category })} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.CATEGORY_DESCRIPTION", { category })} />
			</Helmet>
		);
	}

	if (country && city) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.COUNTRY_CITY_TITLE", { country, city })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_TITLE", { country, city })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_DESCRIPTION", { country, city })} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_DESCRIPTION", { country, city })} />
			</Helmet>
		);
	}

	if (country && category) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.COUNTRY_CATEGORY_TITLE", { country, category })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.COUNTRY_CATEGORY_TITLE", { country, category })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.COUNTRY_CATEGORY_DESCRIPTION", { country, category })} />
				<meta
					property="og:description"
					content={t("SEO.SEARCH_PAGE.COUNTRY_CATEGORY_DESCRIPTION", { country, category })}
				/>
			</Helmet>
		);
	}

	if (city && category) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.CITY_CATEGORY_TITLE", { city, category })}</title>
				<meta property="og:title" content={t("SEO.SEARCH_PAGE.CITY_CATEGORY_TITLE", { city, category })} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.CITY_CATEGORY_DESCRIPTION", { city, category })} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.CITY_CATEGORY_DESCRIPTION", { city, category })} />
			</Helmet>
		);
	}

	if (country && city && category) {
		metaTags = (
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.COUNTRY_CITY_CATEGORY_TITLE", { country, city, category })}</title>
				<meta
					property="og:title"
					content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_CATEGORY_TITLE", { country, city, category })}
				/>
				<meta
					name="description"
					content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_CATEGORY_DESCRIPTION", { country, city, category })}
				/>
				<meta
					property="og:description"
					content={t("SEO.SEARCH_PAGE.COUNTRY_CITY_CATEGORY_DESCRIPTION", { country, city, category })}
				/>
			</Helmet>
		);
	}
	// Order is important!!! END

	useEffect(() => {
		setKeyForRerender(prevState => prevState + 1);
	}, [country, city, category]);

	useEffect(() => {
		if (!discoverList) {
			dispatch(fetchExperiencesDiscover());
		}
	}, [dispatch, discoverList]);

	const loadExperiences = async () => {
		try {
			setIsLoading(true);
			const tempOffset = initialQueryParams?.offset || 0;

			const experiencesList = await experiencesService.getListOfExperiences({
				...initialQueryParams,
				...(category && { categories: categoriesDict.find(elem => elem.label === category)?.value }),
				limit: 20,
				offset: tempOffset,
				with_upcoming_bookings: true,
			});

			if (experiencesList.data) {
				if (initialQueryParams.offset === "0") {
					setResults(experiencesList.data);
				} else {
					setResults(prevState => [...prevState, ...experiencesList.data]);
				}

				setCount(experiencesList.count);

				if (experiencesList.upcoming_bookings) {
					setUpcomingBookings(experiencesList.upcoming_bookings);
				}
			} else {
				setResults([]);
				setUpcomingBookings([]);
				setCount(0);
			}
		} catch {
			setResults([]);
			setCount(0);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		loadExperiences();
		// eslint-disable-next-line
	}, [location.search, location.pathname]);

	useEffect(() => {
		const tempQueryParams = omit(
			{
				...initialQueryParams,
				sort: "duration_minutes",
				offset: 0,
			},
			["city", "country", "category"],
		);

		if (sorterDuration === "UP") {
			tempQueryParams.order = "asc";
		} else if (sorterDuration === "DOWN") {
			tempQueryParams.order = "desc";
		}
		if (sorterDuration === "UP" || sorterDuration === "DOWN") {
			// @ts-ignore
			const queryURL = new URLSearchParams(tempQueryParams);

			if (Array.isArray(initialQueryParams.categories)) {
				queryURL.delete("categories");

				initialQueryParams.categories.forEach(elem => {
					queryURL.append("categories", elem);
				});
			}

			if (Array.isArray(initialQueryParams.languages)) {
				queryURL.delete("languages");

				initialQueryParams.languages.forEach(elem => {
					queryURL.append("languages", elem);
				});
			}

			history.push({ pathname: location.pathname, search: queryURL.toString() });
		}
		// eslint-disable-next-line
	}, [sorterDuration]);

	useEffect(() => {
		const tempQueryParams = omit(
			{
				...initialQueryParams,
				sort: "average_rating",
				offset: 0,
			},
			["city", "country", "category"],
		);

		if (sorterRating === "UP") {
			tempQueryParams.order = "asc";
		} else if (sorterRating === "DOWN") {
			tempQueryParams.order = "desc";
		}

		if (sorterRating === "UP" || sorterRating === "DOWN") {
			// @ts-ignore
			const queryURL = new URLSearchParams(tempQueryParams);

			if (Array.isArray(initialQueryParams.categories)) {
				queryURL.delete("categories");

				initialQueryParams.categories.forEach(elem => {
					queryURL.append("categories", elem);
				});
			}

			if (Array.isArray(initialQueryParams.languages)) {
				queryURL.delete("languages");

				initialQueryParams.languages.forEach(elem => {
					queryURL.append("languages", elem);
				});
			}

			history.push({ pathname: location.pathname, search: queryURL.toString() });
		}
		// eslint-disable-next-line
	}, [sorterRating]);

	useEffect(() => {
		const tempQueryParams = omit(
			{
				...initialQueryParams,
				sort: "price",
				offset: 0,
			},
			["city", "country", "category"],
		);

		if (sorterPrice === "UP") {
			tempQueryParams.order = "asc";
		} else if (sorterPrice === "DOWN") {
			tempQueryParams.order = "desc";
		}

		if (sorterPrice === "UP" || sorterPrice === "DOWN") {
			// @ts-ignore
			const queryURL = new URLSearchParams(tempQueryParams);
			if (Array.isArray(initialQueryParams.categories)) {
				queryURL.delete("categories");

				initialQueryParams.categories.forEach(elem => {
					queryURL.append("categories", elem);
				});
			}

			if (Array.isArray(initialQueryParams.languages)) {
				queryURL.delete("languages");

				initialQueryParams.languages.forEach(elem => {
					queryURL.append("languages", elem);
				});
			}

			history.push({ pathname: location.pathname, search: queryURL.toString() });
		}
		// eslint-disable-next-line
	}, [sorterPrice]);

	useEffect(() => {
		setSelectedTextSearch(initialQueryParams.text_search || "");
	}, [initialQueryParams.text_search]);

	const textSearchOnChange = (value: string) => setSelectedTextSearch(value);

	const mainSearchAction = () => {
		const adults = selectedPeople.nbAdults || 1;
		const dateFrom =
			filterDate[0] && isValid(filterDate[0]) ? dateObjectToString(filterDate[0], DATE_FORMATS.DATE_FORMAT) : null;
		const dateTo =
			filterDate[1] && isValid(filterDate[1]) ? dateObjectToString(filterDate[1], DATE_FORMATS.DATE_FORMAT) : null;

		dispatch<any>(
			experienceTravellerActions.updateBooking({
				selectedDates: {
					dateFrom,
					dateTo,
				},
				selectedChildren: {
					...omit(selectedPeople, ["nbAdults", "nbKids"]),
				},
				adults,
				kids: selectedPeople.nbKids,
			}),
		);

		const queryParams: {
			date_from?: string;
			date_to?: string;
			text_search?: string;
			number_of_travelers?: number;
		} = {};

		if (selectedTextSearch) {
			queryParams.text_search = selectedTextSearch;
		}

		if (dateFrom) {
			queryParams.date_from = dateFrom ?? undefined;
		}

		if (dateTo) {
			queryParams.date_to = dateTo ?? undefined;
		}

		if (selectedPeople.nbAdults > 0) {
			queryParams.number_of_travelers = selectedPeople.nbAdults;
		}

		// @ts-ignore
		const queryURL = new URLSearchParams({
			...omit(initialQueryParams, [
				"city",
				"country",
				"category",
				"date_from",
				"date_to",
				"text_search",
				"number_of_travelers",
			]),
			...queryParams,
			limit: 20,
			offset: 0,
		});

		history.push({ pathname: location.pathname, search: queryURL.toString() });
	};

	const keyPressAction = useCallback(
		v => {
			if (v.key === "Enter") {
				v.preventDefault();
				if (selectedTextSearch) {
					// @ts-ignore
					const queryURL = new URLSearchParams({ text_search: selectedTextSearch, limit: 20, offset: 0 });
					history.push({ pathname: paths.SEARCH, search: queryURL.toString() });
				} else {
					history.push({ pathname: paths.SEARCH });
				}
			}
		},
		[selectedTextSearch, history],
	);

	const onClickSorterDuration = (v: TSorter) => {
		setSorterDuration(v);
		setSorterRating("NEUTRAL");
		setSorterPrice("NEUTRAL");
	};

	const onClickSorterRating = (v: TSorter) => {
		setSorterRating(v);
		setSorterDuration("NEUTRAL");
		setSorterPrice("NEUTRAL");
	};

	const onClickSorterPrice = (v: TSorter) => {
		setSorterPrice(v);
		setSorterRating("NEUTRAL");
		setSorterDuration("NEUTRAL");
	};

	const createBreadcrumbsList = () => {
		const arrayOfLinks: { route: string; label: string }[] = [];

		if (initialQueryParams.country) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_COUNTRY, { country: initialQueryParams.country }),
				label: initialQueryParams.country,
			});
		}

		if (!initialQueryParams.country && initialQueryParams.city) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_CITY, { city: initialQueryParams.city }),
				label: initialQueryParams.city,
			});
		}

		if (initialQueryParams.country && initialQueryParams.city) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_COUNTRY_CITY, {
					country: initialQueryParams.country,
					city: initialQueryParams.city,
				}),
				label: initialQueryParams.city,
			});
		}

		if (!initialQueryParams.country && !initialQueryParams.city && category) {
			arrayOfLinks.push({ route: fillRoute(paths.SEARCH_CATEGORY, { category }), label: category });
		}

		if (initialQueryParams.country && !initialQueryParams.city && category) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_COUNTRY_CATEGORY, { country: initialQueryParams.country, category }),
				label: category,
			});
		}

		if (!initialQueryParams.country && initialQueryParams.city && category) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_CITY_CATEGORY, { city: initialQueryParams.city, category }),
				label: category,
			});
		}

		if (initialQueryParams.country && initialQueryParams.city && category) {
			arrayOfLinks.push({
				route: fillRoute(paths.SEARCH_COUNTRY_CITY_CATEGORY, {
					country: initialQueryParams.country,
					city: initialQueryParams.city,
					category,
				}),
				label: category,
			});
		}

		return arrayOfLinks;
	};

	return (
		<AppWrapper>
			<Helmet>
				<title>{t("SEO.SEARCH_PAGE.BASIC_TITLE")}</title>

				<meta property="og:title" content={t("SEO.SEARCH_PAGE.BASIC_TITLE")} />
				<meta name="description" content={t("SEO.SEARCH_PAGE.BASIC_DESCRIPTION")} />
				<meta property="og:description" content={t("SEO.SEARCH_PAGE.BASIC_DESCRIPTION")} />
			</Helmet>

			<Container>
				{metaTags}

				<Breadcrumbs lastBreadcrumbLabel={t("EXPERIENCES")} list={createBreadcrumbsList()} />

				<MainSearchWrapper>
					<FilterWrapper>
						<StyledPlacesAutocompleteField
							onKeyPressAction={keyPressAction}
							selectedValue={selectedTextSearch}
							onChange={textSearchOnChange}
							customOptions={[
								{
									type: CustomOptionType.CURRENT_LOCATION,
									label: t("SEARCH.EXPERIENCES.NEARBY"),
									value: "Nearby",
								},
							]}
							label={t("SEARCH.EXPERIENCES.MAIN_FILTERS.CHOOSE_DESTINATION")}
						/>
					</FilterWrapper>

					<FilterWrapper>
						<RangePicker
							date={filterDate}
							setDate={setFilterDate}
							inputDateFormat={DATE_FORMATS.DATE_FORMAT2}
							mask="__.__.____"
							disablePast
						/>
					</FilterWrapper>

					<FilterWrapper>
						<SelectPeople
							label={t("SEARCH.EXPERIENCES.PEOPLE")}
							values={selectedPeople}
							onChange={v => setSelectedPeople(prevState => ({ ...prevState, ...v }))}
						/>
					</FilterWrapper>

					<SearchButton onClick={mainSearchAction} color="primary" variant="contained">
						<SearchIcon />
						{t("SEARCH.EXPERIENCES.MAIN_FILTERS.SEARCH")}
					</SearchButton>
				</MainSearchWrapper>

				<MoreFilters key={keyForRerender} />

				{!!upcomingBookings.length && <UpcomingBookings upcomingBookings={upcomingBookings} />}

				{count > 0 && (
					<>
						<NbExperiences>
							{count} {t("SEARCH.EXPERIENCES.SORTERS.EXPERIENCES")}
						</NbExperiences>

						<Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom="20px">
							<Box marginRight="20px">
								<Sorter
									label={t("SEARCH.EXPERIENCES.SORTERS.DURATION")}
									onChange={onClickSorterDuration}
									value={sorterDuration}
								/>
							</Box>

							<Box marginRight="20px">
								<Sorter
									label={t("SEARCH.EXPERIENCES.SORTERS.RATING")}
									onChange={onClickSorterRating}
									value={sorterRating}
								/>
							</Box>

							<Sorter label={t("SEARCH.EXPERIENCES.SORTERS.PRICE")} onChange={onClickSorterPrice} value={sorterPrice} />
						</Box>
					</>
				)}

				{count === 0 && !isLoading && (
					<>
						<EmptyList
							description={t("SEARCH.EXPERIENCES.EMPTY_LIST.DESCRIPTION")}
							currentRef={refExploreSection}
							nameBtn={t("SEARCH.EXPERIENCES.EMPTY_LIST.START_BTN")}
							title={t("SEARCH.EXPERIENCES.EMPTY_LIST.TITLE")}
						/>

						{discoverList && (
							<div ref={refExploreSection}>
								<Discover discoverList={discoverList} titleTranslationKey="HP.EXPLORE.TITLE" />
							</div>
						)}
					</>
				)}

				{isLoading && <StyledLinearProgress color="primary" />}

				<ExperiencesList
					isLoading={isLoading}
					experiencesList={results}
					expCount={count}
					showLoadMore={results.length > 0 && count !== results.length}
				/>
			</Container>
		</AppWrapper>
	);
});

export default ExperienceSearchPage;
