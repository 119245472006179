export enum EFormatter {
	dayMonth = "dayMonth",
	dayMonthYear = "dayMonthYear",
	time = "time",
}

export interface IDateParams {
	month: string | number;
	day: string | number;
	year: string | number;
	hours: string | number;
	minutes: string | number;
}
