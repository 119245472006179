import { EUserType } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import ChangeFinancials from "../components/ChangeFinancials";
import UserDetails from "../components/UserDetails";
import { Header2 } from "../shared.styled";

const AccountSettings = () => {
	const { t } = useTranslation();

	const userData = useAppSelector(selectUser);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	return (
		<>
			<Header2>{t("SETTINGS.PERSONAL_INFORMATION_HEADER")}</Header2>

			<UserDetails />

			{userData?.userType !== "TRAVELER" && !partnerType && <ChangeFinancials />}
		</>
	);
};

export default AccountSettings;
