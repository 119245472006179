import { Box, Checkbox, Grid, Switch, useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as AllIcon } from "assets/icons/notifications/all-icon.svg";
import { ReactComponent as CategoriesIcon } from "assets/icons/notifications/categories-icon.svg";

import { ENotificationCategory, ENotificationRecipient, INotification } from "data/notifications/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import notificationsService from "services/api/notifications";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectNotifications } from "store/selectors/notifications";
import { selectUser } from "store/selectors/user";
import { fetchUnreadNotifications } from "store/slices/notifications";

import { localStorageKeys } from "utils/constants";
import { getDateWithDefaultTime, secondsToOtherFormats } from "utils/dates";
import fillRoute from "utils/routes";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import ActionButton from "ui/buttons/ActionButton";
import CircularLoader from "ui/loaders/CircularLoader";
import NotificationRecord from "ui/NotificationRecord";

import { Container } from "styles/common";

import { Header, Wrapper } from "../shared.styled";
import {
	CategoriesFilters,
	FilterButtons,
	MenuElem,
	StyledArrow,
	StyledFormControlLabel,
	SwitchContainer,
} from "./styled";

interface IQueryParams {
	offset?: number;
	limit?: number;
	category?: ENotificationCategory[];
	min_created_on?: string;
	max_created_on?: string;
	marked_as_read?: boolean;
	recipient_type?: ENotificationRecipient;
}

const listItemsOffset = 10;

const AccountNotificationsPage = () => {
	const { t, withRaw } = useTranslation();

	const history = useHistory();

	const { handleAndNotify } = useErrors();

	const dispatch = useAppDispatch();

	const userData = useAppSelector(selectUser);
	const notificationsGlobal = useAppSelector(selectNotifications);

	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [results, setResults] = useState<{
		count?: number;
		data?: INotification[];
	}>();
	const [page, setPage] = useState<number>(1);
	const [loader, setLoader] = useState<"GLOBAL" | false>("GLOBAL");
	const [loadMore, setLoadMore] = useState<boolean>(false);

	const [showCategories, setShowCategories] = useState<boolean>(false);
	const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(
		!!window.localStorage.getItem(localStorageKeys.notifications.showOnlyUnread.key),
	);
	const [onlyToday, setOnlyToday] = useState<boolean>(false);
	const [categories, setCategories] = useState<ENotificationCategory[]>([]);

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const fetchData = async (reset: boolean, offset?: number) => {
		setLoader(reset ? "GLOBAL" : false);

		const queryParams: IQueryParams = {
			limit: listItemsOffset,
			...(!partnerType && { recipient_type: userData?.userType as unknown as ENotificationRecipient }),
			...(offset !== undefined && { offset }),
			...(showOnlyUnread && { marked_as_read: false }),
			...(categories.length && { category: categories }),
			...(onlyToday && {
				min_created_on: getDateWithDefaultTime(new Date(), "FROM"),
				max_created_on: getDateWithDefaultTime(new Date(), "TO"),
			}),
		};

		try {
			const response = await notificationsService.getSearchNotifications(queryParams);

			setResults(prevState =>
				reset
					? { count: response.count, data: response.data }
					: {
							count: response.count,
							data: !!prevState?.data ? [...prevState.data, ...response.data] : response.data,
					  },
			);

			setLoadMore(false);
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setLoader(false);
		}
	};

	const fetchDataWithReset = () => {
		fetchData(true);

		setPage(1);
	};

	useEffect(() => {
		fetchDataWithReset();

		// eslint-disable-next-line
	}, [userData?.userType, showOnlyUnread, categories, onlyToday]);

	useEffect(() => {
		if (loadMore) {
			if (page * listItemsOffset <= (results?.count || 0)) {
				fetchData(false, page * listItemsOffset);

				setPage(prevState => prevState + 1);
			}
		}

		// eslint-disable-next-line
	}, [loadMore]);

	useEffect(() => {
		setShowCategories(!isMobileScreen);
	}, [isMobileScreen]);

	const markAsRead = async (id: string, read: boolean) => {
		if (results?.data) {
			const newResults = [...results.data];

			const objIndex = newResults.findIndex(obj => obj.id === id);

			try {
				newResults[objIndex].marked_as_read = read;

				setResults(prevState => ({
					...prevState,
					data: newResults,
				}));

				await notificationsService.patchUpdateNotification(id, read);

				dispatch(fetchUnreadNotifications(!partnerType ? { recipient_type: userData?.userType } : undefined));
			} catch (e) {
				handleAndNotify(e);

				newResults[objIndex].marked_as_read = !read;

				setResults(prevState => ({
					...prevState,
					data: newResults,
				}));
			}
		}
	};

	const markAllAsRead = async () => {
		setLoader("GLOBAL");

		const queryParams: IQueryParams = {
			...(!partnerType && { recipient_type: userData?.userType as unknown as ENotificationRecipient }),
			...(showOnlyUnread && { marked_as_read: false }),
			...(categories.length && { category: categories }),
			...(onlyToday && {
				min_created_on: getDateWithDefaultTime(new Date(), "FROM"),
				max_created_on: getDateWithDefaultTime(new Date(), "TO"),
			}),
		};

		try {
			await notificationsService.postMarkNotificationsAsRead(queryParams);

			dispatch(fetchUnreadNotifications(!partnerType ? { recipient_type: userData?.userType } : undefined));

			fetchDataWithReset();
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const countOfAllCategories = Object.values(ENotificationCategory).reduce(
		(result, elem) => result + (notificationsGlobal.unreadNotifications?.statistics.by_category?.[elem] || 0),
		0,
	);

	const SwitcherComponent = () => (
		<SwitchContainer container spacing={1}>
			<Grid item>
				<Box fontSize={isMobileScreen ? "12px" : "16px"} lineHeight="39px">
					{t("NOTIFICATIONS.SHOW_ONLY_UNREAD_BUTTON")}
				</Box>
			</Grid>

			<Grid item>
				<Switch
					checked={showOnlyUnread}
					size="medium"
					onChange={() =>
						setShowOnlyUnread(prevState => {
							if (prevState) {
								window.localStorage.removeItem(localStorageKeys.notifications.showOnlyUnread.key);
							} else {
								window.localStorage.setItem(
									localStorageKeys.notifications.showOnlyUnread.key,
									localStorageKeys.notifications.showOnlyUnread.defaultValue,
								);
							}

							return !prevState;
						})
					}
				/>
			</Grid>
		</SwitchContainer>
	);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.NOTIFICATIONS_LABEL")} />

				<Wrapper>
					<Box display="flex" justifyContent="space-between">
						<Header>{t("TOPBAR.NOTIFICATIONS_LABEL")}</Header>

						{isMobileScreen && <SwitcherComponent />}
					</Box>

					<Box display={!isMobileScreen ? "flex" : undefined}>
						<Box marginTop={isMobileScreen ? "-15px" : undefined}>
							<Box
								display="flex"
								alignItems={isMobileScreen ? "flex-end" : "flex-start"}
								borderBottom={isMobileScreen ? "1px solid #dadada" : undefined}
								flexDirection={!isMobileScreen ? "column" : undefined}
								minWidth={!isMobileScreen ? "225px" : undefined}
							>
								<MenuElem active={!categories.length} onClick={() => setCategories([])}>
									{!isMobileScreen && <AllIcon />}

									{t("NOTIFICATIONS.CATEGORIES_FILTER.ALL")}

									{!!notificationsGlobal.unreadNotifications?.statistics.total && (
										<>
											&nbsp;<strong>({notificationsGlobal.unreadNotifications.statistics.total})</strong>
										</>
									)}
								</MenuElem>

								<MenuElem
									noClick={!isMobileScreen}
									active={!!categories.length}
									onClick={isMobileScreen ? () => setShowCategories(prevState => !prevState) : undefined}
								>
									{!isMobileScreen && <CategoriesIcon />}

									{t("NOTIFICATIONS.CATEGORIES_FILTER")}

									{countOfAllCategories > 0 && (
										<>
											&nbsp;<strong>({countOfAllCategories})</strong>
										</>
									)}

									{isMobileScreen && <StyledArrow $isOpened={showCategories} />}
								</MenuElem>
							</Box>

							{showCategories && (
								<CategoriesFilters>
									<Grid container>
										{Object.values(ENotificationCategory).map(elem => (
											<Grid key={elem} md={12} xs={6} item>
												<StyledFormControlLabel
													control={
														<Checkbox
															checked={categories.includes(elem)}
															onChange={() =>
																setCategories(prevState => {
																	if (prevState.includes(elem)) {
																		return prevState.filter(oldElem => oldElem !== elem);
																	} else {
																		return [...prevState, elem];
																	}
																})
															}
															name={elem}
														/>
													}
													label={
														<span>
															{t(`NOTIFICATIONS.CATEGORIES.${elem}`)}

															{!!notificationsGlobal.unreadNotifications?.statistics.by_category?.[elem] && (
																<>
																	&nbsp;
																	<strong>
																		({notificationsGlobal.unreadNotifications.statistics.by_category[elem]})
																	</strong>
																</>
															)}
														</span>
													}
												/>
											</Grid>
										))}
									</Grid>
								</CategoriesFilters>
							)}
						</Box>

						<Box
							marginTop={isMobileScreen ? "20px" : undefined}
							marginLeft={!isMobileScreen ? "20px" : undefined}
							width={!isMobileScreen ? "100%" : undefined}
						>
							{!isMobileScreen && (
								<Box
									display="flex"
									justifyContent="space-between"
									marginBottom="20px"
									paddingBottom="10px"
									position="relative"
									top="1px"
									borderBottom="solid 1px #dadada"
								>
									<FilterButtons
										$active={onlyToday}
										minWidth="120px"
										paddingX="10px"
										onClick={() => setOnlyToday(prevState => !prevState)}
									>
										{t("NOTIFICATIONS.FILTERS.TODAY")}
									</FilterButtons>

									<Box display="flex" gridGap="30px">
										<FilterButtons minWidth="150px" paddingX="10px" onClick={() => markAllAsRead()}>
											{t("NOTIFICATIONS.MARK_ALL_AS_READ_BUTTON")}
										</FilterButtons>

										<SwitcherComponent />
									</Box>
								</Box>
							)}

							<Box>
								{loader === "GLOBAL" ? (
									<Box position="relative" height="150px">
										<CircularLoader />
									</Box>
								) : (
									<>
										{results?.data?.length === 0 && (
											<Box textAlign="center" marginTop={isMobileScreen ? "60px" : "100px"}>
												<Box fontWeight="bold" fontSize={isMobileScreen ? "16px" : "24px"} marginBottom="10px">
													{t(
														showOnlyUnread
															? "NOTIFICATIONS.NO_RESULTS.TITLE.ONLY_UNREAD"
															: "NOTIFICATIONS.NO_RESULTS.TITLE",
													)}
												</Box>

												<Box fontSize={isMobileScreen ? "14px" : "16px"}>
													{withRaw(
														showOnlyUnread
															? "NOTIFICATIONS.NO_RESULTS.SUB_TITLE.ONLY_UNREAD"
															: "NOTIFICATIONS.NO_RESULTS.SUB_TITLE",
														null,
													)}
												</Box>
											</Box>
										)}

										{results?.data?.map(elem => (
											<NotificationRecord
												key={elem.id}
												title={elem.title}
												description={elem.message}
												subTitle={elem.subject_name}
												sent={
													secondsToOtherFormats(
														Math.round(
															(new Date().getTime() +
																new Date().getTimezoneOffset() * 60000 -
																new Date(elem.created_on).getTime()) /
																1000,
														),
														true,
														true,
													) + " ago"
												}
												read={elem.marked_as_read}
												makeRead={() => markAsRead(elem.id, !elem.marked_as_read)}
												click={() => {
													markAsRead(elem.id, true);

													history.push(fillRoute(paths.REDIRECT, { id: elem.subject_id, type: elem.type }));
												}}
											/>
										))}

										{!!results?.data?.length && page * listItemsOffset <= (results?.count || 0) && (
											<Box textAlign="center" marginTop={isMobileScreen ? "20px" : "40px"}>
												<ActionButton
													translationDefault={t("NOTIFICATIONS.SHOW_MORE_BUTTON")}
													isAction={loadMore}
													isDisabled={loadMore}
													size="large"
													onClick={() => setLoadMore(true)}
												/>
											</Box>
										)}
									</>
								)}
							</Box>
						</Box>
					</Box>
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default AccountNotificationsPage;
