import { Box, FormControlLabel, FormHelperText, Radio, RadioGroup, useMediaQuery, useTheme } from "@material-ui/core";
import { Form as Formik, FormikProvider, useFormik } from "formik";
import { ChangeEvent, useCallback } from "react";
import styled from "styled-components";

import { EPartnerConnectionType, EPartnerType, IPartnerContactFormData } from "data/notifications/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import notificationsService from "services/api/notifications";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";
import { Description, SmallerTitle } from "ui/CommonParagraph";

import { MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import useValidationSchema from "./validate";

const inputMargin = "10px";

const Wrapper = styled.section`
	font-family: ${MontserratFontFamily};
	padding-top: 3.75rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const CheckboxWrapper = styled.span`
	.MuiTypography-body1 {
		padding: 5px 0 0;
		font-family: inherit;
		font-size: inherit;
		letter-spacing: inherit;
		line-height: 1.2;
		color: inherit;
	}

	.PrivateSwitchBase-root-5 {
		padding-bottom: 0;
	}
`;

const BusinessItem = styled(CheckboxWrapper)`
	margin-right: 50px;
`;

const StyledActionButton = styled(ActionButton)`
	margin-top: 2.75rem;
`;

const FormWrapper = styled.div`
	padding-bottom: 1.875rem;
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${media.desktop`
    width: 100%;
  `};
`;

const InputsWrapper = styled.div`
	margin-top: 1.875rem;
	width: 100%;
	display: flex;
	flex-direction: row;

	${media.desktop`
    margin-top: 1.25rem;
    flex-direction: column;
  `};
`;

const StyledRadioGroupBusiness = styled(RadioGroup)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: row;

	span {
		font-family: ${MontserratFontFamily};
		font-size: ${dimensions.fontSize.large};
		font-weight: 500;
		line-height: 2.79;
		letter-spacing: 0.04px;
	}

	label {
		padding: 5px;
	}

	${media.desktop`
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const StyledRadioGroupAccessList = styled(RadioGroup)`
	span {
		font-family: ${MontserratFontFamily};
		font-size: ${dimensions.fontSize.large};
		font-weight: 500;
		line-height: 2.79;
		letter-spacing: 0.04px;
	}

	label {
		padding: 5px;
	}

	${media.desktop`
    width: 90%;
  `};
`;

const initialValues: IPartnerContactFormData<""> = {
	company: "",
	description: "",
	first_name: "",
	last_name: "",
	from: "",
	partner_connection_type: "",
	partner_type: "",
	phone: "",
	website: "",
};

const CommercialTermsForm = () => {
	const { t, withRaw } = useTranslation();

	const theme = useTheme();
	const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: useValidationSchema(),
		initialValues,
		onSubmit: async formValues => {
			const data = { ...(formValues as IPartnerContactFormData) };

			try {
				if (userData?.id) {
					data.user_id = userData.id;
				}

				await notificationsService.postSendPartnerEmail(data);

				addSuccess(t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.SUCCESS"));
			} catch (e) {
				handleAndNotify(e);
			}
		},
	});

	const handleInputChange = useCallback(
		fieldName => (event: ChangeEvent<HTMLInputElement>) => formikProps.setFieldValue(fieldName, event.target.value),
		// eslint-disable-next-line
		[],
	);

	return (
		<FormikProvider value={formikProps}>
			<Formik>
				<Wrapper>
					<SmallerTitle>{t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.TITLE")}</SmallerTitle>
					<Description>{t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.DESCRIPTION")}</Description>

					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						marginTop="1.875rem"
						marginBottom="10px"
						width="100%"
					>
						<Description>
							{withRaw("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.YOUR_BUSINESS", null)}

							{formikProps.errors.partner_type && (
								<div>
									<FormHelperText error>{formikProps.errors.partner_type}</FormHelperText>
								</div>
							)}
						</Description>
					</Box>

					<StyledRadioGroupBusiness
						aria-label="partner type"
						name="partner_type"
						value={formikProps.values.partner_type}
						onChange={event => formikProps.setFieldValue("partner_type", event.target.value)}
					>
						{Object.entries(EPartnerType).map(([value, label]) => (
							<BusinessItem key={value}>
								<FormControlLabel
									disabled={formikProps.isSubmitting}
									control={<Radio />}
									value={value}
									label={t(label)}
								/>
							</BusinessItem>
						))}
					</StyledRadioGroupBusiness>

					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						marginTop="1.875rem"
						marginBottom="10px"
						width="100%"
					>
						<Description>
							{withRaw("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.YOUR_BUSINESS", null)}

							{formikProps.errors.partner_connection_type && (
								<div>
									<FormHelperText error>{formikProps.errors.partner_connection_type}</FormHelperText>
								</div>
							)}
						</Description>
					</Box>

					<StyledRadioGroupAccessList
						aria-label="partner connection type"
						name="partner_connection_type"
						value={formikProps.values.partner_connection_type}
						onChange={event => formikProps.setFieldValue("partner_connection_type", event.target.value)}
					>
						{Object.entries(EPartnerConnectionType).map(([value, label]) => (
							<Box key={value} marginBottom={isSmScreen ? "20px" : "10px"}>
								<CheckboxWrapper>
									<FormControlLabel
										disabled={formikProps.isSubmitting}
										control={<Radio />}
										value={value}
										label={t(label)}
									/>
								</CheckboxWrapper>
							</Box>
						))}
					</StyledRadioGroupAccessList>

					<Box display="flex" alignItems="center" justifyContent="center" marginTop="1.875rem" width="100%">
						<Description>
							{withRaw("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.CUSTOMIZED_COLLABORATION_TITLE", null)}
						</Description>
					</Box>

					<FormWrapper>
						<InputsWrapper>
							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginRight: isSmScreen ? "0" : inputMargin }}
								name="first_name"
								type="text"
								value={formikProps.values.first_name}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.FIRST_NAME")}
								onChange={handleInputChange("first_name")}
							/>

							{isSmScreen && <br />}

							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginLeft: isSmScreen ? "0" : inputMargin }}
								name="last_name"
								type="text"
								value={formikProps.values.last_name}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.LAST_NAME")}
								onChange={handleInputChange("last_name")}
							/>
						</InputsWrapper>

						<InputsWrapper>
							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginRight: isSmScreen ? "0" : inputMargin }}
								name="company"
								type="text"
								value={formikProps.values.company}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.COMPANY")}
								onChange={handleInputChange("company")}
							/>

							{isSmScreen && <br />}

							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginLeft: isSmScreen ? "0" : inputMargin }}
								name="phone"
								type="number"
								value={formikProps.values.phone}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.PHONE")}
								onChange={handleInputChange("phone")}
							/>
						</InputsWrapper>

						<InputsWrapper>
							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginRight: isSmScreen ? "0" : inputMargin }}
								name="from"
								type="text"
								value={formikProps.values.from}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.EMAIL")}
								onChange={handleInputChange("from")}
							/>

							{isSmScreen && <br />}

							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ width: "100%", marginLeft: isSmScreen ? "0" : inputMargin }}
								name="website"
								type="text"
								value={formikProps.values.website}
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.WEBSITE")}
								onChange={handleInputChange("website")}
							/>
						</InputsWrapper>

						<InputsWrapper>
							{isSmScreen && <br />}

							<Input
								required
								disabled={formikProps.isSubmitting}
								inputAndWrapperStyles={{ height: "110px", width: "100%" }}
								name="description"
								label={t("HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.MESSAGE")}
								type="text"
								multiline
								value={formikProps.values.description}
								onChange={handleInputChange("description")}
							/>
						</InputsWrapper>
					</FormWrapper>

					<StyledActionButton
						type="button"
						size="large"
						onClick={formikProps.handleSubmit}
						translationDefault="HELP.DISTRIBUTION_PARTNER.COMMERCIAL_TERMS_FORM.SEND_BTN"
						translationAction="CONTACT.SENDING_MESSAGE"
						isAction={formikProps.isSubmitting}
						disabled={formikProps.isSubmitting || !formikProps.isValid}
					/>
				</Wrapper>
			</Formik>
		</FormikProvider>
	);
};

export default CommercialTermsForm;
