import { Box, Grid } from "@material-ui/core";
import { Form, FormikProvider, useFormik } from "formik";
import { rgba } from "polished";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import usersService from "services/api/users";
import { signOut } from "services/aws/auth";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import ConfirmDialog from "components/dialogs/ConfirmDialog";
import Input from "components/formik/Input";

import ActionButton from "ui/buttons/ActionButton";
import Dialog from "ui/Dialog";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

const DescriptionBox = styled.span`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71;
	letter-spacing: 0.08px;
	color: ${rgba(colors.darkGreen, 0.87)};
`;

const DeleteButton = styled(ActionButton)`
	background-color: ${rgba("#ef5350", 0.5)};
	border: 1px solid #b71c1c;
	color: #e53935;
`;

const DeleteAccount = () => {
	const { t } = useTranslation();
	const { addSuccess } = useNotification();
	const { handleAndNotify } = useErrors();
	const history = useHistory();
	const query = useQuery();

	const [isAction, setIsAction] = useState<boolean>(false);
	const [showPopup, setShowPopup] = useState<boolean>(query.get("account") === "remove");

	const userData = useAppSelector(selectUser);

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			code: Yup.string().required(t("ERRORS.REQUIRED")),
		}),
		initialValues: {
			code: "",
		},
		onSubmit: async values => {
			setIsAction(true);

			try {
				await usersService.deleteAccount(userData?.id || "", values.code);

				await signOut();

				addSuccess(t("SETTINGS.DELETE_ACCOUNT.ACCOUNT_DELETED"));

				history.push(paths.HOMEPAGE);
			} catch (e) {
				handleAndNotify(e);
			} finally {
				setIsAction(false);
			}
		},
	});

	const sendRemoveAccountToken = async () => {
		setIsAction(true);

		try {
			await usersService.deleteAccount(userData?.id || "");

			addSuccess(t("SETTINGS.DELETE_ACCOUNT.CONFIRMATION_CODE_SENT"));

			showDialog();
		} catch (e) {
			handleAndNotify(e);
		} finally {
			setIsAction(false);
		}
	};

	const closeDialog = () => setShowPopup(false);
	const showDialog = () => setShowPopup(true);

	return (
		<>
			<Dialog showDialog={showPopup} handleClose={closeDialog} minHeight="auto">
				<DialogContentWrapper title={t("SETTINGS.DELETE_ACCOUNT.TITLE")}>
					<FormikProvider value={formikProps}>
						<Form>
							<Input
								required
								name="code"
								label={t("SETTINGS.DELETE_ACCOUNT.CONFIRMATION_CODE")}
								inputClassName="full"
							/>

							<Box textAlign="center">
								<DeleteButton translationDefault={"SETTINGS.DELETE_ACCOUNT.TITLE"} isAction={isAction} />
							</Box>
						</Form>
					</FormikProvider>
				</DialogContentWrapper>
			</Dialog>

			<Grid container spacing={10} alignItems="center">
				<Grid item>
					<ConfirmDialog
						onConfirm={sendRemoveAccountToken}
						title={t("SETTINGS.DELETE_ACCOUNT.CONFIRM_TITLE")}
						message={t("POPUP.CONFIRM_MESSAGE")}
					>
						<DeleteButton translationDefault={"SETTINGS.DELETE_ACCOUNT.TITLE"} isAction={isAction} padding={25} />
					</ConfirmDialog>
				</Grid>

				<Grid item>
					<DescriptionBox>{t("SETTINGS.DELETE_ACCOUNT.DESCRIPTION")}</DescriptionBox>
				</Grid>
			</Grid>
		</>
	);
};

export default DeleteAccount;
