import { rgba } from "polished";
import { useMemo } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { MY_FINANCES_TABS, paths } from "routing/paths";

import fillRoute from "utils/routes";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import Tabs from "ui/Tabs";
import { ITabsListItem } from "ui/Tabs/types";

import colors from "styles/colors";
import { Container, RobotoFontFamily } from "styles/common";

import { Header, Wrapper } from "../shared.styled";
import BankingDetails from "./components/BankingDetails";
import PastEarnings from "./components/PastEarnings";
import Withdrawals from "./components/Withdrawals";

const Header2 = styled.h2`
	font-family: ${RobotoFontFamily};
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: 0.1px;
	color: ${rgba(colors.darkGreen, 0.87)};
	margin-top: 35px;
`;

const AccountMyFinancesPage = () => {
	const { t, i18n } = useTranslation();

	const tabsList: ITabsListItem[] = useMemo(
		() => [
			{
				title: t("MY_FINANCES.TAB1.NAME"),
				route: fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.PAST_EARNINGS }),
				content: (
					<>
						<Header2>{t("MY_FINANCES.TAB1.NAME")}</Header2>

						<PastEarnings />
					</>
				),
			},
			{
				title: t("MY_FINANCES.TAB3.NAME"),
				route: fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.WITHDRAWALS }),
				content: (
					<>
						<Header2>{t("MY_FINANCES.TAB3.NAME")}</Header2>

						<Withdrawals />
					</>
				),
			},
			{
				title: t("MY_FINANCES.TAB2.NAME"),
				route: fillRoute(paths.MY_FINANCES, { page: MY_FINANCES_TABS.BANKING_DETAILS }),
				content: (
					<>
						<Header2>{t("MY_FINANCES.BANKING_DETAILS.HEADER")}</Header2>

						<BankingDetails />
					</>
				),
			},
		],

		// eslint-disable-next-line
		[i18n.language],
	);

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs lastBreadcrumbLabel={t("TOPBAR.MY_FINANCES_LABEL")} />

				<Wrapper>
					<Header>{t("TOPBAR.MY_FINANCES_LABEL")}</Header>

					<Tabs tabs={tabsList} />
				</Wrapper>
			</Container>
		</AppWrapper>
	);
};

export default AccountMyFinancesPage;
