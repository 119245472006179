import Rating from "@material-ui/lab/Rating";
import { useContext, useState } from "react";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

import { RateAnswersContext } from "../index";
import { IRateIcons, IRatingsSettings } from "../types";
import RatingsIcon from "./RatingsIcon";

const RatingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

const RateValueLabel = styled.strong<{ color?: string }>`
	color: ${props => (props.color ? props.color : colors.sunShade)};
	font-size: 12px;
	font-weight: bold;
	font-family: ${RobotoFontFamily};
	position: absolute;
	left: 100%;
	white-space: nowrap;
`;

const StyledRating = styled(Rating)`
	position: relative;
`;

interface IRatesList {
	icons: IRateIcons;
	categoryKey: string;
	settings: IRatingsSettings;
}

const RatingsList = ({ icons, categoryKey, settings }: IRatesList) => {
	const { t } = useTranslation();
	const [hover, setHover] = useState<number>(-1);
	const { rate, answers } = useContext(RateAnswersContext);
	const countIcons = Object.keys(icons).length;
	const selected = answers[categoryKey] ?? null;
	const currentRateKey =
		-1 !== hover && icons.hasOwnProperty(hover) ? hover : icons.hasOwnProperty(selected) ? selected : -1;

	const IconContainer = props => {
		const { value, ...other } = props;
		const Icon = icons[value]?.icon;

		if (!Icon) {
			return null;
		}

		return (
			<RatingsIcon
				index={value}
				selected={selected}
				withLabel={settings.withBottomLabel}
				color={icons[value]?.color}
				size={settings.size}
				label={icons[value]?.label}
				{...other}
			>
				<Icon />
			</RatingsIcon>
		);
	};

	return (
		<RatingContainer>
			<StyledRating
				name={`rate-category-${categoryKey}`}
				getLabelText={v => icons[v]?.label}
				value={selected}
				max={countIcons}
				IconContainerComponent={IconContainer}
				onChange={(event, newValue) => rate(categoryKey, newValue)}
				onChangeActive={(event, newHover) => setHover(newHover)}
			/>

			{!settings?.withoutLabel && (
				<RateValueLabel color={icons[currentRateKey]?.color}>
					{!!icons[currentRateKey]?.label ? t(icons[currentRateKey]?.label) : t("RATING.USER.DEFAULT_ANSWER")}
				</RateValueLabel>
			)}
		</RatingContainer>
	);
};

export default RatingsList;
