import { Box, BoxProps, Divider, FormHelperText } from "@material-ui/core";
import { Fragment, ReactNode } from "react";
import styled from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

export type ValueType = string | number;

export type Option = {
	value: ValueType;
	label: string | ReactNode;
};

export interface OptionsListProps {
	options: Option[];
	selectedValue: ValueType;
	onItemClick(value: ValueType): void;
	error: string;
}

interface ItemProps {
	$isSelected?: boolean;
	$selectedColor?: string;
}

const Wrapper = styled(Box)`
	margin-bottom: 0.625rem;
	scroll-behavior: smooth;
	overflow-y: auto;
`;

const Item = styled(Box)<ItemProps & BoxProps>`
	padding: 0.3rem;
	cursor: pointer;
	font-family: ${RobotoFontFamily};
	letter-spacing: 0.38px;

	background-color: ${props => (props.$isSelected ? props.$selectedColor : "transparent")};
	color: ${props => (props.$isSelected ? colors.white : colors.mainFontColor)};
`;

const OptionsList = ({
	options,
	height = 250,
	maxHeight,
	width = "100%",
	selectedValue,
	onItemClick,
	error,
}: OptionsListProps & Pick<BoxProps, "height" | "width" | "maxHeight">) => {
	return (
		<>
			<Wrapper height={height} width={width} maxHeight={maxHeight}>
				{options.map(option => {
					const isSelected = option.value === selectedValue;

					return (
						<Fragment key={option.value}>
							<Item
								$selectedColor={colors.lightOrange}
								$isSelected={isSelected}
								onClick={() => onItemClick(option.value)}
							>
								{option.label}
							</Item>

							<Divider />
						</Fragment>
					);
				})}
			</Wrapper>

			{error && <FormHelperText error>{error}</FormHelperText>}
		</>
	);
};

export default OptionsList;
