import { ReactNode } from "react";

import { TDate } from "data/dates/types";

export type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export type TDateField = { from: string; to: string };

export enum CustomOptionType {
	RECENT_SEARCH = "RECENT_SEARCH",
	CURRENT_LOCATION = "CURRENT_LOCATION",
}

export interface IId {
	id: string;
	modified_by: string;
	created_by: string;
	modified_on: TDate;
	created_on: TDate;
}

export interface IIdEmpty {
	id?: string;
}

export interface IDaysOfWeek {
	"0": string[];
	"1": string[];
	"2": string[];
	"3": string[];
	"4": string[];
	"5": string[];
	"6": string[];
}

export interface ISignedResponse {
	fields: {
		key: string;
		policy: string;
		"x-amz-algorithm": string;
		"x-amz-credential": string;
		"x-amz-date": string;
		"x-amz-security-token": string;
		"x-amz-signature": string;
	};
	media_id: string;
	url: string;
}

export interface ILocation {
	coordinates?: { lat: number; lon: number };
	formatted_address?: string;
	tips?: string;
}

interface IMatchProps {
	offset: number;
	length: number;
}

export interface PlaceType {
	type?: CustomOptionType;
	label?: string;
	value?: string;
	icon?: ReactNode;
	onClick?: () => any;
	description?: string;
	structured_formatting?: {
		main_text: string;
		secondary_text: string;
		main_text_matched_substrings: IMatchProps[];
	};
}

export interface ITranslationEnumValue<E> {
	translation: string;
	value: E;
}

export type TLabelValueOption = {
	value: string | number;
	label: ReactNode;
};

export interface IInternalComment extends IId {
	value: string;
	document_id: string;
}
