import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "formik";

import AutocompleteUI, { IAutocompleteUI } from "ui/forms/Autocomplete";

interface IAutocomplete extends Omit<IAutocompleteUI, "onChange"> {
	customOnChange?: (value: IAutocompleteUI["value"]) => void;
	className?: string;
}

const Autocomplete = ({ name, className, customOnChange, ...rest }: IAutocomplete) => (
	<Field name={name}>
		{({ field, form: { setFieldValue }, meta }) => {
			const hasError = meta.touched && !!meta.error;

			const onChange = (v): void => {
				const value = rest.multiple ? [...v.map(i => i.value)] : v?.value || "";
				if (customOnChange) {
					customOnChange(value);
				}

				setFieldValue(field.name, value);
			};

			return (
				<div className={className}>
					<AutocompleteUI name={field.name} value={field.value} onChange={onChange} error={hasError} {...rest} />

					{hasError && <FormHelperText error>{meta.error}</FormHelperText>}
				</div>
			);
		}}
	</Field>
);

export default Autocomplete;
