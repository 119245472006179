import { Box, Chip, Grid, IconButton, List, ListItem, ListItemSecondaryAction, TextField } from "@material-ui/core";
import { Add, Cancel, Check, Close } from "@material-ui/icons";
import { useState } from "react";

import { getDictionary } from "data/dictionaries";

import useTranslation from "hooks/useTranslation";

export interface IStringTagsProps {
	onChange: (newValue) => void;
	value: string[];
	field: string;
	add?: boolean;
	translateDic?: boolean;
}

const StringTags = ({ onChange, value = [], field, add, translateDic = true }: IStringTagsProps) => {
	const { t } = useTranslation();
	// @ts-ignore
	const dictionary = getDictionary(field);
	const [isAddMode, setIsAddMode] = useState<boolean>(false);
	const [currentlyAdded, setCurrentlyAdded] = useState<string>("");

	const handleDelete = index => {
		onChange(value.filter((_, i) => i !== index));
	};

	const handleAdd = category => {
		onChange([...value, category]);
	};

	const handleNew = () => {
		if (!currentlyAdded) {
			return;
		}

		onChange([...value, currentlyAdded]);
		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	const handleCancel = () => {
		setCurrentlyAdded("");
		setIsAddMode(false);
	};

	return (
		<Grid container spacing={8} direction="column">
			<Grid item xs={12}>
				<Grid container direction="row">
					{value.map((v, i) => (
						<Box display="flex" pr={5} pb={5} key={v}>
							<Chip
								color="primary"
								label={translateDic ? t(`EXPERIENCE.${field}.${v}`) : v}
								onDelete={() => handleDelete(i)}
								deleteIcon={<Cancel />}
							/>
						</Box>
					))}
				</Grid>
			</Grid>

			<Grid item xs>
				<Grid container direction="row" spacing={2}>
					{dictionary.map(d =>
						value.indexOf(d) === -1 ? (
							<Grid item key={d}>
								<Box display="flex" pr={5} pb={5}>
									<Chip
										label={translateDic ? t(`EXPERIENCE.${field}.${d}`) : d}
										onDelete={() => handleAdd(d)}
										onClick={() => handleAdd(d)}
										deleteIcon={<Add />}
										size="small"
									/>
								</Box>
							</Grid>
						) : null,
					)}

					{add && (
						<Box display="flex" pr={5} pb={5}>
							<Chip
								variant="outlined"
								label={t(`EXPERIENCE.FORM.STRING_TAGS.ADD_POSITION.DEFAULT`)}
								onClick={() => setIsAddMode(true)}
								size="small"
							/>
						</Box>
					)}
				</Grid>
			</Grid>

			{isAddMode && (
				<List key="list">
					<ListItem divider key="add_more">
						<TextField
							placeholder={t("EXPERIENCE.FORM.STRING_TAGS.PLACEHOLDER.DEFAULT")}
							variant="outlined"
							onChange={e => setCurrentlyAdded(e.target.value)}
							value={currentlyAdded}
							size="small"
						/>

						<ListItemSecondaryAction>
							<Box display="flex" flexDirection="row">
								<Box pr={4}>
									<IconButton type="button" edge="end" aria-label="accept" color="inherit" onClick={handleNew}>
										<Check />
									</IconButton>
								</Box>

								<Box>
									<IconButton type="button" edge="end" aria-label="close" color="inherit" onClick={handleCancel}>
										<Close />
									</IconButton>
								</Box>
							</Box>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			)}
		</Grid>
	);
};

export default StringTags;
