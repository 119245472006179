import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	List,
	ListItem,
	ListItemText,
	Radio,
	RadioGroup,
} from "@material-ui/core";
import { FormikProps } from "formik";
import { isEmpty } from "lodash-es";
import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Experience from "classes/Experience";

import { IExperience as FormValues } from "data/experiences/types";
import { ETypeRadioGroup } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import useCountry from "store/hooks/useCountry";
import { selectUser } from "store/selectors/user";

import { minutesToHM } from "utils/dates";
import { getEarnings } from "utils/prices";
import fillRoute from "utils/routes";

import PrivatePrice from "components/creation-process/PrivatePrice";
import TableFixedPrice from "components/creation-process/tables-pricing/TableFixedPrice";
import TableTieredPrice from "components/creation-process/tables-pricing/TableTieredPrice";
import PriceOfYourExp from "components/creation-process/tooltips/pricing/PriceOfYourExp";
import RecordHP from "components/experiences/RecordHP";
import Input from "components/formik/Input";

import BarChartVertical from "ui/BarChartVertical";
import { Tooltip } from "ui/tooltips/Tooltip";

import { StyledLink } from "styles/common";
import media from "styles/media";

import {
	ItemDescription,
	ItemTitle,
	ItemWrapper,
	MainTitleSection,
	TitleTooltipWrapper,
	Wrapper,
} from "../shared.styled";

const StyledList = styled(List)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${media.tablet`
    flex-direction: column;
    justify-content: flex-start;
  `};
`;

const StyledButton = styled(Button)`
	height: 36px;
	width: 130px;
`;

const Step5 = ({ isSubmitting, values, errors, setFieldValue, touched }: FormikProps<FormValues>) => {
	const { id } = useParams<{ id: string }>();

	const userData = useAppSelector(selectUser);

	const { findByName } = useCountry();

	const [valueRadioGroup, setValueRadioGroup] = useState<ETypeRadioGroup>(
		values.pricing.price_per_person ? ETypeRadioGroup.fixed : ETypeRadioGroup.tiered,
	);
	const [checkedKidsFreeUnder, setCheckedKidsFreeUnder] = useState<boolean>(!!values.pricing.kids_free_under);

	const currency = findByName(values?.country)?.currency;

	const handleChangeKidsFreeUnder = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedKidsFreeUnder(event.target.checked);
		if (!event.target.checked) {
			setFieldValue("pricing.kids_free_under", null);
		}
	};

	const handleChangeRadioGroup = (event: ChangeEvent<HTMLInputElement>) => {
		setValueRadioGroup(event.target.value as ETypeRadioGroup);
		if (event.target.value === ETypeRadioGroup.fixed) {
			setFieldValue("pricing.tiered_pricing_details", {});
		} else if (event.target.value === ETypeRadioGroup.tiered) {
			setFieldValue("pricing.price_per_person", null);
		}
	};

	const { t } = useTranslation();

	return (
		<Wrapper>
			<MainTitleSection>{t("EXPERIENCE.PRICING")}</MainTitleSection>

			<ItemWrapper>
				<TitleTooltipWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.pricingType.TITLE")}</ItemTitle>

					<Tooltip>
						<PriceOfYourExp />
					</Tooltip>
				</TitleTooltipWrapper>

				<ItemDescription>{t("EXPERIENCE.FIELDS.pricingType.DESCRIPTION")}</ItemDescription>

				<RadioGroup
					aria-label="price-per-person"
					name="price-per-person"
					value={valueRadioGroup}
					onChange={handleChangeRadioGroup}
				>
					<StyledList key="radio-group-fixed-tiered" dense>
						<ListItem>
							<Radio value={ETypeRadioGroup.fixed} />

							<ListItemText
								primary={t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.RADIO_GROUP.FIXED_PRICE.TITLE")}
								secondary={t("EXPERIENCE.FORM.NUMBER_RADIO_INPUT.FIXED_PRICE.DESCRIPTION")}
							/>
						</ListItem>

						<ListItem>
							<Radio value={ETypeRadioGroup.tiered} />

							<ListItemText
								primary={t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.RADIO_GROUP.TIERED_PRICE.TITLE")}
								secondary={t("EXPERIENCE.FORM.NUMBER_RADIO_INPUT.TIERED_PRICE.DESCRIPTION")}
							/>
						</ListItem>
					</StyledList>
				</RadioGroup>

				{valueRadioGroup === ETypeRadioGroup.fixed ? (
					<TableFixedPrice
						onChange={v => setFieldValue("pricing.price_per_person", v)}
						value={values.pricing.price_per_person || null}
						currency={currency}
					/>
				) : (
					<TableTieredPrice
						value={values.pricing.tiered_pricing_details || {}}
						onChange={v => setFieldValue("pricing.tiered_pricing_details", { ...v })}
						minValue={values.number_of_travelers?.min || 1}
						currency={currency}
						maxValue={values.number_of_travelers?.max || undefined}
					/>
				)}
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.pricing.your_potential_earnings.TITLE")}</ItemTitle>

				<Box marginTop="40px" marginBottom="40px">
					<BarChartVertical
						width="60px"
						currency={currency}
						value={
							// @todo -> Experience.getPriceChartData
							valueRadioGroup === ETypeRadioGroup.fixed
								? !!values.pricing.price_per_person && !!getEarnings(values.pricing.price_per_person)
									? Experience.countPricePerPersonFixed(values.pricing.price_per_person)
									: undefined
								: !!values.pricing.tiered_pricing_details
								? Experience.countPricePerPersonTiered(values.pricing.tiered_pricing_details)
								: undefined
						}
					/>
				</Box>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_PRICE_FOR_CHILDREN")}</ItemTitle>
				<Box display="flex" marginTop="30px">
					<FormControlLabel
						control={
							<Checkbox
								checked={checkedKidsFreeUnder}
								onChange={handleChangeKidsFreeUnder}
								name="checkbox-kids_free_under"
							/>
						}
						label={t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.KIDS_UNDER_LABEL")}
					/>

					<Input
						inputAndWrapperStyles={{ width: "90px" }}
						name="pricing.kids_free_under"
						label={t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.YEARS_OLD_LABEL")}
						type="number"
						readOnly={isSubmitting}
						value={values.pricing.kids_free_under ?? 1}
						inputProps={{ min: 1 }}
						disabled={!checkedKidsFreeUnder}
						onChange={(v: ChangeEvent<HTMLInputElement>) => setFieldValue("pricing.kids_free_under", v.target.value)}
					/>
				</Box>

				<TableTieredPrice
					field="JSON_PRICES_FOR_CHILDREN"
					onChange={v => setFieldValue("pricing.kids_pricing_details", { ...v })}
					value={values.pricing.kids_pricing_details || {}}
					currency={currency}
					minValue={values.pricing.kids_free_under ? Number(values.pricing.kids_free_under) + 1 : 1}
				/>
			</ItemWrapper>

			<ItemWrapper>
				<ItemTitle>{t("EXPERIENCE.FIELDS.pricing.private_price.TITLE")}</ItemTitle>
				<ItemDescription>{t("EXPERIENCE.FIELDS.pricing.private_price.DESCRIPTION")}</ItemDescription>

				<PrivatePrice
					onChange={v => setFieldValue("pricing.private_price", v)}
					value={values.pricing.private_price || null}
					currency={currency}
					chartValues={
						// @todo -> Experience.getPriceChartData
						valueRadioGroup === ETypeRadioGroup.fixed
							? !!values.pricing.price_per_person && !!getEarnings(values.pricing.price_per_person)
								? Experience.countPricePerPersonFixed(values.pricing.price_per_person)
								: undefined
							: !!values.pricing.tiered_pricing_details
							? Experience.countPricePerPersonTiered(values.pricing.tiered_pricing_details)
							: undefined
					}
				/>

				{touched.pricing && errors?.pricing?.private_price && (
					<FormHelperText error>{t("ERRORS.TOO_SHORT_MIN_1")}</FormHelperText>
				)}
			</ItemWrapper>

			<Box marginTop="40px" marginBottom="100px">
				<ItemWrapper>
					<ItemTitle>{t("EXPERIENCE.FIELDS.pricing.preview.TITLE")}</ItemTitle>

					<ItemDescription>{t("EXPERIENCE.FIELDS.pricing.preview.DESCRIPTION")}</ItemDescription>

					<RecordHP
						avatarUrl={userData?.profile_draft?.pictures?.profile || undefined}
						city={values.city}
						hours={minutesToHM(values.duration_minutes) || ""}
						imgUrl={values.photo_main || undefined}
						name={userData?.profile_draft?.first_name}
						pricePerPerson={
							values.pricing.price_per_person ||
							(!!values.pricing?.tiered_pricing_details &&
								!isEmpty(values.pricing.tiered_pricing_details) &&
								Math.min(...Object.values(values.pricing.tiered_pricing_details))) ||
							0
						}
						ratings={{
							count: 0,
							average: 0,
						}}
						title={values.title}
						exp_type="UNIQUE"
					/>
				</ItemWrapper>

				<StyledLink
					$withoutHoverUnderline
					to={fillRoute(paths.UPDATE_EXPERIENCE_PAGE, { id, search: { preview: true } })}
				>
					<StyledButton color="primary" variant="contained">
						{t("EXPERIENCE.FULL_PREVIEW_BTN")}
					</StyledButton>
				</StyledLink>
			</Box>
		</Wrapper>
	);
};

export default Step5;
