import { Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";

export const spaceDefault = 16;

export const mobileWidth = "425px";
export const tabletWidth = "1024px";
export const desktopWidth = "1440px";

export const MainContainer = styled.main`
	height: 100%;
	padding: ${spaceDefault}px;
`;

export const MainContent = styled.section<{ maxWidth?: number; flex?: number }>`
	display: flex;
	max-width: 460px;
	margin: 0 auto;
	flex-direction: column;

	${props => props.maxWidth && `max-width: ${props.maxWidth}px`};
	${props => props.flex && `flex: ${props.flex}`};
`;

export const StepContainer = styled.div`
	margin-bottom: ${spaceDefault * 2}px;
`;

export const InputsContainer = styled(StepContainer)`
	display: flex;
	flex-direction: column;
`;

export const TitleHeader = styled.h2<{ mb?: number }>`
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	margin-bottom: ${props => (props.mb ? props.mb : spaceDefault * 2)}px;
	text-align: center;
	line-height: 40px;
`;

export const Alignment = styled.div`
	@media screen and (min-height: 625px) and (min-width: ${tabletWidth}) {
		display: flex;
		height: calc(100vh - 135px);
		align-items: center;
	}
`;

export const Desc = styled.p<{ textAlign?: string }>`
	margin-bottom: ${spaceDefault * 2}px;
	font-size: 14px;
	text-align: ${props => props.textAlign || "center"};
	line-height: 24px;
	color: #666;
`;

export const StyledButton = styled(Button)`
	width: 250px;
	margin: 0 auto;
`;

export const StyledAutocomplete = styled(Autocomplete)`
	.MuiChip-root {
		background-color: ${colors.lightOrange};
	}
`;

export const LanguageSelectorContainer = styled.div`
	position: relative;
	width: 100%;

	@media screen and (max-width: ${desktopWidth}) {
		height: 60px;
	}
`;
