import { IDecorator } from "../types";
import LogoDecorator from "./decorators/LogoDecorator";
import WithoutLogoDecorator from "./decorators/WithoutLogoDecorator";

const decoratorToComponent = {
	withoutLogo: WithoutLogoDecorator,
};

const Decorator = ({ decorator, children, ...props }: IDecorator) => {
	if (!decorator) {
		return <LogoDecorator {...props}>{children}</LogoDecorator>;
	}

	const CustomDecorator = decoratorToComponent[decorator];
	return <CustomDecorator {...props}>{children}</CustomDecorator>;
};

export default Decorator;
