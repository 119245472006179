import { FormikProvider, useFormik } from "formik";
import { isEmpty } from "lodash-es";
import { memo, useState } from "react";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import Ratings from "components/Ratings";
import Textarea from "components/Ratings/components/Textarea";
import { FormikForm } from "components/Ratings/shared.styled";
import { TRateResult } from "components/Ratings/types";

import ActionButton from "ui/buttons/ActionButton";
import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";
import Stepper from "ui/Dialog/components/Stepper";

import { iconsShort } from "../../config";
import { ESteps, IRateAction } from "../../types";
import { BookingInfo } from "../BookingInfo";

const categories = {
	friendliness: null,
};

interface IFormValues {
	comment: string | null;
}

type TRateResultWithCategories = TRateResult<typeof categories>;

const customDescription = {
	0: "RATING.NPS.RATE_NO_LABEL",
	1: "RATING.NPS.RATE_MAYBE_LABEL",
	2: "RATING.NPS.RATE_YES_LABEL",
};

const RateNPS = memo(({ action, bookingData }: IRateAction) => {
	const { t, withRaw } = useTranslation();

	const {
		yup: { validations, requiredStringMinMaxLength, stringMinMaxLength },
	} = useValidations();

	const { addError } = useNotification();

	const [ratingSelected, setRatingSelected] = useState<boolean>(false);
	const [ratingData, setRatingData] = useState<TRateResultWithCategories>();
	const [isAction, setIsAction] = useState<boolean>(false);

	const save = async (formikValues: IFormValues) => {
		if (ratingSelected) {
			setIsAction(true);

			const requestData = {
				answer: ratingData?.friendliness || 0,
				comment: formikValues.comment || null,
			};

			await action(ESteps.NPS, requestData, ESteps.EXPERIENCE);

			setIsAction(false);

			return;
		}

		return addError(t("RATING.REQUIRED.NPS"));
	};

	const formikProps = useFormik<IFormValues>({
		enableReinitialize: true,
		initialValues: {
			comment: null,
		},
		validationSchema: validations({
			comment:
				ratingData?.friendliness === 0
					? requiredStringMinMaxLength(30, 500).nullable()
					: stringMinMaxLength(30, 500).nullable(),
		}),
		onSubmit: async values => await save(values),
	});

	const handleData = (data: TRateResultWithCategories) => {
		if (!isEmpty(data) && Object.values(data).every(elem => typeof elem === "number")) {
			setRatingSelected(true);
		} else {
			setRatingSelected(false);
		}

		formikProps.resetForm();

		setRatingData(data);
	};

	return (
		<>
			<DialogContentWrapper
				title={
					<>
						{t("RATING.NPS.TITLE")}

						{bookingData && <BookingInfo bookingData={bookingData} />}
					</>
				}
				center
				underline
			>
				<Ratings
					icons={iconsShort}
					categories={categories}
					name={t("RATING.USER.NPS_RATE_TITLE")}
					update={handleData}
					settings={{
						withoutLabel: true,
						size: 100,
						withBottomLabel: true,
					}}
				/>

				<FormikProvider value={formikProps}>
					<FormikForm>
						{ratingSelected && (
							<Textarea
								name="comment"
								placeholder={`${t("RATING.COMMENT_FORM_LABEL")} ${
									ratingData?.friendliness === 0 ? "*" : `(${t("COMMON.OPTIONAL")})`
								}`}
								nodeLabel={withRaw(customDescription[ratingData?.friendliness || 0], null)}
								multiline
								required={ratingData?.friendliness === 0}
							/>
						)}

						<Stepper>
							<ActionButton
								onClick={() => formikProps.handleSubmit()}
								type="button"
								translationDefault={
									ratingData?.friendliness === 0 ? "RATING.USER.LOCALHOST_NEXT_BUTTON_TITLE" : "RATING.NPS.START_BUTTON"
								}
								isAction={isAction}
								size="large"
							/>
						</Stepper>
					</FormikForm>
				</FormikProvider>
			</DialogContentWrapper>
		</>
	);
});

export default RateNPS;
