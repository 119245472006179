import RegisterSocialImg from "assets/images/RegisterSocialImg.jpg";

import ForgotPasswordView from "components/auth/ForgotPasswordView";

import AppWithImageWrapper from "ui/AppWithImageWrapper";

const ForgotPasswordPage = () => (
	<AppWithImageWrapper image={RegisterSocialImg}>
		<ForgotPasswordView />
	</AppWithImageWrapper>
);

export default ForgotPasswordPage;
