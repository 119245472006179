import { Grid } from "@material-ui/core";
import { ReactNode, RefObject } from "react";
import styled from "styled-components";

import { MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const Title = styled.h3`
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xxlarge};
	font-weight: normal;
	margin-bottom: 0;
	line-height: 1.86;

	${media.phone`
    font-size: ${dimensions.fontSize.xxxlarge};
  `};
`;

const Description = styled.div`
	font-family: ${MontserratFontFamily};
	overflow-wrap: break-word;
	white-space: normal;
	line-height: 1.86;
`;

interface IHelpSection {
	title: string | ReactNode;
	descriptionLeft: string | ReactNode;
	descriptionRight?: string | ReactNode;
	refElement?: RefObject<HTMLDivElement>;
	id?: string;
}

const HelpSection = ({ title, descriptionLeft, descriptionRight, refElement, id }: IHelpSection) => (
	<section ref={refElement} id={id}>
		<Title>{title}</Title>

		<Grid container spacing={10}>
			<Grid item md={descriptionRight ? 6 : 12} xs={12}>
				<Description>{descriptionLeft}</Description>
			</Grid>

			{descriptionRight && (
				<Grid item md={6} xs={12}>
					<Description>{descriptionRight}</Description>
				</Grid>
			)}
		</Grid>
	</section>
);

export default HelpSection;
