import { Box } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import { EExpType, EPromotingSections, IExperience } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import experiencesService from "services/api/experiences";

import fillRoute from "utils/routes";

import { SmallerTitle } from "ui/CommonParagraph";
import CircularLoader from "ui/loaders/CircularLoader";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import { WrapperSection } from "../../index";

const Background = styled.div`
	width: 100%;
	padding: 1rem 0 3rem;
	background-image: linear-gradient(177deg, rgba(255, 244, 229, 0) 16%, rgba(255, 229, 210, 0.63) 84%);
	clip-path: ellipse(150% 50% at 50% 50%);

	${media.desktop`
    clip-path: ellipse(200% 50% at 50% 50%);
  `};
`;

const TilesWrapper = styled.div`
	margin: 1.875rem auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	${media.desktop`
    justify-content: center;
  `};
`;

const TileWrapper = styled.div<{ $backgroundUrl: string }>`
	margin: 0.625rem 0.313rem 15px 0;
	padding: 32px;
	width: 428px;
	height: 542px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.$backgroundUrl});
	background-repeat: no-repeat;
	background-position: center;
	border-radius: ${dimensions.radius.medium};
	background-size: 100% 100%;
	transition: background-size 0.5s linear;
	cursor: pointer;

	&:hover {
		background-size: 105% 105%;
	}

	${media.desktop`
    margin: 1.875rem 5px 0 0;
  `};

	${media.phone`
    width: 100%;
    height: 428px;
  `};
`;

const TileTitle = styled.h3`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	color: ${colors.white};
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.75px;
	text-decoration: none;

	${media.phone`
    font-size: 16px;
  `};
`;

const SomeInspiration = memo(() => {
	const { withRaw } = useTranslation();

	const [experiences, setExperiences] = useState<IExperience[] | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const experiencesData = await experiencesService.getListOfExperiences({
					exposures: EPromotingSections["UNIQUE-EXP-LP"],
					exp_type: EExpType.UNIQUE,
					limit: 100,
				});

				setExperiences(experiencesData.data);
			} catch {
				setExperiences([]);
			}
		};

		fetchData();
	}, []);

	return (
		<WrapperSection>
			<Background>
				<Container>
					<SmallerTitle textAlignCenter>{withRaw("UNIQUE_EXPERIENCES.SOME_INSPIRATION_TITLE")}</SmallerTitle>

					<TilesWrapper>
						{experiences === null ? (
							<Box display="flex" width="100%" justifyContent="center">
								<CircularLoader absolute={false} size={60} />
							</Box>
						) : (
							experiences.map((value, i) => (
								<StyledLink
									$withoutHoverUnderline
									key={value.id}
									to={
										value.id
											? fillRoute(paths.SEARCH_EXPERIENCES, {
													title: slugify(value.title.toLowerCase() || ""),
													id: value.id,
											  })
											: ""
									}
								>
									<TileWrapper $backgroundUrl={value?.photo_main || ""}>
										<TileTitle>{value.title}</TileTitle>
									</TileWrapper>
								</StyledLink>
							))
						)}
					</TilesWrapper>
				</Container>
			</Background>
		</WrapperSection>
	);
});

export default SomeInspiration;
