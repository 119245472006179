import { Box } from "@material-ui/core";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";
import CommonParagraph from "ui/CommonParagraph";
import HelpSection from "ui/HelpSection";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";

const StyledLinkToSection = styled.div`
	font-family: ${MontserratFontFamily};
	font-size: 16px;
	line-height: 2.2;
	letter-spacing: 0.03px;
	font-weight: bold;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const CookiesPage = () => {
	const { t, withRaw, withComponents } = useTranslation();

	const refLink1 = useRef<HTMLDivElement | null>(null);
	const refLink2 = useRef<HTMLDivElement | null>(null);
	const refLink3 = useRef<HTMLDivElement | null>(null);
	const refLink4 = useRef<HTMLDivElement | null>(null);
	const refLink5 = useRef<HTMLDivElement | null>(null);

	const scrollToInfo = currentRef => {
		if (currentRef.current) {
			window.scrollTo({
				behavior: "smooth",
				top: currentRef.current.offsetTop,
			});
		}
	};

	return (
		<AppWrapper>
			<Helmet>
				<link rel="canonical" />
				<meta name="robots" content="noindex, follow" />
			</Helmet>

			<Container>
				<Breadcrumbs
					list={[
						{
							route: paths.HELP_LEGAL_TERMS,
							label: t("FOOTER.LINKS.LEGAL_TERMS.label"),
						},
					]}
					lastBreadcrumbLabel={t("HELP.COOKIES.TITLE")}
				/>

				<CommonParagraph
					withMarginTop
					mainTitle={t("HELP.COOKIES.TITLE")}
					subTitle={t("HELP.COOKIES.UPDATED_AT")}
					description={t("HELP.COOKIES.MAIN_DESCRIPTION")}
				/>

				<Box marginTop="30px" marginBottom="30px">
					<StyledLinkToSection onClick={() => scrollToInfo(refLink1)}>
						{withRaw("HELP.COOKIES.SECTION_1.TITLE")}
					</StyledLinkToSection>

					<StyledLinkToSection onClick={() => scrollToInfo(refLink2)}>
						{withRaw("HELP.COOKIES.SECTION_2.TITLE")}
					</StyledLinkToSection>

					<StyledLinkToSection onClick={() => scrollToInfo(refLink3)}>
						{withRaw("HELP.COOKIES.SECTION_3.TITLE")}
					</StyledLinkToSection>

					<StyledLinkToSection onClick={() => scrollToInfo(refLink4)}>
						{withRaw("HELP.COOKIES.SECTION_4.TITLE")}
					</StyledLinkToSection>

					<StyledLinkToSection onClick={() => scrollToInfo(refLink5)}>
						{withRaw("HELP.COOKIES.SECTION_5.TITLE")}
					</StyledLinkToSection>
				</Box>

				<HelpSection
					refElement={refLink1}
					title={t("HELP.COOKIES.SECTION_1.TITLE")}
					descriptionLeft={withRaw("HELP.COOKIES.SECTION_1.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refLink2}
					title={t("HELP.COOKIES.SECTION_2.TITLE")}
					descriptionLeft={withRaw("HELP.COOKIES.SECTION_2.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refLink3}
					title={t("HELP.COOKIES.SECTION_3.TITLE")}
					descriptionLeft={withRaw("HELP.COOKIES.SECTION_3.DESCRIPTION")}
				/>

				<HelpSection
					refElement={refLink4}
					title={t("HELP.COOKIES.SECTION_4.TITLE")}
					descriptionLeft={withComponents("HELP.COOKIES.SECTION_4.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>

				<HelpSection
					refElement={refLink5}
					title={t("HELP.COOKIES.SECTION_5.TITLE")}
					descriptionLeft={withComponents("HELP.COOKIES.SECTION_5.DESCRIPTION", {
						Link: <StyledLink $color={colors.sunShade} $decoration="underline" to={paths.CONTACT} />,
					})}
				/>
			</Container>
		</AppWrapper>
	);
};

export default CookiesPage;
