import { SwiperOptions } from "swiper";

export const carouselSwiperConfig: SwiperOptions = {
	lazy: true,
	slidesPerView: 1,
	pagination: { clickable: true },
	breakpoints: {
		300: {
			spaceBetween: 20,
			width: 304,
		},
		600: {
			spaceBetween: 50,
		},
		960: {
			spaceBetween: 135,
			width: 434,
		},
	},
};
