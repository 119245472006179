import { Form as Formik } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Formik)`
	display: flex;
	flex-direction: column;
	margin: 15px 0;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

export const RememberMeContainer = styled.div`
	margin-bottom: 15px;
`;
