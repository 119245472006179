import { Box, Divider } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import styled, { css } from "styled-components";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";
import media from "styles/media";

export const Wrapper = styled.div`
	width: 100%;
	background: ${colors.white};
	border-radius: 4px;
	box-shadow: 0 0 40px 0 #cecece;

	${media.largeDesktop`
      margin-bottom: 40px;
  `};
`;

export const OrangeBox = styled.div`
	display: flex;
	align-items: center;
	padding-left: 10px;
	height: 36px;
	width: 100%;
	border-radius: 4px 4px 0 0;
	background: ${colors.sunShade};
	font-family: ${RobotoFontFamily};
	letter-spacing: 0.83px;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.white};
	text-transform: uppercase;
`;

export const Text = styled.div`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.1px;
	color: ${colors.mainFontColor};
`;

export const StyledDivider = styled(Divider)`
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.54);
`;

export const ShowHideSection = styled.div`
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 10px;
	cursor: pointer;
`;

export const BarChartContainer = styled(Box)`
	overflow: auto;
`;

const IconsCommonStyles = css`
	font-size: 24px;
	color: ${colors.mainFontColor};
`;

export const StyledKeyboardArrowUp = styled(KeyboardArrowUp)`
	${IconsCommonStyles};
`;

export const StyledKeyboardArrowDown = styled(KeyboardArrowDown)`
	${IconsCommonStyles};
`;
