import { Box } from "@material-ui/core";
import styled from "styled-components";

import colors from "styles/colors";

export const LabelContent = styled.div<{ backgroundColor: string }>`
	margin-right: 5px;
	padding: 3px 20px 4px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${props => props.backgroundColor};
	color: ${colors.white};
	font-weight: 500;
	border-radius: 4px;
	white-space: nowrap;
`;

export const buttonAdditionalStyles = { fontSize: 16, color: colors.white, marginRight: 10 };

interface IStatusLabelBox<Type extends string> {
	getLabel: (name) => { icon: string; label: string; color: string };
	type: Type;
}

export const StatusLabelBox = <Type extends string>({ getLabel, type }: IStatusLabelBox<Type>) => (
	<Box display="flex">
		{type
			.split("__")
			.map(getLabel)
			.map(({ color, icon, label }) => (
				<LabelContent backgroundColor={color} key={`label-${label}-${color}`}>
					{icon}

					{label}
				</LabelContent>
			))}
	</Box>
);
