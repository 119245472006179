import qs from "query-string";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { IBookingIId } from "data/backoffice/bookings/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";
import fillRoute from "utils/routes";

import { RobotoFontFamily, StyledLink } from "styles/common";

const Container = styled.div`
	font-family: ${RobotoFontFamily};
	font-weight: normal;
	font-size: 13px;
	margin-top: 25px;
`;

interface IBookingInfo {
	bookingData: IBookingIId;
}

export const BookingInfo = ({ bookingData }: IBookingInfo) => {
	const { t } = useTranslation();

	const location = useLocation();

	const { token } = qs.parse(location.search);

	return (
		<Container>
			{t("RATING.FOR_BOOKING_LABEL")}{" "}
			<StyledLink
				to={fillRoute(paths.GUEST_BOOKING_PAGE, {
					booking_id: bookingData.id,
					token: null,
					...(!!token && { search: { token } }),
				})}
				target="_blank"
				$decoration="underline"
			>
				<strong>
					{bookingData.exp_title},&nbsp;{parseISOWrapper(bookingData.date_time, DATE_FORMATS.DATETIME_FORMAT3)}
				</strong>
			</StyledLink>
		</Container>
	);
};
