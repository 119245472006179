import {
	Box,
	FormControlLabel,
	FormHelperText,
	LinearProgress,
	Switch,
	TextField,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import { forIn, isEmpty } from "lodash-es";
import { ChangeEvent, lazy, Suspense, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { EUserType } from "data/users/types";

import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import useLanguages from "store/hooks/useLanguages";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { experienceTravellerActions } from "store/slices/experienceTraveller";

import { getKidsAges } from "utils/booking";

import AppWrapper from "components/layout/AppWrapper";

import BookingInfo from "ui/BookingInfo";
import Checkbox from "ui/forms/Checkbox";

import colors from "styles/colors";
import { Container, RobotoFontFamily, StyledLink } from "styles/common";
import media from "styles/media";

import { MainTitle } from "../../shared.styled";
import BreadCrumbsBooking from "../BreadCrumbsBooking";
import DetailsBox from "../DetailsBox";
import Stepper from "../Stepper";
import BookingDetails from "./components/BookingDetails";
import PersonalDetails, { Row } from "./components/PersonalDetails";

export const SubTitle = styled.h2`
	margin: 0;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.5px;
	color: ${colors.mainFontColor};
	text-transform: uppercase;

	${media.tablet`
    font-size: 14px;
  `};
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${media.tablet`
    align-items: center;
    flex-direction: column;
    padding: 0;
  `};
`;

const SummaryCol = styled.div`
	flex: 2;
	padding-right: 50px;

	${media.desktop`
    padding-right: 0;
  `};
`;

const BookingCol = styled.div`
	flex: 1;

	${media.tablet`
    margin: 50px auto;
  `};
`;

export const Text = styled.span<{ lowercase?: boolean }>`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	color: ${colors.mainFontColor};
	text-transform: ${props => (props.lowercase ? "lowercase" : "capitalize")};

	${media.tablet`
    font-size: 12px;
  `};
`;

export const TextBold = styled(Text)<{ color?: string }>`
	font-weight: bold;

	${props => !!props.color && `color: ${props.color}`};
`;

const FormTextField = styled(TextField)`
	width: 100%;

	input {
		padding: 8px;
		font-size: 14px;
	}
`;

const CommentTextField = styled(TextField)`
	margin-top: 18px;
	width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
	padding: 20px 0 30px;

	.MuiTypography-body1 {
		font-size: 14px;
		font-family: ${RobotoFontFamily};
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.83px;
	}

	${media.tablet`
    .MuiTypography-body1 {
      font-size: 12px;
    }
  `};
`;

const PhoneInputUI = lazy(() => import("ui/forms/PhoneInput"));

const BookingSummary = () => {
	const { t } = useTranslation();

	const booking = useAppSelector(selectExperienceTravellerBooking);
	const userData = useAppSelector(selectUser);

	const history = useHistory();
	const match = useRouteMatch();

	const dispatch = useAppDispatch();

	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { findNameByKey } = useLanguages();

	const { addError } = useNotification();

	const [switcherChecked, setSwitcherChecked] = useState<boolean>(false);
	const [travelerName, setTravelerName] = useState<string>("");
	const [travelerLastName, setTravelerLastName] = useState<string>("");
	const [travelerMainPhone, setTravelerMainPhone] = useState<string>("");
	const [countryMainSelected, setCountryMainSelected] = useState<boolean | null>(null);
	const [travelerPhone, setTravelerPhone] = useState<string>("");
	const [countrySelected, setCountrySelected] = useState<boolean | null>(null);
	const [travelerEmail, setTravelerEmail] = useState<string>("");
	const [commentValue, setCommentValue] = useState<string>("");
	const [commentValidationError, setCommentValidationError] = useState<boolean>(false);
	const [agreeCheckboxValues, setAgreeCheckboxValues] = useState<{
		terms: boolean | null;
		cancellation: boolean | null;
		privacy: boolean | null;
	}>({ terms: null, cancellation: null, privacy: null });

	if (isEmpty(booking.date)) {
		history.push(`${match.url}?step=0`);

		return null;
	}

	const partnerType = ([EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER] as (EUserType | undefined)[]).includes(
		userData?.userType,
	);

	const nameUser =
		userData && !partnerType
			? userData.profile_draft?.first_name || userData.public_profile?.first_name
			: booking?.travelerDetails?.first_name;
	const lastNameUser =
		userData && !partnerType
			? userData.profile_draft?.last_name || userData.private_profile?.last_name
			: booking?.travelerDetails?.last_name;
	const emailUser = userData && !partnerType ? userData.personal?.email : booking?.travelerDetails?.email;
	const phoneUser = userData && !partnerType ? userData.personal?.phone : booking?.travelerDetails?.phone;

	const allAgreeCheckboxSet = Object.values(agreeCheckboxValues).every(v => v === true);
	const allAgreeCheckboxTouched = Object.values(agreeCheckboxValues).every(v => v !== null);

	const details = {
		first_name: switcherChecked ? travelerName : nameUser || "",
		last_name: switcherChecked ? travelerLastName : lastNameUser || "",
		email: switcherChecked ? travelerEmail : emailUser || "",
		phone: switcherChecked ? travelerPhone : phoneUser || travelerMainPhone,
	};

	const filledAllOfDetails = switcherChecked ? Object.values(details).every(v => !!v) : true;

	const phoneValidationError = switcherChecked ? false : !!phoneUser ? false : !travelerMainPhone;

	const showErrorOfValidation = () => {
		setAgreeCheckboxValues(prev => {
			const fields = { ...prev };

			forIn(fields, (value, key) => {
				if (value === null) {
					fields[key] = false;
				}
			});

			return fields;
		});

		let errorTranslation;

		if (phoneValidationError) {
			errorTranslation = "BOOKING.SUMMARY.PHONE_VALIDATION_ERROR";
		} else if (commentValidationError) {
			errorTranslation = "BOOKING.SUMMARY.COMMENTS_VALIDATION_ERROR";
		} else {
			errorTranslation = "ERRORS.ALL_FIELDS_REQUIRED";
		}

		addError(errorTranslation, true);
	};

	const handleChangeSwitcher = () => setSwitcherChecked(prevState => !prevState);

	const updateTravelerDetails = () => {
		dispatch(
			experienceTravellerActions.updateBooking({
				travelerDetails: details,
				comment: commentValue,
			}),
		);
	};

	const freeBooking = booking.pricing.final_gross_price === 0;

	return (
		<AppWrapper>
			<Container>
				<BreadCrumbsBooking
					lastBreadcrumb={booking.isPrivate ? t("BOOKING_SUMMARY.MAIN_TITLE_PRIVATE") : t("BOOKING_SUMMARY.MAIN_TITLE")}
				/>

				<InnerWrapper>
					<SummaryCol>
						<MainTitle>
							{booking.isPrivate ? t("BOOKING_SUMMARY.MAIN_TITLE_PRIVATE") : t("BOOKING_SUMMARY.MAIN_TITLE")}
						</MainTitle>

						<BookingDetails
							language={findNameByKey(booking.language)}
							children={getKidsAges(booking.selectedChildren, "kid-nr-")}
						/>

						<PersonalDetails
							firstname={nameUser || ""}
							lastname={lastNameUser || ""}
							email={emailUser || ""}
							phone={phoneUser || ""}
							phoneInputComponent={
								<Suspense fallback={<LinearProgress />}>
									<PhoneInputUI
										disabled={switcherChecked}
										customHeight="33px"
										noLabel
										noPlaceholder
										value={travelerMainPhone}
										onChange={(value: string, countryData: { dialCode?: string }, _, formattedValue: string) => {
											if (!!countryData?.dialCode) {
												setCountryMainSelected(true);
											} else {
												setCountryMainSelected(false);
											}

											if (value !== countryData?.dialCode) {
												setTravelerMainPhone(formattedValue.replace(/\s/g, ""));
											}
										}}
										onFocus={() => {
											if (!countryMainSelected) {
												setCountryMainSelected(false);
											}
										}}
									/>

									{!switcherChecked && countryMainSelected === false && (
										<FormHelperText error>{t("FORMS.PHONE_INPUT_FIELD.VALIDATION.NO_COUNTRY_SELECTED")}</FormHelperText>
									)}
								</Suspense>
							}
						/>

						{!partnerType && (
							<StyledFormControlLabel
								control={
									<Switch
										checked={switcherChecked}
										onChange={handleChangeSwitcher}
										name="booking-for-traveler-switcher"
									/>
								}
								label={t("BOOKING_SUMMARY.SWITCHER_BOOKING_FOR_SOMEONE_ELSE")}
							/>
						)}

						{switcherChecked && (
							<>
								<Box marginBottom="18px">
									<SubTitle>{t("BOOKING_SUMMARY.SUBTITLE_TRAVELER_DETAILS")}</SubTitle>
								</Box>

								<Row>
									<Box flex={0.7}>
										<Text>{t("BOOKING_SUMMARY.ROW_NAME")} *</Text>
									</Box>

									<Box flex={1} height="28px">
										<FormTextField
											id="id-traveler-name"
											variant="outlined"
											value={travelerName}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setTravelerName(e.target.value)}
										/>
									</Box>
								</Row>

								<Row>
									<Box flex={0.7}>
										<Text>{t("BOOKING_SUMMARY.ROW_LAST_NAME")} *</Text>
									</Box>

									<Box flex={1} height="28px">
										<FormTextField
											id="id-traveler-last-name"
											variant="outlined"
											value={travelerLastName}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setTravelerLastName(e.target.value)}
										/>
									</Box>
								</Row>

								<Row>
									<Box flex={0.7}>
										<Text>{t("BOOKING_SUMMARY.ROW_EMAIL")} *</Text>
									</Box>

									<Box flex={1}>
										<FormTextField
											id="id-traveler-email"
											variant="outlined"
											value={travelerEmail}
											onChange={(e: ChangeEvent<HTMLInputElement>) => setTravelerEmail(e.target.value)}
										/>
									</Box>
								</Row>

								<Row>
									<Box flex={0.7}>
										<Text>{t("BOOKING_SUMMARY.ROW_PHONE")} *</Text>
									</Box>

									<Box flex={1} marginTop="-3px">
										<Suspense fallback={<LinearProgress />}>
											<PhoneInputUI
												customHeight="33px"
												noLabel
												noPlaceholder
												value={travelerPhone}
												onChange={(value: string, countryData: { dialCode?: string }, _, formattedValue: string) => {
													if (!!countryData?.dialCode) {
														setCountrySelected(true);
													} else {
														setCountrySelected(false);
													}

													if (value !== countryData?.dialCode) {
														setTravelerPhone(formattedValue.replace(/\s/g, ""));
													}
												}}
												onFocus={() => {
													if (!countrySelected) {
														setCountrySelected(false);
													}
												}}
											/>

											{countrySelected === false && (
												<FormHelperText error>
													{t("FORMS.PHONE_INPUT_FIELD.VALIDATION.NO_COUNTRY_SELECTED")}
												</FormHelperText>
											)}
										</Suspense>
									</Box>
								</Row>
							</>
						)}

						<Box marginTop="40px" marginBottom="18px">
							<SubTitle>{t("BOOKING_SUMMARY.SUBTITLE_COMMENTS")}</SubTitle>

							<CommentTextField
								placeholder={t("BOOKING_SUMMARY.COMMENT_PLACEHOLDER")}
								id="id-comment"
								multiline
								rows={4}
								value={commentValue}
								variant="outlined"
								error={commentValidationError}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setCommentValue(e.target.value)}
								onBlur={() =>
									setCommentValidationError(
										!!commentValue.length && (commentValue.length < 2 || commentValue.length > 160),
									)
								}
							/>

							{commentValidationError && (
								<FormHelperText error>{t("BOOKING.SUMMARY.COMMENTS_VALIDATION_ERROR")}</FormHelperText>
							)}
						</Box>

						<Box marginY="28px">
							{t(partnerType ? "BOOKING.SUMMARY.TERMS_CONDITIONS_PARTNER" : "BOOKING.SUMMARY.TERMS_CONDITIONS")}

							<Box display="flex" alignItems="center" mt="10px">
								<Checkbox
									checked={allAgreeCheckboxSet}
									onChange={value =>
										setAgreeCheckboxValues(
											value
												? { terms: true, cancellation: true, privacy: true }
												: { terms: false, cancellation: false, privacy: false },
										)
									}
								/>

								{t("BOOKING_FORM.ALL_OF_BELOW")}
							</Box>

							<Box display="flex" alignItems="center" paddingLeft="22px">
								<Checkbox
									checked={!!agreeCheckboxValues.terms}
									onChange={value => setAgreeCheckboxValues(prev => ({ ...prev, terms: value }))}
								/>

								<StyledLink to={paths.HELP_LEGAL_TERMS}>
									<strong>{t("BOOKING_FORM.TERMS_CONDITIONS")} *</strong>
								</StyledLink>
							</Box>

							<Box display="flex" alignItems="center" paddingLeft="22px">
								<Checkbox
									checked={!!agreeCheckboxValues.cancellation}
									onChange={value => setAgreeCheckboxValues(prev => ({ ...prev, cancellation: value }))}
								/>

								<StyledLink to={paths.HELP_CANCELLATION_POLICY}>
									<strong>{t("BOOKING_FORM.CANCELLATION_POLICY")} *</strong>
								</StyledLink>
							</Box>

							<Box display="flex" alignItems="center" paddingLeft="22px">
								<Checkbox
									checked={!!agreeCheckboxValues.privacy}
									onChange={value => setAgreeCheckboxValues(prev => ({ ...prev, privacy: value }))}
								/>

								<StyledLink to={paths.HELP_PRIVACY_POLICY}>
									<strong>{t("BOOKING_FORM.PRIVACY_POLICY")} *</strong>
								</StyledLink>
							</Box>

							{allAgreeCheckboxTouched && !allAgreeCheckboxSet && (
								<FormHelperText error>{t("ERRORS.ALL_FIELDS_REQUIRED")}</FormHelperText>
							)}
						</Box>

						<Stepper
							customNext={
								allAgreeCheckboxSet && filledAllOfDetails && !commentValidationError && !phoneValidationError
									? undefined
									: showErrorOfValidation
							}
							onPrevStepClick={updateTravelerDetails}
							onNextStepClick={updateTravelerDetails}
							backText={t("BOOKING.STEPPER.BACK")}
							nextText={t(freeBooking ? "BOOKING.SUMMARY.NEXT_STEP_WITHOUT_PAYMENT" : "BOOKING.SUMMARY.NEXT_STEP")}
						/>
					</SummaryCol>

					{isMediumScreen ? (
						<DetailsBox hideButton {...booking} />
					) : (
						<BookingCol>
							<BookingInfo {...booking} />
						</BookingCol>
					)}
				</InnerWrapper>
			</Container>
		</AppWrapper>
	);
};

export default BookingSummary;
