import { isUndefined } from "lodash-es";
import { rgba } from "polished";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import colors from "styles/colors";
import { RobotoFontFamily } from "styles/common";

type TIconContent = { color: string; selected: number; index: number; size: number; padding?: number };

const selectedOrEmpty = (props: TIconContent) =>
	(props.selected > 0 && props.index === props.selected) || props.selected < 0;

const defaultPadding = 15;
const IconContent = styled.span<TIconContent>`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${props => (isUndefined(props?.padding) ? defaultPadding : props?.padding)}px;
	min-width: ${props => props.size + 2 * (isUndefined(props?.padding) ? defaultPadding : props?.padding)}px;
	width: ${props => props.size + 2 * (isUndefined(props?.padding) ? defaultPadding : props?.padding)}px;

	.icon-bottom-label {
		text-align: center;
		font-family: ${RobotoFontFamily};
		font-size: 14px;
		color: ${rgba(colors.mainFontColor, 0.87)};
		margin-top: 10px;
		font-weight: 500;

		${props =>
			selectedOrEmpty(props) &&
			css`
				font-weight: bold;
			`}
	}

	svg .fill-hover {
		${props =>
			selectedOrEmpty(props) &&
			css`
				fill: ${props.color};
			`}
	}

	&.MuiRating-iconActive {
		.fill-hover {
			fill: ${props => props.color};
		}

		.icon-bottom-label {
			font-weight: bold;
		}
	}
`;

interface IRatesIcon {
	children: ReactNode;
	color: string;
	selected?: number;
	index: number;
	size: number;
	label?: string;
	withLabel?: boolean;
	padding?: number;
}

const RatingsIcon = ({
	index,
	children,
	color,
	size,
	withLabel,
	label,
	padding,
	selected = -1,
	...props
}: IRatesIcon) => {
	const { t } = useTranslation();

	return (
		<IconContent color={color} selected={selected} index={index} size={size} padding={padding} {...props}>
			{children}

			{!!withLabel && !!label && <span className="icon-bottom-label">{t(label)}</span>}
		</IconContent>
	);
};

export default RatingsIcon;
