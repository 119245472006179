import { Box, Button, Dialog, DialogContent, Slider, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ChangeEvent, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import styled, { css } from "styled-components";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useTranslation from "hooks/useTranslation";

import resizeCanvasImage from "utils/canvas/resizeCanvasImage";

import CircularLoader from "ui/loaders/CircularLoader";

import colors from "styles/colors";
import { CloseIconBtn, MontserratFontFamily, RobotoFontFamily } from "styles/common";

export enum EDialogTypes {
	avatar = "avatar",
	cover = "cover",
}

interface IImageEditorDialog {
	showDialog: boolean;
	handleClose: () => void;
	saveImage: (imgBlob: Blob, imgBase64: string) => void;
	initialImage?: string;
	kind?: EDialogTypes;
}

const EditorContent = styled.div`
	padding: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
`;

const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 24px;
	font-weight: 300;
	text-align: center;
	line-height: 1.33;
	color: rgba(0, 0, 0, 0.87);
`;

const SubTitle = styled.h2`
	margin: 0;
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	font-weight: 400;
	line-height: 2;
	letter-spacing: 0.26px;
	text-align: center;
	color: ${colors.mainFontColor};
`;

const SliderContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const SliderLabel = styled.span`
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	font-weight: 500;
	cursor: pointer;
`;

const InputFileHidden = styled.input`
	visibility: hidden;
	height: 0;
	width: 0;
`;

const Container = styled.div<{ placeholderText?: string }>`
	position: relative;

	${props =>
		props.placeholderText &&
		css`
			&::after {
				content: "${props.placeholderText}";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				width: fit-content;
				height: fit-content;
				cursor: pointer;
			}
		`}
`;

const StyledCloseButton = styled(CloseIconBtn)`
	background-color: rgba(white, 0.7);

	&:hover {
		background-color: rgba(white, 0.9);
	}
`;

const editorProperties = {
	[EDialogTypes.avatar]: {
		width: 400,
		height: 400,
		border: 0,
		borderRadius: 200,
		titleKey: "PROFILE.UPDATE_PICTURE_TITLE",
		descriptionKey: "PROFILE.UPDATE_PICTURE_DESCRIPTION",
	},
	[EDialogTypes.cover]: {
		width: 500,
		height: 100,
		border: 10,
		borderRadius: 0,
		titleKey: "PROFILE.UPDATE_COVER_TITLE",
		descriptionKey: "PROFILE.UPDATE_COVER_DESCRIPTION",
	},
};

const ImageEditorDialog = ({
	showDialog,
	handleClose,
	saveImage,
	initialImage,
	kind = EDialogTypes.avatar,
}: IImageEditorDialog) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { t } = useTranslation();

	const { handleAndNotify } = useErrors();
	const { addError } = useNotification();

	const [scaleValue, setScaleValue] = useState<number>(1);
	const [image, setImage] = useState<string | File | null>(initialImage || null);
	const [touchedImg, setTouchedImg] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);

	const editor = useRef<ReturnType<typeof AvatarEditor>>();

	const changeScaleEvent = (_event: ChangeEvent<HTMLInputElement>, value: number) => {
		setScaleValue(value);
		setTouchedImg(true);
	};

	const changeScale = (value: number) => {
		let newScale = scaleValue;

		newScale += value;

		if (newScale >= 2) {
			setScaleValue(2);
		} else if (newScale <= 1) {
			setScaleValue(1);
		} else {
			setScaleValue(newScale);
		}

		setTouchedImg(true);
	};

	const saveClippedImage = async () => {
		try {
			if (editor && editor?.current) {
				setLoader(true);

				const canvasScaled = editor!.current.getImage();
				const imgBase64 = canvasScaled.toDataURL();

				const blob = await resizeCanvasImage(canvasScaled);

				saveImage(blob, imgBase64);

				handleClose();
			}
		} catch (e) {
			setLoader(false);

			handleAndNotify(e);
		}
	};

	const handleDrop = (dropped: File[]) => {
		const file = dropped[0];

		// ~ 6 MB
		if (file.size > 6291456 || !["png", "jpg", "jpeg"].includes(file.type.split("/")[1])) {
			addError(t("ERRORS.WRONG_FILE_SIZE_OR_TYPE"));

			return;
		}

		setImage(file);
		setTouchedImg(true);
	};

	const resetImage = () => setImage(null);

	return (
		<Dialog
			fullScreen={isSmallScreen}
			open={showDialog}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				{loader ? (
					<Box width="300px" height="300px" display="flex" justifyContent="center" alignItems="center">
						<CircularLoader disableShrink absolute={false}>
							<Box marginTop="30px">{t("PROFILE.UPLOAD_PICTURE.LOADER_DESCRIPTION")}</Box>
						</CircularLoader>
					</Box>
				) : (
					<>
						<CloseIconBtn aria-label="close-button" onClick={handleClose}>
							<CloseIcon />
						</CloseIconBtn>

						<EditorContent>
							<MainTitle>{t(editorProperties[kind].titleKey)}</MainTitle>

							<SubTitle>{t(editorProperties[kind].descriptionKey)}</SubTitle>

							<Box marginTop={13} marginBottom={5}>
								<Dropzone onDrop={handleDrop} noKeyboard noClick={!!image}>
									{({ getRootProps, getInputProps }) => (
										<Container
											{...getRootProps()}
											placeholderText={!image ? t("PROFILE.UPLOAD_PICTURE.PLACEHOLDER") : undefined}
										>
											<AvatarEditor
												image={image}
												width={isSmallScreen ? 300 : editorProperties[kind].width}
												height={isSmallScreen ? 300 : editorProperties[kind].height}
												border={editorProperties[kind].border}
												borderRadius={editorProperties[kind].borderRadius}
												color={[224, 224, 224, 0.5]}
												background={[0, 0, 0, 0.6]}
												scale={scaleValue}
												ref={editor}
												rotate={0}
												style={{ cursor: "pointer" }}
											/>

											<InputFileHidden name="new-image" {...getInputProps()} />

											{image && (
												<StyledCloseButton aria-label="close-button" onClick={resetImage}>
													<CloseIcon />
												</StyledCloseButton>
											)}
										</Container>
									)}
								</Dropzone>
							</Box>

							{image && (
								<SliderContent>
									<SliderLabel onClick={() => changeScale(-0.1)}>-</SliderLabel>

									<Slider
										value={scaleValue}
										aria-labelledby="change-scale-slider"
										step={0.1}
										min={1}
										max={2}
										onChange={changeScaleEvent}
									/>

									<SliderLabel onClick={() => changeScale(0.1)}>+</SliderLabel>
								</SliderContent>
							)}

							<br />

							<Button
								color="primary"
								disabled={!touchedImg || !image}
								onClick={saveClippedImage}
								variant="contained"
								style={{ width: "15rem" }}
							>
								{t("PROFILE.SAVE_PICTURE_BTN")}
							</Button>

							<Button onClick={handleClose} style={{ marginTop: "1rem" }}>
								{t("COMMON.CANCEL")}
							</Button>
						</EditorContent>
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default ImageEditorDialog;
