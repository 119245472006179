import { Button, ButtonGroup, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { memo } from "react";

import useTranslation from "hooks/useTranslation";

export interface ISwitcherYesNo {
	onChangeYesNo: (newValue: boolean) => void;
	value?: boolean;
	disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		checked: {
			color: theme.palette.common.white,
			textTransform: "capitalize",
		},
		unchecked: {
			color: theme.palette.common.black,
			textTransform: "capitalize",
		},
	}),
);

const SwitcherYesNo = memo(({ onChangeYesNo, value, disabled }: ISwitcherYesNo) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const handleSwitcher = (switcherValue: boolean) => {
		onChangeYesNo(switcherValue);
	};

	return (
		<ButtonGroup disableElevation>
			<Button
				className={value ? classes.checked : classes.unchecked}
				color={value ? "primary" : "default"}
				onClick={() => handleSwitcher(true)}
				variant={value ? "contained" : "outlined"}
				disabled={!!disabled}
			>
				{t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.YES_BTN")}
			</Button>

			<Button
				className={!value ? classes.checked : classes.unchecked}
				color={!value ? "primary" : "default"}
				onClick={() => handleSwitcher(false)}
				variant={!value ? "contained" : "outlined"}
				disabled={!!disabled}
			>
				{t("EXPERIENCE.FORM.COMMON_FOR_BUTTONS.NO_BTN")}
			</Button>
		</ButtonGroup>
	);
});

export default SwitcherYesNo;
