import { IExperienceResponse, ILocalhostResponse, IRatingByBooking } from "data/ratings/types";

import { ESteps } from "pages/rating/types";

import API from "./index";
import { IPostCreateRatings } from "./types/ratings";

const internalNamespace = "internal";

const apiName = "/ratings";

const ratingsService = {
	postCreateRatings: (data: IPostCreateRatings) =>
		API.post<IRatingByBooking>(internalNamespace, apiName, { body: data }),
	// TODO: remove this enum (enum includes step FINISH that don't exist in API urls)
	postRatings: (body: Record<string, any>, step: ESteps, withGuestCredentials?: boolean) =>
		API.post(internalNamespace, `${apiName}/${step}`, { body }, undefined, withGuestCredentials),
	//
	getSearchExpRatings: (experienceId: string) =>
		API.get<IExperienceResponse[]>(internalNamespace, `${apiName}/experience`, {
			queryStringParameters: {
				experience_id: experienceId,
			},
		}),
	getSearchLocalhostRatings: (localhostId: string, experienceId?: string) =>
		API.get<ILocalhostResponse[]>(internalNamespace, `${apiName}/localhost`, {
			queryStringParameters: {
				localhost_id: localhostId,
				...(experienceId && { experience_id: experienceId }),
			},
		}),
	//
	getRatingByBooking: (bookingId: string, withGuestCredentials?: boolean) =>
		API.get<IRatingByBooking>(
			internalNamespace,
			`${apiName}/by-booking/${bookingId}`,
			undefined,
			undefined,
			withGuestCredentials,
		),
};

export default ratingsService;
