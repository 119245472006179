import { Box, Button } from "@material-ui/core";
import { useCallback } from "react";
import styled from "styled-components";

import { TCurrency } from "data/backoffice/financials/types";
import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { minutesToHM } from "utils/dates";
import fillRoute from "utils/routes";

import CarouselSwiper from "ui/CarouselSwiper";

import { Container, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import RecordBinipool from "../RecordBinipool";
import { carouselSwiperConfig } from "../RecordBinipool/config";

const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.xhuge};
	font-weight: bold;
	line-height: 1.08;
	letter-spacing: 0.07px;

	${media.tablet`
      font-size: ${dimensions.fontSize.huge};
  `};
`;

const Description = styled.p`
	margin-bottom: 70px;
	font-family: ${MontserratFontFamily};
	font-size: 20px;
	letter-spacing: 0.04px;
	line-height: 1.08;

	${media.tablet`
      font-size: ${dimensions.fontSize.large};
  `};
`;

const SeeAllBtn = styled(Button)`
	margin-top: 40px;
	height: 48px;
	min-width: 207px;
`;

interface IBinipoolExperiencesListProps {
	binipoolExpList: {
		id: string;
		city: string;
		duration_minutes: number;
		pricing: {
			filter_price?: number;
			currency?: TCurrency;
		};
		photo_main?: string;
		ratings: {
			average: number;
			count: number;
		};
		title: string;
	}[];
}

const BinipoolExperiencesList = ({ binipoolExpList }: IBinipoolExperiencesListProps) => {
	const { t } = useTranslation();

	const createListOfSliders = useCallback(
		() =>
			binipoolExpList.map(v => (
				<RecordBinipool
					key={`${v.id}-${v.title}`}
					id={v.id}
					city={v.city}
					hours={!!v.duration_minutes ? v.duration_minutes / 60 : 0}
					duration={minutesToHM(v.duration_minutes)}
					imgUrl={v.photo_main || ""}
					rating={{ totalRating: v.ratings.average, nbReviews: v.ratings.count }}
					title={v.title}
					currency={v.pricing?.currency}
					withEarnings
				/>
			)),
		[binipoolExpList],
	);

	return (
		<>
			<Container>
				<MainTitle>{t("EXPERIENCE.BINIPOOL_LIST.BINIPOOL_TITLE")}</MainTitle>

				<Description>{t("EXPERIENCE.BINIPOOL_LIST.BINIPOOL_DESCRIPTION")}</Description>
			</Container>

			<CarouselSwiper
				isMarginLeft
				heightSlide="inherit"
				widthSlide="100%"
				paddingBox={5}
				settings={carouselSwiperConfig}
				slides={createListOfSliders()}
				hidePrevNextBtn={binipoolExpList.length <= 1}
			/>

			<Box display="flex" alignItems="center" justifyContent="center">
				<StyledLink
					to={fillRoute(paths.ONBOARDING, {
						step: EOnboardingSteps.Binipools,
						search: { type: "binipool", selectOnlyBinipools: true },
					})}
					$withoutHoverUnderline
				>
					<SeeAllBtn color="primary" variant="contained">
						{t("EXPERIENCE.BINIPOOL_LIST.BINIPOOL_SEE_ALL_BTN")}
					</SeeAllBtn>
				</StyledLink>
			</Box>
		</>
	);
};

export default BinipoolExperiencesList;
