import { Box, Button, Divider, Hidden } from "@material-ui/core";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Congrats1img from "assets/images/congratulations/1.jpg";
import Congrats2img from "assets/images/congratulations/2.jpg";
import Congrats3img from "assets/images/congratulations/3.jpg";
import Congrats4img from "assets/images/congratulations/4.jpg";
import Congrats5img from "assets/images/congratulations/5.jpg";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import fillRoute from "utils/routes";

import AppWrapper from "components/layout/AppWrapper";

import Breadcrumbs from "ui/Breadcrumbs";

import colors from "styles/colors";
import { Container, MontserratFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import Animation from "./components/Animation";

const Wrapper = styled.div`
	margin: 60px auto;
	position: relative;
`;

const CongratsBox = styled.section`
	position: absolute;
	top: 220px;
	left: 220px;
	width: 891px;
	border-radius: 6px;
	background: ${colors.white};
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
		0 24px 38px 3px rgba(0, 0, 0, 0.14);

	${media.largeDesktop`
    padding: 0;
    position: initial;
    top: initial;
    left: initial;
    width: 100%;
    height: 100%;
    box-shadow: none;
  `};
`;

const MainTitle = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 48px;
	font-weight: bold;
	line-height: 1.02;

	${media.tablet`
    font-size: 20px;
  `};
`;

const SubTitle = styled.h2`
	margin: 20px 0 0 0;
	font-size: ${dimensions.fontSize.huge};
	font-weight: 500;
	line-height: 1.09;
	letter-spacing: 0.12px;

	${media.tablet`
    margin: 0 0 1.25rem 0;
    font-size: ${dimensions.fontSize.xlarge};
  `};
`;

const Description = styled.div`
	margin: 20px 0 100px 0;
	font-size: ${dimensions.fontSize.xlarge};
	line-height: 1.5;
	letter-spacing: 0.09px;

	${media.tablet`
    margin: 0 0 1.25rem 0;
    font-size: ${dimensions.fontSize.large};
  `};
`;

const Picture = styled.img`
	width: 170px;
	height: 177px;
`;

const ForgotLink = styled(StyledLink)`
	display: block;
	margin-top: 20px;
	font-size: 13px;
	font-weight: 500;
	line-height: 1.69;
	letter-spacing: 0.3px;
	text-transform: uppercase;
	opacity: 0.8;
	color: inherit;
	text-decoration: none;

	${media.tablet`
    font-size: 13px;
  `};
`;

const FinishBtn = styled(Button)`
	width: 97px;
	height: 42px;

	&:hover {
		text-decoration: none;
	}
`;

const AnimationContent = styled.div`
	padding: 5px 5px 5px 0;
	position: relative;
	display: flex;
	justify-content: space-between;
	flex: 1;

	${media.phone`
    flex: 0;
  `};
`;

const ExperienceCongratulationsPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();

	return (
		<AppWrapper>
			<Container>
				<Breadcrumbs
					withoutHomepage
					customFirstBreadcrumb={{ route: paths.EXPERIENCES, label: t("EXPERIENCE.BACK_TO_EXPERIENCES_BTN") }}
					lastBreadcrumbLabel={t("CONGRATULATIONS.MAIN_TITLE")}
				/>

				<Wrapper>
					<Hidden mdDown>
						<Box display="flex" justifyContent="flex-end" width="60%">
							<Picture src={Congrats3img} alt="congratulations img" />
						</Box>

						<Box display="flex" justifyContent="space-between" width="100%">
							<Box marginTop={-30}>
								<Picture src={Congrats1img} alt="congratulations img 1" />
							</Box>

							<Picture src={Congrats4img} alt="congratulations img 2" />
						</Box>

						<Box display="flex" justifyContent="flex-start" marginTop={160}>
							<Picture src={Congrats2img} alt="congratulations img 3" />
						</Box>

						<Box display="flex" justifyContent="flex-end" width="60%">
							<Picture src={Congrats5img} alt="congratulations img 4" />
						</Box>
					</Hidden>

					<CongratsBox>
						<Box display="flex">
							<Box paddingTop="50px" paddingBottom="50px" paddingLeft="30px" paddingRight="0" position="relative">
								<MainTitle>{t("CONGRATULATIONS.MAIN_TITLE")}</MainTitle>
								<SubTitle>{t("CONGRATULATIONS.SUB_TITLE")}</SubTitle>
								<Description>{t("CONGRATULATIONS.DESCRIPTION")}</Description>

								<StyledLink to={paths.EXPERIENCES} $withoutHoverUnderline>
									<FinishBtn variant="contained" color="primary">
										{t("CONGRATULATIONS.FINISH_BTN")}
									</FinishBtn>
								</StyledLink>

								<ForgotLink to={fillRoute(paths.EXPERIENCE_CREATE_STEP, { id, step: "step-1" })}>
									{t("CONGRATULATIONS.FORGOT_STH")}
								</ForgotLink>

								<Box position="absolute" top="130px" right="250px">
									<Hidden mdDown>
										<AnimationContent>
											<Animation />
										</AnimationContent>
									</Hidden>
								</Box>
							</Box>
						</Box>
					</CongratsBox>
				</Wrapper>
			</Container>

			<Divider />
		</AppWrapper>
	);
};

export default ExperienceCongratulationsPage;
