import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useState } from "react";

import { getDictionary } from "data/dictionaries";
import { EOnboardingSteps } from "data/users/types";

import useTranslation from "hooks/useTranslation";

import useLanguages from "store/hooks/useLanguages";

import { DATE_FORMATS } from "utils/dates";

import MuiDateProvider from "providers/MuiDateProvider";

import {
	DescriptionContent,
	IconContent,
	Text,
	Title,
	TooltipContent,
} from "components/creation-process/tooltips/shared.styled";
import TextFieldBox from "components/forms/TextFieldBox";

import ImageEditor from "ui/ImageEditDialog";
import StepBar from "ui/StepBar";
import TooltipIcon from "ui/tooltips/TooltipIcon";

import colors from "styles/colors";

import { stepsLength } from "../../../config";
import {
	InputsContainer,
	MainContainer,
	MainContent,
	StepContainer,
	StyledAutocomplete,
	StyledButton,
	TitleHeader,
} from "../../../shared.styled";
import { BioProps } from "../index";
import {
	AvatarContainer,
	AvatarImage,
	Desc,
	DropzoneContainer,
	DropzoneInside,
	DropzoneWrapper,
	FieldContainer,
	FieldWithTooltipContainer,
	StyledIcon,
	StyledTooltip,
} from "../shared.styled";

const BioLayout = ({
	handleOnFilesAdded,
	handleLanguagesChange,
	handleDateChange,
	inputs,
	handleInputChange,
	handleInterestsChange,
	handleSendButtonClick,
	isSendButtonDisabled,
	validateField,
	userName,
	maxDateRangeTimestamp,
}: BioProps) => {
	const { t, withRaw, withValues } = useTranslation();

	const { languagesSelectSorted, findNameByKey } = useLanguages();

	const [openAvatarDialog, setOpenAvatarDialog] = useState<boolean>(false);
	const [skillsAndInterestsTextValue, setSkillsAndInterestsTextValue] = useState<string>("");

	const avatarError = !inputs.avatar_url ? !!inputs.errors.avatar_url : false;

	return (
		<MainContainer>
			{openAvatarDialog && (
				<ImageEditor
					initialImage={inputs.avatar_url}
					showDialog
					handleClose={() => {
						validateField("avatar_url");
						setOpenAvatarDialog(prevState => !prevState);
					}}
					saveImage={handleOnFilesAdded}
				/>
			)}

			<MainContent>
				<TitleHeader>{withValues("ONBOARDING.HEADER_WITH_NAME", { name: userName })}</TitleHeader>

				<StepContainer>
					<StepBar activeStep={EOnboardingSteps.LongBio} stepsAmount={stepsLength} />
				</StepContainer>

				<AvatarContainer>
					<DropzoneWrapper>
						{inputs.avatar_url ? (
							<AvatarImage src={inputs.avatar_url} onClick={() => setOpenAvatarDialog(prevState => !prevState)} />
						) : (
							<DropzoneContainer error={avatarError}>
								<DropzoneInside onClick={() => setOpenAvatarDialog(prevState => !prevState)}>
									<StyledIcon fontSize="large" />
								</DropzoneInside>
							</DropzoneContainer>
						)}
					</DropzoneWrapper>

					<Desc error={avatarError}>
						{t("ONBOARDING.BIO.UPLOAD_IMAGE")}
						<br />
						<small>{t("ONBOARDING.BIO.UPLOAD_IMAGE_SMALL")}</small>
					</Desc>
				</AvatarContainer>

				<InputsContainer>
					<FieldContainer>
						<MuiDateProvider>
							<KeyboardDatePicker
								autoOk
								disableToolbar
								inputVariant="outlined"
								format={DATE_FORMATS.DATE_FORMAT4}
								id="bio_calendar"
								label={t("ONBOARDING.BIO.INPUT.BIRTHDATE")}
								disableFuture
								maxDate={new Date(maxDateRangeTimestamp)}
								value={inputs.birthDatePure}
								onBlur={() => validateField("birthdate")}
								openTo="year"
								maxDateMessage={t("ONBOARDING.BIO.INPUT.BIRTHDATE_MAX_DATE")}
								views={["year", "month", "date"]}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								InputLabelProps={{ shrink: !!inputs.birthDatePure }}
								error={inputs.errors.birthdate}
								helperText={inputs.errors.birthdate && t("ERRORS.INCORRECT_DATE")}
							/>
						</MuiDateProvider>
					</FieldContainer>

					<FieldContainer>
						<TextFieldBox
							id="bio_text_input_occupation"
							label={t("ONBOARDING.BIO.INPUT.OCCUPATION.LABEL")}
							variant="outlined"
							name="occupation"
							value={inputs.occupation}
							onChange={handleInputChange}
							onBlur={() => validateField("occupation")}
							error={inputs.errors.occupation}
							helperText={inputs.errors.occupation && t("ONBOARDING.BIO.INPUT.OCCUPATION.ERROR")}
							maxLength={100}
							customBottom="-20px"
						/>
					</FieldContainer>

					<FieldContainer>
						<StyledAutocomplete
							id="bio_autocomplete_skills_list"
							multiple
							freeSolo
							filterSelectedOptions
							autoHighlight
							inputValue={skillsAndInterestsTextValue}
							value={inputs.skills_and_interests.map(elem => ({ label: elem }))}
							options={(!!skillsAndInterestsTextValue
								? [{ label: skillsAndInterestsTextValue }, ...getDictionary("CATEGORIES")]
								: getDictionary("CATEGORIES")
							).filter(elem => {
								let properValue = true;

								inputs.skills_and_interests.forEach(e => {
									if (e === elem.label) {
										properValue = false;
									}
								});

								return properValue;
							})}
							getOptionLabel={(option: { label: string }) => option.label}
							onChange={(event, value) => {
								// @ts-ignore
								handleInterestsChange(event, value);
								setSkillsAndInterestsTextValue("");
							}}
							renderInput={params => (
								<TextField
									{...params}
									onChange={event => setSkillsAndInterestsTextValue(event.target.value)}
									label={t("ONBOARDING.BIO.INPUT.SKILLS_AND_INTERESTS.LABEL")}
									variant="outlined"
									name="skills_interests"
									placeholder={t("ONBOARDING.BIO.INPUT.SKILLS_AND_INTERESTS.PLACEHOLDER")}
									onBlur={() => validateField("skills_and_interests")}
									error={inputs.errors.skills_and_interests}
									helperText={inputs.errors.skills_and_interests && t("ERRORS.REQUIRED")}
								/>
							)}
						/>
					</FieldContainer>

					<FieldContainer>
						<StyledAutocomplete
							id="bio_autocomplete_languages_list"
							multiple
							autoHighlight
							value={inputs.languages.map(elem => ({ value: elem, label: findNameByKey(elem) }))}
							options={languagesSelectSorted}
							getOptionLabel={(option: { label: string }) => option.label}
							getOptionSelected={(
								option: { label: string; value: string },
								selected: { label: string; value: string },
							) => option.value === selected.value}
							filterSelectedOptions
							onChange={handleLanguagesChange}
							renderInput={params => (
								<TextField
									{...params}
									label={t("ONBOARDING.BIO.INPUT.LANGUAGE.LABEL")}
									variant="outlined"
									name="language_skills"
									placeholder={t("ONBOARDING.BIO.INPUT.LANGUAGE.PLACEHOLDER")}
									onBlur={() => validateField("languages")}
									error={inputs.errors.languages}
									helperText={inputs.errors.languages && t("ERRORS.REQUIRED")}
								/>
							)}
						/>
					</FieldContainer>

					<FieldContainer>
						<TextFieldBox
							id="bio_text_input_motto"
							label={t("ONBOARDING.BIO.INPUT.MOTTO.LABEL")}
							variant="outlined"
							name="motto"
							value={inputs.motto}
							onChange={handleInputChange}
							onBlur={() => validateField("motto")}
							error={inputs.errors.motto}
							helperText={inputs.errors.motto && t("ONBOARDING.BIO.INPUT.MOTTO.ERROR")}
							maxLength={300}
							customBottom="-20px"
						/>
					</FieldContainer>

					<FieldWithTooltipContainer>
						<TextFieldBox
							id="bio_textarea_words_about_you"
							label={t("ONBOARDING.BIO.INPUT.DESCRIPTION.LABEL")}
							multiline
							rows="5"
							variant="outlined"
							name="description"
							value={inputs.description}
							onChange={event => {
								handleInputChange(event);

								validateField("description");
							}}
							onBlur={() => validateField("description")}
							error={inputs.errors.description}
							helperText={inputs.errors.description && t("ONBOARDING.BIO.INPUT.DESCRIPTION.ERROR")}
							maxLength={1000}
							customBottom="-20px"
						/>

						<StyledTooltip>
							<TooltipContent>
								<IconContent>
									<TooltipIcon iconType="warning" />
								</IconContent>

								<DescriptionContent>
									<Title isMarginBottom color={colors.sunShade}>
										{t("ONBOARDING.BIO.INPUT.DESCRIPTION.TOOLTIP_TITLE")}
									</Title>

									<Text>{withRaw("ONBOARDING.BIO.INPUT.DESCRIPTION.TOOLTIP_EXAMPLE", null)}</Text>
								</DescriptionContent>
							</TooltipContent>
						</StyledTooltip>
					</FieldWithTooltipContainer>
				</InputsContainer>

				<StyledButton
					id="bio_button_send_for_review"
					onClick={handleSendButtonClick}
					disabled={isSendButtonDisabled()}
					variant="contained"
					color="primary"
				>
					{t("ONBOARDING.NEXT_BUTTON")}
				</StyledButton>
			</MainContent>
		</MainContainer>
	);
};

export default BioLayout;
