import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { IBookingIId } from "data/backoffice/bookings/types";

import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import bookingsService from "services/api/bookings";

import { useAppDispatch, useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerStatus } from "store/selectors/experienceTraveller";
import { selectGuest } from "store/selectors/user";
import { fetchExperienceDetailsTraveller } from "store/slices/experienceTraveller";
import { fetchGuestAuthCredentials, userActions } from "store/slices/users";

import { DATE_FORMATS, parseISOWrapper } from "utils/dates";

import AppWrapper from "components/layout/AppWrapper";
import Loader from "components/loaders/Loader";

import { Container } from "styles/common";

import BookingConfirmation from "../booking/components/BookingConfirmation";
import PaymentStep, { IPaymentStep } from "./components/PaymentStep";

interface IPaymentPage {
	bid: string;
}

const PaymentPage = ({ match }: RouteComponentProps<IPaymentPage>) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const status = useAppSelector(selectExperienceTravellerStatus);
	const { data: guestCredentials, status: guestCredentialsStatus } = useAppSelector(selectGuest);

	const query = useQuery();
	const token = query.get("token");

	const [bookingDetails, setBookingDetails] = useState<IBookingIId | null>();

	const { bid } = match.params;

	const fetchBookingDetails = async (withGuestCredentials?: boolean) => {
		try {
			const response = await bookingsService.getBooking(bid, withGuestCredentials);

			const bookingData = response.data;

			dispatch(
				fetchExperienceDetailsTraveller({
					id: bookingData.eid,
					withoutUpcomingBookings: true,
					withExtraData: true,
					withGuestCredentials,
				}),
			);

			setBookingDetails(bookingData);
		} catch {
			setBookingDetails(null);
		}
	};

	useEffect(() => {
		if (token) {
			dispatch(fetchGuestAuthCredentials({ token, trigger: match.path }));
		} else {
			fetchBookingDetails();
		}

		// eslint-disable-next-line
	}, [bid, token]);

	useEffect(() => {
		if (guestCredentialsStatus === "success" && guestCredentials?.trigger === match.path) {
			dispatch(userActions.resetGuestStatus());

			fetchBookingDetails(true);
		}

		// eslint-disable-next-line
	}, [guestCredentialsStatus]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [query]);

	if (bookingDetails === null || status === "failed") {
		return (
			<AppWrapper>
				<Container>
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
						<Alert severity="error">{t("NOTIFICATION.UNDEFINED_ERROR")}</Alert>
					</Box>
				</Container>
			</AppWrapper>
		);
	}

	if (!bookingDetails || status !== "succeeded") {
		return (
			<AppWrapper>
				<Container>
					<Loader />
				</Container>
			</AppWrapper>
		);
	}

	if (query.get("confirmed")) {
		return <BookingConfirmation />;
	}

	const createBookingDetails = () => {
		let kids = 0;

		if (bookingDetails.tickets.kids?.length) {
			bookingDetails.tickets.kids.forEach(kid => {
				kids = kids + kid.quantity;
			});
		}

		const pricing = {
			final_gross_price: bookingDetails.price,
		};

		return {
			date: [parseISOWrapper(bookingDetails.date_time, DATE_FORMATS.DATE_FORMAT)],
			hour: [parseISOWrapper(bookingDetails.date_time, DATE_FORMATS.TIME_FORMAT)],
			adults: bookingDetails.tickets.adults,
			kids,
			language: bookingDetails.language,
			pricing,
		} as IPaymentStep["bookingDetails"];
	};

	return (
		<PaymentStep
			amount={bookingDetails.price as number}
			bookingId={bookingDetails.id}
			bookingDetails={createBookingDetails()}
			travellerDetails={{
				name: bookingDetails.traveler_details?.first_name,
				last_name: bookingDetails.traveler_details?.last_name,
				email: bookingDetails.traveler_details?.email,
			}}
		/>
	);
};

export default PaymentPage;
