import { IGeneratePriceSummaryPostData, IGeneratePriceSummaryResultData } from "data/pricing/types";

import { APISingleData } from "./index";

const namespace = "internal";
const api = "/pricing";

const pricingService = {
	postGeneratePriceSummary: (data: IGeneratePriceSummaryPostData) =>
		APISingleData.post<IGeneratePriceSummaryResultData>(namespace, `${api}/generate-price-summary`, {
			body: data,
		}),
};

export default pricingService;
