import { Chip as BaseChip } from "@material-ui/core";
import { ChipProps } from "@material-ui/core/Chip/Chip";
import styled from "styled-components";

import colors from "styles/colors";

interface IChipAction extends ChipProps {
	isClicked?: boolean;
}

const StyledChip = styled(BaseChip)<{ $isClicked?: boolean }>`
	font-size: 14px;
	background: ${props => (props.$isClicked ? colors.sunShade : colors.white)} !important;
	color: ${props => (props.$isClicked ? colors.white : colors.mainFontColor)};
	${props => props.$isClicked && "font-weight: 500;"}
	box-shadow: 0 0 4px 0 #cecece;
	border-radius: 16px;
	margin-top: 5px;
`;

const ChipAction = ({ onClick, isClicked, onDelete, clickable, label, disabled }: IChipAction) => (
	<StyledChip
		$isClicked={isClicked}
		onClick={onClick}
		clickable={clickable}
		label={label}
		onDelete={onDelete}
		disabled={disabled}
	/>
);

export default ChipAction;
