import { Box } from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import copy from "copy-to-clipboard";
import styled from "styled-components";

import useNotification from "hooks/useNotification";

import { isValidIdToPreview } from "utils/strings";

import { StyledLink } from "styles/common";

const StyledIcon = styled(FilterNoneIcon)<{ $isBold?: boolean }>`
	width: 14px;
	height: 14px;
	margin-left: 15px;
	cursor: pointer;
	opacity: ${props => (props.$isBold ? 1 : 0.6)};
`;

const ContentBox = styled(Box)`
	white-space: nowrap;
	line-height: 1;
`;

const BolderStyledLink = styled(StyledLink)`
	font-weight: 500;
`;

interface IElemClipboard {
	elem?: string | null;
	splitPart?: number;
	route?: string | false;
	targetBlank?: boolean;
	differentText?: string;
	isBold?: boolean;
}

const defaultSplitPart = 15;

const validToCopy = (text: string) => !["N/A"].includes(text);

const ElemClipboard = ({ elem, splitPart, route, targetBlank, differentText, isBold }: IElemClipboard) => {
	const { addSuccess } = useNotification();

	if (typeof elem === "undefined") {
		return null;
	}

	if (typeof elem !== "string") {
		return elem;
	}

	const localSplitPart = splitPart || defaultSplitPart;
	const text = differentText || elem.slice(0, localSplitPart) + (elem.length > localSplitPart ? "..." : "");

	return (
		<ContentBox display="inline-flex" alignItems="center" title={differentText || elem}>
			{route && validToCopy(elem) && isValidIdToPreview(elem) ? (
				<BolderStyledLink to={route} target={targetBlank ? "_blank" : undefined}>
					{text}
				</BolderStyledLink>
			) : (
				<span>{text}</span>
			)}

			{validToCopy(elem) && (
				<StyledIcon
					$isBold={isBold}
					onClick={() => {
						copy(elem);

						addSuccess("COMMON.COPIED", true);
					}}
				/>
			)}
		</ContentBox>
	);
};

export default ElemClipboard;
