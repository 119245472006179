import styled from "styled-components";

import FooterLandingPage from "components/layout/FooterLandingPage";
import TopBarLandingPage from "components/layout/TopBarLandingPage";

import colors from "styles/colors";
import { MontserratFontFamily } from "styles/common";
import media from "styles/media";

import About from "./components/About";
import BecomeLocalhost from "./components/BecomeLocalhost";
import ContactUs from "./components/ContactUs";
import Header from "./components/Header";
import HowItWorks from "./components/HowItWorks";
import LocalsSay from "./components/LocalsSay";
import QuestionsAndAnswers from "./components/QuestionsAndAnswers";
import SomeInspiration from "./components/SomeInspiration";

export const WrapperSection = styled.section`
	margin: 50px auto 100px;
	width: 100%;
	font-family: ${MontserratFontFamily};
	color: ${colors.mainFontColor};

	${media.tablet`
     margin: 25px auto;
  `};
`;

const UniqueExperiencesPage = () => (
	<>
		<TopBarLandingPage />
		<Header />
		<About />
		<SomeInspiration />
		<HowItWorks />
		<BecomeLocalhost />
		<LocalsSay />
		<QuestionsAndAnswers />
		<ContactUs />
		<FooterLandingPage isDivider={false} />
	</>
);

export default UniqueExperiencesPage;
