import useTranslation from "hooks/useTranslation";

import TooltipIcon from "ui/tooltips/TooltipIcon";

import colors from "styles/colors";

import { DescriptionContent, IconContent, Text, Title, TooltipContent } from "../shared.styled";

const UniqueExp = () => {
	const { t } = useTranslation();

	return (
		<TooltipContent>
			<IconContent>
				<TooltipIcon iconType="warning" />
			</IconContent>

			<DescriptionContent>
				<Title isMarginBottom color={colors.sunShade}>
					{t("EXPERIENCE.TOOLTIP.BASIC_INFO.EXP7.TITLE")}
				</Title>

				<Text>{t("EXPERIENCE.TOOLTIP.BASIC_INFO.EXP7.DESC")}</Text>
			</DescriptionContent>
		</TooltipContent>
	);
};

export default UniqueExp;
