import { API as AmplifyAPI } from "aws-amplify";

import { AmplifyAPIRequest, AmplifyAPIRequestSingle } from "services/aws/types";

import { selectGuest } from "store/selectors/user";

import ApiError from "utils/errors/ApiError";

import store from "../../store";

export enum HttpMethod {
	get = "get",
	post = "post",
	put = "put",
	patch = "patch",
	delete = "del",
	head = "head",
}

type TAPI = {
	[key in keyof typeof AmplifyHttpMethodMap]: <EntityDTO, Entity = EntityDTO>(
		apiName: any,
		path: any,
		params?: any,
		mapFromDTO?: (dataDTO: EntityDTO) => Entity,
		withGuestCredentials?: boolean,
	) => Promise<EntityDTO>;
};

const injectGuestCredentials = () => {
	const guestCredentials = selectGuest(store.getState());

	return (
		guestCredentials.data && {
			headers: {
				Authorization: guestCredentials.data.authorization,
				Authentication: guestCredentials.data.authentication,
			},
		}
	);
};

async function handleAPIRequest<EntityDTO, Entity = EntityDTO>(
	apiRequest: AmplifyAPIRequest<EntityDTO>,
	mapFromDTO: ((dataDTO: EntityDTO) => Entity) | undefined = (d: any) => d as Entity,
) {
	try {
		const response = await apiRequest;

		if (mapFromDTO) {
			return mapFromDTO(response?.data);
		}

		return response?.data;
	} catch (e) {
		throw new ApiError(e);
	}
}

async function handleSingleAPIRequest<EntityDTO, Entity = EntityDTO>(
	apiRequest: AmplifyAPIRequestSingle<EntityDTO>,
	mapFromDTO: ((dataDTO: EntityDTO) => Entity) | undefined = (d: any) => d as Entity,
) {
	try {
		const response = await apiRequest;

		if (mapFromDTO) {
			return mapFromDTO(response);
		}

		return response;
	} catch (e) {
		throw new ApiError(e);
	}
}

const AmplifyHttpMethodMap = {
	[HttpMethod.get]: "get",
	[HttpMethod.post]: "post",
	[HttpMethod.patch]: "patch",
	[HttpMethod.put]: "put",
	[HttpMethod.delete]: "del",
	[HttpMethod.head]: "head",
};

const API = Object.values(AmplifyHttpMethodMap).reduce((acc, method: keyof typeof AmplifyHttpMethodMap) => {
	acc[method] = (apiName, path, params, mapFromDTO, withGuestCredentials) =>
		handleAPIRequest(
			AmplifyAPI[AmplifyHttpMethodMap[method]](apiName, path, {
				...params,
				...(withGuestCredentials && injectGuestCredentials()),
			}),
			mapFromDTO,
		);
	return acc;
}, {} as TAPI);

const APISingleData = Object.values(AmplifyHttpMethodMap).reduce((acc, method: keyof typeof AmplifyHttpMethodMap) => {
	acc[method] = (apiName, path, params, mapFromDTO, withGuestCredentials) =>
		handleSingleAPIRequest(
			AmplifyAPI[AmplifyHttpMethodMap[method]](apiName, path, {
				...params,
				...(withGuestCredentials && injectGuestCredentials()),
			}),
			mapFromDTO,
		);
	return acc;
}, {} as TAPI);

export { APISingleData };

export default API;
