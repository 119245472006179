import { rgba } from "polished";
import styled, { css } from "styled-components";

import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectUser } from "store/selectors/user";

import LoginForm from "components/auth/LoginView/components/LoginForm";

import DialogContentWrapper from "ui/Dialog/components/DialogContentWrapper";

import colors from "styles/colors";
import { MontserratFontFamily, RobotoFontFamily } from "styles/common";

const TextContent = styled.div<{ underline: boolean }>`
	${props =>
		props.underline &&
		css`
			border-bottom: 1px solid ${colors.dividerColor};
			padding-bottom: 25px;
			margin-bottom: 25px;
		`}
	display: flex;
	flex-direction: column;
`;

const Content = styled(DialogContentWrapper)`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	padding: 10px;
	color: ${rgba(colors.mainFontColor, 0.87)};
	line-height: 2.4;
	text-align: center;
	font-weight: 500;
`;

const PopupTitle = styled.div`
	font-size: 24px;
	font-family: ${MontserratFontFamily};
	color: ${rgba(colors.mainFontColor, 0.87)};
	font-weight: 600;
	text-align: center;
	margin: 10px 0;
`;

const H1 = styled.h1`
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;
	color: ${rgba(colors.sunShade, 0.87)};
`;

const Footer = styled.div`
	padding-bottom: 15px;
`;

const LoginFormContainer = styled.div`
	margin-top: 25px;
`;

const RateFinish = () => {
	const { t, withComponentsValues, withValues } = useTranslation();

	const loggedUser = useAppSelector(selectUser);

	// TODO: change it to proper value if promotion will be ready
	// const notLogged = !loggedUser;
	const notLogged = false;

	const name = loggedUser?.public_profile?.first_name || loggedUser?.profile_draft?.first_name || "User";

	const price = "€20";

	return (
		<Content center>
			<TextContent underline={notLogged}>
				<PopupTitle>{t("RATING.FINISH.HEADER")}</PopupTitle>

				<div>
					{notLogged
						? t("RATING.FINISH.DESCRIPTION_ROW1_NOT_LOGGED")
						: withValues("RATING.FINISH.DESCRIPTION_ROW1_LOGGED", { name })}
				</div>

				{notLogged && (
					<>
						<strong>{t("RATING.FINISH.DESCRIPTION_ROW2")}</strong>

						<H1>{withValues("RATING.FINISH.DESCRIPTION_ROW3", { price })}</H1>
					</>
				)}
			</TextContent>

			{notLogged && (
				<Footer>
					{withComponentsValues(
						"RATING.FINISH.DESCRIPTION_ROW4",
						{
							strong: <strong />,
						},
						{ price },
					)}

					<LoginFormContainer>
						<LoginForm />
					</LoginFormContainer>
				</Footer>
			)}
		</Content>
	);
};

export default RateFinish;
