import { Button } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import { isEmpty } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { EStatusHostingRequest } from "data/backoffice/bookings/types";
import { ERejectionReasonType } from "data/notifications/types";

import useTranslation from "hooks/useTranslation";
import useValidations from "hooks/useValidations";

import { dateObjectToString, DATE_FORMATS, getCurrentDateTimeFormatted } from "utils/dates";

import RadioGroupField from "components/formik/advanced/RadioGroupField";
import InputField from "components/formik/InputV2";
import TimePickerField from "components/formik/TimePickerField";

import Dialog from "ui/Dialog";

import colors from "styles/colors";
import { RobotoFontFamily, StyledLink } from "styles/common";
import media from "styles/media";

import { Col, StyledDivider, Text, Title } from "../shared.styled";

const StyledDialog = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		margin: 0 auto;
		min-width: 350px;

		${media.tablet`
      width: 100%;
      min-width: auto;
    `};
	}

	.MuiDialogContent-root {
		padding: 50px 10px 20px;

		${media.tablet`
      padding: 50px 5px;
    `};
	}
`;

const Wrapper = styled.section`
	padding: 5px;
`;

const Item = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 10px;
`;

const TextBold = styled(Text)`
	font-weight: bold;
`;

const BtnsContent = styled.div<{ $justifyContent: string }>`
	width: 100%;
	display: flex;
	justify-content: ${props => props.$justifyContent};
	align-items: center;
`;

const StyledExpLink = styled.a`
	font-family: ${RobotoFontFamily};
	font-size: 12px;
	font-weight: bold;
	line-height: 1.67;
	color: ${colors.sunShade};

	&:hover {
		text-decoration: underline;
	}
`;

const StyledRejectedButtonContainer = styled.div`
	display: flex;
	justify-content: end;
`;

const StyledRadioInputsContainer = styled.div`
	width: 100%;
	padding-top: 15px;
	display: flex;
	flex-direction: column;
`;

const StyledRadioGroupField = styled(RadioGroupField)`
	flex-direction: column-reverse;
`;

const StyledRadioGroupTime = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		width: 100px;
	}
`;

const StyledTimePickerContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 15px;
	padding-top: 5px;
	> div {
		width: 100px;
	}
`;

interface IDashboardPendingRequestPreviewProps {
	title: string;
	startDate: string;
	startLocation: string;
	language: string;
	yourEarnings: string;
	onClickDecline: () => void;
	onClickAccept: () => void;
	onClickSendRejectionReason: (reasons: { rejection_reason: ERejectionReasonType; rejection_comment?: string }) => void;
	showDialog: boolean;
	handleClose: () => void;
	linkExp: string;
	sendSeen: () => void;
	showRejectionReasons: boolean;
	statusPendingRequest?: EStatusHostingRequest;
	linkSuggestedItinerary?: string;
	totalTravelers?: number;
	totalGroups?: number;
	isLoading?: boolean;
}

const disableActionsStatuses = [
	EStatusHostingRequest.ACCEPTED,
	EStatusHostingRequest.REJECTED,
	EStatusHostingRequest.TIMED_OUT,
	EStatusHostingRequest.CANCELED,
];

const DashboardPendingRequestPreview = ({
	title,
	startDate,
	startLocation,
	language,
	yourEarnings,
	onClickDecline,
	onClickAccept,
	onClickSendRejectionReason,
	handleClose,
	showDialog,
	statusPendingRequest,
	linkSuggestedItinerary,
	linkExp,
	sendSeen,
	totalTravelers,
	totalGroups,
	showRejectionReasons,
	isLoading,
}: IDashboardPendingRequestPreviewProps) => {
	const { t } = useTranslation(["ui", "common"]);
	const [showTimeOptions, setShowTimeOptions] = useState<boolean>(false);
	const [showOtherOption, setShowOtherOption] = useState<boolean>(false);

	useEffect(() => {
		if (showDialog) {
			sendSeen();
		}

		// eslint-disable-next-line
	}, [showDialog]);

	const formatDate = useCallback(time => {
		return time ? dateObjectToString(new Date(time), DATE_FORMATS.TIME_FORMAT) : "";
	}, []);

	const {
		yup: { validations, requiredString, stringMaxLength },
	} = useValidations();

	const formikProps = useFormik({
		enableReinitialize: true,
		validationSchema: validations({
			reason: requiredString,
			HELPER__other_reason: stringMaxLength(169).nullable(),
		}),
		initialValues: {
			reason: ERejectionReasonType.NOT_AVAILABLE,
			reason_TIME: "reason_BEFORE",
			HELPER__time: "",
			HELPER__time_after: "",
			HELPER__time_earlier: "",
			HELPER__other_reason: "",
		},
		onSubmit: async submitValues => {
			const { reason, HELPER__other_reason, reason_TIME, HELPER__time, HELPER__time_after, HELPER__time_earlier } =
				submitValues;
			let rejectionComment = "";
			const tReasonPrefix = "DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON";
			if (reason === ERejectionReasonType.OTHER) {
				rejectionComment = HELPER__other_reason;
			} else if (reason === ERejectionReasonType.TIME) {
				if (reason_TIME === "reason_BEFORE_AFTER") {
					rejectionComment = `${t(`${tReasonPrefix}.TIME.COULD_DO_IT`)}${" "}${t(
						`${tReasonPrefix}.TIME.EARLIER`,
					)}${" "}${formatDate(HELPER__time_earlier)}${" "}${t(`${tReasonPrefix}.TIME.OR`)}${" "}${t(
						`${tReasonPrefix}.TIME.LATER`,
					)}${" "}${formatDate(HELPER__time_after)}`;
				} else {
					rejectionComment = `${t(`${tReasonPrefix}.TIME.COULD_DO_IT`)}${" "}${t(
						`${tReasonPrefix}.TIME.${reason_TIME === "reason_BEFORE" ? "EARLIER" : "LATER"}`,
					)}${" "}${formatDate(HELPER__time)}`;
				}
			}
			const body: { rejection_reason: ERejectionReasonType; rejection_comment?: string } = { rejection_reason: reason };
			if (rejectionComment) {
				body.rejection_comment = rejectionComment;
			}
			onClickSendRejectionReason(body);
			handleCloseModal();
		},
	});
	const { values, submitForm } = formikProps;

	useEffect(() => {
		setShowTimeOptions(values.reason === ERejectionReasonType.TIME);
		setShowOtherOption(values.reason === ERejectionReasonType.OTHER);
	}, [values.reason]);

	useEffect(() => {
		if (showTimeOptions) {
			formikProps.setFieldValue("HELPER__time", getCurrentDateTimeFormatted());
			formikProps.setFieldValue("HELPER__time_after", getCurrentDateTimeFormatted());
			formikProps.setFieldValue("HELPER__time_earlier", getCurrentDateTimeFormatted());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTimeOptions]);

	const handleCloseModal = () => {
		setShowTimeOptions(false);
		setShowOtherOption(false);
		formikProps.setFieldValue("reason", ERejectionReasonType.NOT_AVAILABLE);
		formikProps.setFieldValue("reason_TIME", "reason_BEFORE");
		formikProps.setFieldValue("HELPER__time", "");
		formikProps.setFieldValue("HELPER__time_after", "");
		formikProps.setFieldValue("HELPER__time_earlier", "");
		formikProps.setFieldValue("HELPER__other_reason", "");
		handleClose();
	};

	const isValidDate = (date: Date | string | null): boolean => {
		let localDate = date;

		if (typeof date === "string" && localDate) {
			localDate = new Date(localDate);
		}

		return localDate ? !isNaN((localDate as Date).getTime()) : false;
	};

	const getInvalidReason = () => {
		const { reason, reason_TIME, HELPER__other_reason, HELPER__time, HELPER__time_after, HELPER__time_earlier } =
			values;

		if (reason !== ERejectionReasonType.NOT_AVAILABLE) {
			const isOtherOpt =
				reason === ERejectionReasonType.OTHER &&
				(isEmpty(HELPER__other_reason) || Boolean(formikProps?.errors?.HELPER__other_reason));

			const isBeforeAfterOpt =
				reason === ERejectionReasonType.TIME &&
				reason_TIME === "reason_BEFORE_AFTER" &&
				(!isValidDate(HELPER__time_after) || !isValidDate(HELPER__time_earlier));

			const isOtherTime =
				reason === ERejectionReasonType.TIME && reason_TIME !== "reason_BEFORE_AFTER" && !isValidDate(HELPER__time);

			return isOtherOpt || isBeforeAfterOpt || isOtherTime;
		}
		return false;
	};

	const isDisabled = getInvalidReason() || isLoading;

	const disabledStatuses = statusPendingRequest && disableActionsStatuses.includes(statusPendingRequest);

	return (
		<StyledDialog decorator="withoutLogo" showDialog={showDialog} handleClose={handleCloseModal}>
			<Wrapper>
				<StyledLink to={linkExp}>
					<Title>{title}</Title>
				</StyledLink>

				<StyledDivider />

				<Item>
					<Col>
						<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_DATE")}</Text>
					</Col>

					<Col>
						<TextBold>{startDate}</TextBold>
					</Col>
				</Item>

				<Item>
					<Col>
						<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.STARTING_LOCATION")}</Text>
					</Col>

					<Col>
						<TextBold>{startLocation}</TextBold>
					</Col>
				</Item>

				{!!totalTravelers && (
					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.NB_OF_TRAVELERS")}</Text>
						</Col>

						<Col>
							<TextBold>
								{totalTravelers}
								{!!totalGroups && totalGroups > 1 && ` (${totalGroups} groups)`}
							</TextBold>
						</Col>
					</Item>
				)}

				<Item>
					<Col>
						<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LANGUAGE")}</Text>
					</Col>

					<Col>
						<TextBold>{language}</TextBold>
					</Col>
				</Item>

				{yourEarnings && (
					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.YOUR_EARNINGS")}</Text>
						</Col>

						<Col>
							<TextBold>{yourEarnings}</TextBold>
						</Col>
					</Item>
				)}

				{linkSuggestedItinerary && (
					<Item>
						<Col>
							<Text>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.SUGGESTED_ITINERARY")}</Text>
						</Col>

						<Col>
							<StyledExpLink href={linkSuggestedItinerary} target="_blank">
								{t("DASHBOARD_PENDING_REQUEST_PREVIEW.LINK_LABEL")}
							</StyledExpLink>
						</Col>
					</Item>
				)}

				<StyledDivider />

				{!showRejectionReasons && (
					<BtnsContent $justifyContent={disabledStatuses ? "end" : "space-between"}>
						{!disabledStatuses && (
							<Button disabled={disabledStatuses} variant="outlined" onClick={onClickDecline}>
								{t("DASHBOARD_PENDING_REQUEST_PREVIEW.DECLINE_BTN")}
							</Button>
						)}

						<Button variant="contained" color="primary" onClick={onClickAccept} disabled={disabledStatuses}>
							{t("DASHBOARD_PENDING_REQUEST_PREVIEW.ACCEPT_BTN")}
						</Button>
					</BtnsContent>
				)}

				{showRejectionReasons && (
					<Wrapper>
						<Title>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TITLE")}</Title>
						<StyledDivider />
						<i>{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.SUBTITLE")}:</i>
						<FormikProvider value={formikProps}>
							<StyledRadioInputsContainer>
								<StyledRadioGroupField
									name="reason"
									values={Object.values(ERejectionReasonType).reduce(
										(object, elem) => ({
											...object,
											[elem]: {
												label: t(`DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.${elem}`),
											},
										}),
										{},
									)}
								/>
							</StyledRadioInputsContainer>
							<StyledDivider />
							{showTimeOptions && (
								<div>
									{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME")}:
									<StyledRadioInputsContainer>
										<StyledRadioGroupTime>
											<StyledRadioGroupField
												name="reason_TIME"
												values={{
													reason_BEFORE: {
														label: `${t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.COULD_DO_IT")} ${t(
															"DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.EARLIER",
														)}`,
													},
												}}
											/>
											{values.reason_TIME === "reason_BEFORE" && (
												<TimePickerField
													label={t("BO.BOOKING.ADD_NEW_BOOKING.FORM_VIEW.SECTIONS.BOOKING_DETAILS.TIME_LABEL")}
													name="HELPER__time"
												/>
											)}
										</StyledRadioGroupTime>
										<StyledRadioGroupTime>
											<StyledRadioGroupField
												name="reason_TIME"
												values={{
													reason_AFTER: {
														label: `${t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.COULD_DO_IT")} ${t(
															"DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.LATER",
														)}`,
													},
												}}
											/>
											{values.reason_TIME === "reason_AFTER" && (
												<TimePickerField
													label={t("BO.BOOKING.ADD_NEW_BOOKING.FORM_VIEW.SECTIONS.BOOKING_DETAILS.TIME_LABEL")}
													name="HELPER__time"
												/>
											)}
										</StyledRadioGroupTime>
										<StyledRadioGroupField
											name="reason_TIME"
											values={{
												reason_BEFORE_AFTER: {
													label: `${t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.AVAILABLE")} ${t(
														"DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.EARLIER",
													)}`,
												},
											}}
										/>
										{values.reason_TIME === "reason_BEFORE_AFTER" && (
											<StyledTimePickerContainer>
												<TimePickerField
													label={t("BO.BOOKING.ADD_NEW_BOOKING.FORM_VIEW.SECTIONS.BOOKING_DETAILS.TIME_LABEL")}
													name="HELPER__time_earlier"
												/>
												<p>
													{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.OR")}{" "}
													{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.TIME.LATER")}
												</p>
												<TimePickerField
													label={t("BO.BOOKING.ADD_NEW_BOOKING.FORM_VIEW.SECTIONS.BOOKING_DETAILS.TIME_LABEL")}
													name="HELPER__time_after"
												/>
											</StyledTimePickerContainer>
										)}
									</StyledRadioInputsContainer>
								</div>
							)}
							{showOtherOption && (
								<div>
									{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.OTHER_REASON")}:
									<InputField name="HELPER__other_reason" multiline required customBottom="-20px" maxLength={169} />
								</div>
							)}
							{(showTimeOptions || showOtherOption) && <StyledDivider />}
							<StyledRejectedButtonContainer>
								<Button variant="contained" color="primary" onClick={submitForm} disabled={isDisabled}>
									{t("DASHBOARD_PENDING_REQUEST_PREVIEW.REJECTION_REASON.REJECT_BTN")}
								</Button>
							</StyledRejectedButtonContainer>
						</FormikProvider>
					</Wrapper>
				)}
			</Wrapper>
		</StyledDialog>
	);
};

export default DashboardPendingRequestPreview;
