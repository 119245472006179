import { TPromoCodesUsageIId } from "data/promo-codes/types";

export const columns = [
	"MY_REFERRALS.TABLE.TYPE",
	"MY_REFERRALS.TABLE.REFERRED_LOCAL_HOST",
	"MY_REFERRALS.TABLE.DATE_OF_USAGE",
	"MY_REFERRALS.TABLE.VALUE",
	"MY_REFERRALS.TABLE.PROCESS",
];

export const fields: (keyof TPromoCodesUsageIId)[] = [
	"type",
	"referred_localhost_id",
	"created_on",
	"amount",
	"status",
];

export const sortedColumns = [
	"MY_REFERRALS.TABLE.TYPE",
	"MY_REFERRALS.TABLE.DATE_OF_USAGE",
	"MY_REFERRALS.TABLE.VALUE",
	"MY_REFERRALS.TABLE.PROCESS",
];

export const idForContainer = "my-referrals-main-view";
export const listItemsOffset = 20;
